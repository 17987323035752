/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActionsHttpService {
  private jsonContentType = 'application/json';
  // host
  private documentGedHost: string;
  private documentsExportHost: string;

  private readonly jsonOptions: object;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private transferService: TransferService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
  }

  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
    this.documentGedHost = appConfig.docDetails.http.host;
    /* ENDPOINTS DOCUMENT EXPORT ---------------------------------------------------------------------------------------------------- */
    this.documentsExportHost = appConfig.documentsExport.http.host;
    // this.hasAttachments = `${this.docDetailsHost}/v1/Document/GEDINVOICE/HasAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentChannelType={account}`;
  }

  // AZIONI -------------------------------------------------------------------------------------------------------
  // SINGOLA: download pdf
  whenSinglePdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<Transfer> {
    const url =
      this.documentGedHost +
      actionUrl.replace('{docHash}', docHash).replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.get(url, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  whenPreviewSinglePdf(actionUrl: string, progSpool: string, progBusta: string, docHash: string): Observable<unknown> {
    const url =
      this.documentGedHost +
      actionUrl.replace('{docHash}', docHash).replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
    const options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob', null, true);
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.get(url, opts);
      }),
    );
  }

  // MULTI: download zip di documenti
  whenPdfZip(actionUrl: string, body: unknown): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    const url = this.documentGedHost + actionUrl;
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.post<unknown>(url, body, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  // MULTI: download pdf mergiati
  whenMergedPdf(actionUrl: string, body: unknown): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    const url = this.documentGedHost + actionUrl;
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.post<unknown>(url, body, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  // MULTI: download excel doc selezionati
  whenExcel(actionUrl: string, body: unknown): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    const url = this.documentsExportHost + actionUrl;
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  // MASSIVA: download excel di tutta la serie documentale/sezione
  whenExcelAll(actionUrl: string, body: unknown): Observable<unknown> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    const url = this.documentsExportHost + actionUrl;
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.post(url, body, opts);
      }),
    );
  }
}
