import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BoxDetails } from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BoxDetailsHttpService {
  boxDetails$: Observable<BoxDetails>;
  private destroy$ = new ReplaySubject<void>(1);

  private gawpassHost!: string;
  private boxDetailsUrl!: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.gawpassHost = appConfig.gawPassRead.http.host;

    this.boxDetailsUrl = `${this.gawpassHost}/api/v1/Action/ViewTrackingDetails/{IdScatola}/{Sia}/{IdLicenza}`;
  }

  whenBoxDetails$(licenseId: string, siaCode: string, boxId: string): Observable<BoxDetails> {
    const url = this.boxDetailsUrl
      .replace('{IdLicenza}', licenseId)
      .replace('{Sia}', siaCode)
      .replace('{IdScatola}', boxId);
    return this.http.get<BoxDetails>(url);
  }
}
