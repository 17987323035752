<div class="preview h-100 pb-3">
  <tabset [justified]="true">
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header">
          <b>Spedizione</b>
        </div>
      </ng-template>
      <gaw-gawpass-expedition [boxDetails$]="boxDetails$" [data]="data"></gaw-gawpass-expedition>
    </tab>
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header">
          <b>Note</b>
        </div>
      </ng-template>
      <gaw-gawpass-notes [boxDetails$]="boxDetails$"></gaw-gawpass-notes>
    </tab>
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header document-detail-body">
          <b>Storico Stati</b>
        </div>
      </ng-template>
      <gaw-gawpass-status-history class="document-detail-body" [data]="data"></gaw-gawpass-status-history>
    </tab>
  </tabset>
</div>
