import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Note } from '../../entities/note';
import { StylingComponent } from '../../entities/stylingComponent';
import { DocumentNotesService } from '../../services/document-notes.service';

@Component({
  selector: 'ctel-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddNotesComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
  @Input() btnStyle: StylingComponent;
  @Input() textAreaStyle: StylingComponent;
  @Input() submitIcon: StylingComponent;

  @Input() progSpool: number;
  @Input() progBusta: number;
  @Input() idSerieDoc: number;
  @Input() note: Note;
  @Input() userId: number;

  @Output() noteSaved = new EventEmitter<boolean>();
  @Output() viewInitalized = new EventEmitter<boolean>();

  public msg: string;
  public disabled = true;
  public charCount = 0;

  private destroy$ = new Subject<void>();
  private textarea: HTMLTextAreaElement;

  constructor(
    private documentNotesService: DocumentNotesService,
    private modalRef: BsModalRef,
  ) {}

  ngOnInit(): void {
    if (this.note) {
      this.documentNotesService.setMode('edit');
      this.documentNotesService.selectNote(this.note, this.userId);
    } else this.documentNotesService.setMode('new');

    this.handleDisableTextArea();
    this.handleTextAreaMsg();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes)
      if (changes.progSpool || changes.progBusta || changes.idSerieDoc)
        this.documentNotesService.setDocumentId(this.progSpool, this.progBusta, this.idSerieDoc);
  }

  // Ogni volta che seleziono una nota
  selectedNotes(note: Note, userId: number) {
    this.documentNotesService.selectNote(note, userId);
  }

  submit(element: EventTarget) {
    if (!(element instanceof HTMLTextAreaElement)) return;

    const msg = element.value;
    if (msg && msg.length > 0) {
      this.documentNotesService.submitMsg(msg);
      this.noteSaved.emit(true);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.viewInitalized.emit(true);
    this.charactersCount();
  }

  charactersCount() {
    this.textarea = document.querySelector('.document-notes-lib.inputArea');
    this.charCount = +this.textarea.value.length;
    this.textarea.addEventListener('input', () => {
      this.charCount = +this.textarea.value.length;
    });
  }

  close() {
    this.modalRef.hide();
    this.ngOnDestroy();
  }

  private handleDisableTextArea() {
    const mode$ = this.documentNotesService.getMode();

    mode$.pipe(takeUntil(this.destroy$)).subscribe((mode) => (this.disabled = mode === 'readonly'));
  }

  private handleTextAreaMsg() {
    this.documentNotesService
      .getSelectedNote()
      .pipe(takeUntil(this.destroy$))
      .subscribe((note: Note | undefined) => {
        // Setto il msg da visualizzare nella textarea
        this.msg = note ? note.note : '';
        this.charactersCount();
      });
  }
}
