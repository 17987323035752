import { Pipe, PipeTransform } from '@angular/core';
import { EnabledServicesService } from 'app/core/business/user/enabled-services/enabled-services.service';
import { Service } from 'app/entities/services/service.interface';
import { Observable } from 'rxjs';

@Pipe({
  name: 'enabledServicesFilter',
  pure: false,
})
export class EnabledServicesFilterPipe implements PipeTransform {
  constructor(private enabledServices: EnabledServicesService) {}

  transform(serviziDocumento: Service[]): Observable<Service[]> {
    return this.enabledServices.filteredServices(serviziDocumento);
  }
}
