import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe per conversione date
 */
@Pipe({
  name: 'convertdate',
})
export class ConvertdatePipe implements PipeTransform {
  transform(value: string): string {
    if (value !== null && value !== undefined) if (value.length > 10) value = value.substring(0, 10);

    return value;
  }
}
