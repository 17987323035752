<input
  class="form-control form-control-sm"
  type="{{ type || 'text' }}"
  name="{{ name }}"
  [id]="id"
  value="{{ value }}"
  [placeholder]="placeholder"
  [ngStyle]="style"
  [ngClass]="{
    valid: control?.value && control?.valid,
    invalid: control?.invalid,
  }"
  [formControl]="control"
  [popover]="InvalidFormMessage"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()"
  triggers=""
/>
<ng-template #InvalidFormMessage>
  <ul *ngIf="control.invalid" class="invalid-form-msg">
    <ng-container *ngFor="let error of controlErrors$ | async">
      <li>
        {{ error }}
      </li>
    </ng-container>
  </ul>
</ng-template>
