import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { SingleDocumentCallback } from './callbacks/single-document-callback';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly viewDocument = new ActionBuilder(SingleActionsCode.viewDocument, ActionCode.SHOW_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=false',
    )
    .build();

  public readonly restoreFromTrash = new ActionBuilder(
    SingleActionsCode.restoreFromTrash,
    ActionCode.RESTORE_FROM_TRASH,
  )
    .setActionURL('/Trash/command/RestoreFromTrash')
    .build();

  public readonly deleteFromTrash = new ActionBuilder(SingleActionsCode.deleteFromTrash, ActionCode.DELETE_FROM_TRASH)
    .setActionURL('/Trash/command/DeleteFromTrash')
    .build();

  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  viewDocument = 'viewDocument',
  restoreFromTrash = 'restoreFromTrash',
  deleteFromTrash = 'deleteFromTrash',
}
