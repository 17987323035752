<div class="main-div container-fluid">
  <form [formGroup]="formGroup">
    <div *ngFor="let item of metadata" class="form-container">
      <label class="form-label" for="{{ item.keyCode }}">{{ item.label }}:</label>

      <input [formControlName]="item.keyCode" id="{{ item.keyCode }}" type="text" class="form-control" />
    </div>
  </form>
</div>

<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn btn-danger" (click)="close()">Annulla</button>
  <button type="button" (click)="onSubmit()" class="btn btn-primary">Conferma</button>
</div>
