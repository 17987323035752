import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { take } from 'rxjs/operators';
import { AttachmentItem } from '../attachment-item';
import { WorkitemAction } from '../action';

@Component({
  selector: 'gaw-attachment-list',
  templateUrl: './attachment-list.component.html',
  styleUrls: ['./attachment-list.component.scss'],
})
export class AttachmentListComponent {
  @Input() attachments: AttachmentItem[];
  @Input() actions: WorkitemAction[];
  @Input() errorOptions: Options = {
    onRetry: () => (this._areInError = false),
  };
  @Output() readonly clickedItem = new EventEmitter<AttachmentItem>();

  _areInError = false;
  _areLoading = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  @Input() set areInError(areInError: boolean) {
    this.detachDetector();
    this._areInError = areInError;
    this.reattachDetector();
  }

  @Input() set areLoading(areLoading: boolean) {
    if (areLoading) this.detachDetector();
    else this.reattachDetector();
  }

  trackByFn(index: number, item: AttachmentItem) {
    return item.id;
  }

  onclick(attachment: AttachmentItem) {
    this.clickedItem.emit(attachment);
  }

  executeAction(attachment: AttachmentItem, action: WorkitemAction) {
    action.execute(attachment).pipe(take(1)).subscribe();
  }

  private detachDetector() {
    this.cdr.detach();
  }

  private reattachDetector() {
    this.cdr.detectChanges();
    this.cdr.reattach();
  }
}
