<div>
  <tabset [justified]="justified">
    <tab *ngFor="let documentAttachment of documentAttachments; index as i" [id]="'tab' + i">
      <ng-template tabHeading>
        <div class="tab-header">{{ documentAttachment.name }}</div>
      </ng-template>

      <ng-container [ngSwitch]="documentAttachment.type">
        <dry-xml-viewer
          class="code-content"
          *ngSwitchCase="documentContentType.XML"
          [documentAttachment]="documentAttachment | asXmlDoc"
        ></dry-xml-viewer>
        <dry-pdf-viewer
          *ngSwitchCase="documentContentType.PDF"
          [documentAttachment]="documentAttachment | asPdfDoc"
        ></dry-pdf-viewer>
      </ng-container>
    </tab>
  </tabset>
</div>
