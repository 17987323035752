import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigurationGroup } from '@ctel/gaw-commons';
import { UiConfigurationColumnsResponse } from 'app/constants/column-configuration/ui-configuration-columns';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UiConfigurationHttpService implements OnDestroy {
  private uiConfigurationHost: string;

  // Endpoints
  private getConfigurationUrl: string;
  private relatedDataSearch: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private httpClient: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  httpHostInit(appConfig: AppConfig) {
    this.uiConfigurationHost = appConfig.uiConfiguration.http.host;
    this.getConfigurationUrl = `${this.uiConfigurationHost}/v2/my/configurations/{gruppoconfigurazione}/GAWCONS?licenseId={licenseId}&siaCode={siaCode}`;
    this.relatedDataSearch = `${this.uiConfigurationHost}/v2/sections/GAWCONS/{sectionCode}/relatedSectionData?licenseId={licenseId}&siaCode={siaCode}`;
  }

  public getSectionColumns(
    licenseId: string,
    siaCode: string,
    section?: string,
  ): Observable<UiConfigurationColumnsResponse> {
    return this.getConfiguration(ConfigurationGroup.COLUMN_CONFIGURATION, licenseId, siaCode, section);
  }

  public getConfiguration(
    configurationGroup: string,
    licenseId: string,
    siaCode: string,
    section?: string,
  ): Observable<any> {
    let url = this.getConfigurationUrl
      .replace('{gruppoconfigurazione}', configurationGroup)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    if (section !== '') url = url + '&sectionCode=' + section;

    return this.httpClient.get<any>(url);
  }

  public getRelatedSearchData(licenseId: string, siaCode: string, sectionId: string): Observable<RelatedSectionData[]> {
    const url = this.relatedDataSearch
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{sectionCode}', sectionId);
    return this.httpClient.get<RelatedSectionData[]>(url);
  }
}
