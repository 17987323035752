import { NgModule } from '@angular/core';
import { GawPipesModule } from './pipes/pipes.module';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';

@NgModule({
  declarations: [InfiniteScrollComponent],
  imports: [GawPipesModule],
  exports: [GawPipesModule, InfiniteScrollComponent],
})
export class GawCommonsModule {}
