import dayjs from 'dayjs';

export class DateRangeFilter {
  private readonly _start: dayjs.Dayjs;
  private readonly _end: dayjs.Dayjs;

  constructor(start: string | Date | dayjs.Dayjs, end: string | Date | dayjs.Dayjs) {
    this._start = dayjs.isDayjs(start) ? start : dayjs(start);
    this._end = dayjs.isDayjs(end) ? end : dayjs(end);
  }

  public get start(): dayjs.Dayjs {
    return this._start;
  }

  public get end(): dayjs.Dayjs {
    return this._end;
  }
}
