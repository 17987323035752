export enum ServiceType {
  GAW = 'GAW',
  GAW30 = 'GAW30',
  HUBFE = 'HUBFE',
  GAWPEC = 'GAWPEC',
  GAWPOST = 'GAWPOST',
  GAWEDI = 'GAWEDI',
  GAWLOTTI = 'GAWLOTTI',
  GAWNOTICE = 'GAWNOTICE',
  GAWMAIL = 'GAWMAIL',
  GAWPASS = 'GAWPASS',
  GAWCONS = 'GAWCONS',
  GAWES = 'GAWES',
}
