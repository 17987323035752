export enum MetadataEnum {
  INTESTAZIONE = 'intestazione',
  INTESTAZIONE_KW = 'intestazione.keyword',
  NUMERO_FATTURA = 'numeroFattura',
  NUMERO_FATTURA_KW = 'numeroFattura.keyword',
  DATA_FATTURA = 'dataFattura',
  IMPORTO = 'importo',
  DATA_INSERIMENTO = 'dataProtocollazioneCtel',
  DATA_INSERIMENTO_22 = 'dataInserimento22',
  DATA_RICEZIONE_06 = 'dataRicezione06',
  DATA_SPEDIZIONE_10 = 'dataSpedizione10',
  DATA_RICEZIONE = 'timestampCtelRicezioneSdi',
  FAMIGLIA_ERRORE = 'famigliaErrorePreElab',
  FAMIGLIA_ERRORE_KW = 'famigliaErrorePreElab.keyword',
  DETTAGLIO_ERRORE = 'dettaglioErrorePreElab',
  DETTAGLIO_ERRORE_KW = 'dettaglioErrorePreElab.keyword',
  DESCRIZIONE_STATO = 'hubfe_descrizioneStato',
  DESCRIZIONE_STATO_KW = 'hubfe_descrizioneStato.keyword',
  DESCRIZIONE_STATO_35 = 'descrizioneUltimoStato35',
  DESCRIZIONE_STATO_35_KW = 'descrizioneUltimoStato35.keyword',
  DESCRIZIONE_STATO_22 = 'descrizioneUltimoStato22',
  DESCRIZIONE_STATO_22_KW = 'descrizioneUltimoStato22.keyword',
  DESCRIZIONE_STATO_21 = 'descrizioneUltimoStato21',
  DESCRIZIONE_STATO_21_KW = 'descrizioneUltimoStato21.keyword',
  DESCRIZIONE_STATO_06 = 'descrizioneUltimoStato06',
  DESCRIZIONE_STATO_06_KW = 'descrizioneUltimoStato06.keyword',
  DESCRIZIONE_STATO_10 = 'descrizioneStato10',
  DESCRIZIONE_STATO_10_KW = 'descrizioneStato10.keyword',
  DESCRIZIONE_STATO_39_KW = 'descrizioneStato39.keyword',
  DESCRIZIONE_STATO_TNOTICE_KW = 'tnotice_descrizioneStato.keyword',
  NUMERO_PROTOCOLLO = 'numeroProtocollo',
  NUMERO_PROTOCOLLO_KW = 'numeroProtocollo.keyword',
  DATA_PROTOCOLLO = 'dataProtocollo',
  DATA_PROTOCOLLO_KW = 'dataProtocollo.keyword',
  DATA_SPEDIZIONE_GROUP_10_KW = 'dataSpedizioneGroup10.keyword',
  ULTIMO_STATO_PREM = 'ultimoStatoPrem',
  GAW30_TAGS = 'gaw30_tags',
  GAW30_TAGS_KW = 'gaw30_tags.keyword',
  PIVA = 'piva',
  CODICE_FISCALE = 'codiceFiscale',
  ID_FILE_SDI = 'idFileSdi',
  DESCRIZIONE_SERIE_DOC = 'descrizioneSerieDoc',
  DESCRIZIONE_SERIE_DOC_KW = 'descrizioneSerieDoc.keyword',
  FLAG_ELIMINATO_37 = 'flagEliminato37',
  DATA_ULTIMO_STATO_37 = 'dataUltimoStato37',
  HUBFE_SEZIONE = 'hubfe_sezione',
  HUBFE_CP_ID_TIPO = 'hubfe_cp_idTipo',
  HASH_DOC_KEY = 'hashDocKey',
  PROGSPOOL = 'progSpool',
  PROGBUSTA = 'progBusta',
  VISIBILITY = 'hubfe_visibilita',
  VISIBILITY_KW = 'hubfe_visibilita.keyword',
  HAS_NOTES = 'hasNotes',
  PRESENZA_ALLEGATI_35 = 'presenzaAllegati35',
  FORMATO_TRASMISSIONE_SDI = 'formatoTrasmissioneSdi',
  FORMATO_TRASMISSIONE_SDI_KW = 'formatoTrasmissioneSdi.keyword',
  STATO_WEB = 'statoWeb',
  ULTIMO_STATO_35 = 'ultimoStato35',
  SCONTO_DINAMICO = 'gawdd_scontoDinamico',
  HAS_SCONTO_DINAMICO = 'gawdd_hasScontoDinamico',
  HAS_SCONTO_DINAMICO_KW = 'gawdd_hasScontoDinamico.keyword',
  ID_COMUNICAZIONE_35 = 'idComunicazione35',
  ID_COMUNICAZIONE_37 = 'idComunicazione37',
  ID_COMUNICAZIONE_22 = 'idComunicazione22',
  ID_COMUNICAZIONE_21 = 'idComunicazione21',
  ID_COMUNICAZIONE_06 = 'idComunicazione06',
  ULTIMO_STATO_PA35 = 'ultimoStatoPa35',
  STATO_WEB_KW = 'statoWeb.keyword',
  ULTIMO_STATO_22 = 'ultimoStato22',
  ULTIMO_STATO_06 = 'ultimoStato06',
  ULTIMO_STATO_21 = 'ultimoStato21',
  ULTIMO_STATO_39 = 'ultimoStato39',
  HUBFE_CP_ESPORTATO = 'hubfe_cp_esportato',
  HUBFE_CP_ATTESAACCETTRIFIUTO = 'hubfe_cp_inAttesaAccettRifiuto',
  // TODO EVO IN CORSO CARD IN CONSERVAZIONE - KARANDIP SINGH MODIFICARE MEDATA ENUM
  ULTIMO_STATO_GEDCONS = 'test',

  HUBFE_IN_FIRMA = 'hubfe_inFirma',
  HUBFE_IN_ERRORE_PRE_ELAB = 'hubfe_inErrorePreElab',
  HUBFE_NON_CONSEGNATO_35 = 'hubfe_nonConsegnato35',

  SERIVCES = 'serviziConfigurati',
  DOCLABEL = 'docLabel',
  RAGIONE_SOCIALE = 'ragioneSociale',
  RAGIONE_SOCIALE_GROUP_10_KW = 'ragioneSocialeGroup10.keyword',
  SERVIZI_CONFIGURATI = 'serviziConfigurati',
  DATA_INSERIMENTO_21 = 'dataInserimento21',
  DATA_INSERIMENTO_39 = 'dataInserimento39',
  ID_SERIE_DOC = 'idSerieDoc',

  VALUTA_ISO = 'valutaIso',
  GAWCONS = 'gawcons',
}
