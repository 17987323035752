import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormValidationModule } from 'app/shared/components/form-validation/form-validation.module';
import { ReactiveFormValidationModule } from 'app/shared/components/form-validation/reactive-form/reactive-form-validation.module';
import { InvoiceModalComponent } from 'app/shared/components/invoice-layouts/invoice-modal/invoice-modal.component';
import { ModalSectionArrayComponent } from 'app/shared/components/invoice-layouts/invoice-modal/modal-section-array/modal-section-array.component';
import { ModalSectionComponent } from 'app/shared/components/invoice-layouts/invoice-modal/modal-section/modal-section.component';

@NgModule({
  declarations: [ModalSectionArrayComponent, InvoiceModalComponent, ModalSectionComponent],
  imports: [CommonModule, ReactiveFormsModule, ReactiveFormValidationModule, FormValidationModule],
  exports: [InvoiceModalComponent],
})
export class InvoiceLayoutsModule {}
