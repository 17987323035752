import { createFeatureSelector, createSelector } from '@ngrx/store';
import { documentNotesModuleFeatureKey, DocumentNotesModulesState, DocumentNotesState } from './document-notes.reducer';

export const selectDocumentNotesModuleState =
  createFeatureSelector<DocumentNotesModulesState>(documentNotesModuleFeatureKey);

export const selectDocumentNotesState = createSelector(
  selectDocumentNotesModuleState,
  (state: DocumentNotesModulesState) => state.document_notes,
);

export const getDocumentNotes = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => state.notes);

export const getNotesRequestParams = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => ({
  progSpool: state.progSpool,
  progBusta: state.progBusta,
  sorting: state.sorting,
}));

export const getDocumentId = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => ({
  progSpool: state.progSpool,
  progBusta: state.progBusta,
}));

export const getMode = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => ({ mode: state.mode }));

export const getSelectedNote = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => ({
  selectedNote: state.selectedNote,
}));

export const getSorting = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => ({
  sorting: state.sorting,
}));

/** Ottengo tutto lo state relativo alle note del documento. */
export const getState = createSelector(selectDocumentNotesState, (state: DocumentNotesState) => ({ state }));
