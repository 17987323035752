import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StatusHistory } from '@ctel/gaw-commons';
import { StatusHistoryHttpService } from './status-history-http.service';

@Injectable({
  providedIn: 'root',
})
export class StatusHistoryService implements OnDestroy {
  private statusHistory$ = new ReplaySubject<StatusHistory[]>(1);
  private statusHistoryError$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new ReplaySubject<void>(1);

  constructor(private historyHttpService: StatusHistoryHttpService) {}

  whenStatusHistory(progSpool: string, progBusta: string, idComunicazione: string) {
    return this.historyHttpService.whenStatusHistory(progSpool, progBusta, idComunicazione).pipe(
      catchError(() => {
        this.sendStatusHistoryError(true);
        return EMPTY;
      }),
      tap((statusHistory) => {
        this.sendStatusHistoryError(false);
        this.sendStatusHistory(statusHistory);
      }),
    );
  }

  sendStatusHistory(statusHistory: StatusHistory[]) {
    this.statusHistory$.next(statusHistory);
  }

  sendStatusHistoryError(value: boolean) {
    this.statusHistoryError$.next(value);
  }

  whenStatusHistoryError(): Observable<boolean> {
    return this.statusHistoryError$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
