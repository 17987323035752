import { createAction } from '@ngrx/store';
import { Note } from '../entities/note';
import { Sorting } from '../entities/sorting';

/*** AZIONI SULLA SINGOLA NOTA ***/

/** Aggiornamento della nota. L'azione richiede i seguenti parametri: progSpool, progBusta, noteId, message. */
export const updateNote = createAction(
  '[Document Notes Service] updateNote',
  (progSpool: number, progBusta: number, idSerieDoc: number, noteId: number, message: string) => ({
    payload: {
      progSpool,
      progBusta,
      idSerieDoc,
      message,
      noteId,
    },
  }),
);

/** Creazione di una nota. L'azione richiede i seguenti parametri: progSpool, progBusta, message. */
export const createNote = createAction(
  '[Document Notes Service] createNote',
  (progSpool: number, progBusta: number, idSerieDoc: number, message: string) => ({
    payload: {
      progSpool,
      progBusta,
      idSerieDoc,
      message,
    },
  }),
);

/** Eliminazione di una nota. L'azione richiede i seguenti parametri: nota, progSpool, progBusta. */
export const deleteNote = createAction(
  '[Document Notes Service] deleteNote',
  (note: Note, progSpool: number, progBusta: number, idSerieDoc: number) => ({
    payload: {
      note,
      progBusta,
      progSpool,
      idSerieDoc,
    },
  }),
);

/*** AZIONI CHE AGISCONO SULLO STATO ***/

/** Setta nello stato la lista delle note. */
export const setNotes = createAction('[Document Notes Service] setNotes', (notes: Note[]) => ({ payload: { notes } }));

/** Setta nello stato gli identificativi del documento. L'azione richiede: progSpool, progBusta. */
export const setDocumentId = createAction(
  '[Document Notes Service] setDocumentId',
  (progSpool: number, progBusta: number, idSerieDoc: number) => ({ payload: { progSpool, progBusta, idSerieDoc } }),
);

/** Setta nello stato la modalità. L'azione accetta un parmaetro di tipo: 'new', 'edit' o 'readonly'. */
export const setMode = createAction('[Document Notes Service] setMode', (mode: 'new' | 'edit' | 'readonly') => ({
  payload: { mode },
}));

/** Setta nello stato la nota selezionata. */
export const selectNote = createAction('[Document Notes Service] selectNote', (selectedNote: Note, userId: number) => ({
  payload: { selectedNote, userId },
}));

/** Setta nello stato l'ordinamento. Accetta un oggetto di tipo {@link Sorting}. */
export const setSorting = createAction('[Document Notes Service] setSorting', (sorting: Sorting) => ({
  payload: { sorting },
}));

/** Ripristina la modalità e la nota selezionata alle impostazioni iniziali. */
export const restoreTextAreaValue = createAction('[Document Notes Service] restoreTextAreaValue', () => ({
  payload: { mode: 'readonly', selectedNote: undefined },
}));

/*** AZIONI INTERMEDIE PER GLI EFFECT */

/** Quest'azione viene lanciata ogni volta che si ba il submit dela textArea. */
export const submitTextArea = createAction('[Document Notes Service] submitTextArea', (noteMsg: string) => ({
  payload: { noteMsg },
}));

/** Azione identificativa per eseguire la richiesta delle note. */
export const fetchDocumentNotes = createAction('[Document Notes Service] fetchDocumentNotes', () => null);
