import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { HistoryActionModalComponent } from './modals/history-action-modal.component/history-action-modal.component';
import { TipoDocumentoModalComponent } from './modals/tipo-documento-modal.component/tipo-documento-modal.component';

@NgModule({
  declarations: [HistoryActionModalComponent, TipoDocumentoModalComponent],
  imports: [
    CommonModule,
    DryComponentsModule,
    DisplayDocumentModule,
    PipesModule,
    ErrorPlaceholderModule,
    PlaceholderModule,
    TabsModule,
    DocumentsSearchModule,
  ],
  exports: [],
})
export class GawediCommonsModule {}
