import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, OnChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Copier } from 'app/core/common/utilities/copier';
import { Link, LinkSection } from 'app/shared/components/table-of-contents/link-sections';
import { NavigationFocusService } from 'app/shared/components/table-of-contents/navigation-focus/navigation-focus.service';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'gaw-toc',
  styleUrls: ['./table-of-contents.component.scss'],
  templateUrl: './table-of-contents.component.html',
})
export class TableOfContentsComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() container: string;
  @Input() linkSections: LinkSection[];
  @Input() parentLink: string;
  linkSectionsCopy: LinkSection[];
  links: Link[] = [];

  blueBorder = false;
  selectedIndex: number;
  rootUrl = this.router.url.split('/')[0];
  private scrollContainer: Element | Window;
  private urlFragment = '';
  private subscriptions = new Subscription();
  private scrollSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private navigationFocusService: NavigationFocusService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.subscriptions.add(
      this.navigationFocusService.navigationEndEvents.subscribe(() => {
        const rootUrl = router.url.split('/')[0];
        if (rootUrl !== this.rootUrl) this.rootUrl = rootUrl;
      }),
    );

    this.subscriptions.add(
      this.route.fragment.subscribe((fragment) => {
        this.urlFragment = fragment;

        const target = document.getElementById(this.urlFragment);
        if (target) target.scrollIntoView();
      }),
    );
  }

  ngAfterViewInit() {
    this.updateScrollPosition();
  }

  navigateTo(link: Link) {
    let obj: Link;

    this.linkSectionsCopy.forEach((value) => {
      value.links.forEach((val) => (val.active = false));
    });

    for (let i = 0; i < this.linkSectionsCopy.length; i++) {
      obj = this.linkSectionsCopy[i].links.find((x) => x.id === link.id);
      if (obj !== undefined) break;
    }

    this.linkSectionsCopy.forEach((value) => {
      const objToEdit = value.links.find((x) => x.id === link.id);
      value.links.forEach((val) => {
        if (val.id === objToEdit.id) val.active = true;
      });
    });
    this.router.navigate([this.parentLink + link.id]).then();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
    this.scrollSub?.unsubscribe();
  }

  updateScrollPosition(): void {
    const target = document.getElementById(this.urlFragment);
    if (target) target.scrollIntoView();
  }

  changeBorder(i: number, blueBorder: boolean) {
    this.blueBorder = blueBorder;
    this.selectedIndex = i;
  }

  ngOnChanges(): void {
    this.linkSectionsCopy = Copier.deepCopy(this.linkSections);
    // On init, the sidenav content element doesn't yet exist, so it's not possible
    // to subscribe to its scroll event until next tick (when it does exist).
    Promise.resolve().then(() => {
      this.scrollContainer = this.container ? this.document.querySelectorAll(this.container)[0] : window;

      if (this.scrollContainer) {
        this.scrollSub?.unsubscribe();
        this.scrollSub = fromEvent(this.scrollContainer, 'scroll')
          .pipe(debounceTime(10))
          .subscribe(() => this.onScroll());
      }
    });
  }

  // resetHeaders() {
  // 	this.linkSections = [];
  // 	this.links = [];
  // }
  //
  // addHeaders(sectionName: string, docViewerContent: HTMLElement, sectionIndex = 0) {
  // 	const headers = Array.from<HTMLHeadingElement>(docViewerContent.querySelectorAll('h3, h4'));
  // 	const links: Link[] = [];
  // 	headers.forEach((header) => {
  // 		// remove the 'link' icon name from the inner text
  // 		const name = header.innerText.trim().replace(/^link/, '');
  // 		const { top } = header.getBoundingClientRect();
  // 		links.push({
  // 			name,
  // 			type: header.tagName.toLowerCase(),
  // 			top: top,
  // 			id: header.id,
  // 			active: false
  // 		});
  // 	});
  // 	this.linkSections[sectionIndex] = { name: sectionName, links };
  // 	this.links.push(...links);
  // }

  /** Gets the scroll offset of the scroll container */
  private getScrollOffset(): number {
    const { top } = this.elementRef.nativeElement.getBoundingClientRect();
    if (this.scrollContainer instanceof Element) return this.scrollContainer.scrollTop + top;
    else if (this.scrollContainer instanceof Window) return this.scrollContainer.scrollY + top;
  }

  private onScroll(): void {
    for (let i = 0; i < this.links.length; i++)
      this.links[i].active = this.isLinkActive(this.links[i], this.links[i + 1]);
  }

  private isLinkActive(currentLink: Link, nextLink: Link): boolean {
    // A link is considered active if the page is scrolled passed the anchor without also
    // being scrolled passed the next link
    const scrollOffset = this.getScrollOffset();
    return scrollOffset >= currentLink.top && !(nextLink && nextLink.top < scrollOffset);
  }
}
