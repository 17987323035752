import { Injectable } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Service } from '../../../../entities/services/service.interface';
import { EnabledService, EnabledServiceEndpoint } from './enabled-service';
import { EnabledServicesHttpService } from './enabled-services-http.service';
@Injectable({
  providedIn: 'root',
})
export class EnabledServicesService {
  private enabledServices$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private serviceConfiguration$: BehaviorSubject<Service[]> = new BehaviorSubject<Service[]>([]);

  constructor(
    private companiesService: CompaniesService,
    private enabledServicesHttpService: EnabledServicesHttpService,
    private logger: NGXLogger,
  ) {
    this.initServiceConfiguration();
  }

  initServiceConfiguration() {
    this.enabledServicesHttpService.getServiceConfiguration().subscribe((ss) => {
      this.serviceConfiguration$.next(ss);
    });
  }

  getEnabledService(): Observable<EnabledService[]> {
    return this.companiesService.whenCurrentCompany().pipe(
      switchMap((companyInfo) =>
        this.enabledServicesHttpService.getHttpEnabledService(companyInfo.licenseId, companyInfo.siaCode).pipe(
          switchMap((enabledServicesFromRemote: EnabledServiceEndpoint[]) => {
            const servicesUseful: EnabledService[] = [];
            enabledServicesFromRemote.forEach((service) => {
              servicesUseful.push(
                new EnabledService(service.fL_ABILITATO, service.sectioN_CODE, service.serviziO_ID, service.uI_CODE),
              );
            });

            const enabledServices: string[] = enabledServicesFromRemote.map(({ serviziO_ID }) => serviziO_ID);
            this.enabledServices$.next(enabledServices);

            return of(servicesUseful);
          }),
          catchError((err: unknown) => {
            this.logger.error('Errore durante il caricamento dei servizi abilitati ', err);
            this.enabledServices$.next([]);
            return of([]);
          }),
        ),
      ),
    );
  }

  isEnabledService(serviziO_ID: string): Observable<EnabledService> {
    return this.getEnabledService().pipe(map((services) => services.find((s) => s.serviziO_ID === serviziO_ID)));
  }

  whenEnabledServices(): Observable<string[]> {
    return this.enabledServices$.asObservable();
  }

  whenServiceConfiguration(): Observable<Service[]> {
    return this.serviceConfiguration$.asObservable();
  }

  /**
   * Metodo che serve a filtrare i servizi configurati per un documento mettendo enabled a false per i servizi che non sono configurati per l'utente
   * Ordina in base a ordinamento definito a progetto.
   * Richiamato dal EnabledServicesFilterPipe
   *
   * serviziUtente è l'array dei servizi configurati per l'UTENTE
   * serviziDocumento è l'array dei servizi configurati per il DOCUMENTO
   */
  filteredServices(serviziDocumento?: Service[]): Observable<Service[]> {
    const serviceConfiguration$ = this.whenServiceConfiguration();
    const enabledServices$ = this.whenEnabledServices();

    return combineLatest([serviceConfiguration$, enabledServices$]).pipe(
      map((combined) => {
        const serviceConfiguration = combined[0];
        const serviziUtente = combined[1];

        // metto enabled a true se l'utente è abilitato a vedere quel servizio
        serviziDocumento = serviziDocumento.map((sd) => {
          if (sd.code === 'GEDPEC') sd.enabled = serviziUtente.includes('GAWPEC');
          else if (sd.code === 'GEDPOST') sd.enabled = serviziUtente.includes('GAWPOST');
          else if (sd.code === 'GEDEDI') sd.enabled = serviziUtente.includes('GAWEDI');
          else if (sd.code === 'ArchivioLotti') sd.enabled = true;
          else sd.enabled = serviziUtente.includes(sd.code);
          return sd;
        });

        // faccio merge dei servizi del documento con la configurazione dei servizi contenente l'attributo order
        serviziDocumento = serviziDocumento.map((item) => {
          const obj = Object.assign(
            {},
            item,
            serviceConfiguration.find((sc) => sc.code === item.code),
          );
          if (!obj.order) obj.order = 999;
          return obj;
        });

        // ordino i servizi in base all'attributo order
        const ordered = serviziDocumento.sort((a, b) => (a.order && b.order && a.order > b.order ? 1 : -1));

        return ordered;
      }),
    );
  }
}
