import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// REMEMBER ME
// import { AppCommonModule } from 'app/core/common/app-common.module';
// import { CoreCommonModule } from 'app/core/common/core-common.module';
import { PaginationComponent } from './pagination.component';
import { PaginationModule as NgxPaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    // AppCommonModule,
    // CoreCommonModule,
    CommonModule,
    FormsModule,
    NgxPaginationModule.forRoot(),
  ],
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
})
export class PaginationModule {}
