import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class MultiActionsCatalog {
  public readonly restoreFromTrash = new ActionBuilder(MultiActionCode.restoreFromTrash, ActionCode.RESTORE_FROM_TRASH)
    .setActionURL('/Trash/command/RestoreFromTrash')
    .build();

  public readonly deleteFromTrash = new ActionBuilder(MultiActionCode.deleteFromTrash, ActionCode.DELETE_FROM_TRASH)
    .setActionURL('/Trash/command/DeleteFromTrash')
    .build();
}

export enum MultiActionCode {
  restoreFromTrash = 'restoreFromTrash',
  deleteFromTrash = 'deleteFromTrash',
}
