import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { ReplaySubject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GawbiHttpService implements OnDestroy {
  private powerBiHost: string;
  private cookieDomain: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(private configService: ConfigService) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  httpHostInit(appConfig: AppConfig) {
    this.powerBiHost = appConfig.powerBi.http.host;
    this.cookieDomain = appConfig.powerBiCookieDomain;
  }

  getEmbedUrl(siaCode: string) {
    const url = `${this.powerBiHost}/powerbi/GAWBI/CicloAttivo?rs:embed=true&filter=Fatture/CODSIA eq '${siaCode}'`;
    return url.toString();
  }

  getCookieDomain() {
    return this.cookieDomain;
  }
}
