import { Injectable } from '@angular/core';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly massiveActions: ActionObject[] = [
    this.massiveActionsCatalog.exportExcelMassive,
    this.massiveActionsCatalog.cancelDocumentMassive,
    this.massiveActionsCatalog.processDocumentMassive,
  ];

  public readonly multiActions: ActionObject[] = [
    this.multiActionsCatalog.exportExcelMultiple,
    this.multiActionsCatalog.cancelDocumentMultiple,
    this.multiActionsCatalog.processDocumentMultiple,
  ];

  public readonly singleActions: ActionObject[] = [
    this.singleActionsCatalog.checkAsDeleted,
    this.singleActionsCatalog.openDetail,
    this.singleActionsCatalog.cancelStateSelection,
    this.singleActionsCatalog.printBarcode,
    this.singleActionsCatalog.editBox,
    this.singleActionsCatalog.checkAsReceived,
    this.singleActionsCatalog.checkAsForwarded,
    this.singleActionsCatalog.spoolHistory,
    this.singleActionsCatalog.checkAsRejected,
    this.singleActionsCatalog.openAnomalyDetail,
    this.singleActionsCatalog.openBoxDetail,
    this.singleActionsCatalog.anomalyHistory,
    this.singleActionsCatalog.pdfDownload,
    this.singleActionsCatalog.editAnomaly,
    this.singleActionsCatalog.uploadDocument,
    this.singleActionsCatalog.cancelDocument,
    this.singleActionsCatalog.processDocument,
  ];

  constructor(
    private massiveActionsCatalog: MassiveActionsCatalog,
    private multiActionsCatalog: MultiActionsCatalog,
    private singleActionsCatalog: SingleActionsCatalog,
  ) {}
}
