<div class="d-flex justify-content-center">
  <pagination
    [(ngModel)]="actualPage"
    [totalItems]="totalItems"
    [itemsPerPage]="itemsPerPage"
    [maxSize]="7"
    [boundaryLinks]="true"
    (pageChanged)="changePage($event)"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>
