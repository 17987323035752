import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'dry-code-markup-block',
  templateUrl: './code-markup-block.component.html',
  styleUrls: ['./code-markup-block.component.scss'],
})
export class CodeMarkupBlockComponent {
  @Input() public asyncCode$?: Observable<unknown>;
  @Input() public code?: string;
  @Input() public language? = 'language-javascript';
}
