import { Injectable } from '@angular/core';
import { DocSeriesColor } from '@ctel/gaw-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { EnabledServicesService } from 'app/core/business/user/enabled-services/enabled-services.service';
import { Color, ColorList } from 'app/core/common/utilities/color-list';
import { Sections, SingleSection } from 'app/entities/sections/sections';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentsHttpService } from '../documents-http.service';

/**
 * Servizio che gestisce lo stato delle serie documentali
 * (tutto l'array di serie documentali, l'id della serie documantale "All",
 * le serie documentali preferite, le serie documentali "Altri"
 * e la serie documentale corrente).
 * La serie documentale "All" viene utilizzata come serie di default
 * ed è sempre la prima dell'array di SingleSection "visible"
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsSeriesService {
  private destroy$ = new Subject<void>();

  // Array di tutte le serie documentali
  private documentSeriesList$ = new ReplaySubject<SingleSection[]>(1);
  private visibleDocumentSeriesList$ = new ReplaySubject<SingleSection[]>(1);
  private otherDocumentSeriesList$ = new ReplaySubject<SingleSection[]>(1);
  private documentSeriesColors$ = new ReplaySubject<DocSeriesColor[]>(1);

  // Serie documentale attuale
  private currentDocumentSeries$ = new ReplaySubject<SingleSection>(1);
  // serie documentale "All"
  private allSections$ = new BehaviorSubject<unknown>(null);
  private allDocSeries$ = new BehaviorSubject<SingleSection>(null);
  private selectedDocSeries$ = new BehaviorSubject<string>('');
  private docSeriesLabel$ = new BehaviorSubject<string>('');
  private otherDocSeriesLabel$ = new BehaviorSubject<string>('Altri documenti');

  private licenseId: string;
  private siaCode: string;
  private colorList: Color[];
  private usedColors: string[] = [];

  constructor(
    private documentsHttpService: DocumentsHttpService,
    private companiesService: CompaniesService,
    private enabledServicesService: EnabledServicesService,
  ) {
    // prendo tutti i tipi documento (visible e other) e aggiorno i relativi observables
    // il primo id dell'array visible corrisponde alla serie documentale "All"
    this.enabledServicesService
      .whenEnabledServices()
      .pipe(
        switchMap(() => this.companiesService.whenCurrentCompany()),
        // catchError((err) => {
        // 	if (err.type === ErrorTypes.HTTP_UNAUTHORIZED) {
        // 		//this.router.navigate(['/unauthorized']).then();
        // 	}
        // 	return EMPTY;
        // }),
        tap((company) => {
          this.licenseId = company.licenseId;
          this.siaCode = company.siaCode;
        }),
        // chiediamo il filtro iniziale da impostare in base alla docSeriesId corrente
        switchMap(() => this.documentsHttpService.whenSections(this.licenseId, this.siaCode)),
        takeUntil(this.destroy$),
      )
      .subscribe((docTypes) => {
        const allSectionIndex = docTypes.sections.findIndex((x) => x.sectionCode === 'ALL');
        // Sposto la sezione "ALL" come primo elemento dell'array
        if (allSectionIndex >= 0) {
          docTypes.sections.unshift(docTypes.sections[allSectionIndex]);
          docTypes.sections.splice(allSectionIndex + 1, 1);
        }
        this.sendAllDocSeries(docTypes[0]);
        this.sendDocumentsSeries(docTypes.sections);
        const visibleSections: SingleSection[] = [];
        const otherSections: SingleSection[] = [];
        docTypes.sections.forEach((section) => {
          // workaround per nascondere sezione ALL
          if (section.sectionCode !== 'ALL')
            if (section.visible) visibleSections.push(section);
            else otherSections.push(section);
        });
        this.sendDocumentSeriesColors(visibleSections, otherSections);
        this.sendVisibleDocumentsSeries(visibleSections);
        this.sendOtherDocumentsSeries(otherSections);
      });
  }

  orderSection(docTypes: Sections) {
    docTypes.sections.findIndex((x) => x.sectionCode === 'ALL');
  }

  // ASSEGNO I COLORI ALLE SERIE DOCUMENTALI
  sendDocumentSeriesColors(docTypes: SingleSection[], otherDocTypes: SingleSection[]) {
    this.colorList = ColorList.getColorList();
    let newColorList = [];
    const docSeriesColors = [];
    let i = Math.ceil(docTypes.length / this.colorList.length);
    while (i >= 1) {
      newColorList = newColorList.concat(this.colorList);
      i--;
    }
    for (i = 0; i < docTypes.length; i++) {
      const obj = Object.assign(docTypes[i], newColorList[i]);
      delete obj.filter;
      docSeriesColors.push(obj);
    }
    for (i = 0; i < otherDocTypes.length; i++) {
      const obj = Object.assign(otherDocTypes[i], { name: 'grey', hex: 'dimgrey' });
      docSeriesColors.push(obj);
    }

    // workaraound temporaneo per gestire eventuali serie documentali con label uguale (le doppie vengono rimosse)
    const docSeriesColorsReduced = docSeriesColors.filter(
      (obj, pos, arr) => arr.map((mapObj) => mapObj.description).indexOf(obj.description) === pos,
    );

    // metodo alternativo per rimuovere le label doppie
    // // se la next label non c'è nell'array reduced di oggetti allora la pusho
    // const docSeriesColorsReduced = docSeriesColors.reduce(function (p, c) {
    // 	if (!p.some(function (el) { return el.description === c.description; })) { p.push(c); }
    // 	return p;
    // }, []);

    this.documentSeriesColors$.next(docSeriesColorsReduced);
  }

  whenDocumentsSeriesColors(): Observable<DocSeriesColor[]> {
    return this.documentSeriesColors$.asObservable();
  }

  // TUTTE LE SERIE DOC
  sendDocumentsSeries(docSeries: SingleSection[]) {
    this.documentSeriesList$.next(docSeries);
  }

  whenDocumentsSeries(): Observable<SingleSection[]> {
    return this.documentSeriesList$.asObservable();
  }

  // TUTTE LE SERIE DOC PREFERITE
  sendVisibleDocumentsSeries(docSeries: SingleSection[]) {
    this.visibleDocumentSeriesList$.next(docSeries);
  }

  whenVisibleDocumentsSeries(): Observable<SingleSection[]> {
    return this.visibleDocumentSeriesList$.asObservable();
  }

  // TUTTE LE SERIE DOC "ALTRI"
  sendOtherDocumentsSeries(docSeries: SingleSection[]) {
    this.otherDocumentSeriesList$.next(docSeries);
  }

  whenOtherDocumentsSeries(): Observable<SingleSection[]> {
    return this.otherDocumentSeriesList$.asObservable();
  }

  // SERIE DOC ATTUALE
  sendCurrentDocumentsSeries(docSeries: SingleSection) {
    this.currentDocumentSeries$.next(docSeries);
  }

  whenCurrentDocumentsSeries(): Observable<SingleSection> {
    return this.currentDocumentSeries$.asObservable();
  }

  updateCurrentDocumentsSeries(docSeriesId: string) {
    let visibleOrOther: SingleSection;
    // Aggiorno la current docSeries prendendo nell'elenco di docSeries disponibili la prima con l'id uguale a quello in ingresso
    this.whenDocumentsSeries()
      .pipe(take(1))
      .subscribe((docSeriesList) => {
        if (docSeriesId === 'gcons_archivioFiscalePdv') docSeriesId = 'gcons_pacchettiDiVersamento';

        if (docSeriesId === 'gcons_firmaRemotaDocumenti') docSeriesId = 'gcons_firmaRemota';

        const documentSeries = docSeriesList.find((docSeries) => docSeries.sectionCode === docSeriesId);
        if (documentSeries !== undefined) {
          this.sendCurrentDocumentsSeries(documentSeries);
          if (documentSeries.description) this.sendVisibleDocSeriesLabel(documentSeries.description);
        }
      });

    // capisco se la serie attuale è visible o other (serve per avere la label in caso di F5 sulla pagina documenti)
    this.whenVisibleDocumentsSeries()
      .pipe(take(1))
      .subscribe((docSeriesList) => {
        visibleOrOther = docSeriesList.find((docSeries) => docSeries.sectionCode === docSeriesId);
        if (visibleOrOther !== undefined && visibleOrOther.description)
          this.sendVisibleDocSeriesLabel(visibleOrOther.description);
      });
  }

  // SERIE DOCUMENTALE "ALL"
  whenDefaultDocumentSeries(): Observable<SingleSection> {
    return this.whenDocumentsSeries().pipe(map((documentSeries) => documentSeries[0]));
  }

  // SERIE DOCUMANTALE "ALL"
  getAllDocSeries(): SingleSection {
    return this.allDocSeries$.value;
  }

  whenAllDocSeriesId(): Observable<string> {
    return this.whenDocumentsSeries().pipe(map((documentSeries) => documentSeries[0]?.sectionCode));
  }

  whenSections(): Observable<unknown> {
    return this.allSections$.asObservable();
  }

  sendAllDocSeries(docSeries: SingleSection): void {
    this.allDocSeries$.next(docSeries);
  }

  // SELECTED DOC SERIES LABEL
  getSelectedDocSeries() {
    return this.selectedDocSeries$.value;
  }

  whenSelectedDocSeries(): Observable<string> {
    return this.selectedDocSeries$.asObservable();
  }

  sendVisibleDocSeriesLabel(label: string): void {
    this.docSeriesLabel$.next(label);
    this.selectedDocSeries$.next(label);
  }

  resetSelectedDocSeries(): void {
    this.selectedDocSeries$.next('');
  }

  // SELECTED DOC SERIES LABEL
  getDocSeriesLabel() {
    return this.docSeriesLabel$.value;
  }

  whenOtherDocSeriesLabel(): Observable<string> {
    return this.otherDocSeriesLabel$.asObservable();
  }

  sendOtherDocSeriesLabel(label: string): void {
    this.docSeriesLabel$.next(label);
    this.otherDocSeriesLabel$.next(label);
  }

  resetOtherDocSeriesLabel(): void {
    this.otherDocSeriesLabel$.next('Altri documenti');
  }

  getColor(descTipoDocumento: string): string {
    let index = this.usedColors.indexOf(descTipoDocumento);
    if (index === -1) {
      this.usedColors.push(descTipoDocumento);
      index = this.usedColors.length;
    }
    return this.colorList[index].hex;
  }
}
