import { Injectable } from '@angular/core';
import { NotificationEventTypes } from 'app/core/common/notification/notification-event-types';
import { EventData } from '../event/event-data';
import { EventService } from '../event/event.service';
import { ModalInfo } from './modal/modal-info';
import { ModalType } from './modal/modal-type.enum';
import { NotificationType } from './notification-type';
import { SnackbarAction } from './snackbar/snackbar-action';
import { SnackbarInfo } from './snackbar/snackbar-info';
import { SweetAlertInfo } from './sweet-alert/sweet-alert-info';
import { ToastInfo } from './toast/toast-info';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private eventService: EventService) {}

  public closeSweetAlert(): void {
    this.eventService.emit(NotificationEventTypes.E_ALERT_SWEET_CLOSE, null);
  }

  public showSweetAlert(
    type: NotificationType,
    title: string,
    message: string,
    confirmCallback?: () => void,
    cancelCallback?: () => void,
    allowOutsideClick = true,
  ): void {
    const data = new EventData(
      this,
      new SweetAlertInfo(type, title, message, confirmCallback, cancelCallback, allowOutsideClick),
    );
    this.eventService.emit(NotificationEventTypes.E_ALERT_SWEET, data);
  }

  public showToast(type: NotificationType, title: string, message: string, onTapCallback?: () => void): void {
    const data = new EventData(this, new ToastInfo(type, title, message, onTapCallback));
    this.eventService.emit(NotificationEventTypes.E_ALERT_TOAST, data);
  }

  public showModal(type: NotificationType, content: any): void {
    let modalType: ModalType;
    switch (type) {
      case NotificationType.ERROR:
      case NotificationType.LARGE:
        if (content.disableClickOutside === true) modalType = ModalType.SIMPLE_LARGE_DISABLED_CLICK;
        else modalType = ModalType.SIMPLE_LARGE;
        break;

      case NotificationType.CUSTOM:
        if (content.disableClickOutside === true) modalType = ModalType.SIMPLE_LARGE_DISABLED_CLICK;
        else modalType = ModalType.SIMPLE_LARGE;
        break;

      case NotificationType.EXTRA_LARGE:
        if (content.disableClickOutside === true) modalType = ModalType.EXTRA_LARGE_DISABLED_CLICK;
        else modalType = ModalType.EXTRA_LARGE;
        break;

      case NotificationType.XXL:
        modalType = ModalType.XXL;
        break;
      default:
        if (content.disableClickOutside === true) modalType = ModalType.SIMPLE_DISABLED_CLICK;
        else modalType = ModalType.SIMPLE;
        break;
    }

    const data = new EventData(this, new ModalInfo(modalType, content));
    this.eventService.emit(NotificationEventTypes.E_MODAL, data);
  }

  public showSnackbar(type: NotificationType, message: string, action?: SnackbarAction, clearBeforeShow = false): void {
    const data = new EventData(this, new SnackbarInfo(type, message, action, clearBeforeShow));
    this.eventService.emit(NotificationEventTypes.E_SNACKBAR, data);
  }

  public clearSnackbar() {
    this.eventService.emit(NotificationEventTypes.E_SNACKBAR_CLOSE, null);
  }
}
