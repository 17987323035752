import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hub-documents-list-placeholder',
  templateUrl: './documents-list-placeholder.component.html',
  styleUrls: ['./documents-list-placeholder.component.scss'],
})
export class DocumentsListPlaceholderComponent implements OnInit {
  public random;

  ngOnInit() {
    this.random = Math.random() * (1 - 0.7) + 0.7;
  }
}
