<input
  class="form-check-input"
  type="checkbox"
  name="{{ name }}"
  [id]="id"
  [ngClass]="{
    valid: checkboxInputForm.value && checkboxInputForm.valid && required,
  }"
  #checkboxInputForm="ngModel"
  [(ngModel)]="value"
  (ngModelChange)="onChangeUpdate()"
  [ngModelOptions]="ngModelOptions"
  [popover]="InvalidFormMessage"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()"
  triggers=""
  [required]="required"
  isBoolean
  [composeValidators]="_validators"
  [composeAsyncValidators]="_asyncValidators"
/>
<ng-template #InvalidFormMessage>
  <ul *ngIf="checkboxInputForm.invalid">
    <ng-container *ngFor="let error of getErrorMessage(checkboxInputForm)">
      <li>
        {{ error }}
      </li>
    </ng-container>
  </ul>
</ng-template>
