import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { NGXLogger } from 'ngx-logger';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { EcmDocumentHierarchy, EcmDossierHierarchy, XmlPaAttachmentsListResponse } from '../entities/ecm/ecm';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai workflow
 */
@Injectable({
  providedIn: 'root',
})
export class EcmHttpService implements OnDestroy {
  private jsonContentType = 'application/json';

  private ecmHost: string;
  private docDetailsHost: string;

  private documentHierarchy: string;
  private dossierHierarchy: string;
  private documentDownload: string;
  private attachmentDownload: string;
  private attachmentDelete: string;

  private xmlPaAttachmentsList: string;
  private xmlPaAttachmentDownload: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private logger: NGXLogger,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.ecmHost = appConfig.ecm.http.host;

    this.documentHierarchy = `${this.ecmHost}/document/{documentId}/getHierarchy`;
    this.dossierHierarchy = `${this.ecmHost}/dossier/{dossierId}/getHierarchy`;
    this.documentDownload = `${this.ecmHost}/document/{documentId}/file/download?docSeriesId={docSeriesId}&download=false`;
    this.attachmentDownload = `${this.ecmHost}/attachment/{attachmentId}/file/download?download=false`;
    this.attachmentDelete = `${this.ecmHost}/attachment/delete`;

    this.docDetailsHost = appConfig.docDetails.http.host;
    this.xmlPaAttachmentsList = `${this.docDetailsHost}/v1/Document/GEDPASSJOIN/Attachments?progSpool={progSpool}&progBusta={progBusta}`;
    this.xmlPaAttachmentDownload =
      `${this.docDetailsHost}/v1/Document/GEDPASSJOIN/AttachmentDownload?progSpool={progSpool}` +
      '&progBusta={progBusta}&idAllegato={idAllegato}' +
      '&nomeAllegato={nomeAllegato}&mimeType={mimeType}&download={download}';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // getWorkflowHost(): string {
  // 	return this.workflowHost;
  // }

  /**
   * Ritorna la gerarchia del documento dato il documentId
   * @param documentId
   * @return Observable<EcmDocumentHierarchy>
   */
  whenDocumentHierarchy(documentId: string): Observable<EcmDocumentHierarchy> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    const url = this.documentHierarchy.replace('{documentId}', documentId);
    return this.http.get<EcmDocumentHierarchy>(url, options);
  }

  whenDossierHierarchy(dossierId: string): Observable<EcmDossierHierarchy> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    const url = this.dossierHierarchy.replace('{dossierId}', dossierId);
    return this.http.get<EcmDossierHierarchy>(url, options);
  }

  /**
   * Ottiene il blob del file dato documentId e docSeriesId
   *
   * @param documentId
   * @param docSeriesId
   * @return Observable<Blob>
   */
  whenDocumentPdf(documentId: string, docSeriesId: string): Observable<Blob> {
    const options = this.getHttpOptions(this.jsonContentType, 'blob');
    const url = this.documentDownload.replace('{documentId}', documentId).replace('{docSeriesId}', docSeriesId);
    return this.http.get<Blob>(url, options);
  }

  /**
   * Ottiene il contenuto di un allegato PDF al documento.
   * @param attachmentId
   * @return Observable<Blob>
   */
  whenAttachmentPDFFile(attachmentId: string): Observable<Blob> {
    const url = this.attachmentDownload.replace('{attachmentId}', attachmentId);
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  /**
   * Ottiene il contenuto di un allegato al documento.
   * @param attachmentId
   * @return Observable<Blob>
   */
  whenAttachmentTextFile(attachmentId: string): Observable<string> {
    const url = this.attachmentDownload.replace('{attachmentId}', attachmentId);
    return this.http.get<string>(url, {
      responseType: 'text' as 'json',
    });
  }

  whenDeleteAttachment(attachmentId: string): Observable<void> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    const url = this.attachmentDelete;
    const body = '{ "attachmentId":"' + attachmentId + '" }';
    return this.http.post<void>(url, body, options);
  }

  // lista di allegati xml pa per il wf
  whenXmlPaAttachmentsList(progSpool: string, progBusta: string): Observable<XmlPaAttachmentsListResponse> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    const url = this.xmlPaAttachmentsList.replace('{progSpool}', progSpool).replace('{progBusta}', progBusta);
    return this.http.get<XmlPaAttachmentsListResponse>(url, options).pipe(
      catchError((err: unknown) => {
        if (err && err['cause'] && err['cause']['status'] === 404) {
          this.logger.error('whenXmlPaAttachmentsList', err['cause']['error']);
          return of({ attachments: [] });
        } else throw err;
      }),
    );
  }

  /**
   * @param progSpool Progspool del documento
   * @param progBusta ProgBusta del documento
   * @param attachmentId Id dell'allegato
   * @param attachmentName Nome dell'allegato
   * @param mimeType MimeType dell'allegato
   * @param download
   * @return Observable<Blob>
   */
  whenXmlPaAttachmentDownload(
    progSpool: string,
    progBusta: string,
    attachmentId: number,
    attachmentName: string,
    mimeType: string,
    download: boolean,
  ): Observable<Blob> {
    const url = this.xmlPaAttachmentDownload
      .replace('{progSpool}', progSpool)
      .replace('{progBusta}', progBusta)
      .replace('{idAllegato}', attachmentId + '')
      .replace('{nomeAllegato}', attachmentName)
      .replace('{mimeType}', mimeType)
      .replace('{download}', download + '');

    let responseType;

    switch (mimeType) {
      case 'text/plain':
      case 'application/octet-stream':
      case 'application/xml':
        responseType = 'text';
        break;
      default:
        responseType = 'blob';
        break;
    }

    return this.http.get<Blob>(url, {
      responseType,
    });
  }

  // -----------------------------------------------------------------------------------------------------------------------
  private getHttpOptions(contentType: string, responseType: string): object {
    const headers = new HttpHeaders().set('Content-Type', contentType);
    let options;
    switch (responseType) {
      case 'blob':
        options = {
          headers,
          responseType: 'blob' as const,
        };
        break;
      case 'json':
        options = {
          headers,
          responseType: 'json' as const,
        };
        break;
      case 'html':
        options = {
          headers,
          responseType: 'html' as const,
        };
        break;
      default:
        options = {
          headers,
          responseType: 'json' as const,
        };
        break;
    }
    return options;
  }
}
