import { Observable } from 'rxjs';
import { DocumentAttachmentType } from './document-attachment-type';

export abstract class DocumentAttachment<T> {
  public name: string;
  public contentUrl: string;
  public content: Observable<T>;

  abstract type: DocumentAttachmentType;

  public constructor(name: string, content: Observable<T>, contentUrl?: string) {
    this.name = name;
    this.contentUrl = contentUrl;
    this.content = content;
  }
}
