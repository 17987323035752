import { Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

const datiCassa = [
  { value: 'TC01', label: 'Cassa Nazionale Previdenza e Assistenza Avvocati e Procuratori legali' },
  { value: 'TC02', label: 'Cassa Previdenza Dottori Commercialisti' },
  { value: 'TC03', label: 'Cassa Previdenza e Assistenza Geometri' },
  { value: 'TC04', label: 'Cassa Nazionale Previdenza e Assistenza Ingegneri e Architetti liberi profess.' },
  { value: 'TC05', label: 'Cassa Nazionale del Notariato' },
  { value: 'TC06', label: 'Cassa Nazionale Previdenza e Assistenza Ragionieri e Periti commerciali' },
  { value: 'TC07', label: 'Ente Nazionale Assistenza Agenti e Rappresentanti di Commercio (ENASARCO)' },
  { value: 'TC08', label: 'Ente Nazionale Previdenza e Assistenza Consulenti del Lavoro (ENPACL)' },
  { value: 'TC09', label: 'Ente Nazionale Previdenza e Assistenza Medici (ENPAM)' },
  { value: 'TC10', label: 'Ente Nazionale Previdenza e Assistenza Farmacisti (ENPAF)' },
  { value: 'TC11', label: 'Ente Nazionale Previdenza e Assistenza Veterinari (ENPAV)' },
  { value: 'TC12', label: 'Ente Nazionale Previdenza e Assistenza Impiegati dellAgricoltura (ENPAIA)' },
  { value: 'TC13', label: 'Fondo Previdenza Impiegati Imprese di Spedizione e Agenzie Marittime)' },
  { value: 'TC14', label: 'Istituto Nazionale Previdenza Giornalisti Italiani (INPGI)' },
  { value: 'TC15', label: 'Opera Nazionale Assistenza Orfani Sanitari Italiani (ONAOSI)' },
  { value: 'TC16', label: 'Cassa Autonoma Assistenza Integrativa Giornalisti Italiani (CASAGIT)' },
  { value: 'TC17', label: 'Ente Previdenza Periti Industriali e Periti Industriali Laureati (EPPI)' },
  { value: 'TC18', label: 'Ente Previdenza e Assistenza Pluricategoriale (EPAP)' },
  { value: 'TC19', label: 'Ente Nazionale Previdenza e Assistenza Biologi (ENPAB)' },
  { value: 'TC20', label: 'Ente Nazionale Previdenza e Assistenza Professione Infermieristica (ENPAPI)' },
  { value: 'TC21', label: 'Ente Nazionale Previdenza e Assistenza Psicologi (ENPAP)' },
  { value: 'TC22', label: 'INPS' },
];

@Injectable()
export class DatiCassaService {
  public getList(): InvoiceMultiValues[] {
    return datiCassa.slice();
  }
}
