import { Component, Input } from '@angular/core';
import { UploadResults } from '../../../../entities/uploads-notification/uploads-notification';
import { CompaniesService } from '../../../../core/business/companies/companies.service';
import { retry, take } from 'rxjs/operators';
import { Company } from '../../../../entities/companies/company';

@Component({
  selector: 'gaw-upload-notifications',
  templateUrl: './upload-notifications.component.html',
  styleUrls: ['./upload-notifications.component.scss'],
})
export class UploadNotificationsComponent {
  @Input() uploadNotifications: UploadResults[];
  private companies: Company[];

  constructor(private companiesService: CompaniesService) {
    companiesService
      .whenCompanies()
      .pipe(retry(3), take(1))
      .subscribe((result) => (this.companies = result));
  }

  getCompanyName(licenseId: string, siaCode: string) {
    return this.companies.find((c) => c.licenseId === licenseId && c.siaCode === siaCode)?.companyDescription;
  }
}
