import { Injectable } from '@angular/core';
import {
  AbstractDocumentEffects,
  DocumentActions,
  DocumentState,
  FilterActions,
  getFiltersStateWithUserValues,
  getRouterInfo,
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { ActionsConfigurationService } from 'app/core/common/ui-configuration/actions-configuration/actions-configuration.service';
import { ActionsService2 } from 'app/modules/homepage/core/documents-search/actions/actions.service';
import { DocumentsHttpService } from 'app/modules/homepage/core/documents-search/documents-http.service';
import { EMPTY, combineLatest, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SearchContextHttpService } from '../../../search-context/search-context-http.service';
import { SearchContextService } from '../../../search-context/search-context.service';
import { DocumentsService2 } from '../documents.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentEffects extends AbstractDocumentEffects {
  /**
   * Effetto che gestisce il side effect di aver ricevuto i nuovi documenti,
   * e per i quali è necessario eventualmente raffinare i filtri.
   */
  onDocumentsFetched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.documentsFetched),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([action, routerInfo]) => !action.payload.chunked && routerInfo.state.url.indexOf('gaw/') !== -1),
      map(([{ payload }]) => FilterActions.refineFiltersRequested({ filters: payload.filters })),
    ),
  );

  /**
   * Effetto che gestisce il side effect di aver richiesto nuovi documenti.
   */

  onFetchDocumentsRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.fetchDocuments),
      mergeMap((payload) =>
        of(payload).pipe(
          withLatestFrom(
            this.store.pipe(
              select(
                getFiltersStateWithUserValues({
                  fromFavourites: payload.payload.fromFavourites,
                  technicalFilters: [],
                }),
              ),
            ),
            this.store.pipe(select(getRouterInfo)),
          ),
        ),
      ),
      filter(([, filterState, routerInfo]) => !filterState.requested && routerInfo.state.url.indexOf('gaw/') !== -1),
      tap(() => this.documentsService.setLoadingDocs(true)),
      switchMap(([, filterState, routerInfo]) =>
        combineLatest([
          of(filterState),
          this.searchContextHttpService
            .getSearchContext(
              routerInfo.state.params['license'],
              routerInfo.state.params['siacode'],
              routerInfo.state.params['uicode'],
            )
            .pipe(take(1)),
        ]),
      ),
      switchMap(([filterState, searchContext]) => {
        const searchDataInfo = searchContext.map((context) => {
          delete context.columnMetadata;
          return {
            ...context,
            selectMetadata: [MetadataEnum.DOCLABEL, MetadataEnum.SERIVCES, MetadataEnum.HUBFE_SEZIONE],
          };
        });
        this.searchContextService.sendSearchContext(searchDataInfo);
        const crossFilterState = { ...filterState, searchDataInfo };
        const chunked = filterState.paging && filterState.paging.offset > 0;
        return combineLatest([
          of(chunked),
          this.documentsHttpService.whenAllDocuments(JSON.stringify(crossFilterState)),
        ]);
      }),
      map(([chunked, documentsResponse]) =>
        DocumentActions.documentsFetched(
          documentsResponse.docs,
          documentsResponse.totalDocs,
          documentsResponse.filters,
          documentsResponse.metrics,
          chunked,
        ),
      ),
      tap(() => {
        this.documentsService.setLoadingDocs(false);
        this.documentsService.setLoadingDocsAfterFilterApplication(false);
        this.documentsService.setLoadingDocsOnSectionChange(false);
        this.documentsService.setLoadingDocsOnPaging(false);
      }),
      catchError(() => {
        this.documentsService.setLoadingDocs(false);
        this.documentsService.setLoadingDocsAfterFilterApplication(false);
        this.documentsService.setLoadingDocsOnSectionChange(false);
        this.documentsService.setLoadingDocsOnPaging(false);
        this.documentsService.setErrorLoadingDocs(true);
        return EMPTY;
      }),
    ),
  );

  /**
   * Effetto che gestisce il side effect di aver richiesto le azioni su una data serie documentale.
   */
  onFetchDocumentsActionsRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentActions.fetchDocumentsActions),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gaw/') !== -1),
      switchMap(() =>
        //richiesta azioni massive spostata da BE a FE
        // return this.actionsConfigurationService.getMassiveActionsByDocSeries(licenseId, siaCode, docSeriesId)
        this.actionsConfigurationService.getMassiveActionsByDefault().pipe(
          map((config) =>
            DocumentActions.documentActionsFetched(this.actionsService.mergeActionConfig(config, 'massive')),
          ),
          catchError((error: unknown) => this.handleError(error)),
        ),
      ),
    ),
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<DocumentState>,
    private documentsHttpService: DocumentsHttpService,
    private searchContextService: SearchContextService,
    private searchContextHttpService: SearchContextHttpService,
    private actionsService: ActionsService2,
    private actionsConfigurationService: ActionsConfigurationService,
    private documentsService: DocumentsService2,
  ) {
    super(actions$, store);
  }
}
