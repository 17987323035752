import { Injectable } from '@angular/core';
import { FilterPayload, Metric } from '@ctel/gaw-commons';
import { Store } from '@ngrx/store';
import {
  accountFilter,
  amountFilterConfig,
  docTypeFilterConfig,
  primaryServiceStatusConfig,
  timestampCtelRicezioneSdiFilterConfig,
} from 'app/constants/filters/filters-config';
import { payableMetricsConfig } from 'app/constants/metrics/metrics';
import { metadataListConfig } from 'app/constants/ui-config/ui-config';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AccountType } from 'app/core/common/account/accountType';
import { Copier } from 'app/core/common/utilities/copier';
import { Company } from 'app/entities/companies/company';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { AccountService } from '../account/account.service';
import { FilterService } from '../documents/filters/filter.service';
import { resetHomeDateFilterPayload } from '../documents/filters/store/home-filter.actions';
import { ClientServiceConfigurationResponse } from '../entities/cards/client-configuration';
import { ReducedDocumentResponse } from '../entities/documents/documents-response';
import { CardsHttpService } from './cards-http.service';

/**
 * Servizio che gestisce lo stato delle cards piccole e grandi del ciclo attivo
 */
@Injectable({
  providedIn: 'root',
})
export class PayableAccountCardsService {
  private services$ = new ReplaySubject<ClientServiceConfigurationResponse>(1);
  private largeCardsValues$ = new ReplaySubject<ReducedDocumentResponse>(1);
  private errorLoadingServices$ = new BehaviorSubject<boolean>(false);
  private firstLoad = true;

  constructor(
    private cardsHttpService: CardsHttpService,
    private companiesService: CompaniesService,
    private accountService: AccountService,
    private filterService: FilterService,
    private store: Store<any>,
  ) {
    this.initializeServices();
  }

  initializeServices() {
    this.companiesService
      .whenCurrentCompany()
      .pipe(
        distinctUntilChanged((x, y) => x.siaCode === y.siaCode),
        tap((currentCompany) => {
          this.cardsHttpService
            .whenServices(currentCompany.licenseId, currentCompany.siaCode, 'PASSIVO')
            .pipe(
              tap({
                error: () => {
                  this.setErrorLoadingServices(true);
                  return EMPTY;
                },
              }),
            )
            .subscribe({
              next: (services) => {
                this.setErrorLoadingServices(false);
                // canali configurati sul ciclo passivo per quell'azienda
                this.sendServices(services);
                // se sono sulla home
                if (this.accountService.getCurrentAccount() === AccountType.PAYABLE) {
                  /*
										if (window.location.href.indexOf('/home') !== -1
											|| (window.location.href.indexOf('/documents/') !== -1 && window.location.href.indexOf('/details') !== -1)) {
											const payload = this.buildPayload(currentCompany);
											this.store.dispatch(resetHomeDateFilterPayload(payload, 'payable', this.firstLoad));
											this.firstLoad = false;
										}
										*/
                  const payload = this.buildPayload(currentCompany);
                  this.store.dispatch(resetHomeDateFilterPayload(payload, 'payable', this.firstLoad));
                  this.firstLoad = false;
                }
              },
              error: catchError(() => {
                this.setErrorLoadingServices(true);
                return EMPTY;
              }),
            });
        }),
      )
      .subscribe();
  }

  whenServices(): Observable<ClientServiceConfigurationResponse> {
    return this.services$.asObservable();
  }

  // quando ho i canali, recupero la configurazione per disegnare la UI
  sendServices(services: ClientServiceConfigurationResponse) {
    this.services$.next(services);
  }

  public whenErrorLoadingServices(): Observable<boolean> {
    return this.errorLoadingServices$.asObservable();
  }

  public setErrorLoadingServices(value: boolean) {
    this.errorLoadingServices$.next(value);
  }

  // totali nelle tiles grandi
  whenLargeCardsValues(): Observable<ReducedDocumentResponse> {
    return this.largeCardsValues$.asObservable();
  }

  // totali nelle tiles grandi
  sendLargeCardsValues(cardValues: ReducedDocumentResponse) {
    this.largeCardsValues$.next(cardValues);
  }

  // costruisce payload per richiesta totali tiles ciclo passivo
  buildPayload(currentCompany: Company): FilterPayload {
    // piastrelle grandi
    const metrics: Metric[] = Copier.deepCopy(payableMetricsConfig);

    // filtro di default per ciclo attivo e data inizio anno - oggi
    const filters = [
      Copier.deepCopy(docTypeFilterConfig),
      Copier.deepCopy(primaryServiceStatusConfig),
      Copier.deepCopy(amountFilterConfig),
      Copier.deepCopy(accountFilter.get(AccountType.PAYABLE)),
      Copier.deepCopy(timestampCtelRicezioneSdiFilterConfig),
    ];

    return {
      licenseId: currentCompany.licenseId,
      siaCode: currentCompany.siaCode,
      docSeriesId: 'ALL',
      filters,
      metrics,
      selectMetadata: [],
      search: {
        fullText: false,
        value: '',
        metadataList: metadataListConfig,
      },
      paging: {
        offset: 0,
        docsPerPages: 0,
      },
    };
  }
}
