import { ComponentRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { ErrorPlaceholderComponent } from 'app/core/common/placeholder/error-placeholder/templates/error-placeholder/error-placeholder.component';
import { ComponentInjector } from 'app/core/common/utilities/component-injector';

/**
 * Aggiunge il template al DOM a meno che non sia avvenuto un errore.
 *
 * Se l'espressione assegnata a `error` e' vera, allora rimuove il template
 * dal DOM e aggiunge un placeholder sostitutivo che comunica all'utente
 * l'impossibilità di visualizzare correttamente quella parte di view
 *
 * ```
 * <div *hubError="!everythingLoaded" class="success">
 *   Tutto caricato correttamente.
 * </div>
 * ```
 *
 * ### Syntax
 *
 * - `<div *hubError="error">...</div>`
 * - `<ng-template [hubError]="error"><div>...</div></ng-template>`
 *
 */
@Directive({
  selector: '[dryError]',
})
export class ErrorDirective implements OnDestroy {
  public _loading: boolean;

  public _options: Options;

  // Istanza del componente di errore
  public errorComponentInstantiated = false;
  private errorComponent: ComponentRef<ErrorPlaceholderComponent>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input() set dryError(error: boolean) {
    if (error === true) {
      this.clearView();
      this.instantiateErrorComponent();
    } else if (error === false) {
      this.clearView();
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  @Input() set dryErrorLoading(loading: boolean) {
    this._loading = loading;
    if (this.errorComponentInstantiated) this.errorComponent.instance.loading = loading;
  }

  @Input() set dryErrorOptions(options: Options) {
    this._options = options;
    if (this.errorComponentInstantiated) this.errorComponent.instance.options = options;
  }

  ngOnDestroy(): void {
    if (this.errorComponent) this.errorComponent.destroy();
  }

  clearView() {
    this.viewContainer.clear();
    this.errorComponentInstantiated = false;
  }

  instantiateErrorComponent() {
    this.errorComponent = this.createErrorPlaceholderComponent();
    ComponentInjector.insertComponent(this.viewContainer, this.errorComponent);
    this.errorComponentInstantiated = true;
  }

  private createErrorPlaceholderComponent(): ComponentRef<ErrorPlaceholderComponent> {
    const placeholderComponent = this.viewContainer.createComponent(ErrorPlaceholderComponent);
    placeholderComponent.instance.loading = this._loading;
    placeholderComponent.instance.options = this._options;
    return placeholderComponent;
  }
}
