import { Component, ElementRef, HostListener, Input, OnDestroy, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@ctel/auth';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { WorkflowHttpService } from '../../../services/workflow-http.service';
import { WorkflowService } from '../../../services/workflow.service';

/**
 * Componente modal per creazione nuovo item
 */
@Component({
  selector: 'gaw-create-workitem-modal',
  templateUrl: './create-workitem-modal.component.html',
  styleUrls: ['./create-workitem-modal.component.scss'],
})
export class CreateWorkitemModalComponent implements OnDestroy {
  @Input() title: string;

  public createItemFormLink$: Observable<SafeResourceUrl>;
  public token$: Observable<string>;

  frame$: ReplaySubject<Element> = new ReplaySubject<Element>(1);

  private destroy$ = new Subject<void>();

  constructor(
    public bsCreateWorkItemModalRef: BsModalRef,
    private workflowService: WorkflowService,
    private workflowHttpService: WorkflowHttpService,
    private authService: AuthService,
  ) {
    this.createItemFormLink$ = null;
    this.createItemFormLink$ = this.workflowService.whenCreateWorkItemFormLink();
    this.token$ = this.authService.whenToken();
  }

  @ViewChild('createForm', { static: false }) set form(form: ElementRef) {
    if (form) {
      this.destroy$.next();
      form.nativeElement.submit();

      this.whenFrame()
        .pipe(
          tap((frame: HTMLIFrameElement) => (frame.onload = () => this.sendHostMessage(frame))),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  @ViewChild('frame', { static: false }) set frame(frame: ElementRef) {
    if (frame) this.sendFrame(frame.nativeElement);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    const successMessage = event.data;
    const targetOrigin = event.origin;
    if (successMessage === 'success' && targetOrigin === this.workflowHttpService.getBeFlowHost())
      this.closeModalAndUpdate();
  }

  sendHostMessage(frame) {
    const beflowUrl = this.workflowHttpService.getBeFlowHost();
    const receiver = frame.contentWindow;
    receiver.postMessage(window.location.origin, beflowUrl);
  }

  closeModalAndUpdate() {
    this.bsCreateWorkItemModalRef.hide();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private sendFrame(frame) {
    this.frame$.next(frame);
  }

  private whenFrame() {
    return this.frame$.asObservable();
  }
}
