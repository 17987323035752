import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PlaceholderModule } from '@ctel/placeholders';
import { WorkitemActivityActionsComponent } from './workitem-activity-actions/workitem-activity-actions.component';
import { WorkitemActivityDetailFrameComponent } from './workitem-activity-detail-frame/workitem-activity-detail-frame.component';
import { WorkitemActivityIndirectActionFrameComponent } from './workitem-activity-indirect-action-frame/workitem-activity-indirect-action-frame.component';
import { WorkitemActivityComponent } from './workitem-activity.component';

@NgModule({
  imports: [CommonModule, PlaceholderModule],
  exports: [WorkitemActivityComponent],
  declarations: [
    WorkitemActivityComponent,
    WorkitemActivityActionsComponent,
    WorkitemActivityDetailFrameComponent,
    WorkitemActivityIndirectActionFrameComponent,
  ],
})
export class WorkitemActivityModule {}
