<table *ngIf="history$ | async as history">
  <ng-container *ngIf="history.length === 0"> Storico non disponibile per il documento selezionato. </ng-container>
  <tr>
    <th>Stato</th>
    <th>Note</th>
    <th>Data e ora</th>
  </tr>
  <tr *ngFor="let historyItem of history; let i = index">
    <td>{{ historyItem.stato }}</td>
    <td>{{ historyItem.note }}</td>
    <td>{{ historyItem.dataOra | isoDatetimeToDatetime }}</td>
  </tr>
</table>
