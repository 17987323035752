import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { UploadLottoMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';

@Injectable()
export class ResendPaHttpService {
  // endpoints
  private uploadString: string;
  private uploadStringHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.uploadStringHost = appConfig.docDetails.http.host;
    this.uploadString = `${this.uploadStringHost}/v1/Document/ResendPa`;
  }

  public uploadDocumentPa(
    id_: string,
    sia_: string,
    grafica_: string,
    documentPa: string,
    progspool: string,
    progBusta: string,
    ctelDocElastic: string,
    ctelDocId: string,
  ) {
    const url_ = this.uploadString;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/json',
      }),
      params: {
        id: id_,
        sia: sia_,
        grafica: grafica_,
        metadataMatching: 'SequenceNumberMatchingKey',
        progSpool: progspool,
        progBusta,
        ctelDocSeriesId: ctelDocId,
        ctelElasticDocumentId: ctelDocElastic,
        forcedChannel: '35',
      },
    };

    return this.http.post<UploadLottoMessage>(url_, documentPa, httpOptions);
  }
}
