import { TableAction } from './table-action';
import { Observable } from 'rxjs';

export class AsyncTableAction extends TableAction {
  action: () => Observable<unknown>;

  constructor(
    name: string,
    icon: string,
    color = '',
    canExecute: (params) => boolean,
    action: () => Observable<unknown>,
    public validation: (params) => boolean = () => true,
  ) {
    super(name, icon, color, canExecute, action);
  }
}
