import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CancelChannelHttpService } from './cancel-channel-http.service';

@Injectable()
export class CancelChannelService {
  constructor(
    private cancelChannelHttpService: CancelChannelHttpService,
    private router: Router,
  ) {}

  public cancelChannel(progSpool, progBusta, docHash, channelType, errorChannel) {
    return this.cancelChannelHttpService.cancelChannel(progSpool, progBusta, docHash, channelType, errorChannel).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
    );
  }
}
