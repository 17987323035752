import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { PDDRequestModel } from '../entities/PDD-request-model';
import { PDDResponseModel } from '../entities/PDD-response-model';
import { StatusCheckResponseModel } from '../entities/status-check-response-model';
import { PacchettiDiDistribuzioneHttpService } from './pacchetti-di-distribuzione-http.service';

@Injectable({
  providedIn: 'root',
})
export class PacchettiDiDistribuzioneService {
  private actions$ = new ReplaySubject(1);

  constructor(private pacchettiDiDistribuzioneHttp: PacchettiDiDistribuzioneHttpService) {}

  validaDocumentiMultiplo(pddModel: PDDRequestModel): Observable<string> {
    return this.pacchettiDiDistribuzioneHttp.validaDocumentiMultiplo(pddModel).pipe(catchError(() => EMPTY));
  }

  validaDocumentiMassivo(body: string): Observable<string> {
    return this.pacchettiDiDistribuzioneHttp.validaDocumentiMassivo(body).pipe(catchError(() => EMPTY));
  }

  richiestaPddMultipla(pddModel: PDDRequestModel): Observable<PDDResponseModel> {
    return this.pacchettiDiDistribuzioneHttp.richiestaPddMultipla(pddModel).pipe(catchError(() => EMPTY));
  }

  verificaStatoDocumenti(body: string): Observable<StatusCheckResponseModel> {
    return this.pacchettiDiDistribuzioneHttp.verificaStatoDocumenti(body).pipe(catchError(() => EMPTY));
  }

  richiestaPddMassiva(body: {
    searchRequest: any;
    idRegistro: string;
    annoFiscale?: string;
    flagAttestato?: boolean;
    flagIPDA?: boolean;
    flagRDV?: boolean;
    flagSuperimpronte?: boolean;
    flagComunicazioniAde?: boolean;
    flagRicevuteAde?: boolean;
  }): Observable<PDDResponseModel> {
    return this.pacchettiDiDistribuzioneHttp.richiestaPddMassiva(body).pipe(catchError(() => EMPTY));
  }

  getActions() {
    this.pacchettiDiDistribuzioneHttp
      .getActions()
      .pipe(take(1))
      .subscribe((value) => this.actions$.next(value));
  }

  whenActions() {
    return this.actions$.asObservable();
  }
}
