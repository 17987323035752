<div class="modal fade" id="{{ modalId }}" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="invoice-modal-title">
          <p>{{ modalTitle }}</p>
        </div>
      </div>
      <div class="modal-body">
        <ng-container *ngFor="let field of fields">
          <app-modal-section-array [field]="field" [parentForm]="parentForm"></app-modal-section-array>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"><b>Conferma</b></button>
      </div>
    </div>
  </div>
</div>
