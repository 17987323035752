import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from './callbacks/single-document-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  // Action URL GAWPEC
  public readonly cancelElabGedPec = new ActionBuilder(SingleActionsCode.cancelElabGedPec, ActionCode.CANCEL_ELAB)
    .setActionURL('/api/v1/Action/cancelonchannel')
    .build();

  public readonly visualizeMessage = new ActionBuilder(SingleActionsCode.visualizeMessage, ActionCode.SHOW_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=false',
    )
    .build();

  public readonly visualizeMessageHistory = new ActionBuilder(
    SingleActionsCode.visualizeMessageHistory,
    ActionCode.SHOW_STATUS_HISTORY,
  )
    .setActionURL(
      '/api/v1/Action/viewstatushistory?progSpool={progSpool}&progBusta={progBusta}&idComunicazione={idComunicazione}',
    )
    .build();

  public readonly pdfDownload = new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/api/v1/Action/downloadpdfdocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();
  // Fine Action URL GAWPEC

  public readonly downloadAttachments = new ActionBuilder(
    SingleActionsCode.downloadAttachments,
    ActionCode.DOWNLOAD_ATTACHMENTS,
  )
    .setActionURL('/api/v1/Action/downloadattachment/{idComunicazione}/SCARICA_ALLEGATI')
    .build();

  public readonly downloadOriginalPdf = new ActionBuilder(
    SingleActionsCode.downloadOriginalPdf,
    ActionCode.DOWNLOAD_ORIGINAL_PDF,
  )
    .setActionURL('/api/v1/Action/downloadattachment/{idComunicazione}/SCARICA_ORIGINALE')
    .build();

  public readonly downloadAcceptance = new ActionBuilder(
    SingleActionsCode.downloadAcceptance,
    ActionCode.DOWNLOAD_ACCEPTANCE,
  )
    .setActionURL('/api/v1/Action/downloadattachment/{idComunicazione}/SCARICA_ACCETTAZIONE')
    .build();

  public readonly downloadDelivery = new ActionBuilder(SingleActionsCode.downloadDelivery, ActionCode.DOWNLOAD_DELIVERY)
    .setActionURL('/api/v1/Action/downloadattachment/{idComunicazione}/SCARICA_CONSEGNA')
    .build();

  public readonly downloadMissedDelivery = new ActionBuilder(
    SingleActionsCode.downloadMissedDelivery,
    ActionCode.DOWNLOAD_MISSING_DELIVERY,
  )
    .setActionURL('/api/v1/Action/downloadattachment/{idComunicazione}/SCARICA_MANCATA_CONSEGNA')
    .build();

  public readonly editAddressAndResend = new ActionBuilder(
    SingleActionsCode.editAddressAndResend,
    ActionCode.EDIT_ADDRESS_AND_RESEND,
  ).build();
  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  cancelElabGedPec = 'cancelElabGedPec',
  visualizeMessage = 'visualizeMessage',
  visualizeMessageHistory = 'visualizeMessageHistory',
  pdfDownload = 'pdfDownload',
  downloadAttachments = 'downloadAttachments',
  downloadOriginalPdf = 'downloadOriginalPdf',
  downloadAcceptance = 'downloadAcceptance',
  downloadDelivery = 'downloadDelivery',
  downloadMissedDelivery = 'downloadMissedDelivery',
  editAddressAndResend = 'editAddressAndResend',
}
