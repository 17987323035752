import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '../services/user.service';

/**
 * Direttiva per nascondere o visualizzare porzioni di pagina in base ai ruoli dell'utente.
 *
 * @example
 * <div *ctelAuthRolesAccess="['Platform.Admin']"></div>
 */
@Directive({
  selector: '[ctelAuthRolesAccess]',
})
export class RolesAccessDirective implements OnInit, OnDestroy {
  private roles$ = new ReplaySubject<Array<string>>(1);
  private destroy$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  @Input('ctelAuthRolesAccess') set roles(roles: Array<string>) {
    if (roles) this.roles$.next(roles);
  }

  ngOnInit(): void {
    this.userService
      .getRoles()
      .pipe(
        switchMap((userRoles) =>
          this.roles$.pipe(
            // Controllo che almeno un elemento dell'array dei ruoli richiesti sia presente tra i ruoli dell'utente
            map((requiredRoles) => requiredRoles.some((requiredRole) => Array.from(userRoles).includes(requiredRole))),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((hasRoles) => {
        if (hasRoles) this.viewContainer.createEmbeddedView(this.templateRef);
        else this.viewContainer.clear();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
