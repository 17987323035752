import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdvancedTextSearchService } from '@ctel/gaw-commons';
import {
  AbstractFilterEffects,
  DocumentActions,
  FilterAction,
  FilterActions,
  FilterState,
  IAdvancedTextSearchUrl,
  IFilterStatus,
  IFilterUrl,
  IFullTextSearchUrl,
  IOrderByUrl,
  IOuterFilterUrl,
  IPagingUrl,
  IRouterStateUrl,
  getFiltersStateWithUserValues,
  getRouterInfo,
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { selectMetadataConfig } from 'app/constants/ui-config/ui-config';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UiConfigurationService } from '../../../ui-configuration/ui-configuration.service';
import { DocumentsService } from '../../documents/documents.service';
import { sectionColumnsFetchedHUBFE } from '../../documents/store/document.extended';
import { FilterService } from '../filter.service';
import * as ExtendedFilterActions from './filter.actions';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;

@Injectable({
  providedIn: 'root',
})
export class FilterEffects extends AbstractFilterEffects {
  /**
   * Effetto che gestisce il side effect di aver fetchato i filtri preferiti,
   * e per i quali è necessario recuperare nuovi documenti.
   */
  onFavoriteFiltersFetched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favoriteFiltersFetched),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/') !== -1),
      switchMap(() => [DocumentActions.fetchDocuments(true), DocumentActions.fetchDocumentsActions()]),
    ),
  );

  onSectionColumnsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedFilterActions.sectionColumnsRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      switchMap(([action, routerInfo]) =>
        this.uiConfigurationService
          .getSectionColumns(
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            routerInfo.state.params['classification'],
          )
          .pipe(
            switchMap((sectionColumns) => {
              const valArray = [...sectionColumns.primaryConfig, ...sectionColumns.secondaryConfig];
              const colsAsArray = valArray.reduce(
                (acc, val) => {
                  if (acc.indexOf(val.id) === -1) acc.push(val.id);

                  return acc;
                },
                [...selectMetadataConfig],
              );

              const account = routerInfo.state.params['accounttype'];
              const classification = routerInfo.state.params['classification'];
              const licenseId = routerInfo.state.params['license'];
              const siaCode = routerInfo.state.params['siacode'];
              const newFilterStatus: IFilterStatus = this.documentsService.buildFilterPayload(
                licenseId,
                siaCode,
                account,
                action.payload.filterResponse,
                colsAsArray,
                classification,
              );
              if (newFilterStatus) this.documentsService.setDocListError(false);

              return [
                sectionColumnsFetchedHUBFE(sectionColumns.primaryConfig, sectionColumns.secondaryConfig),
                FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus }),
              ];
            }),
            catchError((error: unknown) => this.handleError(error)),
          ),
      ),
    ),
  );

  /**
   * Effetto che gestisce il side effect della richiesta dei filtri preferiti,
   * che è necessario recuperare.
   */
  onFavouritesFiltersRequested2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favouritesFiltersRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/') !== -1),
      switchMap(([, routerInfo]) =>
        this.uiConfigurationService
          .getFavoriteFilters(
            routerInfo.state.params['classification'], // TODO: check se è sempre così.
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
          )
          .pipe(
            take(1),
            tap((filtersResponse) => {
              // La preffilters per HUBFE ritorna dei termsAggs senza l'oggetto buckets vuoto.
              filtersResponse.filters.forEach((value) => {
                if (value.filterType === 'termsAggs' && !value.configData.buckets) value.configData.buckets = [];
              });
            }),
            map((filtersResponse) => ExtendedFilterActions.sectionColumnsRequested(filtersResponse)),
            catchError((error: unknown) => {
              this.documentsService.setDocListError(true);
              return this.handleError(error);
            }),
          ),
      ),
    ),
  );

  onChangeFilterRequestedInHubFe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.changeFilterRequested),
      withLatestFrom(
        this.store.pipe(select(getRouterInfo)),
        this.store.pipe(
          select(
            getFiltersStateWithUserValues({
              fromFavourites: true,
              technicalFilters: [MetadataEnum.DATA_FATTURA, MetadataEnum.DATA_INSERIMENTO, MetadataEnum.DATA_RICEZIONE],
            }),
          ),
        ),
      ),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/') !== -1),
      filter(
        ([action, ,]) =>
          action.kind === ChangeFilterRequestedKind.ResetFilter || action.kind === ChangeFilterRequestedKind.Reset,
      ),
      switchMap(([action, , filters]) => {
        // In HubFE i value dei filter in home sono "patched" prima della search a partire dai filtri in home,
        // entro i pref filters. Se semplicemente ripristinassimo i pref filters (ovvero ciò che fa il reducer
        // di default sul changeFilterRequested di tipo reset/reset filter), non puliremmo anche tali valori
        // patchati. Pertanto dobbiamo farlo esplicitamente.
        const metadataToReset =
          action.kind === ChangeFilterRequestedKind.Reset
            ? [
                MetadataEnum.DATA_FATTURA.toString(),
                MetadataEnum.DATA_INSERIMENTO.toString(),
                MetadataEnum.DATA_RICEZIONE.toString(),
              ]
            : action.metadata;
        const resetValue = { term: '', terms: [], from: '', to: '', ranges: [], value: '' };
        const patchedFilters = filters.filters.reduce(
          (acc, item) => [
            ...acc,
            {
              ...item,
              value: metadataToReset.indexOf(item.metadata) !== -1 ? { ...resetValue } : item.value,
            },
          ],
          [],
        );

        return [
          // Patchiamo i filtri e forziamo un refresh dei documenti.
          FilterActions.patchFiltersRequested({ filters: patchedFilters }),
          DocumentActions.fetchDocuments(true),
        ];
      }),
    ),
  );

  onAdvancedTextSearchMetadataRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.advancedTextSearchMetadataRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawfe/documents') !== -1),
      switchMap(([, routerInfo]) =>
        this.uiConfigurationService
          .getRelatedData(
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            routerInfo.state.params[
              routerInfo.state.url.indexOf('/details/') !== -1 ? 'accounttype' : 'classification'
            ],
          )
          .pipe(
            switchMap((relatedSectionData) =>
              this.documentsService.whenMetadataDescriptions(relatedSectionData).pipe(
                map((docSeriesMetadataDesc) => {
                  const sorted = this.advancedTextSearchService.sortTextSearchMetadata(
                    docSeriesMetadataDesc,
                    relatedSectionData,
                  );
                  return FilterActions.advancedTextSearchMetadataFetched({ advancedTextSearch: sorted });
                }),
                catchError((error: unknown) => this.handleError(error)),
              ),
            ),
            catchError((error: unknown) => this.handleError(error)),
          ),
      ),
    ),
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<FilterState>,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected documentsService: DocumentsService,
    protected filterService: FilterService,
    private uiConfigurationService: UiConfigurationService,
    protected advancedTextSearchService: AdvancedTextSearchService,
    protected ngZone: NgZone,
  ) {
    super(actions$, store, filterService, router, activatedRoute, ngZone);
  }

  protected getParamsDistinctCriteria(prev: Params, succ: Params): boolean {
    if (this.router.url.indexOf(this.getDocumentsListUrl()) === -1) return false;

    return super.getParamsDistinctCriteria(prev, succ);
  }

  protected getDocumentsListUrl(): string {
    return '/gawfe/documents';
  }

  protected getDocumentDetailUrl(): string {
    return '/details/';
  }

  protected doNavigationChangedOnDocTypeParamsChanged() {
    this.documentsService.setLoadingDocs(true);
    return super.doNavigationChangedOnDocTypeParamsChanged();
  }

  protected doOnChangeFilterRequested(
    action: FilterAction,
    routerInfo: { state: IRouterStateUrl; navigationId: number },
    filtersUrl: IFilterUrl[],
    outerFiltersUrl: IOuterFilterUrl[],
    pagingFiltersUrl: IPagingUrl,
    orderByFiltersUrl: IOrderByUrl[],
    fullTextSearchUrl: IFullTextSearchUrl,
    advancedTextSearchUrl: IAdvancedTextSearchUrl[],
  ): void {
    if (routerInfo.state.url.indexOf('gawfe/') === -1) return;

    if (action.kind === ChangeFilterRequestedKind.PagingChanged) this.documentsService.setLoadingDocsOnPaging(true);
    else {
      this.documentsService.setLoadingDocsAfterFilterApplication(true);
      this.documentsService.setLoadingDocsOnPaging(false);
    }
    super.doOnChangeFilterRequested(
      action,
      routerInfo,
      filtersUrl,
      outerFiltersUrl,
      pagingFiltersUrl,
      orderByFiltersUrl,
      fullTextSearchUrl,
      advancedTextSearchUrl,
    );
  }

  protected getUrlCommands(routerInfo: { state: IRouterStateUrl; navigationId: number }): string[] {
    const urlCommands: string[] = [];
    urlCommands.push(this.getDocumentsListUrl());
    urlCommands.push(routerInfo.state.params['license']);
    urlCommands.push(routerInfo.state.params['siacode']);
    urlCommands.push(routerInfo.state.params['accounttype']);
    urlCommands.push(routerInfo.state.params['serviceid']);
    urlCommands.push(routerInfo.state.params['classification']);
    return urlCommands;
  }
}
