import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject, isObservable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DocumentNotesConfig } from '../document-notes-config';
import { DOC_NOTES_CONFIG_TOKEN } from '../document-notes-token';
import { DocumentNotesResponse } from '../entities/document-notes-response';
import { Sorting } from '../entities/sorting';

@Injectable({
  providedIn: 'root',
})
export class DocumentNotesHttpService {
  private readonly config$ = new ReplaySubject<DocumentNotesConfig>(1);

  constructor(
    @Inject(DOC_NOTES_CONFIG_TOKEN) private config: DocumentNotesConfig | Observable<DocumentNotesConfig>,
    private readonly httpClient: HttpClient,
  ) {
    if (isObservable(config)) config.pipe(take(1)).subscribe((val) => this.config$.next(val));
    else this.config$.next(config as DocumentNotesConfig);
  }

  /**
   * Ottiene la lista delle note del documento
   * @param progSpool ProgSpool del documento
   * @param progBusta ProgBusta del documento
   * @param sorting Oggetto per l'ordinamento del risultato
   */
  public getNotes(progSpool: number, progBusta: number, sorting: Sorting): Observable<DocumentNotesResponse> {
    const params = {
      progSpool,
      progBusta,
      orderField: sorting.field,
      orderType: sorting.type,
    };

    return this.config$.pipe(
      map((config) => config.e_get),
      switchMap((url) => this.httpClient.get<DocumentNotesResponse>(url, { params })),
    );
  }

  public createNote(progSpool: number, progBusta: number, idSerieDoc: number, message: string): Observable<unknown> {
    const body = { progSpool, progBusta, idSerieDoc, note: message };

    return this.config$.pipe(
      map((config) => config.e_insert),
      switchMap((url) => this.httpClient.post(url, body)),
    );
  }

  public updateNote(
    progSpool: number,
    progBusta: number,
    idSerieDoc: number,
    noteId: number,
    message: string,
  ): Observable<unknown> {
    const body = { progSpool, progBusta, idSerieDoc, note: message, noteId };

    return this.config$.pipe(
      map((config) => config.e_update),
      switchMap((url) => this.httpClient.put(url, body)),
    );
  }

  public deleteNote(progSpool: number, progBusta: number, idSerieDoc: number, noteId: number): Observable<unknown> {
    const params = {
      progSpool,
      progBusta,
      noteId,
      idSerieDoc,
    };

    return this.config$.pipe(
      map((config) => config.e_delete),
      switchMap((url) => this.httpClient.delete(url, { params })),
    );
  }
}
