import { Component } from '@angular/core';
import { PageContent } from 'app/core/common/utilities/contents/page-content';
import { ErrorPages } from '../error-pages';

@Component({
  selector: 'dry-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public pageContent: PageContent = ErrorPages.pages.get('404-not-found');
}
