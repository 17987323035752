import { Component, Optional, Self } from '@angular/core';
import { FormControlName, NgControl } from '@angular/forms';
import { CustomFormControlComponent } from '../../custom-form-control.component';

@Component({
  selector: 'app-checkbox-form-control',
  templateUrl: './checkbox-form-control.component.html',
  styleUrls: ['./checkbox-form-control.component.scss'],
})
export class CheckboxFormControlComponent extends CustomFormControlComponent<boolean> {
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public controlName: FormControlName,
  ) {
    super(ngControl, controlName);
  }
}
