<div class="main-div">
  <div class="row">
    <ng-container *ngFor="let grafica of docSeries; let i = index">
      <div class="col-md-4">
        <a class="over-icon" (click)="graficaClick(grafica.graphicsCode)">
          <div class="tile-grafica" [style.backgroundColor]="grafica.color">
            <table class="tile-title-table">
              <tr class="tile-title-row">
                <td class="tile-title-cell">{{ grafica.description }}</td>
              </tr>
            </table>
          </div>
        </a>
      </div>
    </ng-container>
  </div>
</div>
