import { Injectable } from '@angular/core';
import { CausaliPagamentoService } from 'app/core/business/invoice-pa/create-edit-invoice/causali-pagamento/causali-pagamento.service';
import { InvoiceFormService } from 'app/core/business/invoice-pa/create-edit-invoice/invoice/invoice-form/invoice-form.service';
import { DatiCassaService } from 'app/core/business/invoice-pa/create-edit-invoice/shared/dati-cassa/dati-cassa.service';
import { ModalitaPagamentoService } from 'app/core/business/invoice-pa/create-edit-invoice/shared/modalita-pagamento/modalita-pagamento.service';
import { NaturaService } from 'app/core/business/invoice-pa/create-edit-invoice/shared/natura/natura.service';
import { InvoiceFields } from 'app/entities/invoice-pa/invoice-fields';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

@Injectable()
export class InvoiceFieldsService {
  public readonly booleanValues: InvoiceMultiValues[] = [
    { value: '', label: 'NO' },
    { value: 'SI', label: 'SI' },
  ];
  public readonly tipoScontoMaggiorazioneValues: InvoiceMultiValues[] = [
    { value: 'SC', label: 'Sconto' },
    { value: 'MG', label: 'Maggiorazione' },
  ];

  public readonly tipoRitenuta: InvoiceMultiValues[] = [
    { value: 'RT01', label: 'Persone fisiche' },
    { value: 'RT02', label: 'Persone giuridiche' },
    { value: 'RT03', label: 'Contributo INPS' },
    { value: 'RT04', label: 'Contributo ENASARCO' },
    { value: 'RT05', label: 'Contributo ENPAM' },
    { value: 'RT06', label: 'Altro contributo previdenziale' },
  ];

  constructor(
    private invoiceFormService: InvoiceFormService,
    private datiCassaService: DatiCassaService,
    private naturaService: NaturaService,
    private causaliPagamentoService: CausaliPagamentoService,
    private modalitaPagamentoService: ModalitaPagamentoService,
  ) {}

  public getDatiDocumentoModalFields(buttonModalToAttach: string): InvoiceFields[] {
    return [
      {
        formArrayName: 'DatiCassaPrevidenziale',
        sectionName: 'Dati cassa previdenziale',
        modalButtonId: buttonModalToAttach,
        emptyForm: this.invoiceFormService.getDatiCassaFormGroup(),
        fields: [
          {
            label: 'Tipo cassa',
            formControlname: 'TipoCassa',
            id: 'Dati generali: tipoCassa',
            fieldType: {
              selectList: this.datiCassaService.getList(),
            },
          },
          {
            label: 'Ritenuta',
            formControlname: 'Ritenuta',
            id: 'Dati generali: ritenuta',
            fieldType: {
              selectList: this.booleanValues,
            },
          },
          {
            label: 'Aliquota IVA',
            formControlname: 'AliquotaIVA',
            id: 'Dati generali: aliquota iva',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Rif. amministrazione',
            formControlname: 'RifAmministrazione',
            id: 'Dati generali: riferimento amministrazione',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Imponibile cassa',
            formControlname: 'ImponibileCassa',
            id: 'Dati generali: imponibile cassa',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Importo contributo cassa',
            formControlname: 'ImportoContributoCassa',
            id: 'Dati generali: importo contributo cassa',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Natura',
            formControlname: 'Natura',
            id: 'Dati generali: natura',
            fieldType: {
              selectList: this.naturaService.getList(),
            },
          },
          {
            label: 'Aliquota cassa',
            formControlname: 'AlCassa',
            id: 'Dati generali: aliquota cassa',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
        ],
      },
      {
        formArrayName: 'ScontoMaggiorazione',
        sectionName: 'Sconto maggiorazione',
        modalButtonId: buttonModalToAttach,
        emptyForm: this.invoiceFormService.getScontoMaggiorazioneFormGroup(),
        fields: [
          {
            label: 'Tipo',
            fieldType: {
              selectList: this.tipoScontoMaggiorazioneValues,
            },
            formControlname: 'Tipo',
            id: 'Dati generali: tipo',
          },
          {
            label: 'Importo',
            fieldType: {
              number: {
                decimals: 8,
              },
            },
            formControlname: 'Importo',
            id: 'Dati generali: importo sconto',
          },
          {
            label: 'Percentuale',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
            formControlname: 'Percentuale',
            id: 'Dati generali: percentuale sconto',
          },
        ],
      },
      {
        sectionName: 'Dati ritenuta',
        formArrayName: 'DatiRitenuta',
        emptyForm: this.invoiceFormService.getDatiRitenutaFormGroup(),
        modalButtonId: buttonModalToAttach,
        fields: [
          {
            label: 'Tipo ritenuta',
            formControlname: 'TipoRitenuta',
            id: 'Dati generali: tipo ritenuta',
            fieldType: {
              selectList: this.tipoRitenuta,
            },
          },
          {
            label: 'Aliquota ritenuta',
            formControlname: 'AliquotaRitenuta',
            id: 'Dati generali: aliquota ritenuta',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Causale di pagamento',
            formControlname: 'CausalePagamento',
            id: 'Dati generali: causale pagamento',
            fieldType: {
              selectList: this.causaliPagamentoService.getList(),
              printValue: true,
            },
          },
          {
            label: 'Importo ritenuta',
            formControlname: 'ImportoRitenuta',
            id: 'Dati generali: importo ritenuta',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
        ],
      },
    ];
  }

  public getDatiPagamentoModalFields(buttonModalToAttach: string): InvoiceFields[] {
    return [
      {
        sectionName: 'Dettaglio pagamento',
        formArrayName: 'DettaglioPagamento',
        emptyForm: this.invoiceFormService.getDettaglioPagamentoFormGroup(),
        modalButtonId: buttonModalToAttach,
        fields: [
          {
            label: 'Beneficiario',
            formControlname: 'Beneficiario',
            id: 'Dati pagamento: Beneficiario',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'IBAN',
            formControlname: 'IBAN',
            id: 'Dati pagamento: IBAN',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Modalità pagamento',
            formControlname: 'ModalitaPagamento',
            id: 'Dati pagamento: Modalit� pagamento',
            fieldType: {
              selectList: this.modalitaPagamentoService.getList(),
            },
          },
          {
            label: 'ABI',
            formControlname: 'ABI',
            id: 'Dati pagamento: ABI',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Istituto finanziario',
            formControlname: 'IstitutoFinanziario',
            id: 'Dati pagamento: Istituto finanziario',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'CAB',
            formControlname: 'CAB',
            id: 'Dati pagamento: CAB',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Data riferimento termini pagamento',
            formControlname: 'DataRiferimentoTerminiPagamento',
            id: 'Dati pagamento: Data riferimento termini pagamento',
            fieldType: {
              date: true,
            },
          },
          {
            label: 'BIC',
            formControlname: 'BIC',
            id: 'Dati pagamento: BIC',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Giorni termini pagamento',
            formControlname: 'GiorniTerminiPagamento',
            id: 'Dati pagamento: Giorni termini pagamento',
            fieldType: {
              number: {
                decimals: 0,
              },
            },
          },
          {
            label: 'Sconto pagamento anticipato',
            formControlname: 'ScontoPagamentoAnticipato',
            id: 'Sconto pagamento anticipato',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Importo pagamento',
            formControlname: 'ImportoPagamento',
            id: 'Dati pagamento: Importo pagamento',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Data limite pagamento anticipato',
            formControlname: 'DataLimitePagamentoAnticipato',
            id: 'Data limite pagamento anticipato',
            fieldType: {
              date: true,
            },
          },
          {
            label: 'Codice ufficio postale',
            formControlname: 'CodUfficioPostale',
            id: 'Dati pagamento: Codice ufficio postale',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Penalità pagamenti ritardati',
            formControlname: 'PenalitaPagamentiRitardati',
            id: 'Penalita pagamenti ritardati',
            fieldType: {
              number: {
                decimals: 2,
              },
            },
          },
          {
            label: 'Titolo quietanzante',
            formControlname: 'TitoloQuietanzante',
            id: 'Dati pagamento: Titolo quietanzante',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Data decorrenza penale',
            formControlname: 'DataDecorrenzaPenale',
            id: 'Data decorrenza penale',
            fieldType: {
              date: true,
            },
          },
          {
            label: 'Cognome quietanzante',
            formControlname: 'CognomeQuietanzante',
            id: 'Dati pagamento: Cognome quietanzante',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Data scadenza pagamento',
            formControlname: 'DataScadenzaPagamento',
            id: 'Data inizio periodo',
            fieldType: {
              date: true,
            },
          },
          {
            label: 'Nome quietanzante',
            formControlname: 'NomeQuietanzante',
            id: 'Dati pagamento: Nome quietanzante',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'Codice pagamento',
            formControlname: 'CodicePagamento',
            id: 'Codice pagamento',
            fieldType: {
              text: true,
            },
          },
          {
            label: 'CF quietanzante',
            formControlname: 'CFQuietanzanate',
            id: 'Dati pagamento: CF quietanzante',
            fieldType: {
              text: true,
            },
          },
        ],
      },
    ];
  }
}
