import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, reduce, switchMap, take, tap } from 'rxjs/operators';
import { WorkitemActions } from '../../shared/action';
import { AttachmentItem } from '../../shared/attachment-item';
import { AttachmentPreview } from '../../shared/attachment-preview/attachment-preview';
import { UserAttachmentService } from '../user-attachment.service';

@Component({
  selector: 'gaw-wf-user-attachment-list',
  templateUrl: './user-attachments-list.component.html',
  styleUrls: ['./user-attachments-list.component.scss'],
})
export class UserAttachmentsListComponent implements OnInit {
  @Output() goToDetails = new EventEmitter<string>();

  public faIcons = FaIcons;
  attachments$: Observable<AttachmentItem[]>;
  attachmentsError = false;
  attachmentsLoading$: Observable<boolean>;

  errorOptions: Options = {
    onRetry: () => (this.attachmentsError = false),
  };

  actions: WorkitemActions = [
    {
      icon: `${this.faIcons.FAS_DOWNLOAD}`,
      label: '',
      disable: () => of(false),
      execute: (attachment) => this.downloadAttachment(attachment),
    },
    {
      icon: `${this.faIcons.FAR_TRASH}`,
      label: '',
      disable: () => of(false),
      execute: (attachment) => this.deleteAttachment(attachment),
    },
  ];

  private readonly outletName = 'workitem-detail';

  constructor(
    private readonly userAttachmentService: UserAttachmentService,
    private readonly notificatonService: NotificationService,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.attachments$ = this.userAttachmentService.attachmentList$.pipe(
      mergeMap((list) =>
        from(list).pipe(
          map(
            (att) =>
              <AttachmentItem>{
                mimeType: att.values.mimeType,
                id: att.attachmentId,
                filename: att.values.fileName,
                content: this.userAttachmentService
                  .getAttachmentById(att.attachmentId)
                  .pipe(switchMap((a) => this.userAttachmentService.downloadAttachment(a))),
              },
          ),
          reduce<AttachmentItem, AttachmentItem[]>((acc, val) => [...acc, val], []),
        ),
      ),
      tap((list) => {
        // Se la lista presenta solo un elemento, ed è visualizzabile, faccio subito il routing verso la preview
        if (list.length === 1 && AttachmentPreview.hasPreview(list[0].filename)) this.attachmentDetail(list[0]);
      }),
      catchError(() => {
        this.attachmentsError = true;
        return EMPTY;
      }),
    );

    this.attachmentsLoading$ = this.userAttachmentService.attachmentListLoading$;
  }

  attachmentDetail(attachment: AttachmentItem) {
    this.goToDetails.emit(attachment.id);
  }

  private deleteAttachment(attachment: AttachmentItem) {
    return this.userAttachmentService.getAttachmentById(attachment.id).pipe(
      tap((att) => {
        const callback$ = this.userAttachmentService.deleteAttachment(att.attachmentId).pipe(
          take(1),
          tap(() => this.userAttachmentService.refreshItems()),
          catchError(() => {
            this.notificatonService.showSweetAlert(
              NotificationType.ERROR,
              "Errore durante la cancellazione dell'allegato",
              '',
            );
            return EMPTY;
          }),
        );

        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          "Sicuro di volere eliminare l'allegato?",
          '',
          () => callback$.pipe(take(1)).subscribe(),
        );
      }),
    );
  }

  private downloadAttachment(attachment: AttachmentItem) {
    return attachment.content.pipe(
      take(1),
      tap((content) => {
        const blob = content instanceof Blob ? content : new Blob([content], { type: attachment.mimeType });
        saveAs(blob, attachment.filename);
      }),
      catchError(() => {
        this.notificatonService.showSweetAlert(NotificationType.ERROR, "Errore durante il download dell'allegato", '');
        return EMPTY;
      }),
    );
  }
}
