import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { SafeHtml } from '@angular/platform-browser';
import { FieldType } from './field-type';
import { Is } from './is.enum';

export class Field {
  // Obbligatori
  public name: string;
  public id: string;

  // Opzionali
  public type: FieldType = FieldType.TEXT;
  public visible = true;
  public editable = Is.EDITABLE;
  public required = true;
  public filterable = true;
  public sortable = true;

  public renderer = '';
  public helperTooltip: SafeHtml;

  public validators: ValidatorFn[] = [];
  public asyncValidators: AsyncValidatorFn[] = [];

  constructor(id: string, name: string) {
    this.name = name;
    this.id = id;
  }

  public isEditable(): boolean {
    return this.editable === Is.EDITABLE;
  }

  public isNotEditable(): boolean {
    return this.editable === Is.NOT_EDITABLE;
  }

  public isEditableOnInsertOnly(): boolean {
    return this.editable === Is.EDITABLE_ON_INSERT_ONLY;
  }

  public getValidators(): ValidatorFn {
    return Validators.compose(this.validators);
  }

  public getAsyncValidators(): AsyncValidatorFn {
    return Validators.composeAsync(this.asyncValidators);
  }

  public helper(help: SafeHtml) {
    this.helperTooltip = help;
    return this;
  }
}
