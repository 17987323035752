import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { ConfirmMassiveRequest } from '../entities/confirm-massive-request';

@Injectable({
  providedIn: 'root',
})
export class FrDocumentiHttpService {
  frDocumentiHost: string;
  cancelMassiveUrl: string;
  confirmMassiveUrl: string;
  private jsonContentType = 'application/json';
  private readonly jsonOptions: object;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appconfig: AppConfig) => {
      this.httpHostInit(appconfig);
    });
    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
  }

  httpHostInit(appconfig: AppConfig) {
    this.frDocumentiHost = appconfig.webConsOrchestrator.http.host;
    this.cancelMassiveUrl = `${this.frDocumentiHost}/v1/FirmaRemota/documenti/cancella/massiva`;
    this.confirmMassiveUrl = `${this.frDocumentiHost}/v1/FirmaRemota/documenti/conferma/massiva`;
  }

  cancelMassive(body: string): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body,
    };
    return this.http.delete<void>(this.cancelMassiveUrl, options);
  }

  confirmMassive(payload: ConfirmMassiveRequest): Observable<void> {
    return this.http.put<void>(this.confirmMassiveUrl, payload, this.jsonOptions);
  }
}
