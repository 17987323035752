import { Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { AnomalyStatusHistoryItem } from 'app/entities/anomalies/anomaly-status-history-item';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AnomaliesStatusHistoryService } from '../../../services/anomalies-status-history.service';
import { AnomaliesService } from '../../../services/anomalies.service';
import { StatusHistoryDetailsModalComponent } from './status-history-details-modal/status-history-details-modal.component';

@Component({
  selector: 'gaw-anomaly-status-history',
  templateUrl: './anomaly-status-history.component.html',
  styleUrls: ['./anomaly-status-history.component.scss'],
})
export class AnomalyStatusHistoryComponent implements OnChanges {
  @Input() data: IDetailsComponent;

  public faIcons = FaIcons;

  statusHistory$: Observable<AnomalyStatusHistoryItem[]>;
  statusHistoryError$: Observable<boolean>;
  historyPanelLoading = true;
  tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };

  constructor(
    private statusHistoryService: AnomaliesStatusHistoryService,
    private notificationService: NotificationService,
    private anomaliesService: AnomaliesService,
  ) {}

  ngOnChanges(changes: { data: SimpleChange }): void {
    if (!changes.data) return;

    this.statusHistory$ = this.statusHistoryService.whenStatusHistory(this.data.keys['idSospeso']);

    this.statusHistory$.pipe(take(1)).subscribe(() => (this.historyPanelLoading = false));

    this.statusHistoryError$ = this.statusHistoryService.whenStatusHistoryError().pipe(
      tap((x: boolean) => {
        if (x) this.historyPanelLoading = false;
      }),
    );
  }

  onDetails(dataStato: string, descrizione: string) {
    this.notificationService.showModal(NotificationType.GENERAL, {
      customFooter: true,
      title: `Dettaglio Stato del ${this.anomaliesService.utcToShort(dataStato, 'DD-MM-YYYY HH:mm')}`,
      childComponent: StatusHistoryDetailsModalComponent,
      childData: {
        innerHtml: descrizione,
      },
    });
  }
}
