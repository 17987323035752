import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { CoreCommonModule } from 'app/core/common/core-common.module';

// import { BsDatepickerModule, ModalModule, PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { FileDropDirective } from '../directives/drag-and-drop.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [FileDropDirective],
  exports: [FileDropDirective],
})
export class DirectivesModule {}
