import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TextPlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/templates/text-placeholder/text-placeholder.component';
import { ComponentInjector } from 'app/core/common/utilities/component-injector';

@Component({
  selector: 'gaw-base-loading-placeholder',
  templateUrl: './base-loading-placeholder.component.html',
  styleUrls: ['./base-loading-placeholder.component.scss'],
})
export class BaseLoadingPlaceholderComponent implements OnDestroy, AfterViewInit {
  @Input() margin = 20;
  @Input() component: Type<any>;
  @Input() template: TemplateRef<any>;
  @Input() style: {
    [key: string]: string;
  } = {};
  @Input() options: any;

  @ViewChild('componentContainer', { read: ViewContainerRef, static: false }) insertionPoint;
  @ViewChild('placeholderContainer', { static: false }) placeholderContainer: ElementRef;

  private defaultComponent: Type<any> = TextPlaceholderComponent;

  constructor(
    private viewContainer: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    let componentToLoad: Type<any>;
    if (this.component) componentToLoad = this.component;
    else componentToLoad = this.defaultComponent;

    const createdComponent = this.viewContainer.createComponent(componentToLoad);
    createdComponent.instance.options = this.options;
    ComponentInjector.insertComponent(this.insertionPoint, createdComponent);
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.viewContainer.clear();
  }
}
