import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che sostituisce il caratteri ascii a capo
 */
@Pipe({
  name: 'removeAsciiPipe',
})
export class RemoveAsciiPipe implements PipeTransform {
  transform(value: string): string {
    value.replace(/\n/gi, '\\');
    return value;
  }
}
