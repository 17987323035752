import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFormTypeaheadControlComponent } from 'app/shared/components/form-validation/reactive-form/specialization/text-form-typeahead-control/text-form-typeahead-control.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CheckboxFormControlComponent } from './specialization/checkbox-form-control/checkbox-form-control.component';
import { DateFormControlComponent } from './specialization/date-form-control/date-form-control.component';
import { NumberFormControlComponent } from './specialization/number-form-control/number-form-control.component';
import { SelectFormControlComponent } from './specialization/select-form-control/select-form-control.component';
import { TextFormControlComponent } from './specialization/text-form-control/text-form-control.component';
import { TextareaFormControlComponent } from './specialization/textarea-form-control/textarea-form-control.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, PopoverModule, TypeaheadModule.forRoot()],
  declarations: [
    TextFormControlComponent,
    NumberFormControlComponent,
    SelectFormControlComponent,
    CheckboxFormControlComponent,
    DateFormControlComponent,
    TextFormTypeaheadControlComponent,
    TextareaFormControlComponent,
  ],
  exports: [
    ReactiveFormsModule,
    TextFormControlComponent,
    NumberFormControlComponent,
    SelectFormControlComponent,
    CheckboxFormControlComponent,
    DateFormControlComponent,
    TextFormTypeaheadControlComponent,
    TextareaFormControlComponent,
  ],
})
export class ReactiveFormValidationModule {}
