import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getActions } from '@ctel/search-filter-store';
import { select, Store } from '@ngrx/store';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { HistoryObject, HistoryOutcomesPayableItem } from 'app/core/common/entities/documents/actions/history-item';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ProtocolFields } from 'app/core/common/modals/action-protocol-modal/reverse-charge-data';
import { HttpHeadersFilename } from 'app/core/common/utilities/http-headers-filename';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { HasAttachments } from 'app/entities/ui-config/actions-config';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import * as _ from 'lodash';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ActionsByType } from '../../documents/actions/actions-by-type';
import {
  ActionsConfigRequest,
  ConfigurationsGroup,
  DocMetadata,
} from '../../entities/documents/actions/actions-configuration';
import { ActionsConfigurationService } from '../../ui-configuration/actions-configuration/actions-configuration.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionCrudResponse } from 'app/entities/ui-config/action/action-crud-response';

/**
 * Servizio che gestisce lo stato delle azioni disponibili per i documenti.
 */
@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  private history$ = new ReplaySubject<HistoryObject[]>(1);
  private historyOutcomesPayable$ = new ReplaySubject<HistoryOutcomesPayableItem[]>(1);
  private protocolFields$ = new ReplaySubject<any[]>(1);
  private protocolMode$ = new ReplaySubject<number>(1);
  private readonly massiveActionsList$: Observable<ActionObject[]>;
  private multiActionsList$ = new ReplaySubject<ActionObject[]>(1);
  private singleActionsList$ = new ReplaySubject<ActionObject[]>(1);

  constructor(
    protected actionsHttpService: ActionsHttpService,
    protected actionsConfigurationService: ActionsConfigurationService,
    protected companiesService: CompaniesService,
    protected router: Router,
    protected actionsByType: ActionsByType,
    protected store: Store<any>,
  ) {
    this.massiveActionsList$ = this.store.pipe(
      select(getActions),
      // filter(actions => actions && actions.length >= 0),
      // map(actions => actions.length === 0 ? null : <ActionObject[]> actions[0])
      map((actions) => <ActionObject[]>actions),
    );
  }

  mergeActionConfig(configuration: ConfigurationsGroup[], actionsType: 'single' | 'multi' | 'massive') {
    let actionsCatalog = [];
    switch (actionsType) {
      case 'single':
        actionsCatalog = _.cloneDeep(this.actionsByType.singleActions) as ActionObject[];
        break;
      case 'multi':
        actionsCatalog = _.cloneDeep(this.actionsByType.multiActions) as ActionObject[];
        break;
      case 'massive':
        actionsCatalog = _.cloneDeep(this.actionsByType.massiveActions) as ActionObject[];
        break;
    }

    const actionsList: ActionObject[] = [];
    if (configuration)
      for (let i = 0; i < configuration.length; i++) {
        const action: ActionObject = actionsCatalog.find((obj) => obj.code === configuration[i].code);
        if (action !== undefined) {
          Object.keys(configuration[i]).forEach((key) => {
            action[key] = configuration[i][key];
          });
          actionsList.push(action);
        }
      }

    return actionsList;
  }

  // presenza o meno di allegati per visualizzare o meno il button dell'azione
  whenHasAttachments(
    progSpool: string,
    progBusta: string,
    docHash: string,
    account: string,
  ): Observable<HasAttachments> {
    const accountType = account === SectionCode.RECEIVABLE ? 'ATTIVO' : 'PASSIVO';
    return this.actionsHttpService.whenHasAttachments(progSpool, progBusta, docHash, accountType).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
      take(1),
    );
  }

  whenMassiveActionsList(): Observable<ActionObject[]> {
    return this.massiveActionsList$;
  }

  // azioni multiple
  whenMultiActionsList(): Observable<ActionObject[]> {
    return this.multiActionsList$.asObservable();
  }

  sendMultiActionsList(actions: ActionObject[]) {
    this.multiActionsList$.next(actions);
  }

  // azioni singole
  whenSingleActionsList(): Observable<ActionObject[]> {
    return this.singleActionsList$.asObservable();
  }

  sendSingleActionsList(actions: ActionObject[]) {
    this.singleActionsList$.next(actions);
  }

  getActionsServiceDetailSection(service: string, account: string) {
    switch (service) {
      case 'GEDINVOICE':
        if (account === 'receivable') return SectionCode.GEDFATT;
        else return SectionCode.GEDFATT_CP;

      case 'GEDPOST':
        return SectionCode.GEDPOST;
      case 'GEDMAIL':
        return SectionCode.GEDMAIL;
      case 'GEDPEC':
        return SectionCode.GEDPEC;
      case 'GEDPASS':
        return SectionCode.GEDPASS;
      case 'GEDPASSPLUS':
        return SectionCode.GEDPASSPLUS;
      case 'GEDEDI':
        return SectionCode.GEDEDI;
    }
  }

  requestSingleActionsConfig(licenseId: string, siaCode: string, section: string, keys: any, metadataList: any[]) {
    const newMetadataList: DocMetadata[] = [];

    for (let i = 0; i < metadataList.length; i++)
      if ('metadata' in metadataList[i]) {
        const subObject: DocMetadata = (({ metadata, value }) => ({
          keyCode: metadata.replace('.keyword', ''),
          value,
        }))(metadataList[i]);
        newMetadataList.push(subObject);
      } else {
        const subObject: DocMetadata = (({ keyCode, value }) => ({
          keyCode: keyCode.replace('.keyword', ''),
          value,
        }))(metadataList[i]);
        newMetadataList.push(subObject);
      }

    const body: ActionsConfigRequest = {
      progSpool: keys.progSpool,
      progBusta: keys.progBusta,
      docMetadata: newMetadataList,
    };

    this.actionsConfigurationService
      .getSingleActionsByCard(section, licenseId, siaCode, body)
      .pipe(
        take(1),
        tap((config) => {
          this.sendSingleActionsList(this.mergeActionConfig(config, 'single'));
        }),
      )
      .subscribe();
  }

  requestMultiActionsConfig(section: string, licenseId: string, siaCode: string, body: ActionsConfigRequest[]) {
    this.actionsConfigurationService
      .getMultipleActionsByCard(section, licenseId, siaCode, body)
      .pipe(
        tap((config) => {
          this.sendMultiActionsList(this.mergeActionConfig(config, 'multi'));
        }),
        take(1),
      )
      .subscribe();
  }

  // azione che apre un modal storico qualsiasi (esiti o modifiche)
  whenHistory(): Observable<HistoryObject[]> {
    return this.history$.asObservable();
  }

  sendHistory(historyObject: HistoryObject[]) {
    this.history$.next(historyObject);
  }

  // azione che apre la modal per lo storico esiti passivo (cliente SNAM)
  whenHistoryOutcomesPayable(): Observable<HistoryOutcomesPayableItem[]> {
    return this.historyOutcomesPayable$.asObservable();
  }

  sendHistoryOutcomesPayable(historyObject: HistoryOutcomesPayableItem[]) {
    this.historyOutcomesPayable$.next(historyObject);
  }

  // mode dell'azione di protocollazione (da 0 a 5)
  whenProtocolMode(): Observable<number> {
    return this.protocolMode$.asObservable();
  }

  sendProtocolMode(mode: number) {
    this.protocolMode$.next(mode);
  }

  // metadati per i mode 2 e 4 dell'azione di protocollazione
  whenProtocolFields(): Observable<ProtocolFields[]> {
    return this.protocolFields$.asObservable();
  }

  sendProtocolFields(fields: ProtocolFields[]) {
    this.protocolFields$.next(fields);
  }

  /**
   * Ottiene un oggetto File
   * @param url indirizzo del file
   * @return Observable<File>
   */
  downloadOutcome(url: string): Observable<File> {
    return this.actionsHttpService.whenDownloadOutcome(url).pipe(
      take(1),
      map(
        (response) =>
          new File([response.body], HttpHeadersFilename.getFilenameFromHttpHeaders(response), {
            type: response.headers.get('Content-Type'),
          }),
      ),
    );
  }

  // pdf/xml esito
  whenOutcome(url: string): Observable<Blob> {
    return this.actionsHttpService.whenShowOutcome(url).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
      map((result) => new Blob([result], { type: 'application/pdf' })),
    );
  }

  whenIsPdfOriginalOrCustomPresent(
    serviceName: string,
    progSpool: string,
    progBusta: string,
    docHash: string,
    account: string,
  ): Observable<ActionCrudResponse> {
    return this.actionsHttpService.whenIsPdfOriginalOrCustomPresent(
      serviceName,
      progSpool,
      progBusta,
      docHash,
      account,
    );
  }

  whenDisplayAdeDocument(
    account: string,
    serviceName: string,
    progSpool: string,
    progBusta: string,
    docHash: string,
    download: boolean,
  ): Observable<Blob> {
    return this.actionsHttpService.whenDisplayAdeDocument(
      account,
      serviceName,
      progSpool,
      progBusta,
      docHash,
      download,
    );
  }
}
