import { PlatformLocation } from '@angular/common';
import { AfterContentInit, Component, Input } from '@angular/core';
import { FilterActions, FilterState, IBucket, IFilter } from '@ctel/search-filter-store';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;

@Component({
  selector: 'gawedi-tipo-documento-modal',
  templateUrl: './tipo-documento-modal.component.html',
  styleUrls: ['./tipo-documento-modal.component.scss'],
})
export class TipoDocumentoModalComponent implements AfterContentInit {
  @Input() modal: BsModalRef;
  @Input() data: {
    filter: IFilter;
  };

  tipoDocumento: IBucket[];
  filtered: IBucket[];
  checkedList: boolean[] = [];
  selectedList: string[] = [];
  filterLimit = 10;
  checkedCount = 0;
  disable = false;

  constructor(
    private location: PlatformLocation,
    private store: Store<FilterState>,
  ) {
    location.onPopState(() => this.modal.hide());
  }

  ngAfterContentInit() {
    this.tipoDocumento = this.data.filter.configData.buckets;
    this.tipoDocumento.forEach((type) => {
      this.checkedList.push(this.data.filter.value.terms.includes(type.key));
      if (this.data.filter.value.terms.includes(type.key)) this.checkedCount++;
    });
    if (this.checkedCount >= 10) this.disable = true;

    this.filtered = [...this.tipoDocumento];
  }

  check(key: string) {
    const i = this.tipoDocumento.map((doc) => doc.key).indexOf(key);

    if (!this.checkedList[i]) {
      this.checkedList[i] = !this.checkedList[i];
      this.checkedCount++;
      if (this.checkedCount >= this.filterLimit) this.disable = true;
    } else {
      this.checkedList[i] = !this.checkedList[i];
      this.checkedCount--;
      this.disable = false;
    }
  }

  getChecked(key: string) {
    const i = this.tipoDocumento.map((doc) => doc.key).indexOf(key);
    return this.checkedList[i];
  }

  applyFilters() {
    this.selectedList = [];
    for (let i = 0; i < this.tipoDocumento.length; i++)
      if (this.checkedList[i]) this.selectedList.push(this.tipoDocumento[i].key);

    this.modal.hide();
    this.store.dispatch(
      FilterActions.changeFilterRequested({
        kind: ChangeFilterRequestedKind.CheckBoxTermsSelected,
        metadata: this.data.filter.metadata,
        buckets: this.selectedList,
      }),
    );
  }

  searchFilters(event) {
    if (!event.target.value) this.filtered = [...this.tipoDocumento];

    this.filtered = this.tipoDocumento.filter((filter) =>
      filter.key.toLowerCase().includes(event.target.value.toLowerCase()),
    );
  }
}
