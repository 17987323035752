import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { buffer, debounceTime, filter, takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clickable]',
})
export class ClickDirective implements OnInit, OnDestroy {
  @Input() preventDefault: boolean;

  @Output() readonly singleClick = new EventEmitter<MouseEvent>();
  @Output() readonly doubleClick = new EventEmitter<MouseEvent[]>();

  private readonly click$ = new Subject<MouseEvent>();
  private readonly destroy$ = new Subject<void>();

  @HostListener('click', ['$event']) onClick($event: MouseEvent) {
    if (this.preventDefault) $event.preventDefault();

    this.click$.next($event);
  }

  ngOnInit(): void {
    // Click and Double click
    const debounceClick$ = this.click$.pipe(debounceTime(500));

    const clickInBuffer$ = this.click$.pipe(buffer(debounceClick$));

    // Single click
    clickInBuffer$
      .pipe(
        filter((c) => c.length === 1),
        takeUntil(this.destroy$),
      )
      .subscribe((value: MouseEvent[]) => this.singleClick.emit(value[0]));

    // Double click
    clickInBuffer$
      .pipe(
        filter((c) => c.length > 1),
        takeUntil(this.destroy$),
      )
      .subscribe((value: MouseEvent[]) => this.doubleClick.emit(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
