import { HomeFilter, HomeFilterRangeType } from '@ctel/gaw-commons';
import { IFilter, IFullTextSearch, IMetric, IOrderBy, IPaging } from '@ctel/search-filter-store';
import { Action, createReducer, on } from '@ngrx/store';
import {
  dataProtocollazioneCtelFilterConfig,
  timestampCtelRicezioneSdiFilterConfig,
} from 'app/constants/filters/filters-config';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { produce } from 'immer';
import * as HomeFilterActions from './home-filter.actions';

export interface HomeFiltersModuleState {
  homeFilterState: HomeFilterState;
}

export const homeFilterModuleReducers = {
  homeFilterState: homeFilterReducer,
};

// export const homeFilterModuleFeatureKey = 'homeFilterModule';

/**
 * Reducer per lo stato.
 * @param state lo stato attuale
 * @param action la azione di mutamento dello stato
 */
export function homeFilterReducer(state: HomeFilterState | undefined, action: Action) {
  return _homeFilterReducer(state, action);
}

export const dataProtocollazioneCtelFilterConfigHome: HomeFilter = {
  ...dataProtocollazioneCtelFilterConfig,
  dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE,
};

export const timestampCtelRicezioneSdiFilterConfigHome: HomeFilter = {
  ...timestampCtelRicezioneSdiFilterConfig,
  dateRangeType: HomeFilterRangeType.ULTIMO_TRIMESTRE,
};

/**
 * Model per lo stato.
 */
export interface HomeFilterState {
  docSeriesId: string;
  licenseId: string;
  siaCode: string;
  filters: IFilter[];
  metrics?: IMetric[];
  selectMetadata?: string[];
  search: IFullTextSearch;
  paging?: IPaging;
  orderBy?: IOrderBy[];
  cachedReceivableHomeFilters: HomeFilter[];
  cachedPayableHomeFilters: HomeFilter[];
}

/**
 * Definizione dello stato iniziale.
 */
const initialState: HomeFilterState = {
  docSeriesId: 'ALL',
  licenseId: '',
  siaCode: '',
  filters: <IFilter[]>[],
  search: <IFullTextSearch>{
    fullText: true,
    value: '',
    metadataList: [],
  },
  paging: null,
  orderBy: null,
  metrics: null,
  selectMetadata: null,
  cachedReceivableHomeFilters: <HomeFilter[]>[dataProtocollazioneCtelFilterConfigHome],
  cachedPayableHomeFilters: <HomeFilter[]>[timestampCtelRicezioneSdiFilterConfigHome],
};

const _onChangeHomeDateFilterRequested = (currentState, { payload }) => {
  /*
		return produce<HomeFilterState, HomeFilterState>(currentState, draftState => {
	*/
  const newState = {
    ...currentState,
  };
  if (payload.section === 'receivable') newState.cachedReceivableHomeFilters = [...payload.homeFilters];

  if (payload.section === 'payable') newState.cachedPayableHomeFilters = [...payload.homeFilters];

  const newFilters = newState.filters.filter(
    (value: IFilter) =>
      value.metadata !== MetadataEnum.DATA_INSERIMENTO &&
      value.metadata !== MetadataEnum.DATA_RICEZIONE &&
      value.metadata !== MetadataEnum.DATA_FATTURA,
  );

  newState.filters = newFilters.concat([...payload.homeFilters]);

  return newState;
  /*
		return draftState;
	});*/
};

const _onSwitchHomeDateFilterAccount = (currentState, { payload }) => ({
  ...currentState,
  filters: [...payload.filters],
  metrics: [...payload.metrics],
});

const _onRefreshHomeDateFilterPayload = (currentState, { payload }) =>
  produce<HomeFilterState, HomeFilterState>(currentState, (draftState) => {
    const filterPayload = payload.filterPayload;

    draftState.docSeriesId = filterPayload.docSeriesId;
    draftState.licenseId = filterPayload.licenseId;
    draftState.siaCode = filterPayload.siaCode;
    draftState.filters = filterPayload.filters;
    draftState.search = filterPayload.search;
    draftState.paging = filterPayload.paging;
    draftState.orderBy = filterPayload.orderBy;
    draftState.metrics = filterPayload.metrics;
    draftState.selectMetadata = filterPayload.selectMetadata;
    draftState.cachedReceivableHomeFilters = [dataProtocollazioneCtelFilterConfigHome];
    draftState.cachedPayableHomeFilters = [timestampCtelRicezioneSdiFilterConfigHome];
    return draftState;
  });

const _homeFilterReducer = createReducer(
  initialState,
  on(HomeFilterActions.changeHomeDateFilterRequested, _onChangeHomeDateFilterRequested),
  on(HomeFilterActions.switchHomeDateFilterAccount, _onSwitchHomeDateFilterAccount),
  on(HomeFilterActions.resetHomeDateFilterPayload, _onRefreshHomeDateFilterPayload),
);
