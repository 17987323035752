import { Injectable, OnDestroy } from '@angular/core';
import { DocumentViewModel, Filter } from '@ctel/gaw-commons';
import { IDocument, getDocuments, getFiltersStateWithUserValues } from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { DocumentVisibility } from 'app/constants/metadata/document-visibility.enum';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { Copier } from 'app/core/common/utilities/copier';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ColorList } from 'app/core/common/utilities/color-list';
import { Document } from '../../entities/documents/documents-response';
import { FilterService } from '../filters/filter.service';

/**
 * Servizio che contiene tutta la logica e gli stati relativi alla view dei documenti.
 * La view è necessaria per via dell'infinite scroll (richiesta di documenti aggiuntivi arrivati a fondo pagina):
 * le chiamate http restituiscono sempre un range fisso di documenti, che andranno concatenati ai precedenti.
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsViewService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private availableDocSeriesDescriptionsColor = new Map<string, string>();
  private readonly documents$: Observable<DocumentViewModel[]>;
  private alteredDocuments: Document[] = [];
  private documentsViewPayableError$ = new BehaviorSubject<Document[][]>(null);
  private documentsScrollPosition$ = new BehaviorSubject<number>(0);

  constructor(
    protected filterService: FilterService,
    protected cookieService: CookieService,
    protected store: Store<unknown>,
  ) {
    this.documents$ = this.store.pipe(
      select(getDocuments),
      map((docs) => docs as Document[]),
      map((docs) => docs.map((d) => this.createDocumentViewModel(d as Document))),
    );

    this.documents$
      .pipe(
        withLatestFrom(this.store.pipe(select(getFiltersStateWithUserValues(null)))),
        tap(([documents, filterStatus]) => {
          // NOTA: lo stato è immutabile. Qui si altera il documedto.
          // si poteva fare anche nel reducer, ma sembrava sensato lasciarlo qui.
          const docs = Copier.deepCopy(documents);
          this.alteredDocuments = this.checkIfCookieExists(filterStatus.filters, docs);
          this.updateDocumentsView(this.alteredDocuments);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private static initDocumentHidden(doc: Document): boolean {
    for (const display of doc.display)
      if (display.metadata === MetadataEnum.VISIBILITY_KW && display.value === DocumentVisibility.NOT_VISIBLE)
        return true;

    return false;
  }

  whenDocumentsView(): Observable<DocumentViewModel[]> {
    return this.documents$;
  }

  // view specifica per piastrella errore passivo
  whenDocumentsViewForPayableError(): Observable<Document[][]> {
    return this.documentsViewPayableError$.asObservable();
  }

  sendDocumentsViewPayableError(documents: Document[][]) {
    this.documentsViewPayableError$.next(documents);
  }

  trasformDocumentsViewForPayableError(documents: IDocument[]): void {
    const arrayOfArray: Document[][] = [];
    const docs = Copier.deepCopy(documents);
    let docArray = [];

    for (let i = 0; i < docs.length; i++) {
      if (i === 0) docArray.push(Copier.deepCopy(docs[i]));

      if (i + 1 !== docs.length)
        if (docs[i].keys.hashDocKey === docs[i + 1].keys.hashDocKey)
          // se il doc hash è uguale a quello del doc successivo, aggiungo il doc successivo all'array
          docArray.push(Copier.deepCopy(docs[i + 1]));
        else {
          // se è diverso, lo aggiungo ad un array nuovo
          arrayOfArray.push(Copier.deepCopy(docArray));
          docArray = [Copier.deepCopy(docs[i + 1])];
        }
      else arrayOfArray.push(Copier.deepCopy(docArray));
    }
    this.sendDocumentsViewPayableError(Copier.deepCopy(arrayOfArray));
  }

  getDocumentsScrollPosition(): number {
    return this.documentsScrollPosition$.value;
  }

  sendDocumentsScrollPosition(position: number) {
    this.documentsScrollPosition$.next(position);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateDocumentsView(documents: Document[]): Document[] {
    if (documents !== null) {
      const docs: Document[] = [];
      for (let i = 0; i < documents.length; i++) {
        const doc = documents[i];
        const result = documents[i].display.find((obj) => obj.metadata === 'ultimoStatoPrem');
        if (result !== undefined)
          if (result.value === '9') {
            doc['grey'] = true;
            doc['stato'] = 'Firma in corso';
          }

        doc.hidden = DocumentsViewService.initDocumentHidden(doc);
        docs.push(doc);
      }
      return docs;
    }
    return null;
  }

  public isDocumentHidden(doc: Document): boolean {
    return !!this.alteredDocuments.find((value) => value.documentId === doc.documentId && value.hidden);
  }

  public isDocumentGrey(doc: Document): boolean {
    return !!this.alteredDocuments.find((value) => value.documentId === doc.documentId && value.grey);
  }

  public getDocumentStato(doc: Document): string {
    const found = this.alteredDocuments.find((value) => value.documentId === doc.documentId && value.stato);
    return (found && found.stato) || '';
  }

  // se c'è il cookie, aggiungo la proprietà grey a Document
  protected checkIfCookieExists(filters: Filter[], documents: Document[]): Document[] {
    const doc = <Document[]>documents;
    for (let i = 0; i < filters.length; i++)
      if (filters[i].metadata.startsWith('hubfe_'))
        if (filters[i].metadata !== 'hubfe_sezione') {
          for (let y = 0; y < doc.length; y++) {
            const cookie = this.getCurrentCookie(doc[y].documentId, filters[i].metadata);
            doc[y].grey = cookie !== null;
          }
          break;
        }

    return doc;
  }

  private createDocumentViewModel(d: Document) {
    return <DocumentViewModel>{
      ...d,
      docSeriesDescriptionColor: this.computeIfAbsentDocSeriesColor(d.docSeriesDescription),
    };
  }

  private computeIfAbsentDocSeriesColor(docSeriesDescr: string) {
    if (!docSeriesDescr) return null;

    const mapKey = docSeriesDescr?.trim();

    if (mapKey && this.availableDocSeriesDescriptionsColor.has(mapKey))
      return this.availableDocSeriesDescriptionsColor.get(mapKey);

    const colors = ColorList.getColorList();
    // eslint-disable-next-line no-bitwise
    const hash = Array.from(mapKey ?? '').reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);
    const colorIdx = Math.abs(hash % colors.length);
    const color = colors[colorIdx].hex;

    this.availableDocSeriesDescriptionsColor.set(mapKey, color);
    return color;
  }

  private getCurrentCookie(documentId: string, classification: string): unknown {
    let cookie: unknown = null;
    if (this.cookieService.check('HUBFE_CookiesForActions' + documentId + '_' + classification))
      cookie = JSON.parse(this.cookieService.get('HUBFE_CookiesForActions' + documentId + '_' + classification));

    return cookie;
  }
}
