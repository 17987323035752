import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable()
export class JsonToXmlHttpService {
  // endpoints
  private validation: string;
  private validationHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.validationHost = appConfig.gedInvoice.http.host;
    this.validation = `${this.validationHost}/v1/GedInvoice/ConvertToXML`;
  }

  public convertJsonToXml(_id, _sia, invoice: string) {
    const url_ = this.validation;
    const stringaDaInviare = invoice;

    const headers = new HttpHeaders().set('Content-Type', 'text/json');

    return this.http.post(url_, stringaDaInviare, {
      headers,
      responseType: 'text',
      params: {
        id: _id,
        sia: _sia,
      },
    });
  }
}
