import { Filter } from '@ctel/gaw-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { AccountType } from 'app/core/common/account/accountType';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const receivableFilterConfig: Filter = {
  metadata: MetadataEnum.HUBFE_SEZIONE,
  metadataDescription: '',
  type: 'string',
  filterType: 'term',
  configData: {},
  value: {
    term: 'CicloAttivo',
  },
};

export const payableFilterConfig: Filter = {
  metadata: MetadataEnum.HUBFE_SEZIONE,
  metadataDescription: '',
  type: 'string',
  filterType: 'term',
  configData: {},
  value: {
    term: 'CicloPassivo',
  },
};

export const accountFilter: Map<AccountType, Filter> = new Map([
  [AccountType.RECEIVABLE, receivableFilterConfig],
  [AccountType.PAYABLE, payableFilterConfig],
]);

export const timestampHomepageFilterConfig: Filter = {
  metadata: MetadataEnum.DATA_RICEZIONE,
  metadataDescription: 'Data ricezione',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const timestampCtelRicezioneSdiFilterConfig: Filter = {
  metadata: MetadataEnum.DATA_RICEZIONE,
  metadataDescription: 'Data ricezione',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const dataProtocollazioneCtelFilterConfig: Filter = {
  metadata: MetadataEnum.DATA_INSERIMENTO,
  metadataDescription: 'Data inserimento',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const dataInserimento22FilterConfig: Filter = {
  metadata: MetadataEnum.DATA_INSERIMENTO_22,
  metadataDescription: 'Data inserimento',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const dataInserimento21FilterConfig: Filter = {
  metadata: MetadataEnum.DATA_INSERIMENTO_21,
  metadataDescription: 'Data inserimento',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const dataRicezione06FilterConfig: Filter = {
  metadata: MetadataEnum.DATA_RICEZIONE_06,
  metadataDescription: 'Data inserimento',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const dataSpedizione10FilterConfig: Filter = {
  metadata: MetadataEnum.DATA_SPEDIZIONE_10,
  metadataDescription: 'Data inserimento',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const dataInserimento39FilterConfig: Filter = {
  metadata: MetadataEnum.DATA_INSERIMENTO_39,
  metadataDescription: 'Data inserimento',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};

export const docTypeFilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_SERIE_DOC_KW,
  metadataDescription: 'Tipo documento',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const primaryServiceStatusConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_KW,
  metadataDescription: 'Stato firma',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const status35FilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_35_KW,
  metadataDescription: 'Stato di dettaglio',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const descrizioneStato22FilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_22_KW,
  metadataDescription: 'Stato di dettaglio',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const descrizioneStato21FilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_21_KW,
  metadataDescription: 'Stato di dettaglio',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const descrizioneStato06FilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_06_KW,
  metadataDescription: 'Stato di dettaglio',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};
export const descrizioneStato10FilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_10_KW,
  metadataDescription: 'Stato di dettaglio',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const descrizioneStato39FilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_STATO_TNOTICE_KW,
  metadataDescription: 'Stato di dettaglio',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const deletedFlagPayableFilterConfig: Filter = {
  metadata: MetadataEnum.FLAG_ELIMINATO_37,
  metadataDescription: '',
  type: 'string',
  filterType: 'term',
  configData: {},
  value: {
    term: 'false',
  },
};

// Filtro formato trasmissione
export const transmissionFormatFilterConfig: Filter = {
  metadata: MetadataEnum.FORMATO_TRASMISSIONE_SDI_KW,
  metadataDescription: 'Formato trasmissione',
  type: 'string',
  filterType: 'termsAggs',
  configData: {},
  value: {
    terms: [],
  },
};

export const amountFilterConfig: Filter = {
  metadata: MetadataEnum.IMPORTO,
  metadataDescription: 'Importo',
  type: 'currency',
  filterType: 'rangeAggs',
  configData: {
    buckets: [
      {
        key: '0-500',
        from: '',
        to: '500.0',
        docCount: '0',
      },
      {
        key: '500-2000',
        from: '500.0',
        to: '2000.0',
        docCount: '0',
      },
      {
        key: '2000-10000',
        from: '2000.0',
        to: '10000.0',
        docCount: '0',
      },
      {
        key: '10000',
        from: '10000.0',
        to: '',
        docCount: '0',
      },
    ],
  },
  value: {
    ranges: [],
  },
};

export const gawconsFilterConfig: Filter = {
  metadata: MetadataEnum.GAWCONS,
  metadataDescription: 'gawcons',
  type: 'date',
  filterType: 'range',
  configData: {},
  value: {
    from: dayjs().subtract(3, 'month').utcOffset(0).set('h', 0).set('m', 0).set('s', 0).set('ms', 0).toISOString(),
    to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
  },
};
