import { HttpHeaders } from '@angular/common/http';

export class CustomHttpOptions {
  static getHttpOptionsObserveProgressEvent(
    contentType: string,
    responseType: string,
    appendHeaders?: HttpHeaders,
    log = false,
  ): object {
    const options = CustomHttpOptions.getHttpOptions(contentType, responseType, appendHeaders, log);
    return {
      ...options,
      reportProgress: true,
      observe: 'events',
    };
  }

  static getHttpOptions(contentType: string, responseType: string, appendHeaders?: HttpHeaders, log = false): object {
    let headers: HttpHeaders = appendHeaders ? appendHeaders : new HttpHeaders();
    headers = headers.set('Content-Type', contentType);

    if (log) headers = headers.set('log', 'true');

    let options;
    switch (responseType) {
      case 'blob':
        options = {
          headers,
          responseType: 'blob' as const,
        };
        break;
      case 'html':
        options = {
          headers,
          responseType: 'html' as const,
        };
        break;
      case 'text':
        options = {
          headers,
          responseType: 'text' as const,
        };
        break;
      default:
        options = {
          headers,
          responseType: 'json' as const,
        };
        break;
    }

    return options;
  }

  static getHttpOptionsObserveResponse(
    contentType: string,
    responseType: string,
    appendHeaders?: HttpHeaders,
    log = false,
  ): object {
    let headers: HttpHeaders = appendHeaders ? appendHeaders : new HttpHeaders();
    headers = headers.set('Content-Type', contentType);

    if (log) headers = headers.set('log', 'true');

    let options;
    switch (responseType) {
      case 'blob':
        options = {
          observe: 'response',
          headers,
          responseType: 'blob' as const,
        };
        break;
      case 'html':
        options = {
          observe: 'response',
          headers,
          responseType: 'html' as const,
        };
        break;
      case 'text':
        options = {
          observe: 'response',
          headers,
          responseType: 'text' as const,
        };
        break;
      default:
        options = {
          observe: 'response',
          headers,
          responseType: 'json' as const,
        };
        break;
    }

    return options;
  }
}
