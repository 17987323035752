import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ErrorTypes } from 'app/core/common/error';
import { EMPTY, Observable } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { EcmDocumentHierarchy, EcmDossierHierarchy, XmlPaAttachment } from '../entities/ecm/ecm';
import { WorkflowService } from '../services/workflow.service';
import { EcmHttpService } from './ecm-http.service';

/**
 * Servizio che gestisce la parte ecm
 */
@Injectable({
  providedIn: 'root',
})
export class EcmService {
  constructor(
    private ecmHttpService: EcmHttpService,
    private domSanitizer: DomSanitizer,
    private workflowService: WorkflowService,
    private router: Router,
  ) {}

  /**
   * Gerarchia del documento
   * @param documentId
   * @return Observable<EcmDocumentHierarchy>
   */
  whenDocumentHierarchy(documentId: string): Observable<EcmDocumentHierarchy> {
    return this.ecmHttpService.whenDocumentHierarchy(documentId);
  }

  /**
   * Ottiene gli allegati utene (ECM)
   * @param progSpool
   * @param progBusta
   */
  whenXmlPaAttachmentsList(progSpool: string, progBusta: string): Observable<XmlPaAttachment[]> {
    return this.ecmHttpService.whenXmlPaAttachmentsList(progSpool, progBusta).pipe(map((result) => result.attachments));
  }

  // gerarchia dossier
  whenDossierHierarchy(dossierId: string): Observable<EcmDossierHierarchy> {
    return this.ecmHttpService.whenDossierHierarchy(dossierId).pipe(
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
    );
  }

  /**
   * Ottiene le informazioni per la visualizzazione del documento
   *
   * @param documentId
   * @param docSeriesId
   */
  whenDocumentPdf(documentId: string, docSeriesId: string): Observable<Blob> {
    return this.ecmHttpService.whenDocumentPdf(documentId, docSeriesId).pipe(
      map((result) => new Blob([result], { type: 'application/pdf' })),
      // map(blob => this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)))
    );
  }

  /**
   * Elimina un allegato
   * @param attachmentId
   */
  whenDeleteAttachment(attachmentId: string): Observable<void> {
    return this.ecmHttpService.whenDeleteAttachment(attachmentId);
  }

  /**
   * Ottengo il contenuto di un attachment.
   * Se il content è `application/pdf` ottengo un Blob, altrimenti ottengo la stringa del contenuto
   *
   * @param attachmentId
   * @param attachmentName
   * @param mimeType
   * @return Observable<Blob | string>
   */
  whenAttachmentFile(attachmentId: string, attachmentName: string, mimeType: string): Observable<Blob | string> {
    switch (mimeType) {
      case 'application/pdf':
        return this.ecmHttpService
          .whenAttachmentPDFFile(attachmentId)
          .pipe(map((result) => new Blob([result], { type: mimeType })));
      default:
        return this.ecmHttpService.whenAttachmentTextFile(attachmentId);
    }
  }

  /**
   * Ottengo il contenuto di un allegato utente
   * Se il content è `application/pdf` ottengo un Blob, altrimenti ottengo la stringa del contenuto.
   *
   * @param attachment
   * @return Observable<Blob | string>
   */
  whenXmlPaAttachmentFile(attachment: XmlPaAttachment): Observable<Blob | string> {
    const download = false;

    return this.workflowService.selectedWorkItem$.pipe(
      take(1),
      filter((wi) => wi !== undefined),
      map((wi) => ({
        progSpool: wi.properties.find((prop) => prop.name === 'progSpool'), // */{value: '13264237'},
        progBusta: wi.properties.find((prop) => prop.name === 'progBusta'), // */{value: '1'}
      })),
      switchMap((obj) =>
        this.ecmHttpService.whenXmlPaAttachmentDownload(
          obj.progSpool.value,
          obj.progBusta.value,
          attachment.idAllegato,
          attachment.nomeAllegato,
          attachment.mimeType,
          download,
        ),
      ),
    );
  }

  whenXmlPaAttachmentDownload(
    attachment: XmlPaAttachment,
    progSpool: string,
    progBusta: string,
  ): Observable<Blob | string> {
    const download = false;
    return this.ecmHttpService.whenXmlPaAttachmentDownload(
      progSpool,
      progBusta,
      attachment.idAllegato,
      attachment.nomeAllegato,
      attachment.mimeType,
      download,
    );
  }
}
