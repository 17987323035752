import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { DateRange } from 'app/core/common/utilities/date/date-range';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { defineLocale, itLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { AbstractGridComponent } from './abstract-grid.component';
import { Column } from './interface/column';
import { Sorting } from './interface/events';

@Component({
  selector: 'gaw-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent<T1, T2 extends Field>
  extends AbstractGridComponent<T1, T2>
  implements AfterViewInit, OnDestroy
{
  @Output() allRowSelected = new EventEmitter<boolean>();
  @ViewChildren(PopoverDirective) popovers: QueryList<PopoverDirective>;

  sortType = Sorting;
  fieldType = FieldType;

  readonly allCheckboxSelected$: Observable<boolean>;

  // Oggetto utilizzato come model per il form dei filtri
  readonly formModel: { [p: string]: unknown } = {};

  public faIcons = FaIcons;

  private destroy$ = new Subject<void>();

  constructor(
    private _localeService: BsLocaleService,
    private readonly cdr: ChangeDetectorRef,
    protected logger: NGXLogger,
  ) {
    super(logger);
    // calendario in italiano
    defineLocale('it', itLocale);
    this._localeService.use('it');

    this.allCheckboxSelected$ = super.whenAllRowsSelected().pipe(
      distinctUntilChanged(),
      debounceTime(50),
      tap((value) => this.allRowSelected.emit(value)),
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    // Permette di tenere aperto un solo popover alla volta
    this.popovers.forEach((popover: PopoverDirective) => {
      popover.onShown.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.popovers.filter((p) => p !== popover).forEach((p) => p.hide());
      });
    });
  }

  /**
   * Funzione di eliminazione di un filtro
   * @param c Colonna su cui eliminare il filtro
   * @param popover
   */
  deleteFilter(c: Column<Field>, popover: PopoverDirective) {
    // Chiudo il popover
    popover.isOpen = false;

    if (!this.formModel[c.field.id]) return;

    // Tolgo il filtro dal model dei filtri
    this.formModel[c.field.id] = undefined;
    super.removeFilter(c);
  }

  /**
   * Funzione di inserimento filtro
   * @param c Colonna su cui eseguire il filtro
   * @param value Valore del filtro
   * @param popover
   */
  insertFilter(c: Column<Field>, value: unknown, popover: PopoverDirective) {
    popover.isOpen = false;
    value = c.field.type === FieldType.DATE && value ? new DateRange(value[0], value[1]) : value;
    super.addFilter(c, value);
  }

  /**
   * Funzione di trackBy per ottimizzare l'ngFor sugli item
   * @param index
   * @param item
   */
  gridTrackBy(index: number, item: unknown) {
    return this.options && this.options.getRowId ? this.options.getRowId(item) : item;
  }

  /**
   * Function called when user change date input
   * @param model
   */
  onPickerChange(model: NgModel) {
    // FIXME: Logica solo per il DateRange, creare ValueAccessor specifico
    // Se viene inserita solo una data, setto il range al giorno dopo
    if (model.value && model.value[0] && !model.value[1]) {
      const val = [model.value[0], model.value[0]];
      model.control.setValue(val);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
