import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable()
export class CausaliPagamentoHttpService {
  // endpoints
  private causaliEndpoint: string;
  private gedInvoice: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.gedInvoice = appConfig.gedInvoice.http.host;
    this.causaliEndpoint = `${this.gedInvoice}/v2/GedInvoice/Resources/CausaliPagamento`;
  }

  public getCausali() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get(this.causaliEndpoint, httpOptions);
  }
}
