import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocSeriesColor, DocumentDetails, DocumentKey, IDetailsComponent, Memoize, Metadata } from '@ctel/gaw-commons';
import {
  ActionsService2,
  DocumentDetailsService,
  DocumentsSeriesService,
  ExecuteActionsService,
  LottoDetailService,
  SingleActionsCode,
} from '@ctel/gawlotti-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'gaw-gawlotti-detail',
  templateUrl: './gawlotti-detail.component.html',
  styleUrls: ['./gawlotti-detail.component.scss'],
})
export class GAWLOTTIDetailComponent implements OnChanges {
  @Input() data: IDetailsComponent;

  public singleActionsList$: Observable<ActionObject[]> = this.actionsService.whenSingleActionsList();
  public singleActionsList: ActionObject[];
  public actionLoadingError$: Observable<boolean>;
  public documentDetails$: Observable<DocumentDetails>;
  public metadatiCanale$: Observable<DocumentDetails>;
  public singleActionsCode = SingleActionsCode;
  public metadataEnum = MetadataEnum;
  public actionCode = ActionCode;
  public documentSeriesColors$: Observable<DocSeriesColor[]>;

  constructor(
    private executeActionsService: ExecuteActionsService,
    private actionsService: ActionsService2,
    private documentDetailsService: DocumentDetailsService,
    private documentSeriesService: DocumentsSeriesService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private lottoDetailService: LottoDetailService,
  ) {}

  @Memoize()
  getDocLabel(metadataList: Metadata[]) {
    const docLabel = metadataList.find((m) => m.keyCode === this.metadataEnum.DOCLABEL);
    return docLabel && docLabel.value ? docLabel.value : 'Doc Label sconosciuta';
  }

  executeAction(action: ActionObject, keys: DocumentKey) {
    // per il workflow salvo l'id serie doc reale (in caso venissi dalla all)
    const keysArray = Object.entries(keys).map(([key, value]) => ({ key, value }));
    for (const item of keysArray)
      if (item.key === 'ctelDocSeriesId') this.documentDetailsService.sendRealDocSeriesId('' + item.value);

    this.executeActionsService.executeSingleAction(action, keys, false);
  }

  ngOnChanges(): void {
    // OTTENGO I METADATI E LE AZIONI DEL DOCUMENTO
    // this.documentDetails$ = this.documentDetailsService.whenDocumentDetails(
    // 	this.data.licenseId,
    // 	this.data.siaCode,
    // 	this.data.docSeriesId,
    // 	this.data.elasticId,
    // 	true
    // );
    this.documentDetails$
      .pipe(
        switchMap(() =>
          this.actionsService.requestSingleActionsConfig(
            this.data.licenseId,
            this.data.siaCode,
            this.route.snapshot.params.section,
          ),
        ),
        take(1),
      )
      .subscribe();

    // AZIONI DOCUMENTO
    this.singleActionsList$ = this.actionsService.whenSingleActionsList();

    // Colori Serie documentale
    this.documentSeriesColors$ = this.documentSeriesService.whenDocumentsSeriesColors();
  }

  showModal = () => {
    this.notificationService.showModal(NotificationType.GENERAL, {
      title: 'Esporta risultati in Excel',
      childComponent: ExcelFormatModalComponent,
      customFooter: true,
      childData: {
        backButton: true,
        action: { url: '/api/v1/Export/ExportExcelDettaglioLotti' },
        service: 'GAWLOTTIDetail',
        keys: { ...this.data.keys, tipoCanale: this.lottoDetailService.tipoCanale },
      },
    });
  };
}
