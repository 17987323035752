import { ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { Column, Row } from '..';
import { CellRenderer } from './cell-renderer';
import { DateCellComponent } from './date-cell/date-cell.component';
import { DecimalCellComponent } from './decimal-cell/decimal-cell.component';
import { NumberCellComponent } from './number-cell/number-cell.component';
import { TextCellComponent } from './text-cell/text-cell.component';

@Directive({
  selector: '[gridCellRenderer]',
})
export class CellRendererDirective<T1, T2 extends Field> implements OnInit, OnChanges {
  @Input() column: Column<T2>;
  @Input() row: Row<T1>;

  private component: ComponentRef<unknown>;

  constructor(private container: ViewContainerRef) {}

  ngOnInit(): void {
    const component = this.column.cellComponentRender ? this.column.cellComponentRender : this.getDefaultComponent();
    this.component = this.container.createComponent(component);
    this.component.instance['column'] = this.column;
    this.component.instance['value'] = this.row.data[this.column.field.id];
    this.component.instance['row'] = this.row;
  }

  ngOnChanges(): void {
    if (this.component) {
      this.component.instance['column'] = this.column;
      this.component.instance['value'] = this.row.data[this.column.field.id];
      this.component.instance['row'] = this.row;
    }
  }

  private getDefaultComponent(): Type<CellRenderer> {
    switch (this.column.field.type) {
      case FieldType.DECIMAL:
        return DecimalCellComponent;
      case FieldType.NUMBER:
        return NumberCellComponent;
      case FieldType.DATE:
        return DateCellComponent;
      default:
        return TextCellComponent;
    }
  }
}
