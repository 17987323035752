// per richiesta documenti
import {
  IFilter,
  IFilterStatus,
  IFilterUrl,
  IFullTextSearch,
  IMetric,
  IOrderBy,
  IPaging,
  IRange,
  IValue,
} from '@ctel/search-filter-store';

export type FilterPayload = IFilterStatus;

export type Filter = IFilter;

export enum HomeFilterRangeType {
  NESSUN_FILTRO = 'nessunFiltro',
  ULTIMO_TRIMESTRE = 'ultimoTrimestre',
  DA_INIZIO_ANNO = 'inizioAnno',
  MESE_SCORSO = 'meseScorso',
  MESE_CORRENTE = 'meseCorrente',
  GIORNO_PRECEDENTE = 'giornoPrecedente',
  CUSTOM = 'custom',
}

export interface HomeFilter extends Filter {
  dateRangeType?: HomeFilterRangeType;
}
export interface HomeFilterUrl extends IFilterUrl {
  t: HomeFilterRangeType;
}

export type Value = IValue;

export type Range = IRange;

export type Metric = IMetric;

export type FullTextSearch = IFullTextSearch;

export type Paging = IPaging;

export type OrderBy = IOrderBy;
