import { Injectable } from '@angular/core';
import { Document, Filter, FiltersResponse } from '@ctel/gaw-commons';
import {
  DocumentActions,
  DocumentState,
  IFilterStatus,
  getDocuments,
  getTotalDocuments,
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { Copier } from 'app/core/common/utilities/copier';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ExtendedDocumentState, selectExtendedDocumentState } from './store/document.extended';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
  providedIn: 'root',
})
export class FRLottiDocumentsService2 {
  public sectionColumns$: Observable<{
    primaryConfig: Column[];
    secondaryConfig: Column[];
  }>;

  private destroy$ = new Subject<void>();
  private readonly documents$: Observable<Document[]>;
  private readonly totalDocuments$: Observable<number>;
  private documentsArray: Document[];
  private errorLoadingDocuments$ = new BehaviorSubject<boolean>(false);
  private errorDocList$ = new BehaviorSubject<boolean>(false);
  private loadingDocs$ = new BehaviorSubject<boolean>(true);
  private loadingDocsAfterFilterApplication$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnPaging$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnSectionChange$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store<DocumentState>) {
    this.documents$ = this.store.pipe(select(getDocuments)) as Observable<Document[]>;
    this.totalDocuments$ = this.store.pipe(select(getTotalDocuments));

    this.documents$
      .pipe(
        tap((value) => (this.documentsArray = value)),
        takeUntil(this.destroy$),
      )
      .subscribe();
    this.sectionColumns$ = this.store.pipe(
      select(selectExtendedDocumentState),
      map((value: ExtendedDocumentState) => ({
        primaryConfig: value.primaryColumnConfig,
        secondaryConfig: value.secondaryColumnConfig,
      })),
    );
  }

  whenDocuments(): Observable<Document[]> {
    return this.documents$.pipe(map((documents) => documents.map((document) => ({ ...document, show: false }))));
  }

  whenTotalDocuments(): Observable<number> {
    return this.totalDocuments$;
  }

  mergeDocSeriesId(sectionRelatedData: RelatedSectionData[]): string[] {
    let docSeries: string[] = [];
    for (const data of sectionRelatedData) docSeries.concat(data.docSeriesIds);

    sectionRelatedData.forEach((data) => {
      docSeries = docSeries.concat(data.docSeriesIds);
    });
    return docSeries;
  }

  /**
   * Crea un payload per i filtri a partire da filtri e colonne.
   * Il payload verrà utilizzato per le successive richieste alla /search di Magellano.
   * Questo metodo è utilizzato nella gestione dinamica dei filtri.
   * Altera parzialmente i filtri per impostare gli eventuali filtri data dalla home (con default a ultimo trimestre).
   * Imposta anche metric e orderby.
   *
   * @param licenseId la licenza corrente
   * @param siaCode l'azienda corrente
   * @param sectionId
   * @param filterPayload il payload dei filtri precedentemente fetchati da mocks/API
   */
  public buildFilterPayload(
    licenseId: string,
    siaCode: string,
    sectionId: string,
    filterPayload: FiltersResponse,
  ): IFilterStatus {
    const currentFilters: Filter[] = this.patchFiltersPayload(filterPayload.filters);
    return {
      docSeriesId: sectionId,
      licenseId,
      siaCode,
      search: filterPayload.search,
      paging: filterPayload.paging,
      orderBy: filterPayload.orderBy,
      filters: currentFilters,
    };
  }

  public patchFiltersPayload(filters: Filter[]): Filter[] {
    // Ribalto i value dei filtri da home in quelli attuali se li ho.
    // Per alcuni metadata imposto i from/to opportunamente.
    const currentFilters = Copier.deepCopy(filters);
    currentFilters.forEach((fetchedFilter: Filter) => {
      // La preffilters per HUBFE ritorna dei termsAggs senza l'oggetto buckets vuoto.
      if (fetchedFilter.filterType === 'termsAggs' && !fetchedFilter.configData.buckets)
        fetchedFilter.configData.buckets = [];

      // Impostiamo un default per i configData dei range di date.
      if (
        fetchedFilter.filterType === 'range' &&
        fetchedFilter.type === 'date' &&
        (!fetchedFilter.configData || (!fetchedFilter.configData.from && !fetchedFilter.configData.to))
      )
        fetchedFilter.configData = {
          from: dayjs()
            .set('y', 1900)
            .set('M', 1)
            .set('D', 1)
            .set('h', 0)
            .set('m', 0)
            .set('s', 0)
            .set('ms', 0)
            .toISOString(),
          to: dayjs().add(1, 'year').toISOString(),
        };
    });
    return currentFilters;
  }

  /**
   * forza refresh griglia documenti
   */
  refreshDocuments() {
    this.store.dispatch(DocumentActions.fetchDocuments(true));
  }

  public setLoadingDocs(value: boolean) {
    this.loadingDocs$.next(value);
  }

  public whenLoadingDocs(): Observable<boolean> {
    return this.loadingDocs$.asObservable();
  }

  public setLoadingDocsAfterFilterApplication(value: boolean) {
    this.loadingDocsAfterFilterApplication$.next(value);
  }

  public whenLoadingDocsAfterFilterApplication(): Observable<boolean> {
    return this.loadingDocsAfterFilterApplication$.asObservable();
  }

  public setLoadingDocsOnPaging(value: boolean) {
    this.loadingDocsOnPaging$.next(value);
  }

  public whenLoadingDocsOnPaging(): Observable<boolean> {
    return this.loadingDocsOnPaging$.asObservable();
  }

  public setLoadingDocsOnSectionChange(value: boolean) {
    this.loadingDocsOnSectionChange$.next(value);
  }

  public whenLoadingDocsOnSectionChange(): Observable<boolean> {
    return this.loadingDocsOnSectionChange$.asObservable();
  }

  public whenErrorLoadingDocs(): Observable<boolean> {
    return this.errorLoadingDocuments$.asObservable();
  }

  public setErrorLoadingDocs(value: boolean) {
    this.errorLoadingDocuments$.next(value);
  }
}
