import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
  imports: [],
  providers: [],
  exports: [],
})
export class ElementRefModule {
  static forRoot(): ModuleWithProviders<ElementRefModule> {
    return {
      ngModule: ElementRefModule,
    };
  }
}
