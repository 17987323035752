import { Component, Input } from '@angular/core';
import { LargePlaceholderComponent } from '@ctel/placeholders';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Upload } from 'app/entities/drag-and-drop-upload/upload';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { DetailsService } from 'app/modules/details/services/details.service';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ActionsService2 } from '../documents-search/actions/actions.service';

@Component({
  selector: 'upload-document-modal',
  templateUrl: './upload-document-modal.component.html',
  styleUrls: ['./upload-document-modal.component.scss'],
})
export class UploadDocumentModalComponent {
  @Input() data: {
    progressivoLotto: string;
    progressivo: string;
    idSospeso: string;
    nomeFlusso: string;
    idFilePath: string;
  };
  @Input() modal: BsModalRef;

  loadingTemplate = LargePlaceholderComponent;
  public fileLoaded: boolean;
  public faIcons = FaIcons;
  public currentUpload: Upload;
  public maxFileSize = 131072000;
  public classHovered = 'jumbotron uploadPage';
  public dropzoneActive = false;
  loading = false;
  channels: string[];
  modes: string[];

  formato = 'pdf';
  fileName: string;
  fileAppend: boolean;
  errorMessage: string;
  private file: File;

  constructor(
    private notificationService: NotificationService,
    private actionsService: ActionsService2,
    private detailService: DetailsService,
  ) {}

  handleDrop(fileList: FileList) {
    const filesIndex = _.range(fileList.length);
    _.each(filesIndex, (idx) => {
      this.currentUpload = new Upload(fileList[idx]);
    });

    const file: File = fileList[0];
    if (!file) return Promise.resolve();

    this.createImport(file);
    if (this.checkFile(file)) {
      this.fileName = file.name;
      this.file = file;
      this.fileLoaded = true;
    } else {
      this.fileName = undefined;
      this.file = undefined;
      this.fileLoaded = false;
    }
  }

  dropzoneState($event: boolean) {
    if (!$event) this.classHovered = 'jumbotron uploadPage';
    else this.classHovered = 'jumbotron boxHovered';

    this.dropzoneActive = $event;
  }

  public onFileChange(e: any): Promise<void> {
    const file = e.target.files[0];
    if (!file) return Promise.resolve();

    this.createImport(file);
  }

  uploadDocument() {
    this.errorMessage = undefined;
    this.actionsService
      .whenUploadPdf(
        this.data.progressivoLotto,
        this.data.progressivo,
        this.data.idSospeso,
        this.data.nomeFlusso,
        this.data.idFilePath,
        this.file,
        this.fileName,
      )
      .pipe(
        take(1),
        catchError((err: unknown) => {
          if (Array.isArray(err['cause']?.error))
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Rilevati file corrotti',
              err['cause']?.error,
            );

          return EMPTY;
        }),
      )
      .subscribe((value) => {
        if (!value.isError) {
          this.notificationService.showSweetAlert(
            NotificationType.SUCCESS,
            '',
            value.message,
            () => this.modal.hide(),
            () => this.modal.hide(),
          );
          setTimeout(() => this.detailService.sendRefreshDetails(), 3000);
        } else this.errorMessage = value.message;
      });
  }

  goBack() {
    this.modal.hide();
  }

  removeFile() {
    this.fileName = null;
    this.fileLoaded = false;
    this.errorMessage = undefined;
  }

  private checkMaxFileSize(fileSizeLoaded: number): boolean {
    return fileSizeLoaded <= this.maxFileSize;
  }

  private createImport(file: File): void {
    if (this.checkFile(file)) {
      this.fileName = file.name;
      this.file = file;
      this.fileLoaded = true;
    } else {
      this.fileName = undefined;
      this.file = undefined;
      this.fileLoaded = true;
    }
  }

  private checkFile(file: File): boolean {
    // Check file size
    if (this.checkMaxFileSize(file.size))
      if (this.checkFileExt(file, this.formato))
        // Check file extension
        return true;
      else {
        this.notificationService.showSweetAlert(
          NotificationType.ERROR,
          `Il file deve avere estensione .${this.formato}`,
          '',
        );
        return false;
      }
    else {
      this.notificationService.showSweetAlert(
        NotificationType.ERROR,
        'File troppo grande, la dimensione massima consentita è ' + this.maxFileSize / 1048576 + ' Mb',
        '',
      );
      return false;
    }
  }

  private checkFileExt(file: File, ext: string): boolean {
    const fileEx = file.name.split('.').pop().toLowerCase();
    return fileEx === ext.split('.').pop().toLowerCase();
  }
}
