import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from '@ctel/block-ui';
import { IDetailsComponent, LottoDetail } from '@ctel/gaw-commons';
import { LargePlaceholderComponent } from '@ctel/placeholders';
import { ElementRefService } from 'app/core/business/element-ref/element-ref.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LottoDetailService } from '../../../services/lotto-detail.service';

@Component({
  selector: 'gaw-gawlotti-lotto-detail',
  templateUrl: './gawlotti-lotto-detail.component.html',
  styleUrls: ['./gawlotti-lotto-detail.component.scss'],
})
export class GawlottiLottoDetailComponent implements OnInit, OnDestroy {
  @Input() data: IDetailsComponent;
  @BlockUI('documents-bottom-loader') documentsBottomLoader: NgBlockUI;

  blockTemplate = LargePlaceholderComponent;

  lottoDetail$: Observable<LottoDetail>;
  isFirstLoading = true;
  faIcons = FaIcons;
  private destroy$ = new Subject<void>();
  private maxLength;

  constructor(
    public lottoDetailService: LottoDetailService,
    private elementRefService: ElementRefService,
  ) {}

  ngOnInit() {
    this.loadDetails(this.isFirstLoading);
    this.lottoDetail$ = this.lottoDetailService.whenLottoDetails().pipe(
      map((value) => {
        if (value && value.details && value.details[0]) {
          this.maxLength = value.details[0].length;
          value.details.forEach((value1) => {
            if (value1.length < this.maxLength) value1.push({ value: '' });
          });
        }
        return value;
      }),
    );
    this.isFirstLoading = false;
  }

  getSpool(elasticId: string): string {
    return elasticId.split('_')[0];
  }

  getCanale(elasticId: string): string {
    return elasticId.split('_')[1];
  }

  loadDetails(isFirstLoading) {
    this.lottoDetailService.sendLottoDetails(
      this.getSpool(this.data.elasticId),
      this.data.keys ? this.data.keys['tipoCanale'] : this.getCanale(this.data.elasticId),
      isFirstLoading,
    );
  }

  ngOnDestroy(): void {
    this.lottoDetailService.resetLottoDetailList();
    this.destroy$.next();
    this.destroy$.complete();
  }

  scrollToTop() {
    this.elementRefService
      .whenTopPage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((elementRef) => {
        if (elementRef) elementRef.nativeElement.scrollIntoView();
      });
  }
}
