import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che tronca i campi più lunghi di 60 caratteri
 * ed aggiunge puntini di sospensione
 */
@Pipe({
  name: 'shorten',
})
export class ShortenPipe implements PipeTransform {
  transform(value: string, maxLength: number | string) {
    if (value !== null && value !== undefined && value !== '') {
      if (value.length > +maxLength) value = value.substring(0, +maxLength).trim() + '...';
    } else if (value === '') value = '\xa0';

    return value;
  }
}
