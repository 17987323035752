import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  DocSeriesMetadataDesc,
  DocSeriesMetadataDescSearch,
  DocumentDetails,
  DocumentsResponse,
  FiltersResponse,
  GraphicInfoResponse,
} from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { SearchBarTypeahead } from 'app/entities/search-bar/search-bar-typeahead';
import { Sections } from 'app/entities/sections/sections';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai workflow
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsHttpService implements OnDestroy {
  private jsonContentType = 'application/json';
  // host
  private searchHost: string;
  private configHost: string;
  private docSeriesHost: string;
  private updateHost: string;
  private docListDetails: string;
  private uiConfigHost: string;
  private archivioFiscaleHost: string;
  private docDetailsHost: string;
  private documentiHost: string;
  // endpoints
  private search: string;
  private favoriteFilters: string;
  private docSeriesInfo: string;
  private graphic: string;
  private docDetail: string;
  private updateTags: string;
  private typeAhead: string;
  private favoritesSections: string;
  private displayDocument: string;
  private displayMessage: string;
  private downloadPdfZipUrl: string;
  private docDetails: string;
  private metadata: string;
  private orchestratorHost: string;
  private metadataDesc: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    public spinnerService: FullScreenSpinnerService,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */

    this.archivioFiscaleHost = appConfig.gawConsArchivioFiscaleRead.http.host;
    this.orchestratorHost = appConfig.webConsOrchestrator.http.host;
    this.documentiHost = appConfig.gawConsDocumentiRead.http.host;
    this.searchHost = appConfig.search.http.host;
    this.docDetail = `${this.archivioFiscaleHost}/v1/archivioFiscale/magellano/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?includeTechnicalMetadata={includeTechnical}`;

    this.updateHost = appConfig.update.http.host;
    this.updateTags = `${this.updateHost}/v1/document/update`;

    /* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
    this.docDetailsHost = appConfig.docDetails.http.host;

    /* ENDPOINTS DOCSERIES API ------------------------------------------------------------------------------------------------------ */
    this.docSeriesHost = appConfig.docSeries.http.host;
    this.docSeriesInfo = `${this.docSeriesHost}/v1/docseries/{licenseId}/{siaCode}/public`;
    this.graphic = `${this.docSeriesHost}/v1/docseries/{docSeriesId}/public`;

    /* ENDPOINTS UI CONFIGURATION --------------------------------------------------------------------------------------------------- */
    this.configHost = appConfig.uiConfiguration.http.host;
    this.uiConfigHost = appConfig.uiConfiguration.http.host;
    this.favoritesSections = `${this.uiConfigHost}/v1/favorites/ui/GAWCONS/sections?licenseId={licenseId}&siaCode={siaCode}`;

    /* ENDPOINTS GAWCONS --------------------------------------------------------------------------------------------------- */

    this.favoriteFilters = `${this.configHost}/v1/favorites/ui/GAWCONS/filters?sectionCode={sectionCode}&licenseId={licenseId}&siaCode={siaCode}`;
    this.docDetails = `${this.archivioFiscaleHost}/v1/archivioFiscale/magellano/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?includeTechnicalMetadata={includeTechnical}`;
    this.metadata = `${this.archivioFiscaleHost}/v1/archivioFiscale/metadati/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/{docId}/{versionId}/{registryId}/{fiscalYear}/details?includeTechnicalMetadata={includeTechnical}`;
    this.metadataDesc = `${this.docSeriesHost}/v3/docseries/metadata/descriptionssasasas`;

    this.search = `${this.orchestratorHost}/v1/ArchivioFiscale/documenti/ricerca`;
    this.downloadPdfZipUrl = `${this.documentiHost}/v1/documenti/scarica/{licenseId}/{siaCode}/{idDoc}/{idVersion}/{download}`;
  }

  /* HTTP REQUESTS A DICKENS---------------------------------------------------------------------------------------------- */
  whenTagsUpdate(body: string): Observable<any> {
    const url = this.updateTags;
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    return this.http.post<any>(url, body, options);
  }

  /* HTTP REQUESTS A DOC SERIES API ------------------------------------------------------------------------------------------ */

  // ottengo la grafica data una serie doc
  whenGraphic(docSeriesId: string): Observable<GraphicInfoResponse> {
    const url = this.graphic.replace('{docSeriesId}', docSeriesId);
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    return this.http.get<GraphicInfoResponse>(url, options);
  }

  /* HTTP REQUESTS A MAGELLANO ---------------------------------------------------------------------------------------------- */

  // FAVORITES FILTERS
  whenFavoriteFilters(
    registryId: string,
    licenseId: string,
    siaCode: string,
    sectionCode: string,
  ): Observable<FiltersResponse> {
    return this.configService.whenAppConfig().pipe(
      filter((value) => value !== null),
      switchMap(() => {
        const options = this.getHttpOptions(this.jsonContentType, 'json');
        const url = this.favoriteFilters
          .replace('{registryId}', registryId)
          .replace('{licenseId}', licenseId)
          .replace('{siaCode}', siaCode)
          .replace('{sectionCode}', sectionCode);

        return this.http.get<FiltersResponse>(url, options);
      }),
    );
  }

  // GET tutti i tipi documento per quel cliente

  // GET tutte le szioni per quel cliente
  whenSections(licenseId: string, siaCode: string): Observable<Sections> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    const url = this.favoritesSections.replace('{licenseId}', licenseId).replace('{siaCode}', siaCode);
    return this.http.get<Sections>(url, options);
  }

  // RITORNA LISTA DOCUMENTI
  whenAllDocuments(body: string): Observable<DocumentsResponse> {
    const options = this.getHttpOptions(this.jsonContentType, 'json', true);
    const url = this.search;
    return this.http.post<DocumentsResponse>(url, body, options);
  }

  // DETTAGLIO METADATI DEL DOCUMENTO
  whenDocumentDetails(
    licenseId: string,
    siaCode: string,
    docSeriesId: string,
    elasticDocumentId: string,
    includeTechnical: boolean,
  ): Observable<DocumentDetails> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');

    const url = this.docDetail
      .replace('{docSeriesId}', docSeriesId)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{elasticDocumentId}', elasticDocumentId)
      .replace('{includeTechnical}', `${includeTechnical}`);

    return this.http.get<DocumentDetails>(url, options).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  whenMetadata(
    licenseId: string,
    siaCode: string,
    docSeriesId: string,
    elasticDocumentId: string,
    docId: string,
    versionId: string,
    registryId: string,
    fiscalYear: string,
    includeTechnical: boolean,
  ): Observable<DocumentDetails> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');

    const url = this.metadata
      .replace('{docSeriesId}', docSeriesId)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{elasticDocumentId}', elasticDocumentId)
      .replace('{docId}', docId)
      .replace('{versionId}', versionId)
      .replace('{registryId}', registryId)
      .replace('{fiscalYear}', fiscalYear)
      .replace('{includeTechnical}', `${includeTechnical}`);

    return this.http.get<DocumentDetails>(url, options).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // Typeahead su ragione sociale nel bandone di ricerca
  whenSearchTypeahead(body: any): Observable<SearchBarTypeahead> {
    const url = this.typeAhead;
    return this.spinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((headers) =>
        this.http.post<SearchBarTypeahead>(url, body, {
          headers,
        }),
      ),
    );
  }

  /**
   * Ritorna il dettaglio dei documenti dato le chiavi
   * @param documentsKeys
   * @param includeTechnicalMetadata
   * @param includeDocumentMetadata
   */
  getDocumentsDetails(
    documentsKeys: { ctelElasticDocumentId: string; ctelDocSeriesId: string }[],
    includeTechnicalMetadata: boolean,
    includeDocumentMetadata: boolean,
  ): Observable<DocumentDetails[]> {
    const url = this.docListDetails;

    const body = { documentsKeys, includeTechnicalMetadata, includeDocumentMetadata };
    return this.http.post<DocumentDetails[]>(url, body);
  }

  whenDisplayDocument(
    licenseId: string,
    siaCode: string,
    idDocumento: string,
    idVersione: string,
    download: boolean,
  ): Observable<any> {
    const url = this.downloadPdfZipUrl
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{idDoc}', idDocumento)
      .replace('{idVersion}', idVersione)
      .replace('{download}', `${download}`);
    let options;
    if (download) {
      options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob');
    } else {
      options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');
    }

    return this.http.get<any>(url, options);
  }

  whenDisplayMessage(
    serviceName: string,
    progSpool: string,
    progBusta: string,
    docHash: string,
    download: boolean,
    docType: string,
  ): Observable<any> {
    const url = this.displayMessage
      .replace('{serviceName}', serviceName)
      .replace('{progSpool}', progSpool)
      .replace('{progBusta}', progBusta)
      .replace('{docHash}', docHash === '' ? null : docHash)
      .replace('{docType}', docType)
      .replace('{download}', String(download));

    let options;
    if (download) {
      options = CustomHttpOptions.getHttpOptionsObserveResponse(this.jsonContentType, 'blob');
    } else {
      options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'blob');
    }

    return this.http.get<any>(url, options);
  }

  whenMetadataDescriptions(body: DocSeriesMetadataDescSearch): Observable<DocSeriesMetadataDesc[]> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.metadataDesc;
    return this.http.post<DocSeriesMetadataDesc[]>(url, body, options);
  }

  private getHttpOptions(contentType: string, responseType: string, log = false): object {
    let headers = new HttpHeaders().set('Content-Type', contentType);
    if (log) {
      headers = headers.set('log', 'true');
    }
    let options;
    switch (responseType) {
      case 'blob':
        options = {
          headers,
          responseType: 'blob' as const,
        };
        break;
      case 'json':
      default:
        options = {
          headers,
          responseType: 'json' as const,
        };
        break;
    }
    return options;
  }
}
