import { Injectable, OnDestroy } from '@angular/core';
import { FullTextSearch } from '@ctel/gaw-commons';
import {
  FilterActions,
  FilterState,
  getFullTextSearchValueWithUserValuesForSearch,
  getFullTextSearchWithUserValuesForSearch,
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx). Questa classe è quasi esclusivamente noop.
 */
@Injectable({
  providedIn: 'root',
})
export class FullTextSearchService2 implements OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly fullTextSearch$: Observable<FullTextSearch>;
  private readonly fullTextSearchValue$: Observable<string>;
  private fullTextSearch: FullTextSearch;

  constructor(private store: Store<FilterState>) {
    this.fullTextSearch$ = this.store.pipe(select(getFullTextSearchWithUserValuesForSearch));
    this.fullTextSearchValue$ = this.store.pipe(select(getFullTextSearchValueWithUserValuesForSearch));
    this.fullTextSearch$.pipe(takeUntil(this.destroy$)).subscribe((value) => (this.fullTextSearch = value));
  }

  private static isImport(value: string) {
    const reg = new RegExp('^(((\\d{1,3})((\\.\\d{3})*)|(\\d*))(,\\d{1,2})?)$');
    return reg.test(value.trim());
  }

  private static sanitizeImport(value: string) {
    return value.trim().split('.').join('').replace(',', '.');
  }

  whenFullTextSearch(): Observable<FullTextSearch> {
    return this.fullTextSearch$;
  }

  getFullTextSearchValue(): string {
    return this.fullTextSearch.value;
  }

  getFullTextToggleValue(): boolean {
    return this.fullTextSearch.fullText;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendFullTextSearchValue(value: string, urlCommands: string[] = null) {
    if (value && FullTextSearchService2.isImport(value)) value = FullTextSearchService2.sanitizeImport(value);

    this.store.dispatch(
      FilterActions.changeFilterRequested({
        kind: FilterActions.ChangeFilterRequestedKind.FullTextSearchChanged,
        metadata: '',
        fullTextSearchValue: value ? value : '',
        urlCommands,
      }),
    );
  }

  sendFullTextToggleValue(value: boolean) {
    this.store.dispatch(
      FilterActions.changeFilterRequested({
        kind: FilterActions.ChangeFilterRequestedKind.FullTextSearchChanged,
        metadata: '',
        fullTextSearchToggle: value,
      }),
    );
  }

  whenFullTextSearchValue(): Observable<string> {
    return this.fullTextSearchValue$;
  }
}
