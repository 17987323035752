import { FiltersResponse } from '@ctel/gaw-commons';
import { createAction } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';

export const sectionColumnsRequested = createAction(
  '[Filter Effect GAWPOST] sectionColumnsRequested',
  (filterResponse: FiltersResponse = null) => ({ payload: { filterResponse } }),
);

export const relatedSearchDataRequested = createAction(
  '[Filter Effect GAWPOST] relatedSearchDataRequested',
  (filterResponse: FiltersResponse = null, primaryConfig: Column[] = [], secondaryConfig: Column[]) => ({
    payload: {
      filterResponse,
      primaryConfig,
      secondaryConfig,
    },
  }),
);
