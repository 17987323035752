import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, take } from 'rxjs';
import { Responsabili } from '../entities/responsabili';
import { ResponsabiliHttpService } from './responsabili-http.service';

@Injectable({
  providedIn: 'root',
})
export class ResponsabiliService {
  private responsabili$ = new ReplaySubject<Responsabili[]>(1);
  private actions$ = new ReplaySubject(1);

  constructor(private responsabiliHttpService: ResponsabiliHttpService) {}

  getActions() {
    this.responsabiliHttpService
      .getActions()
      .pipe(take(1))
      .subscribe((value) => this.actions$.next(value));
  }

  whenActions() {
    return this.actions$.asObservable();
  }

  getResponsabili(licenseId: string, siaCode: string) {
    this.responsabiliHttpService
      .whenResponsabili(licenseId, siaCode)
      .pipe(take(1))
      .subscribe((value) => this.responsabili$.next(value));
  }

  whenResponsabili(): Observable<Responsabili[]> {
    return this.responsabili$;
  }
}
