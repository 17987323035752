import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionsConfigRequest, ActionsConfigResponse } from 'app/entities/ui-config/action/actions-configuration';
import { Observable } from 'rxjs';
import { SectionEnum } from 'app/entities/anomalies/section-enum';

/**
 * Servizio http per la gestione delle chiamate alla ui-configuration per ottenere le azioni
 */
@Injectable({
  providedIn: 'root',
})
export class ActionsConfigurationHttpService {
  private uiConfigurationHost: string;
  private gawpassReadHost: string;
  private jsonContentType = 'application/json';
  private readonly jsonOptions: object;

  // Endpoints
  private getSingleActionsConfigurationUrl: string;
  private getMultipleActionsConfigurationUrl: string;
  private getMassiveActionsConfigurationUrl: string;
  private getAnomaliesSingleActionsConfigurationUrl: string;

  constructor(
    private httpClient: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
  }

  httpHostInit(appConfig: AppConfig) {
    this.uiConfigurationHost = appConfig.uiConfiguration.http.host;
    this.gawpassReadHost = appConfig.gawPassRead.http.host;
    this.getSingleActionsConfigurationUrl = `${this.gawpassReadHost}/api/v1/Action/Actions/{IdLicenza}/{CodSia}/{IdScatola}/{sectionCode}`;
    this.getAnomaliesSingleActionsConfigurationUrl = `${this.uiConfigurationHost}/v2/actions/document/ui/GAWPASS/singleActions?licenseId={IdLicenza}&siaCode={CodSia}&sectionCode={sectionCode}`;
    this.getMultipleActionsConfigurationUrl = `${this.uiConfigurationHost}/v2/actions/document/ui/GAWPASS/multipleActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
    this.getMassiveActionsConfigurationUrl = `${this.uiConfigurationHost}/v2/actions/document/ui/GAWPASS/massiveActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
  }
  public getSingleActions(
    licenseId: string,
    siaCode: string,
    boxId: string,
    sectionCode: SectionEnum,
    body: ActionsConfigRequest,
  ): Observable<ActionsConfigResponse> {
    let url: string;
    switch (sectionCode) {
      case SectionEnum.SHIPMENTS:
        url = this.getSingleActionsConfigurationUrl
          .replace('{IdLicenza}', licenseId)
          .replace('{CodSia}', siaCode)
          .replace('{IdScatola}', boxId)
          .replace('{sectionCode}', sectionCode);

        return this.httpClient.get<ActionsConfigResponse>(url, this.jsonOptions);

      case SectionEnum.ANOMALIES:
        url = this.getAnomaliesSingleActionsConfigurationUrl
          .replace('{IdLicenza}', licenseId)
          .replace('{CodSia}', siaCode)
          .replace('{sectionCode}', sectionCode);
        return this.httpClient.post<ActionsConfigResponse>(url, body, this.jsonOptions);
    }
  }
  public getMultipleActions(
    licenseId: string,
    siaCode: string,
    sectionCode: string,
    body?: ActionsConfigRequest[],
  ): Observable<ActionsConfigResponse> {
    const url = this.getMultipleActionsConfigurationUrl
      .replace('{sectionCode}', sectionCode)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);

    return this.httpClient.post<ActionsConfigResponse>(url, body, this.jsonOptions);
  }
  public getMassiveActions(licenseId: string, siaCode: string, sectionCode: string): Observable<ActionsConfigResponse> {
    const url = this.getMassiveActionsConfigurationUrl
      .replace('{sectionCode}', sectionCode)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);

    return this.httpClient.get<ActionsConfigResponse>(url, this.jsonOptions);
  }
}
