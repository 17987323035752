import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ActionConfirmationModalComponent } from 'app/core/common/modals/action-confirmation-modal/action-confirmation-modal.component';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { MassiveActionRequest } from 'app/entities/ui-config/action/massive-action-request';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, count, delay, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentsSeriesService } from '../../documents-series/documents-series.service';
import { DocumentsService2 } from '../../documents/documents.service';
import { FilterService2 } from '../../filters/filter.service';
import { ActionsHttpService } from '../actions-http.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
  providedIn: 'root',
})
export class AllDocumentsCallback implements OnDestroy {
  private readonly _filterPayload$: Observable<FilterStatus>;
  private readonly _totalDocuments$: Observable<number>;
  private concurrentDownloads = 0;
  private destroy$ = new Subject<void>();

  constructor(
    private documentsService: DocumentsService2,
    private filterService: FilterService2,
    private notificationService: NotificationService,
    private documentsSeriesService: DocumentsSeriesService,
    private actionsHttpService: ActionsHttpService,
    private transferService: TransferService,
  ) {
    this._totalDocuments$ = documentsService.whenTotalDocuments();
    this._filterPayload$ = filterService.whenFilterValue();
    this.transferService
      .whenTransfers()
      .pipe(
        switchMap((value) =>
          of(Object.values<Transfer>(value)).pipe(
            mergeMap((value1) => value1),
            count((v) => !TransferUtils.isComplete(v)),
            tap((v) => (this.concurrentDownloads = v)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Funzione di esporta excel massivo
   * @param action
   */
  public exportXLSX(action: ActionObject, extraParams?: any): Observable<any> {
    if (this.concurrentDownloads >= 2) {
      this.notificationService.showSweetAlert(
        NotificationType.INFO,
        'Attenzione',
        'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva',
      );
      return EMPTY;
    }

    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap((docCount) =>
        combineLatest([this._filterPayload$]).pipe(
          take(1),
          switchMap(([payload]) => {
            // Modal della scelta del formato dell'excel
            const showModal = () => {
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Esporta risultati in Excel',
                customFooter: true,
                childComponent: ExcelFormatModalComponent,
                childData: {
                  backButton: true,
                  action,
                  actionType: 'massive',
                  docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
                  massivePayload: payload,
                  service: 'GAWPOST',
                  sectionCode: extraParams ? extraParams.sectionCode : null,
                },
              });
            };

            if (docCount > 50000)
              this.notificationService.showSweetAlert(
                NotificationType.QUESTION,
                'Numero massimo di documenti superato.',
                `Procedere all'esportazione dei primi 50000 documenti?
									\nL'operazione potrebbe richiedere alcuni minuti`,
                showModal,
              );
            else showModal();

            return EMPTY;
          }),
        ),
      ),
    );
  }

  /**
   * Funzione di nascondi documenti
   * @param action
   * @param hide
   */
  public hideAndRestoreMassiveDocuments(action: ActionObject, hide: boolean): Observable<any> {
    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    const errorAction$ = () => {
      this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore durante l'esecuzione dell'azione", '');
      return EMPTY;
    };

    const executeAction$ = (payload) => {
      const body = <MassiveActionRequest>{
        elasticRequest: payload,
      };
      return this.actionsHttpService.whenHideAndShowDocumentsMassive(action.url, body, hide).pipe(
        take(1),
        tap((response) => {
          // se l'azione è nascondi documenti
          if (response.esitoOK && hide)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Documenti nascosti con successo',
              response.message,
            );
          else if (response.esitoOK && !hide)
            // se l'azione è ripristina documenti
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Documenti ripristinati con successo',
              response.message,
            );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              "Errore durante l'esecuzione dell'azione",
              response.message,
            );
        }),
        // TAM: DECOMMENTARE PER AZIONI MASSIVE
        delay(1000),
        tap(() => this.documentsService.refreshDocuments()),
        catchError(() => errorAction$()),
      );
    };

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap((docCount) =>
        this._filterPayload$.pipe(
          take(1),
          switchMap((filterPayload) => {
            // se l'azione è nascondi documenti
            if (hide)
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Nascondi documenti',
                childComponent: ActionConfirmationModalComponent,
                childData: {
                  action: executeAction$(filterPayload),
                  title: 'Nascondi documenti',
                  docCount,
                  maxDocCount: 10000,
                },
              });
            // se l'azione è ripristina documenti
            else
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Ripristina documenti',
                childComponent: ActionConfirmationModalComponent,
                childData: {
                  action: executeAction$(filterPayload),
                  title: 'Ripristina documenti',
                  docCount,
                  maxDocCount: 10000,
                },
              });

            return EMPTY;
          }),
        ),
      ),
    );
  }

  // Controllo sull'azione in input, ritorna true se l'url è valido
  // noinspection JSMethodCanBeStatic
  private checkActionInput(action): boolean {
    return !!(action && action.url);
  }
}
