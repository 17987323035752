/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentsSeriesService } from '@ctel/gaw30-commons';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ConfigService } from 'app/core/common/config/config.service';
import { UiConfigurationService } from 'app/core/common/ui-configuration/ui-configuration.service';
import { AppConfig } from 'app/entities/config/app-config';
import {
  DeleteDocumentInFolder,
  DeleteFolder,
  DeleteMultipleFolder,
  ExportExcel,
  ExportZip,
  InsertDocumentInFolder,
  InsertFolder,
  RemoveMultipleDocuments,
  SearchInFolderContext,
  UpdateDocumentInFolder,
  UpdateFolder,
} from 'app/entities/folder/folder-request';
import {
  AssociateDocInFolderResponse,
  DeleteMultipleFoldersResponse,
  FoldersResponse,
  InsertFolderResponse,
  LogStati,
  RemoveMultipleDocumentsResponse,
  SearchResult,
} from 'app/entities/folder/folder-response';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { EMPTY, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Folder } from '../../../../entities/folder/folder.interface';
import { OrphansCount } from '../../../../entities/orphans-count/orphans-count';

@Injectable({
  providedIn: 'root',
})
export class FoldersHttpService {
  private _idsSerieDocFascicolo: string[] = [];
  private idsSerieDocFascicolo$ = new ReplaySubject<string[]>(1);
  currentIdsSerieDocFascicolo$ = this.idsSerieDocFascicolo$.asObservable();

  private jsonContentType = 'application/json';
  // host
  private hostRead: string;
  private hostWrite: string;
  private hostExportFeatures: string;
  // endpoints
  private e_getFolder;
  private foldersByIdSerieDocList: string;
  private foldersOfDocument: string;
  private getFolderDocument: string;
  private folderHasDocuments: string;
  private getChildrenOfFolder: string;
  private getOrphansCount: string;
  private folderAncestors: string;
  private insert: string;
  private update: string;
  private delete: string;
  private deleteMultipleFolders: string;
  private updateDocument: string;
  private insertDocument: string;
  private deleteDocument: string;
  private removeMultipleDocuments: string;
  private folderLogStati: string;
  private folderDocumentLogStati: string;
  private searchFolderByAll: string;
  private search: string;
  private exportExcel: string;
  private exportZip: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private companiesService: CompaniesService,
    private uiConfigurationService: UiConfigurationService,
    private documentSeriesService: DocumentsSeriesService,
    private transferService: TransferService,
  ) {
    combineLatest([this.companiesService.whenCurrentCompany(), this.documentSeriesService.whenDocumentsSeries()])
      .pipe(
        switchMap((responses) => {
          const foldersSection = responses[1].find((section) => section.sectionCode === 'gaw30_fascicoli');
          if (foldersSection)
            return this.uiConfigurationService.getRelatedData(
              responses[0].licenseId,
              responses[0].siaCode,
              foldersSection.sectionCode,
            );
          else return EMPTY;
        }),
        tap((sections: RelatedSectionData[]) =>
          sections.forEach((section) => {
            if (section.docSeriesIds.length > 0) {
              this._idsSerieDocFascicolo = section.docSeriesIds;
              this.idsSerieDocFascicolo$.next(this._idsSerieDocFascicolo);
            }
          }),
        ),
      )
      .subscribe();

    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  get idsSerieDocFascicolo() {
    return this._idsSerieDocFascicolo;
  }

  setIdSerieDocFascicolo(docSeriesIds: string[]) {
    this.idsSerieDocFascicolo$.next(docSeriesIds);
  }

  httpHostInit(appConfig: AppConfig) {
    this.hostRead = appConfig.foldersRead.http.host;
    this.hostWrite = appConfig.foldersWrite.http.host;
    this.hostExportFeatures = appConfig.foldersRead.http.host.replace('folders', 'ExportFeatures');
    this.e_getFolder = `${this.hostRead}/GetFolder/{folderId}?{idSerieDocFascicolo}licenseId={licenseId}&siaCode={siaCode}`;
    this.foldersByIdSerieDocList = `${this.hostRead}/GetFoldersByIdSerieDocList?{idSerieDocFascicolo}&licenseId={licenseId}&siaCode={siaCode}`;
    this.foldersOfDocument = `${this.hostRead}/documents/GetFoldersOfDocument/{progBusta}/{progSpool}?idSerieDoc={idSerieDoc}&licenseId={licenseId}&siaCode={siaCode}`;
    this.getFolderDocument = `${this.hostRead}/documents/GetDocumentsInFolder?{idSerieDoc}&folderId={folderId}&licenseId={licenseId}&siaCode={siaCode}`;
    this.folderHasDocuments = `${this.hostRead}/documents/FolderHasDocuments?{idSerieDoc}&folderId={folderId}&licenseId={licenseId}&siaCode={siaCode}`;
    this.getChildrenOfFolder = `${this.hostRead}/GetChildFolders/{folderId}?idSerieDocFascicolo={idSerieDocFascicolo}&licenseId={licenseId}&siaCode={siaCode}`;
    this.getOrphansCount = `${this.hostRead}/documents/GetOrphansDocumentsNumber?{idSerieDoc}&folderId={folderId}&licenseId={licenseId}&siaCode={siaCode}`;
    this.folderAncestors = `${this.hostRead}/GetAncestorsFolders/{folderId}?idSerieDocFascicolo={idSerieDoc}&licenseId={licenseId}&siaCode={siaCode}`;
    this.insert = `${this.hostWrite}/Insert`;
    this.update = `${this.hostWrite}/Update`;
    this.delete = `${this.hostWrite}/Delete`;
    this.deleteMultipleFolders = `${this.hostWrite}/DeleteMultiple`;
    this.folderLogStati = `${this.hostRead}/GetFolderLogStati/{folderId}`;
    this.folderDocumentLogStati = `${this.hostRead}/documents/GetFolderDocumentLogStati/{folderDocumentId}`;
    this.updateDocument = `${this.hostWrite}/documents/Update`;
    this.insertDocument = `${this.hostWrite}/documents/InsertMultipleDocumentsInFolder`;
    this.deleteDocument = `${this.hostWrite}/documents/Delete`;
    this.removeMultipleDocuments = `${this.hostWrite}/documents/DeleteMultiple`;
    this.searchFolderByAll = `${this.hostRead}/SearchByAll?idSerieDocFascicolo={idSerieDocFascicolo}&{idSerieDoc}&searchString={searchString}&{textSearchMetadata}&licenseId={licenseId}&siaCode={siaCode}{idFascicolo}`;
    this.search = `${this.hostRead}/Search`;
    this.exportExcel = `${this.hostExportFeatures}/ExportSelection`;
    this.exportZip = `${this.hostExportFeatures}/ExportZip`;
  }

  insertFolder(folder: InsertFolder) {
    return this.http.post<InsertFolderResponse>(this.insert, folder, this.getHttpOptions(this.jsonContentType, true));
  }

  updateFolder(folder: UpdateFolder) {
    return this.http.post<any>(this.update, folder, this.getHttpOptions(this.jsonContentType, true));
  }

  deleteFolder(folder: DeleteFolder) {
    return this.http.post<any>(this.delete, folder, this.getHttpOptions(this.jsonContentType, true));
  }

  whenDeleteMultipleFolders(folders: DeleteMultipleFolder): Observable<DeleteMultipleFoldersResponse[]> {
    return this.http.post<any>(this.deleteMultipleFolders, folders, this.getHttpOptions(this.jsonContentType, true));
  }

  insertDocumentInFolder(doc: InsertDocumentInFolder): Observable<AssociateDocInFolderResponse[]> {
    return this.http.post<any>(this.insertDocument, doc, this.getHttpOptions(this.jsonContentType, true));
  }

  updateDocumentInFolder(doc: UpdateDocumentInFolder) {
    return this.http.post<any>(this.updateDocument, doc, this.getHttpOptions(this.jsonContentType, true));
  }

  deleteDocumentInFolder(doc: DeleteDocumentInFolder) {
    return this.http.post<any>(this.deleteDocument, doc, this.getHttpOptions(this.jsonContentType, true));
  }

  whenRemoveMultipleDocuments(docs: RemoveMultipleDocuments): Observable<RemoveMultipleDocumentsResponse[]> {
    return this.http.post<any>(this.removeMultipleDocuments, docs, this.getHttpOptions(this.jsonContentType, true));
  }

  whenExportExcel(obj: ExportExcel): Observable<Transfer> {
    return this.http
      .post<Blob>(this.exportExcel, obj, {
        responseType: 'blob' as 'json',
        observe: 'response',
        reportProgress: true,
        headers: new HttpHeaders({ log: 'true' }),
      })
      .pipe(transferHandler(this.transferService));
  }

  whenExportZip(obj: ExportZip): Observable<Transfer> {
    return this.http
      .post<Blob>(this.exportZip, obj, {
        responseType: 'blob' as 'json',
        observe: 'response',
        reportProgress: true,
        headers: new HttpHeaders({ log: 'true' }),
      })
      .pipe(transferHandler(this.transferService));
  }

  /**
   * @deprecated Use getFolder instead
   * @param folderId
   * @param idSerieDocFascicolo
   */
  getFolderById(folderId: string, idSerieDocFascicolo: string) {
    let url = this.e_getFolder;
    const company = this.companiesService.getCurrentCompanyValue();
    url = url
      .replace('{idSerieDocFascicolo}', idSerieDocFascicolo ? 'idSerieDocFascicolo=' + idSerieDocFascicolo + '&' : '') // TO FIX
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode)
      .replace('{folderId}', folderId);
    return this.http.get<FoldersResponse>(url, this.getHttpOptions(this.jsonContentType));
  }

  getFolder(
    folderId: string,
    idSerieDocFascicolo: string,
    licenseId: string,
    siaCode: string,
  ): Observable<FoldersResponse<Folder>> {
    const url = this.e_getFolder
      .replace('{idSerieDocFascicolo}', idSerieDocFascicolo ? 'idSerieDocFascicolo=' + idSerieDocFascicolo + '&' : '') // TO FIX
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{folderId}', folderId);
    return this.http.get<FoldersResponse<Folder>>(url, this.getHttpOptions(this.jsonContentType));
  }

  getFolderLogStati(folderId: string): Observable<FoldersResponse<LogStati[]>> {
    const url = this.folderLogStati.replace('{folderId}', folderId);
    return this.http.get<FoldersResponse<LogStati[]>>(url, this.getHttpOptions(this.jsonContentType));
  }

  getFolderDocumentLogStati(folderDocumentId: string): Observable<FoldersResponse<LogStati[]>> {
    const url = this.folderDocumentLogStati.replace('{folderDocumentId}', folderDocumentId);
    return this.http.get<FoldersResponse<LogStati[]>>(url, this.getHttpOptions(this.jsonContentType));
  }

  whenAllFolders(): Observable<Folder[]> {
    const company = this.companiesService.getCurrentCompanyValue();
    let url = this.foldersByIdSerieDocList;
    let idSerieDoc = '';
    return this.idsSerieDocFascicolo$.pipe(
      switchMap((ids) => {
        ids.forEach((id, index) => {
          idSerieDoc = idSerieDoc + 'idSerieDocFascicolo=' + id;
          if (index + 1 < ids.length) idSerieDoc = idSerieDoc + '&';
        });
        url = url
          .replace('{idSerieDocFascicolo}', idSerieDoc)
          .replace('{licenseId}', company.licenseId)
          .replace('{siaCode}', company.siaCode);
        return this.http.get<FoldersResponse>(url, this.getHttpOptions(this.jsonContentType)).pipe(
          map((response) =>
            response && response.isSuccessful && response.data
              ? (response.data as any[]).map(
                  (el) =>
                    ({
                      ...el,
                      id: el.id.toString(),
                      parentFolderId: el.parentFolderId ? el.parentFolderId.toString() : null,
                      idSerieDoc: el.idSerieDoc ? el.idSerieDoc.toString() : null,
                      idSerieDocFascicolo: el.idSerieDocFascicolo ? el.idSerieDocFascicolo.toString() : null,
                    }) as Folder,
                )
              : [],
          ),
        );
      }),
    );
  }

  whenDocsInFolder(folderId: string, allIdSerieDoc: string[]): Observable<FoldersResponse> {
    let url = this.getFolderDocument;
    const company = this.companiesService.getCurrentCompanyValue();
    let idSerieDoc = '';
    allIdSerieDoc.forEach((value, index) => {
      idSerieDoc = idSerieDoc + 'idSerieDoc=' + value;
      if (index + 1 < allIdSerieDoc.length) idSerieDoc = idSerieDoc + '&';
    });
    url = url
      .replace('{idSerieDoc}', idSerieDoc)
      .replace('{folderId}', folderId)
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode);
    return this.http.get<FoldersResponse>(url, this.getHttpOptions(this.jsonContentType));
  }

  whenOrphansCount(folderId: string, allIdSerieDoc: any[]): Observable<OrphansCount> {
    let url = this.getOrphansCount;
    const company = this.companiesService.getCurrentCompanyValue();
    let idSerieDoc = '';
    allIdSerieDoc.forEach((value, index) => {
      idSerieDoc = idSerieDoc + 'idSerieDoc=' + value;
      if (index + 1 < allIdSerieDoc.length) idSerieDoc = idSerieDoc + '&';
    });
    url = url
      .replace('{idSerieDoc}', idSerieDoc)
      .replace('{folderId}', folderId)
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode);
    return this.http.get<any>(url, this.getHttpOptions(this.jsonContentType)).pipe(map((value) => value.data));
  }

  whenFolderAncestors(folderId: string, idSerieDoc: string): Observable<Folder[]> {
    let url = this.folderAncestors;
    const company = this.companiesService.getCurrentCompanyValue();
    url = url
      .replace('{idSerieDoc}', idSerieDoc)
      .replace('{folderId}', folderId)
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode);
    return this.http.get<any>(url, this.getHttpOptions(this.jsonContentType)).pipe(
      map(
        (result) =>
          result.data.map((el) => ({
            ...el,
            id: el.id.toString(),
            parentFolderId: el.parentFolderId ? el.parentFolderId.toString() : null,
            idSerieDoc: el.idSerieDoc ? el.idSerieDoc.toString() : null,
            idSerieDocFascicolo: el.idSerieDocFascicolo ? el.idSerieDocFascicolo.toString() : null,
          })) as Folder[],
      ),
    );
  }

  whenFoldersOfDocument(progSpool: string, progBusta: string, idSerieDoc: string) {
    let url = this.foldersOfDocument;
    const company = this.companiesService.getCurrentCompanyValue();
    url = url
      .replace('{progBusta}', progBusta)
      .replace('{progSpool}', progSpool)
      .replace('{idSerieDoc}', idSerieDoc)
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode);
    return this.http.get<FoldersResponse>(url, this.getHttpOptions(this.jsonContentType));
  }

  whenChildrenInFolder(folderId: string, idSerieDoc: string): Observable<FoldersResponse> {
    const company = this.companiesService.getCurrentCompanyValue();
    let url = this.getChildrenOfFolder;
    url = url
      .replace('{folderId}', folderId)
      .replace('{idSerieDocFascicolo}', idSerieDoc)
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode);
    return this.http.get<FoldersResponse>(url, this.getHttpOptions(this.jsonContentType));
  }

  searchTypeAhead(search: SearchInFolderContext) {
    return this.http.post<SearchResult>(this.search, search, this.getHttpOptions(this.jsonContentType));
  }

  getFolderHasDocuments(folderId: string, allIdSerieDoc: any[]): Observable<boolean> {
    let url = this.folderHasDocuments;
    const company = this.companiesService.getCurrentCompanyValue();
    let idSerieDoc = '';
    allIdSerieDoc.forEach((value, index) => {
      idSerieDoc = idSerieDoc + 'idSerieDoc=' + value;
      if (index + 1 < allIdSerieDoc.length) idSerieDoc = idSerieDoc + '&';
    });
    url = url
      .replace('{idSerieDoc}', idSerieDoc)
      .replace('{folderId}', folderId)
      .replace('{licenseId}', company.licenseId)
      .replace('{siaCode}', company.siaCode);
    return this.http.get<boolean>(url, this.getHttpOptions(this.jsonContentType));
  }

  private getHttpOptions(contentType: string, log = false): object {
    let headers = new HttpHeaders({ 'Content-Type': contentType });

    if (log) headers = headers.set('log', 'true');

    const options = {
      headers,
      responseType: 'json' as const,
    };
    return options;
  }
}
