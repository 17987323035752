import { HomeFilter, HomeFilterRangeType } from '@ctel/gaw-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export class FilterBuilder {
  public static invoiceDateFilter(valueFrom: string, valueTo: string) {
    return {
      metadata: MetadataEnum.DATA_FATTURA,
      metadataDescription: '',
      type: 'date',
      filterType: 'range',
      configData: {},
      value: {
        from: valueFrom,
        to: valueTo,
      },
    };
  }

  public static dataProtocollazioneCtelFilter(valueFrom: string, valueTo: string) {
    return {
      metadata: MetadataEnum.DATA_INSERIMENTO,
      metadataDescription: '',
      type: 'date',
      filterType: 'range',
      configData: {},
      value: {
        from: valueFrom,
        to: valueTo,
      },
    };
  }

  public static timestampCtelRicezioneSdiFilter(valueFrom: string, valueTo: string) {
    return {
      metadata: MetadataEnum.DATA_RICEZIONE,
      metadataDescription: '',
      type: 'date',
      filterType: 'range',
      configData: {},
      value: {
        from: valueFrom,
        to: valueTo,
      },
    };
  }

  public static generalDateFilter(metadata: string, valueFrom: string, valueTo: string) {
    return {
      metadata,
      metadataDescription: '',
      type: 'date',
      filterType: 'range',
      configData: {},
      value: {
        from: valueFrom,
        to: valueTo,
      },
    };
  }

  /**
   * Genera un filtro data per la home, in base alla tipologia di filter range type fornita.
   * @param metadata il metadato del filtro
   * @param filterRangeType la tipologia di filtro in home
   * @param valueFrom l'eventuale from, in caso di filter type custom
   * @param valueTo l'eventuale to, in caso di filter type custom
   */
  public static generalHomeDateFilter(
    metadata: string,
    filterRangeType: HomeFilterRangeType,
    valueFrom?: string,
    valueTo?: string,
  ) {
    switch (filterRangeType) {
      case HomeFilterRangeType.DA_INIZIO_ANNO:
        valueFrom = dayjs().startOf('year').toISOString();
        valueTo = dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString();
        break;
      case HomeFilterRangeType.MESE_CORRENTE:
        valueFrom = dayjs().startOf('month').toISOString();
        valueTo = dayjs().endOf('month').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString();
        break;
      case HomeFilterRangeType.MESE_SCORSO: {
        const pastMonth = dayjs().subtract(1, 'month');
        valueFrom = pastMonth.startOf('month').toISOString();
        valueTo = pastMonth.endOf('month').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString();
        break;
      }
      case HomeFilterRangeType.ULTIMO_TRIMESTRE:
        valueFrom = dayjs()
          .subtract(3, 'month')
          .utcOffset(0)
          .set('h', 0)
          .set('m', 0)
          .set('s', 0)
          .set('ms', 0)
          .toISOString();
        valueTo = dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString();
        break;
      case HomeFilterRangeType.GIORNO_PRECEDENTE:
        valueFrom = dayjs()
          .subtract(1, 'day')
          .utcOffset(0)
          .set('h', 0)
          .set('m', 0)
          .set('s', 0)
          .set('ms', 0)
          .toISOString();
        valueTo = dayjs()
          .subtract(1, 'day')
          .utcOffset(0)
          .set('h', 23)
          .set('m', 50)
          .set('s', 59)
          .set('ms', 999)
          .toISOString();
        break;
      case HomeFilterRangeType.CUSTOM:
      default:
        break;
    }
    return {
      ...(FilterBuilder.generalDateFilter(metadata, valueFrom, valueTo) as HomeFilter),
      dateRangeType: filterRangeType,
    };
  }
}
