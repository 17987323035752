import { Injectable } from '@angular/core';
import {
  Display,
  DocSeriesMetadataDesc,
  DocSeriesMetadataDescSearch,
  Document,
  MemoizeObservable,
  ServiceType,
} from '@ctel/gaw-commons';
import { DocumentActions, DocumentState, getDocuments, getTotalDocuments } from '@ctel/search-filter-store';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { SearchBarTypeaheadPayload } from 'app/entities/search-bar/search-bar-typeahead-payload';
import { DocumentsHttpService } from 'app/modules/homepage/core/documents-search/documents-http.service';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RelatedSectionData } from '../../../../../entities/sections/related-section-data';
import { SearchContextService } from '../../search-context/search-context.service';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsService2 {
  private destroy$ = new Subject<void>();
  private loadingDocs$ = new BehaviorSubject<boolean>(true);
  private loadingDocsAfterFilterApplication$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnSectionChange$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnPaging$ = new BehaviorSubject<boolean>(false);
  private readonly documents$: Observable<Document[]>;
  private readonly totalDocuments$: Observable<number>;
  private documentsArray: Document[];
  private errorLoadingDocuments$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store<DocumentState>,
    private readonly actions$: Actions,
    private documentsHttpService: DocumentsHttpService,
    private companiesService: CompaniesService,
    private searchContextService: SearchContextService,
  ) {
    this.documents$ = this.store.pipe(select(getDocuments)) as Observable<Document[]>;
    this.totalDocuments$ = this.store.pipe(select(getTotalDocuments));

    this.documents$
      .pipe(
        tap((value) => {
          this.documentsArray = value;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  @MemoizeObservable()
  public whenMetadataDescriptions(relatedSectionData: RelatedSectionData[]): Observable<DocSeriesMetadataDesc[]> {
    const reduced = relatedSectionData.reduce(
      (acc, item) => {
        item.docSeriesIds.forEach((docSeriesId) => acc.docSeriesIds.add(docSeriesId));
        item.textSearchMetadata.forEach((metadata) => acc.metadata.add(metadata));
        return acc;
      },
      { docSeriesIds: new Set<string>(), metadata: new Set<string>() },
    );

    const payload = {
      idsSerieDoc: Array.from(reduced.docSeriesIds),
      metadata: Array.from(reduced.metadata),
    } as DocSeriesMetadataDescSearch;
    return this.documentsHttpService.whenMetadataDescriptions(payload);
  }

  whenDocuments(): Observable<Document[]> {
    return this.documents$;
  }

  getDocuments(): Document[] {
    // TODO: leva questo return dell'oggetto sbiotto e rendi più reactive il chiamante.
    return this.documentsArray;
  }

  whenTotalDocuments(): Observable<number> {
    return this.totalDocuments$;
  }

  whenTypeAhead(text: string): Observable<string[]> {
    const company$ = this.companiesService.whenCurrentCompany();
    const uiCode$ = this.searchContextService.getSelectSearchArea();
    const searchContext$ = this.searchContextService.whenSearchContext();

    // quando ho company e lo uiCode selezionato
    return combineLatest([company$, uiCode$, searchContext$]).pipe(
      debounceTime(200),
      switchMap(([company, uiCode, searchContext]) => {
        // riduco l'array di obj a un array di docSeriesIds unici
        const docSeriesIds = new Set<string>();
        searchContext
          .map((sc) => sc.docSeriesIds)
          .flat()
          .forEach((id) => docSeriesIds.add(id));

        //creo il payload per la typeahead
        const body: SearchBarTypeaheadPayload = {
          docSeriesIds: Array.from(docSeriesIds),
          licenseId: company.licenseId,
          siaCode: company.siaCode,
          text,
        };
        return this.documentsHttpService.whenSearchTypeahead(body, uiCode.uI_CODE).pipe(
          map((value) => {
            const matchListWithDoubleQuotes: string[] = [];
            value.bestMatchList.forEach(function (match) {
              matchListWithDoubleQuotes.push('"' + match + '"');
            });
            return matchListWithDoubleQuotes;
          }),
        );
      }),
    );
  }

  /**
   * forza refresh griglia documenti
   */
  refreshDocuments() {
    this.store.dispatch(DocumentActions.fetchDocuments(true));
  }

  public setLoadingDocsAfterFilterApplication(value: boolean) {
    this.loadingDocsAfterFilterApplication$.next(value);
  }

  public whenLoadingDocsAfterFilterApplication(): Observable<boolean> {
    return this.loadingDocsAfterFilterApplication$.asObservable();
  }

  public setLoadingDocsOnSectionChange(value: boolean) {
    this.loadingDocsOnSectionChange$.next(value);
  }

  public setLoadingDocsOnPaging(value: boolean) {
    this.loadingDocsOnPaging$.next(value);
  }

  public whenLoadingDocsOnPaging(): Observable<boolean> {
    return this.loadingDocsOnPaging$.asObservable();
  }

  public setLoadingDocs(value: boolean) {
    this.loadingDocs$.next(value);
  }

  public whenLoadingDocs(): Observable<boolean> {
    return this.loadingDocs$.asObservable();
  }

  public getRedirectUrl(display: Display[], inputService?: string, keys?: unknown) {
    const serviziConfigurati = display.find((item) => item.metadata === MetadataEnum.SERIVCES);
    const services = serviziConfigurati && serviziConfigurati.value ? JSON.parse(serviziConfigurati.value) : [];
    const service = inputService ? inputService : services[0];
    if (service === 'GAW30' || service === 'GAW 3.0') {
      let currentUrl =
        '/gaw30/documents/types/' +
        this.companiesService.getCurrentCompanyValue().licenseId +
        '/' +
        this.companiesService.getCurrentCompanyValue().siaCode +
        '/' +
        'ALL/';
      if (keys) currentUrl = currentUrl + keys['ctelDocSeriesId'] + '/';

      currentUrl += 'doc/';
      return currentUrl;
    }
    if (service === 'GAWFE' || service === 'GAW FE') {
      const urlSegment =
        display.find((item) => item.metadata === MetadataEnum.HUBFE_SEZIONE).value === 'CicloAttivo'
          ? 'receivable/0/receivable'
          : 'payable/0/payable';
      const currentUrl =
        '/gawfe/documents/' +
        this.companiesService.getCurrentCompanyValue().licenseId +
        '/' +
        this.companiesService.getCurrentCompanyValue().siaCode +
        '/' +
        urlSegment +
        '/details/';
      return currentUrl;
    }

    if (service === 'GEDPEC' || service === 'GAWPEC') {
      let currentUrl =
        '/details/' +
        this.companiesService.getCurrentCompanyValue().licenseId +
        '/' +
        this.companiesService.getCurrentCompanyValue().siaCode +
        '/' +
        'ALL/';
      currentUrl = currentUrl + keys['ctelDocSeriesId'] + '/';
      currentUrl += ServiceType.GAWPEC + '/';
      return currentUrl;
    }
    if (service === 'ArchivioLotti' || service === 'Archivio Lotti') {
      let currentUrl =
        '/details/' +
        this.companiesService.getCurrentCompanyValue().licenseId +
        '/' +
        this.companiesService.getCurrentCompanyValue().siaCode +
        '/' +
        'ALL/';
      currentUrl = currentUrl + keys['ctelDocSeriesId'] + '/';
      currentUrl += ServiceType.GAWLOTTI + '/';
      return currentUrl;
    }

    return null;
  }

  public whenErrorLoadingDocs() {
    return this.errorLoadingDocuments$.asObservable();
  }

  public setErrorLoadingDocs(value: boolean) {
    this.errorLoadingDocuments$.next(value);
  }
}
