<!--PANEL HISTORY STATI CANALE-->
<div *hubLoading="historyPanelLoading; style: { height: '200px' }" class="status-history-box px-3 py-4">
  <ng-container *ngIf="statusHistory$ | async as statusHistory">
    <table class="table table-responsive table-striped">
      <thead>
        <tr>
          <th [style.width]="'16.66%'">Stato</th>
          <th [style.width]="'16.66%'">Data/ora</th>
          <th [style.width]="'16.66%'">Azienda</th>
          <th [style.width]="'16.66%'">Utente</th>
          <th [style.width]="'16.66%'">Descrizione scatola</th>
          <th [style.width]="'16.66%'">Descrizione stato</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let history of statusHistory">
          <td>
            <span>{{ history.stato }}</span>
          </td>
          <td>
            <span>{{ history.dataStato | date: 'd/M/yy, H:mm' }}</span>
          </td>
          <td>
            <span>{{ history.azienda }}</span>
          </td>
          <td>
            <span>{{ history.utente }}</span>
          </td>
          <td>
            <span>{{ history.statoScatola }}</span>
          </td>
          <td>
            <span>{{ history.descrizioneStato }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
