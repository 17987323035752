import { Directive, ElementRef, HostListener } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Directive({
  selector: '[gawRouteTransformer]',
})
export class RouteTransformerDirective {
  constructor(
    private el: ElementRef,
    private router: Router,
  ) {}

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.hasAttribute('href')) {
      const url = event.target.getAttribute('href');
      if (url.startsWith('/')) {
        const path = url.split('?');
        const pathName = path[0];
        const navigationExtras: NavigationExtras = {};

        if (path[1]) {
          // Se sono presenti dei queryParams
          const params = this.paramsToObject(new URLSearchParams(path[1]));
          navigationExtras['queryParams'] = params;
        }

        this.router.navigate([pathName], navigationExtras);
        event.preventDefault();
      }
    } else return;
  }

  private paramsToObject(params: URLSearchParams): object {
    const result = {};
    for (const entry of params.entries()) {
      // each 'entry' is a [key, value] tupple
      const [key, value] = entry;
      result[key] = value;
    }
    return result;
  }
}
