<div class="content">
  <ng-container [ngSwitch]="activityStatus">
    <ng-container *ngSwitchCase="status.DETAIL">
      <ng-container *ngTemplateOutlet="DetailStatusTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="status.INDIRECT_ACTION">
      <ng-container *ngTemplateOutlet="IndirectActionStatusTemplate"></ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- Template per lo stato di `Dettaglio` -->
<ng-template #DetailStatusTemplate>
  <div class="actions">
    <gaw-workitem-activity-actions (executeAction)="executeAction($event)"></gaw-workitem-activity-actions>
  </div>
  <div class="frame">
    <gaw-workitem-activity-detail-frame></gaw-workitem-activity-detail-frame>
  </div>
</ng-template>

<!-- Template per lo stato di `Azione Indiretta` -->
<ng-template #IndirectActionStatusTemplate>
  <div class="frame">
    <gaw-workitem-activity-indirect-action-frame
      [link]="indirectFormLink"
      (indirectActionCompleted)="indirectActionCompleted($event)"
    >
    </gaw-workitem-activity-indirect-action-frame>
  </div>
</ng-template>
