import { Injectable } from '@angular/core';
import { ListCf } from 'app/entities/invoice-pa/invoice-pa-autocomplete/list-cf';
import { PaOfficesList } from 'app/entities/invoice-pa/invoice-pa-autocomplete/pa-offices-list';
import { CedentePrestatore } from 'app/entities/invoice-pa/invoice-pa-model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerRegistryHttpService } from './customer-registry-http.service';

@Injectable()
export class CustomerRegistryService {
  constructor(private customerRegistryHttpService: CustomerRegistryHttpService) {}

  public getRegistry(id: string, sia: string): Observable<CedentePrestatore> {
    return this.customerRegistryHttpService.getRegistry(id, sia).pipe(
      map((customerData) => {
        let email: string[] = [''];
        if (customerData.email) email = customerData.email.split(',');

        const cedentePrestatore: CedentePrestatore = {
          DatiAnagrafici: {
            IdFiscaleIVA: {
              IdCodice: customerData.piva,
              IdPaese: 'IT',
            },
            Anagrafica: {
              Denominazione: customerData.companyName,
            },
            CodiceFiscale: customerData.fiscalCode,
          },
          Sede: {
            Indirizzo: customerData.address,
            CAP: customerData.cap,
            Comune: customerData.city,
            Provincia: customerData.province,
            Nazione: 'IT',
          },
          Contatti: {
            Email: email[0].toLowerCase(),
          },
        };
        return cedentePrestatore;
      }),
    );
  }

  // EVO IN CORSO KARANDIP SINGH 27/02/2020

  public getOfficePaByCf(cf: string): Observable<PaOfficesList[]> {
    return this.customerRegistryHttpService.getOfficePaByCf(cf === '' ? null : cf).pipe(
      map((value: PaOfficesList[]) =>
        value.sort(function (a, b) {
          const textA = a.codUfficio.toUpperCase();
          const textB = b.codUfficio.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
      ),
    );
  }

  public getCfListByCf(cf: string, invoicePa: boolean): Observable<ListCf[]> {
    // Todo Karandip Singh Applicare sort 08/05/2020
    if (invoicePa) return this.customerRegistryHttpService.getCfListByCf(cf);

    return of([]);
  }

  public getOfficePaByCodUni(officeCode: string): Observable<PaOfficesList[]> {
    let codufficio = '';
    if (officeCode) codufficio = officeCode.toUpperCase();

    return this.customerRegistryHttpService.getOfficePaByCodUni(codufficio === '' ? null : codufficio);
  }
}
