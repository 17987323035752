import { ErrorType } from './error-type';

export class ErrorTypes {
  // Errori general purpose
  public static AUTHENTICATION_CONTEXT_ERROR = new ErrorType(
    'AUTHENTICATION_CONTEXT_ERROR',
    "Errore nell'estrazione delle informazioni di login",
  );
  public static PARSE_FAILURE = new ErrorType('PARSE_FAILURE', 'Parse oggetto fallita');
  public static INVALID_OBJECT = new ErrorType('INVALID_OBJECT', 'Oggetto non valido');
  public static OBJECT_NOT_FOUND = new ErrorType('OBJECT_NOT_FOUND', "L'oggetto non è stato trovato");
  public static USER_ID_NOT_VALID = new ErrorType('USER_ID_NOT_VALID', 'Utente non valido');
  public static UPDATE_FAILURE = new ErrorType('UPDATE_FAILURE', "Impossibile aggiornare l'oggetto");
  public static CREATE_FAILURE = new ErrorType('CREATE_FAILURE', "Impossibile creare l'oggetto");
  public static DELETE_FAILURE = new ErrorType('DELETE_FAILURE', "Impossibile eliminare l'oggetto");
  public static GET_FAILURE = new ErrorType('GET_FAILURE', "Impossibile richiedere l'oggetto");

  // Application Error
  public static CONFIG_LOADING_ERROR = new ErrorType(
    'CONFIG_LOADING_ERROR',
    'Il file di configurazione non è stato caricato correttamente',
  );

  // HTTP status codes
  // https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html
  public static HTTP_UNAUTHORIZED = new ErrorType(
    'HTTP_UNAUTHORIZED',
    "Non è stata inviata autenticazione o l'autorizzazione è stata negata per le credenziali fornite",
  );
  public static HTTP_FORBIDDEN = new ErrorType(
    'HTTP_FORBIDDEN',
    'Il server ha compreso la richiesta ma si è rifiutato di completarla',
  );
  public static HTTP_NOT_FOUND = new ErrorType(
    'HTTP_NOT_FOUND',
    "Il server non ha trovato nessuna risorsa corrispondente all'URI fornito nella richiesta",
  );
  public static HTTP_ERROR = new ErrorType('HTTP_ERROR', 'Si è verificato un errore durante la chiamata HTTP');
  public static REFERENTIAL_INTEGRITY_ERROR = new ErrorType(
    'REFERENTIAL_INTEGRITY_ERROR',
    'Si è verificata una violazione di integrità referenziale',
  );

  // HTTP Client status codes
  public static HTTP_CLIENT_OR_NETWORK_ERROR = new ErrorType(
    'HTTP_CLIENT_OR_NETWORK_ERROR',
    'Si è verificato un errore durante la richiesta, verificare la connessione di rete e riprovare',
  );

  // Generic Error
  public static GENERIC_ERROR = new ErrorType('GENERIC_ERROR', 'Errore applicativo non specificato');

  public static JAVASCRIPT_ERROR = new ErrorType('JAVASCRIPT_ERROR', 'Errore Javascript');
}
