import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { News, Notification } from 'app/entities/news-and-notifications/news-and-notifications';
import { Observable } from 'rxjs';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http di news e notifiche
 */
@Injectable()
export class NewsAndNotificationsHttpService {
  private newsNotificationsHost;

  private news;
  private newsRead;
  private notifications;
  private notificationRead;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  // inizializzazione endpoint string
  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS NEWS E NOTIFICHE --------------------------------------------------------------------------------------------------- */
    this.newsNotificationsHost = appConfig.newsNotifications.http.host;
    this.news = `${this.newsNotificationsHost}/v2/news`;
    this.newsRead = `${this.newsNotificationsHost}/v2/news/{id}/read`;
    this.notifications = `${this.newsNotificationsHost}/v2/notifications`;
    this.notificationRead = `${this.newsNotificationsHost}/v2/notifications/{id}/read`;
  }

  /* HTTP REQUESTS NOTIFICHE E NEWS---------------------------------------------------------------------------------------- */

  whenNews(): Observable<News[]> {
    const url = this.news;
    const params: any = { getReadItems: true };
    return this.http.get<News[]>(url, { params });
  }

  setNewsRead(id: number): Observable<any> {
    const url = this.newsRead.replace('{id}', id);
    return this.http.get(url);
  }

  whenNotifications(): Observable<Notification[]> {
    const url = this.notifications;
    const params: any = { getReadItems: true };
    return this.http.get<Notification[]>(url, { params });
  }

  setNotificationRead(notificationId: number): Observable<any> {
    const url = this.notificationRead.replace('{id}', notificationId);
    return this.http.get(url);
  }
}
