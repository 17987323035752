import { Injectable } from '@angular/core';
import { SearchFilterService } from '@ctel/search-filter-store';
import { FilterState } from '@ctel/search-filter-store';
import { select, Store } from '@ngrx/store';
import { getFiltersStateWithUserValuesGawcons } from '../documents/store/document.extended';
import { FilterStatus } from '@ctel/gaw-commons';
import { Observable } from 'rxjs';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx). Questa classe è quasi esclusivamente noop.
 */
@Injectable({
  providedIn: 'root',
})
export class PdvFilterService2 extends SearchFilterService {
  private readonly filterStatus$;

  constructor(private store: Store<FilterState>) {
    super();
    this.filterStatus$ = this.store.pipe(select(getFiltersStateWithUserValuesGawcons));
  }

  whenFilterValue(): Observable<FilterStatus> {
    return this.filterStatus$;
  }
}
