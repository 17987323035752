export enum FieldType {
  SELECT,
  NUMBER,
  DECIMAL,
  PASSWORD,
  TEXT,
  CHECKBOX,
  TYPEAHEAD,
  DATE,
  DATE_RANGE,
  OBJECT,
  ARRAY,
}
