import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gaw-service-disabled',
  templateUrl: './service-disabled.component.html',
  styleUrls: ['./service-disabled.component.scss'],
})
export class ServiceDisabledComponent implements OnInit {
  serviceName$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.serviceName$ = this.route.queryParams.pipe(map((params) => params['serviceName']));
  }

  backToHome() {
    this.router.navigate(['/home']).finally();
  }
}
