import { Pipe, PipeTransform } from '@angular/core';
import { DocumentAttachmentPdf } from '../components/display-document/document-attachment-pdf';
import { DocumentAttachmentXml } from '../components/display-document/document-attachment-xml';
import { DocumentAttachmentType } from '../components/display-document/document-attachment-type';

@Pipe({
  name: 'asXmlDoc',
})
export class AsXmlDocPipe implements PipeTransform {
  transform(value: DocumentAttachmentXml | DocumentAttachmentPdf): DocumentAttachmentXml | undefined {
    if (!value || value.type === DocumentAttachmentType.PDF) return undefined;
    return value as DocumentAttachmentXml;
  }
}
