import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { KPISection, UserKpi } from '../../entities/kpi/kpi-service';

@Injectable({
  providedIn: 'root',
})
export class KpiSectionsHttpService {
  private serviceKpiHost: string;
  private getSections: string;
  private setSections: string;
  private getKPIsUrl: string;
  private setKPIsUrl: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.serviceKpiHost = appConfig.gawUserPortalConfiguration.http.host;
    this.getSections = `${this.serviceKpiHost}/api/v1/KPI/loaduserkPIsection?IdLicenza={IdLicenza}&Sia={CodSia}`;
    this.setSections = `${this.serviceKpiHost}/api/v1/KPI/setuserkpisection/{IdLicenza}/{CodSia}`;
    this.getKPIsUrl = `${this.serviceKpiHost}/api/v1/KPI/loaduserkpiservice/{IdLicenza}/{CodSia}/{SectionId}`;
    this.setKPIsUrl = `${this.serviceKpiHost}/api/v1/KPI/setuserkpiservice/{IdLicenza}/{CodSia}`;
  }

  public getKPISections(licenseId: string, siaCode: string): Observable<any> {
    const url = this.getSections.replace('{IdLicenza}', licenseId).replace('{CodSia}', siaCode);
    return this.http.get(url);
  }

  public setKPISections(licenseId: string, siaCode: string, section: Array<KPISection>): Observable<any> {
    const url = this.setSections.replace('{IdLicenza}', licenseId).replace('{CodSia}', siaCode);
    return this.http.post(url, section);
  }

  public getKPIs(licenseId: string, siaCode: string, sectionId: string): Observable<any> {
    const url = this.getKPIsUrl
      .replace('{IdLicenza}', licenseId)
      .replace('{CodSia}', siaCode)
      .replace('{SectionId}', sectionId);
    return this.http.get(url);
  }

  public setKPIs(licenseId: string, siaCode: string, userKpi: UserKpi): Observable<any> {
    const url = this.setKPIsUrl.replace('{IdLicenza}', licenseId).replace('{CodSia}', siaCode);
    return this.http.post(url, userKpi);
  }
}
