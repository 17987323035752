import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from '@ctel/block-ui';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorPlaceholderSize } from 'app/core/common/placeholder/error-placeholder/directives/error-placeholder-size.enum';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { DropdownPlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/dropdown-placeholder/dropdown-placeholder.component';
import { SimplePlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/templates/simple-placeholder/simple-placeholder.component';
import { Company } from 'app/entities/companies/company';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { EMPTY, Observable, Subject, combineLatest } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';

/**
 * Componente che rappresenta la combo aziende
 */
@Component({
  selector: 'gaw-companies-dropdown',
  templateUrl: './companies-dropdown.component.html',
  styleUrls: ['./companies-dropdown.component.scss'],
})
export class CompaniesDropdownComponent implements OnDestroy {
  @BlockUI('companies-loader') companiesLoader: NgBlockUI;
  public faIcons = FaIcons;
  private destroy$ = new Subject<void>();

  public cdMaxLength = 16;

  // Non reactive
  public companies: Company[];
  public singleCompany: boolean;
  public currentCompany: Company;

  // Loading
  public loading = false;
  public dropDownPlaceholder = DropdownPlaceholderComponent;

  blockTemplate = SimplePlaceholderComponent;

  public comapniesLoading: boolean;

  // Error
  public error$: Observable<[boolean, unknown]>;
  public errorOptions: Options = {
    message: 'Impossibile caricare le aziende.',
    size: ErrorPlaceholderSize.SMALL,
    onRetry: () => this.retry(),
  };
  constructor(
    private companiesService: CompaniesService,
    private router: Router,
  ) {
    this.refresh();
    this.error$ = this.companiesService.whenLoadingError();
    this.error$.pipe(filter(([ok]) => !ok));
  }
  public companyComparator = (c1, c2) => c1 && c2 && c1.licenseId === c2.licenseId && c1.siaCode === c2.siaCode;

  refresh() {
    combineLatest([this.companiesService.whenCompanies(), this.companiesService.whenCurrentCompany()])
      .pipe(
        tap(([companies, currentCompany]) => {
          // Inizio caricamento
          this.loading = true;

          // Inizializzo in modo non reactive in quanto non avrei benefici tangibili e complicherei il codice
          this.companies = companies;
          this.currentCompany = currentCompany;
          this.singleCompany = companies.length === 1;

          // Caricamento effettuato correttamente
          this.loading = false;
          this.companiesService.sendLoadingError(false, null);
        }),
        catchError((err: unknown) => {
          this.companiesService.sendLoadingError(true, err);
          return EMPTY;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  changeCompany(selectedCompany: Company) {
    // Al cambio azienda si torna sempre sulla home, tutto lo stato dipende dall'azienda e va reinizializzato
    // solo se seleziono un'azienda diversa dall'attuale
    if (!this.companyComparator(selectedCompany, this.currentCompany)) {
      this.companiesService.sendCurrentCompany(selectedCompany);

      // Nel caso in cui non siaCode nella pagina dei minimi, effettuo un reset, imposto ciclo attivo e navigo in home
      // TODO [ivan.silva@besolutions.it,30/05/19] È chi visualizza che deve preoccuparsi del fatto che i dati che sta
      // visualizzando siano corretti. È necessario rimuovere questa logiche di reset e setup da questo punto.
      if (
        window.location.href.indexOf('/configuration/regimeminimi') === -1 &&
        window.location.href.indexOf('/address-book') === -1
      )
        this.router.navigate(['/home']).then();
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private retry() {
    // in caso di errore, riprovo a fare la chiamata HTTP inizialiando tutto
    this.companiesService.refreshCompanies();
  }
}
