import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { TableAction } from 'app/shared/components/table/actions/table-action';
import { TableActionStatus } from 'app/shared/components/table/actions/table-action-status';
import { DateRangeFilter } from 'app/shared/components/table/interface/date-range-filter';
import { Observable, from } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import { Pagination } from '../pagination/pagination';
import { PaginationComponentInput } from '../pagination/pagination-component-input';
import { FormFilter } from './interface/form-filter';
import { Order, Ordering } from './interface/ordering';

@Component({
  template: '',
})
export class AbstractTableComponent<T> implements PaginationComponentInput {
  @Input() noDataAvailable = 'Nessun dato disponibile';

  // Elementi di paginazione
  /** Booleano che indica l'utilizzo della paginazione nella tabella */
  @Input() usePagination = false;
  /** Totale di elementi presenti*/
  @Input() totalItems: number;
  /** Totale di elementi da visualizzare perpagina */
  @Input() itemsPerPage = 10;
  /** Indice della pagina attuale (default = 1) */
  @Input() actualPage = 1;
  /** Evento di cambio pagina */
  @Output() changedPage: EventEmitter<Pagination> = new EventEmitter<Pagination>();

  // Elementi di filtro
  /** Booleano che indica l'utilizzo dei filtri di ricerca nella tabella */
  @Input() useFilter = false;
  /** Evento di cambio filtri */
  @Output() filterChange: EventEmitter<FormFilter[]> = new EventEmitter<FormFilter[]>();

  // Elementi di ordinamento
  @Input() useOrdering = false;
  @Output() orderChange: EventEmitter<Ordering> = new EventEmitter<Ordering>();

  // Azioni sulla tabella
  /** Lista delle azioni dell'intera tabella */
  tableActions$: Observable<TableActionStatus[]>;

  /** Array di fields che identificano le colonne */
  protected _fields: Field[] = [];
  protected _data: T[] = [];

  protected _actualOrder: Ordering = {
    field: '',
    order: Order.NOT_SET,
  };

  @Input() set tableActions(actions: TableAction[]) {
    this.tableActions$ = from(actions).pipe(
      map((action) => new TableActionStatus(action)),
      reduce<TableActionStatus, TableActionStatus[]>((acc, val) => [...acc, val], []),
    );
  }

  // Azioni sul contenuto

  /** Funzione di cambio pagina
   * Emette un evento di 'changedPage'
   * @param $event
   * @type Pagination
   * @event changedPage Evento emesso ogni di tipo Pagination,
   * viene lanciato ogni volta si cambia la pagina di visualizzazione
   */
  changePage($event: Pagination) {
    if (this.usePagination && this.actualPage !== $event.actualPage) {
      this.actualPage = $event.actualPage;
      this.totalItems = $event.totalItems;
      this.itemsPerPage = $event.itemsPerPage;
      this.changedPage.emit($event);
    }
  }

  /**
   * Fuzione di Submit del form dei filtri
   * Emette un evento
   * @param form
   * @type NgForm
   * @event setFilter Evento di tipo FormFilter[],
   * viene emesso ogni volta che viene fatto un submit sul form dei filtri
   */
  formSubmit(form: NgForm) {
    if (this.useFilter) {
      if (!this._fields || !(this._fields instanceof Array)) return;

      const filters: FormFilter[] = [];
      this._fields.forEach((field) => {
        if (!field.visible)
          // Se il campo non è visibile passo al campo successivo
          return;

        let value = form.value[field.id];
        if (value)
          if (field.type === FieldType.DATE)
            if (value instanceof Array)
              // Se il valore è presente, lo parso in base al tipo
              // Se il valore è un Array, corrisponde a un range di date
              value = new DateRangeFilter(value[0], value[1]);
            // Altrimenti si tratta di una singla data
            else value = new DateRangeFilter(value, value);

        filters.push(<FormFilter>{
          value,
          field,
        });
      });
      this.filterChange.emit(filters);
    }
  }

  /**
   * Funzione che gestisce l'ordinamento della tabella
   * @param field
   */
  order(field: Field) {
    if (!this.useOrdering) return;

    const defaultOrder = Order.ASCENDING;

    // Creo un oggetto conenente di ordinamento contenente il nuovo campo e l'ordinamento di default
    const newOrder: Ordering = {
      field: field.id,
      order: defaultOrder,
    };

    // Se sto ordinando per un campo già ordinato in precedenza, ciclo sul tipo di ordinamento
    if (field.id === this._actualOrder.field)
      switch (this._actualOrder.order) {
        case Order.ASCENDING:
          newOrder.order = Order.DESCENDING;
          break;
        case Order.DESCENDING:
          newOrder.order = Order.NOT_SET;
          break;
        default:
          newOrder.order = Order.ASCENDING;
      }

    this._actualOrder = newOrder;
    this.orderChange.emit(newOrder);
  }
}
