import { WorkflowProperty, Workflow } from './entities/workflow';

export class WorkflowVM implements Workflow {
  id: number;
  workflowSectionName: string;
  name: string;
  workflowName: string;
  description: string;
  canBeContainedIntoFolder: boolean;
  canCreateItems: boolean;
  standaloneCreation: boolean;
  detailsInMixedMode?: boolean;
  itemCreationLink: string;
  properties: WorkflowProperty[];
  color: string;
  batchCreation: boolean;
  uiCreation: boolean;
  workItemsCount?: number;
  docSeries: string;
  expiredItemDate?: number;

  constructor(workflow: Workflow) {
    this.id = workflow.id;
    this.workflowSectionName = workflow.workflowSectionName;
    this.workflowName = workflow.workflowName;
    this.description = workflow.description;
    this.canBeContainedIntoFolder = workflow.canBeContainedIntoFolder;
    this.canCreateItems = workflow.canCreateItems;
    this.detailsInMixedMode = workflow.detailsInMixedMode;
    this.itemCreationLink = workflow.itemCreationLink;
    this.standaloneCreation = workflow.standaloneCreation;
    this.properties = workflow.properties;
    this.color = workflow.color;
    this.uiCreation = workflow.uiCreation;
    this.docSeries = workflow.docSeries;
    this.expiredItemDate = workflow.expiredItemDate;
  }
}
