import { Action, createReducer, on } from '@ngrx/store';
import { AccountType } from 'app/core/common/account/accountType';
import * as AccountActions from './account.actions';

export interface AccountModuleState {
  accountState: AccountState;
}

export const accountModuleReducers = {
  accountState: accountStateReducer,
};

export const accountModuleFeatureKey = 'accountModule';

/**
 * Reducer per lo stato.
 * @param state lo stato attuale
 * @param action la azione di mutamento dello stato
 */
export function accountStateReducer(state: AccountState | undefined, action: Action) {
  return _accountStateReducer(state, action);
}

/**
 * Model per lo stato.
 */
export interface AccountState {
  currentAccount: AccountType;
}

/**
 * Definizione dello stato iniziale.
 */
const initialState: AccountState = {
  currentAccount: AccountType.RECEIVABLE,
};

const _onchangeAccountType = (currentState, { payload }) => ({
  ...currentState,
  currentAccount: payload.accountType,
});

const _accountStateReducer = createReducer(initialState, on(AccountActions.changeAccountType, _onchangeAccountType));
