export class DecimalUtilities {
  static hasDecimalLength(value: string | undefined, decimalValue: number): boolean {
    if (!value) return false;

    const decimals = value.toString().split('.');
    const decimalCount: number = decimals[1] ? decimals[1].length : 0;
    return decimalCount <= decimalValue;
  }

  static checkDecimalLength(value: string | undefined, decimalValue: number): number {
    if (!value) value = '';

    const decimals = value.toString().split('.');
    const decimalCount: number = decimals[1] ? decimals[1].length : 0;

    return decimalCount - decimalValue;
  }

  static hasDecimal(value: string): boolean {
    if (!value) return false;

    const decimals = value.toString().split('.');
    const decimalCount: number = decimals[1] ? decimals[1].length : 0;
    return decimalCount > 0;
  }
}
