import { Injectable, OnDestroy } from '@angular/core';
import { OrderBy } from '@ctel/gaw-commons';
import { FilterActions, FilterState, getOrderByFiltersWithUserValuesForSearch } from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { Order } from 'app/shared/components/table/interface/ordering';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx). Questa classe è quasi esclusivamente noop.
 */
@Injectable({
  providedIn: 'root',
})
export class OrderByService2 implements OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly orderBy$: Observable<OrderBy[]>;
  private orderByArray: OrderBy[];

  constructor(private store: Store<FilterState>) {
    this.orderBy$ = this.store.pipe(select(getOrderByFiltersWithUserValuesForSearch));
    this.orderBy$
      .pipe(
        tap((value) => {
          this.orderByArray = value;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
  createOrderByValues(metadata: string, initialOrder: Order = Order.ASCENDING) {
    const newValue = this.getNewOrderByValue(metadata, initialOrder);
    this.store.dispatch(
      FilterActions.changeFilterRequested({
        kind: FilterActions.ChangeFilterRequestedKind.OrderByChanged,
        metadata: newValue.metadata,
        orderByOrder: newValue.order,
      }),
    );
  }

  getOrderByValues(): OrderBy[] {
    return this.orderByArray;
  }

  whenMetadataOrder(metadata: string): Observable<Order> {
    return this.whenOrderByValues().pipe(
      map((orderByArray) => orderByArray.find((el) => el.metadata === metadata)),
      map((foundOrderBy) => {
        if (foundOrderBy) {
          if (foundOrderBy.order === 'asc') return Order.ASCENDING;
          else if (foundOrderBy.order === 'desc') return Order.DESCENDING;
        } else return Order.NOT_SET;
      }),
    );
  }

  whenOrderByValues(): Observable<OrderBy[]> {
    return this.orderBy$;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getNewOrderByValue(metadata: string, initialOrder: Order) {
    const defaultOrder = Order.ASCENDING;
    let newValue: OrderBy;
    if (initialOrder === Order.NOT_SET) initialOrder = defaultOrder;

    // se non ci sono ordinamenti impostati nei filtri lo assegna
    if (this.getOrderByValues().length === 0)
      newValue = {
        metadata,
        order: initialOrder,
      };
    // se c'è un ordinamento per lo stesso metadato, inverte asc/desc
    else if (this.getOrderByValues()[0].metadata === metadata)
      switch (this.getOrderByValues()[0].order) {
        case 'asc': {
          newValue = {
            metadata,
            order: 'desc',
          };
          break;
        }
        case 'desc': {
          newValue = {
            metadata,
            order: 'asc',
          };
          break;
        }
      }
    // se l'ordinamento è per un metadato diverso, lo sostituisco a quello esistente
    else
      newValue = {
        metadata,
        order: 'asc',
      };

    return newValue;
  }
}
