import { Pipe, PipeTransform } from '@angular/core';

export interface ToLocaleStringPipeOptions {
  symbol?: string;
  locale?: string;
}

/**
 * Pipe che fa replace di alcuni caratteri speciali
 */
@Pipe({
  name: 'toLocaleString',
})
export class ToLocaleStringPipe implements PipeTransform {
  transform(value: string | number | null | undefined, isCurrency: boolean, options?: ToLocaleStringPipeOptions) {
    if (value !== null && value !== undefined && value !== '') {
      if (isCurrency) {
        const n = Number(value);
        value = n.toLocaleString(options?.locale || 'it-IT', { minimumFractionDigits: 2 });
        if (options?.symbol) value = value + ' ' + options.symbol;
      } else {
        value = value.toLocaleString();
        value = value.replace(/,/g, '.');
      }
      return value;
    } else return '';
  }
}
