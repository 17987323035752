import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { WorkflowService } from '../../../../services/workflow.service';
import { WorkflowAndItemInfo } from '../../../../entities/work-item/work-item';
import { AuthService } from '@ctel/auth';

@Component({
  selector: 'gaw-workitem-activity-detail-frame',
  templateUrl: './workitem-activity-detail-frame.component.html',
  styleUrls: ['./workitem-activity-detail-frame.component.scss'],
})
export class WorkitemActivityDetailFrameComponent implements OnInit {
  token$: Observable<string>;
  link$: Observable<SafeResourceUrl>;

  constructor(
    private authService: AuthService,
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer,
  ) {}

  @ViewChild('previewForm', { static: false }) set form(form: ElementRef) {
    if (form) form.nativeElement.submit();
  }

  ngOnInit() {
    this.token$ = this.authService.whenToken();

    this.link$ = combineLatest([
      this.workflowService.whenSelectedWorkflow(),
      this.workflowService.selectedWorkItem$,
    ]).pipe(
      filter(([wf, wi]) => wi !== undefined && wf !== undefined),
      map(
        ([currentWF, workItem]) =>
          <WorkflowAndItemInfo>{
            workflowId: currentWF.id,
            workflowName: workItem.workflowName,
            workitemId: workItem.id,
            workItemStage: workItem.stageName,
          },
      ),
      switchMap((workflowAndItemInfo) => this.workflowService.retrievePreviewFormLink(workflowAndItemInfo)),
      map((val) => this.sanitizer.bypassSecurityTrustResourceUrl(val)),
    );
  }
}
