import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfficeDeskHttpService {
  private shortcutHost: string;
  private getShortcutsHost: string;
  private setShortcutHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.shortcutHost = appConfig.gawUserPortalConfiguration.http.host;

    this.getShortcutsHost = `${this.shortcutHost}/api/v1/OfficeDesk/loaduserofficedeskshortcut?IdLicenza={IdLicenza}&Sia={Sia}&isHomePage={isHomePage}`;
    this.setShortcutHost = `${this.shortcutHost}/api/v1/OfficeDesk/setuserofficedeskshortcut/{IdLicenza}/{CodSia}`;
  }

  public getShortcuts(idLicenza: string, sia: string, isHomePage: boolean): Observable<any> {
    const url = this.getShortcutsHost
      .replace('{IdLicenza}', idLicenza)
      .replace('{Sia}', sia)
      .replace('{isHomePage}', isHomePage.toString());
    return this.http.get(url);
  }

  public setShortcut(idLicenza: string, sia: string, body: any): Observable<any> {
    const url = this.setShortcutHost.replace('{IdLicenza}', idLicenza).replace('{CodSia}', sia);
    return this.http.post(url, body);
  }
}
