import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProvinceHttpService } from './province-http.service';
import { ProvinceService } from './province.service';

@NgModule({
  imports: [CommonModule],
  providers: [],
  exports: [],
})
export class ProvinceModule {
  static forRoot(): ModuleWithProviders<ProvinceModule> {
    return {
      ngModule: ProvinceModule,
      providers: [ProvinceHttpService, ProvinceService],
    };
  }
}
