import { Injectable } from '@angular/core';
import { SingleActionsCatalog } from './single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly singleActions: ActionObject[] = [this.singleActionsCatalog.openDetail];

  constructor(private singleActionsCatalog: SingleActionsCatalog) {}
}
