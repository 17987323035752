import { Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

const natura = [
  { value: 'N1', label: 'N1 - escluse ex art. 15', labelWithValue: 'N1 - escluse ex art. 15' },
  {
    value: 'N2.1',
    label: 'N2.1 - non soggette - artt. da 7 a 7-septies DPR 633/72',
    labelWithValue: 'N2.1 - non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72',
  },
  { value: 'N2.2', label: 'N2.2 - non soggette - altri casi', labelWithValue: 'N2.2 - non soggette - altri casi' },
  {
    value: 'N3.1',
    label: 'N3.1 - non imponibili - esportazioni',
    labelWithValue: 'N3.1 - non imponibili - esportazioni',
  },
  {
    value: 'N3.2',
    label: 'N3.2 - non imponibili - cessioni intracomunitarie',
    labelWithValue: 'N3.2 - non imponibili - cessioni intracomunitarie',
  },
  {
    value: 'N3.3',
    label: 'N3.3 - non imponibili - cessioni verso San Marino',
    labelWithValue: 'N3.3 - non imponibili - cessioni verso San Marino',
  },
  {
    value: 'N3.4',
    label: "N3.4 - non imponibili – assimilazioni cessioni all'esportazione",
    labelWithValue: "N3.4 - non imponibili - operazioni assimilate alle cessioni all'esportazione",
  },
  {
    value: 'N3.5',
    label: "N3.5 - non imponibili – per dichiarazioni d'intento",
    labelWithValue: "N3.5 - non imponibili - a seguito di dichiarazioni d'intento",
  },
  {
    value: 'N3.6',
    label: 'N3.6 - non imponibili - altre operazioni fuori plafond',
    labelWithValue: 'N3.6 - non imponibili - altre operazioni che non concorrono alla formazione del plafond',
  },
  { value: 'N4', label: 'N4 - esenti', labelWithValue: 'N4 - esenti' },
  {
    value: 'N5',
    label: 'N5 - regime del margine',
    labelWithValue: 'N5 - regime del margine / IVA non esposta in fattura',
  },
  {
    value: 'N6.1',
    label: 'N6.1 - inv. contabile – cessione rottami e connessi',
    labelWithValue: 'N6.1 - inversione contabile - cessione di rottami e altri materiali di recupero',
  },
  {
    value: 'N6.2',
    label: 'N6.2 - inv. contabile – cessione oro e argento puro',
    labelWithValue: 'N6.2 - inversione contabile - cessione di oro e argento puro',
  },
  {
    value: 'N6.3',
    label: 'N6.3 - inv. contabile – subappalto settore edile',
    labelWithValue: 'N6.3 - inversione contabile - subappalto nel settore edile',
  },
  {
    value: 'N6.4',
    label: 'N6.4 - inv. contabile - cessione fabbricati',
    labelWithValue: 'N6.4 - inversione contabile - cessione di fabbricati',
  },
  {
    value: 'N6.5',
    label: 'N6.5 - inv. contabile - cessione cellulari',
    labelWithValue: 'N6.5 - inversione contabile - cessione di telefoni cellulari',
  },
  {
    value: 'N6.6',
    label: 'N6.6 - inv. contabile - cessione prodotti elettronici',
    labelWithValue: 'N6.6 - inversione contabile - cessione di prodotti elettronici',
  },
  {
    value: 'N6.7',
    label: 'N6.7 - inv. contabile - prestazioni edili e connesse',
    labelWithValue: 'N6.7 - inversione contabile - prestazioni comparto edile e settori connessi',
  },
  {
    value: 'N6.8',
    label: 'N6.8 - inv. contabile - prestazioni settore energetico',
    labelWithValue: 'N6.8 - inversione contabile - operazioni settore energetico',
  },
  {
    value: 'N6.9',
    label: 'N6.9 - inv. contabile - altri casi',
    labelWithValue: 'N6.9 - inversione contabile - altri casi',
  },
  {
    value: 'N7',
    label: 'N7 - IVA assolta in altro stato UE',
    labelWithValue:
      'N7 - IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-octies, comma 1 lett. a, b, art. 74-sexies DPR 633/72)',
  },
];

@Injectable()
export class NaturaService {
  public getList(): InvoiceMultiValues[] {
    return natura.slice();
  }
}
