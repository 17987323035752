import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  DocSeriesMetadataDesc,
  DocSeriesMetadataDescSearch,
  Filter,
  FilterPayload,
  FiltersResponse,
  MemoizeObservable,
  Metric,
} from '@ctel/gaw-commons';
import {
  DocumentActions,
  FilterState,
  IDocument,
  getDocuments,
  getFiltersStateWithUserValues,
  getMetrics,
  getRouterInfo,
  getTotalDocuments,
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import {
  metadataListConfig,
  uiConfigReceivable,
  uiLargeCardsMetricsConfig,
  uiPayableCardsMetricsConfig,
} from 'app/constants/ui-config/ui-config';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AppErrorBuilder } from 'app/core/common/error';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { Comparer } from 'app/core/common/utilities/comparer';
import { Copier } from 'app/core/common/utilities/copier';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DateDropdownsPayableService } from '../../date-dropdowns/date-dropdowns-payable.service';
import { DateDropdownsReceivableService } from '../../date-dropdowns/date-dropdowns-receivable.service';
import { DocumentsHttpService } from '../documents-http.service';
import { FilterService } from '../filters/filter.service';
import { ExtendedDocumentState, selectExtendedDocumentState } from './store/document.extended';

dayjs.extend(utc);

/**
 * Servizio che gestisce lo stato dei documenti (search response).
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsService implements OnDestroy {
  public sectionColumns$: Observable<{
    primaryConfig: Column[];
    secondaryConfig: Column[];
  }>;

  private destroy$ = new Subject<void>();
  private documentListTitle$ = new ReplaySubject<string>(1);
  private currentAccountLabel$ = new ReplaySubject<string>(1);
  private currentClassification$ = new ReplaySubject<string>(1);
  private refresh$: BehaviorSubject<number> = new BehaviorSubject(0);
  private errorLoadingDocuments$ = new BehaviorSubject<boolean>(false);
  private errorDocList$ = new BehaviorSubject<boolean>(false);
  private loadingDocs$ = new BehaviorSubject<boolean>(false);
  private loadingDocsAfterFilterApplication$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnPaging$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnSectionChange$ = new BehaviorSubject<boolean>(false);

  private readonly totalDocumentsWithoutCopies$: Observable<number>;
  private readonly documents$: Observable<IDocument[]>;
  private readonly totalDocuments$: Observable<number>;
  private readonly actualServiceId$: Observable<string>;

  private getColumns$: Observable<string[]>;

  constructor(
    protected documentsHttpService: DocumentsHttpService,
    protected companiesService: CompaniesService,
    protected filterService: FilterService,
    protected dateDropdownsReceivableService: DateDropdownsReceivableService,
    protected dateDropdownsPayableService: DateDropdownsPayableService,
    protected router: Router,
    protected store: Store,
  ) {
    this.documentsInit();

    this.getColumns$ = this.store.pipe(
      select(getFiltersStateWithUserValues(null)),
      map((value: FilterState) => value.selectMetadata),
    );

    this.sectionColumns$ = this.store.pipe(
      select(selectExtendedDocumentState),
      map((value: ExtendedDocumentState) => ({
        primaryConfig: value.primaryColumnConfig,
        secondaryConfig: value.secondaryColumnConfig,
      })),
    );

    this.totalDocumentsWithoutCopies$ = this.store.pipe(
      select(getMetrics),
      map((metrics) => metrics && metrics.length > 0 && metrics[0].value),
    );

    this.documents$ = this.store.pipe(select(getDocuments));
    this.totalDocuments$ = this.store.pipe(select(getTotalDocuments));

    this.actualServiceId$ = this.store.pipe(
      select(getRouterInfo),
      map((routerInfo) => routerInfo.state.params['serviceid']),
    );
  }

  // se docIndex parametri di richiesta non sono modificati, non parte una nuova chiamata http
  private static compareTwoFilterPayloads(previous: FilterPayload, current: FilterPayload): boolean {
    let differences: Array<string> = [];

    if (previous.search.value !== current.search.value)
      differences.push('fullSearchText', previous.search.value, current.search.value);

    if (!Comparer.deepCompare(previous.orderBy, current.orderBy)) differences.push('orderBy');

    if (!Comparer.deepCompare(previous.paging, current.paging)) differences.push('docsPerPages/offset');

    if (!Comparer.deepCompare(previous.siaCode, current.siaCode)) differences.push('siacode');

    if (!Comparer.deepCompare(previous.metrics, current.metrics)) differences.push('metrics');

    differences = differences.concat(DocumentsService.getFiltersDifferences(previous.filters, current.filters));

    // ritorno se i payload sono uguali (true) o diversi (false)
    return differences.length === 0;
  }

  private static getFiltersDifferences(previousFilters, nextFilters): string[] {
    const differences: string[] = [];
    if (previousFilters.length !== nextFilters.length) differences.push('filters.length');
    else if (previousFilters.length > 0 && nextFilters.length > 0)
      for (let i = 0; i < previousFilters.length; i++)
        if (!Comparer.deepCompare(previousFilters[i].value, nextFilters[i].value))
          differences.push('filters[' + i + ']');

    return differences;
  }

  @MemoizeObservable()
  public whenMetadataDescriptions(relatedSectionData: RelatedSectionData[]): Observable<DocSeriesMetadataDesc[]> {
    const reduced = relatedSectionData.reduce(
      (acc, item) => {
        item.docSeriesIds.forEach((docSeriesId) => acc.docSeriesIds.add(docSeriesId));
        item.textSearchMetadata.forEach((metadata) => acc.metadata.add(metadata));
        return acc;
      },
      { docSeriesIds: new Set<string>(), metadata: new Set<string>() },
    );

    const payload = {
      idsSerieDoc: Array.from(reduced.docSeriesIds),
      metadata: Array.from(reduced.metadata),
    } as DocSeriesMetadataDescSearch;
    return this.documentsHttpService.whenMetadataDescriptions(payload);
  }

  whenTypeAhead(text: string): Observable<string[]> {
    const searchStringLength = text.trim().length;
    const minSearchStringLength = 3;

    return this.companiesService.whenCurrentCompany().pipe(
      tap(() => {
        if (searchStringLength < 3)
          throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
            .description(
              `La stringa di ricerca senza spazi marginali (trim) deve avere una lunghezza >= ${minSearchStringLength}`,
            )
            .info('Stringa di ricerca', text)
            .info('Lunghezza trim', searchStringLength)
            .build();
      }),
      take(1),
      debounceTime(200),
      switchMap((company) => {
        const body = {
          docSeriesId: 'ALL',
          licenseId: company.licenseId,
          siaCode: company.siaCode,
          field: 'intestazione',
          text,
          isText: true,
        };
        return this.documentsHttpService.whenSearchTypeahead(body).pipe(
          map((value) => {
            const matchListWithDoubleQuotes: string[] = [];
            value.bestMatchList.forEach(function (match) {
              matchListWithDoubleQuotes.push('"' + match + '"');
            });
            return matchListWithDoubleQuotes;
          }),
          catchError((err: unknown) => {
            throw new AppErrorBuilder(ErrorTypes.GET_FAILURE)
              .cause(err as Error)
              .description('Errore durante la ricerca sui documenti.')
              .info('Stringa di ricerca', text)
              .build();
          }),
        );
      }),
    );
  }

  whenTotalDocumentsWithoutCopies(): Observable<number> {
    return this.totalDocumentsWithoutCopies$;
  }

  whenDocuments(): Observable<IDocument[]> {
    return this.documents$;
  }

  whenTotalDocuments(): Observable<number> {
    return this.totalDocuments$;
  }

  whenActualServiceId(): Observable<string> {
    return this.actualServiceId$;
  }

  // titolo della lista documenti visualizzata in base alla piastrella
  whenDocumentListTitle(): Observable<string> {
    return this.documentListTitle$.asObservable();
  }

  sendDocumentListTitle(title: string) {
    if (title === SectionCode.RECEIVABLE) {
      this.documentListTitle$.next('Ciclo attivo');
      return;
    }
    if (title === SectionCode.PAYABLE) {
      this.documentListTitle$.next('Ciclo passivo');
      return;
    }
    // cerco il titolo in base alla piastrella (metric metadata)
    const configLargeCards = Copier.deepCopy(uiLargeCardsMetricsConfig);
    const labelReceivableObj = configLargeCards.find((object) => object.metadata === title);
    // la label è una piastrella grande dell'attivo
    if (labelReceivableObj !== undefined) {
      this.documentListTitle$.next(labelReceivableObj.title);
      return;
    }
    const configPayableCards = Copier.deepCopy(uiPayableCardsMetricsConfig);
    const labelPayableObj = configPayableCards.find((object) => object.metadata === title);
    // la label è una piastrella del passivo
    if (labelPayableObj !== undefined) {
      this.documentListTitle$.next(labelPayableObj.title);
      return;
    }
    const configSmallCards = Copier.deepCopy(uiConfigReceivable);
    for (let i = 0; i < configSmallCards.services.length; i++) {
      const labelObject = configSmallCards.services[i].tiles.find((object) => object.metadata === title);
      // la label è una piastrella piccola dell'attivo
      if (labelObject !== undefined) {
        this.documentListTitle$.next(labelObject.title);
        return;
      }
    }
  }

  // evidenziazione label ciclo attivo/passivo (receivable/payable)
  whenCurrentAccountLabel(): Observable<string> {
    return this.currentAccountLabel$.asObservable();
  }

  sendCurrentAccountLabel(label: string) {
    this.currentAccountLabel$.next(label);
  }

  // card corrente
  whenCurrentClassification(): Observable<string> {
    return this.currentClassification$.asObservable();
  }

  sendCurrentClassification(label: string) {
    this.currentClassification$.next(label);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setLoadingDocs(value: boolean) {
    this.loadingDocs$.next(value);
  }

  public whenLoadingDocs(): Observable<boolean> {
    return this.loadingDocs$.asObservable();
  }

  public setLoadingDocsAfterFilterApplication(value: boolean) {
    this.loadingDocsAfterFilterApplication$.next(value);
  }

  public whenLoadingDocsAfterFilterApplication(): Observable<boolean> {
    return this.loadingDocsAfterFilterApplication$.asObservable();
  }

  public setLoadingDocsOnPaging(value: boolean) {
    this.loadingDocsOnPaging$.next(value);
  }

  public whenLoadingDocsOnPaging(): Observable<boolean> {
    return this.loadingDocsOnPaging$.asObservable();
  }

  public setLoadingDocsOnSectionChange(value: boolean) {
    this.loadingDocsOnSectionChange$.next(value);
  }

  public whenLoadingDocsOnSectionChange(): Observable<boolean> {
    return this.loadingDocsOnSectionChange$.asObservable();
  }

  public whenErrorLoadingDocs(): Observable<boolean> {
    return this.errorLoadingDocuments$.asObservable();
  }

  public setErrorLoadingDocs(value: boolean) {
    this.errorLoadingDocuments$.next(value);
  }

  public setDocListError(value) {
    this.errorDocList$.next(value);
  }

  public patchFiltersPayload(account: string, filters: Filter[]): Filter[] {
    // Se filtri date sono presenti, allora inietto i values.
    let dateFilters: Filter[];
    if (account === SectionCode.RECEIVABLE)
      dateFilters = this.dateDropdownsReceivableService.getCurrentDateFilterValue();
    else dateFilters = this.dateDropdownsPayableService.getCurrentDateFilterValue();

    // Ribalto i value dei filtri da home in quelli attuali se li ho.
    // Per alcuni metadata imposto i from/to opportunamente.
    const currentFilters = Copier.deepCopy(filters);
    currentFilters.forEach((fetchedFilter: Filter) => {
      let fixed = false;
      if (dateFilters)
        dateFilters.forEach((dateFilter: Filter) => {
          if (dateFilter.metadata === fetchedFilter.metadata) {
            fetchedFilter.value = dateFilter.value;
            fixed = true;
          }
        });

      if (
        !fixed &&
        (fetchedFilter.metadata === MetadataEnum.DATA_INSERIMENTO ||
          // || fetchedFilter.metadata === MetadataEnum.DATA_FATTURA
          fetchedFilter.metadata === MetadataEnum.DATA_RICEZIONE)
      )
        fetchedFilter.value = {
          from: dayjs()
            .subtract(3, 'month')
            .utcOffset(0)
            .set('h', 0)
            .set('m', 0)
            .set('s', 0)
            .set('ms', 0)
            .toISOString(),
          to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
        };

      // Impostiamo un default per i configData dei range di date.
      if (
        fetchedFilter.filterType === 'range' &&
        fetchedFilter.type === 'date' &&
        (!fetchedFilter.configData || (!fetchedFilter.configData.from && !fetchedFilter.configData.to))
      )
        fetchedFilter.configData = {
          from: dayjs()
            .subtract(3, 'month')
            .utcOffset(0)
            .set('h', 0)
            .set('m', 0)
            .set('s', 0)
            .set('ms', 0)
            .toISOString(),
          to: dayjs().endOf('day').set('h', 23).set('m', 50).set('s', 59).set('ms', 999).toISOString(),
        };
    });

    return currentFilters;
  }

  /**
   * Crea un payload per i filtri a partire da filtri e colonne.
   * Il payload verrà utilizzato per le successive richieste alla /search di Magellano.
   * Questo metodo è utilizzato nella gestione dinamica dei filtri.
   * Altera parzialmente i filtri per impostare gli eventuali filtri data dalla home (con default a ultimo trimestre).
   * Imposta anche metric e orderby.
   *
   * @param licenseId la licenza corrente
   * @param siaCode l'azienda corrente
   * @param account la macro-sezione logica (receivable/payable)
   * @param filterPayload il payload dei filtri precedentemente fetchati da mocks/API
   * @param columns le colonne precedentemente fetchate da UiConfiguration
   * @param classification l'effettiva sezione
   */
  public buildFilterPayload(
    licenseId: string,
    siaCode: string,
    account: string,
    filterPayload: FiltersResponse,
    columns: string[],
    classification: string,
  ): FilterPayload {
    let metrics: Metric[] = [];

    const currentFilters: Filter[] = this.patchFiltersPayload(account, filterPayload.filters);

    if (classification === SectionCode.CP_SQUADRATO)
      metrics = [
        {
          metadata: 'hubfe_cp_squadrato',
          metricType: 'sum',
          filter: {
            metadata: 'hubfe_cp_idTipo',
            filterType: 'term',
            type: 'integer',
            configData: {},
            value: {
              term: '0',
            },
          },
        },
      ];

    return {
      licenseId,
      siaCode,
      docSeriesId: 'ALL',
      filters: currentFilters,
      metrics,
      selectMetadata: [...columns, MetadataEnum.ID_SERIE_DOC], // Serve ID_SERIE_DOC per alcune azioni. Ma senza avere la colonna.
      search: {
        fullText: false, // Su hubFE non esiste la fullText.
        value: '', // Nei nuovi filtri, comanda lo stato dell'URL per quanto riguarda i filtri utente.
        metadataList: filterPayload.search ? filterPayload.search.metadataList : metadataListConfig,
      },
      paging: filterPayload.paging,
      orderBy: filterPayload.orderBy,
    };
  }

  /**
   * forza refresh griglia documenti
   */
  refreshDocuments() {
    this.store.dispatch(DocumentActions.fetchDocuments(true));
  }

  // viene chiamato all'arrivo di un nuovo valore di FiltersPayload nell'observable (può essere uguale al precedente, ma se è
  // uguale non eseguo la chiamata http)
  protected searchValuesComparator = (previous: FilterPayload, current: FilterPayload) =>
    DocumentsService.compareTwoFilterPayloads(previous, current);

  protected documentStream$ = (filterPayload) =>
    this.refresh$.pipe(
      tap(() => {
        this.setLoadingDocs(true);
      }),
      switchMap(() =>
        this.documentsHttpService.whenAllDocuments(JSON.stringify(filterPayload)).pipe(
          tap(() => {
            this.setErrorLoadingDocs(false);
            this.setLoadingDocs(false);
          }),
          catchError((err: unknown) => {
            if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
              //this.router.navigate(['/unauthorized']).then();
            } else {
              this.setLoadingDocs(false);
              this.setErrorLoadingDocs(true);
            }
            return EMPTY;
          }),
        ),
      ),
    );

  private documentsInit() {
    // chiedo anche le serie documentali per associare un colore label
    this.companiesService
      .whenCurrentCompany()
      .pipe(
        switchMap((company) =>
          this.documentsHttpService.whenDocSeries(company.licenseId, company.siaCode).pipe(take(1)),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((result) => {
        this.companiesService.sendDocSeriesInfo(result);
        const labels = [];
        for (let i = 0; i < result.length; i++) labels.push(result[i].description);

        this.companiesService.sendDocumentSeriesColors(labels);
      });
  }
}
