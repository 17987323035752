import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import {
  ActionsHttpService,
  DocumentsSeriesService,
  FRDocumentiDocumentsService2,
  FilterService2,
} from '@ctel/gawcons-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { count, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SignReasonModalComponent } from '../../../modals/sign-reason-modal/sign-reason-modal.component';
import { FrLottiService } from '../../../services/fr-lotti.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
  providedIn: 'root',
})
export class AllDocumentsCallback implements OnDestroy {
  private readonly _filterPayload$: Observable<FilterStatus>;
  private readonly _totalDocuments$: Observable<number>;
  private concurrentDownloads = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private documentsService: FRDocumentiDocumentsService2,
    private filterService: FilterService2,
    private notificationService: NotificationService,
    private documentsSeriesService: DocumentsSeriesService,
    private actionsHttpService: ActionsHttpService,
    private transferService: TransferService,
    private frLottiService: FrLottiService,
  ) {
    this._totalDocuments$ = documentsService.whenTotalDocuments();
    this._filterPayload$ = filterService.whenFilterValue();
    this.transferService
      .whenTransfers()
      .pipe(
        switchMap((value) =>
          of(Object.values<Transfer>(value)).pipe(
            mergeMap((value1) => value1),
            count((v) => !TransferUtils.isComplete(v)),
            tap((v) => (this.concurrentDownloads = v)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signMassive(action: ActionObject, extraParams: unknown) {
    return this._totalDocuments$.pipe(
      take(1),
      mergeMap(() =>
        combineLatest([this._filterPayload$]).pipe(
          take(1),
          switchMap(([payload]) => this.frLottiService.checkMassive(JSON.stringify(payload))),
          tap((anomalies) => {
            if (anomalies)
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'Firma',
                'La selezione comprende dei lotti con documenti anomali: si prega di selezionare ' +
                  'prima i soli lotti che presentano anomalie utilizzando i filtri di ricerca e procedere alla loro conferma',
              );
            else this.openSign();
          }),
        ),
      ),
    );
  }

  openSign() {
    this.notificationService.showModal(NotificationType.CUSTOM, {
      title: 'Firma',
      childComponent: SignReasonModalComponent,
      customFooter: true,
      disableClickOutside: false,
      childData: {
        sign: this.sign,
      },
    });
  }

  reasonConfirm = () =>
    this._filterPayload$.pipe(
      mergeMap((payload) => {
        const body = {
          searchRequest: payload,
          motivazioneConferma: 'Conferma automatica',
        };
        return this.frLottiService.reasonConfirm(JSON.stringify(body));
      }),
    );

  sign = (username: string, password: string, otp: number) =>
    this._filterPayload$.pipe(
      mergeMap((payload) => {
        const body = {
          searchRequest: payload,
          username,
          password,
          otp,
        };
        return this.frLottiService.sign(JSON.stringify(body));
      }),
      tap(() =>
        this.notificationService.showSweetAlert(
          NotificationType.SUCCESS,
          'Firma',
          'Operazione avvenuta con successo',
          () => this.documentsService.refreshDocuments(),
        ),
      ),
    );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  cancelMassive(action: ActionObject, extraParams: unknown) {
    return this._totalDocuments$.pipe(
      take(1),
      mergeMap(() =>
        combineLatest([this._filterPayload$]).pipe(
          take(1),
          switchMap(([payload]) => {
            this.notificationService.showSweetAlert(
              NotificationType.QUESTION,
              'Cancella',
              'Confermi la cancellazione massiva?',
              () => this.executeCancelMassive(JSON.stringify(payload)),
            );

            return EMPTY;
          }),
        ),
      ),
    );
  }

  executeCancelMassive = (body: string) => {
    this.frLottiService.cancelMassive(body).subscribe({
      next: () => {
        this.notificationService.showSweetAlert(
          NotificationType.SUCCESS,
          'Cancella',
          'I lotti selezionati sono stati cancellati con successo',
        );
        this.documentsService.refreshDocuments();
      },
      error: (error: unknown) =>
        this.notificationService.showSweetAlert(
          NotificationType.ERROR,
          'Errore',
          error['additionalInfo']?.find((x) => x.t === 'response')?.u,
        ),
    });
  };

  confirmMassive() {
    return this._totalDocuments$.pipe(
      take(1),
      mergeMap(() =>
        combineLatest([this._filterPayload$]).pipe(
          take(1),
          switchMap(([payload]) =>
            this.frLottiService
              .checkMassive(JSON.stringify(payload))
              .pipe(map((anomalies) => ({ payload, anomalies }))),
          ),
          tap(({ payload, anomalies }) => {
            const body = {
              searchRequest: payload,
              motivazioneConferma: 'Conferma automatica',
            };
            if (anomalies)
              this.notificationService.showSweetAlert(
                NotificationType.QUESTION,
                'Conferma',
                'Stai confermando documenti con anomalie, sei sicuro?',
                () =>
                  this.frLottiService
                    .reasonConfirm(JSON.stringify(body))
                    .pipe(take(1))
                    .subscribe({
                      next: () => {
                        this.notificationService.showSweetAlert(
                          NotificationType.SUCCESS,
                          '',
                          'Conferma dei documenti con anomalia avvenuta con successo',
                        );
                        this.documentsService.refreshDocuments();
                      },
                      error: (error: unknown) =>
                        this.notificationService.showSweetAlert(
                          NotificationType.ERROR,
                          '',
                          error['additionalInfo']?.find((x) => x.t === 'response')?.u,
                        ),
                    }),
              );
            else this.notificationService.showSweetAlert(NotificationType.INFO, '', 'Nessun documento anomalo trovato');

            return EMPTY;
          }),
        ),
      ),
    );
  }

  // Controllo sull'azione in input, ritorna true se l'url è valido
  // noinspection JSMethodCanBeStatic
  private checkActionInput(action): boolean {
    return !!(action && action.url);
  }
}
