import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from 'app/core/common/app-common.module';
import { ErrorPlaceholderModule } from 'app/core/common/placeholder/error-placeholder/error-placeholder.module';
import { DryLoadingPlaceholderModule } from 'app/core/common/placeholder/loading-placeholder/dry-loading-placeholder.module';
import { GridModule } from 'app/shared/components/grid/grid.module';
import { HeaderModule } from 'app/shared/components/header/header.module';
import { InvoiceLayoutsModule } from 'app/shared/components/invoice-layouts/invoice-layouts.module';
import { PaginationModule } from 'app/shared/components/pagination/pagination.module';
import { TableModule } from 'app/shared/components/table/table.module';
// import { CoreCommonModule } from 'app/core/common/core-common.module';
import { BlockUIModule } from '@ctel/block-ui';
import { PipesModule } from 'app/shared/pipes/pipes.module';

// import { BsDatepickerModule, ModalModule, PopoverModule, TooltipModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DirectivesModule } from '../directives/directives.module';
import { DisplayDocumentModule } from './display-document/display-document.module';
import { DragAndDropComponent } from './drag-and-drop/drag-and-drop.component';
import { DryComponentsModule } from './dry/dry-components.module';
import { FormValidationModule } from './form-validation/form-validation.module';
import { MainMenuButtonBlockComponent } from './main-menu-button-block/main-menu-button-block.component';
import { SaveFavoriteSearchComponent } from './save-favorite-search/save-favorite-search.component';
import { TableOfContentsModule } from './table-of-contents/table-of-contents.module';

@NgModule({
  imports: [
    // CoreCommonModule,
    AppCommonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    RouterModule.forChild([]),
    ModalModule,
    BlockUIModule,
    TooltipModule,
    FormValidationModule,
    DisplayDocumentModule,
    RouterModule,
    ErrorPlaceholderModule,
    // Pagination
    PaginationModule,
    // Table
    TableModule,
    // Grid
    GridModule,
    DirectivesModule,
    PipesModule,
    DryLoadingPlaceholderModule,
    PopoverModule,
    TypeaheadModule,
    TableOfContentsModule,
    DryComponentsModule,
    HeaderModule,
    InvoiceLayoutsModule,
  ],
  declarations: [MainMenuButtonBlockComponent, DragAndDropComponent, SaveFavoriteSearchComponent],
  exports: [MainMenuButtonBlockComponent, DryComponentsModule, SaveFavoriteSearchComponent],
})
export class ComponentsModule {}
