import { NgForm } from '@angular/forms';
import { Action } from '../action';
import { ActionParams } from './action-params';

export class CleanFilterParams extends ActionParams {
  constructor(
    action: Action,
    public form: NgForm,
  ) {
    super(action);
  }
}
