import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateShort',
})
export class DateShortPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | number | Date): string {
    return this.datePipe.transform(value, 'dd/MM/yyyy');
  }
}
