import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@ctel/auth';
import { ModernizrService } from '@ctel/modernizr';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'gaw-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnDestroy {
  public isSupportedBrowser: boolean;
  public panelClass: string;
  public panelBodyClass: string;
  private destroy$ = new Subject<void>();
  private readonly panelClassOnModernBrowser = 'panel panel-default login-panel';
  private readonly panelClassOnOldBrowser = 'panel panel-default login-panel old-browser-panel';
  private readonly panelBodyClassOnOldBrowser = 'panel-body old-browser-panel-body';
  private readonly panelBodyClassOnModernBrowser = 'panel-body';

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private modernizrService: ModernizrService,
  ) {
    this.isSupportedBrowser = this.modernizrService.checkFeature('smil');
    if (this.isSupportedBrowser) {
      this.panelClass = this.panelClassOnModernBrowser;
      this.panelBodyClass = this.panelBodyClassOnModernBrowser;
    } else {
      this.panelClass = this.panelClassOnOldBrowser;
      this.panelBodyClass = this.panelBodyClassOnOldBrowser;
    }
    this.authService.isOidcInitialized$
      .pipe(
        filter((val) => val),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (this.isSupportedBrowser) this.onModuleSetup();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onModuleSetup() {
    this.authService
      .checkAuth()
      .pipe(take(1), withLatestFrom(this.route.queryParams))
      .subscribe(([isAuth, queryParam]) => {
        if (isAuth) this.authService.access();
        else this.authService.login(queryParam['bridgetk']);
      });
  }
}
