import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FavoriteSearchesService } from 'app/modules/homepage/core/favorite-searches/favorite-searches.service';
import { SaveSearchModel, UpdateSearchModel } from 'app/modules/homepage/entities/favorite-search/favorite-search';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationType } from '../../notification/notification-type';
import { NotificationService } from '../../notification/notification.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-favorite-search-modal',
  templateUrl: './favorite-search-modal.component.html',
  styleUrls: ['./favorite-search-modal.component.scss'],
})
export class FavoriteSearchModalComponent implements OnInit, AfterViewInit {
  @ViewChild('inputName') inputName!: ElementRef;
  @Input() data: any;
  @Input() modal: BsModalRef;
  formGroup = new UntypedFormGroup({});

  constructor(
    private fb: UntypedFormBuilder,
    private favoriteSearchesService: FavoriteSearchesService,
    private notificationService: NotificationService,
  ) {}

  get favoriteSearchName() {
    return this.formGroup.get('favoriteSearchName');
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      favoriteSearchName: [this.data.name, [Validators.required, Validators.maxLength(50)]],
    });
  }

  ngAfterViewInit(): void {
    this.inputName.nativeElement.focus();
  }

  onSubmit() {
    const { licenseId, siaCode, url, service, guid } = this.data;
    if (this.formGroup.value.favoriteSearchName)
      if (this.data.guid) {
        //UPDATE
        const data: UpdateSearchModel = {
          searchName: this.formGroup.value.favoriteSearchName,
        };
        this.favoriteSearchesService
          .updateFavoriteSearches(licenseId, siaCode, guid, data)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.favoriteSearchesService.getFavoriteSearchesList(licenseId, siaCode);
              this.modal.hide();
            },
            error: (error: unknown) => {
              this.notificationService.showSweetAlert(NotificationType.ERROR, '', error['additionalInfo'][0].u, () => {
                this.modal.hide();
              });
            },
          });
      } else {
        //INSERT
        const data: SaveSearchModel = {
          searchName: this.formGroup.value.favoriteSearchName,
          idLicenza: licenseId,
          sia: siaCode,
          servizio_Id: service,
          navigationUrl: url,
        };
        this.favoriteSearchesService
          .saveFavoriteSearchesList(data)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.modal.hide();
            },
            error: (error: unknown) => {
              this.notificationService.showSweetAlert(NotificationType.ERROR, '', error['additionalInfo'][0].u, () => {
                this.modal.hide();
              });
            },
          });
      }
  }
}
