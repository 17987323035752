import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaxDecimalValidatorDirective } from 'app/shared/components/dry/validator/directive/number/max-decimal-validator.directive';
import { EmailValidatorDirective } from 'app/shared/components/dry/validator/directive/text/email-validator.directive';
import { OnlyLetterValidatorDirective } from 'app/shared/components/dry/validator/directive/text/only-letter-validator.directive';
import { OnlyNumberValidatorDirective } from 'app/shared/components/dry/validator/directive/text/only-number-validator.directive';
import { IsBooleanValidatorDirective } from './directive/checkbox/is-boolean-validator.directive';
import { IsDateValidatorDirective } from './directive/date/is-date-validator.directive';
import { MaxDateValidatorDirective } from './directive/date/max-date-validator.directive';
import { DecimalLengthDirective } from './directive/number/decimal-length.directive';
import { HasDecimalDirective } from './directive/number/has-decimal.directive';
import { IsNumberValidatorDirective } from './directive/number/is-number-validator.directive';
import { MaxValueValidatorDirective } from './directive/number/max-value-validator.directive';
import { MinValueValidatorDirective } from './directive/number/min-value-validator.directive';
import { NegativeValueValidatorDirective } from './directive/number/negative-value-validator.directive';
import { SelectValidatorDirective } from './directive/select/select-validator.directive';
import { AtLeastNumberValidatorDirective } from './directive/text/at-least-number-validator.directive';
import { BicTypeValidatorDirective } from './directive/text/bic-type-validator.directive';
import { CodiceFiscaleValidatorDirective } from './directive/text/codice-fiscale-validator.directive';
import { FaxValidatorDirective } from './directive/text/fax-validator.directive';
import { OnlyNumberAndLetterValidatorDirective } from './directive/text/only-number-and-letter-validator.directive';
import { FileValueAccessorDirective } from './file-control-value-accessor';
import { FileValidator } from './file-input.validator';

@NgModule({
  imports: [CommonModule],
  declarations: [
    // Date
    IsDateValidatorDirective,
    MaxDateValidatorDirective,

    // Number
    IsNumberValidatorDirective,
    MaxValueValidatorDirective,
    MinValueValidatorDirective,
    MaxDecimalValidatorDirective,
    NegativeValueValidatorDirective,

    // Select
    SelectValidatorDirective,

    // Text
    OnlyNumberValidatorDirective,
    FaxValidatorDirective,
    EmailValidatorDirective,
    OnlyLetterValidatorDirective,
    CodiceFiscaleValidatorDirective,
    OnlyNumberAndLetterValidatorDirective,
    AtLeastNumberValidatorDirective,
    IsBooleanValidatorDirective,
    BicTypeValidatorDirective,
    DecimalLengthDirective,
    HasDecimalDirective,
    FileValidator,
    FileValueAccessorDirective,
  ],
  exports: [
    // Date
    IsDateValidatorDirective,
    MaxDateValidatorDirective,

    // Number
    IsNumberValidatorDirective,
    MaxValueValidatorDirective,
    MinValueValidatorDirective,
    MaxDecimalValidatorDirective,
    NegativeValueValidatorDirective,
    HasDecimalDirective,

    // Select
    SelectValidatorDirective,

    // Text
    OnlyNumberValidatorDirective,
    FaxValidatorDirective,
    EmailValidatorDirective,
    OnlyLetterValidatorDirective,
    CodiceFiscaleValidatorDirective,
    OnlyNumberAndLetterValidatorDirective,
    AtLeastNumberValidatorDirective,
    IsBooleanValidatorDirective,
    BicTypeValidatorDirective,
    DecimalLengthDirective,
    FileValidator,
    FileValueAccessorDirective,
  ],
})
export class ValidatorModule {}
