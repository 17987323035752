import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class MultiActionsCatalog {
  public readonly downloadMergedPdf = new ActionBuilder(
    MultiActionCode.downloadMergedPdf,
    ActionCode.DOWNLOAD_MERGED_PDF,
  )
    .setActionURL('/v1/Document/DisplayMergedDocs')
    .build();

  public readonly downloadZip = new ActionBuilder(MultiActionCode.downloadZip, ActionCode.DOWNLOAD_ZIP)
    .setActionURL('/v1/Document/Scarica')
    .build();

  // public readonly startWorkflow = new ActionBuilder(MultiActionCode.startWorkflow, ActionCode.START_WORKFLOW)
  // 	.setActionURL('')
  // 	.build();

  public readonly exportExcel = new ActionBuilder(MultiActionCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
    .setActionURL('/v2/documents/export/excel?clientId=GAW30')
    .build();

  public readonly multiHideDocumentsAction = new ActionBuilder(
    MultiActionCode.multiHideDocumentsAction,
    ActionCode.HIDE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
    .build();

  public readonly multiRestoreDocumentsAction = new ActionBuilder(
    MultiActionCode.multiRestoreDocumentsAction,
    ActionCode.RESTORE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
    .build();

  public readonly moveToTrash = new ActionBuilder(MultiActionCode.moveToTrash, ActionCode.MOVE_TO_TRASH)
    .setActionURL('/Trash/command/MoveToTrash')
    .build();

  public readonly downloadMultiXml = new ActionBuilder(MultiActionCode.downloadMultiXml, ActionCode.DOWNLOAD_XML_ZIP)
    .setActionURL('/Fatture/allegati/download')
    .build();
}

export enum MultiActionCode {
  downloadMergedPdf = 'downloadMergedPdf',
  downloadZip = 'downloadZip',
  // startWorkflow = 'startWorkflow',
  exportExcel = 'exportExcel',
  multiHideDocumentsAction = 'multiHideDocumentsAction',
  multiRestoreDocumentsAction = 'multiRestoreDocumentsAction',
  moveToTrash = 'moveToTrash',
  downloadMultiXml = 'downloadMultiXml',
}
