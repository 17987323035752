import { Component, Input } from '@angular/core';
import { BoxDetails, IDetailsComponent } from '@ctel/gaw-commons';
import { Observable } from 'rxjs';

@Component({
  selector: 'gaw-gawpass-details-main',
  templateUrl: './gawpass-details-main.component.html',
  styleUrls: ['./gawpass-details-main.component.scss'],
})
export class GawpassDetailsMainComponent {
  @Input() data: IDetailsComponent;
  @Input() boxDetails$: Observable<BoxDetails>;
}
