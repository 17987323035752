<div class="modal-body">
  <textarea
    [(ngModel)]="msg"
    (keydown.enter)="submit($event.target)"
    [disabled]="disabled ? true : null"
    class="document-notes-lib inputArea"
    placeholder="Inserisci il contenuto della nota"
    #textAreaElement
    [ngStyle]="textAreaStyle?.style"
    [ngClass]="textAreaStyle?.klass"
    maxlength="200"
  ></textarea>
  <p class="document-notes-lib message" [ngClass]="{ maxChar: charCount === 200 }">{{ charCount }}/200 caratteri</p>
</div>

<div class="modal-footer d-flex justify-content-end">
  <button type="button" class="btn gaw-default-btn" (click)="close()">Annulla</button>
  <button class="btn btn-primary" [disabled]="disabled" (click)="submit(textAreaElement)">Salva</button>
</div>
