import { Injectable } from '@angular/core';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly massiveActions: ActionObject[] = [
    this.massiveActionsCatalog.signMassive,
    this.massiveActionsCatalog.confirmMassive,
    this.massiveActionsCatalog.cancelMassive,
  ];

  public readonly multiActions: ActionObject[] = [
    this.multiActionsCatalog.signMultiple,
    this.multiActionsCatalog.cancelMultiple,
  ];

  public readonly singleActions: ActionObject[] = [
    this.singleActionsCatalog.openDetail,
    this.singleActionsCatalog.sign,
    this.singleActionsCatalog.cancel,
  ];

  constructor(
    private massiveActionsCatalog: MassiveActionsCatalog,
    private multiActionsCatalog: MultiActionsCatalog,
    private singleActionsCatalog: SingleActionsCatalog,
  ) {}
}
