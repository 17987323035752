import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidationError } from 'app/shared/components/dry/validator/custom-validation-error';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { CreateService } from '../../../../modules/portal-settings/modules/employees/services/create.service';
import { ErrorMessage } from '@ctel/gaw-fields';

/**
 * Validatori relativi solo ai form di tipo Text
 */
export class TextValidators {
  /**
   * Validatore per il controllo di un pattern specifico.
   * @param regex
   * @return ValidatorFn
   */
  public static pattern(regex: string | RegExp): ValidatorFn {
    return Validators.pattern(regex);
  }

  /**
   * Validatore per il controllo della lunghezza minima.
   * @param n
   * @return ValidatorFn
   */
  public static minLength(n: number): ValidatorFn {
    return Validators.minLength(n);
  }

  /**
   * Validatore per il controllo della lunghezza massima.
   * @param n
   * @return ValidatorFn
   */
  public static maxLength(n: number): ValidatorFn {
    return Validators.maxLength(n);
  }

  /**
   * Validatore per partita iva.
   * @return ValidatorFn
   */
  public static patternPartitaIva(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^[0-9]{11}$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              partitaIva: {
                message: 'Il campo deve contenere 11 caratteri numerici',
              },
            };

      return null;
    };
  }

  /** Validatore per controllare che la stringa abbia almeno un carattere maiuscolo */
  public static atLeastUpperChar =
    () =>
    (control: AbstractControl): CustomValidationError => {
      const regex = new RegExp('^.*[A-Z]+.*$');
      return control.value && control.value !== '' && regex.test(control.value)
        ? null
        : <CustomValidationError>{
            upperChar: {
              message: 'Il campo deve avere almeno una lettera maiuscola',
            },
          };
    };

  /** Validatore per controllare che la stringa abbia almeno un carattere minuscolo */
  public static atLeastLowerChar =
    () =>
    (control: AbstractControl): CustomValidationError => {
      const regex = new RegExp('^.*[a-z]+.*$');
      return control.value && control.value !== '' && regex.test(control.value)
        ? null
        : <CustomValidationError>{
            lowerChar: {
              message: 'Il campo deve avere almeno una lettera minuscola',
            },
          };
    };

  /** Validatore per controllare che la stringa abbia almeno un carattere speciale */
  public static atLeastOneSpecialChar =
    () =>
    (control: AbstractControl): CustomValidationError => {
      const regex = new RegExp('^.*[^a-zA-Z0-9_]+.*$');
      return control.value && control.value !== '' && regex.test(control.value)
        ? null
        : <CustomValidationError>{
            specialChar: {
              message: 'Il campo deve contenere almeno un carattere speciale',
            },
          };
    };

  /**
   * Validatore per codice fiscale.
   * @return ValidatorFn
   */
  public static patternCodiceFiscale(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^(?:[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]|\\w{11})$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              codiceFiscale: {
                message: 'Codice fiscale non valido',
              },
            };

      return null;
    };
  }

  /**
   * Validatore per testo senza spazi.
   * @return ValidatorFn
   */
  public static noSpacePattern(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^\\S*$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              noSpaceDigit: {
                message: 'Il campo non deve contenere spazi',
              },
            };

      return null;
    };
  }

  public static onlyNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^\\d+$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              onlyNumber: {
                message: 'Sono ammessi solo valori numerici',
              },
            };

      return null;
    };
  }

  public static onlyLetter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^[a-zA-Z]+$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              onlyLetter: {
                message: 'Sono ammessi solo lettere alfabetiche',
              },
            };

      return null;
    };
  }

  public static patternFaxAndTelephone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      // eslint-disable-next-line no-control-regex
      const regex = new RegExp('([\x00-\x7F]{5,12})');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              fax: {
                message: 'Non rispecchia un fax valido',
              },
            };

      return null;
    };
  }

  public static patternEmail(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^.+[@].+[.]+.+$');
      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              email: {
                message: "Non rispecchia un'email valida",
              },
            };

      return null;
    };
  }

  public static onlyLetterAndNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^[a-zA-Z0-9]+$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              onlyLetterAndNumber: {
                message: 'Sono ammessi solo lettere alfabetiche e valori numerici',
              },
            };

      return null;
    };
  }

  /** Validatore per controllare che ci sia almeno un carattere numerico nella stringa */
  public static atLeastOneNumber = () => {
    const regex = new RegExp('^.*[0-9]+.*$');
    return (control: AbstractControl): CustomValidationError =>
      control.value && !regex.test(control.value)
        ? <CustomValidationError>{
            atLeastOneNumber: {
              message: 'Deve contenere almeno un valore numerico',
            },
          }
        : null;
  };

  /** Validatore per controllare che ci sia almeno una lettera nella stringa */
  public static atLeastOneChar = () => {
    const regex = new RegExp('^.*[A-Za-z]+.*$');
    return (control: AbstractControl): CustomValidationError =>
      control.value && !regex.test(control.value)
        ? <CustomValidationError>{
            atLeastOneChar: {
              message: 'Deve contenere almeno una lettera',
            },
          }
        : null;
  };

  public static bicTypeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3}){0,1}');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              BIC: {
                message: 'Campo non valido',
              },
            };

      return null;
    };
  }

  public static abiTypeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^[0-9]{5}$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              ABI: {
                message: 'Il campo deve essere di 5 caratteri numerici',
              },
            };

      return null;
    };
  }

  public static cabTypeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      const regex = new RegExp('^[0-9]{5}$');

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              CAB: {
                message: 'Il campo deve essere di 5 caratteri numerici',
              },
            };

      return null;
    };
  }

  public static pecDestinatario(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      // Questa valida dal 1-01-2021
      // const regex = new RegExp('sdi(.*)@pec\\.fatturapa\\.it');
      // Valida fino al 31-12-2020
      const regex = new RegExp('.+@.+[.]+.+');
      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? <CustomValidationError>{
              email: {
                message: 'Email non ammessa',
              },
            }
          : null;

      return null;
    };
  }

  public static mustNotContainString(unwantedString: string) {
    const regex = new RegExp(unwantedString);
    return (control: AbstractControl): CustomValidationError =>
      control.value && !regex.test(control.value)
        ? <CustomValidationError>{
            mustNotContain: {
              message: `Non può contenere la stringa ${unwantedString}`,
            },
          }
        : null;
  }

  static patternPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = new RegExp(
        '^\\s*(?:\\+?(\\d{1,3}))?([-. (]*(\\d{3})[-. )]*)?((\\d{3})[-. ]*(\\d{2,4})(?:[-.x ]*(\\d+))?)\\s*$',
      );

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              numeroTelefono: {
                message: 'Il campo non corrisponde a un numero valido',
              },
            };

      return null;
    };
  }

  public static checkNickname(createService: CreateService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      createService.checkNickname(control.value).pipe(
        debounceTime(500),
        distinctUntilChanged(),
        take(1),
        map(() => null),
        catchError(() =>
          of(<CustomValidationError>{
            nomeUtente: {
              message: 'Nome Utente già presente',
            },
          }),
        ),
      );
  }

  static patternPassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const regex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\\\!#$%&@()*+,./:;=?_{|}\\-\\[\\]])[A-Za-z\\d\\\\!#$%&@()*+,./:;=?_{|}\\-\\[\\]]{11,128}$',
      );

      if (control.value && control.value !== '')
        return regex.test(control.value)
          ? null
          : <CustomValidationError>{
              password: {
                message:
                  'La password deve avere:\n' +
                  'Lunghezza compresa tra 11 e 128 caratteri \n' +
                  'Almeno una maiuscola \n' +
                  'Almeno una minuscola\n' +
                  'Almeno un numero\n' +
                  'Almeno un simbolo tra !#$%&@()*+,./-:;=?[]_{|}\\',
              },
            };

      return null;
    };
  }
}
