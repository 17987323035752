import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthService } from '@ctel/auth';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { PostMessagePayload } from 'app/entities/post-message-payload';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BeFramePayload } from '../../../../entities/action/be-frame-payload';
import { WorkflowHttpService } from '../../../../services/workflow-http.service';
import { IndirectActionExecution } from './indirect-action-execution.enum';

@Component({
  selector: 'gaw-workitem-activity-indirect-action-frame',
  templateUrl: './workitem-activity-indirect-action-frame.component.html',
  styleUrls: ['./workitem-activity-indirect-action-frame.component.scss'],
})
export class WorkitemActivityIndirectActionFrameComponent implements OnInit, OnDestroy {
  @Input() link: string;
  @Output() indirectActionCompleted = new EventEmitter<{
    execute: IndirectActionExecution;
    stillInCharge: boolean;
  }>();

  token$: Observable<string>;

  private frame$: ReplaySubject<Element> = new ReplaySubject<Element>(1);
  private destroy$ = new Subject<void>();

  constructor(
    private readonly authService: AuthService,
    private readonly workflowHttpService: WorkflowHttpService,
  ) {
    this.token$ = this.authService.whenToken();
  }

  @ViewChild('postForm', { static: false }) set form(form: ElementRef) {
    if (form) form.nativeElement.submit();
  }

  @ViewChild('frame', { static: false }) set frame(frame: ElementRef) {
    if (frame) this.sendFrame(frame.nativeElement);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: PostMessagePayload<BeFramePayload>) {
    if (event.origin === this.workflowHttpService.getBeFlowHost())
      switch (event.data.action) {
        case 'abort':
          this.indirectActionCompleted.emit({
            execute: IndirectActionExecution.ABORT,
            stillInCharge: event.data.stillInCharge,
          });
          break;
        case 'failed':
          this.indirectActionCompleted.emit({
            execute: IndirectActionExecution.FAILED,
            stillInCharge: event.data.stillInCharge,
          });
          break;
        case 'success':
          this.indirectActionCompleted.emit({
            execute: IndirectActionExecution.SUCCESS,
            stillInCharge: event.data.stillInCharge,
          });
          break;
        default:
          throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
            .description('Errore durante la lettura del payload da BE')
            .info('value', event.data.action)
            .build();
      }
  }

  ngOnInit() {
    this.whenFrame()
      .pipe(
        tap((frame: HTMLIFrameElement) => (frame.onload = () => this.sendHostMessage(frame))),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sendHostMessage(frame) {
    const beflowUrl = this.workflowHttpService.getBeFlowHost();
    const receiver = frame.contentWindow;
    receiver.postMessage(window.location.origin, beflowUrl);
  }

  private sendFrame(frame) {
    this.frame$.next(frame);
  }

  private whenFrame() {
    return this.frame$.asObservable();
  }
}
