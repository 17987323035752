/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { IGawconsDetailsComponentInterface } from '../../entities/gawcons-details-component.interface';

@Injectable({
  providedIn: 'root',
})
export class ActionsHttpService implements OnDestroy {
  private jsonContentType = 'application/json';
  // host
  private responsabiliHost: string;
  private archivioFiscaleHost: string;
  private documentiHost: string;

  private actionLoading$ = new BehaviorSubject<boolean>(false);

  private downloadPdfUrl: string;
  private orchestratorHost: string;
  private downloadExcelUrl: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private transferService: TransferService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  httpHostInit(appConfig: AppConfig) {
    this.responsabiliHost = appConfig.webConsOrchestrator.http.host;
    this.documentiHost = appConfig.gawConsDocumentiRead.http.host;
    this.archivioFiscaleHost = appConfig.gawConsArchivioFiscaleRead.http.host;
    this.orchestratorHost = appConfig.webConsOrchestrator.http.host;

    this.downloadPdfUrl = `${this.responsabiliHost}/v1/Responsabili/scaricaPdf/{idUtenteRdc}`;
    this.downloadExcelUrl = `${this.orchestratorHost}/v1/ArchivioFiscale/documenti/esporta?TipoDocumento={extension}&IdLicenza={licenseId}&CodSia={siaCode}&AnnoFiscale={fiscalYear}&IdRegistro={registryId}`;
  }

  public whenLoadingAction(): Observable<boolean> {
    return this.actionLoading$.asObservable();
  }

  public setLoadingAction(value: boolean) {
    this.actionLoading$.next(value);
  }

  whenSinglePdf(idUtenteRdc: string): Observable<Transfer> {
    const url = this.downloadPdfUrl.replace('{idUtenteRdc}', idUtenteRdc);
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.get(url, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  whenExcelAll(actionUrl: string, body: unknown, extension: string): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    // TODO da inserire url corretto
    const url = this.orchestratorHost + actionUrl.replace('{format}', extension);
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  whenPdfZip(actionUrl: string, data: IGawconsDetailsComponentInterface, download = true) {
    let url =
      this.orchestratorHost +
      actionUrl
        .replace('{licenseId}', data.licenseId)
        .replace('{siaCode}', data.siaCode)
        .replace('{idDoc}', (data.documentId || data.elasticId).split('_')[0])
        .replace('{fiscalYear}', data.keys['annoFiscale'])
        .replace('{registryId}', data.registryId)
        .replace('{download}', `${download}`);

    if (data.keys['idVersione']) url = url.concat(`&IdVersion=${data.keys['idVersione']}`);

    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.get(url, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  whenExcelSingleMultiple(registryId, licenseId, siaCode, annoFiscale, documentId, extension) {
    const url = this.downloadExcelUrl
      .replace('{registryId}', registryId)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{fiscalYear}', annoFiscale)
      .replace('{extension}', extension);

    const body = { idsDocumenti: documentId };
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap(() =>
        this.http
          .post(url, body, {
            observe: 'response',
            responseType: 'blob',
            headers: new HttpHeaders({ log: 'true' }),
          })
          .pipe(transferHandler(this.transferService)),
      ),
    );
  }
}
