<div class="wrapper-main">
  <ctel-sidebar-container style="min-height: 100vh" [animate]="true">
    <!-- COLLAPSABLE SIDE BAR -->
    <ctel-sidebar *ngIf="showSidebar" mode="push" [(opened)]="isOpen" [autoFocus]="false" [animate]="true">
      <gaw-sidebar></gaw-sidebar>
    </ctel-sidebar>

    <!-- MAIN PAGE CONTENT WRAPPER -->
    <div ctel-sidebar-content #inner id="sidebar-content" class="h-100">
      <dry-progress-bar></dry-progress-bar>
      <div id="page-wrapper" class="h-100 container-fluid px-0">
        <div [ngClass]="route.includes('home') ? 'full-home-header' : 'fixed-margin'"></div>
        <!-- PANNELLO DI RICERCA VISIBILE SOLO NELLA HOME -->
        <!-- MAIN / ROUTES WRAPPER-->
        <router-outlet></router-outlet>
      </div>
    </div>
  </ctel-sidebar-container>
</div>
