import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdvancedTextSearchService } from '@ctel/gaw-commons';
import {
  AbstractFilterEffects,
  DocumentActions,
  FilterAction,
  FilterActions,
  FilterState,
  IAdvancedTextSearchUrl,
  IFilterStatus,
  IFilterUrl,
  IFullTextSearchUrl,
  IOrderByUrl,
  IOuterFilterUrl,
  IPagingUrl,
  IRouterStateUrl,
  getRouterInfo,
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { ErrorTypes } from 'app/core/common/error';
import { EMPTY, combineLatest } from 'rxjs';
import { catchError, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { UiConfigurationService } from '../../../ui-configuration/ui-configuration.service';
import { DocumentsHttpService } from '../../documents-http.service';
import { DocumentsService2 } from '../../documents/documents.service';
import {
  getRelatedSectionData,
  relatedSearchDataFetchedGAWEDI,
  sectionColumnsFetchedGAWEDI,
} from '../../documents/store/document.extended';
import { FilterService2 } from '../../filters/filter.service';
import * as ExtendedFilterActions from './filter.actions';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;

@Injectable({
  providedIn: 'root',
})
export class FilterEffects extends AbstractFilterEffects {
  /**
   * Effetto che gestisce il side effect di aver fetchato i filtri preferiti,
   * e per i quali è necessario recuperare nuovi documenti.
   */
  onFavoriteFiltersFetched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favoriteFiltersFetched),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawedi/') !== -1),
      switchMap(() => [DocumentActions.fetchDocuments(true), DocumentActions.fetchDocumentsActions()]),
    ),
  );

  onRelatedSearchDataRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedFilterActions.relatedSearchDataRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      switchMap(([action, routerInfo]) =>
        this.uiConfigurationService
          .getRelatedData(
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            routerInfo.state.params['doctype'],
          )
          .pipe(
            switchMap((relatedSectionData) => {
              const newFilterStatus: IFilterStatus = this.documentsService.buildFilterPayload(
                routerInfo.state.params['license'],
                routerInfo.state.params['siacode'],
                routerInfo.state.params['doctype'],
                action.payload.filterResponse,
              );
              return [
                relatedSearchDataFetchedGAWEDI(relatedSectionData),
                sectionColumnsFetchedGAWEDI(action.payload.primaryConfig, action.payload.secondaryConfig),
                FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus }),
              ];
            }),
            catchError((error: unknown) => this.handleError(error)),
          ),
      ),
    ),
  );

  onSectionColumnsRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtendedFilterActions.sectionColumnsRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      switchMap(([action, routerInfo]) =>
        this.uiConfigurationService
          .getSectionColumns(
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
            routerInfo.state.params['doctype'],
          )
          .pipe(
            switchMap((sectionColumns) => [
              ExtendedFilterActions.relatedSearchDataRequested(
                action.payload.filterResponse,
                sectionColumns.primaryConfig,
                sectionColumns.secondaryConfig,
              ),
            ]),
            catchError((error: unknown) => {
              if (error['type'] === ErrorTypes.HTTP_NOT_FOUND)
                return [ExtendedFilterActions.relatedSearchDataRequested(action.payload.filterResponse, [], [])];

              return EMPTY;
            }),
          ),
      ),
    ),
  );

  onAdvancedTextSearchMetadataRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.advancedTextSearchMetadataRequested),
      switchMap(() =>
        combineLatest([this.store.pipe(select(getRouterInfo)), this.store.pipe(select(getRelatedSectionData))]),
      ),
      filter(([routerInfo]) => routerInfo.state.url.indexOf('gawedi/documents') !== -1),
      switchMap(([, relatedSectionData]) =>
        this.documentsService.whenMetadataDescriptions(relatedSectionData).pipe(
          map((docSeriesMetadataDesc) => {
            const sorted = this.advancedTextSearchService.sortTextSearchMetadata(
              docSeriesMetadataDesc,
              relatedSectionData,
            );
            return FilterActions.advancedTextSearchMetadataFetched({ advancedTextSearch: sorted });
          }),
          catchError((error: unknown) => this.handleError(error)),
        ),
      ),
    ),
  );

  /**
   * Effetto che gestisce il side effect della richiesta dei filtri preferiti,
   * che è necessario recuperare.
   */
  onFavouritesFiltersRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favouritesFiltersRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gawedi/') !== -1),
      switchMap(([, routerInfo]) =>
        this.documentsHttpService
          .whenFavoriteFilters(
            routerInfo.state.params['doctype'],
            routerInfo.state.params['license'],
            routerInfo.state.params['siacode'],
          )
          .pipe(
            take(1),
            map((filtersResponse) => {
              this.documentsService.setErrorLoadingDocs(false);
              return ExtendedFilterActions.sectionColumnsRequested(filtersResponse);
              // return FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus });
            }),
            catchError((error: unknown) => {
              this.documentsService.setErrorLoadingDocs(true);
              return this.handleError(error);
            }),
          ),
      ),
    ),
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<FilterState>,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private documentsHttpService: DocumentsHttpService,
    private documentsService: DocumentsService2,
    protected filterService: FilterService2,
    private uiConfigurationService: UiConfigurationService,
    protected advancedTextSearchService: AdvancedTextSearchService,
    protected ngZone: NgZone,
  ) {
    super(actions$, store, filterService, router, activatedRoute, ngZone);
  }

  protected doOnChangeFilterRequested(
    action: FilterAction,
    routerInfo: { state: IRouterStateUrl; navigationId: number },
    filtersUrl: IFilterUrl[],
    outerFiltersUrl: IOuterFilterUrl[],
    pagingFiltersUrl: IPagingUrl,
    orderByFiltersUrl: IOrderByUrl[],
    fullTextSearchUrl: IFullTextSearchUrl,
    advancedTextSearchUrl: IAdvancedTextSearchUrl[],
  ): void {
    if (routerInfo.state.url.indexOf('gawedi/') === -1) return;

    if (action.kind === ChangeFilterRequestedKind.PagingChanged) this.documentsService.setLoadingDocsOnPaging(true);
    else {
      this.documentsService.setLoadingDocsAfterFilterApplication(true);
      this.documentsService.setLoadingDocsOnPaging(false);
    }
    super.doOnChangeFilterRequested(
      action,
      routerInfo,
      filtersUrl,
      outerFiltersUrl,
      pagingFiltersUrl,
      orderByFiltersUrl,
      fullTextSearchUrl,
      advancedTextSearchUrl,
    );
  }

  protected doNavigationChangedOnDocTypeParamsChanged() {
    this.documentsService.setLoadingDocs(true);
    return super.doNavigationChangedOnDocTypeParamsChanged();
  }

  protected getDocumentDetailUrl(): string {
    return '/details/';
  }

  protected getDocumentsListUrl(): string {
    return '/gawedi/documents/types/';
  }

  protected getUrlCommands(routerInfo: { state: IRouterStateUrl; navigationId: number }): string[] {
    const urlCommands: string[] = [];
    urlCommands.push(this.getDocumentsListUrl());
    urlCommands.push(routerInfo.state.params['license']);
    urlCommands.push(routerInfo.state.params['siacode']);
    urlCommands.push(routerInfo.state.params['section']);
    return urlCommands;
  }

  protected getParamsDistinctCriteria(prev: Params, succ: Params): boolean {
    if (this.getDocumentsListUrl().indexOf(this.router.url) !== -1) return false;

    return super.getParamsDistinctCriteria(prev, succ);
  }
}
