import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@ctel/auth';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EventService } from 'app/core/common/event/event.service';
import { PostMessagePayload } from 'app/entities/post-message-payload';
import { Observable, ReplaySubject, Subject, combineLatest } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BeFramePayload } from '../../../entities/action/be-frame-payload';
import { WorkflowAndItemInfo } from '../../../entities/work-item/work-item';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowEvent } from '../../../workflow-event';

@Component({
  selector: 'gaw-workitem-full-frame',
  templateUrl: './workitem-full-frame.component.html',
  styleUrls: ['./workitem-full-frame.component.scss'],
})
export class WorkitemFullFrameComponent implements OnDestroy {
  @Output() closeModal = new EventEmitter<boolean>();

  token$: Observable<string>;
  link$: Observable<SafeResourceUrl>;

  private frame$ = new ReplaySubject(1);
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private workflowService: WorkflowService,
    private sanitizer: DomSanitizer,
    private eventService: EventService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.token$ = this.authService.whenToken();

    this.link$ = combineLatest([
      this.workflowService.whenSelectedWorkflow(),
      this.workflowService.selectedWorkItem$,
    ]).pipe(
      filter(([wf, wi]) => wi !== undefined && wf !== undefined),
      tap(() => {
        this.detachDetectorChanges();
      }),
      map(
        ([currentWF, workItem]) =>
          <WorkflowAndItemInfo>{
            workflowId: currentWF.id,
            workflowName: workItem.workflowName,
            workitemId: workItem.id,
            workItemStage: workItem.stageName,
          },
      ),
      switchMap((workflowAndItemInfo) => this.workflowService.retrievePreviewFormLink(workflowAndItemInfo)),
      map((val) => this.sanitizer.bypassSecurityTrustResourceUrl(val)),
      tap(() => {
        this.reattachDetectorChanges();
      }),
    );

    this.frame$
      .pipe(
        tap((frame: HTMLIFrameElement) => (frame.onload = () => this.sendHostMessage(frame))),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  @ViewChild('fullFrameForm', { static: false }) set form(form: ElementRef) {
    if (form) form.nativeElement.submit();
  }

  @ViewChild('fullFrame', { static: false }) set frame(frame: ElementRef) {
    if (frame) this.frame$.next(frame.nativeElement);
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: PostMessagePayload<BeFramePayload>) {
    if (event.origin === this.workflowService.getBEHost())
      switch (event.data.action) {
        case 'abort':
        case 'failed':
        case 'success':
          this.handleMessage();
          break;
        default:
          throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
            .description('Errore durante la lettura del payload da BE')
            .info('value', event.data.action)
            .build();
      }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private sendHostMessage(frame) {
    const beflowUrl = this.workflowService.getBEHost();
    const receiver = frame.contentWindow;
    receiver.postMessage(window.location.origin, beflowUrl);
  }

  private handleMessage() {
    this.eventService.emit(WorkflowEvent.E_ACTION_COMPLETED);
    // Notifico alla modal di chiudersi
    this.closeModal.emit(true);
  }

  private detachDetectorChanges() {
    if (this.cdr) this.cdr.detach();
  }

  private reattachDetectorChanges() {
    if (this.cdr) {
      this.cdr.detectChanges();
      this.cdr.reattach();
    }
  }
}
