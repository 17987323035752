import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject } from 'rxjs';
import { StatusHistory } from '@ctel/gaw-commons';

@Injectable({
  providedIn: 'root',
})
export class StatusHistoryHttpService implements OnDestroy {
  private destroy$ = new ReplaySubject<void>(1);

  private gawpostHost!: string;
  private statusHistoryUrl!: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.gawpostHost = appConfig.gawPostRead.http.host;

    this.statusHistoryUrl = `${this.gawpostHost}/api/v1/Action/viewstatushistory?progSpool={progSpool}&progBusta={progBusta}&idComunicazione={idComunicazione}`;
  }

  whenStatusHistory(progSpool: string, progBusta: string, idComunicazione: string): Observable<StatusHistory[]> {
    const url = this.statusHistoryUrl
      .replace('{idComunicazione}', idComunicazione)
      .replace('{progSpool}', progSpool)
      .replace('{progBusta}', progBusta);
    return this.http.get<StatusHistory[]>(url);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
