import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class MultiActionsCatalog {
  public readonly cancelMultiple = new ActionBuilder(MultiActionCode.CancelMultiple, ActionCode.CANCEL)
    .setActionURL('/v1/FirmaRemota/documenti/cancella')
    .build();

  public readonly confirmMultiple = new ActionBuilder(MultiActionCode.ConfirmMultiple, ActionCode.CONFIRM)
    .setActionURL('')
    .build();
}

export enum MultiActionCode {
  CancelMultiple = 'cancelMultiple',
  ConfirmMultiple = 'confirmMultiple',
}
