import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';
import { Pair } from '../utilities/dictionary/pair';
import { AppErrorBuilder } from './app-error-builder';
import { ErrorType } from './error-type';

export class AppError extends Error {
  public readonly incidentReportId: string;
  public readonly type: ErrorType;
  public readonly errorDate: dayjs.Dayjs;
  public readonly cause: Error;
  public readonly description: string;
  public readonly hasCause: boolean;
  public readonly additionalInfo: Array<Pair<string, any>>;

  constructor(builder: AppErrorBuilder) {
    // Se c'è un errore causa, imposto il messaggio della classe padre con il messaggio di errore della causa
    let message = '';
    if (builder.hasCause()) {
      const errorCause = builder.getErrorCause();
      message = errorCause.message;
    }
    super(message);

    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, AppError.prototype);

    this.incidentReportId = uuid();
    this.errorDate = dayjs();
    this.type = builder.getType();
    this.hasCause = builder.hasCause();
    if (builder.hasCause()) this.cause = builder.getErrorCause();

    this.description = builder.getErrorDescription();
    this.additionalInfo = builder.getAdditionalInfo();
  }

  private static getErrorStack(appError: AppError): AppError[] {
    if (appError.hasCause && appError.cause instanceof AppError)
      return [appError].concat(this.getErrorStack(appError.cause));
    else return [appError];
  }

  public getParentErrors() {
    return AppError.getErrorStack(this);
  }

  toString() {
    return `{
			'incidentReportId': ${this.incidentReportId},
			'errorDate': ${this.errorDate},
			'type': ${this.type},
			'cause': ${this.cause},
			'description': ${this.description},
			'additionalInfo': ${this.additionalInfo}
		}`;
  }
}
