import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { SingleDocumentCallback } from './callbacks/single-document-callback';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly sign = new ActionBuilder(SingleActionsCode.Sign, ActionCode.SIGN).setActionURL('').build();

  public readonly cancel = new ActionBuilder(SingleActionsCode.Cancel, ActionCode.CANCEL).setActionURL('').build();

  public readonly confirm = new ActionBuilder(SingleActionsCode.Confirm, ActionCode.CONFIRM).setActionURL('').build();

  public readonly openDetail = new ActionBuilder(SingleActionsCode.OpenDetail, ActionCode.OPEN_DETAIL).build();

  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  Sign = 'sign',
  Cancel = 'cancel',
  OpenDetail = 'openDetail',
  Confirm = 'confirm',
}
