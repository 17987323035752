<div class="workitem-preview">
  <tabset [justified]="true">
    <tab class="tabs" (selectTab)="routing(this.documentPreviewUrl)">
      <!-- Header Tab -->
      <ng-template tabHeading>
        <div class="tab-header">
          <b>Documento</b>
        </div>
      </ng-template>
      <gaw-wf-doc-preview></gaw-wf-doc-preview>
    </tab>
    <tab class="tabs" (selectTab)="routing(this.documentAttachmentsUrl)">
      <!-- Header Tab -->
      <ng-template tabHeading>
        <div class="tab-header">
          <b class="me-2">Allegati documento</b>
          <span class="ms-2 tab-items-count">{{ (documentAttachmentCount$ | async) || 0 }}</span>
        </div>
      </ng-template>
      <gaw-wf-doc-attachment-list
        *ngIf="!docAttachmentId"
        (goToDetails)="docAttachmentId = $event"
      ></gaw-wf-doc-attachment-list>
      <gaw-wf-doc-attachment-preview
        *ngIf="docAttachmentId"
        [attachmentId]="docAttachmentId"
        (goToList)="docAttachmentId = undefined"
      ></gaw-wf-doc-attachment-preview>
    </tab>
    <tab class="tabs" (selectTab)="routing(this.userAttachmentsUrl)">
      <!-- Header Tab -->
      <ng-template tabHeading>
        <div class="tab-header">
          <b class="me-2">Allegati utente</b>
          <span class="ms-2 tab-items-count">{{ (userAttachmentCount$ | async) || 0 }}</span>
        </div>
      </ng-template>
      <gaw-wf-user-attachment-list
        *ngIf="!userAttachmentId"
        (goToDetails)="userAttachmentId = $event"
      ></gaw-wf-user-attachment-list>
      <gaw-wf-user-attachment-preview
        *ngIf="userAttachmentId"
        [attachmentId]="userAttachmentId"
        (goToList)="userAttachmentId = undefined"
      ></gaw-wf-user-attachment-preview>
    </tab>
    <tab class="tabs" (selectTab)="routing(this.statusHistoryUrl)">
      <!-- Header Tab -->
      <ng-template tabHeading>
        <div class="tab-header">
          <b>Storico stati</b>
        </div>
      </ng-template>
      <gaw-wf-status-history></gaw-wf-status-history>
    </tab>
  </tabset>
</div>
