import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionsService } from '@ctel/hubfe-commons';
import { HistoryOutcomesPayableItem } from 'app/core/common/entities/documents/actions/history-item';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

/**
 * Componente modal per l'azione Visualizza storico esiti del passivo
 * visibile solo con ruolo dedicato (richiesta cliente SNAM)
 */
@Component({
  selector: 'hub-history-outcomes-modal',
  templateUrl: './history-outcomes-payable-modal.component.html',
  styleUrls: ['./history-outcomes-payable-modal.component.scss'],
})
export class HistoryOutcomesPayableModalComponent {
  @Input() modal: BsModalRef;
  public faIcons = FaIcons;
  public history$: Observable<HistoryOutcomesPayableItem[]>;

  constructor(
    private actionsService: ActionsService,
    private location: PlatformLocation,
  ) {
    location.onPopState(() => {
      this.modal.hide();
    });
    this.history$ = this.actionsService.whenHistoryOutcomesPayable();
  }
}
