import { JavaScriptError } from './java-script-error';

export class JavaScriptErrors {
  public static ERROR = new JavaScriptError('Error', 'Non specified JavaScript error.');
  public static EVALERROR = new JavaScriptError('EvalError', 'Error regarding the global eval() function.');
  public static INTERNALERROR = new JavaScriptError('InternalError', 'JavaScript internal engine error.');
  public static RANGEERROR = new JavaScriptError(
    'RangeError',
    'The value is not in the set or range of allowed values.',
  );
  public static REFERENCEERROR = new JavaScriptError('ReferenceError', 'Non-existent variable is referenced.');
  public static SYNTAXERROR = new JavaScriptError('SyntaxError', 'Trying to interpret syntactically invalid code.');
  public static TYPEERROR = new JavaScriptError('TypeError', 'The value is not of the expected type.');
  public static URIERROR = new JavaScriptError('URIError', 'A global URI handling function is used in a wrong way.');
}
