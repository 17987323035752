import { ApiError } from 'app/entities/error/api-error';
import { Pair } from '../utilities/dictionary/pair';
import { AppError } from './app-error';
import { ErrorType } from './error-type';
import { ErrorTypes } from './error-types';

export class AppErrorBuilder {
  private readonly type: ErrorType;
  private errorCause: Error;
  private errorDescription: string;
  private _hasCause = false;
  private errorApiError: ApiError;

  private additionalInfo: Array<Pair<string, any>> = [];

  constructor(type: ErrorType) {
    this.type = type;
  }

  public info(key: string, value: any) {
    this.additionalInfo.push(new Pair(key, value));
    return this;
  }

  public cause(errorCause: Error): AppErrorBuilder {
    if (errorCause === null || errorCause === undefined)
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT).description(
        "La causa dell'errore non è un oggetto di tipo 'Error' valido.",
      );

    this.errorCause = errorCause;
    this._hasCause = true;
    return this;
  }

  public description(errorDescription: string): AppErrorBuilder {
    this.errorDescription = errorDescription;
    return this;
  }

  // TODO: Implementare errori provenienti dalle API, renderli readOnly e farli passare su tutta la gerarchia degli errori
  public apiError(apiError: ApiError): AppErrorBuilder {
    this.errorApiError = apiError;
    return this;
  }

  public build(): AppError {
    return new AppError(this);
  }

  getType(): ErrorType {
    return this.type;
  }

  getErrorCause(): Error {
    return this.errorCause;
  }

  getErrorDescription(): string {
    return this.errorDescription;
  }

  getAdditionalInfo(): Array<Pair<string, any>> {
    return this.additionalInfo;
  }

  hasCause() {
    return this._hasCause;
  }
}
