import { Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';
import { CurrencyHttpService } from './currency-http.service';

@Injectable()
export class CurrencyService {
  constructor(private currencyService: CurrencyHttpService) {}

  public getCyrrency() {
    return this.currencyService.getCurrency();
  }

  public getList(): InvoiceMultiValues[] {
    return Currencies.slice();
  }

  // public addZeroes(num) {
  // 	return num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
  // }

  public checkDecimalLength(numberToCheck: string, maxLength: number): boolean {
    const decimals = numberToCheck.split('.');
    if (!decimals[1] || decimals[1].length <= maxLength) return true;
    else return false;
  }
}

const Currencies = [
  { value: 'aed', label: 'AED' },
  { value: 'afn', label: 'AFN' },
  { value: 'all', label: 'ALL' },
  { value: 'ang', label: 'ANG' },
  { value: 'ars', label: 'ARS' },
  { value: 'aud', label: 'AUD' },
  { value: 'awg', label: 'AWG' },
  { value: 'azn', label: 'AZN' },
  { value: 'bam', label: 'BAM' },
  { value: 'bbd', label: 'BBD' },
  { value: 'bgn', label: 'BGN' },
  { value: 'bmd', label: 'BMD' },
  { value: 'bnd', label: 'BND' },
  { value: 'bob', label: 'BOB' },
  { value: 'brl', label: 'BRL' },
  { value: 'bsd', label: 'BSD' },
  { value: 'bwp', label: 'BWP' },
  { value: 'byr', label: 'BYR' },
  { value: 'bzd', label: 'BZD' },
  { value: 'cad', label: 'CAD' },
  { value: 'chf', label: 'CHF' },
  { value: 'clp', label: 'CLP' },
  { value: 'cny', label: 'CNY' },
  { value: 'cop', label: 'COP' },
  { value: 'crc', label: 'CRC' },
  { value: 'cup', label: 'CUP' },
  { value: 'czk', label: 'CZK' },
  { value: 'dkk', label: 'DKK' },
  { value: 'dop', label: 'DOP' },
  { value: 'eek', label: 'EEK' },
  { value: 'egp', label: 'EGP' },
  { value: 'eur', label: 'EUR' },
  { value: 'fjd', label: 'FJD' },
  { value: 'fkp', label: 'FKP' },
  { value: 'gbp', label: 'GBP' },
  { value: 'ggp', label: 'GGP' },
  { value: 'ghc', label: 'GHC' },
  { value: 'gip', label: 'GIP' },
  { value: 'gtq', label: 'GTQ' },
  { value: 'gyd', label: 'GYD' },
  { value: 'hkd', label: 'HKD' },
  { value: 'hnl', label: 'HNL' },
  { value: 'hrk', label: 'HRK' },
  { value: 'huf', label: 'HUF' },
  { value: 'idr', label: 'IDR' },
  { value: 'ils', label: 'ILS' },
  { value: 'imp', label: 'IMP' },
  { value: 'inr', label: 'INR' },
  { value: 'irr', label: 'IRR' },
  { value: 'isk', label: 'ISK' },
  { value: 'jep', label: 'JEP' },
  { value: 'jmd', label: 'JMD' },
  { value: 'jpy', label: 'JPY' },
  { value: 'kgs', label: 'KGS' },
  { value: 'khr', label: 'KHR' },
  { value: 'kpw', label: 'KPW' },
  { value: 'krw', label: 'KRW' },
  { value: 'kyd', label: 'KYD' },
  { value: 'kzt', label: 'KZT' },
  { value: 'lak', label: 'LAK' },
  { value: 'lbp', label: 'LBP' },
  { value: 'lkr', label: 'LKR' },
  { value: 'lrd', label: 'LRD' },
  { value: 'ltl', label: 'LTL' },
  { value: 'lvl', label: 'LVL' },
  { value: 'mkd', label: 'MKD' },
  { value: 'mnt', label: 'MNT' },
  { value: 'mur', label: 'MUR' },
  { value: 'mxn', label: 'MXN' },
  { value: 'myr', label: 'MYR' },
  { value: 'mzn', label: 'MZN' },
  { value: 'nad', label: 'NAD' },
  { value: 'ngn', label: 'NGN' },
  { value: 'nio', label: 'NIO' },
  { value: 'nok', label: 'NOK' },
  { value: 'npr', label: 'NPR' },
  { value: 'nzd', label: 'NZD' },
  { value: 'omr', label: 'OMR' },
  { value: 'pab', label: 'PAB' },
  { value: 'pen', label: 'PEN' },
  { value: 'php', label: 'PHP' },
  { value: 'pkr', label: 'PKR' },
  { value: 'pln', label: 'PLN' },
  { value: 'pyg', label: 'PYG' },
  { value: 'qar', label: 'QAR' },
  { value: 'ron', label: 'RON' },
  { value: 'rsd', label: 'RSD' },
  { value: 'rub', label: 'RUB' },
  { value: 'sar', label: 'SAR' },
  { value: 'sbd', label: 'SBD' },
  { value: 'scr', label: 'SCR' },
  { value: 'sek', label: 'SEK' },
  { value: 'sgd', label: 'SGD' },
  { value: 'shp', label: 'SHP' },
  { value: 'sos', label: 'SOS' },
  { value: 'srd', label: 'SRD' },
  { value: 'svc', label: 'SVC' },
  { value: 'syp', label: 'SYP' },
  { value: 'thb', label: 'THB' },
  { value: 'trl', label: 'TRL' },
  { value: 'try', label: 'TRY' },
  { value: 'ttd', label: 'TTD' },
  { value: 'tvd', label: 'TVD' },
  { value: 'twd', label: 'TWD' },
  { value: 'uah', label: 'UAH' },
  { value: 'usd', label: 'USD' },
  { value: 'uyu', label: 'UYU' },
  { value: 'uzs', label: 'UZS' },
  { value: 'vef', label: 'VEF' },
  { value: 'vnd', label: 'VND' },
  { value: 'xcd', label: 'XCD' },
  { value: 'yer', label: 'YER' },
  { value: 'zar', label: 'ZAR' },
  { value: 'zwd', label: 'ZWD' },
];

export interface MockCurrency {
  sigla: string;
  label: string;
}
