import { Injectable } from '@angular/core';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly massiveActions: ActionObject[] = [this.massiveActionsCatalog.exportExcelMassive];

  public readonly multiActions: ActionObject[] = [this.multiActionsCatalog.exportExcelMultiple];

  public readonly singleActions: ActionObject[] = [
    this.singleActionsCatalog.visualizeDocument,
    this.singleActionsCatalog.visualizeStatusHistory,
    this.singleActionsCatalog.pdfDownload,
    this.singleActionsCatalog.downloadAttachments,
    this.singleActionsCatalog.visualizeMap, // Visualizza mappa
    this.singleActionsCatalog.searchStatusRegisteredMail, // ricerca stato raccomandata
  ];

  constructor(
    private massiveActionsCatalog: MassiveActionsCatalog,
    private multiActionsCatalog: MultiActionsCatalog,
    private singleActionsCatalog: SingleActionsCatalog,
  ) {}
}
