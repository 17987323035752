<input
  class="form-control form-control-sm"
  [ngClass]="{
    valid: textInputForm.value && textInputForm.valid && required,
  }"
  type="text"
  name="{{ name }}"
  [id]="id"
  value="{{ value }}"
  [placeholder]="placeholder"
  #textInputForm="ngModel"
  [(ngModel)]="value"
  (ngModelChange)="onChangeUpdate()"
  [ngModelOptions]="ngModelOptions"
  #textInputPopover="bs-popover"
  [popover]="InvalidFormMessage"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()"
  triggers=""
  [required]="required"
  [disabled]="disabled"
  maxlength="{{ maxlength }}"
  minlength="{{ minlength }}"
  [composeValidators]="_validators"
  [composeAsyncValidators]="_asyncValidators"
/>
<ng-template #InvalidFormMessage>
  <ul *ngIf="textInputForm.invalid" class="invalid-form-msg">
    <ng-container *ngFor="let error of getErrorMessage(textInputForm)">
      <li>
        {{ error }}
      </li>
    </ng-container>
  </ul>
</ng-template>
