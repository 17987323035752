import { Directive, OnDestroy } from '@angular/core';
import { FilterActions, FilterState } from '@ctel/search-filter-store';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { AdvancedTextSearchService } from '../services/advanced-text-search.service';

/**
 * Logiche base per componenti search bar dei vari servizi che devono implementare una ricerca testuale avanzata.
 */
@Directive()
export class BaseAdvancedSearchBar implements OnDestroy {
  public advancedTextSearchMode = false;
  protected startWithFilterActive = false;
  protected allFilterActive = false;
  protected byFieldsFilterActive = false;
  protected startWithFilterRegexp = /^[^"\r\n ]{3,}[*]$/;
  protected allFilterRegexp = /^"\b[^"]*\b"$/;
  protected advancedTextSearchValue = '';
  protected advancedTextSearchFieldValues = [];
  protected destroy$ = new Subject<void>();

  constructor(
    protected advancedTextSearchService: AdvancedTextSearchService,
    protected store: Store<FilterState>,
  ) {
    this.advancedTextSearchService
      .whenAdvancedTextSearch()
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.advancedTextSearchFieldValues = x;
        const appliedFilters = x.filter((y) => y.value.trim().length > 0);
        this.byFieldsFilterActive = appliedFilters.length > 0;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Used to prevent bubbling/default of clicks in the search bar.
   * @param $event the mouse event
   * @protected
   */
  protected clickWrap($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  /**
   * Handles the base logic around selecting multiple text filters.
   * It needs to be overridden in order to implement custom logic.
   * @param values the values used to filter
   * @protected
   */
  protected onAdvancedSearchedFieldValues(values: { label: string; metadata: string; value: string }[]) {
    this.checkActiveFilters('');
    this.onSearchValuesSubmit(values);
  }

  /**
   * Handles the base logic around selecting a single text filter (starts with or whole words).
   * It needs to be overridden in order to implement custom logic.
   * @param value the current input value
   * @protected
   */
  protected onAdvancedSearchedValue(value: string) {
    this.checkActiveFilters(value);
  }

  /**
   * Handles the base logic around toggling the advanced search mode. It merely flags the internal state and
   * dispatches an advancedTextSearchMetadataRequested action.
   * It needs to be overridden in order to implement custom logic.
   * @param advancedMode true for advanced search, false otherwise
   * @protected
   */
  protected toggleAdvancedSearch(advancedMode: boolean) {
    this.advancedTextSearchMode = advancedMode;
    if (advancedMode) {
      this.advancedTextSearchFieldValues = [];
      this.store.dispatch(FilterActions.advancedTextSearchMetadataRequested());
    }
  }

  /**
   * Handles the base logic around checking the current active filter. This is mostly used to draw the pills
   * "inizia per" and such.
   * It needs to be overridden in order to implement custom logic.
   * @param value the current input value
   * @protected
   */
  protected checkActiveFilters(value: string) {
    this.startWithFilterActive = this.startWithFilterRegexp.test(value);
    this.allFilterActive = this.allFilterRegexp.test(value);
    if (this.startWithFilterActive || this.allFilterActive) this.byFieldsFilterActive = false;
    else {
      const appliedFilters = this.advancedTextSearchFieldValues.filter((y) => y.value.trim().length > 0);
      this.byFieldsFilterActive = !value && appliedFilters.length > 0;
    }
  }

  /**
   * Handles the base logic around building a custom URL command on routing, when applying multiple text filters.
   * It needs to be overridden in order to implement custom logic.
   * @protected
   */
  protected buildUrlCommands(): string[] {
    return [];
  }

  private onSearchValuesSubmit(submitValues: { label: string; metadata: string; value: string }[]) {
    const urlCommands = this.buildUrlCommands();
    this.advancedTextSearchService.sendAdvancedTextSearchValues(submitValues, urlCommands);
  }
}
