import { PlatformLocation } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActionsService } from '@ctel/hubfe-commons';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * Componente modal preview pdf esito
 */
@Component({
  selector: 'hub-outcome-preview-modal',
  templateUrl: './outcome-preview-modal.component.html',
  styleUrls: ['./outcome-preview-modal.component.scss'],
})
export class OutcomePreviewModalComponent implements OnInit {
  @Input() data: any;
  @Input() modal: BsModalRef;

  // public pdfOutcome$: Observable<any>;
  public pdfOutcome: DocumentAttachmentPdf;

  constructor(
    private actionsService: ActionsService,
    private location: PlatformLocation,
  ) {
    location.onPopState(() => this.modal.hide());
  }

  ngOnInit(): void {
    // this.pdfOutcome$ = this.actionsService.whenOutcome(this.data.url);
    this.pdfOutcome = new DocumentAttachmentPdf('PDF ESITO', this.actionsService.whenOutcome(this.data.url));
  }
}
