export enum FileExtension {
  PDF = '.pdf',
  DOCX = '.docx',
  EML = '.eml',
  JPG = '.jpg',
  JPEG = '.jpeg',
  ODB = '.odb',
  ODG = '.odg',
  ODS = '.ods',
  ODP = '.odp',
  PPTX = '.pptx',
  TIF = '.tif',
  TIFF = '.tiff',
  TXT = '.txt',
  XLSX = '.xlsx',
  XML = '.xml',
  P7M = '.p7m',
  CADES = '.cades',
}
