import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/entities/config/app-config';
import { switchMap, take } from 'rxjs';
import { UserService } from '@ctel/auth';
import { ConfigService } from '../config/config.service';
import { CustomHttpOptions } from '../utilities/custom-http-options';

@Injectable()
export class LoggerHttpService {
  private readonly jsonContentType = 'application/json';

  private logUrl: string;
  private jsonOptions: object;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private userService: UserService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => {
      this.logUrl = `${appConfig.loggerApi.http.host}/Logger/log`;
    });
    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
  }

  log(request: HttpRequest<unknown>) {
    this.userService
      .getUser()
      .pipe(
        take(1),
        switchMap((user) => {
          const body = {
            userId: user.id,
            url: request.urlWithParams,
            jsonPayload: JSON.stringify(request.body),
          };
          return this.http.post(this.logUrl, body, this.jsonOptions);
        }),
      )
      .subscribe();
  }

  log2(url: string, request: any) {
    this.userService
      .getUser()
      .pipe(
        take(1),
        switchMap((user) => {
          request.username = user.nickName;
          const body = {
            userId: user.id,
            url: url,
            jsonPayload: JSON.stringify(request),
          };
          return this.http.post(this.logUrl, body, this.jsonOptions);
        }),
      )
      .subscribe();
  }
}
