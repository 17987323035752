import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { ConfigService } from 'app/core/common/config/config.service';
import { Navigation } from '../../../entities/navigation/navigation';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative alla sidebar
 */
@Injectable()
export class NavigationHttpService {
  private jsonContentType = 'application/json';

  private navigation: string;
  private profileHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS UI CONFIGURATION
		 ---------------------------------------------------------------------------------------------------------- */
    this.profileHost = appConfig.profileRead.http.host;
    this.navigation = `${this.profileHost}/v1/profili/navigation/{licenseId}/{siaCode}`;
  }

  // ELEMENTI SIDEBAR
  whenNavigation(licenseId: string, siaCode: string): Observable<Navigation[]> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.navigation.replace('{licenseId}', licenseId).replace('{siaCode}', siaCode);
    return this.http.get<Navigation[]>(url, options);
  }
}
