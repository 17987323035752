import { PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from '@ctel/block-ui';
import { SpoolCardsService } from '@ctel/hubfe-commons';
import { Spool } from 'app/core/common/entities/spool/spool-data';
import { SpoolType } from 'app/core/common/modals/spool-modal/spool-type.enum';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { LargePlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/templates/spinner/large-placeholder/large-placeholder.component';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { Pagination } from 'app/shared/components/pagination/pagination';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EMPTY, Observable, ReplaySubject, Subject, combineLatest, iif } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, takeUntil, tap } from 'rxjs/operators';

/**
 * Componente modal con griglia lotti in elaborazione / in errore
 */
@Component({
  selector: 'hub-spool-modal',
  templateUrl: './spool-modal.component.html',
  styleUrls: ['./spool-modal.component.scss'],
})
export class SpoolModalComponent implements OnInit, OnDestroy {
  @BlockUI('spool-modal-loader') spoolModalLoader: NgBlockUI;

  @Input() title: string;
  @Input() data: {
    spoolType: SpoolType;
    spoolCount: any;
  };
  @Input() modal: BsModalRef;

  public faIcons = FaIcons;
  spoolList$: Observable<Spool[]>;
  spoolCount$: Observable<number>;
  isSpoolErrorModal$: Observable<boolean>;
  isSpoolElabModal$: Observable<boolean>;

  displayedItemLabel$: Observable<string>;

  public inputData$: ReplaySubject<any> = new ReplaySubject(1);
  blockTemplate = LargePlaceholderComponent;

  private destroy$ = new Subject<void>();

  constructor(
    private spoolCardsService: SpoolCardsService,
    private notificationService: NotificationService,
    private location: PlatformLocation,
    private cdr: ChangeDetectorRef,
  ) {
    location.onPopState(() => this.modal.hide());
    // Booleano che indica se sono spool in errore
    this.isSpoolErrorModal$ = this.whenInputData().pipe(
      map((data) => data.spoolType === SpoolType.SPOOL_ERROR),
      takeUntil(this.destroy$),
    );

    // Booleano che indica se sono spool in attesa di elaborazione
    this.isSpoolElabModal$ = this.whenInputData().pipe(
      map((data) => data.spoolType === SpoolType.SPOOL_ELAB),
      takeUntil(this.destroy$),
    );

    // Lista degli spool
    this.spoolList$ = this.whenInputData().pipe(
      switchMap((data) =>
        iif(
          () => data.spoolType === SpoolType.SPOOL_ELAB,
          this.spoolCardsService.spoolProcessingList$,
          this.spoolCardsService.spoolErrorList$,
        ),
      ),
      takeUntil(this.destroy$),
      share({ connector: () => new ReplaySubject<Spool[]>(1) }),
    );

    this.displayedItemLabel$ = this.getDisplayedItem().pipe(
      tap({
        next: () => this.stopSpoolModalLoader(),
        error: () => this.stopSpoolModalLoader(),
        complete: () => this.stopSpoolModalLoader(),
      }),
    );

    // Totale degli spool
    this.spoolCount$ = this.whenInputData().pipe(
      switchMap((data) =>
        iif(
          () => data.spoolType === SpoolType.SPOOL_ELAB,
          this.spoolCardsService.spoolProcessingCount$,
          this.spoolCardsService.spoolErrorCount$,
        ),
      ),
      takeUntil(this.destroy$),
    );
  }

  ngOnInit(): void {
    this.sendInputData(this.data);
    this.startSpoolModalLoader();
    this.spoolCardsService
      .whenSpoolLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        if (val && this.cdr) this.cdr.detach();
        else {
          this.cdr.detectChanges();
          this.cdr.reattach();
        }
        this.stopSpoolModalLoader();
      });
  }

  downloadSpool(spool: Spool) {
    const errorDownloadSpool$ = () => {
      this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download dello spool', '');
      return EMPTY;
    };

    this.spoolCardsService
      .downloadSpool(spool.progspool.value)
      .pipe(take(1), catchError(errorDownloadSpool$), takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result !== null) {
          const blob = new Blob([result], { type: 'application/zip' });
          new FileSaver(blob).saveAs(spool.progspool.value, FileSaverExtension.ZIP);
        } else
          this.notificationService.showSweetAlert(
            NotificationType.ERROR,
            'Uno o più documenti non sono disponibili',
            "L'archivio non verrà scaricato",
          );
      });
  }

  cancelSpool(spool: Spool) {
    const error$ = () => {
      this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore durante l'annullamento dello spool", '');
      return EMPTY;
    };

    const cancelSpool$ = this.spoolCardsService.cancelSpool(spool.progspool.value).pipe(
      take(1),
      catchError(error$),
      // Aggiorno solo gli spool in errore
      tap(() => {
        this.spoolCardsService.refreshErrorSpool();
        this.stopSpoolModalLoader();
      }),
    );

    this.isSpoolErrorModal$
      .pipe(
        take(1),
        filter((isErrorSpool) => isErrorSpool === true),
      )
      .subscribe(() => {
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Il flusso verrà annullato e non verrà elaborato da Credemtel',
          '',
          // eslint-disable-next-line rxjs/no-nested-subscribe
          () => cancelSpool$.pipe(take(1)).subscribe(),
        );
      });
  }

  pageChanged(event: Pagination): void {
    this.startSpoolModalLoader();
    this.spoolCardsService.setPageIndex(event.actualPage);
  }

  ngOnDestroy(): void {
    this.spoolCardsService.setPageIndex(1);
    this.inputData$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private sendInputData(data: any) {
    this.inputData$.next(data);
  }

  private whenInputData(): Observable<any> {
    return this.inputData$.asObservable();
  }

  private getDisplayedItem(): Observable<string> {
    const actualPage$ = this.spoolCardsService.whenPageIndex();
    const pageDimension$ = this.spoolCardsService.whenPageDimension();

    return combineLatest([actualPage$, pageDimension$, this.spoolList$]).pipe(
      map(([actualPage, pageDimensione, spoolList]) => {
        const firstElement = actualPage * pageDimensione - pageDimensione;
        const lastElement = firstElement + spoolList.length;

        return `Da ${firstElement + 1} a ${lastElement}`;
      }),
      takeUntil(this.destroy$),
    );
  }

  private startSpoolModalLoader() {
    this.spoolModalLoader.start();
  }

  private stopSpoolModalLoader() {
    if (this.spoolModalLoader.isActive) this.spoolModalLoader.stop();
  }
}
