import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup, isFormGroup } from '@angular/forms';

@Pipe({
  name: 'asFormGroup',
})
export class AsFormGroupPipe implements PipeTransform {
  transform(control: AbstractControl): FormGroup | undefined {
    if (isFormGroup(control)) return control;
    return undefined;
  }
}
