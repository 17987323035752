import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { ConfigurationsGroup } from 'app/entities/ui-config/action/actions-configuration';

// const DownloadZip: ConfigurationsGroup = {
// 	code: 'downloadZip',
// 	name: 'Scarica PDF',
// 	icon: `${FaIcons.FAR_ZIP}`,
// 	highlighted: false,
// 	order: 2,
// 	services: []
// };

// const DownloadMergedPdf: ConfigurationsGroup = {
// 	code: 'downloadMergedPdf',
// 	name: 'Scarica PDF unico',
// 	icon: `${FaIcons.FAR_PDF}`,
// 	highlighted: false,
// 	order: 1,
// 	services: []
// };

const ExportExcel: ConfigurationsGroup = {
  code: 'exportExcel',
  name: 'Esporta excel',
  icon: `${FaIcons.FAR_EXCEL}`,
  highlighted: false,
  order: 3,
  services: [],
};

const ViewDocument: ConfigurationsGroup = {
  code: 'viewDocument',
  name: 'Visualizza',
  icon: `${FaIcons.FAR_EYE}`,
  highlighted: false,
  order: 1,
  services: [],
};

const PdfDownload: ConfigurationsGroup = {
  code: 'pdfDownload',
  name: 'Scarica PDF',
  icon: `${FaIcons.FAR_PDF}`,
  highlighted: false,
  order: 2,
  services: [],
};

export const SingleActionsConfigurationsGroup: ConfigurationsGroup[] = [ViewDocument, PdfDownload];
export const MultipleActionsConfigurationsGroup: ConfigurationsGroup[] = [ExportExcel];
export const MassiveActionsConfigurationsGroup: ConfigurationsGroup[] = [ExportExcel];
