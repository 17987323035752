import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Display, DocSeriesColor, Metadata } from '@ctel/gaw-commons';
import {
  ActionsService2,
  ArchivioFiscaleService,
  DocumentDetailsService,
  DocumentsSeriesService,
  ExecuteActionsService,
  IGawconsDetailsComponentInterface,
  SingleActionsCode,
} from '@ctel/gawcons-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { SectionEnum } from 'app/entities/anomalies/section-enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Details, DetailsMetadata } from 'app/modules/details/entities/details';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'gaw-archivio-fiscale-detail',
  templateUrl: './archivio-fiscale-detail.component.html',
  styleUrls: ['./archivio-fiscale-detail.component.scss'],
})
export class ArchivioFiscaleDetailComponent implements OnInit, OnDestroy {
  @Input() data: IGawconsDetailsComponentInterface;

  public singleActionsList$: Observable<ActionObject[]> = this.actionsService.whenSingleActionsList();
  public singleActionsList: ActionObject[];
  public actionLoadingError$: Observable<boolean>;
  public documentDetails$: Observable<Details>;
  public singleActionsCode = SingleActionsCode;
  public metadataEnum = MetadataEnum;
  public actionCode = ActionCode;
  public documentSeriesColors$: Observable<DocSeriesColor[]>;
  public downloadAction: ActionObject;
  public docClass: string;
  private documentId: string;
  private licenseId: string;
  private siaCode: string;
  private fiscalYear: string;
  private registryId: string;

  constructor(
    private executeActionsService: ExecuteActionsService,
    private actionsService: ActionsService2,
    private documentDetailsService: DocumentDetailsService,
    private documentSeriesService: DocumentsSeriesService,
    private route: ActivatedRoute,
    private archivioFiscaleService: ArchivioFiscaleService,
    private router: Router,
  ) {}

  getDocLabel(metadataList: Metadata[] | DetailsMetadata[]) {
    const docLabel = metadataList?.find((m) => m.keyCode === this.metadataEnum.DOCLABEL);
    return docLabel && docLabel.value ? docLabel.value : 'Doc Label sconosciuta';
  }

  ngOnInit() {
    this.route.params
      .pipe(
        tap((params) => {
          this.registryId = params.registryId;
          this.documentId = params.elasticid;
          this.siaCode = params.siacode;
          this.fiscalYear = params.fiscalYear;
          this.licenseId = params.license;
        }),
        switchMap(
          () =>
            (this.documentDetails$ = this.archivioFiscaleService.whenMetadati(
              this.documentId,
              this.licenseId,
              this.siaCode,
              this.fiscalYear,
              this.registryId,
            )),
        ),
        switchMap((details) =>
          this.actionsService.requestSingleActionsConfig(this.licenseId, this.siaCode, details.keys).pipe(take(1)),
        ),
      )
      .subscribe();
    this.singleActionsList$.subscribe(
      (value) =>
        (this.downloadAction = value?.find((value1) => value1.actionCode === ActionCode.DOWNLOAD_PRESERVED_DOCUMENT)),
    );
  }

  ngOnDestroy(): void {
    this.actionsService.reset();
  }

  // le azioni nel dettaglio coincidono con le azioni singole
  executeAction(action: ActionObject, keys: object) {
    // per il workflow salvo l'id serie doc reale (in caso venissi dalla all)
    const keysArray = Object.entries(keys).map(([key, value]) => ({ key, value }));
    for (const item of keysArray) {
      if (item.key === 'ctelDocSeriesId') {
        this.documentDetailsService.sendRealDocSeriesId('' + item.value);
      }
    }

    this.data.keys.annoFiscale = this.fiscalYear;
    this.data.registryId = this.registryId;
    this.data.documentId = this.documentId;
    this.data.keys.documentId = this.documentId;
    this.data.keys.idVersione = +this.documentId.split('_')[1];
    this.executeActionsService.executeSingleAction(action, this.data);
  }

  titleRender(docsDisplay: Display[]): Display {
    const index = this.titleDisplayIndex(docsDisplay);
    return docsDisplay[index];
  }

  titleDisplayIndex(docsDisplay: Display[]): number {
    return docsDisplay.findIndex((el) => el.metadata === 'docLabel.keyword');
  }

  getColor(descTipoDocumento: string) {
    return this.documentSeriesService.getColor(descTipoDocumento);
  }
}
