import { Injectable } from '@angular/core';
import { DocumentKey } from '@ctel/gaw-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { TopProgressBarService } from 'app/core/common/spinner/top-progress-bar/top-progress-bar.service';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { EMPTY, Observable } from 'rxjs';
import { catchError, delay, switchMap, take } from 'rxjs/operators';
import { CheckboxService } from '../checkbox/checkbox.service';
import { DocumentsService } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class ExecuteActionsService {
  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private companiesService: CompaniesService,
    private documentsService: DocumentsService,
    private checkboxService: CheckboxService,
    private progressBarService: TopProgressBarService,
  ) {}

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  // ATTENZIONE !! REFACTOR di Keys in Doc!
  // Ora si può leggere altre info relative al documento interessato (ad esempio la serie documentale)
  public executeSingleAction(action: ActionObject | ActionConfigItem, doc: unknown, onCompleted?: { (): unknown }) {
    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    switch (actionCode) {
      case ActionCode.RESTORE_FROM_TRASH:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Ripristina Documento',
          'Vuoi ripristinare il documento?',
          () => {
            this.progressBarService.pushRequest();
            this.companiesService
              .whenCurrentCompany()
              .pipe(
                switchMap((company) =>
                  this.actionsHttpService.postToDocumenti(actionUrl, {
                    licenseId: company.licenseId,
                    siaCode: company.siaCode,
                    keys: [
                      {
                        progSpool: doc['keys'].progSpool,
                        progBusta: doc['keys'].progBusta,
                        docSeriesId: doc['keys'].ctelDocSeriesId,
                        elasticDocumentId: doc['keys'].ctelElasticDocumentId,
                      },
                    ],
                  }),
                ),
                take(1),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Documento non ripristinato',
                    'Si sono verificati dei problemi e non è stato possibile ripristinare il documento',
                  );
                  return EMPTY;
                }),
                delay(1000),
              )
              .subscribe(() => {
                this.progressBarService.popRequest();
                this.documentsService.refreshDocuments();
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Documento Ripristinato',
                  `Il documento è stato correttamente ripristinato. \nOra è disponibile nella serie documentale "${doc['docSeriesDescription']}"`,
                  () => {
                    if (onCompleted) onCompleted();
                  },
                );
              });
          },
        );
        break;
      case ActionCode.DELETE_FROM_TRASH:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Elimina Definitivamente il Documento',
          'Vuoi eliminare definitivamente il documento?<br><b>Una volta eseguita l’operazione, il documento non sarà più recuperabile.</b>',
          () => {
            this.progressBarService.pushRequest();
            this.companiesService
              .whenCurrentCompany()
              .pipe(
                switchMap((company) =>
                  this.actionsHttpService.deleteToDocumenti(actionUrl, {
                    licenseId: company.licenseId,
                    siaCode: company.siaCode,
                    keys: [
                      {
                        progSpool: doc['keys'].progSpool,
                        progBusta: doc['keys'].progBusta,
                        docSeriesId: doc['keys'].ctelDocSeriesId,
                        elasticDocumentId: doc['keys'].ctelElasticDocumentId,
                      },
                    ],
                  }),
                ),
                take(1),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Documento non eliminato',
                    'Si sono verificati dei problemi e non è stato possibile eliminare il documento',
                  );
                  return EMPTY;
                }),
                delay(1000),
              )
              .subscribe(() => {
                this.progressBarService.popRequest();
                this.documentsService.refreshDocuments();
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Documento Eliminato Definitivamente',
                  'Il documento è stato correttamente eliminato.',
                  () => {
                    if (onCompleted) onCompleted();
                  },
                );
              });
          },
        );
        break;
      default:
        break;
    }
  }

  // ESECUZIONE AZIONI MULTIPLE
  public executeMultiAction(action: ActionObject | ActionConfigItem, keys: DocumentKey[]) {
    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    switch (actionCode) {
      case ActionCode.RESTORE_FROM_TRASH:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Ripristina Documenti',
          'Vuoi ripristinare i documenti selezionati?',
          () => {
            this.progressBarService.pushRequest();
            this.companiesService
              .whenCurrentCompany()
              .pipe(
                switchMap((company) =>
                  this.actionsHttpService.postToDocumenti(actionUrl, {
                    licenseId: company.licenseId,
                    siaCode: company.siaCode,
                    keys: keys.map((doc) => ({
                      progSpool: doc.progSpool,
                      progBusta: doc.progBusta,
                      docSeriesId: doc.ctelDocSeriesId,
                      elasticDocumentId: doc.ctelElasticDocumentId,
                    })),
                  }),
                ),
                take(1),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Documenti non ripristinati',
                    'Si sono verificati dei problemi e non è stato possibile ripristinare i documenti.',
                  );
                  return EMPTY;
                }),
                delay(1000),
              )
              .subscribe(() => {
                this.progressBarService.popRequest();
                this.documentsService.refreshDocuments();
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Documenti Ripristinati',
                  'I documenti sono stati correttamente ripristinati, ora sono disponibili nelle loro serie documentali di appartenenza.',
                );
              });
          },
        );
        break;
      case ActionCode.DELETE_FROM_TRASH:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Elimina Definitivamente',
          'Vuoi eliminare definitivamente i documenti selezionati?<br><b>Una volta eseguita l’operazione, i documenti non saranno più recuperabili.</b>',
          () => {
            this.progressBarService.pushRequest();
            this.companiesService
              .whenCurrentCompany()
              .pipe(
                switchMap((company) =>
                  this.actionsHttpService.deleteToDocumenti(actionUrl, {
                    licenseId: company.licenseId,
                    siaCode: company.siaCode,
                    keys: keys.map((doc) => ({
                      progSpool: doc.progSpool,
                      progBusta: doc.progBusta,
                      docSeriesId: doc.ctelDocSeriesId,
                      elasticDocumentId: doc.ctelElasticDocumentId,
                    })),
                  }),
                ),
                take(1),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Documenti non eliminati',
                    'Si sono verificati dei problemi e non è stato possibile eliminare i documenti selezionati.',
                  );
                  return EMPTY;
                }),
                delay(1000),
              )
              .subscribe(() => {
                this.progressBarService.popRequest();
                this.documentsService.refreshDocuments();
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Documenti Eliminati Definitivamente',
                  'I documenti sono stati correttamente eliminati.',
                );
              });
          },
        );
        break;
      default:
        break;
    }
  }
}
