import { NgModule } from '@angular/core';
import { GAWLOTTIDetailComponent } from './gawlotti-detail/gawlotti-detail.component';
import { GAWLOTTIDetailsMainComponent } from './gawlotti-detail/gawlotti-details-main/gawlotti-details-main.component';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { GawlottiStatusHistoryComponent } from './gawlotti-detail/gawlotti-details-main/gawlotti-status-history/gawlotti-status-history.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { GawlottiLottoDetailComponent } from './gawlotti-detail/gawlotti-details-main/gawlotti-lotto-detail/gawlotti-lotto-detail.component';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DocumentsSearchModule } from '@ctel/gawpec-commons';
import { GawCommonsModule } from '@ctel/gaw-commons';

@NgModule({
  declarations: [
    GAWLOTTIDetailComponent,
    GAWLOTTIDetailsMainComponent,
    GawlottiStatusHistoryComponent,
    GawlottiLottoDetailComponent,
  ],
  imports: [
    TabsModule,
    CommonModule,
    DryComponentsModule,
    DisplayDocumentModule,
    PipesModule,
    ErrorPlaceholderModule,
    PlaceholderModule,
    DocumentsSearchModule,
    GawCommonsModule,
  ],
  exports: [GAWLOTTIDetailComponent, GAWLOTTIDetailsMainComponent],
})
export class GawlottiCommonsModule {}
