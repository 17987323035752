import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from '@ctel/block-ui';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { UploadLottoZipRoutingModule } from './upload-lotto-zip-routing.module';
import { UploadLottoZipComponent } from './upload-lotto-zip.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UploadLottoZipRoutingModule,
    DirectivesModule,
    PlaceholderModule,
    ErrorPlaceholderModule,
    BlockUIModule,
  ],
  declarations: [UploadLottoZipComponent],
})
export class UploadLottoZipModule {}
