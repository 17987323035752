import { Component, OnDestroy, OnInit } from '@angular/core';
import { Filter, FullTextSearch } from '@ctel/gaw-commons';
import { Field, FieldType } from 'app/shared/components/dry/field';
import { DateFieldBuilder } from 'app/shared/components/dry/field/builder-specializations/date-field-builder';
import { TextFieldBuilder } from 'app/shared/components/dry/field/builder-specializations/text-field-builder';
import { FormFilter } from 'app/shared/components/table/form-filter';
import { DateRangeFilter } from 'app/shared/components/table/interface/date-range-filter';
import { Observable, Subject, from } from 'rxjs';
import { filter, map, mergeMap, reduce, takeUntil } from 'rxjs/operators';
import { WorkflowService } from '../../../../services/workflow.service';
import { WorkitemHistoryService } from '../../workitem-history/workitem-history.service';

@Component({
  selector: 'gaw-wf-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.scss'],
})
export class StatusHistoryComponent implements OnInit, OnDestroy {
  fields: Array<Field> = [
    new TextFieldBuilder('workflowDescription.keyword', 'Workflow').build(),
    new TextFieldBuilder('stageDescription.keyword', 'Stato iniziale').build(),
    new TextFieldBuilder('action.keyword', 'Azione').build(),
    new TextFieldBuilder('targetStageDescription.keyword', 'Stato destinazione').build(),
    new TextFieldBuilder('username.keyword', 'Utente esecutore').build(),
    new DateFieldBuilder('endDate', 'Data esecuzione').format('DD/MM/YYYY HH:mm:ss').build(),
    new TextFieldBuilder('notes.keyword', 'Note').build(),
  ];
  workItemHistory$: Observable<{ [p: string]: unknown }[]>;

  private readonly defaultFilter: Filter[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private readonly workflowService: WorkflowService,
    private readonly workitemHistoryService: WorkitemHistoryService,
  ) {}

  ngOnInit() {
    // Inizializzo i filtri settando `documentId` e `docSeriesid`
    this.workflowService.selectedWorkItem$
      .pipe(
        filter((wi) => wi !== undefined),
        takeUntil(this.destroy$),
      )
      .subscribe((wi) => {
        const documentSeriesFilter = {
          configData: null,
          filterType: 'term',
          metadata: 'documentSeries',
          metadataDescription: '',
          type: 'string',
          value: {
            term: wi.documentSeries,
          },
        };

        const docSeriesFilter = {
          configData: null,
          filterType: 'term',
          metadata: 'documentId',
          metadataDescription: '',
          type: 'string',
          value: {
            term: wi.documentId,
          },
        };

        // Aggiungo i filtri di default all'array dei filtri di default
        this.defaultFilter.push(documentSeriesFilter, docSeriesFilter);

        // Aggiungo i filtri di default alla ricerca
        this.workitemHistoryService.cleanFilters(this.defaultFilter);
      });

    // Setto l'ordinamento
    this.workitemHistoryService.addOrder({
      metadata: 'endDate',
      order: 'desc',
    });

    // Ottengo i dati
    this.workItemHistory$ = this.workitemHistoryService.history$.pipe(
      map((res) => res.docs),
      mergeMap((docs) =>
        from(docs).pipe(
          mergeMap((doc) =>
            from(doc.display).pipe(
              // Creo un oggetto per ogni metadato contenente la chiave e il valore
              map((display) => ({
                key: display.metadata,
                value: display.value,
              })),
              // Unifico i metadati in un unico oggetto chiave-valore
              reduce<{ key: string; value: unknown }, { [key: string]: unknown }>((acc, val) => {
                acc[val.key] = val.value;
                return acc;
              }, {}),
            ),
          ),
          // Creo un array degli oggetti chiave-valore
          reduce((acc, val) => [...acc, val], []),
        ),
      ),
    );
  }

  filterChange(filterChange: FormFilter[]) {
    // Attualmente sarà solo un unico valore che inserirò nel campo search del payload
    const _filter = filterChange.find((f) => f.value !== undefined && typeof f.value === 'string');
    let textSearch: FullTextSearch;

    if (_filter) {
      // Se ho filtrato per almeno un campo, creo l'oggetto TextSearch
      const value =
        _filter.field.type === FieldType.DATE
          ? (_filter.value as DateRangeFilter).start.format('YYYY-MM-DD')
          : _filter.value;
      textSearch = {
        fullText: false,
        metadataList: [
          // TODO: Capire con mauri quando sistemare questa parte
          _filter.field.id.split('.keyword')[0],
        ],
        value: value as string,
      };
    }
    this.workitemHistoryService.sendFullTextSearch(textSearch);
  }

  ngOnDestroy(): void {
    this.workitemHistoryService.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
