import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly openDetail = new ActionBuilder(SingleActionsCode.openDetail, ActionCode.OPEN_DETAIL).build();
}

export enum SingleActionsCode {
  openDetail = 'openDetail',
}
