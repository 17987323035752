import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AppError } from 'app/core/common/error/app-error';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ActionConfirmationModalComponent } from 'app/core/common/modals/action-confirmation-modal/action-confirmation-modal.component';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { catchError, delay, take, tap } from 'rxjs/operators';
import { DocumentDetailsService } from '../document-details/document-details.service';
import { DocumentsSeriesService } from '../documents-series/documents-series.service';
import { DocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionsService2 } from './actions.service';
import { CheckboxService } from './checkbox/checkbox.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class ExecuteActionsService {
  public readonly gedPecEndpoint = '/api/v1/Action/modifypec';
  private readonly gedMailEndpoint =
    '/v1/Document/GEDMAIL/ModificaMail?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&indirizzoMail={mail}';

  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private actionsService: ActionsService2,
    private documentDetailsService: DocumentDetailsService,
    private documentsService: DocumentsService2,
    private documentsSeriesService: DocumentsSeriesService,
    private checkboxService: CheckboxService,
    private companiesService: CompaniesService,
    private router: Router,
    private transferService: TransferService,
  ) {}

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  public executeSingleAction(action: ActionObject | ActionConfigItem, keys: any, refreshDocuments: boolean) {
    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    let body: any;

    const basicPayload = {
      progSpool: keys.progSpool,
      progBusta: keys.progBusta,
      docHash: keys.hashDocKey,
      ctelElasticDocumentId: keys.ctelElasticDocumentId,
      ctelDocSeriesId: keys.ctelDocSeriesId,
      ultimoStato22: keys.ultimoStato22,
      idComunicazione22: keys.idComunicazione22,
    };

    switch (actionCode) {
      // GAWPEC SINGLE ACTIONS
      case ActionCode.CANCEL_ELAB: {
        body = [
          {
            progSpool: basicPayload.progSpool,
            progBusta: basicPayload.progBusta,
            hashDoc: basicPayload.docHash,
            ctelElasticDocumentId: basicPayload.ctelElasticDocumentId,
          },
        ];

        const cancelElabAction$ = () =>
          this.actionsHttpService.whenCancelInvoice(actionUrl, body).pipe(
            catchError((err: unknown) => this.catchError(err as AppError)),
            delay(1000),
            tap(() => {
              // per evitare Search se sono nella pagina di dettaglio
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
            tap((result) => {
              if (result) {
                let isError = false;
                for (let i = 0; i < result.length; i++)
                  if (result[i].esitoOK === false) {
                    isError = true;
                    break;
                  }

                if (isError)
                  this.notificationService.showSweetAlert(NotificationType.INFO, 'Documento non annullabile', '');
                else {
                  // se sono nel dettaglio, l'azione fa il refresh della lista azioni
                  if (!refreshDocuments) this.documentDetailsService.sendRefreshDocumentDetails();

                  this.notificationService.showSweetAlert(
                    NotificationType.SUCCESS,
                    'Richiesta annullamento' + ' eseguita correttamente',
                    '',
                  );
                }
              } else this.notificationService.showSweetAlert(NotificationType.INFO, 'Documento non annullabile', '');
            }),
          );

        //TODO TAM: deve diventare uno sweet alert
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Annulla',
          childComponent: ActionConfirmationModalComponent,
          customFooter: true,
          childData: {
            action: cancelElabAction$,
            title: 'Annulla',
            docCount: 1,
          },
          disableClickOutside: false,
        });
        break;
      }

      // case ActionCode.SHOW_STATUS_HISTORY:
      // 	this.actionsHttpService
      // 		.whenChangesHistory(actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.idComunicazione22)
      // 		.pipe(
      // 			catchError((err: AppError) => {
      // 				return this.catchError(err);
      // 			})
      // 		)
      // 		.subscribe((result) => {
      // 			if (result) {
      // 				let title = '';
      //
      // 				switch (actionCode) {
      // 					case ActionCode.SHOW_STATUS_HISTORY:
      // 						title = 'Storico stati';
      // 						this.actionsService.sendHistory(result);
      // 						this.notificationService.showModal(NotificationType.LARGE, {
      // 							title: title,
      // 							customFooter: false,
      // 							childComponent: HistoryActionModalComponent,
      // 							childData: basicPayload,
      // 							disableClickOutside: false
      // 						});
      // 						break;
      // 				}
      // 			} else {
      // 				this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico non trovato');
      // 			}
      // 		});
      // 	break;

      case ActionCode.DOWNLOAD_PDF:
        this.actionsHttpService
          .whenSinglePdf(actionUrl, keys.progSpool, keys.progBusta, keys.hashDocKey)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
                new FileSaver(blob).saveAs(`${basicPayload.idComunicazione22}.principale`, FileSaverExtension.PDF);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.DOWNLOAD_ATTACHMENTS:
        this.actionsHttpService
          .whenAttachments(actionUrl, basicPayload.idComunicazione22)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
                new FileSaver(blob).saveAs(
                  `${basicPayload.idComunicazione22}.AllegatiAggiuntivi.zip`,
                  FileSaverExtension.ZIP,
                );
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.DOWNLOAD_ORIGINAL_PDF:
        this.actionsHttpService
          .whenOriginalPdf(actionUrl, basicPayload.idComunicazione22)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/octet-stream' });
                new FileSaver(blob).saveAs(`${basicPayload.idComunicazione22}.originale.eml`, FileSaverExtension.EML);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.DOWNLOAD_ACCEPTANCE:
        this.actionsHttpService
          .whenAcceptance(actionUrl, basicPayload.idComunicazione22)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/octet-stream' });
                new FileSaver(blob).saveAs(
                  `${basicPayload.idComunicazione22}.msg.accettazione.eml`,
                  FileSaverExtension.EML,
                );
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.DOWNLOAD_DELIVERY:
        this.actionsHttpService
          .whenDelivery(actionUrl, basicPayload.idComunicazione22)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/octet-stream' });
                new FileSaver(blob).saveAs(
                  `${basicPayload.idComunicazione22}.msg.avvenuta-consegna.eml`,
                  FileSaverExtension.EML,
                );
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.DOWNLOAD_MISSING_DELIVERY:
        this.actionsHttpService
          .whenMissingDelivery(actionUrl, basicPayload.idComunicazione22)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/octet-stream' });
                new FileSaver(blob).saveAs(
                  `${basicPayload.idComunicazione22}.msg.avvenuta-consegna.eml`,
                  FileSaverExtension.EML,
                );
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.EDIT_ADDRESS_AND_RESEND: {
        let docHash: string;
        // nel caso in cui dochash sia vuoto
        // TODO karandip docHash da verificare - 15-01-2020
        if (basicPayload.docHash) docHash = basicPayload.docHash;
        else docHash = ' ';

        this.documentDetailsService.sendCurrentProgSpoolAndBustaService([
          basicPayload.progSpool,
          basicPayload.progBusta,
          basicPayload.docHash,
          basicPayload,
        ]);
        this.documentDetailsService.sendDocumentListUrl(this.router.url);
        const url = [
          '/gawpec/modifypec/22/',
          this.companiesService.getCurrentCompanyValue().licenseId,
          '/',
          this.companiesService.getCurrentCompanyValue().siaCode,
          '/',
          basicPayload.progSpool,
          '/',
          basicPayload.progBusta,
          '/',
          docHash,
          '/',
          basicPayload.ctelElasticDocumentId,
          '/',
          basicPayload.idComunicazione22,
        ].join('');
        this.router.navigate([url]).then();
        break;
      }

      default:
        console.warn('ACTION non configurata', actionCode);
        break;
    }
  }

  downloadFile(result, fileName) {
    if (result !== null) {
      const blob = new Blob([result], { type: 'application/octet-stream' });
      saveAs(blob, fileName);
    } else this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
  }

  // ESECUZIONE AZIONI MULTIPLE
  public executeMultiAction(action: ActionObject, keys: any[], sectionCode?: string) {
    const docCount = keys.length;

    const actionCode = action.actionCode;
    // let actionCode = '';
    // let actionUrl = '';

    // if (action instanceof ActionObject) {
    // 	actionCode = action.actionCode;
    // 	actionUrl = action.url;
    // } else {
    // 	actionCode = action.actionCode;
    // 	actionUrl = action.actionUrl;
    // }

    switch (actionCode) {
      // massive/multi
      case ActionCode.DOWNLOAD_EXCEL: {
        // Modal della scelta del formato dell'excel
        const showModal = () => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: 'Esporta risultati in Excel',
            customFooter: true,
            childComponent: ExcelFormatModalComponent,
            childData: {
              backButton: true,
              action,
              actionType: 'multiple',
              docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
              keys,
              service: 'GAWLOTTI',
              sectionCode,
            },
          });
        };

        if (docCount > 50000)
          this.notificationService.showSweetAlert(
            NotificationType.QUESTION,
            'Numero massimo di documenti superato.',
            "Procedere all'esportazione dei primi 50000 documenti?",
            showModal,
          );
        else showModal();

        break;
      }
    }
  }

  public editEmail(
    indirizzoPec: string,
    progSpool: string,
    progBusta: string,
    idComunicazione: string,
    gedpec: boolean,
  ) {
    if (gedpec)
      return this.actionsHttpService.whenModifyMailOrPecAddress(this.gedPecEndpoint, {
        progSpool,
        progBusta,
        idComunicazione,
        indirizzoPec,
      });

    return this.actionsHttpService.whenModifyMailOrPecAddress(this.gedMailEndpoint, {
      progSpool,
      progBusta,
      idComunicazione,
      indirizzoPec,
    });
  }

  private catchError(err: AppError): Observable<null> {
    if (err.type === ErrorTypes.HTTP_UNAUTHORIZED)
      //this.router.navigate(['/unauthorized']).then();
      return of(null);
    else return of(null);
  }
}
