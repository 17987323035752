import { Browser } from './browser.enum';

declare const InstallTrigger: any;
declare const opr: any;
declare const safari: any;

/**
 * Classe contenente le utilità per l'identificazione del browser corrente
 */
export class BrowserCheck {
  /** Test se il browser corrente è Opera 8.0+ */
  public static isOpera(): boolean {
    return (!!window['opr'] && !!opr['addons']) || !!window['opera'] || navigator.userAgent.indexOf(' OPR/') >= 0;
  }

  /** Test se il browser corrente è Mozilla-Firefox 1.0+ */
  public static isFirefox(): boolean {
    return typeof InstallTrigger !== 'undefined';
  }

  /** Test se il browser corrente è Mozilla-Firefox Safari 3.0+. Utilizzando per il test "[object HTMLElementConstructor]" */
  public static isSafari(): boolean {
    return (
      /constructor/i.test(window['HTMLElement'] as any) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || safari['pushNotification'])
    );
  }

  /** Test se il browser corrente è Internet Explorer 6-11 */
  public static isInternetExplorer(): boolean {
    return /*@cc_on!@*/ !!document['documentMode'];
  }

  /** Test se il browser corrente è Edge 20+ */
  public static isEdge() {
    return !this.isInternetExplorer() && !!window['StyleMedia'];
  }

  /** Test se il browser corrente è Chrome 1+ */
  public static isChrome(): boolean {
    return !!window['chrome'] && (!!window['chrome'].webstore || !!window['chrome'].runtime);
  }

  /** Test se l'engine utilizzato è Blink. Attualmente il test è limitato ai browser Chrome e Opera. */
  public static isBlinkEngine(): boolean {
    return this.isChrome() || (this.isOpera() && !!window['CSS']);
  }

  /**
   * Effettua tutti i test implementati per controllare il browser dal quale è stato eseguito.
   *
   * @return array contenente i browser rilevati
   */
  public static test(): string[] {
    const browsers: string[] = [];

    if (this.isOpera()) browsers.push(Browser.OPERA);

    if (this.isFirefox()) browsers.push(Browser.MOZILLA_FIREFOX);

    if (this.isSafari()) browsers.push(Browser.SAFARI);

    if (this.isInternetExplorer()) browsers.push(Browser.INTERNET_EXPLORER);

    if (this.isEdge()) browsers.push(Browser.EDGE);

    if (this.isChrome()) browsers.push(Browser.CHROME);

    if (this.isBlinkEngine()) browsers.push(Browser.BLINK_ENGINE);

    return browsers;
  }
}
