/* eslint-disable no-empty */
import { PlatformLocation } from '@angular/common';
import { AfterContentChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AddressBookService } from 'app/core/business/configuration/address-book/address-book.service';
import { CitiesService, City } from 'app/core/business/invoice-pa/create-edit-invoice/shared/city/cities.service';
import { NationsService } from 'app/core/business/invoice-pa/create-edit-invoice/shared/nations/nations.service';
import { Nations } from 'app/core/business/invoice-pa/create-edit-invoice/shared/nations/nazione';
import { Copier } from 'app/core/common/utilities/copier';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';
import { Contact, ContactConfiguration, emptyContact } from 'app/modules/address-book/contact';
import { unflatten } from 'flat';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompaniesService } from '../../../core/business/companies/companies.service';
import { EnabledServicesService } from '../../../core/business/user/enabled-services/enabled-services.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

/**
 * Componente che rappresenta la modal per l'insert/update di un contatto della rubrica
 *
 */

@Component({
  templateUrl: './contact-details-modal.component.html',
  styleUrls: ['./contact-details-modal.component.scss'],
})
export class ContactDetailsModalComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() modal: BsModalRef;

  data: {
    edit: boolean;
    contact: Contact;
  };

  nationsSortedByName$: Observable<InvoiceMultiValues[]>;
  cities: City[];
  denominazioneDisabled = false;
  nomeCognomeDisabled = false;
  pecDisabled = false;
  contact: Contact;
  aliasList: string[];
  mandatoryDataErrorMessage = false;
  aliasAlreadyExists = false;
  aliasAlreadyExistsMessage = false;
  personaFisicaFlag = false;
  subscriptions: Subscription = new Subscription();
  lastCodiceVeicolazione: string;
  codVeicolazioneEnabled: boolean;
  codiceVeicolazioneError: boolean;
  codiceVeicolazioneErrorMessage: string;

  private services: string[];

  constructor(
    private location: PlatformLocation,
    private nationsService: NationsService,
    private citiesService: CitiesService,
    private addressBookService: AddressBookService,
    private router: Router,
    private company: CompaniesService,
    private enabledServices: EnabledServicesService,
  ) {
    // Workaround temporaneo perchè all'apertura della modal il loading viene impostato a true
    // this.actionsHttpService.setLoadingAction(false);
    // location.onPopState(() => this.modal.hide());
    this.nationsSortedByName$ = this.nationsService.whenNationsSortedByName();
    this.cities = this.citiesService.getList();
    this.data = this.addressBookService.selectedContact;
  }

  ngOnInit(): void {
    if (!this.data) return;

    this.subscriptions.add(
      this.addressBookService.checkEnabledServices().subscribe((value) => (this.codVeicolazioneEnabled = value)),
    );
    if (this.data.edit) {
      this.lastCodiceVeicolazione = this.data.contact.contactConfiguration.datiTrasmissione.CodiceVeicolazione;
      this.contact = this.dropdownIdsToLabels(Copier.deepCopy(this.data.contact));
      const cognome = this.contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.Anagrafica.Cognome;
      if (cognome === undefined || cognome === '') this.nomeCognomeDisabled = true;
      else {
        this.denominazioneDisabled = true;
        this.personaFisicaFlag = true;
      }
    } else {
      this.contact = Copier.deepCopy(emptyContact);
      this.nomeCognomeDisabled = true;
    }

    this.aliasList = this.addressBookService.getAliasList().filter((alias) => alias !== this.contact.contactAlias);
  }

  onFormSubmit(form: UntypedFormGroup) {
    this.mandatoryDataErrorMessage = false;
    this.aliasAlreadyExistsMessage = false;

    if (!form.value.contactAlias) {
      this.mandatoryDataErrorMessage = true;
      return;
    }

    if (this.aliasAlreadyExists) {
      this.aliasAlreadyExistsMessage = true;
      return;
    }

    if (
      this.personaFisicaFlag &&
      (!form.value['cessionarioCommittente.DatiAnagrafici.Anagrafica.Nome'] ||
        !form.value['cessionarioCommittente.DatiAnagrafici.Anagrafica.Cognome'])
    ) {
      this.mandatoryDataErrorMessage = true;
      return;
    }

    if (!this.personaFisicaFlag && !form.value['cessionarioCommittente.DatiAnagrafici.Anagrafica.Denominazione']) {
      this.mandatoryDataErrorMessage = true;
      return;
    }

    // trimmo eventuali spazi su tutti i campi stringa
    Object.keys(form.value).map(
      (k) => (form.value[k] = typeof form.value[k] === 'string' ? form.value[k].trim() : form.value[k]),
    );
    let newForm: ContactConfiguration = unflatten(Copier.deepCopy(form.value));
    newForm = this.dropdownLabelsToIds(newForm);
    newForm.datiTrasmissione.lastCodiceVeicolazione = this.lastCodiceVeicolazione;
    this.addressBookService
      .whenUpsertContact(newForm)
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.outcome) {
          this.addressBookService.refresh();
          this.addressBookService.sendIsAddressBookLoading(true);
          this.onCancel();
        } else {
          this.codiceVeicolazioneError = !resp.outcome;
          this.codiceVeicolazioneErrorMessage = resp.message;
        }
      });
  }

  onDenominazioneChanged(e: any) {
    this.nomeCognomeDisabled = e !== undefined;
  }

  onAliasChange(e: any) {
    this.aliasAlreadyExists = this.aliasList.some((x) => x === e);
  }

  onFormatoTrasmissioneChanged(e: any) {
    if (e === 'FPA12') {
      (<HTMLInputElement>document.getElementById('rubrica-pec')).value = '';
      this.pecDisabled = true;
    } else this.pecDisabled = false;
  }

  personaFisicaChecked(event: any) {
    if (event.target.checked === true) {
      this.denominazioneDisabled = true;
      this.nomeCognomeDisabled = false;
      this.personaFisicaFlag = true;
    } else {
      this.denominazioneDisabled = false;
      this.nomeCognomeDisabled = true;
      this.personaFisicaFlag = false;
    }
  }

  ngAfterContentChecked(): void {
    const select = <HTMLSelectElement>document.getElementById('rubrica-formato');
    if (select) this.pecDisabled = select.options[select.selectedIndex].value === 'FPA12';
  }

  onCancel() {
    this.addressBookService.selectedContact = null;
    if (this.modal) this.modal.hide();
    else this.router.navigate(['address-book']);
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  // mappa nazione e provincia da descrizione a sigla
  private dropdownLabelsToIds(contact: any): ContactConfiguration {
    try {
      const nation = Nations.find((obj) => obj.label === contact.cessionarioCommittente.Sede.Nazione);
      contact.cessionarioCommittente.Sede.Nazione = nation.value;
    } catch {}

    try {
      const province = this.cities.find((obj) => obj.label === contact.cessionarioCommittente.Sede.Provincia);
      contact.cessionarioCommittente.Sede.Provincia = province.value;
    } catch {}
    try {
      const nationId = Nations.find(
        (obj) => obj.labelWithValue === contact.cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdPaese,
      );
      contact.cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdPaese = nationId.labelWithValue;
    } catch {}
    return contact;
  }

  // mappa nazione e provincia da sigla a descrizione
  private dropdownIdsToLabels(contact: Contact): Contact {
    try {
      const nation = Nations.find(
        (obj) => obj.value === contact.contactConfiguration.cessionarioCommittente.Sede.Nazione,
      );
      contact.contactConfiguration.cessionarioCommittente.Sede.Nazione = nation.label;
    } catch {}
    try {
      const province = this.cities.find(
        (obj) => obj.value === contact.contactConfiguration.cessionarioCommittente.Sede.Provincia,
      );
      contact.contactConfiguration.cessionarioCommittente.Sede.Provincia = province.label;
    } catch {}
    try {
      const nationId = Nations.find(
        (obj) => obj.value === contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdPaese,
      );
      contact.contactConfiguration.cessionarioCommittente.DatiAnagrafici.IdFiscaleIVA.IdPaese = nationId.labelWithValue;
    } catch {}
    return contact;
  }
}
