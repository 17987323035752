import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from 'app/entities/error/api-error';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppError } from './app-error';
import { AppErrorBuilder } from './app-error-builder';
import { ErrorTypes } from './error-types';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {
  private interceptorName = 'interceptor-error';

  constructor(private logger: NGXLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url) {
      this.logger.error('Chiamata http senza url', request); // E' difficile risalire al service mancante. Bisogna approfondire
      return EMPTY;
    }
    return next.handle(request).pipe(
      tap({
        error: (err: unknown) => {
          if (err instanceof HttpErrorResponse) throw this.handleError(err);
        },
      }),
    );
  }

  private manageClientError(err): AppError {
    const clientErrorBuilder = new AppErrorBuilder(ErrorTypes.HTTP_CLIENT_OR_NETWORK_ERROR)
      .cause(err)
      .description(`${err.error.name}: ${err.error.message}.`);

    return clientErrorBuilder.build();
  }

  private manageResponseError(err): AppError {
    let apiError: ApiError;

    let errorType;
    switch (err.status) {
      case 401:
        errorType = ErrorTypes.HTTP_UNAUTHORIZED;
        break;
      case 403:
        errorType = ErrorTypes.HTTP_FORBIDDEN;
        break;
      case 404:
        errorType = ErrorTypes.HTTP_NOT_FOUND;
        break;
      default:
        errorType = ErrorTypes.HTTP_ERROR;
        break;
    }

    if (err.error) {
      apiError = this.getApiError(err.error);

      if (apiError.message.startsWith('Foreign Key Violation')) errorType = ErrorTypes.REFERENTIAL_INTEGRITY_ERROR;
    }

    const httpErrorBuilder = new AppErrorBuilder(errorType).cause(err).description(`${err.name}: ${err.message}.`);

    if (err.error) {
      httpErrorBuilder.info('response', err.error);
      httpErrorBuilder.apiError(apiError);
    }

    if (err.url) httpErrorBuilder.info('url', err.url);

    if (err.status) httpErrorBuilder.info('status code', err.status);

    if (err.statusText) httpErrorBuilder.info('response phrase', err.statusText);

    return httpErrorBuilder.build();
  }

  private getApiError(error: any): ApiError {
    let apiError: ApiError = new ApiError('', '');

    if (error.code && error.message) apiError = new ApiError(error.code, error.message);

    return apiError;
  }

  private handleError(error: HttpErrorResponse): AppError {
    let appError: AppError;

    if (error.error instanceof ErrorEvent) {
      // È avvenuto un errore client-side
      this.logger.error(`[${this.interceptorName}] Errore lato client:`, error.error.message, error);
      appError = this.manageClientError(error);
    } else {
      // È avvenuto un errore server-side, il backend ci ritorna quindi un errore da interpretare
      this.logger.error(`[${this.interceptorName}] Errore lato server con codice ${error.status} e corpo, `, error);
      appError = this.manageResponseError(error);
    }

    return appError;
  }
}
