export interface EnabledServiceEndpoint {
  uI_CODE: string;
  descrizionE_AREA: string;
  codsia?: string;
  iD_LICENZA?: string;
  fL_ABILITATO?: boolean;
  iD_AUTH_UTENTE?: number;
  sectioN_CODE?: string;
  serviziO_ID?: string;
  codiceArea?: string;
}

export interface EnabledServiceEndpointResponse {
  uiCode: string;
  descrizioneArea: string;
  codSia?: string;
  idLicenza?: string;
  fl_Abilitato?: boolean;
  id_Auth_Utente?: number;
  sectionCode?: string;
  servizioId?: string;
  codiceArea?: string;
}

export class EnabledService {
  constructor(
    public fL_ABILITATO: boolean,
    public sectioN_CODE: string,
    public serviziO_ID: string,
    public uI_CODE: string,
  ) {}

  static mapEnabledSearchEndPoint(response: EnabledServiceEndpointResponse[]): EnabledServiceEndpoint[] {
    const res = [];
    response.forEach((r) => {
      res.push({
        uI_CODE: r.uiCode,
        descrizionE_AREA: r.descrizioneArea,
        codsia: r.codSia,
        iD_LICENZA: r.idLicenza,
        fL_ABILITATO: r.fl_Abilitato,
        iD_AUTH_UTENTE: r.id_Auth_Utente,
        sectioN_CODE: r.sectionCode,
        serviziO_ID: r.servizioId,
        codiceArea: r.codiceArea,
      });
    });
    return res;
  }
}
