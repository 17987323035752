import { Injectable } from '@angular/core';
import { News, Notification } from 'app/entities/news-and-notifications/news-and-notifications';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, first, map, switchMap, take, tap } from 'rxjs/operators';
import { PollingService } from '../../common/polling/polling.service';
import { NewsAndNotificationsHttpService } from './news-and-notifications-http.service';

/**
 * Servizio che gestisce lo stato delle news e delle notifiche utente
 */
@Injectable()
export class NewsAndNotificationsService {
  private readonly news$ = new ReplaySubject<News[]>(1);
  private readonly notifications$: Observable<Notification[]>;
  private readonly newsError$ = new BehaviorSubject<boolean>(false);

  constructor(
    private newsAndNotificationsHttpService: NewsAndNotificationsHttpService,
    private logger: NGXLogger,
    private pollingService: PollingService,
  ) {
    this.newsAndNotificationsHttpService
      .whenNews()
      .pipe(
        first(),
        catchError(() => {
          this.setNewsError(true);
          return EMPTY;
        }),
      )
      .subscribe((response) => {
        if (response) {
          this.setNewsError(false);
          this.sendNews(response);
        }
      });

    const innerNotifications$ = this.newsAndNotificationsHttpService.whenNotifications().pipe(
      take(1),
      catchError((error: unknown) => {
        this.logger.error('NewsAndNotificationsService.whenNotifications() error', error);
        return EMPTY;
      }),
      map((x) => x.reverse()),
    );
    innerNotifications$.subscribe();

    this.notifications$ = this.pollingService.whenPolling().pipe(switchMap(() => innerNotifications$));
  }

  public setNewsError(value: boolean) {
    this.newsError$.next(value);
  }

  public whenNewsError(): Observable<boolean> {
    return this.newsError$.asObservable();
  }

  whenNews(): Observable<News[]> {
    return this.news$.asObservable();
  }

  sendNews(news): void {
    this.news$.next(news);
  }

  whenNotifications(): Observable<Notification[]> {
    return this.notifications$;
  }

  requestUpdatedNotifications(): void {
    this.pollingService.restart();
  }

  requestUpdatedNews(): void {
    this.newsAndNotificationsHttpService
      .whenNews()
      .pipe(take(1))
      .subscribe((response) => {
        this.sendNews(response);
      });
  }

  // chiamata post per flaggare una notifica come letta
  setNotificationRead(notificationId: number): void {
    this.newsAndNotificationsHttpService
      .setNotificationRead(notificationId)
      .pipe(
        first(),
        catchError((error: unknown) => {
          this.logger.error('NewsAndNotificationsService.setNotificationRead() error', error);
          this.requestUpdatedNotifications();
          return EMPTY;
        }),
      )
      .subscribe(() => this.requestUpdatedNotifications());
  }

  setNewsRead(id: number) {
    return this.newsAndNotificationsHttpService.setNewsRead(id).pipe(tap(() => this.requestUpdatedNews()));
  }
}
