export enum ChannelsInvoice {
  GEDFATT = 'gedfatt',
  GEDINVOICE = 'gedinvoice',
  GEDMAIL = 'gedmail',
  GEDPEC = 'gedpec',
  GEDPOST = 'gedpost',
  GEDPASS = 'gedpass',
  GEDONWEB = 'gedonweb',
  GEDNOTICE = 'gednotice',
  GAWNOTICE = 'gawnotice',
  GAWMAIL = 'gawmail',
  GAWPOST = 'gawpost',
  GAWPEC = 'gawpec',
  GAWFE = 'gawfe',
  GEDPASSPLUS = 'gedpassplus',
  GEDEDI = 'gededi',
}
export const GEDFATT = 'gedfatt';
export const GEDINVOICE = 'gedinvoice';
export const GEDMAIL = 'gedmail';
export const GEDPEC = 'gedpec';
export const GEDPOST = 'gedpost';
export const GEDPASS = 'gedpass';
export const GEDONWEB = 'gedonweb';
export const GEDNOTICE = 'gednotice';
