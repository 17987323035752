import { TextField } from 'app/shared/components/dry/field';
import { FieldBuilder } from 'app/shared/components/dry/field/field-builder';
import { TextValidators } from 'app/shared/components/dry/validator/text-validators';

export class TextFieldBuilder extends FieldBuilder {
  constructor(id: string, name: string) {
    super(new TextField(id, name));
  }

  withRegexValidator(regex: string | RegExp): TextFieldBuilder {
    this.withValidator(TextValidators.pattern(regex));
    return this;
  }

  isPartitaIva(): TextFieldBuilder {
    this.withValidator(TextValidators.patternPartitaIva());
    return this;
  }

  isCodiceFiscale(): TextFieldBuilder {
    this.withValidator(TextValidators.patternCodiceFiscale());
    return this;
  }

  requireNoSpace(): TextFieldBuilder {
    this.withValidator(TextValidators.noSpacePattern());
    return this;
  }

  requireMinLength(n: number): TextFieldBuilder {
    this.withValidator(TextValidators.minLength(n));
    return this;
  }

  requireMaxLenght(n: number): TextFieldBuilder {
    this.withValidator(TextValidators.maxLength(n));
    return this;
  }
}
