import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { AuthConfigService, IAuthConfig } from '../auth-config';
import { AuthService } from '../services/auth.service';

/**
 * Intercettatore delle chiamate HTTP.
 * Se la richiesta viene fatta verso un indirizzo che compare nella `whitelist` verrà aggiunto il bearer token tra gli header.
 *
 * @example
 *  {
 *    provide: HTTP_INTERCEPTORS,
 *    useClass: AuthHttpInterceptor,
 *    multi: true
 *  }
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(inputRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const inputRequest$: Observable<HttpRequest<unknown>> = of(inputRequest);

    const addAuthorization = ([request, config]: [HttpRequest<unknown>, IAuthConfig]) => {
      const urlComparator = (listUrl) => request.url.startsWith(listUrl);
      const isWhitelisted = config.whiteList.find(urlComparator) !== undefined;
      const isBlacklisted = config.blackList.find(urlComparator) !== undefined;

      if (isWhitelisted && !isBlacklisted)
        return this.addAuthorizationHeaders(request.headers).pipe(map((headers) => request.clone({ headers })));
      else return of(request);
    };

    const authConfigService = this.injector.get(AuthConfigService);
    return inputRequest$.pipe(
      withLatestFrom(authConfigService.getAuthConfig()),
      mergeMap(addAuthorization),
      switchMap((request) => next.handle(request)),
    );
  }

  private addAuthorizationHeaders(headers: HttpHeaders): Observable<HttpHeaders> {
    const authService = this.injector.get(AuthService);
    return authService.whenToken().pipe(
      take(1),
      map((token) => {
        if (
          token !== '' &&
          !headers.keys().includes('Authorization') &&
          headers.get('Authorization') !== 'Bearer ' + token
        ) {
          const tokenValue: string = 'Bearer ' + token;
          headers = headers.append('Authorization', tokenValue);
        }
        return headers;
      }),
      catchError((err: unknown) => {
        throw new Error(`Impossibile aggiungere gli header di autenticazione: ${err}`);
      }),
    );
  }
}
