import { Component, OnDestroy } from '@angular/core';
import { DocumentDetailsService } from '@ctel/gaw30-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Workflow } from '../../../entities/workflow';
import { WorkflowService } from '../../../services/workflow.service';
import { WorkflowVM } from '../../../workflowVM';
import { CreateWorkitemModalComponent } from '../../workflow-modal/create-workitem-modal/create-workitem-modal.component';

/**
 Lista di workflow per serie documentale (lista button in modal dell'azione Attiva workflow)
 */
@Component({
  selector: 'gaw-workflow-list-for-modal',
  templateUrl: 'workflow-list-for-modal.component.html',
  styleUrls: ['workflow-list-for-modal.component.scss'],
})
export class WorkflowListForModalComponent implements OnDestroy {
  bsCreateWorkItemModalRef: BsModalRef;
  public workflowsByDocSeries$: Observable<Workflow[]>;
  private destroy$ = new Subject<void>();

  constructor(
    private companiesService: CompaniesService,
    private workflowService: WorkflowService,
    private notificationService: NotificationService,
    private documentDetailsService: DocumentDetailsService,
    private modalService: BsModalService,
    private logger: NGXLogger,
  ) {
    this.workflowsByDocSeries$ = this.documentDetailsService.whenRealDocSeriesId().pipe(
      take(1),
      mergeMap((docSeriesId) => {
        const licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
        return this.workflowService.whenWorkflowsByDocSeries(licenseId, docSeriesId);
      }),
      takeUntil(this.destroy$),
    );
  }

  openWorkflowModal(workflow: WorkflowVM) {
    this.workflowService.sendSelectedWorkflow(workflow);
    if (workflow !== null)
      if (workflow.itemCreationLink !== null) {
        const initialState = {
          title: 'Crea nuovo item',
          workflow,
        };
        this.workflowService.sendCreationSuccess('close');
        this.bsCreateWorkItemModalRef = this.modalService.show(CreateWorkitemModalComponent, {
          initialState,
          class: 'modal-xxl',
        });
      } else
        this.documentDetailsService
          .whenCreateWorkItem(workflow)
          .pipe(
            take(1),
            catchError((err: unknown) => {
              const errorMessage = "Errore durante l'attivazione del workflow";
              this.logger.error(errorMessage, err);
              this.notificationService.showSweetAlert(NotificationType.ERROR, errorMessage, '');
              this.workflowService.sendCreationSuccess('error');
              return EMPTY;
            }),
          )
          .subscribe(() => this.workflowService.sendCreationSuccess('success'));
  }

  ngOnDestroy() {
    this.workflowService.sendCreationSuccess('start');
    this.destroy$.next();
    this.destroy$.complete();
  }
}
