import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { GAWPOSTDetailComponent } from './gawpost-detail/gawpost-detail.component';
import { GAWPOSTDetailsMainComponent } from './gawpost-detail/gawpost-details-main/gawpost-details-main.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { GAWPOSTStatusHistoryComponent } from './gawpost-detail/gawpost-details-main/gawpost-status-history/gawpost-status-history.component';
import { ErrorPlaceholderModule, PlaceholderModule } from '@ctel/placeholders';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { GAWPOSTDocumentPreviewComponent } from './gawpost-detail/gawpost-details-main/gawpost-document-preview/gawpost-document-preview.component';
import { DisplayDocumentModule } from 'app/shared/components/display-document/display-document.module';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { HistoryActionModalComponent } from './modals/history-action-modal.component/history-action-modal.component';
import { TrackingProviderModalComponent } from './modals/tracking-provider-modal.component/tracking-provider-modal.component';

@NgModule({
  declarations: [
    HistoryActionModalComponent,
    GAWPOSTDetailComponent,
    GAWPOSTDetailsMainComponent,
    GAWPOSTStatusHistoryComponent,
    GAWPOSTDocumentPreviewComponent,
    TrackingProviderModalComponent,
  ],
  imports: [
    CommonModule,
    DryComponentsModule,
    DisplayDocumentModule,
    PipesModule,
    ErrorPlaceholderModule,
    PlaceholderModule,
    TabsModule,
    DocumentsSearchModule,
  ],
  exports: [
    GAWPOSTDetailComponent,
    GAWPOSTDetailsMainComponent,
    GAWPOSTStatusHistoryComponent,
    GAWPOSTDocumentPreviewComponent,
  ],
})
export class GawpostCommonsModule {}
