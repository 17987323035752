import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from './callbacks/single-document-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly viewDocument = new ActionBuilder(SingleActionsCode.viewDocument, ActionCode.SHOW_PDF).build();

  public readonly signDoc = new ActionBuilder(SingleActionsCode.signDoc, ActionCode.SIGN_DOC)
    .setActionURL('/v1/Document/Sign?clientId=HUBFE')
    .build();

  public readonly showOriginalPdf = new ActionBuilder(
    SingleActionsCode.showOriginalPdf,
    ActionCode.SHOW_ORIGINAL_PDF,
  ).build();

  public readonly sendAnalogicCopyGedinvoice = new ActionBuilder(
    SingleActionsCode.sendAnalogicCopyGedinvoice,
    ActionCode.SEND_ANALOGIC_COPY,
  ).build();

  public readonly insertProtocol = new ActionBuilder(SingleActionsCode.insertProtocol, ActionCode.PROTOCOL) // mode 0
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/DatiProtocollo?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&numeroProtocollo={protocolNumber}&dataProtocollo={protocolDate}&idSerieDoc={docSeriesId}&documentId={documentId}',
    )
    .build();

  public readonly confirmRegistration = new ActionBuilder(
    SingleActionsCode.confirmRegistration,
    ActionCode.CONFIRM_REGISTRATION,
  ) // mode 5
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/Confirmation?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&idSerieDoc={docSeriesId}&documentId={documentId}',
    )
    .build();

  public readonly insertRCFile = new ActionBuilder(SingleActionsCode.insertRCFile, ActionCode.RC_FILE) // mode 3
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/RCFile?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&idSerieDoc={docSeriesId}&documentId={documentId}',
    )
    .build();

  public readonly insertRCMetadata = new ActionBuilder(SingleActionsCode.insertRCMetadata, ActionCode.RC_METADATA) // mode 4
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/RCMetadata?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&idSerieDoc={docSeriesId}&documentId={documentId}',
    )
    .build();

  public readonly insertProtocolAndRCFile = new ActionBuilder(
    SingleActionsCode.insertProtocolAndRCFile,
    ActionCode.PROTOCOL_RC_FILE,
  ) // mode 1
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/ProtocollaAndRCFile?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&idSerieDoc={docSeriesId}&documentId={documentId}&numeroProtocollo={protocolNumber}&dataProtocollo={protocolDate}',
    )
    .build();

  public readonly insertProtocolAndRCMetadata = new ActionBuilder(
    SingleActionsCode.insertProtocolAndRCMetadata,
    ActionCode.PROTOCOL_RC_METADATA,
  ) // mode 2
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/ProtocollaAndRCMetadata?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&idSerieDoc={docSeriesId}&documentId={documentId}&numeroProtocollo={protocolNumber}&dataProtocollo={protocolDate}',
    )
    .build();

  // AZIONI DI MODIFICA PER CARD CONSERVAZIONE PASSIVO E REGISTRATE SU GESTIONALE (SOLO 5 SU 6, L'AZIONE CONFERMA NON CI VA)
  // Per card in conservazione e registrate su gestionale TODO - evo in corso - Karandip singh
  public readonly editProtocolData = new ActionBuilder(SingleActionsCode.editProtocolData, ActionCode.EDIT_PROTOCOL)
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/DatiProtocollo?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&numeroProtocollo={protocolNumber}&dataProtocollo={protocolDate}&idSerieDoc={docSeriesId}&documentId={documentId}',
    )
    .build();

  public readonly showStatusHistoryGedPost = new ActionBuilder(
    SingleActionsCode.showStatusHistoryGedPost,
    ActionCode.SHOW_STATUS_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDPOST/VisualizzaStoricoStati?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showStatusHistoryGedMail = new ActionBuilder(
    SingleActionsCode.showStatusHistoryGedMail,
    ActionCode.SHOW_STATUS_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDMAIL/VisualizzaStoricoStati?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showOutComesHistoryGedPost = new ActionBuilder(
    SingleActionsCode.showOutComesHistoryGedPost,
    ActionCode.SHOW_OUTCOMES_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDPOST/VisualizzaStoricoEsiti?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showStatusHistoryGedPec = new ActionBuilder(
    SingleActionsCode.showStatusHistoryGedPec,
    ActionCode.SHOW_STATUS_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDPEC/VisualizzaStoricoStati?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showOutComesHistoryGedPec = new ActionBuilder(
    SingleActionsCode.showOutComesHistoryGedPec,
    ActionCode.SHOW_OUTCOMES_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDPEC/VisualizzaStoricoEsiti?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showChangesHistoryGedinvoice = new ActionBuilder(
    SingleActionsCode.showChangesHistoryGedinvoice,
    ActionCode.SHOW_CHANGES_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDINVOICE/VisualizzaStoricoModifiche?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showOutcomesHistoryGedinvoice = new ActionBuilder(
    SingleActionsCode.showOutcomesHistoryGedinvoice,
    ActionCode.SHOW_OUTCOMES_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDINVOICE/VisualizzaStoricoEsiti?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showStatusHistoryGedinvoice = new ActionBuilder(
    SingleActionsCode.showStatusHistoryGedinvoice,
    ActionCode.SHOW_STATUS_HISTORY,
  )
    .setActionURL(
      '/v1/Document/GEDINVOICE/VisualizzaStoricoStati?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly showOutcomesHistoryPayable = new ActionBuilder(
    SingleActionsCode.showOutcomesHistoryPayable,
    ActionCode.SHOW_OUTCOMES_HISTORY_PAYABLE,
  )
    .setActionURL(
      '/v1/GedInvoice/LogsEsitiClienti?id={licenseId}&sia={siaCode}&progSpool={progSpool}&progBusta={progBusta}',
    )
    .build();

  public readonly multiHideDocumentsAction = new ActionBuilder(
    SingleActionsCode.multiHideDocumentsAction,
    ActionCode.HIDE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
    .build();

  public readonly multiRestoreDocumentsAction = new ActionBuilder(
    SingleActionsCode.multiRestoreDocumentsAction,
    ActionCode.RESTORE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestore?hideAndRestoreAction={hide}')
    .build();

  public readonly forwardWithCopies = new ActionBuilder(
    SingleActionsCode.forwardWithCopies,
    ActionCode.FORWARD_WITH_COPIES,
  )
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/AvanzaOriginaleConCopiaAnalogica?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly forwardWithoutCopies = new ActionBuilder(
    SingleActionsCode.forwardWithoutCopies,
    ActionCode.FORWARD_WITHOUT_COPIES,
  )
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/AvanzaOriginaleSenzaCopiaAnalogica?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly forwardWithSupplier = new ActionBuilder(
    SingleActionsCode.forwardWithSupplier,
    ActionCode.FORWARD_ADD_SUPPLIER,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/AvanzaAggiungiFornitore')
    .build();

  public readonly forwardWithoutSupplier = new ActionBuilder(
    SingleActionsCode.forwardWithoutSupplier,
    ActionCode.FORWARD_NO_SUPPLIER,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/AvanzaSenzaFornitore')
    .build();

  public readonly exportToManagement = new ActionBuilder(
    SingleActionsCode.exportToManagement,
    ActionCode.EXPORT_TO_MANAGEMENT,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/EsportaVersoGestionale')
    .build();

  public readonly editAndResendInvoice = new ActionBuilder(
    SingleActionsCode.editAndResendInvoice,
    ActionCode.EDIT_INVOICE,
  ).build();

  public readonly editAndResendInvoicePremuSign = new ActionBuilder(
    SingleActionsCode.editAndResendInvoicePremuSign,
    ActionCode.EDIT_INVOICE,
  ).build();

  public readonly editAndResendInvoicePremuError = new ActionBuilder(
    SingleActionsCode.editAndResendInvoicePremuError,
    ActionCode.EDIT_INVOICE,
  ).build();

  public readonly editInvoicePecMail = new ActionBuilder(
    SingleActionsCode.editInvoicePecMail,
    ActionCode.EDIT_INVOICE,
  ).build();

  public readonly editInvoiceWithoutCancelGedinvoice = new ActionBuilder(
    SingleActionsCode.editInvoiceWithoutCancelGedinvoice,
    ActionCode.EDIT_INVOICE_WITHOUT_CANCEL,
  ).build();

  public readonly downloadXml = new ActionBuilder(SingleActionsCode.downloadXml, ActionCode.DOWNLOAD_XML)
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=35&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=52&download=true',
    )
    .build();

  public readonly downloadXmlGedpassjoin = new ActionBuilder(
    SingleActionsCode.downloadXmlGedpassjoin,
    ActionCode.DOWNLOAD_XML,
  )
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=52&canale=0&download=true',
    )
    .build();

  public readonly pdfDownloadGedInvoice = new ActionBuilder(
    SingleActionsCode.downloadPdfGedinvoice,
    ActionCode.DOWNLOAD_PDF,
  )
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=GEDINVOICE&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownload = new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownloadDelivered = new ActionBuilder(
    SingleActionsCode.pdfDownloadDelivered,
    ActionCode.DOWNLOAD_PDF,
  )
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownloadSign = new ActionBuilder(SingleActionsCode.pdfDownloadSign, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownloadError = new ActionBuilder(SingleActionsCode.pdfDownloadError, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownloadImporting = new ActionBuilder(
    SingleActionsCode.pdfDownloadImporting,
    ActionCode.DOWNLOAD_PDF,
  )
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownloadShipping = new ActionBuilder(
    SingleActionsCode.pdfDownloadShipping,
    ActionCode.DOWNLOAD_PDF,
  )
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly pdfDownloadCanceled = new ActionBuilder(
    SingleActionsCode.pdfDownloadCanceled,
    ActionCode.DOWNLOAD_PDF,
  )
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=0&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly downloadPdfGedPost = new ActionBuilder(SingleActionsCode.downloadPdfGedPost, ActionCode.DOWNLOAD_PDF)
    // .setActionURL('/v1/Document/GEDPOST/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=true')
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=GEDPOST&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly downloadPdfGedPec = new ActionBuilder(SingleActionsCode.downloadPdfGedPec, ActionCode.DOWNLOAD_PDF)
    // .setActionURL('/v1/Document/GEDPEC/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true')
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=GEDPEC&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly downloadPdfGedMail = new ActionBuilder(SingleActionsCode.downloadPdfGedMail, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/HBFEDisplayDocument?channel=GEDMAIL&progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true',
    )
    // .setActionURL('/v1/Document/GEDMAIL/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&download=true')
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly singlePdfDownload = new ActionBuilder(SingleActionsCode.singlePdfDownload, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&docType=50&canale=0&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly downloadAttachmentsGedinvoice = new ActionBuilder(
    SingleActionsCode.downloadAttachmentsGedinvoice,
    ActionCode.DOWNLOAD_ATTACHMENTS_RECEIVABLE,
  )
    .setActionURL(
      '/v1/Document/GEDINVOICE/DownloadAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly downloadAttachmentsGedPassJoin = new ActionBuilder(
    SingleActionsCode.downloadAttachmentsGedPassJoin,
    ActionCode.DOWNLOAD_ATTACHMENTS_PAYABLE,
  )
    .setActionURL(
      '/v1/Document/GEDPASSJOIN/DownloadAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}',
    )
    .build();

  public readonly cancelElabGedInvoice = new ActionBuilder(
    SingleActionsCode.cancelElabGedInvoice,
    ActionCode.CANCEL_ELAB,
  )
    .setActionURL('/v1/Document/AnnullaElaborazione')
    .setParams({
      channel: 'GedInvoice',
      clientId: 'HUBFE',
    })
    .build();

  public readonly cancelElabGedMail = new ActionBuilder(SingleActionsCode.cancelElabGedMail, ActionCode.CANCEL_ELAB)
    .setActionURL('/v1/Document/AnnullaElaborazione')
    .setParams({
      channel: 'Gedmail',
      clientId: 'HUBFE',
    })
    .build();

  public readonly cancelElabGedPec = new ActionBuilder(SingleActionsCode.cancelElabGedPec, ActionCode.CANCEL_ELAB)
    .setActionURL('/v1/Document/AnnullaElaborazione')
    .setParams({
      channel: 'Gedpec',
      clientId: 'HUBFE',
    })
    .build();

  public readonly cancelElabGedPost = new ActionBuilder(SingleActionsCode.cancelElabGedPost, ActionCode.CANCEL_ELAB)
    .setActionURL('/v1/Document/AnnullaElaborazione')
    .setParams({
      channel: 'Gedpost',
      clientId: 'HUBFE',
    })
    .build();

  public readonly cancelElabPremuError = new ActionBuilder(
    SingleActionsCode.cancelElabPremuError,
    ActionCode.CANCEL_ELAB,
  )
    .setActionURL('/v1/Document/AnnullaElaborazione')
    .setParams({
      channel: 'Premu',
      clientId: 'HUBFE',
    })
    .build();

  public readonly cancelElabPremuSign = new ActionBuilder(SingleActionsCode.cancelElabPremuSign, ActionCode.CANCEL_ELAB)
    .setActionURL('/v1/Document/AnnullaElaborazione')
    .setParams({
      channel: 'Premu',
      clientId: 'HUBFE',
    })
    .build();

  public readonly exportToFtp = new ActionBuilder(SingleActionsCode.exportToFtp, ActionCode.EXPORT_TO_FTP)
    .setActionURL('/v1/Document/ExportToFtp')
    .build();

  public readonly downloadEsitiPdf = new ActionBuilder(
    SingleActionsCode.downloadEsitiPdf,
    ActionCode.DOWNLOAD_ESITI_PDF,
  )
    .setActionURL('/v1/Document/GEDINVOICE/DownloadAllEsitiSdi')
    .build();

  public readonly downloadEsitiXml = new ActionBuilder(
    SingleActionsCode.downloadEsitiXml,
    ActionCode.DOWNLOAD_ESITI_XML,
  )
    .setActionURL('/v1/Document/GEDINVOICE/DownloadAllEsitiSdi')
    .build();

  public readonly downloadEsitiExcel = new ActionBuilder(
    SingleActionsCode.downloadEsitiExcel,
    ActionCode.DOWNLOAD_ESITI_EXCEL,
  )
    .setActionURL('/v1/outcomes/export/excel')
    .build();

  public readonly excludeFromCons = new ActionBuilder(SingleActionsCode.excludeFromCons, ActionCode.EXCLUDE_FROM_CONS)
    .setActionURL('/v1/Document/GEDPASSJOIN/ExcludeProtocol')
    .build();

  public readonly exportToManagementFromUnpreserved = new ActionBuilder(
    SingleActionsCode.exportToManagementFromUnpreserved,
    ActionCode.EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/RevertExcludeProtocol')
    .build();

  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  viewDocument = 'viewDocument',
  signDoc = 'signDoc',
  showOriginalPdf = 'showOriginalPdf',
  sendAnalogicCopyGedinvoice = 'sendAnalogicCopyGedinvoice',
  insertProtocol = 'insertProtocol',
  confirmRegistration = 'confirmRegistration',
  insertRCFile = 'insertRCFile',
  insertRCMetadata = 'insertRCMetadata',
  insertProtocolAndRCFile = 'insertProtocolAndRCFile',
  insertProtocolAndRCMetadata = 'insertProtocolAndRCMetadata',
  editProtocolData = 'editProtocolData',
  showStatusHistoryGedPost = 'showStatusHistoryGedPost',
  showStatusHistoryGedMail = 'showStatusHistoryGedMail',
  showOutComesHistoryGedPost = 'showOutComesHistoryGedPost',
  showStatusHistoryGedPec = 'showStatusHistoryGedPec',
  showOutComesHistoryGedPec = 'showOutComesHistoryGedPec',
  showChangesHistoryGedinvoice = 'showChangesHistoryGedinvoice',
  showOutcomesHistoryGedinvoice = 'showOutcomesHistoryGedinvoice',
  showStatusHistoryGedinvoice = 'showStatusHistoryGedinvoice',
  showOutcomesHistoryPayable = 'showOutcomesHistoryPayable',
  multiHideDocumentsAction = 'multiHideDocumentsAction',
  multiRestoreDocumentsAction = 'multiRestoreDocumentsAction',
  forwardWithCopies = 'forwardWithCopies',
  forwardWithoutCopies = 'forwardWithoutCopies',
  forwardWithSupplier = 'forwardWithSupplier',
  forwardWithoutSupplier = 'forwardWithoutSupplier',
  exportToManagement = 'exportToManagement',
  editAndResendInvoice = 'editAndResendInvoice',
  editAndResendInvoicePremuSign = 'editAndResendInvoicePremuSign',
  editAndResendInvoicePremuError = 'editAndResendInvoicePremuError',
  editInvoicePecMail = 'editInvoicePecMail',
  editInvoiceWithoutCancelGedinvoice = 'editInvoiceWithoutCancelGedinvoice',
  downloadXml = 'downloadXml',
  downloadXmlGedpassjoin = 'downloadXmlGedpassjoin',
  downloadPdfGedinvoice = 'pdfDownloadGedInvoice',
  pdfDownload = 'pdfDownload',
  pdfDownloadImporting = 'pdfDownloadImporting',
  pdfDownloadDelivered = 'pdfDownloadDelivered',
  pdfDownloadShipping = 'pdfDownloadShipping',
  pdfDownloadSign = 'pdfDownloadSign',
  pdfDownloadError = 'pdfDownloadError',
  pdfDownloadCanceled = 'pdfDownloadCanceled',
  downloadPdfGedPost = 'downloadPdfGedPost',
  downloadPdfGedPec = 'downloadPdfGedPec',
  downloadPdfGedMail = 'downloadPdfGedMail',
  singlePdfDownload = 'singlePdfDownload',
  downloadAttachmentsGedinvoice = 'downloadAttachmentsGedinvoice',
  downloadAttachmentsGedPassJoin = 'downloadAttachmentsGedPassJoin',
  cancelElabGedInvoice = 'cancelElabGedInvoice',
  cancelElabGedMail = 'cancelElabGedMail',
  cancelElabGedPec = 'cancelElabGedPec',
  cancelElabGedPost = 'cancelElabGedPost',
  cancelElabPremuSign = 'cancelElabPremuSign',
  cancelElabPremuError = 'cancelElabPremuError',
  exportToFtp = 'exportToFtp',
  downloadEsitiPdf = 'downloadEsitiPdf',
  downloadEsitiXml = 'downloadEsitiXml',
  downloadEsitiExcel = 'downloadEsitiExcel',
  excludeFromCons = 'excludeFromCons',
  exportToManagementFromUnpreserved = 'exportToManagementFromUnpreserved',
}
