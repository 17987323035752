import { NotificationType } from '../notification-type';

export class ToastInfo {
  constructor(
    public type: NotificationType,
    public title: string,
    public message: string,
    public onTapCallback?: () => void,
  ) {}
}
