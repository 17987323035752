import { Injectable } from '@angular/core';
import { Documents } from 'app/entities/invoice-pa/documents-invoice-pa/documents-pa/documentPa';
import { UploadLottoMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';
import { Observable } from 'rxjs';
import { DocumentXmlPaAttachment } from '../../../../../entities/invoice-pa/documents-invoice-pa/document-xml-pa-attachment/document-xml-pa-attachment';
import { ValidationGawNotice } from '../../../../../entities/invoice-pa/validation/validation-gawnotice';
import { UploadDocumentHttpService } from './upload-document-http.service';

@Injectable()
export class UploadDocumentService {
  constructor(private uploadDocumentService: UploadDocumentHttpService) {}

  public uploadDocumentPa(
    documentPa: string,
    id,
    sia,
    grafica,
    gedInvoiceIsPresent,
    folderId?: string,
  ): Observable<UploadLottoMessage> {
    return this.uploadDocumentService.uploadDocumentPa(id, sia, grafica, documentPa, gedInvoiceIsPresent, folderId);
  }

  public validateGedNoticeDocument(documentPa: object): Observable<ValidationGawNotice[]> {
    return this.uploadDocumentService.validateGedNoticeDocument(documentPa);
  }

  public addInvoiceToDocuments(fileName, fileEx, fileBase64) {
    const fatturaPa: Documents = {};
    // create documentpa to send when uploaded with metadati
    // this.addMetadati();
    fatturaPa.documentName = fileName;
    fatturaPa.documentBytes = fileBase64;
    if (fileEx === 'xml') fatturaPa.tipoFile = 1;

    return fatturaPa;
  }

  public onXmlLoad(inputEl: HTMLInputElement): Documents {
    const document: Documents = {};
    const files = inputEl.files;
    let fileName: string;
    let fileEx: string;
    for (let i = 0; i < files.length; i++) {
      fileName = files[i].name;
      fileEx = files[i].name.split('.').pop().toLowerCase();
    }
    if (fileEx === 'pdf') document.tipoFile = 0;
    else if (fileEx === 'xml') document.tipoFile = 1;

    document.documentName = fileName;
    return document;
  }

  public onXmlPaAttachmentUpload(e: any): DocumentXmlPaAttachment {
    const document: DocumentXmlPaAttachment = {};
    const files = e.target.files;
    let fileName: string;
    let fileEx: string;
    for (let i = 0; i < files.length; i++) {
      fileName = files[i].name;
      fileEx = files[i].name.split('.').pop().toLowerCase();
    }
    document.tipoFile = fileEx;
    document.documentName = fileName;
    return document;
  }

  public sendCopiaAnalogica(
    docHash: string,
    progSpool: string,
    progBusta: string,
    documentId: string,
    documentSeries: string,
    channelToResend: number,
    metadataMatching: 'SequenceNumberMatchingKey' | 'LabelMatchingKey',
    body: any,
  ): Observable<{ success: boolean; message: string }> {
    return this.uploadDocumentService.sendCopiaAnalogica(
      docHash,
      progSpool,
      progBusta,
      documentId,
      documentSeries,
      channelToResend,
      metadataMatching,
      body,
    );
  }
}
