import { FilterStatus } from '@ctel/gaw-commons';

export interface MassiveActionRequest {
  elasticRequest: FilterStatus;
  action?: MassiveActionType | string;
  docType: DocType;
  channel?: Channel | string;
}

export enum MassiveActionType {
  // EXPORT_TO_MANAGMENT = 'EsportaVersoGestionaleMassivo',
  DOWNLOAD = 'DownloadMassivo',
  ANNULLA = 'AnnullaMassivo',
}

export enum DocType {
  ATTIVO = 'ATTIVO',
  PASSIVO = 'PASSIVO',
}

export enum Channel {
  PREMULTI = 'Premu',
  GEDINVOICE = 'GedInvoice',
  GEDPEC = 'Gedpec',
  GEDMAIL = 'Gedmail',
  GEDPOST = 'Gedpost',
  GEDPASSJOIN = 'GedPassJoin',
}
