import { FiltersResponse } from '@ctel/gaw-commons';
import { createAction } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';

export const sectionColumnsRequestedPDV = createAction(
  '[Filter Effect GAWCONS] sectionColumnsRequestedPDV',
  (filterResponse: FiltersResponse = null) => ({ payload: { filterResponse } }),
);

export const relatedSearchDataRequestedPDV = createAction(
  '[Filter Effect GAWCONS] relatedSearchDataRequestedPDV',
  (filterResponse: FiltersResponse = null, primaryConfig: Column[] = [], secondaryConfig: Column[]) => ({
    payload: {
      filterResponse,
      primaryConfig,
      secondaryConfig,
    },
  }),
);
