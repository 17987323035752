import { Component, Host, HostBinding, Inject, Input, Optional, SkipSelf, forwardRef } from '@angular/core';
import {
  AsyncValidator,
  AsyncValidatorFn,
  ControlContainer,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { CustomControlComponent } from '../custom-control.component';

@Component({
  selector: 'date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeInputComponent),
      multi: true,
    },
  ],
})
export class DateTimeInputComponent extends CustomControlComponent<Date> {
  @HostBinding('attr.id') externalId = '';

  // TODO: Se si riesce a specificare il formato, spostare date-input qui
  @Input() format = 'YYYY-MM-DDTHH:mm';

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<Validator | ValidatorFn>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<AsyncValidator | AsyncValidatorFn>,
    @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
  ) {
    super(validators, asyncValidators, controlContainer);
  }

  get id() {
    return this._id;
  }

  @Input()
  set id(value: string) {
    this._id = value;
    this.externalId = null;
  }

  onChangeUpdate() {
    super.updateChanges();
  }
}
