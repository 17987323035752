import { Injectable } from '@angular/core';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import {
  metadataPayableServicesColumns,
  metadataReceivableServicesColumns,
} from 'app/constants/metadata/metadata-columns';
import { AccountService } from '../account/account.service';
import { AccountType } from 'app/core/common/account/accountType';
import { UiConfigurationHttpService } from '../ui-configuration/ui-configuration-http.service';
import { FiltersResponse, MemoizeObservable } from '@ctel/gaw-commons';
import { Faq } from 'app/entities/help/faq';
import { faq } from 'assets/mock/faq-ui-configuration/mock-faq';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';

@Injectable({
  providedIn: 'root',
})
export class UiConfigurationService {
  constructor(
    protected uiConfigurationHttpService: UiConfigurationHttpService,
    protected accountService: AccountService,
  ) {}

  @MemoizeObservable()
  public getRelatedData(licenseId: string, siaCode: string, sectionId: string): Observable<RelatedSectionData[]> {
    return this.uiConfigurationHttpService.getRelatedSearchData(licenseId, siaCode, sectionId);
  }

  public getSectionColumns(
    licenseId: string,
    siaCode: string,
    section: string,
  ): Observable<{
    primaryConfig: Column[];
    secondaryConfig: Column[];
  }> {
    return this.uiConfigurationHttpService.getSectionColumns(licenseId, siaCode, section).pipe(
      take(1),
      map((response) => response.configurationsGroup.columns),
      map((columns) => {
        let metadataServicesColumns = [...columns];
        // taglio l'array in modo da tenere solo i primi 3 metadati
        if (columns.length >= 3) metadataServicesColumns = columns.slice(0, 3);

        // ci aggiungo i 4 metadati degli stati sui canali
        if (this.accountService.getCurrentAccount() === AccountType.RECEIVABLE)
          metadataServicesColumns = metadataServicesColumns.concat(metadataReceivableServicesColumns);
        else metadataServicesColumns = metadataServicesColumns.concat(metadataPayableServicesColumns);

        return {
          primaryConfig: columns,
          secondaryConfig: metadataServicesColumns,
        };
      }),
    );
  }

  public getFavoriteFilters(sectionCode: string, licenseId: string, siaCode: string): Observable<FiltersResponse> {
    return this.uiConfigurationHttpService.getFavoritesFilters(sectionCode, licenseId, siaCode);
  }

  public getMockFaqConfiguration(): Observable<Faq[]> {
    return this.uiConfigurationHttpService.getFaqConfiguration(faq.retrieveFaq).pipe(take(1));
  }
}
