import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { DocumentDetailsService } from '@ctel/gawpass-commons';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gaw-anomaly-details-main',
  templateUrl: './anomaly-details-main.component.html',
  styleUrls: ['./anomaly-details-main.component.scss'],
})
export class AnomalyDetailsMainComponent implements OnInit, OnDestroy {
  @Input() data: IDetailsComponent;

  documentAttachmentPec: DocumentAttachmentPdf;
  documentAttachmentMessagePec: DocumentAttachmentPdf;
  active = false;

  private destroy$ = new Subject<void>();

  constructor(private documentDetailsService: DocumentDetailsService) {}

  openPecMessagePdf() {
    this.documentAttachmentMessagePec = new DocumentAttachmentPdf(
      'MESSAGGIO',
      this.documentDetailsService.whenMessage(
        'GAWPASS',
        this.data.progSpool,
        this.data.progBusta,
        this.data.docHash,
        false,
        '53',
      ),
    );
  }

  ngOnInit() {
    this.documentDetailsService.anomalyEdit.pipe(takeUntil(this.destroy$)).subscribe((value) => (this.active = value));
    // this.route.queryParams.subscribe((paramMap) => {
    // 	this.active = paramMap.edit ? JSON.parse(paramMap.edit) : false;
    // });
    this.documentAttachmentPec = new DocumentAttachmentPdf(
      'MESSAGGIO',
      this.documentDetailsService.whenPdf(
        this.data.progSpool,
        this.data.progBusta,
        false,
        this.data.keys['idFilePathPdf'],
      ),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
