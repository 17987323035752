/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Copier } from 'app/core/common/utilities/copier';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import * as xml2js from 'xml2js';
import * as fe from '../../../../entities/invoice-pa/invoice-pa-model';
import {
  Allegati,
  DatiBeniRiepilogo,
  DatiBollo,
  DatiDdt,
  DatiDocumentiCorrelati,
  DatiGenerali,
  DatiGeneraliDocumento,
  DatiRitenuta,
  DatiSal,
  FatturaPrincipale,
} from '../../../../entities/invoice-pa/invoice-pa-model';
import { XmlParserService } from '../create-edit-invoice/shared/xml-parser/xml-parser.service';

/*
	TODO: KARANDIP SINGH - TOGLIERE CATCH ERROR SILENTI E RENDERE IL PARSER PIù SEMPLICE - 14-07-2020
 */
@Injectable()
export class FatturaElettronicaParserService {
  public attributes: fe.Attributi[];
  public edit = false;
  public resultInJson;
  public keyFatturaElettronica: string;
  private xmlDoc: Document;
  // necessario per IE
  private readonly wgxpath = require('wicked-good-xpath');
  private readonly xmlJSConverter = require('xml-js');
  private xmlDocumentError$ = new BehaviorSubject<boolean>(false);

  constructor(private parser: XmlParserService) {
    this.wgxpath.install();
  }

  loadXml(xml: string) {
    try {
      this.xmlDoc = this.parser.createDocument(xml.trim());
      const parseString = xml2js.parseString;
      // missingi mplementation
      parseString(xml, function () {
        return;
      });
      this.resultInJson = this.xmlJSConverter.xml2json(xml, { compact: true, spaces: 4 });
      this.resultInJson = JSON.parse(this.resultInJson);
      this.setXmlDocumentError(false);
    } catch {
      this.setXmlDocumentError(true);
    }
  }

  public setXmlDocumentError(value: boolean) {
    this.xmlDocumentError$.next(value);
  }

  public whenXmlDocumentError(): Observable<boolean> {
    return this.xmlDocumentError$.asObservable();
  }

  public getDatiTrasmissione(edit, versione?, codiceUfficio?, pec?, idcod?: string): fe.DatiTrasmissione {
    let IdPaese: string;
    let IdCodice: string;
    let FormatoTrasmissione: string;
    let CodiceDestinatario: string;
    let PECDestinatario: string;
    let ProgressivoInvio: string;
    if (edit) {
      const root = '//DatiTrasmissione[1]';
      const xml = this.xmlDoc;
      const rootExists = this.parser.hasNode(xml, root);
      if (!rootExists) return null;

      ProgressivoInvio = this.parser.getText(xml, `${root}/ProgressivoInvio`);
      FormatoTrasmissione = this.parser.getText(xml, `${root}/FormatoTrasmissione`);
      CodiceDestinatario = this.parser.getText(xml, `${root}/CodiceDestinatario`);
      IdPaese = this.parser.getText(xml, `${root}/IdTrasmittente/IdPaese`);
      IdCodice = this.parser.getText(xml, `${root}/IdTrasmittente/IdCodice`);
      return {
        ProgressivoInvio,
        FormatoTrasmissione,
        CodiceDestinatario,
        IdTrasmittente: {
          IdPaese,
          IdCodice,
        },
        ContattiTrasmittente: {
          Email: this.parser.getText(xml, `${root}/ContattiTrasmittente/Email`),
          Telefono: this.parser.getText(xml, `${root}/ContattiTrasmittente/Telefono`),
        },
        PECDestinatario: this.parser.getText(xml, `${root}/PECDestinatario`),
      };
    }
    ProgressivoInvio = '00001';
    FormatoTrasmissione = versione;
    CodiceDestinatario = codiceUfficio;
    IdCodice = idcod;
    IdPaese = 'IT';
    if (pec) PECDestinatario = pec;

    return {
      ProgressivoInvio,
      FormatoTrasmissione,
      PECDestinatario,
      CodiceDestinatario,
      IdTrasmittente: {
        IdPaese,
        IdCodice,
      },
    };
  }

  public getCedentePrestatore(): fe.CedentePrestatore {
    const root = '//CedentePrestatore[1]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    const datAnagrafici = this.parseDatiAnagraficiCedente(xml, `${root}/DatiAnagrafici`);
    const sede = this.parseIndirizzo(xml, `${root}/Sede`);
    const stabile = this.parseIndirizzo(xml, `${root}/StabileOrganizzazione`);
    const iscrizione = this.parseIscrizioneREA(xml, `${root}/IscrizioneREA`);
    const contatti = this.getContatti(xml, `${root}/Contatti`);
    const rifAmministrazione = this.parser.getText(xml, `${root}/RiferimentoAmministrazione`);
    return {
      DatiAnagrafici: datAnagrafici,
      Sede: sede,
      StabileOrganizzazione: stabile,
      IscrizioneREA: iscrizione,
      Contatti: contatti,
      RiferimentoAmministrazione: rifAmministrazione,
    };
  }

  public getCessionarioCommittente(): fe.CessionarioCommittente {
    const root = '//CessionarioCommittente[1]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    return {
      DatiAnagrafici: this.parseDatiAnagrafici(xml, `${root}/DatiAnagrafici`),
      Sede: this.parseIndirizzo(xml, `${root}/Sede`),
      StabileOrganizzazione: this.parseIndirizzo(xml, `${root}/StabileOrganizzazione`),
      RappresentanteFiscale: this.parseRappresentanteFiscaleCessionario(xml, `${root}/RappresentanteFiscale`),
    };
  }

  public parseRappresentanteFiscaleCessionario(xml: XMLDocument, root: string): fe.RappresentanteFiscaleCessionario {
    let rappresentateFiscaleCessionario: fe.RappresentanteFiscaleCessionario;
    if (this.parser.hasNode(xml, root)) {
      rappresentateFiscaleCessionario = {};
      rappresentateFiscaleCessionario.IdFiscaleIVA = this.parseIdFiscaleIva(xml, `${root}/IdFiscaleIVA`);
      rappresentateFiscaleCessionario.Denominazione = this.parser.getText(xml, `${root}/Denominazione`);
      rappresentateFiscaleCessionario.Nome = this.parser.getText(xml, `${root}/Nome`);
      rappresentateFiscaleCessionario.Cognome = this.parser.getText(xml, `${root}/Cognome`);
    }
    return rappresentateFiscaleCessionario;
  }

  public getRappresentanteFiscale(): fe.RappresentanteFiscale {
    const root = '//RappresentanteFiscale[1]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    return this.parseRappresentanteFiscale(xml, root);
  }

  public getTerzoIntermediario(): fe.RappresentanteFiscale {
    const root = '//TerzoIntermediarioOSoggettoEmittente[1]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    return this.parseTerzoIntermediario(xml, root);
  }

  public getSoggettoEmittente(): string {
    const root = '//SoggettoEmittente[1]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    return this.parser.getText(xml, root);
  }

  private parseCessionarioCommittente(xml: XMLDocument, root: string): fe.CessionarioCommittente {
    return {
      DatiAnagrafici: this.parseDatiAnagrafici(xml, `${root}/DatiAnagrafici`),
      Sede: this.parseIndirizzo(xml, `${root}/Sede`),
      StabileOrganizzazione: this.parseIndirizzo(xml, `${root}/StabileOrganizzazione`),
      RappresentanteFiscale: this.parseRappresentanteFiscale(xml, `${root}/RappresentanteFiscale`),
    } as fe.CessionarioCommittente;
  }

  private parseRappresentanteFiscale(xml: XMLDocument, root: string): fe.RappresentanteFiscale {
    return {
      DatiAnagrafici: this.parseDatiAnagrafici(xml, `${root}/DatiAnagrafici`),
    } as fe.RappresentanteFiscale;
  }

  private parseTerzoIntermediario(xml: XMLDocument, root: string): fe.TerzoIntermediario {
    return {
      DatiAnagrafici: this.parseDatiAnagrafici(xml, `${root}/DatiAnagrafici`),
    } as fe.TerzoIntermediario;
  }

  private parseIndirizzo(xml: XMLDocument, root: string): fe.Indirizzo {
    let Indirizzo: fe.Indirizzo;
    if (this.parser.hasNode(xml, root)) {
      Indirizzo = {};
      Indirizzo.Indirizzo = this.parser.getText(xml, `${root}/Indirizzo`);
      Indirizzo.NumeroCivico = this.parser.getText(xml, `${root}/NumeroCivico`);
      Indirizzo.CAP = this.parser.getText(xml, `${root}/CAP`);
      Indirizzo.Comune = this.parser.getText(xml, `${root}/Comune`);
      Indirizzo.Provincia = this.parser.getText(xml, `${root}/Provincia`);
      Indirizzo.Nazione = this.parser.getText(xml, `${root}/Nazione`);
    }
    return Indirizzo;
  }

  private parseDatiAnagrafici(xml: XMLDocument, root: string): fe.DatiAnagrafici {
    let DatiAnagrafici: fe.DatiAnagrafici;
    if (this.parser.hasNode(xml, root)) {
      DatiAnagrafici = {};
      DatiAnagrafici.IdFiscaleIVA = this.parseIdFiscaleIva(xml, `${root}/IdFiscaleIVA`);
      DatiAnagrafici.CodiceFiscale = this.parser.getText(xml, `${root}/CodiceFiscale`);
      DatiAnagrafici.Anagrafica = this.parseAnagrafica(xml, `${root}/Anagrafica`);
    }
    return DatiAnagrafici;
  }

  private parseDatiAnagraficiCedente(xml: XMLDocument, root: string): fe.DatiAnagraficiCedente {
    const datiAnagrafici: fe.DatiAnagraficiCedente = this.parseDatiAnagrafici(xml, root);
    if (!_.isNil(datiAnagrafici)) {
      datiAnagrafici.AlboProfessionale = this.parser.getText(xml, `${root}/AlboProfessionale`);
      datiAnagrafici.ProvinciaAlbo = this.parser.getText(xml, `${root}/ProvinciaAlbo`);
      datiAnagrafici.NumeroIscrizioneAlbo = this.parser.getText(xml, `${root}/NumeroIscrizioneAlbo`);
      datiAnagrafici.DataIscrizioneAlbo = this.parser.getText(xml, `${root}/DataIscrizioneAlbo`);
      datiAnagrafici.RegimeFiscale = this.parser.getText(xml, `${root}/RegimeFiscale`);
    }
    return datiAnagrafici;
  }

  private parseAnagrafica(xml: XMLDocument, root: string): fe.Anagrafica {
    let anagrafica: fe.Anagrafica;
    if (this.parser.hasNode(xml, root)) {
      anagrafica = {};
      anagrafica.Denominazione = this.parser.getText(xml, `${root}/Denominazione`);
      anagrafica.Nome = this.parser.getText(xml, `${root}/Nome`);
      anagrafica.Cognome = this.parser.getText(xml, `${root}/Cognome`);
      anagrafica.Titolo = this.parser.getText(xml, `${root}/Titolo`);
      anagrafica.CodEORI = this.parser.getText(xml, `${root}/CodEORI`);
    }
    return anagrafica;
  }

  private parseIdFiscaleIva(xml: XMLDocument, root: string): fe.IdFiscaleIVA {
    let IdFiscaleIVA: fe.IdFiscaleIVA;
    if (this.parser.hasNode(xml, root)) {
      const IdPaese = this.parser.getText(xml, `${root}/IdPaese`);
      const IdCodice = this.parser.getText(xml, `${root}/IdCodice`);
      IdFiscaleIVA = { IdPaese, IdCodice };
    }
    return IdFiscaleIVA;
  }

  private parseIscrizioneREA(xml: XMLDocument, root: string): fe.IscrizioneREA {
    let iscrizioneREA: fe.IscrizioneREA;
    if (this.parser.hasNode(xml, root)) {
      iscrizioneREA = {};
      iscrizioneREA.Ufficio = this.parser.getText(xml, `${root}/Ufficio`);
      iscrizioneREA.NumeroREA = this.parser.getText(xml, `${root}/NumeroREA`);
      const capitaleSociale = this.parser.getText(xml, `${root}/CapitaleSociale`);
      iscrizioneREA.CapitaleSociale = capitaleSociale ? capitaleSociale.trim() : capitaleSociale;
      iscrizioneREA.SocioUnico = this.parser.getText(xml, `${root}/SocioUnico`);
      iscrizioneREA.StatoLiquidazione = this.parser.getText(xml, `${root}/StatoLiquidazione`);
    }
    return iscrizioneREA;
  }

  private getContatti(xml: XMLDocument, root: string): fe.Contatti {
    let contatti: fe.Contatti;
    if (this.parser.hasNode(xml, root)) {
      contatti = {};
      contatti.Email = this.parser.getText(xml, `${root}/Email`);
      contatti.Telefono = this.parser.getText(xml, `${root}/Telefono`);
      contatti.Fax = this.parser.getText(xml, `${root}/Fax`);
    }
    return contatti;
  }

  /*
	+	FATTURA BODY
	+
	*/

  public getDatiGenerali(): fe.DatiGenerali {
    // get keys of json object
    this.getKeys();
    const root = this.resultInJson[this.keyFatturaElettronica].FatturaElettronicaBody;
    if (root.DatiGenerali) {
      // parse dati generali
      const datiGeneraliDocumento = this.parseDatiGeneraliDocumento(root);
      const datiOrdineAcquisto = this.parseDatiCorrelati(root.DatiGenerali, 'DatiOrdineAcquisto');
      const datiContratto = this.parseDatiCorrelati(root.DatiGenerali, 'DatiContratto');
      const datiConvenzione = this.parseDatiCorrelati(root.DatiGenerali, 'DatiConvenzione');
      const datiRicezione = this.parseDatiCorrelati(root.DatiGenerali, 'DatiRicezione');
      const datiFattureCollegate = this.parseDatiCorrelati(root.DatiGenerali, 'DatiFattureCollegate');
      const datiSal = this.parseDatiSal(root.DatiGenerali);
      const datiDdt = this.parseDatiDdt(root.DatiGenerali);
      // Todo fare parsing tutto con xml2JS
      const datiTrasporto = this.parseDatiTrasporto(this.xmlDoc, '//DatiGenerali[1]/DatiTrasporto');
      const fatturaPrincipale = this.parseFatturaPrincipale(root);
      return {
        DatiGeneraliDocumento: datiGeneraliDocumento,
        DatiOrdineAcquisto: datiOrdineAcquisto,
        DatiContratto: datiContratto,
        DatiConvenzione: datiConvenzione,
        DatiRicezione: datiRicezione,
        DatiFattureCollegate: datiFattureCollegate,
        DatiSAL: datiSal,
        DatiDDT: datiDdt,
        DatiTrasporto: datiTrasporto,
        FatturaPrincipale: fatturaPrincipale,
      };
    } else return null;
  }

  /*
		PARSING DATI GENERALI
	 */

  private parseDatiGeneraliDocumento(root): DatiGeneraliDocumento {
    const datiGeneraliDocumento: DatiGeneraliDocumento = {};
    if (root.DatiGenerali.DatiGeneraliDocumento) {
      const object = root.DatiGenerali.DatiGeneraliDocumento;
      if (object.TipoDocumento) {
        const keys = Object.keys(object.TipoDocumento);
        datiGeneraliDocumento.TipoDocumento = object.TipoDocumento[keys[0]];
      }
      if (object.Divisa) {
        const keys = Object.keys(object.Divisa);
        datiGeneraliDocumento.Divisa = object.Divisa[keys[0]];
      }
      if (object.Data) {
        const keys = Object.keys(object.Data);
        datiGeneraliDocumento.Data = object.Data[keys[0]];
      }
      if (object.Numero) {
        const keys = Object.keys(object.Numero);
        datiGeneraliDocumento.Numero = object.Numero[keys[0]];
      }
      if (object.ImportoTotaleDocumento) {
        const keys = Object.keys(object.ImportoTotaleDocumento);
        try {
          datiGeneraliDocumento.ImportoTotaleDocumento = object.ImportoTotaleDocumento[keys[0]].trim();
        } catch (e) {}
      }
      if (object.Arrotondamento) {
        const keys = Object.keys(object.Arrotondamento);
        try {
          datiGeneraliDocumento.Arrotondamento = object.Arrotondamento[keys[0]].trim();
        } catch (e) {}
      }
      if (object.Art73) {
        const keys = Object.keys(object.Art73);
        datiGeneraliDocumento.Art73 = object.Art73[keys[0]];
      }
      // dati ritenuta
      if (object.DatiRitenuta) datiGeneraliDocumento.DatiRitenuta = this.parseDatiRitenuta(object);

      // dati bollo
      if (object.DatiBollo) datiGeneraliDocumento.DatiBollo = this.parseDatiBolloTest(object);

      // sconto maggiorazione
      if (object.ScontoMaggiorazione)
        datiGeneraliDocumento.ScontoMaggiorazione = this.parseScontoMaggiorazioneTest(object);

      // Dati cassa previdenziale
      if (object.DatiCassaPrevidenziale)
        datiGeneraliDocumento.DatiCassaPrevidenziale = this.parseDatiCassaPrevidenzialeTest(object);

      // causale
      if (object.Causale) {
        const causali: string[] = [];
        if (object.Causale.length)
          for (let i = 0; i < object.Causale.length; i++) {
            const keys = Object.keys(object.Causale[i]);
            const causale = object.Causale[i][keys[0]];
            causali.push(causale);
          }
        else {
          const keys = Object.keys(object.Causale);
          const causale = object.Causale[keys[0]];
          causali.push(causale);
        }
        datiGeneraliDocumento.Causale = causali;
      }
    }
    return datiGeneraliDocumento;
  }

  private parseDatiBolloTest(obj: DatiGeneraliDocumento): DatiBollo {
    const datiBollo: fe.DatiBollo = {};
    if (obj.DatiBollo.BolloVirtuale) {
      const keys = Object.keys(obj.DatiBollo.BolloVirtuale);
      datiBollo.BolloVirtuale = obj.DatiBollo.BolloVirtuale[keys[0]];
    }
    if (obj.DatiBollo.ImportoBollo) {
      const keys = Object.keys(obj.DatiBollo.ImportoBollo);
      try {
        datiBollo.ImportoBollo = obj.DatiBollo.ImportoBollo[keys[0]].trim();
      } catch (e) {}
    }
    return datiBollo;
  }

  private parseDatiRitenuta(obj): DatiRitenuta[] {
    const datiRitenuta: DatiRitenuta[] = [];
    let singleDatiRitenuta: DatiRitenuta = {};
    if (obj.DatiRitenuta.length)
      for (let i = 0; i < obj.DatiRitenuta.length; i++) {
        if (obj.DatiRitenuta[i].TipoRitenuta) {
          const keys = Object.keys(obj.DatiRitenuta[i].TipoRitenuta);
          try {
            singleDatiRitenuta.TipoRitenuta = obj.DatiRitenuta[i].TipoRitenuta[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.DatiRitenuta[i].CausalePagamento) {
          const keys = Object.keys(obj.DatiRitenuta[i].CausalePagamento);
          try {
            singleDatiRitenuta.CausalePagamento = obj.DatiRitenuta[i].CausalePagamento[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.DatiRitenuta[i].AliquotaRitenuta) {
          const keys = Object.keys(obj.DatiRitenuta[i].AliquotaRitenuta);
          singleDatiRitenuta.AliquotaRitenuta = obj.DatiRitenuta[i].AliquotaRitenuta[keys[0]];
        }
        if (obj.DatiRitenuta[i].Tipo) {
          const keys = Object.keys(obj.DatiRitenuta[i].ImportoRitenuta);
          singleDatiRitenuta.ImportoRitenuta = obj.DatiRitenuta[i].ImportoRitenuta[keys[0]];
        }

        datiRitenuta.push(singleDatiRitenuta);
        singleDatiRitenuta = {};
      }
    else {
      if (obj.DatiRitenuta.TipoRitenuta) {
        const keys = Object.keys(obj.DatiRitenuta.TipoRitenuta);
        try {
          singleDatiRitenuta.TipoRitenuta = obj.DatiRitenuta.TipoRitenuta[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.DatiRitenuta.CausalePagamento) {
        const keys = Object.keys(obj.DatiRitenuta.CausalePagamento);
        try {
          singleDatiRitenuta.CausalePagamento = obj.DatiRitenuta.CausalePagamento[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.DatiRitenuta.AliquotaRitenuta) {
        const keys = Object.keys(obj.DatiRitenuta.AliquotaRitenuta);
        singleDatiRitenuta.AliquotaRitenuta = obj.DatiRitenuta.AliquotaRitenuta[keys[0]];
      }
      if (obj.DatiRitenuta.Tipo) {
        const keys = Object.keys(obj.DatiRitenuta.ImportoRitenuta);
        singleDatiRitenuta.ImportoRitenuta = obj.DatiRitenuta.ImportoRitenuta[keys[0]];
      }
      datiRitenuta.push(singleDatiRitenuta);
      singleDatiRitenuta = {};
    }
    return datiRitenuta;
  }

  private parseScontoMaggiorazioneTest(obj): fe.ScontoMaggiorazione[] {
    const scontoMaggiorazione: fe.ScontoMaggiorazione[] = [];
    let scontoSingolo: fe.ScontoMaggiorazione = {};
    if (obj.ScontoMaggiorazione.length)
      for (let i = 0; i < obj.ScontoMaggiorazione.length; i++) {
        if (obj.ScontoMaggiorazione[i].Importo) {
          const keys = Object.keys(obj.ScontoMaggiorazione[i].Importo);
          try {
            scontoSingolo.Importo = obj.ScontoMaggiorazione[i].Importo[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.ScontoMaggiorazione[i].Percentuale) {
          const keys = Object.keys(obj.ScontoMaggiorazione[i].Percentuale);
          try {
            scontoSingolo.Percentuale = obj.ScontoMaggiorazione[i].Percentuale[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.ScontoMaggiorazione[i].Tipo) {
          const keys = Object.keys(obj.ScontoMaggiorazione[i].Tipo);
          scontoSingolo.Tipo = obj.ScontoMaggiorazione[i].Tipo[keys[0]];
        }
        scontoMaggiorazione.push(scontoSingolo);
        scontoSingolo = {};
      }
    else {
      if (obj.ScontoMaggiorazione.Importo) {
        const keys = Object.keys(obj.ScontoMaggiorazione.Importo);
        try {
          scontoSingolo.Importo = obj.ScontoMaggiorazione.Importo[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.ScontoMaggiorazione.Percentuale) {
        const keys = Object.keys(obj.ScontoMaggiorazione.Percentuale);
        try {
          scontoSingolo.Percentuale = obj.ScontoMaggiorazione.Percentuale[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.ScontoMaggiorazione.Tipo) {
        const keys = Object.keys(obj.ScontoMaggiorazione.Tipo);
        scontoSingolo.Tipo = obj.ScontoMaggiorazione.Tipo[keys[0]];
      }
      scontoMaggiorazione.push(scontoSingolo);
      scontoSingolo = {};
    }
    return scontoMaggiorazione;
  }

  private parseDatiCassaPrevidenzialeTest(obj): fe.DatiCassaPrevidenziale[] {
    const datiCassaPrevidenziale: fe.DatiCassaPrevidenziale[] = [];
    let datoSingolo: fe.DatiCassaPrevidenziale = {};
    if (obj.DatiCassaPrevidenziale.length)
      for (let i = 0; i < obj.DatiCassaPrevidenziale.length; i++) {
        if (obj.DatiCassaPrevidenziale[i].TipoCassa) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].TipoCassa);
          datoSingolo.TipoCassa = obj.DatiCassaPrevidenziale[i].TipoCassa[keys[0]];
        }
        if (obj.DatiCassaPrevidenziale[i].AlCassa) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].AlCassa);
          try {
            datoSingolo.AlCassa = obj.DatiCassaPrevidenziale[i].AlCassa[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.DatiCassaPrevidenziale[i].ImportoContributoCassa) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].ImportoContributoCassa);
          try {
            datoSingolo.ImportoContributoCassa = obj.DatiCassaPrevidenziale[i].ImportoContributoCassa[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.DatiCassaPrevidenziale[i].AliquotaIVA) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].AliquotaIVA);
          try {
            datoSingolo.AliquotaIVA = obj.DatiCassaPrevidenziale[i].AliquotaIVA[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.DatiCassaPrevidenziale[i].Natura) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].Natura);
          datoSingolo.Natura = obj.DatiCassaPrevidenziale[i].Natura[keys[0]];
        }
        if (obj.DatiCassaPrevidenziale[i].ImponibileCassa) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].ImponibileCassa);
          try {
            datoSingolo.ImponibileCassa = obj.DatiCassaPrevidenziale[i].ImponibileCassa[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.DatiCassaPrevidenziale[i].Ritenuta) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].Ritenuta);
          datoSingolo.Ritenuta = obj.DatiCassaPrevidenziale[i].Ritenuta[keys[0]];
        }
        if (obj.DatiCassaPrevidenziale[i].RifAmministrazione) {
          const keys = Object.keys(obj.DatiCassaPrevidenziale[i].RifAmministrazione);
          datoSingolo.RifAmministrazione = obj.DatiCassaPrevidenziale[i].RifAmministrazione[keys[0]];
        }
        datiCassaPrevidenziale.push(datoSingolo);
        datoSingolo = {};
      }
    else {
      if (obj.DatiCassaPrevidenziale.TipoCassa) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.TipoCassa);
        datoSingolo.TipoCassa = obj.DatiCassaPrevidenziale.TipoCassa[keys[0]];
      }
      if (obj.DatiCassaPrevidenziale.AlCassa) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.AlCassa);
        try {
          datoSingolo.AlCassa = obj.DatiCassaPrevidenziale.AlCassa[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.DatiCassaPrevidenziale.ImportoContributoCassa) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.ImportoContributoCassa);
        try {
          datoSingolo.ImportoContributoCassa = obj.DatiCassaPrevidenziale.ImportoContributoCassa[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.DatiCassaPrevidenziale.AliquotaIVA) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.AliquotaIVA);
        try {
          datoSingolo.AliquotaIVA = obj.DatiCassaPrevidenziale.AliquotaIVA[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.DatiCassaPrevidenziale.Natura) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.Natura);
        datoSingolo.Natura = obj.DatiCassaPrevidenziale.Natura[keys[0]];
      }
      if (obj.DatiCassaPrevidenziale.ImponibileCassa) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.ImponibileCassa);
        try {
          datoSingolo.ImponibileCassa = obj.DatiCassaPrevidenziale.ImponibileCassa[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.DatiCassaPrevidenziale.Ritenuta) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.Ritenuta);
        datoSingolo.Ritenuta = obj.DatiCassaPrevidenziale.Ritenuta[keys[0]];
      }
      if (obj.DatiCassaPrevidenziale.RifAmministrazione) {
        const keys = Object.keys(obj.DatiCassaPrevidenziale.RifAmministrazione);
        datoSingolo.RifAmministrazione = obj.DatiCassaPrevidenziale.RifAmministrazione[keys[0]];
      }
      datiCassaPrevidenziale.push(datoSingolo);
      datoSingolo = {};
    }
    return datiCassaPrevidenziale;
  }

  /*
		PARSING DATI CORRELATI
	 */
  private parseDatiCorrelati(root: DatiGenerali, datoCorrelato) {
    const arrayDatiCorrelati: fe.DatiDocumentiCorrelati[] = [{}];
    // parse solo se dat correlati è maggiore di 1
    if (root[datoCorrelato]) {
      const object = root[datoCorrelato];
      if (root[datoCorrelato].length)
        for (let i = 0; i < object.length; i++) arrayDatiCorrelati[i] = this.parseSingleDatoCorrelato(object[i]);
      else arrayDatiCorrelati[0] = this.parseSingleDatoCorrelato(object);
    }
    return arrayDatiCorrelati;
  }

  private parseSingleDatoCorrelato(obj): DatiDocumentiCorrelati {
    const datoCorrelato: fe.DatiDocumentiCorrelati = {};
    if (obj.CodiceCIG) {
      const keys = Object.keys(obj.CodiceCIG);
      datoCorrelato.CodiceCIG = obj.CodiceCIG[keys[0]];
    }
    if (obj.RiferimentoNumeroLinea) {
      const riferimentiNumeroLinee: string[] = [];
      if (obj.RiferimentoNumeroLinea.length)
        for (let i = 0; i < obj.RiferimentoNumeroLinea.length; i++) {
          const keys = Object.keys(obj.RiferimentoNumeroLinea[i]);
          riferimentiNumeroLinee.push(obj.RiferimentoNumeroLinea[i][keys[0]]);
        }
      else {
        const keys = Object.keys(obj.RiferimentoNumeroLinea);
        riferimentiNumeroLinee.push(obj.RiferimentoNumeroLinea[keys[0]]);
      }
      datoCorrelato.RiferimentoNumeroLinea = riferimentiNumeroLinee;
    }
    if (obj.IdDocumento) {
      const keys = Object.keys(obj.IdDocumento);
      datoCorrelato.IdDocumento = obj.IdDocumento[keys[0]];
    }
    if (obj.Data) {
      const keys = Object.keys(obj.Data);
      datoCorrelato.Data = obj.Data[keys[0]];
    }
    if (obj.NumItem) {
      const keys = Object.keys(obj.NumItem);
      datoCorrelato.NumItem = obj.NumItem[keys[0]];
    }
    if (obj.CodiceCommessaConvenzione) {
      const keys = Object.keys(obj.CodiceCommessaConvenzione);
      datoCorrelato.CodiceCommessaConvenzione = obj.CodiceCommessaConvenzione[keys[0]];
    }
    if (obj.CodiceCUP) {
      const keys = Object.keys(obj.CodiceCUP);
      datoCorrelato.CodiceCUP = obj.CodiceCUP[keys[0]];
    }

    return datoCorrelato;
  }

  /*
		PARSING DATI SAL/DDT
	 */
  private parseDatiSal(root): DatiSal[] {
    const arrayDatiSal: DatiSal[] = [{}];
    // parse solo se dat correlati è maggiore di 1
    if (root.DatiSAL) {
      const object = root.DatiSAL;
      if (root.DatiSAL.length)
        for (let i = 0; i < object.length; i++) arrayDatiSal[i] = this.parseSingleDatoSal(object[i]);
      else arrayDatiSal[0] = this.parseSingleDatoSal(object);
    }
    return arrayDatiSal;
  }

  private parseSingleDatoSal(obj) {
    const datoSal: DatiSal = {};
    if (obj.RiferimentoFase) {
      const keys = Object.keys(obj.RiferimentoFase);
      datoSal.RiferimentoFase = obj.RiferimentoFase[keys[0]];
    }
    return datoSal;
  }

  private parseDatiDdt(root: DatiGenerali): DatiDdt[] {
    const arrayDatiDdt: DatiDdt[] = [{}];
    // parse solo se dat correlati è maggiore di 1
    if (root.DatiDDT) {
      const object = root.DatiDDT;
      if (root.DatiDDT.length)
        for (let i = 0; i < object.length; i++) arrayDatiDdt[i] = this.parseSingleDatoDdt(object[i]);
      else arrayDatiDdt[0] = this.parseSingleDatoDdt(object);
    }
    return arrayDatiDdt;
  }

  private parseSingleDatoDdt(obj) {
    const datoDdt: DatiDdt = {};
    if (obj.NumeroDDT) {
      const keys = Object.keys(obj.NumeroDDT);
      datoDdt.NumeroDDT = obj.NumeroDDT[keys[0]];
    }
    if (obj.RiferimentoNumeroLinea) {
      const riferimentiNumeroLinee: string[] = [];
      if (obj.RiferimentoNumeroLinea.length)
        for (let i = 0; i < obj.RiferimentoNumeroLinea.length; i++) {
          const keys = Object.keys(obj.RiferimentoNumeroLinea[i]);
          riferimentiNumeroLinee.push(obj.RiferimentoNumeroLinea[i][keys[0]]);
        }
      else {
        const keys = Object.keys(obj.RiferimentoNumeroLinea);
        riferimentiNumeroLinee.push(obj.RiferimentoNumeroLinea[keys[0]]);
      }
      datoDdt.RiferimentoNumeroLinea = riferimentiNumeroLinee;
    }
    if (obj.DataDDT) {
      const keys = Object.keys(obj.DataDDT);
      datoDdt.DataDDT = obj.DataDDT[keys[0]];
    }

    return datoDdt;
  }

  /*
		PARSE DATI FATTURA PRINCIPALE
	 */
  private parseFatturaPrincipale(root): FatturaPrincipale {
    const fatturaPrincipale: FatturaPrincipale = {};
    if (root.DatiGenerali.FatturaPrincipale) {
      const object = root.DatiGenerali.FatturaPrincipale;
      if (object.NumeroFatturaPrincipale) {
        const keys = Object.keys(object.NumeroFatturaPrincipale);
        fatturaPrincipale.NumeroFatturaPrincipale = object.NumeroFatturaPrincipale[keys[0]];
      }
      if (object.DataFatturaPrincipale) {
        const keys = Object.keys(object.DataFatturaPrincipale);
        fatturaPrincipale.DataFatturaPrincipale = object.DataFatturaPrincipale[keys[0]];
      }
    }
    return fatturaPrincipale;
  }

  /* DATI BENI SERVIZI */
  private getKeys() {
    const match = new RegExp(/FatturaElettronica/);
    for (const k in this.resultInJson) if (k.match(match)) this.keyFatturaElettronica = k;
  }

  public getDatiBeniServizi(): fe.DatiBeniServizi {
    // get keys of json object
    this.getKeys();
    const root = this.resultInJson[this.keyFatturaElettronica].FatturaElettronicaBody;
    if (root.DatiBeniServizi) {
      // parse dettaglio linee
      const arrayDl = this.parseDettaglioLinee(root);
      // parse dati ripilogo
      const arrayDr = this.parseDatiRiepilogo(root);
      return {
        DettaglioLinee: arrayDl,
        DatiRiepilogo: arrayDr,
      };
    } else return null;
  }

  private parseDettaglioLinee(root): fe.DettaglioLinee[] {
    const arrayDl: fe.DettaglioLinee[] = [{}];
    // parse solo se dettaglio linne è maggiore di 1
    if (root.DatiBeniServizi.DettaglioLinee) {
      const object = root.DatiBeniServizi.DettaglioLinee;
      if (root.DatiBeniServizi.DettaglioLinee.length)
        for (let i = 0; i < object.length; i++) arrayDl[i] = this.parseSingleDettaglioLinee(object[i]);
      else arrayDl[0] = this.parseSingleDettaglioLinee(object);
    }
    return arrayDl;
  }

  private parseSingleDettaglioLinee(obj) {
    const dettaglioLinea: fe.DettaglioLinee = {};
    if (obj.NumeroLinea) {
      const keys = Object.keys(obj.NumeroLinea);
      try {
        dettaglioLinea.NumeroLinea = obj.NumeroLinea[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.PrezzoTotale) {
      const keys = Object.keys(obj.PrezzoTotale);
      try {
        dettaglioLinea.PrezzoTotale = obj.PrezzoTotale[keys[0]].trim();
      } catch {}
    }
    if (obj.PrezzoUnitario) {
      const keys = Object.keys(obj.PrezzoUnitario);
      try {
        dettaglioLinea.PrezzoUnitario = obj.PrezzoUnitario[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.DataFinePeriodo) {
      const keys = Object.keys(obj.DataFinePeriodo);
      dettaglioLinea.DataFinePeriodo = obj.DataFinePeriodo[keys[0]];
    }
    if (obj.TipoCessionePrestazione) {
      const keys = Object.keys(obj.TipoCessionePrestazione);
      dettaglioLinea.TipoCessionePrestazione = obj.TipoCessionePrestazione[keys[0]];
    }
    if (obj.Descrizione) {
      const keys = Object.keys(obj.Descrizione);
      dettaglioLinea.Descrizione = obj.Descrizione[keys[0]];
    }
    if (obj.Quantita) {
      const keys = Object.keys(obj.Quantita);
      try {
        dettaglioLinea.Quantita = obj.Quantita[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.UnitaMisura) {
      const keys = Object.keys(obj.UnitaMisura);
      dettaglioLinea.UnitaMisura = obj.UnitaMisura[keys[0]];
    }
    if (obj.DataInizioPeriodo) {
      const keys = Object.keys(obj.DataInizioPeriodo);
      dettaglioLinea.DataInizioPeriodo = obj.DataInizioPeriodo[keys[0]];
    }
    if (obj.AliquotaIVA && !_.isEmpty(obj.AliquotaIVA)) {
      const keys = Object.keys(obj.AliquotaIVA);
      try {
        dettaglioLinea.AliquotaIVA = obj.AliquotaIVA[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.Ritenuta) {
      const keys = Object.keys(obj.Ritenuta);
      dettaglioLinea.Ritenuta = obj.Ritenuta[keys[0]];
    }
    if (obj.Natura) {
      const keys = Object.keys(obj.Natura);
      dettaglioLinea.Natura = obj.Natura[keys[0]];
    }
    if (obj.RiferimentoAmministrazione) {
      const keys = Object.keys(obj.RiferimentoAmministrazione);
      dettaglioLinea.RiferimentoAmministrazione = obj.RiferimentoAmministrazione[keys[0]];
    }
    // codice articolo
    if (obj.CodiceArticolo) dettaglioLinea.CodiceArticolo = this.parseCodiceArticolo(obj);

    // sconto maggiorazione
    if (obj.ScontoMaggiorazione) dettaglioLinea.ScontoMaggiorazione = this.parseScontoMaggiorazione(obj);

    // altri dati gestionali
    if (obj.AltriDatiGestionali) dettaglioLinea.AltriDatiGestionali = this.parseAltriDatiGestionali(obj);

    return dettaglioLinea;
  }

  private parseCodiceArticolo(obj) {
    const codiceArticoli: fe.CodiceArticolo[] = [];
    let codiceArticolo: fe.CodiceArticolo = {};
    if (obj.CodiceArticolo.length)
      for (let i = 0; i < obj.CodiceArticolo.length; i++) {
        if (obj.CodiceArticolo[i].CodiceTipo) {
          const keys = Object.keys(obj.CodiceArticolo[i].CodiceTipo);
          codiceArticolo.CodiceTipo = obj.CodiceArticolo[i].CodiceTipo[keys[0]];
        }
        if (obj.CodiceArticolo[i].CodiceValore) {
          const keys = Object.keys(obj.CodiceArticolo[i].CodiceValore);
          codiceArticolo.CodiceValore = obj.CodiceArticolo[i].CodiceValore[keys[0]];
        }
        codiceArticoli.push(codiceArticolo);
        codiceArticolo = {};
      }
    else {
      if (obj.CodiceArticolo.CodiceTipo) {
        const keys = Object.keys(obj.CodiceArticolo.CodiceTipo);
        codiceArticolo.CodiceTipo = obj.CodiceArticolo.CodiceTipo[keys[0]];
      }
      if (obj.CodiceArticolo.CodiceValore) {
        const keys = Object.keys(obj.CodiceArticolo.CodiceValore);
        codiceArticolo.CodiceValore = obj.CodiceArticolo.CodiceValore[keys[0]];
      }
      codiceArticoli.push(codiceArticolo);
      codiceArticolo = {};
    }
    return codiceArticoli;
  }

  private parseScontoMaggiorazione(obj) {
    const scontoMaggiorazione: fe.ScontoMaggiorazione[] = [];
    let sconto: fe.ScontoMaggiorazione = {};
    if (obj.ScontoMaggiorazione.length)
      for (let i = 0; i < obj.ScontoMaggiorazione.length; i++) {
        if (obj.ScontoMaggiorazione[i].Importo) {
          const keys = Object.keys(obj.ScontoMaggiorazione[i].Importo);
          sconto.Importo = obj.ScontoMaggiorazione[i].Importo[keys[0]].trim();
        }
        if (obj.ScontoMaggiorazione[i].Percentuale) {
          const keys = Object.keys(obj.ScontoMaggiorazione[i].Percentuale);
          try {
            sconto.Percentuale = obj.ScontoMaggiorazione[i].Percentuale[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.ScontoMaggiorazione[i].Tipo) {
          const keys = Object.keys(obj.ScontoMaggiorazione[i].Tipo);
          sconto.Tipo = obj.ScontoMaggiorazione[i].Tipo[keys[0]];
        }
        scontoMaggiorazione.push(sconto);
        sconto = {};
      }
    else {
      if (obj.ScontoMaggiorazione.Importo) {
        const keys = Object.keys(obj.ScontoMaggiorazione.Importo);
        try {
          sconto.Importo = obj.ScontoMaggiorazione.Importo[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.ScontoMaggiorazione.Percentuale) {
        const keys = Object.keys(obj.ScontoMaggiorazione.Percentuale);
        try {
          sconto.Percentuale = obj.ScontoMaggiorazione.Percentuale[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.ScontoMaggiorazione.Tipo) {
        const keys = Object.keys(obj.ScontoMaggiorazione.Tipo);
        sconto.Tipo = obj.ScontoMaggiorazione.Tipo[keys[0]];
      }
      scontoMaggiorazione.push(sconto);
      sconto = {};
    }
    return scontoMaggiorazione;
  }

  private parseAltriDatiGestionali(obj) {
    const altriDatiGestionali: fe.AltriDatiGestionali[] = [];
    let datoGestionale: fe.AltriDatiGestionali = {};
    if (obj.AltriDatiGestionali.length)
      for (let i = 0; i < obj.AltriDatiGestionali.length; i++) {
        if (obj.AltriDatiGestionali[i].TipoDato) {
          const keys = Object.keys(obj.AltriDatiGestionali[i].TipoDato);
          datoGestionale.TipoDato = obj.AltriDatiGestionali[i].TipoDato[keys[0]];
        }
        if (obj.AltriDatiGestionali[i].RiferimentoData) {
          const keys = Object.keys(obj.AltriDatiGestionali[i].RiferimentoData);
          datoGestionale.RiferimentoData = obj.AltriDatiGestionali[i].RiferimentoData[keys[0]];
        }
        if (obj.AltriDatiGestionali[i].RiferimentoNumero) {
          const keys = Object.keys(obj.AltriDatiGestionali[i].RiferimentoNumero);
          try {
            datoGestionale.RiferimentoNumero = obj.AltriDatiGestionali[i].RiferimentoNumero[keys[0]].trim();
          } catch (e) {}
        }
        if (obj.AltriDatiGestionali[i].RiferimentoTesto) {
          const keys = Object.keys(obj.AltriDatiGestionali[i].RiferimentoTesto);
          datoGestionale.RiferimentoTesto = obj.AltriDatiGestionali[i].RiferimentoTesto[keys[0]];
        }
        altriDatiGestionali.push(datoGestionale);
        datoGestionale = {};
      }
    else {
      if (obj.AltriDatiGestionali.TipoDato) {
        const keys = Object.keys(obj.AltriDatiGestionali.TipoDato);
        datoGestionale.TipoDato = obj.AltriDatiGestionali.TipoDato[keys[0]];
      }
      if (obj.AltriDatiGestionali.RiferimentoData) {
        const keys = Object.keys(obj.AltriDatiGestionali.RiferimentoData);
        datoGestionale.RiferimentoData = obj.AltriDatiGestionali.RiferimentoData[keys[0]];
      }
      if (obj.AltriDatiGestionali.RiferimentoNumero) {
        const keys = Object.keys(obj.AltriDatiGestionali.RiferimentoNumero);
        try {
          datoGestionale.RiferimentoNumero = obj.AltriDatiGestionali.RiferimentoNumero[keys[0]].trim();
        } catch (e) {}
      }
      if (obj.AltriDatiGestionali.RiferimentoTesto) {
        const keys = Object.keys(obj.AltriDatiGestionali.RiferimentoTesto);
        datoGestionale.RiferimentoTesto = obj.AltriDatiGestionali.RiferimentoTesto[keys[0]];
      }
      altriDatiGestionali.push(datoGestionale);
      datoGestionale = {};
    }
    return altriDatiGestionali;
  }

  /*
		DATI RIEPILOGO
	 */
  private parseDatiRiepilogo(root): fe.DatiBeniRiepilogo[] {
    const arrayDr: fe.DatiBeniRiepilogo[] = [{}];
    // parse solo se dettaglio linne è maggiore di 1
    if (root.DatiBeniServizi.DatiRiepilogo) {
      const object = root.DatiBeniServizi.DatiRiepilogo;
      if (root.DatiBeniServizi.DatiRiepilogo.length)
        for (let i = 0; i < object.length; i++) arrayDr[i] = this.parseSingleDatiRiepilogo(object[i]);
      else arrayDr[0] = this.parseSingleDatiRiepilogo(object);
    }
    return arrayDr;
  }

  private parseSingleDatiRiepilogo(obj): DatiBeniRiepilogo {
    const datiRiepilogo: DatiBeniRiepilogo = {};
    if (obj.AliquotaIVA) {
      const keys = Object.keys(obj.AliquotaIVA);
      try {
        datiRiepilogo.AliquotaIVA = obj.AliquotaIVA[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.Arrotondamento) {
      const keys = Object.keys(obj.Arrotondamento);
      try {
        datiRiepilogo.Arrotondamento = obj.Arrotondamento[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.EsigibilitaIVA) {
      const keys = Object.keys(obj.EsigibilitaIVA);
      datiRiepilogo.EsigibilitaIVA = obj.EsigibilitaIVA[keys[0]];
    }
    if (obj.ImponibileImporto) {
      const keys = Object.keys(obj.ImponibileImporto);
      try {
        datiRiepilogo.ImponibileImporto = obj.ImponibileImporto[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.Imposta) {
      const keys = Object.keys(obj.Imposta);
      try {
        datiRiepilogo.Imposta = obj.Imposta[keys[0]].trim();
      } catch (e) {}
    }
    if (obj.Natura) {
      const keys = Object.keys(obj.Natura);
      datiRiepilogo.Natura = obj.Natura[keys[0]];
    }
    if (obj.RiferimentoNormativo) {
      const keys = Object.keys(obj.RiferimentoNormativo);
      try {
        datiRiepilogo.RiferimentoNormativo = obj.RiferimentoNormativo[keys[0]];
      } catch (e) {}
    }
    if (obj.SpeseAccessorie) {
      const keys = Object.keys(obj.SpeseAccessorie);
      try {
        datiRiepilogo.SpeseAccessorie = obj.SpeseAccessorie[keys[0]].trim();
      } catch (e) {}
    }
    return datiRiepilogo;
  }

  private parseScontoMaggiorazioneDatiGenerali(xml: XMLDocument, root: string): fe.ScontoMaggiorazione {
    let scontoMaggiorazione: fe.ScontoMaggiorazione;
    if (this.parser.hasNode(xml, root)) {
      scontoMaggiorazione = {};
      const importo = this.parser.getText(xml, `${root}/Importo`);
      scontoMaggiorazione.Importo = importo ? importo.trim() : importo;
      const percentuale = this.parser.getText(xml, `${root}/Percentuale`);
      scontoMaggiorazione.Percentuale = percentuale ? percentuale.trim() : percentuale;

      scontoMaggiorazione.Tipo = this.parser.getText(xml, `${root}/Tipo`);
    }
    return scontoMaggiorazione;
  }

  // DATI TRASPORTO
  private parseDatiTrasporto(xml: XMLDocument, root: string): fe.DatiTrasporto {
    let datiTrasposrto: fe.DatiTrasporto;
    if (this.parser.hasNode(xml, root)) {
      datiTrasposrto = {};
      datiTrasposrto.DatiAnagraficiVettore = this.parseDatiAnagraficiVettore(xml, `${root}/DatiAnagraficiVettore`);
      datiTrasposrto.MezzoTrasporto = this.parser.getText(xml, `${root}/MezzoTrasporto`);
      datiTrasposrto.CausaleTrasporto = this.parser.getText(xml, `${root}/CausaleTrasporto`);
      const numeroColli = this.parser.getText(xml, `${root}/NumeroColli`);
      datiTrasposrto.NumeroColli = numeroColli ? numeroColli.trim() : numeroColli;
      datiTrasposrto.Descrizione = this.parser.getText(xml, `${root}/Descrizione`);
      datiTrasposrto.UnitaMisuraPeso = this.parser.getText(xml, `${root}/UnitaMisuraPeso`);
      const pesoLordo = this.parser.getText(xml, `${root}/PesoLordo`);
      datiTrasposrto.PesoLordo = pesoLordo ? pesoLordo.trim() : pesoLordo;
      const pesoNetto = this.parser.getText(xml, `${root}/PesoNetto`);
      datiTrasposrto.PesoNetto = pesoNetto ? pesoNetto.trim() : pesoNetto;
      datiTrasposrto.DataOraRitiro = this.parser.getText(xml, `${root}/DataOraRitiro`);
      datiTrasposrto.DataInizioTrasporto = this.parser.getText(xml, `${root}/DataInizioTrasporto`);
      datiTrasposrto.TipoResa = this.parser.getText(xml, `${root}/TipoResa`);
      datiTrasposrto.IndirizzoResa = this.parseIndirizzo(xml, `${root}/IndirizzoResa`);
      datiTrasposrto.DataOraConsegna = this.parser.getText(xml, `${root}/DataOraConsegna`);
    }
    return datiTrasposrto;
  }

  private parseDatiAnagraficiVettore(xml: XMLDocument, root: string): fe.DatiAnagraficiVettore {
    let datiAnagraficiVettore: fe.DatiAnagraficiVettore;
    if (this.parser.hasNode(xml, root)) {
      datiAnagraficiVettore = {};
      datiAnagraficiVettore.Anagrafica = this.parseAnagrafica(xml, `${root}/Anagrafica`);
      datiAnagraficiVettore.IdFiscaleIVA = this.parseIdFiscaleIva(xml, `${root}/IdFiscaleIVA`);
      datiAnagraficiVettore.CodiceFiscale = this.parser.getText(xml, `${root}/CodiceFiscale`);
      datiAnagraficiVettore.NumeroLicenzaGuida = this.parser.getText(xml, `${root}/NumeroLicenzaGuida`);
    }
    return datiAnagraficiVettore;
  }

  /* DATI PAGAMENTO */
  public getDatiVeicoli(): fe.DatiVeicoli {
    const root = '//DatiVeicoli[1]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    return {
      Data: this.parser.getText(xml, `${root}/Data`),
      TotalePercorso: this.parser.getText(xml, `${root}/TotalePercorso`),
    };
  }

  private parseDatiPagamentoTest(): fe.DatiPagamento[] {
    // get keys of json object
    this.getKeys();
    const root = this.resultInJson[this.keyFatturaElettronica].FatturaElettronicaBody;
    if (root.DatiPagamento) {
      // parse dettaglio linee
      const arrayDatiPagamaneto: fe.DatiPagamento[] = this.parseDatiPagamento(root);
      return arrayDatiPagamaneto;
    }
    return null;
  }

  private parseDatiPagamento(root): fe.DatiPagamento[] {
    const arrayDatiPagamento: fe.DatiPagamento[] = [{}];
    // parse solo se dettaglio linne è maggiore di 1
    if (root.DatiPagamento) {
      const object = root.DatiPagamento;
      if (root.DatiPagamento.length)
        for (let i = 0; i < object.length; i++) arrayDatiPagamento[i] = this.parseSingleDatiPagamento(object[i]);
      else arrayDatiPagamento[0] = this.parseSingleDatiPagamento(object);
    }
    return arrayDatiPagamento;
  }

  private parseSingleDatiPagamento(obj): fe.DatiPagamento {
    const datiPagamento: fe.DatiPagamento = {};
    if (obj.CondizioniPagamento && obj.CondizioniPagamento[Object.keys(obj.CondizioniPagamento)[0]])
      datiPagamento.CondizioniPagamento = obj.CondizioniPagamento[Object.keys(obj.CondizioniPagamento)[0]];

    if (obj.DettaglioPagamento) datiPagamento.DettaglioPagamento = this.parseDettaglioPagamento(obj);

    return datiPagamento;
  }

  private parseDettaglioPagamento(obj) {
    const dettaglioPagamento: fe.DettaglioPagamento[] = [];
    if (obj.DettaglioPagamento.length) this.parseArrayDettaglioPagamento(obj, dettaglioPagamento);
    else this.parseSingleDetaglioPagamento(obj, dettaglioPagamento);

    return dettaglioPagamento;
  }

  private parseArrayDettaglioPagamento(obj, dettaglioPagamento: fe.DettaglioPagamento[]) {
    let singleDettaglioPagamento: fe.DettaglioPagamento = {};
    for (let i = 0; i < obj.DettaglioPagamento.length; i++) {
      if (obj.DettaglioPagamento[i].Beneficiario)
        singleDettaglioPagamento.Beneficiario =
          obj.DettaglioPagamento[i].Beneficiario[Object.keys(obj.DettaglioPagamento[i].Beneficiario)[0]];

      if (obj.DettaglioPagamento[i].ModalitaPagamento)
        singleDettaglioPagamento.ModalitaPagamento =
          obj.DettaglioPagamento[i].ModalitaPagamento[Object.keys(obj.DettaglioPagamento[i].ModalitaPagamento)[0]];

      if (obj.DettaglioPagamento[i].DataRiferimentoTerminiPagamento)
        singleDettaglioPagamento.DataRiferimentoTerminiPagamento =
          obj.DettaglioPagamento[i].DataRiferimentoTerminiPagamento[
            Object.keys(obj.DettaglioPagamento[i].DataRiferimentoTerminiPagamento)[0]
          ];

      if (obj.DettaglioPagamento[i].GiorniTerminiPagamento)
        try {
          singleDettaglioPagamento.GiorniTerminiPagamento =
            obj.DettaglioPagamento[i].GiorniTerminiPagamento[
              Object.keys(obj.DettaglioPagamento[i].GiorniTerminiPagamento)[0]
            ].trim();
        } catch (e) {
          console.warn(e);
        }

      if (obj.DettaglioPagamento[i].DataScadenzaPagamento)
        singleDettaglioPagamento.DataScadenzaPagamento =
          obj.DettaglioPagamento[i].DataScadenzaPagamento[
            Object.keys(obj.DettaglioPagamento[i].DataScadenzaPagamento)[0]
          ];

      if (obj.DettaglioPagamento[i].ImportoPagamento)
        try {
          singleDettaglioPagamento.ImportoPagamento =
            obj.DettaglioPagamento[i].ImportoPagamento[
              Object.keys(obj.DettaglioPagamento[i].ImportoPagamento)[0]
            ].trim();
        } catch (e) {
          console.warn(e);
        }

      if (obj.DettaglioPagamento[i].CodUfficioPostale)
        singleDettaglioPagamento.CodUfficioPostale =
          obj.DettaglioPagamento[i].CodUfficioPostale[Object.keys(obj.DettaglioPagamento[i].CodUfficioPostale)[0]];

      if (obj.DettaglioPagamento[i].CognomeQuietanzante)
        singleDettaglioPagamento.CognomeQuietanzante =
          obj.DettaglioPagamento[i].CognomeQuietanzante[Object.keys(obj.DettaglioPagamento[i].CognomeQuietanzante)[0]];

      if (obj.DettaglioPagamento[i].NomeQuietanzante)
        singleDettaglioPagamento.NomeQuietanzante =
          obj.DettaglioPagamento[i].NomeQuietanzante[Object.keys(obj.DettaglioPagamento[i].NomeQuietanzante)[0]];

      if (obj.DettaglioPagamento[i].CFQuietanzanate)
        singleDettaglioPagamento.CFQuietanzanate =
          obj.DettaglioPagamento[i].CFQuietanzanate[Object.keys(obj.DettaglioPagamento[i].CFQuietanzanate)[0]];

      if (obj.DettaglioPagamento[i].TitoloQuietanzante)
        singleDettaglioPagamento.TitoloQuietanzante =
          obj.DettaglioPagamento[i].TitoloQuietanzante[Object.keys(obj.DettaglioPagamento[i].TitoloQuietanzante)[0]];

      if (obj.DettaglioPagamento[i].IstitutoFinanziario)
        singleDettaglioPagamento.IstitutoFinanziario =
          obj.DettaglioPagamento[i].IstitutoFinanziario[Object.keys(obj.DettaglioPagamento[i].IstitutoFinanziario)[0]];

      if (obj.DettaglioPagamento[i].IBAN)
        singleDettaglioPagamento.IBAN = obj.DettaglioPagamento[i].IBAN[Object.keys(obj.DettaglioPagamento[i].IBAN)[0]];

      if (obj.DettaglioPagamento[i].ABI)
        singleDettaglioPagamento.ABI = obj.DettaglioPagamento[i].ABI[Object.keys(obj.DettaglioPagamento[i].ABI)[0]];

      if (obj.DettaglioPagamento[i].CAB)
        singleDettaglioPagamento.CAB = obj.DettaglioPagamento[i].CAB[Object.keys(obj.DettaglioPagamento[i].CAB)[0]];

      if (obj.DettaglioPagamento[i].BIC)
        singleDettaglioPagamento.BIC = obj.DettaglioPagamento[i].BIC[Object.keys(obj.DettaglioPagamento[i].BIC)[0]];

      if (obj.DettaglioPagamento[i].ScontoPagamentoAnticipato)
        singleDettaglioPagamento.ScontoPagamentoAnticipato =
          obj.DettaglioPagamento[i].ScontoPagamentoAnticipato[
            Object.keys(obj.DettaglioPagamento[i].ScontoPagamentoAnticipato)[0]
          ];

      if (obj.DettaglioPagamento[i].DataLimitePagamanetoAnticipato)
        try {
          singleDettaglioPagamento.DataLimitePagamanetoAnticipato =
            obj.DettaglioPagamento[i].DataLimitePagamanetoAnticipato[
              Object.keys(obj.DettaglioPagamento[i].DataLimitePagamanetoAnticipato)[0]
            ].trim();
        } catch (e) {
          console.warn(e);
        }

      if (obj.DettaglioPagamento[i].PenalitaPagamentiRitardati)
        try {
          singleDettaglioPagamento.PenalitaPagamentiRitardati =
            obj.DettaglioPagamento[i].PenalitaPagamentiRitardati[
              Object.keys(obj.DettaglioPagamento[i].PenalitaPagamentiRitardati)[0]
            ].trim();
        } catch (e) {
          console.warn(e);
        }

      if (obj.DettaglioPagamento[i].DataDecorrenzaPenale)
        singleDettaglioPagamento.DataDecorrenzaPenale =
          obj.DettaglioPagamento[i].DataDecorrenzaPenale[
            Object.keys(obj.DettaglioPagamento[i].DataDecorrenzaPenale)[0]
          ];

      if (obj.DettaglioPagamento[i].CodicePagamento)
        singleDettaglioPagamento.CodicePagamento =
          obj.DettaglioPagamento[i].CodicePagamento[Object.keys(obj.DettaglioPagamento[i].CodicePagamento)[0]];

      dettaglioPagamento.push(singleDettaglioPagamento);
      singleDettaglioPagamento = {};
    }
  }

  private parseSingleDetaglioPagamento(obj, dettaglioPagamento: fe.DettaglioPagamento[]) {
    let singleDettaglioPagamento: fe.DettaglioPagamento = {};
    if (obj.DettaglioPagamento.Beneficiario)
      singleDettaglioPagamento.Beneficiario =
        obj.DettaglioPagamento.Beneficiario[Object.keys(obj.DettaglioPagamento.Beneficiario)[0]];

    if (obj.DettaglioPagamento.ModalitaPagamento)
      singleDettaglioPagamento.ModalitaPagamento =
        obj.DettaglioPagamento.ModalitaPagamento[Object.keys(obj.DettaglioPagamento.ModalitaPagamento)[0]];

    if (obj.DettaglioPagamento.DataRiferimentoTerminiPagamento)
      singleDettaglioPagamento.DataRiferimentoTerminiPagamento =
        obj.DettaglioPagamento.DataRiferimentoTerminiPagamento[
          Object.keys(obj.DettaglioPagamento.DataRiferimentoTerminiPagamento)[0]
        ];

    if (obj.DettaglioPagamento.GiorniTerminiPagamento)
      try {
        singleDettaglioPagamento.GiorniTerminiPagamento =
          obj.DettaglioPagamento.GiorniTerminiPagamento[
            Object.keys(obj.DettaglioPagamento.GiorniTerminiPagamento)[0]
          ].trim();
      } catch (e) {
        console.warn(e);
      }

    if (obj.DettaglioPagamento.DataScadenzaPagamento)
      singleDettaglioPagamento.DataScadenzaPagamento =
        obj.DettaglioPagamento.DataScadenzaPagamento[Object.keys(obj.DettaglioPagamento.DataScadenzaPagamento)[0]];

    if (obj.DettaglioPagamento.ImportoPagamento)
      try {
        singleDettaglioPagamento.ImportoPagamento =
          obj.DettaglioPagamento.ImportoPagamento[Object.keys(obj.DettaglioPagamento.ImportoPagamento)[0]].trim();
      } catch (e) {
        console.warn(e);
      }

    if (obj.DettaglioPagamento.CodUfficioPostale)
      singleDettaglioPagamento.CodUfficioPostale =
        obj.DettaglioPagamento.CodUfficioPostale[Object.keys(obj.DettaglioPagamento.CodUfficioPostale)[0]];

    if (obj.DettaglioPagamento.CognomeQuietanzante)
      singleDettaglioPagamento.CognomeQuietanzante =
        obj.DettaglioPagamento.CognomeQuietanzante[Object.keys(obj.DettaglioPagamento.CognomeQuietanzante)[0]];

    if (obj.DettaglioPagamento.NomeQuietanzante)
      singleDettaglioPagamento.NomeQuietanzante =
        obj.DettaglioPagamento.NomeQuietanzante[Object.keys(obj.DettaglioPagamento.NomeQuietanzante)[0]];

    if (obj.DettaglioPagamento.CFQuietanzanate)
      singleDettaglioPagamento.CFQuietanzanate =
        obj.DettaglioPagamento.CFQuietanzanate[Object.keys(obj.DettaglioPagamento.CFQuietanzanate)[0]];

    if (obj.DettaglioPagamento.TitoloQuietanzante)
      singleDettaglioPagamento.TitoloQuietanzante =
        obj.DettaglioPagamento.TitoloQuietanzante[Object.keys(obj.DettaglioPagamento.TitoloQuietanzante)[0]];

    if (obj.DettaglioPagamento.IstitutoFinanziario)
      singleDettaglioPagamento.IstitutoFinanziario =
        obj.DettaglioPagamento.IstitutoFinanziario[Object.keys(obj.DettaglioPagamento.IstitutoFinanziario)[0]];

    if (obj.DettaglioPagamento.IBAN)
      singleDettaglioPagamento.IBAN = obj.DettaglioPagamento.IBAN[Object.keys(obj.DettaglioPagamento.IBAN)[0]];

    if (obj.DettaglioPagamento.ABI)
      singleDettaglioPagamento.ABI = obj.DettaglioPagamento.ABI[Object.keys(obj.DettaglioPagamento.ABI)[0]];

    if (obj.DettaglioPagamento.CAB)
      singleDettaglioPagamento.CAB = obj.DettaglioPagamento.CAB[Object.keys(obj.DettaglioPagamento.CAB)[0]];

    if (obj.DettaglioPagamento.BIC)
      singleDettaglioPagamento.BIC = obj.DettaglioPagamento.BIC[Object.keys(obj.DettaglioPagamento.BIC)[0]];

    if (obj.DettaglioPagamento.ScontoPagamentoAnticipato)
      singleDettaglioPagamento.ScontoPagamentoAnticipato =
        obj.DettaglioPagamento.ScontoPagamentoAnticipato[
          Object.keys(obj.DettaglioPagamento.ScontoPagamentoAnticipato)[0]
        ];

    if (obj.DettaglioPagamento.DataLimitePagamanetoAnticipato)
      try {
        singleDettaglioPagamento.DataLimitePagamanetoAnticipato =
          obj.DettaglioPagamento.DataLimitePagamanetoAnticipato[
            Object.keys(obj.DettaglioPagamento.DataLimitePagamanetoAnticipato)[0]
          ].trim();
      } catch (e) {
        console.warn(e);
      }

    if (obj.DettaglioPagamento.PenalitaPagamentiRitardati)
      try {
        singleDettaglioPagamento.PenalitaPagamentiRitardati =
          obj.DettaglioPagamento.PenalitaPagamentiRitardati[
            Object.keys(obj.DettaglioPagamento.PenalitaPagamentiRitardati)[0]
          ].trim();
      } catch (e) {
        console.warn(e);
      }

    if (obj.DettaglioPagamento.DataDecorrenzaPenale)
      singleDettaglioPagamento.DataDecorrenzaPenale =
        obj.DettaglioPagamento.DataDecorrenzaPenale[Object.keys(obj.DettaglioPagamento.DataDecorrenzaPenale)[0]];

    if (obj.DettaglioPagamento.CodicePagamento)
      singleDettaglioPagamento.CodicePagamento =
        obj.DettaglioPagamento.CodicePagamento[Object.keys(obj.DettaglioPagamento.CodicePagamento)[0]];

    dettaglioPagamento.push(singleDettaglioPagamento);
    singleDettaglioPagamento = {};
  }

  /* ALLEGATI */
  private getAllegati(): Allegati[] {
    this.getKeys();
    const root = this.resultInJson[this.keyFatturaElettronica].FatturaElettronicaBody;
    if (root.Allegati) {
      // parse dettaglio linee
      const arrayAllegati: fe.Allegati[] = this.parseAllegati(root);
      return arrayAllegati;
    }
    return null;
  }

  private parseAllegati(root): Allegati[] {
    const arrayAllegati: fe.Allegati[] = [];
    // parse solo se dettaglio linne è maggiore di 1
    if (root.Allegati) {
      const object = root.Allegati;
      if (root.Allegati.length)
        for (let i = 0; i < object.length; i++) arrayAllegati[i] = this.parseSingleAttachment(object[i]);
      else arrayAllegati[0] = this.parseSingleAttachment(object);
    }
    return arrayAllegati;
  }

  private parseSingleAttachment(obj: Allegati) {
    const attachment: Allegati = {};
    if (obj.AlgoritmoCompressione && obj.AlgoritmoCompressione[Object.keys(obj.AlgoritmoCompressione)[0]])
      attachment.AlgoritmoCompressione = obj.AlgoritmoCompressione[Object.keys(obj.AlgoritmoCompressione)[0]];

    if (obj.FormatoAttachment && obj.FormatoAttachment[Object.keys(obj.FormatoAttachment)[0]])
      attachment.FormatoAttachment = obj.FormatoAttachment[Object.keys(obj.FormatoAttachment)[0]];

    if (obj.DescrizioneAttachment && obj.DescrizioneAttachment[Object.keys(obj.DescrizioneAttachment)[0]])
      attachment.DescrizioneAttachment = obj.DescrizioneAttachment[Object.keys(obj.DescrizioneAttachment)[0]];

    if (obj.Attachment && obj.Attachment[Object.keys(obj.Attachment)[0]])
      attachment.Attachment = obj.Attachment[Object.keys(obj.Attachment)[0]];

    if (obj.NomeAttachment && obj.NomeAttachment[Object.keys(obj.NomeAttachment)[0]])
      attachment.NomeAttachment = obj.NomeAttachment[Object.keys(obj.NomeAttachment)[0]];

    return attachment;
  }

  public getInvoice(): fe.FatturaElettronica {
    const fattura: fe.FatturaElettronica = {};
    const root = '//*[local-name()="FatturaElettronica"]';
    const xml = this.xmlDoc;
    const rootExists = this.parser.hasNode(xml, root);
    if (!rootExists) return null;

    fattura.FatturaElettronicaBody = Copier.deepCopy(this.parseBody(xml, `${root}/FatturaElettronicaBody`));
    fattura.FatturaElettronicaHeader = Copier.deepCopy(this.parseHeader(xml, `${root}/FatturaElettronicaHeader`));
    fattura.attributi = Copier.deepCopy(this.parseAttributes(xml));
    return fattura;
  }

  private parseAttributes(xml: XMLDocument) {
    this.attributes = [{}];
    const tag = xml.getElementsByTagNameNS('*', 'FatturaElettronica')[0];
    const attr = tag.attributes;
    const length = attr.length;
    for (let i = 0; i < length; i++) this.attributes[i] = this.parseSingleAttribute(i, attr);

    return this.attributes;
  }

  private parseSingleAttribute(index, attr) {
    const attributo: fe.Attributi = {};
    attributo.nome = '@' + attr.item(index).nodeName;
    attributo.valore = attr.item(index).nodeValue;
    return attributo;
  }

  private parseHeader(xml: XMLDocument, root: string): fe.FatturaElettronicaHeader {
    let header: fe.FatturaElettronicaHeader;
    if (this.parser.hasNode(xml, root)) {
      header = {};
      header.CedentePrestatore = this.getCedentePrestatore();
      header.CessionarioCommittente = this.getCessionarioCommittente();
      header.RappresentanteFiscale = this.getRappresentanteFiscale();
      header.SoggettoEmittente = this.getSoggettoEmittente();
      header.TerzoIntermediarioOSoggettoEmittente = this.getTerzoIntermediario();
    }
    return header;
  }

  private parseBody(xml: XMLDocument, root: string): fe.FatturaElettronicaBody {
    let body: fe.FatturaElettronicaBody;
    if (this.parser.hasNode(xml, root)) {
      body = {};
      body.DatiGenerali = this.getDatiGenerali();
      body.DatiBeniServizi = this.getDatiBeniServizi();
      body.DatiPagamento = this.parseDatiPagamentoTest();
      body.DatiVeicoli = this.getDatiVeicoli();
      body.Allegati = this.getAllegati();
    }
    return body;
  }

  public createInvoiceToSend(invoice: fe.FatturaElettronica, invoiceFormat: string, edit = false) {
    let tempNome;
    let tempValore;
    const fattura = {};
    if (edit)
      for (let i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].nome.localeCompare('@versione')) {
          tempNome = '@versione';
          tempValore = invoiceFormat;
        } else {
          tempNome = this.attributes[i].nome;
          tempValore = this.attributes[i].valore;
        }
        fattura[tempNome] = tempValore;
      }
    else {
      fattura['@xmlns:ds'] = 'http://www.w3.org/2000/09/xmldsig#';
      fattura['@xmlns:p'] = 'http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2';
      fattura['@versione'] = invoiceFormat;
      fattura['@xsi:schemaLocation'] =
        'http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2' +
        ' http://www.fatturapa.gov.it/export/fatturazione/sdi/fatturapa/v1.2/Schema_del_file_xml_FatturaPA_versione_1.2.xsd';
    }
    return this.createInvoiceBody(fattura, invoice);
  }

  public createInvoiceBody(invoice, fatturaPopolata: fe.FatturaElettronica) {
    invoice['FatturaElettronicaHeader'] = fatturaPopolata.FatturaElettronicaHeader;
    invoice['FatturaElettronicaBody'] = fatturaPopolata.FatturaElettronicaBody;
    return invoice;
  }
}
