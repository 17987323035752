import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormControl, isFormControl } from '@angular/forms';

@Pipe({
  name: 'asFormControl',
})
export class AsFormControlPipe implements PipeTransform {
  transform(control: AbstractControl): FormControl | undefined {
    if (isFormControl(control)) return control;
    return undefined;
  }
}
