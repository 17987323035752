import dayjs from 'dayjs';

export class DateRange {
  private readonly _start: dayjs.Dayjs;
  private readonly _end: dayjs.Dayjs;

  constructor(start: dayjs.Dayjs, end: dayjs.Dayjs) {
    this._start = start;
    this._end = end;
  }

  getStart(): dayjs.Dayjs {
    return this._start;
  }

  getEnd(): dayjs.Dayjs {
    return this._end;
  }
}
