import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GawbiHttpService } from './gawbi-http.service';

@Injectable({
  providedIn: 'root',
})
export class GawbiService {
  public selectedService = new BehaviorSubject<string>('');

  constructor(private gawbiHttpService: GawbiHttpService) {}

  getEmbedUrl(siaCode: string): string {
    return this.gawbiHttpService.getEmbedUrl(siaCode);
  }

  getCookieDomain(): string {
    return this.gawbiHttpService.getCookieDomain();
  }

  whenSelectedService() {
    return this.selectedService.asObservable();
  }

  sendSelectedService(service: string) {
    this.selectedService.next(service);
  }
}
