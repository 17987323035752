import { Injectable } from '@angular/core';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class MassiveActionsCatalog {
  public readonly exportExcelMassive = new ActionBuilder(
    MassiveActionCode.exportExcelMassive,
    ActionCode.DOWNLOAD_EXCEL,
  )
    .setActionURL('/api/v1/Export/ExportLotti')
    .setCallback((action: ActionObject, extraParams?: any) => this.allDocumentsCallback.exportXLSX(action, extraParams))
    .build();
  constructor(private allDocumentsCallback: AllDocumentsCallback) {}
}

export enum MassiveActionCode {
  exportExcelMassive = 'exportExcelMassive',
}
