import { Injectable } from '@angular/core';
import { HomeFilter, HomeFilterRangeType } from '@ctel/gaw-commons';
import { select, Store } from '@ngrx/store';
import { FilterBuilder } from 'app/constants/filters/filter-builder';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { Copier } from 'app/core/common/utilities/copier';
import { map, tap } from 'rxjs/operators';
import { changeHomeDateFilterRequested } from '../documents/filters/store/home-filter.actions';
import { getCachedHomeFilters } from '../documents/filters/store/home-filter.selectors';

/**
 * Servizio che gestisce le dropdown con le date
 */
@Injectable({
  providedIn: 'root',
})
export class DateDropdownsPayableService {
  private currentFilterValue: HomeFilter[];

  constructor(private store: Store) {
    this.initializeDateFilters();
  }

  initializeDateFilters() {
    const currentFilterValue$ = this.store.pipe(
      select(getCachedHomeFilters),
      map((homeFilters) => homeFilters.payable),
    );
    currentFilterValue$
      .pipe(
        tap((homeFilters) => {
          this.currentFilterValue = Copier.deepCopy(homeFilters);
        }),
      )
      .subscribe();
  }

  // current date filter value
  getCurrentDateFilterValue(): HomeFilter[] {
    return this.currentFilterValue;
  }

  createDateFilter(
    metadata: string,
    valueFrom: string,
    valueTo: string,
    doubleInsert: boolean,
    dateRangeType: HomeFilterRangeType,
    valueFromInvoice?: string,
    valueToInvoice?: string,
  ) {
    // filtri data precedenti
    const previousDateFilters = Copier.deepCopy(this.getCurrentDateFilterValue());
    const newFilters: HomeFilter[] = previousDateFilters.filter((value) => {
      if (!doubleInsert) return value.metadata !== metadata;

      return value.metadata !== MetadataEnum.DATA_FATTURA && value.metadata !== MetadataEnum.DATA_RICEZIONE;
    });

    // aggiungo il nuovo filtro (ma non se sto impostando data fattura su "nessun filtro" o se sto facendo un doppio inserimento)
    if (valueFrom !== '' && !doubleInsert) {
      const built = FilterBuilder.generalDateFilter(metadata, valueFrom, valueTo) as HomeFilter;
      built.dateRangeType = dateRangeType;
      newFilters.push(built);
      // se è un doppio inserimento, inserisco entrambe le tipologie di filtro data
    } else if (doubleInsert) {
      const built1 = FilterBuilder.generalDateFilter(MetadataEnum.DATA_RICEZIONE, valueFrom, valueTo) as HomeFilter;
      built1.dateRangeType = dateRangeType;
      const built2 = FilterBuilder.generalDateFilter(
        MetadataEnum.DATA_FATTURA,
        valueFromInvoice,
        valueToInvoice,
      ) as HomeFilter;
      built2.dateRangeType = dateRangeType;
      newFilters.push(built1, built2);
    }

    this.store.dispatch(changeHomeDateFilterRequested(newFilters, 'payable', true));
  }
}
