import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TrackingProviderResponse } from 'app/core/common/entities/documents/actions/tracking-provider';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'tracking-provider-modal',
  templateUrl: './tracking-provider-modal.component.html',
  styleUrls: ['./tracking-provider-modal.component.scss'],
})
export class TrackingProviderModalComponent {
  @Input() modal: BsModalRef;
  @Input() data: TrackingProviderResponse;

  constructor(private location: PlatformLocation) {
    location.onPopState(() => this.modal.hide());
  }
}
