import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';

@Injectable({
  providedIn: 'root',
})
export class MassiveActionsCatalog {
  public readonly exportExcel = new ActionBuilder(MassiveActionCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
    .setActionURL('/v2/documents/export/report/excel')
    .setCallback((action: ActionObject, extraParams?: { sectionCode: unknown }) =>
      this.allDocumentsCallback.exportXLSX(action, extraParams),
    )
    .build();

  public readonly massiveHideDocumentsAction = new ActionBuilder(
    MassiveActionCode.massiveHideDocumentsAction,
    ActionCode.HIDE_DOCUMENTS,
  )
    .setActionURL('/v2/Document/HideAndRestoreAll?hideAndRestoreAction={hide}')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.hideAndRestoreMassiveDocuments(action, true))
    .build();

  public readonly massiveRestoreDocumentsAction = new ActionBuilder(
    MassiveActionCode.massiveRestoreDocumentsAction,
    ActionCode.RESTORE_DOCUMENTS,
  )
    .setActionURL('/v2/Document/HideAndRestoreAll?hideAndRestoreAction={hide}')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.hideAndRestoreMassiveDocuments(action, false))
    .build();

  public readonly moveToTrash = new ActionBuilder(MassiveActionCode.moveToTrash, ActionCode.MOVE_TO_TRASH)
    .setActionURL('/Trash/command/MoveToTrash/massive')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.moveToTrashMassive(action))
    .build();

  public readonly downloadPdfZipMassive = new ActionBuilder(
    MassiveActionCode.downloadMassiveXml,
    ActionCode.DOWNLOAD_MASSIVE_XML,
  )
    .setCallback(() => this.allDocumentsCallback.asyncDownloadMassiveXML())
    .build();

  constructor(private allDocumentsCallback: AllDocumentsCallback) {}
}

export enum MassiveActionCode {
  exportExcel = 'exportExcel',
  massiveHideDocumentsAction = 'massiveHideDocumentsAction',
  massiveRestoreDocumentsAction = 'massiveRestoreDocumentsAction',
  moveToTrash = 'moveToTrash',
  downloadMassiveXml = 'downloadMassiveXml',
}
