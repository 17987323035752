import { Component, Input, OnInit } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DocumentsDoubleKeys } from 'app/entities/anomalies/documents-double-keys';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { AnomaliesService } from '../../../services/anomalies.service';

@Component({
  selector: 'gaw-anomaly-doubles-by-keys',
  templateUrl: './anomaly-doubles-by-keys.component.html',
  styleUrls: ['./anomaly-doubles-by-keys.component.scss'],
})
export class AnomalyDoublesByKeysComponent implements OnInit {
  @Input() data: IDetailsComponent;

  documents$: Observable<DocumentsDoubleKeys[]>;
  showPopover = false;
  public faIcons = FaIcons;
  errorMessage;

  constructor(private anomaliesService: AnomaliesService) {}

  ngOnInit(): void {
    this.documents$ = this.anomaliesService.whenDoubleKeys(this.data.keys['idSospeso']).pipe(
      catchError((err: unknown) => {
        this.errorMessage = err?.['additionalInfo'].find((value) => value.t === 'response').u;
        return EMPTY;
      }),
    );
  }

  showDocDetails() {
    this.showPopover = true;
  }
}
