import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ActionsHttpService } from '@ctel/hubfe-commons';
import { ErrorTypes } from 'app/core/common/error';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

/**
 * Componente modal con data entry indirizzo mail
 */
@Component({
  selector: 'hub-insert-mail-address-modal',
  templateUrl: './insert-mail-address-modal.component.html',
  styleUrls: ['./insert-mail-address-modal.component.scss'],
})
export class InsertMailAddressModalComponent {
  @Input() title: string;
  @Input() data: any;
  @Input() modal: BsModalRef;

  isErrorMail = false;

  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private location: PlatformLocation,
    private router: Router,
  ) {
    location.onPopState(() => this.modal.hide());
  }

  onFormSubmit(form: NgForm) {
    const mail = form.form.value.mail;

    if (mail === '') {
      this.isErrorMail = true;
      return;
    }

    if (!this.validateEmail(mail)) {
      this.isErrorMail = true;
      return;
    }

    this.isErrorMail = false;

    this.actionsHttpService
      .whenModifyMailOrPecAddress(
        this.data.actionUrl,
        this.data.progSpool,
        this.data.progBusta,
        this.data.docHash,
        mail,
        {},
      )
      .pipe(
        take(1),
        catchError((err: unknown) => {
          if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) return of(null);

          if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
            //this.router.navigate(['/unauthorized']).then();
            return of(null);
        }),
      )
      .subscribe((data) => {
        this.modal.hide();
        if (data)
          if (data.esitoOK === true)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Indirizzo modificato',
              'Reinvio eseguito',
            );
          else
            this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore nella modifica dell'indirizzo", '');
        else
          this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore nella modifica dell'indirizzo", '');
      });
  }

  private validateEmail(email) {
    const re = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    return re.test(email);
  }
}
