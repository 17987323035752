import { Injectable, OnDestroy } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { TopProgressBarService } from 'app/core/common/spinner/top-progress-bar/top-progress-bar.service';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, count, delay, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DocumentsSeriesService } from '../../documents-series/documents-series.service';
import { DocumentsService } from '../../documents/documents.service';
import { FilterService2 } from '../../filters/filter.service';
import { ActionsHttpService } from '../actions-http.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
  providedIn: 'root',
})
export class AllDocumentsCallback implements OnDestroy {
  private readonly _filterPayload$: Observable<FilterStatus>;
  private readonly _totalDocuments$: Observable<number>;
  private concurrentDownloads = 0;

  private destroy$ = new Subject<void>();

  constructor(
    private documentsService: DocumentsService,
    private filterService: FilterService2,
    private notificationService: NotificationService,
    private documentsSeriesService: DocumentsSeriesService,
    private actionsHttpService: ActionsHttpService,
    private transferService: TransferService,
    private progressBarService: TopProgressBarService,
  ) {
    this._totalDocuments$ = documentsService.whenTotalDocuments();
    this._filterPayload$ = filterService.whenFilterValue();
    this.transferService
      .whenTransfers()
      .pipe(
        switchMap((value) =>
          of(Object.values<Transfer>(value)).pipe(
            mergeMap((value1) => value1),
            count((v) => !TransferUtils.isComplete(v)),
            tap((v) => (this.concurrentDownloads = v)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public restoreFromTrashMassive(action: ActionObject): Observable<never> {
    this.notificationService.showSweetAlert(
      NotificationType.QUESTION,
      'Ripristina Documenti',
      "Vuoi ripristinare i documenti selezionati? L'operazione avverrà in differita: riceverai una notifica (campanella) al termine del processo",
      () => {
        this.progressBarService.pushRequest();
        this._totalDocuments$
          .pipe(
            mergeMap(() =>
              this._filterPayload$.pipe(
                take(1),
                switchMap((filter) => this.actionsHttpService.postToDocumenti(action.url, filter)),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Ripristina Documenti',
                    'Si sono verificati dei problemi e non è stato possibile ripristinare i documenti dal Cestino',
                  );
                  return EMPTY;
                }),
                delay(1000),
              ),
            ),
            take(1),
          )
          .subscribe(() => {
            this.progressBarService.popRequest();
            this.documentsService.refreshDocuments();
            this.notificationService.showSweetAlert(
              NotificationType.INFO,
              'Ripristina Documenti',
              'La procedura di ripristino dei documenti selezionati potrebbe richiedere un po’ di tempo. Una volta terminata la procedura riceverai una notifica (campanella)',
            );
          });
      },
    );

    return EMPTY;
  }

  public deleteFromTrashMassive(action: ActionObject): Observable<never> {
    this.notificationService.showSweetAlert(
      NotificationType.QUESTION,
      'Elimina Definitivamente i Documenti',
      `Vuoi eliminare definitivamente i documenti selezionati?
						<br><b>Una volta eseguita l’operazione, i documenti non saranno più recuperabili.</b>
						<br>
						<br>L'operazione avverrà in differita: riceverai una notifica (campanella) al termine del processo`,
      () => {
        this.progressBarService.pushRequest();
        this._totalDocuments$
          .pipe(
            mergeMap(() =>
              this._filterPayload$.pipe(
                take(1),
                switchMap((filter) => this.actionsHttpService.postToDocumenti(action.url, filter)),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Elimina Definitivamente i Documenti',
                    'Si sono verificati dei problemi e non è stato possibile eliminare i documenti',
                  );
                  return EMPTY;
                }),
                delay(1000),
              ),
            ),
            take(1),
          )
          .subscribe(() => {
            this.progressBarService.popRequest();
            this.documentsService.refreshDocuments();
            this.notificationService.showSweetAlert(
              NotificationType.INFO,
              'Elimina Definitivamente i Documenti',
              `La procedura di eliminazione definitiva dei documenti selezionati potrebbe richiedere un po’ di tempo.
						<br>Una volta terminata la procedura riceverai una notifica (campanella)`,
            );
          });
      },
    );

    return EMPTY;
  }
}
