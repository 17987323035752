import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { NotificationType } from '../notification/notification-type';
import { NotificationService } from '../notification/notification.service';
import { AppError } from './app-error';
import { ErrorDisplayComponent } from './error-display/error-display.component';

@Injectable()
export class ErrorService {
  public errorMessage$ = new ReplaySubject<AppError>(1);
  private toastMessageMaxLength = 100;

  constructor(private notification: NotificationService) {
    this.whenError().subscribe((errorData: AppError) => {
      this.showError(errorData);
      // this.sendError(errorData);
    });
  }

  public whenError(): Observable<AppError> {
    return this.errorMessage$.asObservable();
  }

  public setError(error: AppError) {
    this.errorMessage$.next(error);
  }

  showError(appError: AppError) {
    // temporaneo per non visualizzare il toaster in alfa e collaudo per questo errore
    if (appError.hasCause && appError.cause.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) return;

    const toastTitle = 'Errore';
    let toastMessage = appError.description ? appError.description : NotificationType.ERROR.toString();

    if (toastMessage.length > this.toastMessageMaxLength)
      toastMessage = toastMessage.substring(0, this.toastMessageMaxLength).concat('...');

    const showModalCallback = () =>
      this.notification.showModal(NotificationType.ERROR, {
        title: "Si è verificato un errore, riprovare o contattare l'amministratore di sistema",
        childComponent: ErrorDisplayComponent,
        childData: appError,
      });

    this.notification.showToast(NotificationType.ERROR, toastTitle, toastMessage, showModalCallback);
  }

  // private sendError(errorData: AppError) {
  // }
}
