/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';

@Directive({
  selector: '[composeAsyncValidators][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ComposeAsyncValidatorDirective, multi: true }],
})
export class ComposeAsyncValidatorDirective implements Validator, OnInit {
  @Input('composeAsyncValidators') validators: Array<Validator | ValidatorFn> = [];

  validator: ValidatorFn;

  ngOnInit(): void {
    this.validator = this.composeValidators(this.validators);
  }

  registerOnValidatorChange(fn: () => void): void {}

  validate(c: AbstractControl): ValidationErrors | null {
    if (typeof this.validator === 'function') return this.validator(c);

    return null;
  }

  private composeValidators(validators: Array<Validator | ValidatorFn>): AsyncValidatorFn | ValidatorFn {
    if (validators === null || validators.length === 0) return null;

    return Validators.compose(validators.map((v) => this.normalizeValidator(v)));
  }

  private normalizeValidator(validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn {
    const func = (validator as Validator).validate.bind(validator);
    if (typeof func === 'function') return (c: AbstractControl) => func(c);
    else return <ValidatorFn | AsyncValidatorFn>validator;
  }
}
