import { EventEmitter, Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

const tipiDocumento: InvoiceMultiValues[] = [
  { value: 'TD01', label: 'TD01 - Fattura' },
  { value: 'TD02', label: 'TD02 - Acconto/Anticipo su fattura' },
  { value: 'TD03', label: 'TD03 - Acconto/Anticipo su parcella' },
  { value: 'TD04', label: 'TD04 - Nota di credito' },
  { value: 'TD05', label: 'TD05 - Nota di debito' },
  { value: 'TD06', label: 'TD06 - Parcella' },
  { value: 'TD16', label: 'TD16 - Integrazione fattura reverse charge interno' },
  { value: 'TD17', label: "TD17 - Integrazione/autofattura per acquisto servizi dall'estero" },
  { value: 'TD18', label: 'TD18 - Integrazione per acquisto di beni intracomunitari' },
  { value: 'TD19', label: 'TD19 - Integrazione/autofattura per acquisto di beni ex art.17 c.2 DPR 633/72' },
  {
    value: 'TD20',
    label:
      'TD20 - Autofattura per regolarizzazione e integrazione delle fatture (ex art.6 c.8 d.lgs.471/97 o art.46 c.5 D.L. 331/93)',
  },
  { value: 'TD21', label: 'TD21 - Autofattura per splafonamento' },
  { value: 'TD22', label: 'TD22 - Estrazione beni da Deposito IVA' },
  { value: 'TD23', label: "TD23 - Estrazione beni da Deposito IVA con versamento dell'IVA" },
  { value: 'TD24', label: "TD24 - Fattura differita di cui all'art.21, comma 4, lett. a)" },
  { value: 'TD25', label: "TD25 - Fattura differita di cui all'art.21, comma 4, terzo periodo lett. b)" },
  { value: 'TD26', label: 'TD26 - Cessione di beni ammortizzabili e per passaggi interni (ex art.36 DPR 633/72)' },
  { value: 'TD27', label: 'TD27 - Fattura per autoconsumo o per cessioni gratuite senza rivalsa' },
  { value: 'TD28', label: 'TD28 - Acquisti da San Marino con IVA' },
];

@Injectable()
export class InvoiceTypeService {
  tipoDocumentoUpdated = new EventEmitter();

  public getList() {
    return tipiDocumento;
  }

  public addAutoFattura() {
    const nuovoTipo: TipoDocumento = {
      value: 'TD20',
      label:
        'TD20 - Autofattura per regolarizzazione e integrazione delle fatture' +
        ' (ex art.6 c.8 d.lgs.471/97 o art.46 c.5 D.L. 331/93)',
    };
    for (let i = 0; i < tipiDocumento.length; i++) if (tipiDocumento[i].value === 'TD20') return;

    tipiDocumento.push(nuovoTipo);
    this.tipoDocumentoUpdated.emit(tipiDocumento);
  }

  public deleteAutoFattura() {
    for (let i = 0; i < tipiDocumento.length; i++) if (tipiDocumento[i].value === 'TD20') tipiDocumento.splice(i, 1);

    this.tipoDocumentoUpdated.emit(tipiDocumento);
  }
}

export interface TipoDocumento {
  value: string;
  label: string;
}
