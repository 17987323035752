import { Injectable } from '@angular/core';
import {
  AdvancedTextSearchService,
  DocSeriesMetadataDesc,
  Document,
  DocumentViewModel,
  Filter,
  FiltersResponse,
  InfoByDocSeries,
  MemoizeObservable,
} from '@ctel/gaw-commons';
import {
  DocumentActions,
  DocumentState,
  IFilterStatus,
  getDocuments,
  getTotalDocuments,
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { GraficaService } from 'app/core/business/grafiche/grafica.service';
import { AppErrorBuilder, ErrorTypes } from 'app/core/common/error';
import { ColorList } from 'app/core/common/utilities/color-list';
import { Copier } from 'app/core/common/utilities/copier';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { DocumentsHttpService } from '../documents-http.service';
import { ExtendedDocumentState, getRelatedSectionData, selectExtendedDocumentState } from './store/document.extended';
import { DocSeries } from '../../../../../../../src/app/entities/settings/doc-series';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsService2 {
  public sectionColumns$: Observable<{
    primaryConfig: Column[];
    secondaryConfig: Column[];
  }>;

  private availableDocSeriesDescriptionsColor = new Map<string, string>();

  private destroy$ = new Subject<void>();
  private readonly documents$: Observable<DocumentViewModel[]>;
  private readonly totalDocuments$: Observable<number>;
  private errorLoadingDocuments$ = new BehaviorSubject<boolean>(false);
  private loadingDocs$ = new BehaviorSubject<boolean>(true);
  private loadingDocsAfterFilterApplication$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnPaging$ = new BehaviorSubject<boolean>(false);
  private loadingDocsOnSectionChange$ = new BehaviorSubject<boolean>(false);

  // Subject contenente la lista dei documenti già estratti con i valori aggiuntivi della detail
  constructor(
    private store: Store<DocumentState>,
    private documentsHttpService: DocumentsHttpService,
    private companiesService: CompaniesService,
    private advancedTextSearchService: AdvancedTextSearchService,
    private graficaService: GraficaService,
  ) {
    this.documents$ = (this.store.pipe(select(getDocuments)) as Observable<Document[]>).pipe(
      map((docs) => docs.map((d) => this.createDocumentViewModel(d))),
    );

    this.totalDocuments$ = this.store.pipe(select(getTotalDocuments));

    this.sectionColumns$ = this.store.pipe(
      select(selectExtendedDocumentState),
      map((value: ExtendedDocumentState) => ({
        primaryConfig: value.primaryColumnConfig,
        secondaryConfig: value.secondaryColumnConfig,
      })),
    );
  }

  @MemoizeObservable()
  public whenMetadataDescriptions(relatedSectionData: RelatedSectionData[]): Observable<DocSeriesMetadataDesc[]> {
    const payload = this.advancedTextSearchService.createMetadataDescriptionPayload(relatedSectionData);
    return this.documentsHttpService.whenMetadataDescriptions(payload);
  }

  whenDocuments(): Observable<DocumentViewModel[]> {
    return this.documents$;
  }

  whenTotalDocuments(): Observable<number> {
    return this.totalDocuments$;
  }

  public whenTypeAhead(
    text: string,
    sectionRelatedData: RelatedSectionData[],
    isHomePage: boolean,
  ): Observable<string[]> {
    const searchStringLength = text.trim().length;
    const minSearchStringLength = 3;
    return this.companiesService.whenCurrentCompany().pipe(
      tap(() => {
        if (searchStringLength < 3)
          throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
            .description(
              `La stringa di ricerca senza spazi marginali (trim) deve avere una lunghezza >= ${minSearchStringLength}`,
            )
            .info('Stringa di ricerca', text)
            .info('Lunghezza trim', searchStringLength)
            .build();
      }),
      debounceTime(200),
      withLatestFrom(this.store.pipe(select(getRelatedSectionData))),
      switchMap(([company, data]) => {
        const docSeriesId = this.mergeDocSeriesId(isHomePage ? sectionRelatedData : data);
        const body = {
          docSeriesIds: docSeriesId,
          licenseId: company.licenseId,
          siaCode: company.siaCode,
          text,
        };

        return this.documentsHttpService.whenSearchTypeahead(body).pipe(
          map((value) => {
            const matchListWithDoubleQuotes: string[] = [];
            value.bestMatchList.forEach(function (match) {
              matchListWithDoubleQuotes.push('"' + match + '"');
            });
            return matchListWithDoubleQuotes;
          }),
          catchError((err: unknown) => {
            throw new AppErrorBuilder(ErrorTypes.GET_FAILURE)
              .cause(err as Error)
              .description('Errore durante la ricerca sui documenti.')
              .info('Stringa di ricerca', text)
              .build();
          }),
        );
      }),
      take(1),
    );
  }

  /**
   * Crea un payload per i filtri a partire da filtri e colonne.
   * Il payload verrà utilizzato per le successive richieste alla /search di Magellano.
   * Questo metodo è utilizzato nella gestione dinamica dei filtri.
   * Altera parzialmente i filtri per impostare gli eventuali filtri data dalla home (con default a ultimo trimestre).
   * Imposta anche metric e orderby.
   *
   * @param licenseId la licenza corrente
   * @param siaCode l'azienda corrente
   * @param sectionId
   * @param filterPayload il payload dei filtri precedentemente fetchati da mocks/API
   */
  public buildFilterPayload(
    licenseId: string,
    siaCode: string,
    sectionId: string,
    filterPayload: FiltersResponse,
  ): IFilterStatus {
    const currentFilters: Filter[] = this.patchFiltersPayload(filterPayload.filters);
    return {
      docSeriesId: sectionId,
      licenseId,
      siaCode,
      search: filterPayload.search,
      paging: filterPayload.paging,
      orderBy: filterPayload.orderBy,
      filters: currentFilters,
    };
  }

  public patchFiltersPayload(filters: Filter[]): Filter[] {
    // Ribalto i value dei filtri da home in quelli attuali se li ho.
    // Per alcuni metadata imposto i from/to opportunamente.
    const currentFilters = Copier.deepCopy(filters);
    currentFilters.forEach((fetchedFilter: Filter) => {
      // La preffilters per HUBFE ritorna dei termsAggs senza l'oggetto buckets vuoto.
      if (fetchedFilter.filterType === 'termsAggs' && !fetchedFilter.configData.buckets)
        fetchedFilter.configData.buckets = [];

      // Impostiamo un default per i configData dei range di date.
      if (
        fetchedFilter.filterType === 'range' &&
        fetchedFilter.type === 'date' &&
        (!fetchedFilter.configData || (!fetchedFilter.configData.from && !fetchedFilter.configData.to))
      )
        fetchedFilter.configData = {
          from: dayjs()
            .set('y', 1900)
            .set('M', 1)
            .set('D', 1)
            .set('h', 0)
            .set('m', 0)
            .set('s', 0)
            .set('ms', 0)
            .toISOString(),
          to: dayjs().add(1, 'year').toISOString(),
        };
    });
    return currentFilters;
  }

  /**
   * forza refresh griglia documenti
   */
  refreshDocuments() {
    this.store.dispatch(DocumentActions.fetchDocuments(true));
  }

  public setLoadingDocs(value: boolean) {
    this.loadingDocs$.next(value);
  }

  public whenLoadingDocs(): Observable<boolean> {
    return this.loadingDocs$.asObservable();
  }

  public setLoadingDocsAfterFilterApplication(value: boolean) {
    this.loadingDocsAfterFilterApplication$.next(value);
  }

  public whenLoadingDocsAfterFilterApplication(): Observable<boolean> {
    return this.loadingDocsAfterFilterApplication$.asObservable();
  }

  public setLoadingDocsOnPaging(value: boolean) {
    this.loadingDocsOnPaging$.next(value);
  }

  public whenLoadingDocsOnPaging(): Observable<boolean> {
    return this.loadingDocsOnPaging$.asObservable();
  }

  public setLoadingDocsOnSectionChange(value: boolean) {
    this.loadingDocsOnSectionChange$.next(value);
  }

  public whenLoadingDocsOnSectionChange(): Observable<boolean> {
    return this.loadingDocsOnSectionChange$.asObservable();
  }

  public whenErrorLoadingDocs(): Observable<boolean> {
    return this.errorLoadingDocuments$.asObservable();
  }

  public setErrorLoadingDocs(value: boolean) {
    this.errorLoadingDocuments$.next(value);
  }

  public whenDocSeriesCodeInfo(docseriesId): Observable<InfoByDocSeries> {
    return this.documentsHttpService.whenDocSeriesCodeInfo(docseriesId);
  }

  public showAttachments(docSeriesId, licenseId, siaCode) {
    return this.whenDocSeriesCodeInfo(docSeriesId).pipe(
      switchMap((docSeries) => {
        if (docSeries.docClassCode === 'fatturePassive' || docSeries.docClassCode === 'fattureAttive')
          return this.graficaService.gawChannels(licenseId, siaCode, docSeries.graphicsCode);
        else return of(false);
      }),
      map((result: unknown) => {
        if (result)
          return !!result?.['tipoCanale'].find(
            (canale) => canale.channelName === 'GEDPASSJOIN' || canale.channelName === 'GEDINVOICE',
          );
        else return false;
      }),
    );
  }

  mergeDocSeriesId(sectionRelatedData: RelatedSectionData[]): string[] {
    let docSeries: string[] = [];
    for (const data of sectionRelatedData) docSeries.concat(data.docSeriesIds);

    sectionRelatedData.forEach((data) => {
      docSeries = docSeries.concat(data.docSeriesIds);
    });
    return docSeries;
  }

  private createDocumentViewModel(d: Document) {
    return <DocumentViewModel>{
      ...d,
      docSeriesDescriptionColor: this.computeIfAbsendDocSeriesColor(d.docSeriesDescription),
    };
  }

  private computeIfAbsendDocSeriesColor(docSeriesDescr: string) {
    const mapKey = docSeriesDescr?.trim();

    if (mapKey && this.availableDocSeriesDescriptionsColor.has(mapKey))
      return this.availableDocSeriesDescriptionsColor.get(mapKey);

    const colors = ColorList.getColorList();
    // eslint-disable-next-line no-bitwise
    const hash = Array.from(mapKey ?? '').reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0);
    const colorIdx = Math.abs(hash % colors.length);
    const color = colors[colorIdx].hex;

    this.availableDocSeriesDescriptionsColor.set(mapKey, color);
    return color;
  }

  public getDocSeriesWithSections(licenseId: string, siaCode: string): Observable<DocSeries[]> {
    return this.documentsHttpService.getDocSeriesWithSections(licenseId, siaCode);
  }
}
