import { Injectable, inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, UrlTree } from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

/**
 * Guardia per controllare se l'utente può o meno accedere al portale.
 *
 * Se l'utente non ha accesso si verrà reindirizzati verso la pagina di accesso negato.
 */
@Injectable()
export class AccessPermissionService {
  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  private canAccess() {
    return combineLatest([this.authService.isAuthorized(), this.userService.hasAccessPermission()]).pipe(
      map(([isAuthorized, hasPermission]) => {
        const canAccess = isAuthorized && hasPermission;
        if (canAccess) return true;

        this.authService.navigateToAccessDenied();
        return false;
      }),
      catchError(() => {
        this.authService.navigateToAccessDenied();
        return of(false);
      }),
    );
  }
}

export const canActivateAccess: CanActivateFn = () => inject(AccessPermissionService).canActivate();

export const canActivateChildAccess: CanActivateChildFn = () => inject(AccessPermissionService).canActivateChild();
