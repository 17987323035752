import { Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';
import { take, tap } from 'rxjs/operators';
import { CausaliPagamentoHttpService } from './causali-pagamento-http.service';

@Injectable()
export class CausaliPagamentoService {
  public causaliPagamento: InvoiceMultiValues[] = [];

  constructor(private causaliService: CausaliPagamentoHttpService) {
    this.causaliService
      .getCausali()
      .pipe(
        tap((x) => {
          Object.keys(x).forEach((key) => {
            this.causaliPagamento.push({
              value: key.toUpperCase(),
              label: x[key].value,
              labelWithValue: x[key].comment,
            });
          });
        }),
        take(1),
      )
      .subscribe();
  }

  public getList(): InvoiceMultiValues[] {
    return this.causaliPagamento;
  }
}

// const causaliDiPagamento = [
// 	{
// 		value: 'Y', label: 'Canoni corrisposti dal 1° gennaio 2005 al 26 luglio 2005 da società o enti residenti ovvero da stabili' +
// 			' organizzazioni' +
// 			' di società estere di cui all’art. 26-quater, comma 1, lett. a) e b) del D.P.R. n. 600 del 29 settembre 1973, a società o' +
// 			' stabili organizzazioni di società, situate in altro stato membro dell’Unione Europea in presenza dei requisiti di cui' +
// 			' al citato art. 26-quater, del D.P.R. n. 600 del 29 settembre 1973, per i quali è stato effettuato, nell’anno 2006, il' +
// 			' rimborso della ritenuta ai sensi dell’art. 4 del D.Lgs. 30 maggio 2005 n. 143'
// 	},
// 	{
// 		value: 'X',
// 		label: 'Canoni corrisposti nel 2004 da società o enti residenti ovvero da stabili organizzazioni di società estere di' +
// 			' cui' +
// 			' all’art. 26-quater, comma 1, lett. a) e b) del D.P.R. 600 del 29 settembre 1973, a società o stabili organizzazioni di' +
// 			' società, situate in altro stato membro dell’Unione Europea in presenza dei requisiti di cui al citato art. 26-quater, del' +
// 			' D.P.R. 600 del 29 settembre 1973, per i quali è stato effettuato, nell’anno 2006, il rimborso della ritenuta ai sensi dell’art. 4 del D.Lgs. 30 maggio 2005 n. 143'
// 	},
// 	{
// 		value: 'P', label: 'Compensi corrisposti a soggetti non residenti privi di stabile organizzazione per l’uso o la concessione in' +
// 			' uso di' +
// 			' attrezzature industriali, commerciali o scientifiche che si trovano nel territorio dello Stato ovvero a società svizzere o' +
// 			' stabili organizzazioni di società svizzere che possiedono i requisiti di cui all’art. 15, comma 2 dell’Accordo tra la' +
// 			' Comunità europea e la Confederazione svizzera del 26 ottobre 2004 (pubblicato in G.U.C.E. del 29 dicembre 2004 n. L385/30)'
// 	},
// 	{
// 		value: 'W',
// 		label: 'Corrispettivi erogati nel 2014 per prestazioni relative a contratti d’appalto cui si sono resi applicabili le' +
// 			' disposizioni' +
// 			' contenute nell’art. 25-ter del D.P.R. n. 600 del 29 settembre 1973'
// 	},
// 	{ value: 'I', label: 'Indennità corrisposte per la cessazione da funzioni notarili' },
// 	{
// 		value: 'H', label: 'Indennità corrisposte per la cessazione dei rapporti di agenzia delle persone fisiche e delle società di' +
// 			' persone con' +
// 			' esclusione delle somme maturate entro il 31 dicembre 2003, già imputate per competenza e tassate come reddito d’impresa'
// 	},
// 	{ value: 'G', label: 'Indennità corrisposte per la cessazione di attività sportiva professionale' },
// 	{
// 		value: 'N',
// 		label: 'Indennità di trasferta, rimborso forfetario di spese, premi e compensi erogati:\r\n– nell’esercizio diretto di' +
// 			' attività' +
// 			' sportive dilettantistiche;\r\n– in relazione a rapporti di collaborazione coordinata e continuativa di carattere' +
// 			' amministrativo-gestionale di natura non professionale resi a favore di società e associazioni sportive dilettantistiche e di cori,' +
// 			' bande e filodrammatiche da parte del direttore e dei collaboratori tecnici'
// 	},
// 	{ value: 'E', label: 'Levata di protesti cambiari da parte dei segretari comunali' },
// 	{ value: 'M', label: 'Prestazioni di lavoro autonomo non esercitate abitualmente' },
// 	{
// 		value: 'O', label: 'Prestazioni di lavoro autonomo non esercitate abitualmente, per le quali non sussiste l’obbligo di' +
// 			' iscrizione alla gestione separata (Circ. INPS n. 104/2001)'
// 	},
// 	{ value: 'A', label: 'Prestazioni di lavoro autonomo rientranti nell’esercizio di arte o professione abituale' },
// 	{ value: 'S', label: 'Provvigioni corrisposte a commissionario' },
// 	{
// 		value: 'V', label: 'Provvigioni corrisposte a incaricato per le vendite a domicilio; provvigioni corrisposte a' +
// 			' incaricato' +
// 			' per la vendita porta a porta e per la vendita ambulante di giornali quotidiani e periodici (L. 25 febbraio 1987,' +
// 			' n. 67)'
// 	},
// 	{ value: 'T', label: 'Provvigioni corrisposte a mediatore' },
// 	{ value: 'U', label: 'Provvigioni corrisposte a procacciatore di affari' },
// 	{ value: 'Q', label: 'Provvigioni corrisposte ad agente o rappresentante di commercio monomandatario' },
// 	{ value: 'R', label: 'Provvigioni corrisposte ad agente o rappresentante di commercio plurimandatario' },
// 	{
// 		value: 'V1', label: 'Redditi derivanti da attività commerciali non esercitate abitualmente (ad esempio, provvigioni corrisposte' +
// 			' per' +
// 			' prestazioni occasionali ad agente o rappresentante di commercio, mediatore, procacciatore d’affari o' +
// 			' incaricato per le vendite a domicilio)'
// 	},
// 	{ value: 'M1', label: 'Redditi derivanti dall’assunzione di obblighi di fare, di non fare o permettere' },
// 	{
// 		value: 'O1', label: 'Redditi derivanti dall’assunzione di obblighi di fare, di non fare o permettere, per le quali non sussiste' +
// 			' l’obbligo' +
// 			' di\r\niscrizione alla gestione separata (Circ. INPS n. 104/2001)'
// 	},
// 	{
// 		value: 'L1',
// 		label: 'Redditi derivanti dall’utilizzazione economica di opere dell’ingegno, di brevetti industriali e di processi,' +
// 			' formule e' +
// 			' informazioni relativi a esperienze acquisite in campo industriale, commerciale o' +
// 			' scientifico, che sono percepiti da soggetti che abbiano acquistato a titolo oneroso i diritti alla loro utilizzazione'
// 	},
// 	{
// 		value: 'L', label: 'Redditi derivanti dall’utilizzazione economica di opere dell’ingegno, di' +
// 			' brevetti' +
// 			' industriali e di processi, formule e informazioni relativi a esperienze acquisite in campo' +
// 			' industriale, commerciale o scientifico, che sono percepiti dagli aventi causa a titolo' +
// 			' gratuito (ad es. eredi e legatari dell’autore e inventore)'
// 	},
// 	{ value: 'Z', label: 'Titolo diverso dai precedenti' },
// 	{
// 		value: 'C', label: 'Utili derivanti da contratti di associazione in partecipazione e da' +
// 			' contratti di' +
// 			' cointeressenza, quando l’apporto è costituito esclusivamente dalla prestazione di lavoro'
// 	},
// 	{
// 		value: 'D', label: 'Utili spettanti ai soci promotori ed ai soci fondatori delle società di' +
// 			' capitali'
// 	},
// 	{
// 		value: 'B', label: 'Utilizzazione economica, da parte dell’autore o dell’inventore, di opere' +
// 			' dell’ingegno, di brevetti industriali e di processi, formule o informazioni relativi ad' +
// 			' esperienze acquisite in campo industriale, commerciale o scientifico'
// 	}
// ];
