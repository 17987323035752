import { Field } from 'app/shared/components/dry/field/field';
import { FieldType } from 'app/shared/components/dry/field/field-type';

export class SelectField extends Field {
  type = FieldType.SELECT;

  public selectValues?: unknown[];

  constructor(id: string, name: string, selectValues: unknown[]) {
    super(id, name);
    this.selectValues = selectValues;
  }
}
