import { Injectable } from '@angular/core';
import { UserService } from '@ctel/auth';
import { Observable, ReplaySubject, of, zip } from 'rxjs';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { CompaniesService } from '../../companies/companies.service';
import { DocumentPermissions } from './document-permissions.enum';
import { PermissionHttpService } from './permission-http.service';
import { Permissions } from './permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private defaultPermissions = new Permissions(
    new Set<DocumentPermissions>([DocumentPermissions.write, DocumentPermissions.read]),
  );

  private permissions$ = new ReplaySubject<string[]>(1);
  private userPermissions$ = new ReplaySubject<Permissions>(1);

  constructor(
    private userService: UserService,
    private permissionHttp: PermissionHttpService,
    private company: CompaniesService,
  ) {
    this.initUserPermissions();
  }

  whenPermissions(): Observable<string[]> {
    return this.permissions$.asObservable();
  }

  whenDocumentsPermissions(): Observable<Set<DocumentPermissions>> {
    return this.userPermissions$.asObservable().pipe(map((permissions) => permissions.documentPermissions));
  }

  initUserPermissions() {
    // return of(new Permissions(new Set());
    this.userService
      .getRoles()
      .pipe(
        map((userRoles) => {
          // TODO Assegnare defaultPermissions, con deepCopy si rompe
          const userPermissions = new Permissions(
            new Set<DocumentPermissions>([DocumentPermissions.write, DocumentPermissions.read]),
          );

          // Se l'utente ha come ruolo 'Platform.HideDocument', deve poter:
          //   - Visualizzare i documenti nascosti
          //   - Modificare la visibilità dei documenti
          const hiddenDocumentsRole = 'Platform.HideDocument';
          // Se l'utente ha come ruolo 'Platform.HideDocument', deve poter:
          //   - Riesportare i documenti verso gestionale
          const reExportToManagmentRole = 'Platform.ReExportDocument';
          // ruolo per mostrare azione singola "visualizza storico esiti" nel passivo
          const showOutcomesHistory = 'HUBFE.STORICO.ESITI.PASSIVO';
          // ruoli per mostrare il pulsante "Nuovo documento"
          const showNewDocument = 'Platform.editDocument';
          const showNewDocumentGAWNOTICE = 'GAWNOTICE.editDocument';
          const showNewDocumentGAWFE = 'GAWFE.editDocument';
          const showNewDocumentGAW30 = 'GAW30.editDocument';
          // ruolo per accedere alla sezione impostazioni
          const settingsAccess = 'Gaw.Impostazioni.Accesso';
          // ruolo per la creazione di report custom
          const createReport = 'Gaw.Impostazioni.CreaReport';
          // Ruolo di accesso per la rubrica
          const addressBook = 'Gaw.Impostazioni.Rubrica';
          const archivioLotti = 'Gaw.Impostazioni.ArchivioLotti';
          const quadratoreView = 'QUAFAT.VIEW';
          const quadratoreRead = 'QUAFAT.READ';
          const quadratoreWrite = 'QUAFAT.WRITE';
          const quadratoreConfig = 'QUAFAT.CONFIG';
          const quadratoreAdmin = 'QUAFAT.ADMIN';

          if (userRoles.has(hiddenDocumentsRole)) {
            userPermissions.documentPermissions.add(DocumentPermissions.viewHiddenFiles);
            userPermissions.documentPermissions.add(DocumentPermissions.toggleHiddenFiles);
          }
          if (userRoles.has(reExportToManagmentRole))
            userPermissions.documentPermissions.add(DocumentPermissions.reExportDocuments);

          if (userRoles.has(showOutcomesHistory))
            userPermissions.documentPermissions.add(DocumentPermissions.showOutcomesHistory);

          if (userRoles.has(showNewDocument))
            userPermissions.documentPermissions.add(DocumentPermissions.showNewDocument);

          if (userRoles.has(showNewDocumentGAWFE))
            userPermissions.documentPermissions.add(DocumentPermissions.showNewDocument);

          if (userRoles.has(showNewDocumentGAWNOTICE))
            userPermissions.documentPermissions.add(DocumentPermissions.showNewDocument);

          if (userRoles.has(showNewDocumentGAW30))
            userPermissions.documentPermissions.add(DocumentPermissions.showNewDocument);

          if (userRoles.has(settingsAccess))
            userPermissions.documentPermissions.add(DocumentPermissions.settingsAccess);

          if (userRoles.has(createReport)) userPermissions.documentPermissions.add(DocumentPermissions.createReport);

          if (userRoles.has(addressBook)) userPermissions.documentPermissions.add(DocumentPermissions.addressBook);

          if (userRoles.has(archivioLotti)) userPermissions.documentPermissions.add(DocumentPermissions.archivioLotti);

          if (
            userRoles.has(quadratoreView) ||
            userRoles.has(quadratoreWrite) ||
            userRoles.has(quadratoreRead) ||
            userRoles.has(quadratoreAdmin) ||
            userRoles.has(quadratoreConfig)
          )
            userPermissions.documentPermissions.add(DocumentPermissions.quadratore);

          return userPermissions;
        }),
        concatMap((value) => zip(this.userService.getUser(), this.company.whenCurrentCompany(), of(value))),
        concatMap(([user, company, permissions]) =>
          zip(
            of(permissions),
            this.permissionHttp.getPermissions(user.id.toString(), company.licenseId, company.siaCode).pipe(take(1)),
          ),
        ),
        tap(([, userPermissions]) => this.permissions$.next(userPermissions ?? [])),
        map(([permissions, userPermissions]) => {
          if (userPermissions) {
            if (userPermissions.includes('Gaw.Azioni.GAW30.NascondiRipristinaDocumento')) {
              permissions.documentPermissions.add(DocumentPermissions.viewHiddenFiles);
              permissions.documentPermissions.add(DocumentPermissions.toggleHiddenFiles);
            }
            if (userPermissions.includes('Gaw.Azioni.RiesportaDocumento'))
              permissions.documentPermissions.add(DocumentPermissions.reExportDocuments);

            if (userPermissions.includes('Gaw.Azioni.StoricoEsitiPassivo'))
              permissions.documentPermissions.add(DocumentPermissions.showOutcomesHistory);

            if (
              userPermissions.includes('Gaw.Azioni.GAWNOTICE.ModificaDocumento') ||
              userPermissions.includes('Gaw.Azioni.GAWFE.ModificaDocumento') ||
              userPermissions.includes('Gaw.Azioni.GAW30.ModificaDocumento')
            )
              permissions.documentPermissions.add(DocumentPermissions.showNewDocument);

            if (
              userPermissions.includes('Gaw.Impostazioni.Dipendenti') ||
              userPermissions.includes('Gaw.Impostazioni.CreaReport')
            )
              permissions.documentPermissions.add(DocumentPermissions.settingsAccess);

            if (userPermissions.includes('Gaw.Impostazioni.CreaReport'))
              permissions.documentPermissions.add(DocumentPermissions.createReport);

            if (userPermissions.includes('Gaw.Impostazioni.Rubrica'))
              permissions.documentPermissions.add(DocumentPermissions.addressBook);

            if (userPermissions.includes('Gaw.Impostazioni.ArchivioLotti'))
              permissions.documentPermissions.add(DocumentPermissions.archivioLotti);

            if (userPermissions.includes('Gaw.Azioni.ArchivioFiscale.VisualizzaDettaglio'))
              permissions.documentPermissions.add(DocumentPermissions.archivioFiscaleDettaglio);

            if (userPermissions.includes('Gaw.Azioni.PDD.Scarica'))
              permissions.documentPermissions.add(DocumentPermissions.pddScarica);

            if (userPermissions.includes('Gaw.Azioni.PDV.VisualizzaDettaglio'))
              permissions.documentPermissions.add(DocumentPermissions.PDVDettaglio);

            if (userPermissions.includes('Gaw.Azioni.Cestino'))
              permissions.documentPermissions.add(DocumentPermissions.cestino);
          }
          return permissions;
        }),
      )
      .subscribe((permissions) => {
        this.userPermissions$.next(permissions);
      });
  }
}
