import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'words',
})
export class WordsPipe implements PipeTransform {
  transform(value: string, wordsNumber: number): string {
    const words = value.split(/\s+/, wordsNumber);
    const separators = value.match(/\s+/g);
    let res = '';
    words.forEach((w, i) => {
      res += w;
      res += separators[i];
    });
    return res;
  }
}
