import { Field } from 'app/shared/components/dry/field/field';
import { FieldType } from 'app/shared/components/dry/field/field-type';
import { BehaviorSubject, isObservable, Observable } from 'rxjs';

export class TypeAheadField extends Field {
  type = FieldType.TYPEAHEAD;

  public typeAheadField?: string;
  public typeAheadValues: BehaviorSubject<unknown[]> = new BehaviorSubject([]);

  public useAsyncValue = false;

  constructor(id: string, name: string, field: string, values: unknown[] | Observable<unknown[]>) {
    super(id, name);
    this.typeAheadField = field;

    if (isObservable(values)) values.subscribe((data) => this.typeAheadValues.next(data));
    else this.typeAheadValues.next(values);
  }
}
