import { Component } from '@angular/core';
import { PageContent } from 'app/core/common/utilities/contents/page-content';
import { ErrorPages } from '../error-pages';

@Component({
  selector: 'dry-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent {
  public pageContent: PageContent = ErrorPages.pages.get('403-forbidden');
}
