import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Note } from '../entities/note';
import { DocumentNotesHttpService } from '../services/document-notes-http.service';
import * as DocumentNotesActions from './document-notes.actions';
import { getNotesRequestParams, getState } from './document-notes.selector';

@Injectable()
export class DocumentNotesEffect {
  whenDocumentNotesRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.fetchDocumentNotes),
      switchMap(() => this.getDocumentNotes().pipe(map((notes) => DocumentNotesActions.setNotes(notes)))),
    ),
  );

  whenRequestChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.setDocumentId, DocumentNotesActions.setSorting),
      map(() => DocumentNotesActions.fetchDocumentNotes()),
    ),
  );

  onNoteDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.deleteNote),
      map((val) => val.payload),
      switchMap((payload) =>
        this.deleteNote(payload.progSpool, payload.progBusta, payload.idSerieDoc, payload.note).pipe(
          map(() => DocumentNotesActions.fetchDocumentNotes()),
        ),
      ),
    ),
  );

  onNoteCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.createNote),
      map((value) => value.payload),
      switchMap((payload) =>
        this.createNote(payload.progSpool, payload.progBusta, payload.idSerieDoc, payload.message).pipe(
          map(() => DocumentNotesActions.fetchDocumentNotes()),
        ),
      ),
    ),
  );

  onNoteUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.updateNote),
      map((val) => val.payload),
      switchMap((payload) =>
        this.updateNote(payload.progSpool, payload.progBusta, payload.idSerieDoc, payload.noteId, payload.message).pipe(
          map(() => DocumentNotesActions.fetchDocumentNotes()),
        ),
      ),
    ),
  );

  restoreTextArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.deleteNote, DocumentNotesActions.updateNote, DocumentNotesActions.createNote),
      map(() => DocumentNotesActions.restoreTextAreaValue()),
    ),
  );

  setEditModeOnNoteSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.selectNote),
      filter(
        (action) =>
          action.payload.selectedNote !== undefined &&
          action.payload.userId.toString() === action.payload.selectedNote.authUserId,
      ),
      map(() => DocumentNotesActions.setMode('edit')),
    ),
  );

  setReadonlyModeOnNoteSelect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.selectNote),
      filter(
        (action) =>
          action.payload.selectedNote !== undefined &&
          action.payload.userId.toString() !== action.payload.selectedNote.authUserId,
      ),
      map(() => DocumentNotesActions.setMode('readonly')),
    ),
  );

  whenNewModeSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.setMode),
      filter((value) => value.payload.mode === 'new'),
      map(() => DocumentNotesActions.selectNote(undefined, undefined)),
    ),
  );

  createNoteOnTextAreaSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.submitTextArea),
      withLatestFrom(this.store.pipe(select(getState))),
      filter(([action, store]) => action.payload.noteMsg !== '' && store.state.mode === 'new'),
      map(([action, store]) =>
        DocumentNotesActions.createNote(
          store.state.progSpool,
          store.state.progBusta,
          store.state.idSerieDoc,
          action.payload.noteMsg,
        ),
      ),
    ),
  );

  updateNoteOnTextAreaSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.submitTextArea),
      withLatestFrom(this.store.pipe(select(getState))),
      filter(([action, store]) => action.payload.noteMsg !== '' && store.state.mode === 'edit'),
      map(([action, store]) =>
        DocumentNotesActions.updateNote(
          store.state.progSpool,
          store.state.progBusta,
          store.state.idSerieDoc,
          store.state.selectedNote.noteId,
          action.payload.noteMsg,
        ),
      ),
    ),
  );

  deleteNoteOnTextAreaSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentNotesActions.submitTextArea),
      withLatestFrom(this.store.pipe(select(getState))),
      filter(([action, selector]) => action.payload.noteMsg === '' && selector.state.mode === 'edit'),
      map(([, selector]) =>
        DocumentNotesActions.deleteNote(
          selector.state.selectedNote,
          selector.state.progSpool,
          selector.state.progBusta,
          selector.state.idSerieDoc,
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<unknown>,
    private documentNotesHttpService: DocumentNotesHttpService,
  ) {}

  private getDocumentNotes(): Observable<Note[]> {
    return this.store.pipe(
      select(getNotesRequestParams),
      filter((params) => params.progSpool !== null && params.progBusta !== null),
      switchMap((params) => this.documentNotesHttpService.getNotes(params.progSpool, params.progBusta, params.sorting)),
      take(1),
      map((res) => {
        if (res.notes) return res.notes.map((e) => new Note(e));

        return [];
      }),
    );
  }

  private deleteNote(progSpool: number, progBusta: number, idSerieDoc: number, note: Note) {
    return this.documentNotesHttpService.deleteNote(progSpool, progBusta, idSerieDoc, note.noteId).pipe(take(1));
  }

  private createNote(progSpool: number, progBusta: number, idSerieDoc: number, message: string) {
    return this.documentNotesHttpService.createNote(progSpool, progBusta, idSerieDoc, message).pipe(take(1));
  }

  private updateNote(progSpool: number, progBusta: number, idSerieDoc: number, noteId: number, message: string) {
    return this.documentNotesHttpService.updateNote(progSpool, progBusta, idSerieDoc, noteId, message).pipe(take(1));
  }
}
