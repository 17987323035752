import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';

@Injectable()
export class CancelChannelHttpService {
  // endpoints
  public cancelChannelGedfattHost: string;
  private docDetailsHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.docDetailsHost = appConfig.docDetails.http.host;
    this.cancelChannelGedfattHost = `${this.docDetailsHost}/v1/Document/GEDINVOICE/AnnullaElaborazione`;
  }

  public cancelChannel(progSpool_, progBusta_, docHash_, documentChannelType_, errorChannel) {
    const documents: DocumentsCancel[] = [{}];
    let url;
    const httpOptions = {
      params: {
        documentChannelType: documentChannelType_,
      },
    };
    switch (errorChannel) {
      case 'gedfatt':
        // documentChannelType: Attivo or passivo
        url = this.cancelChannelGedfattHost;
        documents[0].progSpool = progSpool_;
        documents[0].progBusta = progBusta_;
        documents[0].hashDoch = docHash_;
        return this.http.post(url, documents, httpOptions);
      case 'gedpec':
        url = `${this.docDetailsHost}/v1/Document/GEDPEC/AnnullaElaborazione`;
        documents[0].progSpool = progSpool_;
        documents[0].progBusta = progBusta_;
        documents[0].hashDoch = docHash_;
        return this.http.post(url, documents);
      case 'gedmail':
        url = `${this.docDetailsHost}/v1/Document/GEDMAIL/AnnullaElaborazione`;
        documents[0].progSpool = progSpool_;
        documents[0].progBusta = progBusta_;
        documents[0].hashDoch = docHash_;
        return this.http.post(url, documents);
      case 'gedpost':
        url = `${this.docDetailsHost}/v1/Document/GEDPOST/AnnullaElaborazione`;
        documents[0].progSpool = progSpool_;
        documents[0].progBusta = progBusta_;
        documents[0].hashDoch = docHash_;
        return this.http.post(url, documents);
    }
  }
}

export interface DocumentsCancel {
  progSpool?: number;
  progBusta?: number;
  hashDoch?: string;
}
