import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@ctel/auth';
import { PermissionService } from 'app/core/business/user/permissions/permission.service';
import { Observable, map, tap } from 'rxjs';

@Injectable()
export class PermissionGuard {
  constructor(
    private authService: AuthService,
    private permissionService: PermissionService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const permissionRequired = next.data.permissionRequired;

    return this.permissionService.whenDocumentsPermissions().pipe(
      map((documentsPermissions) => documentsPermissions.has(permissionRequired)),
      tap((hasPermissionRequired) => {
        if (!hasPermissionRequired) this.authService.navigateToForbidden();
      }),
    );
  }
}
