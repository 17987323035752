import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NewsAndNotificationsHttpService } from './news-and-notifications-http.service';
import { NewsAndNotificationsService } from './news-and-notifications.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class NewsAndNotificationsModule {
  static forRoot(): ModuleWithProviders<NewsAndNotificationsModule> {
    return {
      ngModule: NewsAndNotificationsModule,
      providers: [NewsAndNotificationsService, NewsAndNotificationsHttpService],
    };
  }
}
