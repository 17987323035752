import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateValidators } from 'app/shared/components/dry/validator/date-validators';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';
import { TextValidators } from 'app/shared/components/dry/validator/text-validators';
import dayjs from 'dayjs';
import { CausaleValidator } from '../../../../../../shared/components/validator/dati-generali-documento/causale-validator';

@Injectable()
export class InvoiceFormService {
  private dateFormat = 'YYYY-MM-DD';

  constructor(private fb: UntypedFormBuilder) {}

  public getFatturaElettronicaFormGroup(): UntypedFormGroup {
    return this.fb.group({
      FatturaElettronicaHeader: this.getFatturaElettronicaHeaderFormGroup(),
      FatturaElettronicaBody: this.getFatturaElettronicaBodyFormGroup(),
    });
  }

  public getFatturaElettronicaHeaderFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiTrasmissione: this.getDatiTrasmissioneFormGroup(),
      CedentePrestatore: this.getCedentePrestatoreFormGroup(),
      RappresentanteFiscale: this.getRappresentanteFiscaleOrTerzoIntermediarioFormGroup(),
      CessionarioCommittente: this.getCessionarioCommittenteFormGroup(),
      TerzoIntermediarioOSoggettoEmittente: this.getRappresentanteFiscaleOrTerzoIntermediarioFormGroup(),
      SoggettoEmittente: [null],
    });
  }

  public getFatturaElettronicaBodyFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiGenerali: this.getDatiGeneraliFormGroup(),
      DatiVeicoli: this.getDatiVeicoliFormGroup(),
      DatiBeniServizi: this.getDatiBeniServiziForm(),
      DatiPagamento: this.fb.array([this.getDatiPagamentoForm()]),
      Allegati: this.fb.array([]),
    });
  }

  // Cedente prestatore
  public getCedentePrestatoreFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiAnagrafici: this.fb.group({
        AlboProfessionale: [null, [Validators.minLength(1), Validators.maxLength(60)]],
        ProvinciaAlbo: [null],
        NumeroIscrizioneAlbo: [null, [Validators.minLength(1), Validators.maxLength(60)]],
        DataIscrizioneAlbo: [null],
        RegimeFiscale: [null, Validators.required],
        IdFiscaleIVA: this.fb.group({
          IdPaese: [null, Validators.required],
          IdCodice: [null, [Validators.maxLength(28), Validators.required]],
        }),
        CodiceFiscale: [null, [Validators.minLength(11), Validators.maxLength(16), TextValidators.onlyLetterAndNumber]],
        Anagrafica: this.fb.group({
          Denominazione: [null, [Validators.minLength(1), Validators.maxLength(80)]],
          Nome: [null],
          Cognome: [null],
          Titolo: [null],
          CodEORI: [null, [Validators.minLength(13), Validators.maxLength(17)]],
        }),
      }),
      Sede: this.getIndirizzoFormGroupRequired(),
      StabileOrganizzazione: this.getIndirizzoFormGroupNotRequired(),
      IscrizioneREA: this.fb.group({
        Ufficio: [null],
        NumeroREA: [null, [Validators.minLength(1), Validators.maxLength(20)]],
        CapitaleSociale: [null],
        SocioUnico: [null],
        StatoLiquidazione: [null],
      }),
      Contatti: this.fb.group({
        Telefono: [null, TextValidators.patternFaxAndTelephone],
        Fax: [null, TextValidators.patternFaxAndTelephone],
        Email: [null, [Validators.minLength(7), Validators.maxLength(256), TextValidators.patternEmail]],
      }),
      RiferimentoAmministrazione: [null, [Validators.minLength(1), Validators.maxLength(20)]],
    });
  }

  // Cessionario committente
  public getCessionarioCommittenteFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiAnagrafici: this.getDatiAnagraficiFormGroupRequired(),
      Sede: this.getIndirizzoFormGroupRequired(),
      StabileOrganizzazione: this.getIndirizzoFormGroupNotRequired(),
      RappresentanteFiscale: this.fb.group({
        IdFiscaleIVA: this.fb.group({
          IdPaese: [null],
          IdCodice: [null, [Validators.maxLength(28)]],
        }),
        Denominazione: [null, [Validators.minLength(1), Validators.maxLength(80)]],
        Nome: [null],
        Cognome: [null],
      }),
    });
  }

  // Dati Trasmissione
  public getDatiTrasmissioneFormGroup(): UntypedFormGroup {
    return this.fb.group({
      CodiceDestinatario: [null, [Validators.required, TextValidators.pattern('[A-Z0-9]{6,7}')]],
      FormatoTrasmissione: [null, Validators.required],
      ProgressivoInvio: [null],
      IdTrasmittente: this.fb.group({
        IdPaese: [null],
        IdCodice: [null],
      }),
      ContattiTrasmittente: this.fb.group({
        Email: [null],
        Telefono: [null],
      }),
      PECDestinatario: [null, [TextValidators.patternEmail, TextValidators.pecDestinatario]],
    });
  }

  public getDatiPagamentoForm(): UntypedFormGroup {
    return this.fb.group({
      CondizioniPagamento: [null],
      DettaglioPagamento: this.fb.array([this.getDettaglioPagamentoFormGroup()]),
    });
  }

  public getDettaglioPagamentoFormGroup(): UntypedFormGroup {
    return this.fb.group({
      Beneficiario: [null, [Validators.minLength(1), Validators.maxLength(200)]],
      ModalitaPagamento: [null],
      DataRiferimentoTerminiPagamento: [null],
      GiorniTerminiPagamento: [null, Validators.max(999)],
      DataScadenzaPagamento: [null],
      ImportoPagamento: [
        null,
        {
          validators: [NumberValidators.negativeDecimalReg(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      CodUfficioPostale: [null, [Validators.minLength(1), Validators.maxLength(20)]],
      CognomeQuietanzante: [null, [Validators.minLength(1), Validators.maxLength(60)]],
      NomeQuietanzante: [null, [Validators.minLength(1), Validators.maxLength(60)]],
      CFQuietanzanate: [null, TextValidators.pattern('[A-Z0-9]{16}')],
      TitoloQuietanzante: [null],
      IstitutoFinanziario: [null, [Validators.minLength(1), Validators.maxLength(80)]],
      IBAN: [null, TextValidators.pattern('[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{11,30}')],
      ABI: [null, TextValidators.pattern('[0-9][0-9][0-9][0-9][0-9]')],
      BIC: [null, TextValidators.pattern('[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3}){0,1}')],
      CAB: [null, TextValidators.pattern('[0-9][0-9][0-9][0-9][0-9]')],
      ScontoPagamentoAnticipato: [
        null,
        {
          validators: [NumberValidators.negativeDecimalReg(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      DataLimitePagamentoAnticipato: [null],
      PenalitaPagamentiRitardati: [
        null,
        {
          validators: [NumberValidators.negativeDecimalReg(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      DataDecorrenzaPenale: [null],
      CodicePagamento: [null],
    });
  }

  // Allegati
  public getAllegatiFormGroup(): UntypedFormGroup {
    return this.fb.group({
      NomeAttachment: [null],
      AlgoritmoCompressione: [null],
      FormatoAttachment: [null],
      DescrizioneAttachment: [null, [Validators.minLength(1), Validators.maxLength(100)]],
      Attachment: [null],
    });
  }

  // Dati generali
  public getDatiGeneraliFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiGeneraliDocumento: this.getDatiGeneraliDocumentoFormGroup(),
      DatiOrdineAcquisto: this.fb.array([this.getDatiDocumentiCorrelatiFormGroup()]),
      DatiContratto: this.fb.array([this.getDatiDocumentiCorrelatiFormGroup()]),
      DatiConvenzione: this.fb.array([this.getDatiDocumentiCorrelatiFormGroup()]),
      DatiRicezione: this.fb.array([this.getDatiDocumentiCorrelatiFormGroup()]),
      DatiFattureCollegate: this.fb.array([this.getDatiDocumentiCorrelatiFormGroup()]),
      DatiSAL: this.fb.array([this.getDatiSalFormGroup()]),
      DatiDDT: this.fb.array([this.getDatiDdtFormGroup()]),
      DatiTrasporto: this.getDatiTrasportoFormGroup(),
      FatturaPrincipale: this.getDatiFatturaPrinicipale(),
    });
  }

  // Dati Veicoli
  public getDatiVeicoliFormGroup(): UntypedFormGroup {
    return this.fb.group({
      Data: [null],
      TotalePercorso: [null, Validators.maxLength(15)],
    });
  }

  // dati beni servizi
  public getDatiBeniServiziForm(): UntypedFormGroup {
    return this.fb.group({
      DettaglioLinee: this.fb.array([this.getDettaglioLineeFormGroup]),
      DatiRiepilogo: this.fb.array([this.getDatiRiepilogoFormGroup]),
    });
  }

  public getDettaglioLineeFormGroup(): UntypedFormGroup {
    return this.fb.group({
      NumeroLinea: [null, Validators.required],
      Descrizione: [null, Validators.required],
      PrezzoUnitario: [
        null,
        {
          validators: [Validators.required, NumberValidators.negativeDecimalReg(8), NumberValidators.decimalLength(8)],
          updateOn: 'blur',
        },
      ],
      PrezzoTotale: [
        null,
        {
          validators: [Validators.required, NumberValidators.negativeDecimalReg(8), NumberValidators.decimalLength(8)],
          updateOn: 'blur',
        },
      ],
      AliquotaIVA: [
        null,
        {
          validators: [Validators.required, NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      Ritenuta: [null],
      TipoCessionePrestazione: [null],
      Quantita: [
        null,
        {
          validators: [NumberValidators.maxDecimal(8), NumberValidators.decimalLength(8)],
          updateOn: 'blur',
        },
      ],
      UnitaMisura: [null, [Validators.minLength(1), Validators.maxLength(10)]],
      DataInizioPeriodo: [null],
      DataFinePeriodo: [null],
      Natura: [null],
      RiferimentoAmministrazione: [null, [Validators.minLength(1), Validators.maxLength(20)]],
      CodiceArticolo: this.fb.array([this.getCodiceArticoloFormGroup()]),
      ScontoMaggiorazione: this.fb.array([this.getScontoMaggiorazioneFormGroup()]),
      AltriDatiGestionali: this.fb.array([this.getAltriDatiGestionaliFormGroup()]),
    });
  }

  public getDatiRiepilogoFormGroup(): UntypedFormGroup {
    return this.fb.group({
      AliquotaIVA: [
        null,
        {
          validators: [Validators.required, NumberValidators.maxDecimal(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      Natura: [null],
      SpeseAccessorie: [
        null,
        {
          validators: [NumberValidators.negativeDecimalReg(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      Arrotondamento: [
        null,
        {
          validators: [NumberValidators.negativeDecimalReg(8), NumberValidators.decimalLength(8)],
          updateOn: 'blur',
        },
      ],
      ImponibileImporto: [
        null,
        {
          validators: [Validators.required, NumberValidators.negativeDecimalReg(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      Imposta: [
        null,
        {
          validators: [Validators.required, NumberValidators.negativeDecimalReg(2), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      EsigibilitaIVA: [null],
      RiferimentoNormativo: [null, [Validators.minLength(1), Validators.maxLength(100)]],
    });
  }

  public getCodiceArticoloFormGroup(): UntypedFormGroup {
    return this.fb.group({
      CodiceValore: [null, [Validators.minLength(1), Validators.maxLength(35)]],
      CodiceTipo: [null],
    });
  }

  public getAltriDatiGestionaliFormGroup(): UntypedFormGroup {
    return this.fb.group({
      TipoDato: [null],
      RiferimentoNumero: [null],
      RiferimentoTesto: [null],
      RiferimentoData: [null],
    });
  }

  public getDatiRitenutaFormGroup(): UntypedFormGroup {
    return this.fb.group({
      TipoRitenuta: [null],
      CausalePagamento: [null],
      ImportoRitenuta: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      AliquotaRitenuta: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
    });
  }

  public getDatiCassaFormGroup(): UntypedFormGroup {
    return this.fb.group({
      TipoCassa: [null],
      AlCassa: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      ImportoContributoCassa: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      AliquotaIVA: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      Natura: [null],
      ImponibileCassa: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      Ritenuta: [null],
      RifAmministrazione: [null],
    });
  }

  public getScontoMaggiorazioneFormGroup(): UntypedFormGroup {
    return this.fb.group({
      Tipo: [null],
      Percentuale: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.maxDecimal(2)],
          updateOn: 'blur',
        },
      ],
      Importo: [
        null,
        {
          validators: [NumberValidators.decimalLength(8), NumberValidators.negativeDecimalReg(8)],
          updateOn: 'blur',
        },
      ],
    });
  }

  public getDatiDocumentiCorrelatiFormGroup(): UntypedFormGroup {
    return this.fb.group({
      IdDocumento: [null, [Validators.minLength(1), Validators.maxLength(200), TextValidators.pattern('[\x00-\x7F]*')]],
      Data: [null, DateValidators.isDate(this.dateFormat)],
      CodiceCommessaConvenzione: [null, [Validators.minLength(1), Validators.maxLength(100)]],
      CodiceCUP: [null, [Validators.minLength(1), Validators.maxLength(15)]],
      NumItem: [null, [Validators.minLength(1), Validators.maxLength(20)]],
      CodiceCIG: [null, [Validators.minLength(1), Validators.maxLength(15)]],
      RiferimentoNumeroLinea: [[]],
    });
  }

  public getDatiSalFormGroup(): UntypedFormGroup {
    return this.fb.group({
      RiferimentoFase: [null, Validators.maxLength(3)],
    });
  }

  public getDatiDdtFormGroup(): UntypedFormGroup {
    return this.fb.group({
      NumeroDDT: [null],
      DataDDT: [null],
      RiferimentoNumeroLinea: [[]],
    });
  }

  // Rappresentante fiscale
  private getRappresentanteFiscaleOrTerzoIntermediarioFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiAnagrafici: this.getDatiAnagraficiFormGroupNoRequired(),
    });
  }

  private getIndirizzoFormGroupRequired(): UntypedFormGroup {
    return this.fb.group({
      Indirizzo: [null, [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
      NumeroCivico: [null, [Validators.minLength(1), Validators.maxLength(8)]],
      CAP: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      Comune: [null, Validators.required],
      Provincia: [null],
      Nazione: [null, Validators.required],
    });
  }

  private getIndirizzoFormGroupNotRequired(): UntypedFormGroup {
    return this.fb.group({
      Indirizzo: [null, [Validators.minLength(1), Validators.maxLength(60)]],
      NumeroCivico: [null, [Validators.minLength(1), Validators.maxLength(8)]],
      CAP: [null, [Validators.minLength(5), Validators.maxLength(5)]],
      Comune: [null],
      Provincia: [null],
      Nazione: [null],
    });
  }

  private getDatiAnagraficiFormGroupRequired(): UntypedFormGroup {
    return this.fb.group({
      IdFiscaleIVA: this.fb.group({
        IdPaese: [null, Validators.required],
        IdCodice: [null, [Validators.maxLength(28), Validators.required]],
      }),
      CodiceFiscale: [
        null,
        [Validators.minLength(11), Validators.maxLength(16), TextValidators.onlyLetterAndNumber, Validators.required],
      ],
      Anagrafica: this.fb.group({
        Denominazione: [null, [Validators.minLength(1), Validators.maxLength(80)]],
        Nome: [null],
        Cognome: [null],
        Titolo: [null],
        CodEORI: [null, [Validators.minLength(13), Validators.maxLength(17)]],
      }),
    });
  }

  private getDatiAnagraficiFormGroupNoRequired(): UntypedFormGroup {
    return this.fb.group({
      IdFiscaleIVA: this.fb.group({
        IdPaese: [null],
        IdCodice: [null, [Validators.maxLength(28)]],
      }),
      CodiceFiscale: [null, [Validators.minLength(11), Validators.maxLength(16), TextValidators.onlyLetterAndNumber]],
      Anagrafica: this.fb.group({
        Denominazione: [null, [Validators.minLength(1), Validators.maxLength(80)]],
        Nome: [null],
        Cognome: [null],
        Titolo: [null],
        CodEORI: [null, [Validators.minLength(13), Validators.maxLength(17)]],
      }),
    });
  }

  private getDatiGeneraliDocumentoFormGroup(): UntypedFormGroup {
    return this.fb.group({
      TipoDocumento: [null, Validators.required],
      Divisa: [null, Validators.required],
      Data: [null, [Validators.required, DateValidators.maxDate(dayjs().format('YYYY-MM-DD'))]],
      Numero: [
        null,
        [Validators.required, Validators.minLength(1), Validators.maxLength(20), TextValidators.atLeastOneNumber],
      ],
      DatiRitenuta: this.fb.array([this.getDatiRitenutaFormGroup()]),
      DatiBollo: this.fb.group({
        BolloVirtuale: [null],
        ImportoBollo: [
          null,
          {
            validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
            updateOn: 'blur',
          },
        ],
      }),
      DatiCassaPrevidenziale: this.fb.array([this.getDatiCassaFormGroup()]),
      ScontoMaggiorazione: this.fb.array([this.getScontoMaggiorazioneFormGroup()]),
      ImportoTotaleDocumento: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      Arrotondamento: [
        null,
        {
          validators: [NumberValidators.decimalLength(2), NumberValidators.negativeDecimalReg(2)],
          updateOn: 'blur',
        },
      ],
      Causale: [[], [CausaleValidator()]],
      Art73: [null],
    });
  }

  private getDatiTrasportoFormGroup(): UntypedFormGroup {
    return this.fb.group({
      DatiAnagraficiVettore: this.getDatiAnagraficiVettore(),
      MezzoTrasporto: [null, [Validators.minLength(1), Validators.maxLength(80)]],
      CausaleTrasporto: [null, [Validators.minLength(1), Validators.maxLength(100)]],
      NumeroColli: [null, [Validators.minLength(1), Validators.maxLength(4)]],
      Descrizione: [null, [Validators.minLength(1), Validators.maxLength(100)]],
      UnitaMisuraPeso: [null, [Validators.minLength(1), Validators.maxLength(10)]],
      PesoLordo: [
        null,
        {
          validators: [Validators.max(9999.99), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      PesoNetto: [
        null,
        {
          validators: [Validators.max(9999.99), NumberValidators.decimalLength(2)],
          updateOn: 'blur',
        },
      ],
      DataOraRitiro: [
        null,
        TextValidators.pattern('\\d{4}-[01]\\d-[0-3]\\dT[0-2](\\d:[0-5])?\\d:[0-5]\\d(?:\\.\\d+)?Z?'),
      ],
      DataInizioTrasporto: [null],
      TipoResa: [null, [Validators.minLength(3), Validators.maxLength(3), TextValidators.onlyLetterAndNumber]],
      IndirizzoResa: this.getIndirizzoFormGroupNotRequired(),
      DataOraConsegna: [
        null,
        TextValidators.pattern('\\d{4}-[01]\\d-[0-3]\\dT[0-2](\\d:[0-5])?\\d:[0-5]\\d(?:\\.\\d+)?Z?'),
      ],
    });
  }

  private getDatiAnagraficiVettore(): UntypedFormGroup {
    const formGroupDatiAnagrafici = this.getDatiAnagraficiFormGroupNoRequired();
    formGroupDatiAnagrafici.addControl(
      'NumeroLicenzaGuida',
      this.fb.control(null, [Validators.minLength(1), Validators.maxLength(20)]),
    );
    const formGroupDatiAnagraficiVettore = formGroupDatiAnagrafici;
    return formGroupDatiAnagraficiVettore;
  }

  private getDatiFatturaPrinicipale(): UntypedFormGroup {
    return this.fb.group({
      NumeroFatturaPrincipale: [null, [Validators.minLength(1), Validators.maxLength(20)]],
      DataFatturaPrincipale: [null],
    });
  }
}
