import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { AllDocumentsCallback } from 'app/modules/homepage/core/documents-search/actions/callbacks/all-documents-callback';

@Injectable({
  providedIn: 'root',
})
export class MassiveActionsCatalog {
  public readonly downloadPdfZipMassive = new ActionBuilder(
    MassiveActionCode.downloadPdfZipMassive,
    ActionCode.DOWNLOAD_PDF_ZIP,
  )
    .setActionURL('/v1/Document/MassiveAction?clientid=HUBFE')
    .setCallback((action: ActionObject, payload, serviceId, account) =>
      this.allDocumentsCallback.downloadPdfZip(action, payload, serviceId, account),
    )
    .build();

  public readonly downloadXmlZipGedinvoiceMassive = new ActionBuilder(
    MassiveActionCode.downloadXmlZipGedinvoiceMassive,
    ActionCode.DOWNLOAD_XML_ZIP,
  )
    .setActionURL('/v1/Document/MassiveAction?clientid=HUBFE')
    .setCallback((action: ActionObject, payload, serviceId, account) =>
      this.allDocumentsCallback.downloadXmlZip(action, payload, serviceId, account),
    )
    .build();

  public readonly downloadXmlZipGedpassjoinMassive = new ActionBuilder(
    MassiveActionCode.downloadXmlZipGedpassjoinMassive,
    ActionCode.DOWNLOAD_XML_ZIP,
  )
    .setActionURL('/v1/Document/MassiveAction?clientid=HUBFE')
    .setCallback((action: ActionObject, payload, serviceId, account) =>
      this.allDocumentsCallback.downloadXmlZip(action, payload, serviceId, account),
    )
    .build();

  public readonly exportExcel = new ActionBuilder(MassiveActionCode.exportExcel, ActionCode.DOWNLOAD_EXCEL)
    .setActionURL('/v2/documents/export/report/excel')
    .setCallback((action: ActionObject, extraParams?: any) => this.allDocumentsCallback.exportXLSX(action, extraParams))
    .build();

  constructor(private allDocumentsCallback: AllDocumentsCallback) {}
}

export enum MassiveActionCode {
  exportExcel = 'exportExcel',
  downloadPdfZipMassive = 'downloadPdfZipMassive',
  downloadXmlZipGedinvoiceMassive = 'downloadXmlZipGedinvoiceMassive',
  downloadXmlZipGedpassjoinMassive = 'downloadXmlZipGedpassjoinMassive',
}
