import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AsyncActionsHttpService } from '../../business/actions/actions-http.service';
import { AppErrorBuilder, ErrorTypes } from '../error';
import { NotificationType } from '../notification';
import { NotificationService } from '../notification/notification.service';
import { FileSaver } from '../utilities/file-saver/file-saver';
import { FileSaverExtension } from '../utilities/file-saver/file-saver-extension.enum';

enum Actions {
  MASSIVE_DOWNLOAD = 'massiveDownload',
  EMPLOYEE_LOG = 'employeeLog',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationAsyncActionsGuard {
  constructor(
    private actionsHttpService: AsyncActionsHttpService,
    private notificationService: NotificationService,
    private transferService: TransferService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const action = next.paramMap.get('action');

    return this.executeAction(action, next).pipe(map(() => false));
  }
  private fileSaver = (result: Transfer, requestId: string, chunkNumber: string) => {
    if (TransferUtils.isHttpResponse(result.originatingEvent)) {
      const blob = new Blob([result.originatingEvent.body]);

      let fileName = result.name;
      if (!fileName) {
        fileName = `${requestId}_${chunkNumber}.zip`;
        this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.ZIP);
      }

      new FileSaver(blob).saveAs(fileName);
    }
  };
  private executeAction(action: string, next: ActivatedRouteSnapshot): Observable<any> {
    switch (action) {
      case Actions.MASSIVE_DOWNLOAD: {
        const uiCode = next.queryParamMap.get('uiCode');
        const requestId = next.queryParamMap.get('requestId');
        const chunkNumber = next.queryParamMap.get('chunkNumber');
        return this.actionsHttpService.chunkMassiveDownload(uiCode, requestId, chunkNumber).pipe(
          tap((result: Transfer) => this.fileSaver(result, requestId, chunkNumber)),
          catchError(() => {
            this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download', '');
            return of(false);
          }),
        );
      }

      case Actions.EMPLOYEE_LOG: {
        const logId = next.queryParamMap.get('logId');
        return this.actionsHttpService.downloadLog(logId).pipe(
          tap((result: Transfer) => {
            if (TransferUtils.isHttpResponse(result.originatingEvent)) {
              const blob = new Blob([result.originatingEvent.body]);
              let fileName = result.name;
              if (!fileName) {
                fileName = `${logId}.csv`;
                this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.CSV);
              }
              new FileSaver(blob).saveAs(fileName);
            }
          }),
          catchError(() => {
            this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download', '');
            return of(false);
          }),
        );
      }

      default:
        throw new AppErrorBuilder(ErrorTypes.OBJECT_NOT_FOUND)
          .description(`Non è stata trovata nessuna corrispondenza per l'azione ${action}`)
          .build();
    }
  }
}
