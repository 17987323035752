import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UiUserSessionHttpService } from './ui-user-session-http.service';

@Injectable({
  providedIn: 'root',
})
export class UiUserSessionService {
  constructor(private uiUserSessionHttpService: UiUserSessionHttpService) {}

  /**
   * Setta un valore a una determinata chiave per salvare le informazioni.
   * @param key Chiave.
   * @param value Valore da storicizzare.
   * @param expDate Expiration Date per la sessione
   */
  setValue<T>(key: string, value: T, expDate?: dayjs.Dayjs): Observable<unknown> {
    const expiration = expDate || dayjs().add(60, 'days');
    return this.uiUserSessionHttpService.setValue<T>(key, value, expiration);
  }

  /**
   * Ottiene l'informazione dato la chiave.
   * @param key Chiave da cui ottenere il valore.
   */
  getValue<T>(key: string): Observable<T> {
    return this.uiUserSessionHttpService
      .getValue<T>(key)
      .pipe(map((val) => (val.success ? val.sessionConfiguration : undefined)));
  }
}
