import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { documentStateReducer } from '@ctel/search-filter-store';
import { filterStateReducer } from '@ctel/search-filter-store';
import { SearchFilterStoreModule } from '@ctel/search-filter-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FilterEffects } from './filters/store/filter.effects';
import { DocumentEffects } from './documents/store/document.effects';
import { documentsSearchModuleFeatureKey } from './filters/store/feature-key';
import {
  extendedDocumentsSearchModuleReducers,
  extendedRelatedSectionDataModuleReducers,
} from './documents/store/document.extended';

export const documentsSearchModuleReducers = {
  filterState: filterStateReducer,
  documentState: documentStateReducer,
  extendedDocumentState: extendedDocumentsSearchModuleReducers.extendedDocumentState,
  extendedRelatedSectionDataState: extendedRelatedSectionDataModuleReducers.extendedRelatedSectionDataState,
};

@NgModule({
  imports: [
    CommonModule,
    SearchFilterStoreModule.forRoot(null),
    StoreModule.forFeature(documentsSearchModuleFeatureKey, documentsSearchModuleReducers),
    EffectsModule.forFeature([FilterEffects, DocumentEffects]),
  ],
})
export class DocumentsSearchModule {}
