/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable, ReplaySubject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionsHttpService implements OnDestroy {
  private jsonContentType = 'application/json';
  // host
  private documenti: string;

  private readonly jsonOptions: object;
  private actionLoading$ = new BehaviorSubject<boolean>(false);

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  httpHostInit(appConfig: AppConfig) {
    this.documenti = appConfig.documenti.http.host;
  }

  public whenLoadingAction(): Observable<boolean> {
    return this.actionLoading$.asObservable();
  }

  public setLoadingAction(value: boolean) {
    this.actionLoading$.next(value);
  }

  public postToDocumenti(actionUrl: string, body: unknown): Observable<unknown> {
    const url = this.documenti + actionUrl;
    return this.http.post<unknown>(url, body, this.jsonOptions);
  }

  public deleteToDocumenti(actionUrl: string, body: unknown): Observable<unknown> {
    const url = this.documenti + actionUrl;
    const options = {
      ...this.jsonOptions,
      body,
    };
    return this.http.delete<unknown>(url, options); // this.jsonOptions);
  }
}
