import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FavoriteSearch, UpdateSearchModel } from '../../entities/favorite-search/favorite-search';
import { FavoriteSearchesHttpService } from './favorite-searches-http.service';

@Injectable({
  providedIn: 'root',
})
export class FavoriteSearchesService {
  private favoriteSeaches$ = new Subject<any>(); //favoriteSearches

  constructor(private favoriteSearchesHttpService: FavoriteSearchesHttpService) {}

  whenFavoriteSearch(): Observable<FavoriteSearch[]> {
    return this.favoriteSeaches$.asObservable();
  }

  sendFavoriteSearch(value: FavoriteSearch[]) {
    this.favoriteSeaches$.next(value);
  }

  public getFavoriteSearchesList(licenseId, siaCode) {
    this.favoriteSearchesHttpService.getFavoriteSearchesList(licenseId, siaCode).subscribe((favoriteSeaches) => {
      this.sendFavoriteSearch(favoriteSeaches);
    });
  }

  public saveFavoriteSearchesList(search: UpdateSearchModel): Observable<any> {
    return this.favoriteSearchesHttpService.saveFavoriteSearchesList(search);
  }

  public updateFavoriteSearches(
    idlicenza: string,
    codsia: string,
    guidricerca: string,
    search: UpdateSearchModel,
  ): Observable<any> {
    return this.favoriteSearchesHttpService.updateFavoriteSearches(idlicenza, codsia, guidricerca, search);
  }

  public deleteUserFavoriteSearch(idlicenza: string, codsia: string, guidricerca: string): Observable<any> {
    return this.favoriteSearchesHttpService.deleteUserFavoriteSearch(idlicenza, codsia, guidricerca);
  }

  orderUserFavoriteSearch(idlicenza: string, codsia: string, ids: string[]): Observable<any> {
    return this.favoriteSearchesHttpService.orderUserFavoriteSearch(idlicenza, codsia, ids);
  }
}
