import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Extension } from 'app/core/common/utilities/file/file';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { DocumentAttachmentXml } from 'app/shared/components/display-document/document-attachment-xml';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, ReplaySubject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { WorkitemAction, WorkitemActions } from '../action';
import { AttachmentItem } from '../attachment-item';
import { AttachmentPreview } from './attachment-preview';

@Component({
  selector: 'gaw-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
})
export class AttachmentPreviewComponent {
  @Input() actions: WorkitemActions;
  @Input() hasBackButton = true;
  @Output() goBack = new EventEmitter();

  readonly attachmentPreview$ = new ReplaySubject<AttachmentItem>(1);
  readonly EXTENSION = Extension;
  readonly previewType$: Observable<Extension>;
  readonly documentAttachment$: Observable<DocumentAttachmentPdf | DocumentAttachmentXml | null>;

  public faIcons = FaIcons;

  constructor(private readonly notificationService: NotificationService) {
    this.previewType$ = this.attachmentPreview$.pipe(
      map((attachment) => AttachmentPreview.getPreviewType(attachment.filename)),
    );

    this.documentAttachment$ = this.attachmentPreview$.pipe(
      switchMap((attachment) =>
        combineLatest([this.previewType$, attachment.content]).pipe(
          map(([extension, content]) => this.getDocumentAttachment(content, attachment.filename, extension)),
        ),
      ),
    );
  }

  @Input() set attachment(attachment: AttachmentItem) {
    if (attachment) this.attachmentPreview$.next(attachment);
  }

  @ViewChild('NoPreview', { static: false }) set noPreviewContent(noPreviewTemplate: HTMLElement) {
    // Se viene visualizzato il template di `nessuna preview disponibile` scarico il file
    if (noPreviewTemplate)
      this.attachmentPreview$
        .pipe(
          switchMap((attachment) =>
            attachment.content.pipe(
              tap((content) => {
                const blob = content instanceof Blob ? content : new Blob([content], { type: attachment.mimeType });
                saveAs(blob, attachment.filename);
              }),
            ),
          ),
          take(1),
          catchError(() => {
            this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download del file', '');
            return EMPTY;
          }),
        )
        .subscribe();
  }

  back() {
    this.goBack.emit(true);
  }

  executeAction(attachment: AttachmentItem, action: WorkitemAction) {
    action.execute(attachment).pipe(take(1)).subscribe();
  }

  private getDocumentAttachment(
    content: string | Blob,
    filename: string,
    extension: Extension,
  ): DocumentAttachmentPdf | DocumentAttachmentXml | null {
    switch (extension) {
      case Extension.PDF:
        return typeof content !== 'string' ? new DocumentAttachmentPdf(filename, of(content)) : null;
      case Extension.XML:
        return typeof content === 'string' ? new DocumentAttachmentXml(filename, of(content)) : null;
      default:
        return;
    }
  }
}
