import { Field } from 'app/shared/components/dry/field/field';
import { FieldType } from 'app/shared/components/dry/field/field-type';

export class CheckboxField extends Field {
  type = FieldType.CHECKBOX;

  // Checkbox isSelected
  public selected = false;

  constructor(id: string, name: string, selected?: boolean) {
    super(id, name);
    if (selected !== undefined) this.selected = selected;
  }
}
