export class HttpHeadersFilename {
  // recupera il name del file che si sta per scaricare dall'header "content-disposition"
  public static getFilenameFromHttpHeaders(response: any) {
    const contentDisposition = response.headers.get('content-disposition');
    if (contentDisposition !== null) {
      // contentDisposition = 'attachment; filename="DOCUMENTO 5_GEDFATT.XML"; filename*=UTF-8\'\'DOCUMENTO%205_GEDFATT.XML';
      const matches = /filename=([^;]+)/gi.exec(contentDisposition);
      if (matches !== null)
        if (matches.length > 0) {
          if (matches[1].includes('"')) matches[1] = matches[1].replace(/"/g, '');

          return matches[1].trim();
        }
    }
    return 'untitled';
  }
}
