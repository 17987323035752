import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DocSeriesMetadataDesc,
  DocSeriesMetadataDescSearch,
  DocumentDetails,
  DocumentsResponse,
  GraphicInfoResponse,
} from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { SearchBarTypeahead } from 'app/entities/search-bar/search-bar-typeahead';
import { SearchBarTypeaheadPayload } from 'app/entities/search-bar/search-bar-typeahead-payload';
import { Sections } from 'app/entities/sections/sections';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative ai workflow
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentsHttpService {
  private jsonContentType = 'application/json';
  // host
  private searchHost: string;
  private docSeriesHost: string;
  private updateHost: string;
  private uiConfigHost: string;
  // endpoints
  private search: string;
  private graphic: string;
  private docDetail: string;
  private updateTags: string;
  private typeAhead: string;
  private favoritesSections: string;
  private metadataDesc: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    public spinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */
    this.searchHost = appConfig.search.http.host;
    this.search = `${this.searchHost}/v3/search?clientid=GAW`;
    this.docDetail = `${this.searchHost}/v3/documents/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details`; // TODO rimuovere

    this.typeAhead = `${this.searchHost}/v3/search/typeahead?clientid={clientId}`;

    this.updateHost = appConfig.update.http.host;
    this.updateTags = `${this.updateHost}/v1/document/update`;

    /* ENDPOINTS DOCSERIES API ------------------------------------------------------------------------------------------------------ */
    this.docSeriesHost = appConfig.docSeries.http.host;
    this.graphic = `${this.docSeriesHost}/v1/docseries/{docSeriesId}/public`;
    this.metadataDesc = `${this.docSeriesHost}/v3/docseries/metadata/descriptions`;

    /* ENDPOINTS UI CONFIGURATION --------------------------------------------------------------------------------------------------- */
    this.uiConfigHost = appConfig.uiConfiguration.http.host;
    // this.favoriteFilters = `${this.uiConfigHost}/v1/favorites/ui/GAW30/filters?sectionCode={sectionCode}&licenseId={licenseId}&siaCode={siaCode}`;
    this.favoritesSections = `${this.uiConfigHost}/v1/favorites/ui/GAW30/sections?licenseId={licenseId}&siaCode={siaCode}`;
  }

  /* HTTP REQUESTS A DICKENS---------------------------------------------------------------------------------------------- */
  whenTagsUpdate(body: string): Observable<any> {
    const url = this.updateTags;
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    return this.http.post<any>(url, body, options);
  }

  /* HTTP REQUESTS A DOC SERIES API ------------------------------------------------------------------------------------------ */
  // ottengo la grafica data una serie doc
  whenGraphic(docSeriesId: string): Observable<GraphicInfoResponse> {
    const url = this.graphic.replace('{docSeriesId}', docSeriesId);
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    return this.http.get<GraphicInfoResponse>(url, options);
  }

  /* HTTP REQUESTS A MAGELLANO ---------------------------------------------------------------------------------------------- */

  // GET tutte le sezioni per quel cliente
  whenSections(licenseId: string, siaCode: string): Observable<Sections> {
    const options = this.getHttpOptions(this.jsonContentType, 'json');
    const url = this.favoritesSections.replace('{licenseId}', licenseId).replace('{siaCode}', siaCode);
    return this.http.get<Sections>(url, options);
  }

  // RITORNA LISTA DOCUMENTI
  whenAllDocuments(body: string): Observable<DocumentsResponse> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.search;
    return this.http.post<DocumentsResponse>(url, body, options);
  }

  // DETTAGLIO METADATI DEL DOCUMENTO
  whenDocumentDetails(
    licenseId: string,
    siaCode: string,
    docSeriesId: string,
    elasticDocumentId: string,
    includeTechnical: boolean,
  ): Observable<DocumentDetails> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    let url = this.docDetail
      .replace('{docSeriesId}', docSeriesId)
      .replace('{elasticDocumentId}', elasticDocumentId)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    if (includeTechnical) url = url + '?includeTechnicalMetadata=true';

    return this.http.get<DocumentDetails>(url, options);
  }

  // Typeahead su ragione sociale nel bandone di ricerca
  whenSearchTypeahead(body: SearchBarTypeaheadPayload, clientId: string): Observable<SearchBarTypeahead> {
    const url = this.typeAhead.replace('{clientId}', clientId);
    return this.spinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((headers) =>
        this.http.post<SearchBarTypeahead>(url, body, {
          headers,
        }),
      ),
    );
  }

  whenMetadataDescriptions(body: DocSeriesMetadataDescSearch): Observable<DocSeriesMetadataDesc[]> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.metadataDesc;
    return this.http.post<DocSeriesMetadataDesc[]>(url, body, options);
  }

  // -----------------------------------------------------------------------------------------------------------------------
  private getHttpOptions(contentType: string, responseType: string): object {
    const headers = new HttpHeaders().set('Content-Type', contentType);
    let options;
    switch (responseType) {
      case 'blob':
        options = {
          headers,
          responseType: 'blob' as const,
        };
        break;
      case 'json':
      default:
        options = {
          headers,
          responseType: 'json' as const,
        };
        break;
    }
    return options;
  }
}
