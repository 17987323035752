import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { InvoiceHttpService } from 'app/core/business/invoice-pa/create-edit-invoice/invoice/invoice-http.service';
import { CustomerRegistry } from 'app/entities/invoice-pa/customer-registry/customer-registry';
import {
  Allegati,
  CedentePrestatore,
  CessionarioCommittente,
  DatiBeniServizi,
  DatiGenerali,
  DatiPagamento,
  DatiTrasmissione,
  DatiVeicoli,
  FatturaElettronica,
  RappresentanteFiscale,
  TerzoIntermediario,
} from 'app/entities/invoice-pa/invoice-pa-model';
import * as _ from 'lodash';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FatturaElettronicaParserService } from '../../invoice-pa-parser/fattura-elettronica-parser.service';

@Injectable()
export class InvoiceService {
  public isGedInvoiceEnabled$ = new BehaviorSubject<boolean>(false);

  private invoice = new BehaviorSubject<FatturaElettronica>({});
  private transmissionData$ = new BehaviorSubject<DatiTrasmissione>({});
  private taxRepresentative$ = new BehaviorSubject<RappresentanteFiscale>({});
  private supplier$ = new BehaviorSubject<CedentePrestatore>({});
  private transferee$ = new BehaviorSubject<CessionarioCommittente>({});
  private thirdEntity$ = new BehaviorSubject<TerzoIntermediario>({});
  private issuerEntity$ = new BehaviorSubject<string>(null);
  private generalData$ = new BehaviorSubject<DatiGenerali>({});
  private paymentData$ = new BehaviorSubject<DatiPagamento[]>([{}]);
  private goodsServices$ = new BehaviorSubject<DatiBeniServizi>({});
  private vehicleData$ = new BehaviorSubject<DatiVeicoli>({});
  private attachments$ = new BehaviorSubject<Allegati[]>([]);
  private enableThirdEntity$ = new BehaviorSubject<boolean>(null);
  private enableVehicleData$ = new BehaviorSubject<boolean>(null);
  private loadedInvoice: FatturaElettronica;
  // emit value da sotto componenti
  private emitValue$ = new BehaviorSubject<boolean>(null);
  // dati del cedente presi dalla chiamata http (solo per crea fattura)
  private supplierInfoData$ = new BehaviorSubject<CustomerRegistry>(null);
  // abilita modellazione fattura solo se è presente il canale gedfatt
  private enableModelInvoice$ = new BehaviorSubject<boolean>(null);
  private totaleImponibile$ = new BehaviorSubject<string>('0');
  private totaleIva$ = new BehaviorSubject<string>('0');
  private totaleDocumento$ = new BehaviorSubject<string>('0');
  // Reset values
  private resetValues$ = new BehaviorSubject<boolean>(false);
  private invoiceHints$ = new Subject<any>();

  private cfValue$ = new Subject<string>();
  private privatePersonCheckCessionario$ = new Subject<boolean>();

  constructor(
    private invoiceParserService: FatturaElettronicaParserService,
    private invoiceHttpService: InvoiceHttpService,
  ) {}

  /*
		Leggo la fattura dall'xml e me la salvo in un oggetto, poi
		assegno i valori alle singole proprietà in base all'oggetto salvata
	 */

  public parseXml(xml) {
    this.invoiceParserService.loadXml(xml);
    this.loadedInvoice = this.invoiceParserService.getInvoice();
    this.setInvoice(this.loadedInvoice);
    this.setTransmissionData(this.invoiceParserService.getDatiTrasmissione(true));
    this.setTaxRepresentative(
      _.isNil(this.loadedInvoice.FatturaElettronicaHeader.RappresentanteFiscale)
        ? {}
        : this.loadedInvoice.FatturaElettronicaHeader.RappresentanteFiscale,
    );
    this.setSupplier(
      _.isNil(this.loadedInvoice.FatturaElettronicaHeader.CedentePrestatore)
        ? {}
        : this.loadedInvoice.FatturaElettronicaHeader.CedentePrestatore,
    );
    this.setTransferee(
      _.isNil(this.loadedInvoice.FatturaElettronicaHeader.CessionarioCommittente)
        ? {}
        : this.loadedInvoice.FatturaElettronicaHeader.CessionarioCommittente,
    );
    this.setThirdEntity(
      _.isNil(this.loadedInvoice.FatturaElettronicaHeader.TerzoIntermediarioOSoggettoEmittente)
        ? {}
        : this.loadedInvoice.FatturaElettronicaHeader.TerzoIntermediarioOSoggettoEmittente,
    );
    this.setIssuerEntity(this.loadedInvoice.FatturaElettronicaHeader.SoggettoEmittente);
    // se terzo intermediario è presente
    if (!_.isEmpty(this.thirdEntity$.value) || !!this.loadedInvoice.FatturaElettronicaHeader.SoggettoEmittente)
      this.setThirdEntityVisibilty(true);
    else this.setThirdEntityVisibilty(false);

    /* BODY */
    this.setGeneralData(
      _.isNil(this.loadedInvoice.FatturaElettronicaBody.DatiGenerali)
        ? {}
        : this.loadedInvoice.FatturaElettronicaBody.DatiGenerali,
    );
    this.setPaymentData(
      _.isNil(this.loadedInvoice.FatturaElettronicaBody.DatiPagamento)
        ? [{}]
        : this.loadedInvoice.FatturaElettronicaBody.DatiPagamento,
    );
    this.setGoodsServices(
      _.isNil(this.loadedInvoice.FatturaElettronicaBody.DatiBeniServizi)
        ? {}
        : this.loadedInvoice.FatturaElettronicaBody.DatiBeniServizi,
    );
    this.setVehicleData(
      _.isNil(this.loadedInvoice.FatturaElettronicaBody.DatiVeicoli)
        ? {}
        : this.loadedInvoice.FatturaElettronicaBody.DatiVeicoli,
    );
    // se dati veicoli sono presenti
    if (!_.isEmpty(this.vehicleData$.value)) this.setVehicleDataVisibilty(true);
    else this.setVehicleDataVisibilty(false);

    this.setAttachments(
      _.isNil(this.loadedInvoice.FatturaElettronicaBody.Allegati)
        ? []
        : this.loadedInvoice.FatturaElettronicaBody.Allegati,
    );
  }

  public setInvoice(invoice: FatturaElettronica) {
    this.invoice.next(invoice);
  }

  public whenInvoice(): Observable<FatturaElettronica> {
    return this.invoice;
  }

  public setTransmissionData(data: DatiTrasmissione) {
    this.transmissionData$.next(data);
  }

  public whenTransmissionData(): Observable<DatiTrasmissione> {
    return this.transmissionData$;
  }

  public setTaxRepresentative(data: RappresentanteFiscale) {
    this.taxRepresentative$.next(data);
  }

  public whenTaxRepresentative(): Observable<RappresentanteFiscale> {
    return this.taxRepresentative$;
  }

  public setSupplier(data: CedentePrestatore) {
    this.supplier$.next(data);
  }

  public whenSupplier(): Observable<CedentePrestatore> {
    return this.supplier$.asObservable();
  }

  public setTransferee(data: CessionarioCommittente) {
    this.transferee$.next(data);
  }

  public whenTransferee(): Observable<CessionarioCommittente> {
    return this.transferee$.asObservable();
  }

  public setThirdEntity(data: TerzoIntermediario) {
    this.thirdEntity$.next(data);
  }

  public whenThirdEntity(): Observable<TerzoIntermediario> {
    return this.thirdEntity$;
  }

  public setIssuerEntity(data: string) {
    this.issuerEntity$.next(data);
  }

  public whenIssuerEntity(): Observable<string> {
    return this.issuerEntity$;
  }

  public setGeneralData(data: DatiGenerali) {
    this.generalData$.next(data);
  }

  public whenGeneralData(): Observable<DatiGenerali> {
    return this.generalData$;
  }

  public setPaymentData(data: DatiPagamento[]) {
    this.paymentData$.next(data);
  }

  public whenPaymentData(): Observable<DatiPagamento[]> {
    return this.paymentData$;
  }

  public setGoodsServices(goodsServices: DatiBeniServizi) {
    this.goodsServices$.next(goodsServices);
  }

  public whenGoodServices(): Observable<DatiBeniServizi> {
    return this.goodsServices$.asObservable();
  }

  public setVehicleData(data: DatiVeicoli) {
    this.vehicleData$.next(data);
  }

  public whenVehicleData(): Observable<DatiVeicoli> {
    return this.vehicleData$;
  }

  public setAttachments(data: Allegati[]) {
    this.attachments$.next(data);
  }

  public whenAttachments(): Observable<Allegati[]> {
    return this.attachments$;
  }

  public setThirdEntityVisibilty(data: boolean) {
    this.enableThirdEntity$.next(data);
  }

  public whenThirdEntityVisibility(): Observable<boolean> {
    return this.enableThirdEntity$;
  }

  public setVehicleDataVisibilty(data: boolean) {
    this.enableVehicleData$.next(data);
  }

  public whenVehicleDataVisibility(): Observable<boolean> {
    return this.enableVehicleData$;
  }

  public setSupplierInfoData(data: CustomerRegistry) {
    this.supplierInfoData$.next(data);
  }

  public whenSupplierInfoData(): Observable<CustomerRegistry> {
    return this.supplierInfoData$.asObservable();
  }

  // attivo emitavalue in modo da fare emettere i valori dai componenti figli
  public setEmitvalue(value: boolean) {
    this.emitValue$.next(value);
  }

  public whenEmitValue() {
    return this.emitValue$.asObservable();
  }

  // serve per costruire la fattura per inviarla o per creare un modello
  public buildInvoice() {
    const invoiceHeader$ = forkJoin([
      this.whenTransmissionData(),
      this.whenTaxRepresentative(),
      this.whenSupplier(),
      this.whenTransferee(),
      this.whenThirdEntity(),
      this.whenIssuerEntity(),
    ]).pipe(
      map(([transmissionData, taxRepresentative, supplier, transferee, thirdEntity, issuerEntity]) => ({
        transmissionData,
        taxRepresentative,
        supplier,
        transferee,
        thirdEntity,
        issuerEntity,
      })),
    );
    const invoiceBody$ = forkJoin([
      this.whenGeneralData(),
      this.whenPaymentData(),
      this.whenGoodServices(),
      this.whenVehicleData(),
      this.whenAttachments(),
    ]).pipe(
      map(([generalData, paymentData, goodServices, vehicleData, attachments]) => ({
        generalData,
        paymentData,
        goodServices,
        vehicleData,
        attachments,
      })),
    );
    invoiceHeader$.subscribe((data) => {
      this.loadedInvoice.FatturaElettronicaHeader.SoggettoEmittente = data.issuerEntity;
      this.loadedInvoice.FatturaElettronicaHeader.DatiTrasmissione = data.transmissionData;
      this.loadedInvoice.FatturaElettronicaHeader.RappresentanteFiscale = data.taxRepresentative;
      this.loadedInvoice.FatturaElettronicaHeader.CedentePrestatore = data.supplier;
      this.loadedInvoice.FatturaElettronicaHeader.CessionarioCommittente = data.transferee;
      this.loadedInvoice.FatturaElettronicaHeader.TerzoIntermediarioOSoggettoEmittente = data.thirdEntity;
    });
    invoiceBody$.subscribe((data) => {
      this.loadedInvoice.FatturaElettronicaBody.DatiGenerali = data.generalData;
      this.loadedInvoice.FatturaElettronicaBody.DatiPagamento = data.paymentData;
      this.loadedInvoice.FatturaElettronicaBody.DatiBeniServizi = data.goodServices;
      this.loadedInvoice.FatturaElettronicaBody.DatiVeicoli = data.vehicleData;
      this.loadedInvoice.FatturaElettronicaBody.Allegati = data.attachments;
    });
  }

  public resetValuesInvoice() {
    this.setGoodsServices({});
    this.setTransmissionData({});
    this.setTaxRepresentative({});
    this.setTransferee({});
    this.setSupplier({});
    this.setTransferee({});
    this.setThirdEntityVisibilty(null);
    this.setIssuerEntity(null);
    this.setGeneralData({});
    this.setPaymentData([{}]);
    this.setVehicleData({});
    this.setAttachments([]);
    this.setThirdEntity({});
    this.setSupplierInfoData({});
  }
  public calculateTotalInvoiceWithScontoMaggiorazione(
    scontoMaggiorazioneFormArray: UntypedFormArray,
    quantitaForm: AbstractControl,
    prezzoUnitarioForm: AbstractControl,
  ): number {
    // tipo SC MG, percentuale, importo
    let nuovoPrezzoUnitario = prezzoUnitarioForm.value;
    scontoMaggiorazioneFormArray.controls.forEach((scontoMaggiorazioneForm) => {
      const value = scontoMaggiorazioneForm.value;
      // procedo solo se è specificato se è sconto o maggiorazione
      if (value.Tipo !== null)
        if (value.Importo !== null && value.Importo !== '')
          if (value.Tipo === 'SC') {
            // il campo importo vince sempre sul campo percentuale (se sono presenti entrambi) quindi basta un if/else
            if (value.Importo > 0) nuovoPrezzoUnitario = +nuovoPrezzoUnitario - +value.Importo;
            else if (value.Importo < 0 && nuovoPrezzoUnitario <= 0)
              nuovoPrezzoUnitario = +nuovoPrezzoUnitario + +value.Importo;
            else if (value.Importo < 0 && nuovoPrezzoUnitario >= 0)
              nuovoPrezzoUnitario = +nuovoPrezzoUnitario - +value.Importo;
          } else nuovoPrezzoUnitario = +nuovoPrezzoUnitario + +value.Importo;
        else if (value.Percentuale !== null && value.Percentuale !== '') {
          const discount = (nuovoPrezzoUnitario / 100) * +value.Percentuale;
          if (value.Tipo === 'SC') nuovoPrezzoUnitario = +nuovoPrezzoUnitario - +discount;
          else nuovoPrezzoUnitario = +nuovoPrezzoUnitario + +discount;
        }
    });
    // prezzo unitario comprensivo di sconto/maggiorazione
    if (quantitaForm.value === null) return +nuovoPrezzoUnitario;

    return +nuovoPrezzoUnitario * +quantitaForm.value;
  }

  // abilita modellazione fattura solo se è presente il canale gedfatt TODO refactor in corso - KARANDIP SINGH
  public whenEnableModel(): Observable<boolean> {
    return this.enableModelInvoice$.asObservable();
  }

  public setTotaleImponibile(value: string) {
    this.totaleImponibile$.next(value);
  }

  public whenTotaleImponibile(): Observable<string> {
    return this.totaleImponibile$.asObservable();
  }

  public setTotaleIva(value: string) {
    this.totaleIva$.next(value);
  }

  public whenTotaleIva(): Observable<string> {
    return this.totaleIva$.asObservable();
  }

  public setTotaleDocumento(value: string) {
    this.totaleDocumento$.next(value);
  }

  public whenTotaleDocumento(): Observable<string> {
    return this.totaleDocumento$.asObservable();
  }

  public setEnableModel(value: boolean) {
    this.enableModelInvoice$.next(value);
  }

  public setIsGedInvoiceEnabled(value: boolean) {
    this.isGedInvoiceEnabled$.next(value);
  }

  public whenIsGedInvoiceEnabled(): Observable<boolean> {
    return this.isGedInvoiceEnabled$.asObservable();
  }

  public setResetArray(value: boolean) {
    this.resetValues$.next(value);
  }

  public whenResetArray(): Observable<boolean> {
    return this.resetValues$.asObservable();
  }

  public getInvoiceHint(editInvoice: boolean): Observable<any> {
    return this.invoiceHttpService.getInvoiceHintMock(editInvoice).pipe(
      tap((data) => {
        this.setInvoiceHint(data);
      }),
    );
  }

  public whenInvoiceHints(): Observable<any> {
    return this.invoiceHints$.asObservable();
  }

  public setCfValue(value: string) {
    this.cfValue$.next(value);
  }

  public whenCfValue(): Observable<string> {
    return this.cfValue$.asObservable();
  }

  public setPrivatePersonCheckCessionario(value: boolean) {
    this.privatePersonCheckCessionario$.next(value);
  }

  public whenPrivatePersonCheckCessionario(): Observable<boolean> {
    return this.privatePersonCheckCessionario$.asObservable();
  }

  private setInvoiceHint(value: any) {
    this.invoiceHints$.next(value);
  }
}
