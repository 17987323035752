import {
  ComponentRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { ErrorPlaceholderComponent } from 'app/core/common/placeholder/error-placeholder/templates/error-placeholder/error-placeholder.component';
import { BaseLoadingPlaceholderComponent } from 'app/core/common/placeholder/loading-placeholder/base-loading-placeholder/base-loading-placeholder.component';
import { ComponentInjector } from 'app/core/common/utilities/component-injector';
import { Subject } from 'rxjs';

@Directive({
  selector: '[hubNewError]',
})
export class NewErrorDirective implements OnDestroy, OnChanges {
  /*----------------------------------------------
   * Gestione del caricamento
   *----------------------------------------------*/

  @Input() private hubNewError: boolean;
  @Input() private hubNewErrorComponent: Type<any>;
  @Input() private hubNewErrorStyle: {
    [key: string]: string;
  };

  public options: Options;

  // Istanziazione componente di caricamento
  public baseNewErrorComponentInstantiated = false;

  private destroy$ = new Subject<void>();

  private baseNewErrorComponent: ComponentRef<any>;
  private defaultComponent: Type<any> = ErrorPlaceholderComponent;

  /*----------------------------------------------
   * Gestione del contenuto incorporato
   *----------------------------------------------*/
  private embeddedComponent: EmbeddedViewRef<any>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  @Input() set hubNewErrorOptions(opts: Options) {
    this.options = opts;
  }

  /**
   * Tutti i cambiamenti di stato di questa classe vengono gestiti nella ngOnChanges
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) return;

    if (changes['hubNewError']) {
      this.viewContainer.clear();
      if (changes['hubNewError'].currentValue === true) {
        // uso base loading component per avere il contenitore su cui si andrà ad applicare il componente d'errore

        this.instantiateBaseLoadingComponent(this.hubNewErrorComponent || BaseLoadingPlaceholderComponent);
        if (this.embeddedComponent) this.embeddedComponent.destroy();

        ComponentInjector.insertComponent(this.viewContainer, this.baseNewErrorComponent);
      } else {
        ComponentInjector.detachComponent(this.viewContainer, this.baseNewErrorComponent);
        this.embeddedComponent = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
  }

  ngOnDestroy(): void {
    this.viewContainer.clear();
    if (this.baseNewErrorComponentInstantiated === true) this.baseNewErrorComponent.destroy();

    this.destroy$.next();
    this.destroy$.complete();
  }

  private instantiateBaseLoadingComponent(template: Type<any>) {
    this.baseNewErrorComponent = this.viewContainer.createComponent(template);
    this.baseNewErrorComponent.instance.component = this.defaultComponent;
    this.baseNewErrorComponent.instance.style = this.hubNewErrorStyle;
    // options sono necessarie in quanto contengono il messaggio d'errore e l'icona
    this.baseNewErrorComponent.instance.options = this.options;
    this.baseNewErrorComponentInstantiated = true;
  }

  // instantiateComponent(): ComponentRef<any> {
  // 	let componentToLoad: Type<any>;
  // 	if (this.hubNewErrorComponent) {
  // 		componentToLoad = this.hubNewErrorComponent;
  // 	} else {
  // 		componentToLoad = this.defaultComponent;
  // 	}
  // 	const injector = new ComponentInjector(this.resolver);
  // 	const createdComponent = injector.createComponent(componentToLoad);
  // 	createdComponent.instance.style = this.hubNewErrorStyle;
  // 	createdComponent.instance.options = this.options;
  // 	return createdComponent;
  // }
}
