<content-loader
  [speed]="2"
  [foregroundColor]="'#f3f3f3'"
  [backgroundColor]="'#ecebeb'"
  [style]="{ height: '250px', width: '350px' }"
>
  <svg:rect x="9" y="20" rx="3" ry="3" width="70" height="10" />
  <svg:rect x="9" y="35" rx="4" ry="4" width="343" height="20" />

  <svg:rect x="9" y="70" rx="3" ry="3" width="70" height="10" />
  <svg:rect x="9" y="85" rx="4" ry="4" width="343" height="20" />

  <svg:rect x="9" y="120" rx="3" ry="3" width="70" height="10" />
  <svg:rect x="9" y="135" rx="4" ry="4" width="343" height="20" />

  <svg:rect x="9" y="170" rx="3" ry="3" width="70" height="10" />
  <svg:rect x="9" y="185" rx="4" ry="4" width="343" height="20" />

  <svg:rect x="9" y="220" rx="4" ry="4" width="70" height="10" />
  <svg:rect x="9" y="235" rx="4" ry="4" width="343" height="20" />
</content-loader>
