import { Injectable } from '@angular/core';
import { InvoiceMultiValues } from 'app/entities/invoice-pa/invoice-multi-values';

const modalitaPagamento = [
  { value: 'MP01', label: 'Contanti' },
  { value: 'MP02', label: 'Assegno' },
  { value: 'MP03', label: 'Assegno circolare' },
  { value: 'MP04', label: 'Contanti presso Tesoreria' },
  { value: 'MP05', label: 'Bonifico' },
  { value: 'MP06', label: 'Vaglia cambiario' },
  { value: 'MP07', label: 'Bollettino bancario' },
  { value: 'MP08', label: 'Carta di Pagamento' },
  { value: 'MP09', label: 'RID' },
  { value: 'MP10', label: 'RID utenze' },
  { value: 'MP11', label: 'RID veloce' },
  { value: 'MP12', label: 'RIBA' },
  { value: 'MP13', label: 'MAV' },
  { value: 'MP14', label: 'Quietanza erario' },
  { value: 'MP15', label: 'Giroconto su conti di contabilità speciale' },
  { value: 'MP16', label: 'Domiciliazione bancaria' },
  { value: 'MP17', label: 'Domiciliazione postale' },
  { value: 'MP18', label: 'Bollettino di c/c postale' },
  { value: 'MP19', label: 'SEPA Direct Debit' },
  { value: 'MP20', label: 'Sepa Direct Debit CORE' },
  { value: 'MP21', label: 'SEPA Direct Debit B2B' },
  { value: 'MP22', label: 'Trattenuta su somme già riscosse' },
  { value: 'MP23', label: 'PagoPA' },
];

@Injectable()
export class ModalitaPagamentoService {
  public getList(): InvoiceMultiValues[] {
    return modalitaPagamento.slice();
  }
}
