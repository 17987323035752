import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionsConfigRequest, ActionsConfigResponse } from '../../entities/documents/actions/actions-configuration';
import { Observable } from 'rxjs';

/**
 * Servizio http per la gestione delle chiamate alla ui-configuration per ottenere le azioni
 */
@Injectable({
  providedIn: 'root',
})
export class ActionsConfigurationHttpService {
  private uiConfigurationHost: string;
  private jsonContentType = 'application/json';
  private readonly jsonOptions: object;

  // Endpoints
  private getSingleActionsConfigurationUrl: string;
  private getMultipleActionsConfigurationUrl: string;
  private getMassiveActionsConfigurationUrl: string;

  constructor(
    private httpClient: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
  }

  httpHostInit(appConfig: AppConfig) {
    this.uiConfigurationHost = appConfig.uiConfiguration.http.host;
    this.getSingleActionsConfigurationUrl = `${this.uiConfigurationHost}/v1/actions/document/ui/HUBFE/singleActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
    this.getMultipleActionsConfigurationUrl = `${this.uiConfigurationHost}/v1/actions/document/ui/HUBFE/multipleActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
    this.getMassiveActionsConfigurationUrl = `${this.uiConfigurationHost}/v1/actions/document/ui/HUBFE/massiveActions?licenseId={licenseId}&siaCode={siaCode}&sectionCode={sectionCode}`;
  }

  public getSingleActions(
    sectionCode: string,
    licenseId: string,
    siaCode: string,
    body: ActionsConfigRequest,
  ): Observable<ActionsConfigResponse> {
    const url = this.getSingleActionsConfigurationUrl
      .replace('{sectionCode}', sectionCode)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    return this.httpClient.post<ActionsConfigResponse>(url, body, this.jsonOptions);
  }

  public getMultipleActions(
    sectionCode: string,
    licenseId: string,
    siaCode: string,
    body?: ActionsConfigRequest[],
  ): Observable<ActionsConfigResponse> {
    const url = this.getMultipleActionsConfigurationUrl
      .replace('{sectionCode}', sectionCode)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    return this.httpClient.post<ActionsConfigResponse>(url, body, this.jsonOptions);
  }

  public getMassiveActions(
    licenseId: string,
    siaCode: string,
    sectionCode: string,
    docSeriesId?: string,
  ): Observable<ActionsConfigResponse> {
    let url = this.getMassiveActionsConfigurationUrl
      .replace('{sectionCode}', sectionCode)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    if (docSeriesId) url = url + '&docSeriesId=' + docSeriesId;

    return this.httpClient.get<ActionsConfigResponse>(url, this.jsonOptions);
  }
}
