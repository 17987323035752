import { Component } from '@angular/core';
import { DateField } from 'app/shared/components/dry/field/specializations/date-field';
import dayjs from 'dayjs';
import { AbstractCellComponent } from '../abstract-cell.component';

@Component({
  selector: 'gaw-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
})
export class DateCellComponent extends AbstractCellComponent<DateField> {
  value: dayjs.Dayjs;
}
