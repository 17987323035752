<div>
  <!--PANEL HISTORY STATI CANALE-->
  <div class="card-status-history">
    <ng-container *ngIf="statusHistoryError$ | async; else table">
      <div class="banner-container">
        <strong>Nessun Risultato</strong>
      </div>
    </ng-container>
    <ng-template #table>
      <ng-container *ngIf="statusHistory$ | async as statusHistory">
        <table class="table">
          <tr>
            <th style="width: 33%">Stato</th>
            <th style="width: 33%">Data e ora</th>
            <th style="width: 33%">Note</th>
          </tr>
          <ng-container *ngFor="let statusHistoryItem of statusHistory.statiSpool; let rowIndex = index">
            <tr>
              <td>{{ statusHistoryItem.stato }}</td>
              <td>{{ statusHistoryItem.dataOra | isoDatetimeToDatetime }}</td>
              <td>{{ statusHistoryItem.note }}</td>
            </tr>
          </ng-container>
        </table>
      </ng-container>
    </ng-template>
  </div>
</div>
