import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionsService2 } from '@ctel/gawpass-commons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { AnomalyStatusHistoryItem } from 'app/entities/anomalies/anomaly-status-history-item';
import { StatusHistoryDetailsModalComponent } from '../../anomaly-detail/anomaly-details-main/anomaly-status-history/status-history-details-modal/status-history-details-modal.component';
import { AnomaliesService } from '../../services/anomalies.service';

/**
 * Componente modal per un'azione sul documento che mostra uno storico (modifiche o esiti)
 */
@Component({
  selector: 'anomaly-history-action-modal',
  templateUrl: './anomaly-history-action-modal.component.html',
  styleUrls: ['./anomaly-history-action-modal.component.scss'],
})
export class AnomalyHistoryActionModalComponent {
  @Input() modal: BsModalRef;

  public statusHistory$: Observable<AnomalyStatusHistoryItem[]>;

  constructor(
    private actionsService: ActionsService2,
    private location: PlatformLocation,
    private notificationService: NotificationService,
    private anomaliesService: AnomaliesService,
  ) {
    location.onPopState(() => this.modal.hide());
    this.statusHistory$ = this.actionsService.whenAnomalyHistory();
  }

  onDetails(dataStato: string, descrizione: string) {
    this.notificationService.showModal(NotificationType.GENERAL, {
      customFooter: true,
      title: `Dettaglio Stato del ${this.anomaliesService.utcToShort(dataStato, 'DD-MM-YYYY HH:mm')}`,
      childComponent: StatusHistoryDetailsModalComponent,
      childData: {
        innerHtml: descrizione,
      },
    });
  }
}
