import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ResendPaHttpService } from './resend-pa/resend-pa-http.service';
import { ResendPaService } from './resend-pa/resend-pa.service';
import { UploadDocumentHttpService } from './upload-document-pa/upload-document-http.service';
import { UploadDocumentService } from './upload-document-pa/upload-document.service';
import { UploadPdfHttpService } from './upload-pdf/upload-pdf-http.service';
import { UploadPdfService } from './upload-pdf/upload-pdf.service';
import { UploadLottoHttpService } from './upload-zip/upload-lotto-http.service';
import { UploadLottoService } from './upload-zip/upload-lotto.service';

@NgModule({
  imports: [CommonModule],
  providers: [],
  exports: [],
})
export class UploadDocumentModule {
  static forRoot(): ModuleWithProviders<UploadDocumentModule> {
    return {
      ngModule: UploadDocumentModule,
      providers: [
        UploadDocumentService,
        UploadDocumentHttpService,
        UploadLottoService,
        UploadLottoHttpService,
        UploadPdfHttpService,
        UploadPdfService,
        ResendPaHttpService,
        ResendPaService,
      ],
    };
  }
}
