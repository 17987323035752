import { Injectable } from '@angular/core';
import { MassiveActionsCatalog } from 'app/modules/homepage/core/documents-search/actions/massive-actions-catalog';
import { MultiActionsCatalog } from 'app/modules/homepage/core/documents-search/actions/multi-actions-catalog';
import { SingleActionsCatalog } from 'app/modules/homepage/core/documents-search/actions/single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly massiveActions: ActionObject[] = [
    this.massiveActionsCatalog.exportExcel,
    this.massiveActionsCatalog.downloadPdfZipMassive,
    this.massiveActionsCatalog.downloadXmlZipGedinvoiceMassive,
    this.massiveActionsCatalog.downloadXmlZipGedpassjoinMassive,
  ];

  public readonly multiActions: ActionObject[] = [
    this.multiActionsCatalog.downloadMergedPdf,
    this.multiActionsCatalog.downloadZip,
    this.multiActionsCatalog.exportExcel,
  ];

  public readonly singleActions: ActionObject[] = [
    this.singleActionsCatalog.viewDocument,
    this.singleActionsCatalog.pdfDownload,
  ];

  public readonly onlyViewActions: ActionObject[] = [this.singleActionsCatalog.viewDocument];

  constructor(
    private massiveActionsCatalog: MassiveActionsCatalog,
    private multiActionsCatalog: MultiActionsCatalog,
    private singleActionsCatalog: SingleActionsCatalog,
  ) {}
}
