import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Options } from 'app/core/common/placeholder/error-placeholder/directives/options';
import { saveAs } from 'file-saver';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, reduce, take, tap } from 'rxjs/operators';
import { WorkitemActions } from '../../shared/action';
import { AttachmentItem } from '../../shared/attachment-item';
import { DocumentAttachmentService } from '../document-attachment.service';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import { AttachmentPreview } from '../../shared/attachment-preview/attachment-preview';

@Component({
  selector: 'gaw-wf-doc-attachment-list',
  templateUrl: './document-attachments-list.component.html',
  styleUrls: ['./document-attachments-list.component.scss'],
})
export class DocumentAttachmentsListComponent implements OnInit {
  @Output() goToDetails = new EventEmitter<string>();

  public faIcons = FaIcons;
  attachments$: Observable<AttachmentItem[]>;
  attachmentsError = false;
  attachmentsLoading$: Observable<boolean>;

  actions: WorkitemActions = [
    {
      label: '',
      icon: `${this.faIcons.FAS_DOWNLOAD}`,
      disable: () => of(false),
      execute: (attachment) => this.downloadAttachment(attachment),
    },
  ];

  errorOptions: Options = {
    onRetry: () => (this.attachmentsError = false),
  };

  private readonly outletName = 'workitem-detail';

  constructor(
    private readonly documentAttachmentService: DocumentAttachmentService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.attachments$ = this.documentAttachmentService.attachmentList$.pipe(
      mergeMap((list) =>
        from(list).pipe(
          map(
            (att) =>
              <AttachmentItem>{
                id: att.idAllegato.toString(),
                mimeType: att.mimeType,
                filename: att.nomeAllegato,
                content: this.documentAttachmentService.downloadAttachment(att),
              },
          ),
          reduce<AttachmentItem, AttachmentItem[]>((acc, val) => [...acc, val], []),
        ),
      ),
      tap((list) => {
        // Se la lista presenta solo un elemento, ed è visualizzabile, faccio subito il routing verso la preview
        if (list.length > 0 && list.length === 1 && AttachmentPreview.hasPreview(list[0].filename))
          this.attachmentDetail(list[0]);
      }),
      catchError(() => {
        this.attachmentsError = true;
        return EMPTY;
      }),
    );

    this.attachmentsLoading$ = this.documentAttachmentService.attachmentListLoading$;
  }

  attachmentDetail(attachment: AttachmentItem) {
    this.goToDetails.emit(attachment.id);
  }

  private downloadAttachment(attachmentItem: AttachmentItem) {
    return attachmentItem.content.pipe(
      take(1),
      tap((content) => saveAs(content, attachmentItem.filename)),
      catchError(() => {
        this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download', '');
        return EMPTY;
      }),
    );
  }
}
