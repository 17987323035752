import { Injectable } from '@angular/core';
import { Transfer, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { HttpHeadersFilename } from 'app/core/common/utilities/http-headers-filename';
import { Company } from 'app/entities/companies/company';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { PddDocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class PddExecuteActionsService {
  currentCompany: Company;

  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private documentsService: PddDocumentsService2,
    private companiesService: CompaniesService,
  ) {
    this.currentCompany = companiesService.getCurrentCompanyValue();
  }

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  public executeSingleAction(action: ActionObject, data: any) {
    const actionCode = action.actionCode;
    switch (actionCode) {
      case ActionCode.DOWNLOAD_PDD:
        this.actionsHttpService
          .whenPdd(data)
          .pipe(
            take(1),
            catchError((err: unknown) => {
              const reader = new FileReader();
              reader.addEventListener('loadend', (e) => {
                const error = JSON.parse(e.target.result.toString()).message;
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download', error);
              });
              reader.readAsText(err?.['additionalInfo']?.find((x) => x.t === 'response').u);
              return EMPTY;
            }),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              const fileName = HttpHeadersFilename.getFilenameFromHttpHeaders(r.originatingEvent);
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body]);
                new FileSaver(blob).saveAs(fileName);
                if (data.stato === '7') this.documentsService.refreshDocuments();
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;
    }
  }
}
