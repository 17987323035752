import { Component, Input, OnChanges, OnDestroy, SimpleChange } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BoxDetails, DocSeriesColor, DocumentDetails, IDetailsComponent } from '@ctel/gaw-commons';
import {
  ActionsService2,
  DocumentDetailsService,
  ExecuteActionsService,
  SingleActionsCode,
} from '@ctel/gawpass-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { DocumentsSeriesService } from '../documents-search/documents-series/documents-series.service';
import { BoxDetailsService } from '../services/box-details.service';

@Component({
  selector: 'gaw-gawpass-detail',
  templateUrl: './gawpass-detail.component.html',
  styleUrls: ['./gawpass-detail.component.scss'],
})
export class GawpassDetailComponent implements OnChanges, OnDestroy {
  @Input() data: IDetailsComponent;

  public singleActionsList$: Observable<ActionObject[]> = this.actionsService.whenSingleActionsList();
  public singleActionsList: ActionObject[];
  public actionLoadingError$: Observable<boolean>;
  public documentDetails: DocumentDetails;
  public metadatiCanale$: Observable<DocumentDetails>;
  public singleActionsCode = SingleActionsCode;
  public metadataEnum = MetadataEnum;
  public actionCode = ActionCode;
  public documentSeriesColors$: Observable<DocSeriesColor[]>;
  boxDetails$: Observable<BoxDetails>;
  color: string;

  constructor(
    private executeActionsService: ExecuteActionsService,
    private actionsService: ActionsService2,
    private documentDetailsService: DocumentDetailsService,
    private documentSeriesService: DocumentsSeriesService,
    private route: ActivatedRoute,
    private boxDetailsService: BoxDetailsService,
  ) {}

  get docLabel() {
    const docLabel = this.documentDetails?.metadataList?.find((m) => m.keyCode === this.metadataEnum.DOCLABEL);
    return docLabel?.value ? docLabel.value : 'Doc Label sconosciuta';
  }

  // le azioni nel dettaglio coincidono con le azioni singole
  executeAction(action: ActionObject, keys: object) {
    // per il workflow salvo l'id serie doc reale (in caso venissi dalla all)
    const keysArray = Object.entries(keys).map(([key, value]) => ({ key, value }));
    for (const item of keysArray)
      if (item.key === 'ctelDocSeriesId') this.documentDetailsService.sendRealDocSeriesId('' + item.value);

    this.executeActionsService.executeSingleAction(action, keys);
  }

  ngOnChanges(changes: { data: SimpleChange }): void {
    if (!changes.data) return;

    // OTTENGO I METADATI E LE AZIONI DEL DOCUMENTO
    this.documentDetailsService
      .whenDocumentDetails(this.data.licenseId, this.data.siaCode, this.data.docSeriesId, this.data.elasticId, true)
      .pipe(
        tap((details) => {
          this.documentDetails = details;
          this.color = this.documentSeriesService.getColor(details.keys['descTipoDocumento']);
        }),
        switchMap((details) =>
          this.actionsService.requestSingleActionsConfig(
            this.data.licenseId,
            this.data.siaCode,
            this.route.snapshot.params.section,
            details.keys,
          ),
        ),
        take(1),
      )
      .subscribe();

    // AZIONI DOCUMENTO
    this.singleActionsList$ = this.actionsService.whenSingleActionsList();

    // Colori Serie documentale
    this.documentSeriesColors$ = this.documentSeriesService.whenDocumentsSeriesColors();

    this.boxDetails$ = this.boxDetailsService.whenBoxDetails(
      this.data.licenseId,
      this.data.siaCode,
      this.data.keys['idScatola'],
    );
  }

  ngOnDestroy(): void {
    this.actionsService.reset();
  }
}
