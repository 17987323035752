export enum DocumentPermissions {
  read,
  write,
  viewHiddenFiles,
  toggleHiddenFiles,
  reExportDocuments,
  showOutcomesHistory,
  showNewDocument,
  typeAhead,
  settingsAccess,
  createReport,
  addressBook,
  archivioLotti,
  cestino,
  quadratore,
  archivioFiscaleDettaglio,
  pddScarica,
  PDVDettaglio,
}
