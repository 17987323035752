/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TransferService } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable, ReplaySubject, of, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActionsHttpService implements OnDestroy {
  private jsonContentType = 'application/json';
  // host

  private readonly jsonOptions: object;
  private actionLoading$ = new BehaviorSubject<boolean>(false);
  private cancelUrl: string;
  private anomalyCheckUrl: string;
  private firmaRemotaHost: string;
  private signUrl: string;
  private reasonConfirmUrl: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private transferService: TransferService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  httpHostInit(appConfig: AppConfig) {
    this.firmaRemotaHost = appConfig.webConsOrchestrator.http.host;
    this.cancelUrl = `${this.firmaRemotaHost}/v1/FirmaRemota/lotti/cancella`;
    this.anomalyCheckUrl = `${this.firmaRemotaHost}/v1/FirmaRemota/lotti/anomalie/controllo`;
    this.signUrl = `${this.firmaRemotaHost}/v1/FirmaRemota/lotti/firma`;
    this.reasonConfirmUrl = `${this.firmaRemotaHost}/v1/FirmaRemota/lotti/conferma`;
  }

  public whenLoadingAction(): Observable<boolean> {
    return this.actionLoading$.asObservable();
  }

  public setLoadingAction(value: boolean) {
    this.actionLoading$.next(value);
  }

  anomalyCheck(licenseId: string, siaCode: string, progSpools: number[]): Observable<any[]> {
    const body = {
      IdLicenza: licenseId,
      CodiceSia: siaCode,
      IdsProgSpool: progSpools,
    };
    return this.http.post<any[]>(this.anomalyCheckUrl, body);
  }

  reasonConfirm(licenseId: string, siaCode: string, reason: string, progSpools: number[]): Observable<any> {
    const body = {
      idLicenza: licenseId,
      codSia: siaCode,
      motivazioneConferma: reason,
      progSpools,
    };
    return this.http.post(this.reasonConfirmUrl, body);
  }

  sign(
    username: string,
    password: string,
    otp: number,
    licenseId: string,
    siaCode: string,
    progSpools: number[],
  ): Observable<void> {
    const body = {
      idLicenza: licenseId,
      codSia: siaCode,
      username,
      password,
      otp,
      progSpools,
    };
    return this.http.post<void>(this.signUrl, body);
  }

  cancel(licenseId: string, siaCode: string, spools: number[]) {
    const body = {
      idLicenza: licenseId,
      codSia: siaCode,
      lotti: spools,
    };
    return this.http.delete(this.cancelUrl, { body });
  }

  confim(reason: string, licenseId: string, siaCode: string, spools: number[]) {
    return of(true);
  }
}
