import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { getRouterInfo } from '@ctel/search-filter-store';
import { IFilterUrl } from '@ctel/search-filter-store/lib/entities/entities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Store, select } from '@ngrx/store';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { AccountType } from 'app/core/common/account/accountType';
import * as Rison from 'rison-node';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { FilterService } from '../../documents/filters/filter.service';
import { switchHomeDateFilterAccount } from '../../documents/filters/store/home-filter.actions';
import { HOME_URI_STATE_TOKENS, selectHomeFilterState } from '../../documents/filters/store/home-filter.selectors';
import * as AccountActions from './account.actions';
import { AccountState } from './account.reducer';
import { getCurrentAccount } from './account.selectors';

@Injectable({
  providedIn: 'root',
})
export class AccountEffects {
  onAccountTypeChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(
        this.store.pipe(select(getRouterInfo)),
        this.store.pipe(select(getCurrentAccount)),
        this.store.pipe(select(selectHomeFilterState)),
      ),
      filter(
        ([, routerInfo, ,]) => routerInfo && routerInfo.state && routerInfo.state.url.startsWith('/gawfe/home'),
        // routerInfo && routerInfo.state.url.startsWith('/home')
      ),
      map(([, routerInfo, currentAccount, homeFilterState]) => ({
        queryParams: routerInfo.state.queryParams,
        currentAccount,
        homeFilterState,
      })),
      /*
distinctUntilChanged((x, y) => {
	return _.isEqual(x.queryParams, y.queryParams);
}),
*/
      map((value) => {
        const computedAccount = this.computeAccount(value.queryParams);
        return {
          ...value,
          computedAccount,
        };
      }),
      filter((value) => value.computedAccount !== value.currentAccount),
      switchMap((value) => {
        let accountType: AccountType;
        let otherActions = [];
        if (!value.homeFilterState.licenseId && !value.homeFilterState.siaCode)
          // Se non ho uno stato ancora, sono in fase primo caricamento.
          accountType = value.computedAccount;
        else {
          accountType = value.computedAccount;
          const payload = this.filterService.buildSwitchPayload(accountType);
          otherActions = [switchHomeDateFilterAccount(payload.filters, payload.metrics, false, [])];
        }
        return [AccountActions.changeAccountType(accountType), ...otherActions];
      }),
    ),
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<AccountState>,
    private filterService: FilterService,
    private router: Router,
  ) {}

  private computeAccount(queryParams: Params): AccountType {
    if (!queryParams || !queryParams[HOME_URI_STATE_TOKENS.HOME_FILTERS]) return AccountType.RECEIVABLE;

    const filterUrl: IFilterUrl[] = Rison.decode_uri(queryParams[HOME_URI_STATE_TOKENS.HOME_FILTERS]);

    if (!filterUrl || filterUrl.length === 0) return AccountType.RECEIVABLE;

    // La condizione per la quale cambiamo account è la presenza o meno di dataProtocollazioneCtel o timestampCtelRicezioneSdi.
    const filterFound: IFilterUrl = filterUrl.find(
      (value) => value.m === MetadataEnum.DATA_INSERIMENTO || value.m === MetadataEnum.DATA_RICEZIONE,
    );
    if (!filterFound) return AccountType.RECEIVABLE;

    return filterFound.m === MetadataEnum.DATA_INSERIMENTO ? AccountType.RECEIVABLE : AccountType.PAYABLE;
  }
}
