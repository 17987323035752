import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che trasforma uno stringArray in un array di stringhe reale
 */
@Pipe({
  name: 'stringArrayToArray',
})
export class StringarrayToArrayPipe implements PipeTransform {
  transform(value: string) {
    let array = [];
    if (value !== null && value !== undefined && value !== '[]') {
      value = value.replace('["', '').replace('"]', '').replace('" ', '"').replace(' "', '"').trim();
      array = value.split('","');
    }
    return array;
  }
}
