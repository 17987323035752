import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che fa replace di alcuni caratteri speciali
 */
@Pipe({
  name: 'specialchars',
})
export class SpecialcharsPipe implements PipeTransform {
  transform(value: string) {
    if (value !== null && value !== undefined) {
      if (value.includes('&apos;')) return value.replace('&apos;', "'");

      if (value.includes('&amp;')) return value.replace('&amp;', '&');

      if (value.includes('_')) return value.replace('_', ' ');
    }
    return value;
  }
}
