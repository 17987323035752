import { Location } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DocumentsViewService, FilterStatus } from '@ctel/gaw-commons';
import { FilterActions } from '@ctel/search-filter-store';
import { Store } from '@ngrx/store';
import { NavigationService } from 'app/core/business/navigation/navigation.service';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { DocumentsService2 } from 'app/modules/homepage/core/documents-search/documents/documents.service';
import { fromEvent, of, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

/**
 * Componente che rappresenta il container dell'intera pagina
 */
@Component({
  selector: 'gaw-basiclayout',
  templateUrl: 'basicLayout.component.html',
  styleUrls: ['basicLayout.component.scss'],
})
export class BasicLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inner', { static: false }) inner: ElementRef;
  public showSidebar = false;
  route: string;
  isOpen = false;
  public showSearchbar = true;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private locationUrl: Location,
    private navigationService: NavigationService,
    private documentsViewService: DocumentsViewService,
    private documentsService: DocumentsService2,
    public notificationService: NotificationService,
    private store: Store<FilterStatus>,
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (locationUrl.path() !== '') this.route = locationUrl.path();
      else this.route = '/home';
    });
  }

  public ngOnInit(): void {
    this.showSidebar = true;
    this.navigationService
      .whenSidebar()
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.isOpen = val;
      });
  }

  ngAfterViewInit() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e) => {
      this.showSearchbar = !(this.route.includes('/help/') || this.route.includes('/configuration/'));
      if (this.checkIfRouteIsScrollable() && e instanceof NavigationEnd)
        requestAnimationFrame(() => {
          if (this.inner.nativeElement.parentNode)
            this.inner.nativeElement.parentNode.scrollTop = this.documentsViewService.getDocumentsScrollPosition();
        });
    });

    // Rimane in ascolto dell'evento scroll e se sono su una pagina di ricerca documenti
    // e non sono già tutti visualizzati fa partire una chiamata per avere altri n documenti.
    // TODO: questa cosa ha un fire rate elevato. Va rifattorizzato per essere usato in scroll throttling
    // (tramite requestAnimationFrame) o trovata una strategia migliore per gestire lo scroll in modo globale.
    // Al momento ci affidiamo al debounceTime che scarta un'innumerevole quantità di esecuzioni inutili, alleggerendo
    // significativamente il rendering e dando un'esperienza utente più "smooth", specie in caso di scroll continuo.
    fromEvent(this.inner.nativeElement.parentNode, 'scroll')
      .pipe(
        debounceTime(1000),
        switchMap(() => {
          if (!this.checkIfRouteIsScrollable()) return of(null);

          this.documentsViewService.sendDocumentsScrollPosition(this.inner.nativeElement.parentNode.scrollTop);
          if (!this.checkIfBottom()) return of(null);

          return this.documentsService.whenTotalDocuments();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((totDocs) => {
        if (totDocs === null) return;

        const documents = this.documentsService.getDocuments();
        if (documents !== null) {
          if (documents.length !== totDocs) this.loadNextDocs(documents.length);
        } else this.loadNextDocs(totDocs);
      });
  }

  // infinite scroll: caricamento altri documenti
  loadNextDocs(visibleDocCount: number) {
    this.store.dispatch(
      FilterActions.changeFilterRequested({
        kind: FilterActions.ChangeFilterRequestedKind.PagingChanged,
        metadata: '',
        pagingOffset: visibleDocCount,
      }),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private checkIfRouteIsScrollable() {
    return (
      this.route.includes('/documents/') &&
      !this.route.includes('/home') &&
      !this.route.includes('/doc/') &&
      !this.route.includes('/details/')
    );
  }

  private checkIfBottom(): boolean {
    // altezza totale dell'elemento scrollabile
    const totalScrollableHeight = this.inner.nativeElement.parentNode.scrollHeight;
    // posizione scrollata a partire dallo zero (top della pagina)
    const scrolledFromTop = this.inner.nativeElement.parentNode.scrollTop;
    // costante, altezza della scrollbar (cioè la viewport)
    const scrollbarTotalHeight = this.inner.nativeElement.parentNode.offsetHeight;
    // tolleranza dalla fine della pagina oltre la quale parte l'infinite scroll (5%)
    const tollerance = (totalScrollableHeight / 100) * 5;
    // dove è arrivato lo scroll sulla altezza totale
    const sum = scrolledFromTop + scrollbarTotalHeight;

    return sum > totalScrollableHeight - tollerance;
  }
}
