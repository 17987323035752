<div
  class="navigation tooltip-350"
  [ngClass]="{ active: isActive }"
  placement="auto"
  [adaptivePosition]="false"
  container="body"
  tooltip="{{ navigationItem.description }}"
>
  <div class="nav-item" disabled (click)="onMenuItemClick(navigationItem)">
    <img *ngIf="navigationItem.img" [src]="navigationItem.img" />
    <i *ngIf="navigationItem.icon" [ngClass]="navigationItem.icon"></i>
    <p *ngIf="!navigationItem.hasLogo">{{ navigationItem.description }}</p>
  </div>
</div>
