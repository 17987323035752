import { Injectable } from '@angular/core';
import { SnackbarService } from '@ctel/snackbar';
import { SnackbarAction } from './snackbar-action';
import { SnackbarInfo } from './snackbar-info';
import { SnackbarOptions } from './snackbar-options';

@Injectable()
export class SnackBarService {
  private timeout = 4000;

  constructor(private snackbarService: SnackbarService) {}

  public show(data: SnackbarInfo) {
    const snackbarAction = data.action ? data.action : new SnackbarAction('');

    const options: SnackbarOptions = {
      msg: data.message,
      timeout: this.timeout,
      action: snackbarAction,
    };

    // cancella snackbar attivi prima di crearne uno nuovo
    if (data.clearBeforeShow) this.snackbarService.clear();

    this.snackbarService.add(options);
  }

  public clear() {
    this.snackbarService.clear();
  }
}
