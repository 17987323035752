import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { Observable } from 'rxjs';

@Injectable()
export class InvoiceHttpService {
  constructor(
    private httpClient: HttpClient,
    public configService: ConfigService,
  ) {}

  public getInvoiceHintMock(editInvoice: boolean): Observable<any> {
    const url = editInvoice
      ? '/assets/mock/invoice-hint/edit-invoice-hint-config.json'
      : '/assets/mock/invoice-hint/create-invoice-hint-config.json';
    return this.httpClient.get(url);
  }
}
