import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ComponentsModule } from 'app/shared/components/components.module';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ErrorDisplayComponent } from '../../error/error-display/error-display.component';
import { ModalContainerComponent } from './containers/modal-container/modal-container.component';
import { ModalService } from './modal.service';

@NgModule({
  imports: [CommonModule, ModalModule, PopoverModule, ComponentsModule, CollapseModule, PipesModule],
  declarations: [
    // Contenitori
    ErrorDisplayComponent,
    ModalContainerComponent,
  ],
  exports: [],
})
export class ModalEngineModule {
  static forRoot(): ModuleWithProviders<ModalEngineModule> {
    return {
      ngModule: ModalEngineModule,
      providers: [ModalService],
    };
  }
}
