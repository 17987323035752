<table>
  <!-- Header -->
  <thead>
    <tr>
      <!-- Selection checkbox-->
      <th *ngIf="options.rowSelection !== 'none'" class="checkbox-header">
        <!-- Visualizzo la checkbox solo se posso selezionare più di una checkbox -->
        <label *ngIf="options.rowSelection === 'multi'">
          <input
            type="checkbox"
            #checkboxHeader="ngModel"
            [ngModel]="allCheckboxSelected$ | async"
            (click)="setAllRowSelection(!checkboxHeader.value)"
          />
        </label>
      </th>

      <!-- Header -->
      <th *ngFor="let column of columns" [ngStyle]="column.styles">
        <div class="column-header">
          <div
            class="header-content"
            (click)="setSorting(column)"
            [ngClass]="{
              'cursor-pointer': options.sorting,
            }"
          >
            <ng-container [column]="column" gridHeaderRenderer></ng-container>

            <!-- Sort icon -->
            <ng-container *ngIf="options.sorting && column.isSortable">
              <div class="sort">
                <i
                  class="fas"
                  [ngClass]="{
                    'fa-sort-up': column.field.id === _actualSort.columnId && _actualSort.order === sortType.ASCENDING,
                    'fa-sort-down':
                      column.field.id === _actualSort.columnId && _actualSort.order === sortType.DESCENDING,
                    'fa-sort': column.field.id !== _actualSort.columnId || _actualSort.order === sortType.NOT_SET,
                  }"
                ></i>
              </div>
            </ng-container>
          </div>

          <!-- Filter icon -->
          <ng-container *ngIf="options.filter && column.isFilterable">
            <div
              class="filter-icon"
              [popover]="inputPopover"
              #popover="bs-popover"
              [outsideClick]="false"
              placement="auto"
              container="body"
            >
              <i
                [class]="faIcons.FAS_FILTER"
                [ngClass]="{
                  disabled: !_actualFilter[column.field.id],
                }"
                aria-hidden="true"
              >
              </i>
            </div>

            <ng-template #inputPopover>
              <ng-template *ngTemplateOutlet="formFilter; context: { column: column, popover: popover }"></ng-template>
            </ng-template>
          </ng-container>
        </div>
      </th>
    </tr>
  </thead>

  <tbody *ngIf="rows?.length > 0">
    <tr
      *ngFor="let row of rows"
      [ngClass]="{
        'cursor-default': this.options.dblClick,
      }"
    >
      <!-- Selection checkbox-->
      <ng-container *ngIf="options.rowSelection !== 'none'">
        <td class="checkbox-cell">
          <label><input type="checkbox" [ngModel]="row.isSelected" (ngModelChange)="toggleSelection(row)" /></label>
          <ng-container
            [row]="row"
            [c]="options.checkboxAddCellComponent"
            gridCheckboxSelectAdditionRenderer
          ></ng-container>
        </td>
      </ng-container>

      <td
        *ngFor="let c of columns; trackBy: gridTrackBy"
        [ngStyle]="options.setRowStyle(row)"
        clickable
        [preventDefault]="true"
        (doubleClick)="doubleClick(row)"
      >
        <div class="content">
          <!-- Cell content -->
          <ng-container [column]="c" [row]="row" gridCellRenderer></ng-container>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="rows?.length < 1">
  <span class="no-data">Nessun elemento disponibile</span>
</div>

<!-- Form Filter -->
<ng-template #formFilter let-column="column" let-popover="popover">
  <form (ngSubmit)="insertFilter(column, formModel[column.field.id], popover)" #f="ngForm" class="form-filter">
    <div class="filter-input">
      <ng-container [ngSwitch]="column.field.type">
        <!-- TODO: Attualmente il tipo Date viene gestito come DateRange -->
        <!-- <ng-container *ngSwitchCase="fieldType.DATE">-->
        <!-- <input type="text" class="form-control"-->
        <!-- name="{{ column.field.id }}"-->
        <!-- ngModel-->
        <!-- bsDatepicker-->
        <!-- [(ngModel)]="formModel[column.field.id]"-->
        <!-- (ngModelChange)="insertFilter(column, formModel[column.field.id], popover)"-->
        <!-- autocomplete="off"-->
        <!-- [bsConfig]="{-->
        <!-- dateInputFormat: column.field?.['format'],-->
        <!-- containerClass: 'theme-blue',-->
        <!-- showWeekNumbers: false-->
        <!-- }">-->
        <!-- </ng-container>-->
        <ng-container *ngSwitchCase="fieldType.DATE">
          <input
            type="text"
            class="form-control"
            name="{{ column.field.id }}"
            ngModel
            bsDaterangepicker
            [(ngModel)]="formModel[column.field.id]"
            (change)="onPickerChange(datePickerModel)"
            (ngModelChange)="datePickerInput.focus()"
            #datePickerModel="ngModel"
            #datePickerInput
            autocomplete="off"
            [bsConfig]="{
              dateInputFormat: column.field?.['format'],
              containerClass: 'theme-blue',
              showWeekNumbers: false,
            }"
          />
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.NUMBER">
          <input
            type="number"
            class="form-control"
            autocomplete="off"
            name="{{ column.field.id }}"
            [(ngModel)]="formModel[column.field.id]"
          />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
            type="text"
            class="form-control"
            autocomplete="off"
            name="{{ column.field.id }}"
            [(ngModel)]="formModel[column.field.id]"
          />
        </ng-container>
      </ng-container>
    </div>
    <button type="submit" class="btn btn-success gaw-button filter-button">
      <i [ngClass]="faIcons.FAS_CHECK" aria-hidden="true"></i>
    </button>
    <button type="button" class="btn btn-danger btn-cancel filter-button" (click)="deleteFilter(column, popover)">
      <span class="icon-stack filter-remove">
        <i class="icon-stack-base" [ngClass]="faIcons.FAS_FILTER"></i>
        <i class="icon-stack-added" [ngClass]="faIcons.FAS_TIMES"></i>
      </span>
    </button>
  </form>
</ng-template>
