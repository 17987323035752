import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@ctel/auth';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gaw-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  login(): void {
    this.route.queryParams
      .pipe(
        map((queryParams) => queryParams['bridgetk']),
        takeUntil(this.destroy$),
      )
      .subscribe((param) => {
        if (param) this.authService.login(param);
        else this.authService.login();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
