import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable } from 'rxjs';
import { ChannelStateMapping } from '../entities/channel-state-mapping';
import { Details } from '../entities/details';

@Injectable({
  providedIn: 'root',
})
export class DetailsHttpService {
  private jsonContentType = 'application/json';

  // hosts
  private docDetailsHost: string;
  private gawlottiReadHost: string;
  private searchHost: string;
  private archivioFiscaleHost: string;

  // endpoints
  private serviceList: string;
  private docDetails: string;
  private docDetailsGawcons: string; // TODO da eliminare quando funziona clientId
  private serviceListGawlotti: string;
  private lottiDocDetails: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
    this.docDetailsHost = appConfig.docDetails.http.host;
    this.serviceList = `${this.docDetailsHost}/v2/Document/DocumentChannelsStateMapping?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}`;
    /* ENDPOINTS MAGELLANO ---------------------------------------------------------------------------------------------------------- */

    this.searchHost = appConfig.search.http.host;
    this.docDetails = `${this.searchHost}/v3/documents/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?includeTechnicalMetadata={includeTechnical}&clientid={clientId}`;

    /* ENDPOINTS GAWLOTTI ---------------------------------------------------------------------------------------------------------- */
    this.gawlottiReadHost = appConfig.gawLottiRead.http.host;
    this.serviceListGawlotti = `${this.gawlottiReadHost}/api/v1/Spool/{progSpool}/Channels/{licenseId}/{codSia}`;
    this.lottiDocDetails = `${this.gawlottiReadHost}/api/v1/StoricoStati/Spool/{progSpool}/Channel/{tipoCanale}/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/{includeTechnicalMetadata}`;

    /* ENDPOINTS GAWCONS ------------------------------------------------------------------------------------------------------- */
    this.archivioFiscaleHost = appConfig.gawConsArchivioFiscaleRead.http.host;
    this.docDetailsGawcons = `${this.archivioFiscaleHost}/v1/archivioFiscale/magellano/{licenseId}/{siaCode}/{docSeriesId}/{elasticDocumentId}/details?includeTechnicalMetadata={includeTechnical}`;
  }

  whenServiceList(progSpool: string, progBusta: string, docHash: string): Observable<ChannelStateMapping[]> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    // url = this.serviceListGawlotti
    // 	.replace('{progSpool}', progSpool)
    // 	.replace('{licenseId}', licenseId)
    // 	.replace('{codSia}', codSia);
    //
    // return this.http.get(url, options);
    if (docHash === '') docHash = 'null';

    const url = this.serviceList
      .replace('{docHash}', docHash === '' ? null : docHash)
      .replace('{progSpool}', progSpool)
      .replace('{progBusta}', progBusta);

    return this.http.get<ChannelStateMapping[]>(url, options);
  }

  // DETTAGLIO METADATI DEL DOCUMENTO ------------------------------------------------------------------------------
  whenDetails(
    licenseId: string,
    siaCode: string,
    elasticDocumentId: string,
    docSeriesId: string,
    includeTechnical: boolean,
    clientId: string,
  ): Observable<Details> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.docDetails
      .replace('{docSeriesId}', docSeriesId)
      .replace('{elasticDocumentId}', elasticDocumentId)
      .replace('{includeTechnical}', String(includeTechnical))
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{clientId}', clientId);
    return this.http.get<Details>(url, options);
  }

  whenDetailsGawcons(
    licenseId: string,
    siaCode: string,
    elasticDocumentId: string,
    docSeriesId: string,
    includeTechnical: boolean,
  ): Observable<Details> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.docDetailsGawcons
      .replace('{docSeriesId}', docSeriesId)
      .replace('{elasticDocumentId}', elasticDocumentId)
      .replace('{includeTechnical}', String(includeTechnical))
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    return this.http.get<Details>(url, options);
  }

  whenLottoDetails(
    progSpool: string,
    tipoCanale: string,
    licenseId: string,
    siaCode: string,
    elasticDocumentId: string,
    docSeriesId: string,
    includeTechnicalMetadata: boolean,
  ): Observable<Details> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.lottiDocDetails
      .replace('{progSpool}', progSpool)
      .replace('{tipoCanale}', tipoCanale)
      .replace('{elasticDocumentId}', elasticDocumentId)
      .replace('{includeTechnicalMetadata}', String(includeTechnicalMetadata))
      .replace('{docSeriesId}', docSeriesId)
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode);
    return this.http.get<Details>(url, options);
  }

  whenServiceListGawlotti(progSpool: string, licenseId: string, codSia: string) {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.serviceListGawlotti
      .replace('{progSpool}', progSpool)
      .replace('{licenseId}', licenseId)
      .replace('{codSia}', codSia);

    return this.http.get<{ channels: { channel: number; label: string }[] }>(url, options);
  }
}
