import { Injectable } from '@angular/core';
import { CessionarioCommittenteKeyValue } from 'app/core/common/actions/send-analogic-copy/cessionario-committente-key-value';
import { Observable } from 'rxjs';
import { SendAnalogicCopyHttpService } from './send-analogic-copy-http.service';

@Injectable()
export class SendAnalogicCopyService {
  constructor(private sendAnalogicHttpService: SendAnalogicCopyHttpService) {}

  getCessionarioCommittente(
    progSpool: number,
    progBusta: number,
    hash: string,
  ): Observable<CessionarioCommittenteKeyValue[]> {
    return this.sendAnalogicHttpService.getCessionarioCommittente(progSpool, progBusta, hash);
  }
}
