import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { EventService } from 'app/core/common/event/event.service';
import { ModalContent } from 'app/core/common/notification/modal/containers/modal-content';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { WorkflowEvent } from '../../workflow-event';

@Component({
  templateUrl: './workflow-modal.component.html',
  styleUrls: ['./workflow-modal.component.scss'],
})
export class WorkflowModalComponent implements ModalContent, OnInit, OnDestroy {
  env = environment;
  close: () => void;
  data: {
    mixedMode: boolean;
  };
  onHide: () => Observable<void>;

  viewMode: 'MixedMode' | 'FullFrame';

  public newPSCredem$: Observable<boolean>;

  private destroy$ = new Subject<void>();

  constructor(
    private eventService: EventService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.onHide()
      .pipe(take(1))
      .subscribe(() => this.eventService.emit(WorkflowEvent.E_MODAL_CLOSE));

    if (this.data.mixedMode) this.viewMode = 'MixedMode';
    else this.viewMode = 'FullFrame';

    this.newPSCredem$ = this.configService.whenAppConfig().pipe(
      take(1),
      map((appConfig) => appConfig.featureFlags.newPSCredem),
    );
  }

  closeModal($event) {
    if ($event) this.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
