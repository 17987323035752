<ng-container
  *hubNewError="statusHistoryError$ | async; options: tileErrorPlaceholderErrorOption; style: { height: '100px' }"
>
  <div *hubLoading="historyPanelLoading; style: { height: '200px' }" class="row px-3 py-4">
    <!--PANEL HISTORY STATI CANALE-->
    <ng-container *ngIf="statusHistory$ | async as statusHistory">
      <table class="table table-responsive table-striped">
        <thead>
          <tr>
            <th *ngFor="let statusHistoryItem of statusHistory[0].storicoItem">
              {{ statusHistoryItem.headerLabel }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let statusHistoryItem of statusHistory; let rowIndex = index">
            <tr>
              <td *ngFor="let cellItem of statusHistoryItem.storicoItem; let i = index">
                <span *ngIf="i !== 2">{{ cellItem.value }}</span>
                <span *ngIf="i === 2">{{ cellItem.value | isoDatetimeToDatetime }}</span>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container>
