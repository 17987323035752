import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { filter, map, share, switchMap } from 'rxjs/operators';
import { EcmService } from '../../../../../ecm/ecm.service';
import { XmlPaAttachment } from '../../../../../entities/ecm/ecm';
import { AttachmentItem } from '../../shared/attachment-item';
import { DocumentAttachmentService } from '../document-attachment.service';

@Component({
  selector: 'gaw-wf-doc-attachment-preview',
  templateUrl: './document-attachment-preview.component.html',
  styleUrls: ['./document-attachment-preview.component.scss'],
})
export class DocumentAttachmentPreviewComponent implements OnInit, OnDestroy {
  @Input() attachmentId: string;
  @Output() goToList = new EventEmitter<void>();

  /** Allegato corrente */
  public attachment$: Observable<AttachmentItem>;

  /**
   * Mi indica se nella lista allegati ho solo un elemento e se questo elemento ha a disposizione la preview
   */
  hasBackButton$: Observable<boolean>;

  private readonly outletName = 'workitem-detail';

  private destroy$ = new Subject<void>();

  constructor(
    private documentAttachmentService: DocumentAttachmentService,
    private ecmService: EcmService,
  ) {}

  ngOnInit() {
    // Ottengo il contenuto dell'allegato
    const attachmentContent$ = (attachment: XmlPaAttachment) =>
      of(attachment).pipe(
        switchMap((att) => this.documentAttachmentService.downloadAttachment(att)),
        share({ connector: () => new ReplaySubject<string | Blob>(1) }),
      );

    // Ottengo l'allegato tramite id
    this.attachment$ = this.documentAttachmentService.getAttachmentById(this.attachmentId).pipe(
      filter((att) => att !== undefined),
      map(
        (attachment) =>
          <AttachmentItem>{
            id: attachment.idAllegato.toString(),
            mimeType: attachment.mimeType,
            filename: attachment.nomeAllegato,
            content: attachmentContent$(attachment),
          },
      ),
    );

    this.hasBackButton$ = this.documentAttachmentService.attachmentList$.pipe(map((list) => list.length > 1));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
