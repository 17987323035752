import { Injectable, OnDestroy } from '@angular/core';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ConfigService } from 'app/core/common/config/config.service';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { Company } from 'app/entities/companies/company';
import { AppConfig } from 'app/entities/config/app-config';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { EMPTY, Observable, ReplaySubject, take, takeUntil } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SignReasonModalComponent } from '../../modals/sign-reason-modal/sign-reason-modal.component';
import { ActionsHttpService } from './actions-http.service';

import { AppError } from 'app/core/common/error';
import { FRLottiDocumentsService2 } from '../documents/documents.service';
import { FRLottiActionsService2 } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class FRLottiExecuteActionsService implements OnDestroy {
  currentCompany: Company;
  private appConfig: AppConfig;
  private downloadAction?: ActionObject;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private actionsService: FRLottiActionsService2,
    private documentsService: FRLottiDocumentsService2,
    private companiesService: CompaniesService,
    confService: ConfigService,
  ) {
    this.currentCompany = companiesService.getCurrentCompanyValue();
    confService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => (this.appConfig = value));
    this.actionsService
      .whenSingleActionsList()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) =>
          (this.downloadAction = value?.find((value1) => value1.actionCode === ActionCode.DOWNLOAD_PRESERVED_DOCUMENT)),
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  public executeSingleAction(action: ActionObject, data: unknown) {
    const actionCode = action.actionCode;
    switch (actionCode) {
      case ActionCode.SIGN:
        this.actionsHttpService
          .anomalyCheck(data['licenseId'], data['siaCode'], [+data['keys']?.progSpool])
          .pipe(take(1))
          .subscribe((value) => {
            if (value.length)
              this.notificationService.showSweetAlert(
                NotificationType.QUESTION,
                'Firma',
                'Stai firmando documenti con anomalie, sei sicuro?',
                () =>
                  this.reasonConfirm(data['licenseId'], data['siaCode'], [+data['keys']?.progSpool])
                    // eslint-disable-next-line rxjs/no-nested-subscribe
                    .subscribe({
                      next: () => this.openSign(data['licenseId'], data['siaCode'], [+data['keys']?.progSpool]),
                      error: (error: unknown) => {
                        this.notificationService.showSweetAlert(
                          NotificationType.ERROR,
                          'Errore',
                          (error as AppError).additionalInfo.find((x) => x.t === 'response')?.u,
                        );
                      },
                    }),
              );
            else this.openSign(data['licenseId'], data['siaCode'], [+data['keys']?.progSpool]);
          });
        break;
      case ActionCode.CANCEL:
        this.cancel(data['licenseId'], data['siaCode'], [+data['keys']?.progSpool]);
        break;
    }
  }

  reasonConfirm = (licenseId: string, siaCode: string, progSpools: number[]) =>
    this.actionsHttpService.reasonConfirm(licenseId, siaCode, 'Conferma automatica', progSpools);

  sign = (
    username: string,
    password: string,
    otp: number,
    licenseId: string,
    siaCode: string,
    progSpools: number[],
  ): Observable<void> =>
    this.actionsHttpService
      .sign(username, password, otp, licenseId, siaCode, progSpools)
      .pipe(
        tap(() =>
          this.notificationService.showSweetAlert(
            NotificationType.SUCCESS,
            'Firma',
            'Operazione avvenuta con successo',
            () => this.documentsService.refreshDocuments(),
          ),
        ),
      );

  public executeMultiAction(action: ActionObject | ActionConfigItem, keys: unknown[]) {
    const actionCode = action.actionCode;
    const siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
    const licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
    switch (actionCode) {
      case ActionCode.SIGN:
        this.actionsHttpService
          .anomalyCheck(
            licenseId,
            siaCode,
            keys.map((value) => +value['progSpool']),
          )
          .pipe(take(1))
          .subscribe((value) => {
            if (value.length)
              this.notificationService.showSweetAlert(
                NotificationType.QUESTION,
                'Firma',
                'Stai firmando documenti con anomalie, sei sicuro?',
                () =>
                  this.reasonConfirm(
                    licenseId,
                    siaCode,
                    keys.map((spool) => +spool['progSpool']),
                  )
                    // eslint-disable-next-line rxjs/no-nested-subscribe
                    .subscribe({
                      next: () =>
                        this.openSign(
                          licenseId,
                          siaCode,
                          keys.map((spool) => +spool['progSpool']),
                        ),
                      error: (error: unknown) => {
                        this.notificationService.showSweetAlert(
                          NotificationType.ERROR,
                          'Errore',
                          (error as AppError).additionalInfo.find((x) => x.t === 'response')?.u,
                        );
                      },
                    }),
              );
            else
              this.openSign(
                licenseId,
                siaCode,
                keys.map((spool) => +spool['progSpool']),
              );
          });
        break;
      case ActionCode.CANCEL:
        this.cancel(
          licenseId,
          siaCode,
          keys.map((value) => +value['progSpool']),
        );
        break;
    }
  }

  private cancel(licenseId: string, siaCode: string, spools: number[]) {
    this.notificationService.showSweetAlert(NotificationType.QUESTION, 'Cancella', 'Confermi la cancellazione?', () => {
      this.actionsHttpService
        .cancel(licenseId, siaCode, spools)
        .pipe(
          catchError((err: unknown) => {
            let errorMsg = '';
            Object.keys((err as AppError).additionalInfo[0].u.errors).forEach(
              (key) => (errorMsg += `${(err as AppError).additionalInfo[0].u.errors[key]}\n`),
            );
            this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', errorMsg);
            return EMPTY;
          }),
        )
        .subscribe(() => {
          this.notificationService.showSweetAlert(
            NotificationType.SUCCESS,
            '',
            'I lotti selezionati sono stati cancellati con successo',
          );
          this.documentsService.refreshDocuments();
        });
    });
  }

  private openSign(licenseId: string, siaCode: string, progSpools: number[]) {
    this.notificationService.showModal(NotificationType.CUSTOM, {
      title: 'Firma',
      childComponent: SignReasonModalComponent,
      customFooter: true,
      disableClickOutside: false,
      childData: {
        licenseId,
        siaCode,
        progSpools,
        reasonConfirm: this.reasonConfirm,
        sign: this.sign,
      },
    });
  }
}
