/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { DateValidators } from 'app/shared/components/dry/validator/date-validators';

@Directive({
  selector: '[isDate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IsDateValidatorDirective, multi: true }],
})
export class IsDateValidatorDirective implements Validator, OnChanges {
  @Input('isDate') format: string;
  private validator: ValidatorFn;

  registerOnValidatorChange(fn: () => void): void {}

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) this.validator = DateValidators.isDate(this.format);
  }
}
