export class ErrorType {
  constructor(
    public code: string,
    public description: string,
  ) {}

  toString() {
    return this.code + ': ' + this.description;
  }
}
