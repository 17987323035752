import { Component } from '@angular/core';
import { DecimalField } from 'app/shared/components/dry/field/specializations/decimal-field';
import { AbstractCellComponent } from '../abstract-cell.component';

@Component({
  templateUrl: './decimal-cell.component.html',
  styleUrls: ['./decimal-cell.component.scss'],
})
export class DecimalCellComponent extends AbstractCellComponent<DecimalField> {
  value: number;
}
