import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { canActivateAccess, canActivateAuth, canActivateAutoLogin } from '@ctel/auth';
import { AccessDeniedModule } from 'app/modules/access-permission-denied/access-denied.module';
import { ErrorModule } from 'app/modules/error/error.module';
import { ForbiddenComponent } from 'app/modules/error/forbidden/forbidden.component';
import { NotFoundComponent } from 'app/modules/error/not-found/not-found.component';
import { VersionComponent } from 'app/modules/error/version/version.component';
import { LandingComponent } from 'app/modules/landing/landing.component';
import { UnauthorizedComponent } from 'app/modules/unauthorized/unauthorized.component';
import { UnauthorizedModule } from 'app/modules/unauthorized/unauthorized.module';
import { BasicLayoutComponent } from 'app/shared/layouts/basiclayout/basicLayout.component';
import { BlankLayoutComponent } from 'app/shared/layouts/blanklayout/blankLayout.component';
import { DocumentPermissions } from './core/business/user/permissions/document-permissions.enum';
import { AuthorizationGuard } from './core/common/guards/authorization.guard';
import { NotificationAsyncActionsGuard } from './core/common/guards/notification-async-action.guard';
import { PermissionGuard } from './core/common/guards/permission.guard';
import { AccessDeniedComponent } from './modules/access-permission-denied/access-denied.component';
import { BlockpageWafComponent } from './modules/blockpage-waf/blockpage-waf.component';
import { BlockpageWafModule } from './modules/blockpage-waf/blockpage-waf.module';
import { ServiceDisabledComponent } from './modules/service-disabled/service-disabled.component';
import { ServiceDisabledModule } from './modules/service-disabled/service-disabled.module';
import { HeaderLayoutComponent } from './shared/layouts/headerlayout/headerlayout.component';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: 'landing', component: LandingComponent },
      { path: 'version', component: VersionComponent },
      // {
      // 	path: 'kitchensink',
      // 	loadChildren: () => import('app/pages/protected/kitchen-sink/kitchen-sink.module').then(m => m.KitchenSinkModule)
      // },
    ],
  },
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [canActivateAccess, canActivateAutoLogin],
    children: [
      { path: 'home', redirectTo: 'gaw', pathMatch: 'full' },
      {
        path: 'gaw',
        loadChildren: () => import('app/modules/homepage/homepage.module').then((m) => m.HomepageModule),
      },
      {
        path: 'gaw30',
        loadChildren: () => import('app/modules/gaw30/gaw30.module').then((m) => m.Gaw30Module),
        data: {
          authorizedService: 'GAW30',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawfe',
        loadChildren: () => import('app/modules/hubfe/hubfe.module').then((m) => m.HubfeModule),
        data: {
          authorizedService: 'GAWFE',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawpec',
        loadChildren: () => import('app/modules/gawpec/gawpec.module').then((m) => m.GawpecModule),
        data: {
          authorizedService: 'GAWPEC',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawpost',
        loadChildren: () => import('app/modules/gawpost/gawpost.module').then((m) => m.GawpostModule),
        data: {
          authorizedService: 'GAWPOST',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawedi',
        loadChildren: () => import('app/modules/gawedi/gawedi.module').then((m) => m.GawediModule),
        data: {
          authorizedService: 'GAWEDI',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawnotice',
        loadChildren: () => import('../../projects/gawnotice/src/app/app.module').then((m) => m.AppModule),
        data: {
          authorizedService: 'GAWNOTICE',
        },
        // TODO: Configurare guardia autorizzativa GAWNOTICE
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawmail',
        loadChildren: () => import('app/modules/gawmail/gawmail.module').then((m) => m.GawmailModule),
        data: {
          authorizedService: 'GAWMAIL',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawlotti',
        loadChildren: () => import('app/modules/gawlotti/gawlotti.module').then((m) => m.GawlottiModule),
        data: {
          permissionRequired: DocumentPermissions.archivioLotti,
          authorizedService: 'GAWLOTTI',
        },
        canActivate: [PermissionGuard],
        // canActivate: [AuthorizationGuard]
      },
      {
        path: 'gawpass',
        loadChildren: () => import('app/modules/gawpass/gawpass.module').then((m) => m.GawpassModule),
        data: {
          authorizedService: 'GAWPASS',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawbi',
        loadChildren: () => import('app/modules/gawbi/gawbi.module').then((m) => m.GawbiModule),
        data: {
          authorizedService: 'GAWBI',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'portal-settings',
        loadChildren: () =>
          import('app/modules/portal-settings/portal-settings.module').then((m) => m.PortalSettingsModule),
        data: {
          permissionRequired: DocumentPermissions.settingsAccess,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'report-configuration',
        loadChildren: () =>
          import('app/modules/portal-settings/portal-settings.module').then((m) => m.PortalSettingsModule),
        data: {
          permissionRequired: DocumentPermissions.settingsAccess,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'gawcons',
        loadChildren: () => import('../../projects/gaw-cons/src/app/app.module').then((m) => m.AppModule),
        data: {
          authorizedService: 'GAWCONS',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'gawbin',
        loadChildren: () => import('app/modules/gawbin/gawbin.module').then((m) => m.GawBinModule),
        data: {
          permissionRequired: DocumentPermissions.cestino,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: 'gawcyber',
        loadChildren: () => import('app/modules/gawcyb/gawcyb.module').then((m) => m.GawCybModule),
        data: {
          authorizedService: 'GAWCYBER',
        },
        canActivate: [AuthorizationGuard],
      },
      {
        path: '',
        component: HeaderLayoutComponent,
        children: [
          {
            path: 'details',
            loadChildren: () => import('app/modules/details/details.module').then((m) => m.DetailsModule),
          },
          {
            path: 'user',
            loadChildren: () =>
              import('app/modules/personal-area/personal-area.module').then((m) => m.PersonalAreaModule),
          },
          {
            path: 'help',
            loadChildren: () => import('app/modules/help/help.module').then((m) => m.HelpModule),
          },
          {
            path: 'new',
            loadChildren: () =>
              import('app/modules/upload-lotto-zip/upload-lotto-zip.module').then((m) => m.UploadLottoZipModule),
            data: {
              permissionRequired: DocumentPermissions.showNewDocument,
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'communications',
            loadChildren: () =>
              import('app/modules/communications/communications.module').then((m) => m.CommunicationsModule),
          },
          {
            path: 'createinvoice',
            loadChildren: () =>
              import('app/modules/invoice-pa/create-invoice-pa/create-invoice.module').then(
                (m) => m.CreateInvoiceModule,
              ),
            data: {
              permissionRequired: DocumentPermissions.showNewDocument,
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'cloneinvoice',
            loadChildren: () =>
              import('app/modules/invoice-pa/clone-invoice-pa/clone-invoice.module').then((m) => m.CloneInvoiceModule),
            data: {
              permissionRequired: DocumentPermissions.showNewDocument,
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'address-book',
            loadChildren: () => import('app/modules/address-book/address-book.module').then((m) => m.AddressBookModule),
            data: {
              permissionRequired: DocumentPermissions.addressBook,
            },
            canActivate: [PermissionGuard],
          },
          {
            path: 'bridgeGedcons',
            loadChildren: () =>
              import('app/modules/bridge-gedcons/bridge-gedcons.module').then((m) => m.BridgeGedconsModule),
          },
          {
            path: 'notifications',
            children: [{ path: 'apiGet/:action', canActivate: [NotificationAsyncActionsGuard], children: [] }],
          },
          {
            path: 'ricerca-fatture',
            loadChildren: () =>
              import('app/modules/invoice-search/invoice-search.module').then((m) => m.InvoiceSearchModule),
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: HeaderLayoutComponent,
    canActivate: [canActivateAuth],
    children: [{ path: 'service-disabled', component: ServiceDisabledComponent }],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    canActivate: [canActivateAuth],
    children: [{ path: 'access-denied', component: AccessDeniedComponent }],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: '404', component: NotFoundComponent },
    ],
  },
  {
    path: '',
    component: BlankLayoutComponent,
    children: [{ path: 'blockpage-waf', component: BlockpageWafComponent }],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AccessDeniedModule,
    UnauthorizedModule,
    ServiceDisabledModule,
    ErrorModule,
    BlockpageWafModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
