<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <label class="form-label" for="note"
        ><strong>Inserisci la motivazione <span class="text-danger">*</span></strong></label
      >
      <textarea id="note" class="form-control" rows="3" [(ngModel)]="reason"></textarea>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn gaw-btn btn-primary" (click)="onReason()" [disabled]="!reason || loading">
    Conferma
  </button>
</div>
