import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SearchFilterStoreModule, documentStateReducer, filterStateReducer } from '@ctel/search-filter-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppCommonModule } from 'app/core/common/app-common.module';
import { DocumentEffects } from 'app/modules/homepage/core/documents-search/documents/store/document.effects';
import { FilterEffects } from 'app/modules/homepage/core/documents-search/filters/store/filter.effects';

const documentsSearchModuleFeatureKey = 'homeDocumentsSearchModule';

export const documentsSearchModuleReducers = {
  filterState: filterStateReducer,
  documentState: documentStateReducer,
};

@NgModule({
  imports: [
    CommonModule,
    AppCommonModule,
    SearchFilterStoreModule.forRoot(null),
    StoreModule.forFeature(documentsSearchModuleFeatureKey, documentsSearchModuleReducers),
    EffectsModule.forFeature([FilterEffects, DocumentEffects]),
  ],
})
export class DocumentsSearchModule {}
