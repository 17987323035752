import { Injectable } from '@angular/core';
import { ContactUs } from 'app/entities/contact-us/contact-us';
import { Faq } from 'app/entities/help/faq';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { DocumentsService2 } from 'app/modules/homepage/core/documents-search/documents/documents.service';
import { faq } from 'assets/mock/faq-ui-configuration/mock-faq';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UiConfigurationHttpService } from './ui-configuration-http.service';

@Injectable()
export class UiConfigurationService {
  constructor(
    public uiConfigurationHttpService: UiConfigurationHttpService,
    public documentsService: DocumentsService2,
  ) {}

  public getContactUsConfiguration(licenseId: string, siaCode: string): Observable<ContactUs> {
    return this.uiConfigurationHttpService.getContactUsConfiguration(licenseId, siaCode).pipe(
      take(1),
      map((response) => response.configurationsGroup.contactUsConfiguration),
    );
  }

  public getRelatedData(licenseId: string, siaCode: string, sectionId: string): Observable<RelatedSectionData[]> {
    return this.uiConfigurationHttpService.getRelatedSearchData(licenseId, siaCode, sectionId);
  }

  public getMockFaqConfiguration(): Observable<Faq[]> {
    return this.uiConfigurationHttpService.getFaqConfiguration(faq.retrieveFaq).pipe(take(1));
  }
}
