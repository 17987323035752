<table *ngIf="history$ | async as history">
  <ng-container *ngIf="history.length === 0"> Storico non disponibile per il documento selezionato. </ng-container>
  <ng-container *ngFor="let historyItem of history; let i = index">
    <tr *ngIf="i === 0">
      <th>Data invio</th>
      <th>Esito invio</th>
      <th>Note</th>
    </tr>
    <tr>
      <td>{{ historyItem.dataInvio | isoDatetimeToDatetime }}</td>
      <td>{{ historyItem.flagEsitoClienteSpedito | booleanToItalian: 'OK' : 'KO' }}</td>
      <td>{{ historyItem.note }}</td>
    </tr>
  </ng-container>
</table>
