import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, filter, map, share, switchMap, take, tap } from 'rxjs/operators';
import { LockStatus } from '../../../../entities/work-item/lock-status.enum';
import { WorkItem } from '../../../../entities/work-item/work-item';
import { WorkItemAction } from '../../../../entities/work-item/work-item-action';
import { WorkflowService } from '../../../../services/workflow.service';

@Component({
  selector: 'gaw-workitem-activity-actions',
  templateUrl: './workitem-activity-actions.component.html',
  styleUrls: ['./workitem-activity-actions.component.scss'],
})
export class WorkitemActivityActionsComponent implements OnInit {
  @Output() executeAction = new EventEmitter<WorkItemAction>();

  actions$: Observable<WorkItemAction[]>;
  isWorkItemLocked$: Observable<boolean>;
  public actionsLoading: boolean;

  constructor(private workflowService: WorkflowService) {}

  ngOnInit() {
    this.actionsLoading = true;
    const actualWorkItem$ = this.workflowService.selectedWorkItem$.pipe(
      share({ connector: () => new ReplaySubject<WorkItem>(1) }),
    );

    this.actions$ = actualWorkItem$.pipe(
      tap(() => (this.actionsLoading = false)),
      catchError(() => {
        this.actionsLoading = false;
        return EMPTY;
      }),
      filter((wi) => wi !== undefined),
      switchMap((wi) => this.workflowService.whenWorkItemActions(wi)),
    );

    this.actions$.pipe(take(1)).subscribe();

    this.isWorkItemLocked$ = actualWorkItem$.pipe(
      filter((wi) => wi !== undefined),
      map((wi) => wi.lockStatus === LockStatus.ITEM_LOCKED),
    );
  }

  executeWorkItemAction(action: WorkItemAction) {
    this.executeAction.emit(action);
  }
}
