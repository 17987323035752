import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorMessage } from 'app/entities/errors/errors';
import { CustomValidationError } from 'app/shared/components/dry/validator/custom-validation-error';

export class SelectValidators {
  /**
   * Validatore per il controllo del campo select.
   * Controlla che il valore siaCode contenuto nell'Array di ooggetti passato
   * @param valuesArray Array di oggetti disponibili nella select
   * @param valueId Identificatore del campo con cui controllare il valore
   * @return validatorFn
   */
  public static checkObject(valuesArray: Array<unknown>, valueId: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      if (!valuesArray) return null;

      if (control.value && control.value !== '') {
        const value = valuesArray.find((v) => v[valueId] === control.value);
        return value !== undefined
          ? null
          : <CustomValidationError>{
              select: {
                message: 'Il valore non coincide con quelli a disposizione',
              },
            };
      }
      return null;
    };
  }

  /**
   * Validatore per il controllo del campo select.
   * Controlla che il valore siaCode contenuto nell'Array di valori primitivi passato
   * @param valuesArray Array di valori primitivi disponibili nella select
   * @return validatorFn
   */
  public static checkPrimitiveValue(valuesArray: Array<unknown>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      if (!valuesArray) return null;

      if (control.value && control.value !== '') {
        const value = valuesArray.find((v) => v === control.value);
        return value !== undefined
          ? null
          : <CustomValidationError>{
              select: {
                message: 'Il valore non coincide con quelli a disposizione',
              },
            };
      }
      return null;
    };
  }
}
