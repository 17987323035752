import { Component, Input } from '@angular/core';
import { DocumentNotesService, Note } from '@ctel/document-notes';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs';

@Component({
  selector: 'gaw30-document-notes-modal',
  templateUrl: './document-notes-modal.component.html',
  styleUrls: ['./document-notes-modal.component.css'],
  /* TODO 'Global Error Handling Error: NullInjectorError: No provider for DocumentNotesService!'
			Se non forzi il provider del servizio proveniente dalla libreria ritorna l'errore riportato sopra. */
  providers: [DocumentNotesService],
})
export class DocumentNotesModalComponent {
  @Input() data: {
    progSpool: number;
    progBusta: number;
    docSeriesId: number;
    note?: Note;
    userId: number;
  };

  @Input() modal: BsModalRef;

  constructor(private documentNotesService: DocumentNotesService) {}

  onNoteSaved() {
    this.modal.hide();
  }

  onViewInit(initialized: boolean) {
    if (initialized) {
      // gestione titolo
      const modalHeaderTitleEl: HTMLElement = document.querySelector('.modal-header');
      // gestione malsana del titolo della modale... andrebbe utilizzato un service dedicato alle modali
      this.documentNotesService
        .getMode()
        .pipe(take(1))
        .subscribe((mode) => {
          if (mode === 'new') modalHeaderTitleEl.innerText = 'Aggiungi una nota al documento';
          else if (mode === 'edit') modalHeaderTitleEl.innerText = 'Modifica la nota del documento';
          else if (mode === 'readonly') modalHeaderTitleEl.innerText = 'Visualizza la nota del documento';
        });

      // gestione bottone
      const modalFooterEl = document.querySelector('.modal-footer');
      const saveBtnEl = document.querySelector('.document-notes-lib.saveBtn');
      if (saveBtnEl) modalFooterEl.insertAdjacentElement('afterbegin', saveBtnEl);
    }
  }
}
