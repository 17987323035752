import { NgModule } from '@angular/core';
import { DocumentNotesModule } from '@ctel/document-notes';
import { DocumentsSearchModule } from './documents-search/documents-search.module';
import { DocumentNotesModalComponent } from './modals/document-notes-modal/document-notes-modal.component';

@NgModule({
  declarations: [DocumentNotesModalComponent],
  imports: [DocumentsSearchModule, DocumentNotesModule.forRoot()],
  exports: [DocumentsSearchModule],
})
export class GawBinCommonsModule {}
