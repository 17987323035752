import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorDirective } from 'app/core/common/placeholder/error-placeholder/directives/error.directive';
import { ErrorPlaceholderComponent } from 'app/core/common/placeholder/error-placeholder/templates/error-placeholder/error-placeholder.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
/* REMEMBER ME LIBRARY??? */
import { NewErrorDirective } from './directives/new-error.directive';

@NgModule({
  imports: [CommonModule, TooltipModule],
  declarations: [ErrorDirective, ErrorPlaceholderComponent, NewErrorDirective],
  exports: [ErrorDirective, ErrorPlaceholderComponent, NewErrorDirective],
})
export class ErrorPlaceholderModule {}
