import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { AppConfig } from 'app/entities/config/app-config';
import { EditInvoice } from 'app/entities/invoice-pa/edit-invoice/edit-invoice';
import { Observable } from 'rxjs';

@Injectable()
export class EditInvoiceHttpService {
  // endpoints
  private validation: string;
  private validationHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.validationHost = appConfig.docDetails.http.host;
    this.validation = `${this.validationHost}/v2/Document/ModifyDocumentView`;
  }

  public getDati(_docHash: string, _progSpool: number, _progBusta: number): Observable<EditInvoice> {
    const url_ = this.validation;

    const httpOptions = {
      params: {
        docHash: _docHash,
        progSpool: _progSpool.toString(),
        progBusta: _progBusta.toString(),
      },
    };

    return this.http.get<EditInvoice>(url_, httpOptions);
  }
}
