import { Injectable } from '@angular/core';
import { NgBlockUI } from '@ctel/block-ui';

@Injectable()
export class PlaceholderService {
  public startBlockUI(blockUI: NgBlockUI): void {
    blockUI.start('Loading...');
  }

  public stopBlockUI(blockUI: NgBlockUI): void {
    blockUI.stop();
  }
}
