export class RowAction {
  constructor(
    public name: string,
    public icon: string,
    public color = '',
    public canExecute: (params) => boolean = () => true,
    public action: (params: unknown) => unknown,
    public validation: (params) => boolean = () => true,
  ) {}
}
