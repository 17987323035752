import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che crea un array di n valori
 */
@Pipe({
  name: 'forLoop',
})
export class ForLoopPipe implements PipeTransform {
  transform(value: number): number[] {
    const array = [];
    for (let i = 1; i <= value; i++) array.push(i);

    return array;
  }
}
