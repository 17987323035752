import { Injectable } from '@angular/core';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';

@Injectable({
  providedIn: 'root',
})
export class MassiveActionsCatalog {
  public readonly exportExcelMassive = new ActionBuilder(
    MassiveActionCode.exportExcelMassive,
    ActionCode.DOWNLOAD_EXCEL,
  )
    .setActionURL('/api/v1/Action/ExportExcel')
    .setCallback((action: ActionObject, extraParams?: any) => this.allDocumentsCallback.exportXLSX(action, extraParams))
    .build();

  public readonly cancelDocumentMassive = new ActionBuilder(
    MassiveActionCode.cancelDocumentMassive,
    ActionCode.CANCEL_DOCUMENT,
  )
    .setActionURL('/api/v1/Anomalie/AnnullaDocumenti')
    .build();

  public readonly processDocumentMassive = new ActionBuilder(
    MassiveActionCode.processDocumentMassive,
    ActionCode.PROCESS_DOCUMENT,
  )
    .setActionURL('/api/v1/Anomalie/ElaboraDocumenti')
    .build();
  constructor(private allDocumentsCallback: AllDocumentsCallback) {}
}

export enum MassiveActionCode {
  exportExcelMassive = 'exportExcelMassive',
  cancelDocumentMassive = 'cancelDocumentMassive',
  processDocumentMassive = 'processDocumentMassive',
}
