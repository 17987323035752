<div class="login-page">
  <div class="card login-card">
    <div class="card-body">
      <table>
        <tr class="main-row">
          <td class="logo-div">
            <img src="../../../../../assets/images/gaw-logo.svg" alt="" class="gaw-logo" />
          </td>

          <td class="right-div">
            <div class="text-div">
              Autorizzazione scaduta. <br />
              Riesegui il login.
            </div>
            <div class="login-button">
              <button class="btn btn-small" type="button" value="Login" (click)="login()">LOGIN</button>
            </div>
          </td>
        </tr>
        <tr class="footer-row">
          <td [attr.colspan]="2" class="footer-div">Powered by CREDEMTEL</td>
        </tr>
      </table>
    </div>
  </div>
</div>
