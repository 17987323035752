import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
// REMEMBER ME
// import { AppCommonModule } from 'app/core/common/app-common.module';
import { SidebarModule } from '@ctel/sidebar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppSpinnerModule } from '../../core/common/spinner/app-spinner.module';
import { RoutingUtilityModule } from '../../core/common/utilities/routing/routing-utility.module';
import { ComponentsModule } from '../components/components.module';
import { HeaderModule } from '../components/header/header.module';
import { BasicLayoutComponent } from './basiclayout/basicLayout.component';
import { NavItemComponent } from './basiclayout/sidebar/nav-item/nav-item.component';
import { SidebarComponent } from './basiclayout/sidebar/sidebar.component';
import { BlankLayoutComponent } from './blanklayout/blankLayout.component';
import { HeaderLayoutComponent } from './headerlayout/headerlayout.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    SidebarModule,
    MatExpansionModule,
    TabsModule,
    TooltipModule.forRoot(),
    HeaderModule,
    RoutingUtilityModule,
    AppSpinnerModule,
  ],
  declarations: [BasicLayoutComponent, BlankLayoutComponent, HeaderLayoutComponent, SidebarComponent, NavItemComponent],
  exports: [BasicLayoutComponent, BlankLayoutComponent, HeaderLayoutComponent, SidebarComponent],
})
export class LayoutsModule {}
