import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { AccessPermissionService, AuthGuardService, AutoLoginGuard, RolesAccessService } from '../public-api';
import { RolesAccessDirective } from './directives/roles-access.directive';
import { AuthHttpInterceptor } from './interceptors/auth-http.interceptor';

@NgModule({
  declarations: [RolesAccessDirective],
  exports: [RolesAccessDirective],
  imports: [CommonModule],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppAuthModule {
  static forRoot(authConfigService: Provider): ModuleWithProviders<AppAuthModule> {
    return {
      ngModule: AppAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          multi: true,
        },
        authConfigService,
        AuthGuardService,
        AccessPermissionService,
        RolesAccessService,
        AutoLoginGuard,
      ],
    };
  }
}
