export enum FileSaverExtension {
  PDF = '.pdf',
  TXT = '.txt',
  XLS = '.xls',
  XLSX = '.xlsx',
  ZIP = '.zip',
  XML = '.xml',
  EML = '.eml',
  EDI = '.edi',
  NONE = '',
  CSV = '.csv',
}
