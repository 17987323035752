import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { Copier } from 'app/core/common/utilities/copier';
import { AppConfig } from 'app/entities/config/app-config';
import { EditInvoice } from 'app/entities/invoice-pa/edit-invoice/edit-invoice';
import { UploadInvoice } from 'app/entities/invoice-pa/upload-edited/upload-invoice';
import { Observable } from 'rxjs';

@Injectable()
export class UploadEditedHttpService {
  // endpoints
  private validation: string;
  private validationHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.validationHost = appConfig.docDetails.http.host;
    this.validation = `${this.validationHost}/v1/Document/ModifyDocument`;
  }

  public uploadInvoice(
    _docHash: string,
    _progSpool: number,
    _progBusta: number,
    document: EditInvoice,
  ): Observable<UploadInvoice[]> {
    const editedDocument = Copier.deepCopy(document);
    const url_ = this.validation;

    const httpOptions = {
      params: {
        docHash: _docHash,
        progSpool: _progSpool.toString(),
        progBusta: _progBusta.toString(),
      },
    };

    return this.http.post<UploadInvoice[]>(url_, editedDocument, httpOptions);
  }
}
