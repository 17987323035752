import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

/**
 * Guardia per controllare se l'utente ha i ruoli per poter accedere.
 *
 * Nel caso l'utente non abbia i permessi verrà reindirizzato alla pagina di non autorizzato.
 */
@Injectable()
export class RolesAccessService {
  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccessRole(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.hasAccessRole(next);
  }

  private hasAccessRole(next: ActivatedRouteSnapshot): Observable<boolean> {
    const routeAccessRoles: string[] = next.data.roles ? next.data.roles : [];

    return this.userService.hasAtLeastOneRole(routeAccessRoles).pipe(
      map((hasRoles) => {
        if (hasRoles) return true;
        else {
          this.authService.navigateToUnauthorized();
          return false;
        }
      }),
    );
  }
}

export const canActivateRole: CanActivateFn = (route: ActivatedRouteSnapshot) =>
  inject(RolesAccessService).canActivate(route);

export const canActivateChildRole: CanActivateChildFn = (route: ActivatedRouteSnapshot) =>
  inject(RolesAccessService).canActivateChild(route);
