import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from 'app/modules/homepage/core/documents-search/actions/callbacks/single-document-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly viewDocument = new ActionBuilder(SingleActionsCode.viewDocument, ActionCode.SHOW_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=false',
    )
    .build();

  public readonly pdfDownload = new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  viewDocument = 'viewDocument',
  pdfDownload = 'pdfDownload',
}
