import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthConfigService } from '../auth-config';
import { ChangePasswordPayload } from '../interfaces/change-password-payload';
import { User } from '../interfaces/user';

/**
 * Servizio per la comunicazione HTTP
 */
@Injectable({ providedIn: 'root' })
export class UserHttpService {
  private host$: Observable<string>;

  constructor(
    private readonly authConfigService$: AuthConfigService,
    private readonly http: HttpClient,
  ) {
    this.host$ = authConfigService$.getAuthConfig().pipe(map((config) => config.userProfileHost));
  }

  /**
   * Ottiene le informazioni dell'utente autenticato
   * @return Observable<{@link User}>
   */
  public getCurrentUser(): Observable<User> {
    return this.host$.pipe(
      map((host) => `${host}/v1/users/me`),
      mergeMap((url) => this.http.get<User>(url)),
    );
  }

  /**
   * Ottiene l'informazione se l'utente può accedere o meno al portale
   * @return Observable<{@link boolean}>
   */
  public userHasAccessPermission(): Observable<boolean> {
    return this.host$.pipe(
      map((host) => `${host}/v1/users/me/clients/IsGranted`),
      mergeMap((url) => this.http.get<boolean>(url)),
    );
  }

  /**
   * Permette di cambiare la password per l'utente attuale
   * @param payload Payload di richiesta contente la password attuale e quella con cui si vuole cambiare
   * @return Observable<boolean>
   */
  public changePassword(payload: ChangePasswordPayload): Observable<boolean> {
    return this.host$.pipe(
      map((host) => `${host}/v1/users/me/password`),
      mergeMap((url) => this.http.patch<boolean>(url, payload)),
    );
  }
}
