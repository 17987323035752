import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EnabledService,
  EnabledServiceEndpoint,
  EnabledServiceEndpointResponse,
} from 'app/core/business/user/enabled-services/enabled-service';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { SearchContext } from 'app/entities/search-context/search-context';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchContextHttpService {
  private gawUserPortalServiceHost: string;
  private enabledSearchArea: string;
  private searchContext: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private logger: NGXLogger,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.gawUserPortalServiceHost = appConfig.gawUserPortalService.http.host;

    this.searchContext = `${this.gawUserPortalServiceHost}/api/v1/UserPortalServices/gawuserssearchcontext/{idLicenza}/{codsia}/{uiCode}`;
    this.enabledSearchArea = `${this.gawUserPortalServiceHost}/api/v1/UserPortalServices/userenabledsearcharea/{idLicenza}/{codsia}`;
  }

  getEnabledSearchArea(idlicenza: string, codsia: string): Observable<EnabledServiceEndpoint[]> {
    const url = this.enabledSearchArea.replace('{idLicenza}', idlicenza).replace('{codsia}', codsia);
    return this.http.get<EnabledServiceEndpointResponse[]>(url).pipe(
      map((v) => EnabledService.mapEnabledSearchEndPoint(v)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getSearchContext(idlicenza, codsia, uiCode): Observable<SearchContext[]> {
    const url = this.searchContext
      .replace('{idLicenza}', idlicenza)
      .replace('{codsia}', codsia)
      .replace('{uiCode}', uiCode);
    return this.http.get<SearchContext[]>(url).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError((error: unknown) => {
        this.logger.error('errore durante la ricerca contesto ', error);
        return of([]);
      }),
    );
  }
}
