import { createFeatureSelector, createSelector } from '@ngrx/store';
import { accountModuleFeatureKey, AccountModuleState, AccountState } from './account.reducer';

export const selectAccountModuleState = createFeatureSelector<AccountModuleState>(accountModuleFeatureKey);

export const selectAccountState = createSelector(
  selectAccountModuleState,
  (state: AccountModuleState) => state.accountState,
);

export const getCurrentAccount = createSelector(selectAccountState, (state: AccountState) => state.currentAccount);
