<!-- SE NON E' MODE CONFERMA -->
<form *ngIf="data.basicPayload.mode !== 5" novalidate (ngSubmit)="onFormSubmit(protocolForm)" #protocolForm="ngForm">
  <div class="modal-body">
    <div class="container-div">
      <!-- SE E' UNO DEI 3 MODE CHE PREVEDE I DATI PROTOCOLLO -->
      <ng-container
        *ngIf="data.basicPayload.mode === 0 || data.basicPayload.mode === 1 || data.basicPayload.mode === 2"
      >
        <div class="main-div">
          <div class="inner-div">
            <fieldset>
              <div class="form-group mb-2">
                <label class="form-label" for="protocolNumber">
                  <span class="mb-1">Numero protocollo</span>
                  <span *ngIf="isErrorNumber" class="error">*</span>
                </label>
                <input
                  type="text"
                  id="protocolNumber"
                  name="protocolNumber"
                  class="form-control"
                  placeholder="Numero protocollo"
                  ngModel
                />
              </div>
              <div class="form-group mb-2">
                <label class="form-label" for="protocolDate">
                  <span class="mb-1">Data protocollo</span>
                  <span *ngIf="isErrorDate" class="error">*</span>
                </label>
                <input
                  type="text"
                  id="protocolDate"
                  name="protocolDate"
                  class="form-control"
                  placeholder="DD/MM/YYYY"
                  ngModel
                />
              </div>
            </fieldset>
          </div>
        </div>

        <div *ngIf="isErrorDate" class="error-msg">
          <b>*Data protocollo non valida</b>
        </div>
        <div *ngIf="isErrorNumber" class="error-msg">
          <b>*Numero protocollo obbligatorio</b>
        </div>
      </ng-container>

      <!-- tabella dati reverse charge -->
      <ng-container *ngIf="data.basicPayload.mode === 2 || data.basicPayload.mode === 4">
        <div class="table-title"><strong>Metadati di Reverse Charge</strong></div>
        <div class="table-container">
          <table
            class="table table-bordered reverse-charge-table"
            [ngClass]="{
              'table-1-col': reverseChargeColumns.length === 1,
              'table-2-col': reverseChargeColumns.length === 2,
              'table-3-col': reverseChargeColumns.length === 3,
            }"
          >
            <thead>
              <tr>
                <ng-container *ngFor="let column of reverseChargeColumns">
                  <th class="data-th">
                    {{ column.label }}
                  </th>
                </ng-container>
                <th class="actions-th">Azioni</th>
              </tr>
            </thead>
            <tbody>
              <!-- righe con dati già inseriti e riga nuova  -->
              <ng-container *ngFor="let row of insertedTableData; let i = index">
                <tr>
                  <ng-container *ngFor="let cell of row">
                    <td>
                      <label class="form-label" style="border: 0px">
                        <input
                          *ngIf="insertedTableData.length !== i + 1; else emptyRow"
                          type="text"
                          class="form-control table-input"
                          value="{{ cell.value }}"
                          id="cell{{ cell.name }}{{ i }}"
                          name="cell{{ cell.name }}{{ i }}"
                          disabled
                        />
                        <ng-template #emptyRow>
                          <input
                            #emptyInput
                            type="text"
                            class="form-control table-input empty-input"
                            id="cell{{ cell.name }}{{ i }}"
                            name="cell{{ cell.name }}{{ i }}"
                            value="{{ cell.value }}"
                            (input)="inputChange()"
                            placeholder="{{ cell.label }}"
                          />
                        </ng-template>
                      </label>
                    </td>
                  </ng-container>
                  <!-- mostro solo il tasto ok-->
                  <td *ngIf="insertedTableData.length === i + 1; else deleteIcon">
                    <i
                      [class]="faIcons.FAS_CHECK"
                      [ngClass]="{ 'fa-check-green': isGreen === true, 'fa-check-grey': isGreen === false }"
                      (click)="insertData(row, i)"
                    >
                    </i>
                  </td>
                  <ng-template #deleteIcon>
                    <td>
                      <i [ngClass]="faIcons.FAR_TRASH" (click)="deleteRow(i)"></i>
                    </td>
                  </ng-template>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <div *ngIf="isErrorRow" class="error-msg">
          <b>*Confermare l'ultimo set di metadati per procedere</b>
        </div>
      </ng-container>

      <!-- caricamento file -->
      <ng-container *ngIf="data.basicPayload.mode === 3 || data.basicPayload.mode === 1">
        <div><strong>File Reverse Charge</strong></div>
        <div>
          <label class="form-label" for="file-upload" class="form-label">
            <i [ngClass]="faIcons.FAS_UPLOAD"></i> Carica PDF
          </label>
          <input
            #fileUpload
            id="file-upload"
            type="file"
            accept="application/pdf"
            class="form-control"
            (change)="onFileChange($event)"
          />
          {{ inputFilename }}
          <i *ngIf="isResettable" [ngClass]="faIcons.FAS_TIMES" (click)="resetInputFile()"></i>
        </div>
        <div *ngIf="isFileFormatError" class="error-msg">
          <b>Formato file errato!</b>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" id="insert-protocol-confirm-button">Conferma</button>
    <button type="button" class="btn btn-danger" (click)="modal.hide()">Annulla</button>
  </div>
</form>
<ng-container *ngIf="data.basicPayload.mode === 5">
  <div class="modal-body">
    <strong>Registrare il documento sul gestionale?</strong>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" (click)="onFormSubmit()">Conferma</button>
    <button type="button" class="btn btn-danger" (click)="modal.hide()">Annulla</button>
  </div>
</ng-container>
