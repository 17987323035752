import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { ConfigModule } from './config/config.module';
import { ErrorModule } from './error/error.module';
import { EventModule } from './event/event.module';
import { HighlightModule } from './highlight/highlight.module';
import { NotificationModule } from './notification/notification.module';
import { PlaceholderModule } from './placeholder/placeholder.module';
import { AppSpinnerModule } from './spinner/app-spinner.module';
import { UiConfigurationModule } from './ui-configuration/ui-configuration.module';

@NgModule({
  imports: [
    CommonModule,
    // Servizi
    ConfigModule.forRoot(),
    ErrorModule.forRoot(),
    AppSpinnerModule.forRoot(),
    HighlightModule.forRoot(),
    EventModule.forRoot(),
    NotificationModule.forRoot(),
    PlaceholderModule.forRoot(),
    UiConfigurationModule.forRoot(),
    // Pipes
    PipesModule,
  ],
  declarations: [],
  exports: [
    // Servizi
    ConfigModule,
    ErrorModule,
    AppSpinnerModule,
    HighlightModule,
    EventModule,
    NotificationModule,
    PlaceholderModule,
    // Pipes
    PipesModule,
  ],
})
export class DryCommonModule {}
