import { Component, Input } from '@angular/core';
import { FRLottiDocumentsService2 } from '@ctel/gawcons-commons';
import { AppError } from 'app/core/common/error';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmMassiveRequest } from '../../entities/confirm-massive-request';
import { AllDocumentsCallback } from '../../firma-remota-documenti-documents-search/actions/callbacks/all-documents-callback';

@Component({
  selector: 'gaw-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  @Input() modal: BsModalRef;
  @Input() data: {
    payload: ConfirmMassiveRequest;
  };
  reason: string;
  loading = false;

  constructor(
    private callback: AllDocumentsCallback,
    private docService: FRLottiDocumentsService2,
    private notification: NotificationService,
  ) {}

  onReason() {
    this.loading = true;
    this.callback
      .executeConfirmMassive(this.data.payload)
      .pipe()
      .subscribe({
        next: () => {
          this.modal.hide();
          this.notification.showSweetAlert(NotificationType.SUCCESS, 'Conferma', 'Conferma avvenuta con successo');
          this.docService.refreshDocuments();
        },
        error: (error: unknown) => {
          this.notification.showSweetAlert(
            NotificationType.ERROR,
            'Conferma',
            (error as AppError).additionalInfo.find((x) => x.t === 'response')?.u,
          );
          this.loading = false;
        },
      });
  }
}
