import { Injectable } from '@angular/core';
import { ActionsConfigurationService } from '@ctel/hubfe-commons';
import { DocumentActions } from '@ctel/search-filter-store';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ActionsService2 } from '../documents-search/actions/actions.service';
import { OfficeDeskHttpService } from './office-desk-http.service';

@Injectable({
  providedIn: 'root',
})
export class OfficeDeskService {
  private config$ = new ReplaySubject<any>(1);
  private homepageConfig$ = new ReplaySubject<any>(1);
  private payableActions$ = new ReplaySubject<ActionObject[]>(1);

  constructor(
    private officeDeskHttpService: OfficeDeskHttpService,
    private actionsService: ActionsService2,
    private actionsConfigurationService: ActionsConfigurationService,
    private logger: NGXLogger,
  ) {}

  public getShortcutsForHomepage(idLicenza: string, sia: string): Observable<any> {
    return this.officeDeskHttpService.getShortcuts(idLicenza, sia, true).pipe(
      catchError((error: unknown) => {
        this.logger.error('Errore durante il caricamento degli shortcut della home page nella scrivania ', error);
        this.setHomepageConfig([]);
        return EMPTY;
      }),
      tap((data) => {
        this.setHomepageConfig(data);
      }),
    );
  }

  public getShortcuts(idLicenza: string, sia: string): Observable<any> {
    return this.officeDeskHttpService.getShortcuts(idLicenza, sia, false).pipe(
      catchError((error: unknown) => {
        this.logger.error('Errore durante il caricamento degli shortcut della scrivania ', error);
        this.setConfig([]);
        return EMPTY;
      }),
      tap((data) => {
        this.setConfig(data);
      }),
    );
  }

  public setShortcut(idLicenza: string, sia: string, body: any) {
    return this.officeDeskHttpService.setShortcut(idLicenza, sia, body);
  }

  public whenConfig(): Observable<any> {
    return this.config$.asObservable();
  }

  public whenHomepageConfig(): Observable<any> {
    return this.homepageConfig$.asObservable();
  }

  public getActions(company, classification) {
    return this.getActionsByClassification(company, classification);
  }

  public setPayableActions(company) {
    return this.getActionsByClassification(company, 'payable').pipe(
      tap((actions) => {
        this.payableActions$.next(actions);
      }),
    );
  }

  public whenPayableActions(): Observable<ActionObject[]> {
    return this.payableActions$.asObservable();
  }

  private setConfig(value: any) {
    this.config$.next(value);
  }

  private setHomepageConfig(value: any) {
    this.homepageConfig$.next(value);
  }

  private getActionsByClassification(company, classification) {
    return this.actionsConfigurationService
      .getMassiveActionsByCard(company.licenseId, company.siaCode, classification)
      .pipe(
        map((config) =>
          DocumentActions.documentActionsFetched(this.actionsService.mergeActionConfig(config, 'massive')),
        ),
        map((data) => <ActionObject[]>data.payload.actions),
      );
  }
}
