import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'dry-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() text: string;
  @Input() closeOnClickOutside: boolean;
  @Input() childComponent: Type<unknown>;
  @Input() childData: object;
  @Input() customFooter: boolean;
  @ViewChild('childComponent', {
    read: ViewContainerRef,
    static: false,
  })
  viewContainerRef: ViewContainerRef;

  private onClose: Subject<string> = new Subject();
  private componentRef: ComponentRef<unknown>;
  private destroy$ = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private eRef: ElementRef,
  ) {}

  // Detect esc keypress
  @HostListener('window:keydown.esc', ['$event'])
  onEsc(event: KeyboardEvent): void {
    if (event.key === 'Esc' || event.key === 'Escape') {
      event.preventDefault();
      this.close();
    }
  }

  // Click outside modal
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      (event.target as Element).classList[0] !== 'not-outside-click'
    )
      if (this.closeOnClickOutside)
        // Non richiamo la close per problemi con l'apertura di un modal in contemporanea con quello presente tramite click
        // (es. ActionConfirmationModalComponent) --- Non succede se però viene settato un timer all'apertura
        this.sendClose();
  }

  ngAfterViewInit(): void {
    if (this.childComponent) this.addDynamicComponent(this.viewContainerRef, this.childComponent, this.childData);
  }

  public addDynamicComponent(viewContainerRef: ViewContainerRef, dynamicComponent: Type<unknown>, data: object) {
    const component = viewContainerRef.createComponent(dynamicComponent);
    if (data) component.instance['data'] = data;

    // TODO: Fix in all projects
    component.instance['modal'] = this.bsModalRef;
    component.instance['onHide'] = () => this.whenClose();
    component.instance['close'] = () => this.close();

    this.componentRef = component;
    viewContainerRef.insert(component.hostView);
  }

  close() {
    this.sendClose();
    this.bsModalRef.hide();
    if (this.componentRef) this.componentRef.destroy();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private whenClose(): Observable<string> {
    return this.onClose.asObservable();
  }

  private sendClose() {
    this.onClose.next('close');
  }
}
