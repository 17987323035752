import { Injectable } from '@angular/core';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly massiveActions: ActionObject[] = [
    this.massiveActionsCatalog.exportExcelMassive,
    this.massiveActionsCatalog.downloadPdfZipMassive,
    this.massiveActionsCatalog.createPddMassive,
  ];

  public readonly multiActions: ActionObject[] = [
    this.multiActionsCatalog.exportExcelMultiple,
    this.multiActionsCatalog.downloadPdfZipMultiple,
    this.multiActionsCatalog.createPddMultiple,
  ];

  public readonly singleActions: ActionObject[] = [
    this.singleActionsCatalog.openDetail,
    this.singleActionsCatalog.downloadPreservedDocument,
    this.singleActionsCatalog.exportExcel,
    this.singleActionsCatalog.openPreviousVersions,
    this.singleActionsCatalog.createPdd,
    this.singleActionsCatalog.editRegistry,
  ];

  constructor(
    private massiveActionsCatalog: MassiveActionsCatalog,
    private multiActionsCatalog: MultiActionsCatalog,
    private singleActionsCatalog: SingleActionsCatalog,
  ) {}
}
