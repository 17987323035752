/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { TextValidators } from 'app/shared/components/dry/validator/text-validators';

@Directive({
  selector: '[onlyNumber][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: OnlyNumberValidatorDirective, multi: true }],
})
export class OnlyNumberValidatorDirective implements Validator {
  private validator: ValidatorFn;

  constructor() {
    this.validator = TextValidators.onlyNumber();
  }

  registerOnValidatorChange(fn: () => void): void {}

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }
}
