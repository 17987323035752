import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Copier } from 'app/core/common/utilities/copier';
import { CustomValidationError, ErrorMessage } from 'app/shared/components/dry/validator/custom-validation-error';

export interface IValidationMessage {
  type: string;
  message: string;
}

export class ValidatorMessage {
  private static commonMessages: Array<IValidationMessage> = [
    { type: 'required', message: 'Campo obbligatorio' },
    { type: 'minlength', message: 'Lunghezza minima ${i} caratteri' },
    { type: 'maxlength', message: 'Lunghezza massima ${i} caratteri' },
    { type: 'max', message: 'Supera il valore massimo ${i}' },
    { type: 'min', message: 'Minore del valore minimo ${i}' },
    { type: 'pattern', message: 'Sintassi errata' },
  ];

  /**
   * Funzione che estrae i messaggi dei validatori dato un AsbtractControl
   * @param control oggetto di tipo AbstractControl
   * @param commonMessage Array di tipo IValidationMessage, contiene una lista di validatori che hanno un
   * messaggio preimpostato, in aggiunta a quelli di default
   * @return Array<string> Lista dei messaggi dei validatori
   */
  public static getErrorMessage(control: AbstractControl, commonMessage: Array<IValidationMessage> = []): string[] {
    const _commonValidators: Array<IValidationMessage> = this.unifiedErrorMessage(commonMessage);
    const messages: string[] = [];

    const errors: ValidationErrors | CustomValidationError = control.errors;

    if (!errors) return messages;

    Object.keys(errors).forEach((key) => {
      let msg = '';
      const commonError = _commonValidators.find((_msg) => _msg.type === key);

      if (commonError !== undefined) {
        msg = commonError.message;

        // In caso di alcuni validatori specifici, devo modificare il messaggio
        if (key === 'minlength' || key === 'maxlength')
          msg = commonError.message.replace('${i}', control.errors[key].requiredLength);
        else if (key === 'max') msg = commonError.message.replace('${i}', control.errors[key].max);
        else if (key === 'min') msg = commonError.message.replace('${i}', control.errors[key].min);
      } else {
        // Se il validatore non rientra tra quelli da utilizzare per i messaggi generici, controllo che siaCode
        // un CustomValidationError
        const error: ErrorMessage = errors[key];
        if (error.message) msg = error.message;
      }
      messages.push(msg);
    });
    return messages;
  }

  private static unifiedErrorMessage(commonMessage: Array<IValidationMessage> = []): Array<IValidationMessage> {
    const _commonMessages: Array<IValidationMessage> = Copier.deepCopy(this.commonMessages);

    commonMessage.forEach((commonMsg) => {
      // Per ogni messaggio, controllo se è presente nell'array dei validatori generici e sostituisco il
      // mesaggio di errore, altrimento lo aggiungo
      const validator = _commonMessages.find((cm) => cm.type === commonMsg.type);

      if (validator === undefined)
        // Validatore non presente aggiungo
        _commonMessages.push(commonMsg);
      else validator.message = commonMsg.message;
    });

    return _commonMessages;
  }
}
