import { ErrorHandler, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AppError } from '../app-error';
import { AppErrorBuilder } from '../app-error-builder';
import { ErrorTypes } from '../error-types';
import { ErrorService } from '../error.service';
import { JavaScriptErrors } from '../java-script-errors';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private errorService: ErrorService,
    private logger: NGXLogger,
  ) {}

  handleError(error: Error): void {
    if (!error) return;

    this.logger.error(error);
    let appError: AppError;
    if (error instanceof AppError) appError = error;
    else {
      const appErrorBuilder = new AppErrorBuilder(ErrorTypes.JAVASCRIPT_ERROR).cause(error);

      const jsError = error.name ? JavaScriptErrors[error.name.toUpperCase()] : JavaScriptErrors.ERROR;
      if (jsError) appErrorBuilder.description(jsError.name + ': ' + jsError.description);

      appError = appErrorBuilder.build();
    }

    this.errorService.setError(appError);
  }
}
