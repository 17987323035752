export class Responsabili {
  ruolo: string;
  nome: string;
  cognome: string;
  codiceFiscale: string;
  dataNomina: string;
  dataScadenza?: string;
  dataCessazione?: string;
  idUtenteRdc: number;
}
