import { PlatformLocation } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { RegimeMinimiService } from '@ctel/hubfe-commons';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

/**
 * Componente modal per insert o edit di fornitore regime minimi
 */
@Component({
  selector: 'hub-insert-mail-address-modal',
  templateUrl: './regime-minimi-modal.component.html',
  styleUrls: ['./regime-minimi-modal.component.scss'],
})
export class RegimeMinimiModalComponent {
  @Input() title: string;
  @Input() data: any;
  @Input() modal: BsModalRef;

  isErrorPiva = false;
  isErrorCf = false;
  isRequiredRagioneSociale = false;
  isRequiredPivaOrCf = false;

  constructor(
    private regimeMinimiService: RegimeMinimiService,
    private companiesService: CompaniesService,
    private notificationService: NotificationService,
    private location: PlatformLocation,
    private router: Router,
  ) {
    location.onPopState(() => this.modal.hide());
  }

  // 2 lettere per la nazione + un alfanumerico da 1 a 28
  private static validatePiva(piva: string) {
    const re = new RegExp(/^[A-Za-z]{2}[A-Za-z0-9]{1,28}$/);
    return re.test(piva);
  }

  // alfanumerico da 11 a 16
  private static validateCF(cf: string) {
    const re = new RegExp(/^[A-Za-z0-9]{11,16}$/);
    return re.test(cf);
  }
  onFormSubmit(form: NgForm) {
    let piva = form.form.value.piva;
    let cf = form.form.value.cf;
    const ragioneSociale = form.form.value.ragioneSociale;

    // reset eventuali errori precedenti
    this.isRequiredPivaOrCf = false;
    this.isRequiredRagioneSociale = false;
    this.isErrorCf = false;
    this.isErrorPiva = false;

    // ragione sociale obbligatoria
    if (ragioneSociale === '' || ragioneSociale === null) {
      this.isRequiredRagioneSociale = true;
      return;
    }

    // o piva o cf obbligatori
    if ((piva === '' && cf === '') || (piva === null && cf === null)) {
      this.isRequiredPivaOrCf = true;
      return;
    }

    // se la piva c'è ma non è valida
    if (piva !== '' && !RegimeMinimiModalComponent.validatePiva(piva)) {
      this.isErrorPiva = true;
      return;
    }

    // se il cf c'è ma non è valido
    if (cf !== '' && !RegimeMinimiModalComponent.validateCF(cf)) {
      this.isErrorCf = true;
      return;
    }

    if (piva === null) piva = '';

    if (cf === null) cf = '';

    const body = {
      partitaIva: piva.toUpperCase(),
      codiceFiscale: cf.toUpperCase(),
      ragioneSociale: ragioneSociale.toUpperCase(),
      codiceFornitore: 0,
    };

    if (this.data) {
      // EDIT FORNITORE
      // per l'update serve il codice fornitore popolato
      body['codiceFornitore'] = this.data.codiceFornitore;
      this.regimeMinimiService
        .whenUpdateOrInsertRegimeMinimi(body)
        .pipe(
          take(1),
          catchError((err: unknown) => {
            if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) return of(null);

            if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
              //this.router.navigate(['/unauthorized']).then();
              return of(null);
          }),
        )
        .subscribe((data) => {
          if (data !== null)
            if (data.esitoOK === true) {
              this.modal.hide();
              this.notificationService.showSweetAlert(
                NotificationType.SUCCESS,
                'Fornitore modificato correttamente.',
                '',
              );
              // faccio un refresh per restare sulla stessa pagina
              this.regimeMinimiService.refresh();
            } else
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                data.message,
                'Il fornitore non è stato' + ' modificato',
              );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore.',
              'Il fornitore non è stato' + ' modificato',
            );
        });
    }
    // INSERT FORNITORE
    else
      this.regimeMinimiService
        .whenUpdateOrInsertRegimeMinimi(body)
        .pipe(
          take(1),
          catchError((err: unknown) => {
            if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) return of(null);

            if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
              //this.router.navigate(['/unauthorized']).then();
              return of(null);
          }),
        )
        .subscribe((data) => {
          if (data !== null)
            if (data.esitoOK === true) {
              this.modal.hide();
              this.notificationService.showSweetAlert(
                NotificationType.SUCCESS,
                'Fornitore inserito correttamente.',
                '',
              );
              //	triggero il refresh resettando la pagina iniziale dopo una insert
              this.regimeMinimiService.sendCurrentPage(1);
            } else
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                data.message,
                'Il fornitore non è stato' + ' aggiunto',
              );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore.',
              'Il fornitore non è stato aggiunto',
            );
        });
  }
}
