<div class="company-container">
  <!-- Azienda singola -->
  <ng-container *ngIf="singleCompany; else multipleCompanies">
    <span class="first-company" aria-haspopup="true" aria-expanded="false">
      <span
        *ngIf="currentCompany?.companyDescription as companyDescription"
        class="current-company-name"
        id="current-company-name"
        [tooltip]="companyDescription.length > cdMaxLength ? companyDescription : null"
        [tooltipAnimation]="true"
        placement="bottom"
      >
        <svg-icon src="/assets/homepage/group.svg" [svgStyle]="{ 'width.px': 30 }"></svg-icon>
        {{ companyDescription }}
      </span>
    </span>
  </ng-container>

  <!-- Aziende multiple -->
  <ng-template #multipleCompanies>
    <div class="btn-group companies-group" dropdown placement="bottom right">
      <!-- Span (al posto del button), rappresentante la prima azienda -->
      <span class="first-company dropdown-toggle" dropdownToggle aria-haspopup="true" aria-expanded="false">
        <i [ngClass]="faIcons.FAS_CHEVRON_DOWN" aria-hidden="true"></i>
        <span
          *ngIf="currentCompany?.companyDescription as companyDescription"
          class="current-company-name"
          id="current-company-name"
          [tooltip]="companyDescription.length > cdMaxLength ? companyDescription : null"
          [tooltipAnimation]="true"
          placement="bottom"
        >
          <svg-icon src="/assets/homepage/group.svg" [svgStyle]="{ 'width.px': 30 }"></svg-icon>
          {{ companyDescription }}
        </span>
      </span>

      <!-- Contenuto select, elenco aziende successive alla prima-->
      <ng-container *ngIf="companies?.length > 0">
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li
            *ngFor="let company of companies | companiesOrderByAlphabetical; let i = index"
            class="company"
            (click)="changeCompany(company)"
            id="company-name-{{ i }}"
          >
            <a class="active dropdown-item" [ngClass]="{ active: companyComparator(company, currentCompany) }">
              <svg-icon
                src="/assets/homepage/group.svg"
                [svgClass]="companyComparator(company, currentCompany) ? 'active' : ''"
                [svgStyle]="{ 'width.px': 30 }"
              ></svg-icon>
              <span class="company-name">{{ company?.companyDescription }}</span>
            </a>
          </li>
        </ul>
      </ng-container>
    </div>
  </ng-template>
</div>
