import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionsHttpService } from '../actions-http.service';
import { NotificationService } from 'app/core/common/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SingleDocumentCallback {
  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private router: Router,
  ) {}
}
