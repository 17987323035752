import { Component, Input } from '@angular/core';
import { IDetailsComponent } from '@ctel/gaw-commons';
import { DocumentAttachmentPdf } from 'app/shared/components/display-document/document-attachment-pdf';
import { DocumentDetailsService } from '../../../documents-search/document-details/document-details.service';

@Component({
  selector: 'gaw-gawpass-document-preview',
  templateUrl: './gawpass-document-preview.component.html',
  styleUrls: ['./gawpass-document-preview.component.scss'],
})
export class GawpassDocumentPreviewComponent {
  @Input() data: IDetailsComponent;

  public documentAttachmentPdf: DocumentAttachmentPdf;

  constructor(private documentDetailsService: DocumentDetailsService) {}
}
