<div [ngClass]="{ content: !loading }" class="p-4">
  <div *hubLoading="loading; component: loadingTemplate; style: { height: '200px' }">
    <div class="main-div">
      <div class="page-title mb-4">{{ title }}</div>
      <div *ngIf="showAction" class="page-subtitle">Scegli l'azione</div>
      <div *ngIf="showClassification" class="page-subtitle">Scegli la classificazione</div>
      <div *ngIf="showGrafiche" class="page-subtitle">Scegli la serie documentale</div>
      <div *ngIf="showUploadPage" class="page-subtitle">Carica un documento o un lotto</div>

      <!-- ICONS -------------------------------------------------------------------------------------------------->
      <div class="progress-bar-container-row col-12">
        <div class="mdl-card mdl-shadow--2dp">
          <div class="mdl-card__supporting-text">
            <div class="mdl-stepper-horizontal-alternative">
              <!--CLASSIFICATION-->
              <div class="mdl-stepper-step active-step">
                <div class="mdl-stepper-circle green">
                  <span><i class="icon-progress" [ngClass]="faIcons.FAS_BARS"></i></span>
                </div>
                <div
                  class="mdl-stepper-bar-right"
                  [ngClass]="{
                    'green-line': showClassification || showGrafiche || showUploadPage || fileUploaded,
                    'grey-line': showAction,
                  }"
                ></div>
              </div>
              <!--GRAFICHE-->
              <div class="mdl-stepper-step active-step">
                <div
                  class="mdl-stepper-circle"
                  [ngClass]="{
                    green: showGrafiche || showUploadPage || fileUploaded || showClassification,
                    grey: showAction,
                  }"
                >
                  <span><i class="icon-progress" [ngClass]="faIcons.FAS_BARS"></i></span>
                </div>
                <div
                  class="mdl-stepper-bar-left"
                  [ngClass]="{
                    'green-line': showGrafiche || showClassification || showUploadPage || fileUploaded,
                    'grey-line': showAction,
                  }"
                ></div>
                <div
                  class="mdl-stepper-bar-right"
                  [ngClass]="{
                    'green-line': showGrafiche || showUploadPage || fileUploaded,
                    'grey-line': showAction || showClassification,
                  }"
                ></div>
              </div>
              <!--SCELTA AZIONE-->
              <div class="mdl-stepper-step active-step">
                <div
                  class="mdl-stepper-circle"
                  [ngClass]="{
                    green: showUploadPage || fileUploaded || showGrafiche,
                    grey: showAction || showClassification,
                  }"
                >
                  <span><i class="icon-progress  {{ faIcons.FAS_PENCIL }}"></i></span>
                </div>
                <div
                  class="mdl-stepper-bar-left"
                  [ngClass]="{
                    'green-line': showGrafiche || showUploadPage || fileUploaded,
                    'grey-line': showAction || showClassification,
                  }"
                ></div>
                <div
                  class="mdl-stepper-bar-right"
                  [ngClass]="{
                    'green-line': showUploadPage || fileUploaded,
                    'grey-line': showAction || showGrafiche || showClassification,
                  }"
                ></div>
              </div>
              <!--UPLOAD-->
              <div class="mdl-stepper-step active-step">
                <div
                  class="mdl-stepper-circle"
                  [ngClass]="{
                    green: fileUploaded || showUploadPage,
                    grey: showGrafiche || showAction || showClassification,
                  }"
                >
                  <span><i class="icon-progress {{ faIcons.FAS_UPLOAD }}"></i></span>
                </div>
                <div
                  class="mdl-stepper-bar-left"
                  [ngClass]="{
                    'green-line': showUploadPage || fileUploaded,
                    'grey-line': showGrafiche || showAction || showClassification,
                  }"
                ></div>
                <div
                  class="mdl-stepper-bar-right"
                  [ngClass]="{
                    'green-line': fileUploaded,
                    'grey-line': !fileUploaded,
                  }"
                ></div>
              </div>
              <!--DONE-->
              <div class="mdl-stepper-step active-step">
                <div
                  class="mdl-stepper-circle"
                  [ngClass]="{
                    green: fileUploaded,
                    grey: !fileUploaded,
                  }"
                >
                  <span><i class="icon-progress  {{ faIcons.FAS_CHECK }}"></i></span>
                </div>
                <div
                  class="mdl-stepper-bar-left"
                  [ngClass]="{
                    'green-line': fileUploaded,
                    'grey-line': !fileUploaded,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>

      <!-- GRAFICHE -->
      <!-- SECONDO STEP - SCELTA AZIONE ----------------------------------------------------------------------------->
      <div *ngIf="showAction">
        <div class="col-12 row">
          <div class="col-4 offset-2">
            <table class="action-tile-table new-tile" (click)="handleQueryParams({ action: 'upload' })">
              <tr class="tile-title-row">
                <td class="action-tile-cell">Inserisci documenti</td>
              </tr>
            </table>
          </div>
          <div class="col-4">
            <table class="action-tile-table edit-tile" (click)="handleQueryParams({ action: 'data-entry' })">
              <tr class="tile-title-row">
                <td class="action-tile-cell">Crea documento</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- CLASSIFICAZIONE ---------------------------------------------------------------------------------------------->
      <div *ngIf="showClassification">
        <ng-container
          *hubNewError="classificationError; options: tileErrorPlaceholderErrorOption; style: { height: '200px' }"
        >
          <div *hubLoading="classificationLoading; style: { height: '200px' }" class="row">
            <ng-container *ngIf="classifications?.length; else noResult">
              <ng-container *ngFor="let classification of classifications; let i = index">
                <div class="col-4 my-2">
                  <a class="over-icon" (click)="classificationClick(classification.code)">
                    <div class="tile-classification" [style.backgroundColor]="classification.color">
                      <table class="tile-title-table">
                        <tr class="tile-title-row">
                          <td class="tile-title-cell">{{ classification.description }}</td>
                        </tr>
                      </table>
                    </div>
                  </a>
                </div>
              </ng-container>
            </ng-container>
            <div class="col-12 my-3" *ngIf="shouldGoBack">
              <button type="button" class="btn btn-primary btnAvanti" style="float: left" (click)="onBackClick()">
                Indietro
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- GRAFICHE -->
      <div *ngIf="showGrafiche">
        <ng-container *hubNewError="graphicError; options: tileErrorPlaceholderErrorOption; style: { height: '200px' }">
          <div *hubLoading="graphicsLoading; style: { height: '200px' }" class="row">
            <ng-container *ngIf="docSeries?.length; else noResult">
              <ng-container *ngFor="let grafica of docSeries; let i = index">
                <div class="col-4 my-2">
                  <a class="over-icon" (click)="graficaClick(grafica.graphicsCode, grafica.docClassCode)">
                    <div class="tile-grafica" [style.backgroundColor]="grafica.color">
                      <table class="tile-title-table">
                        <tr class="tile-title-row">
                          <td class="tile-title-cell">{{ grafica.description }}</td>
                        </tr>
                      </table>
                    </div>
                  </a>
                </div>
              </ng-container>
              <div class="col-12 my-3" *ngIf="shouldGoBack">
                <button type="button" class="btn btn-primary btnAvanti" style="float: left" (click)="onBackClick()">
                  Indietro
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <ng-template #noResult>
        <ng-container *ngIf="tilesLoaded; else loading">
          <div class="page-subtitle no-results">
            La configurazione che ti è stata assegnata non ti consente di
            {{ action === 'upload' ? 'inserire' : 'creare' }} documenti
          </div>
          <div class="col-12 my-3">
            <button type="button" class="btn btn-primary btnAvanti my-3" style="float: left" (click)="onBackClick()">
              Indietro
            </button>
          </div>
        </ng-container>
      </ng-template>
      <ng-template #loading>
        <div *hubLoading="true"></div>
      </ng-template>
      <!-- GRAFICHE -->

      <!-- UPLOAD DOCUMENTI -------------------------------------------------------------------------------------->
      <div *blockUI="'uploading-docs-loader'; template: loadingTemplate">
        <div data-value="1" draggable="true">
          <div
            *ngIf="showUploadPage"
            class="dropzone"
            fileDrop
            (filesDropped)="handleDrop($event)"
            (filesHovered)="dropzoneState($event)"
            type="file"
          >
            <a class="over-icon" (click)="onUploadFilesClick()">
              <div class="jumbotron upload-page">
                <div *ngIf="!fileLoaded">
                  <p class="lead white-space-normal dropzone-paragraph" style="text-align: center">
                    Puoi allegare un lotto o un documento trascinando i file qua o cliccando su allega file
                  </p>
                  <p class="lead dropzone-paragraph" style="text-align: center">File accettati: zip</p>

                  <label for="files-lotto" class="btn btn-primary btn-lg">
                    <i class="{{ faIcons.FAS_UPLOAD }}"></i>
                    Allega file
                  </label>
                  <input
                    id="files-lotto"
                    type="file"
                    style="display: none"
                    accept=".zip"
                    (change)="onFileChange($event)"
                  />
                </div>
                <div *ngIf="fileLoaded">
                  <p class="lead dropzone-paragraph" style="text-align: center">
                    {{ lottoName }} pronto per essere caricato
                  </p>
                  <button type="button" class="btn btn-danger btn-lg" (click)="removeLotto()">
                    <i class="{{ faIcons.FAR_TRASH }} icon-remove"></i> Rimuovi
                  </button>
                </div>
              </div>
            </a>
            <div class="d-flex justify-content-between mt-2">
              <button type="button" class="btn btn-primary btn-back" style="float: left" (click)="onBackClick()">
                Indietro
              </button>
              <button
                href="#myModal"
                class="btn-upload btn btn-primary"
                data-bs-toggle="modal"
                (click)="uploadLotto()"
                type="button"
                style="float: right"
                [disabled]="fileLoaded ? false : 'disabled'"
              >
                Carica
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
