import { Injectable } from '@angular/core';
import { DocumentDetails, TagsUpdateRequest, TagsValue } from '@ctel/gaw-commons';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { DocumentsHttpService } from '../documents-http.service';
import { DocumentTrashStatus } from './document-trash-status.enum';

/**
 * Servizio che gestisce lo stato dei dettagli del documento (elenco metadati)
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentDetailsService {
  private documentDetails$ = new ReplaySubject<DocumentDetails>(1);
  private graphic$ = new ReplaySubject<string>(1);
  private documentId$ = new BehaviorSubject<string>('');
  private sectionCode$ = new BehaviorSubject<string>('');
  private docSeriesId$ = new BehaviorSubject<string>('');
  private realDocSeriesId$ = new BehaviorSubject<string>('');
  private tags$ = new ReplaySubject<string[]>(1);
  private keys$ = new BehaviorSubject<object>(null);
  private refresh$: BehaviorSubject<number> = new BehaviorSubject(0);

  constructor(private documentsHttpService: DocumentsHttpService) {}

  whenDocumentDetails(
    licenseId: string,
    siaCode: string,
    docSeriesId: string,
    elasticId: string,
    includeTechnical: boolean,
  ): Observable<DocumentDetails> {
    return this.documentsHttpService
      .whenDocumentDetails(licenseId, siaCode, docSeriesId, elasticId, includeTechnical)
      .pipe(
        tap((details) => {
          const gawTags = details.tags.find((tagsArray) => tagsArray.keyCode === 'gaw30_tags');
          if (gawTags !== undefined) this.tags$.next(gawTags.value);

          this.sendKeys(details.keys);
        }),
      );
  }

  whenCurrentDocumentDetails(): Observable<DocumentDetails> {
    return this.documentDetails$.asObservable();
  }

  sendGraphic(graphic: string) {
    this.graphic$.next(graphic);
  }

  whenGraphicValue(): Observable<string> {
    return this.graphic$.asObservable();
  }

  whenGraphic(docSeriesId: string): Observable<string> {
    return this.documentsHttpService.whenGraphic(docSeriesId).pipe(
      map((docSeriesInfo) => docSeriesInfo.graphicsCode),
      tap((graphic) => this.sendGraphic(graphic)),
    );
  }

  sendDocumentDetails(details: DocumentDetails) {
    this.documentDetails$.next(details);
  }

  whenDocumentId(): Observable<string> {
    return this.documentId$.asObservable();
  }

  getDocumentId(): string {
    return this.documentId$.value;
  }

  sendDocumentId(documentId: string) {
    this.documentId$.next(documentId);
  }

  whenSectionCode(): Observable<string> {
    return this.sectionCode$.asObservable();
  }

  getSectionCode(): string {
    return this.sectionCode$.value;
  }

  sendSectionCode(sectionCode: string) {
    this.sectionCode$.next(sectionCode);
  }

  getDocSeriesId(): string {
    return this.docSeriesId$.value;
  }

  getRealDocSeriesId(): string {
    return this.realDocSeriesId$.value;
  }

  sendDocSeriesId(docSeriesId: string) {
    this.docSeriesId$.next(docSeriesId);
  }

  // doc series id reale del singolo documento quando sono su ALL e apro lista WF
  whenRealDocSeriesId(): Observable<string> {
    return this.realDocSeriesId$.asObservable();
  }

  sendRealDocSeriesId(docSeriesId: string) {
    this.realDocSeriesId$.next(docSeriesId);
  }

  getKeys(): object {
    return this.keys$.value;
  }

  sendKeys(keys: object) {
    this.keys$.next(keys);
  }

  // tags
  whenTags(): Observable<string[]> {
    return this.tags$.asObservable();
  }

  sendTags(tags: string[]) {
    this.tags$.next(
      tags.filter(function (elem, index, self) {
        return index === self.indexOf(elem);
      }),
    );
    const payload = this.buildTagsUpdateRequest(tags);
    this.documentsHttpService
      .whenTagsUpdate(JSON.stringify(payload))
      .pipe(
        take(1),
        catchError(() => EMPTY),
      )
      .subscribe();
  }

  resetTags() {
    this.tags$.next([]);
  }

  buildTagsUpdateRequest(tags: string[]): TagsUpdateRequest {
    const tagsValue: TagsValue = {
      value: tags,
      keyCode: 'gaw30_tags',
    };
    return {
      keys: this.getKeys(),
      metadataList: [tagsValue],
    };
  }

  // dopo un'azione dispositiva nel dettaglio, serve aggiornamento dei details di magellano per avere le azioni aggiornate
  sendRefreshDocumentDetails() {
    this.refresh$.next(this.refresh$.getValue() + 1);
  }

  resetRefreshDocumentDetails() {
    this.refresh$.next(0);
  }

  whenRefreshDocumentDetails(): Observable<number> {
    return this.refresh$.asObservable();
  }

  getTrashStatus(
    licenseId: string,
    siaCode: string,
    progSpool: string,
    progBusta: string,
  ): Observable<DocumentTrashStatus> {
    return this.documentsHttpService.getDocumentStatusNumber(licenseId, siaCode, progSpool, progBusta).pipe(
      map((n) => {
        switch (n) {
          case 10:
            return DocumentTrashStatus.visibile;
          case 20:
            return DocumentTrashStatus.trash;
          default:
            return DocumentTrashStatus.deleting;
        }
      }),
    );
  }
}
