import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GraficaValueService {
  public graficaValue = new BehaviorSubject('');
  public channelType = new BehaviorSubject(1);
  currentGrafica = this.graficaValue.asObservable();
  currentChannelType = this.channelType.asObservable();

  newGrafica(grafica: string, channelType: number) {
    this.channelType.next(channelType);
    this.graficaValue.next(grafica);
  }
}
