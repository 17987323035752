import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BoxDetails, IDetailsComponent, Spool } from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, Subscription } from 'rxjs';
import { BoxDetailsService } from '../../../services/box-details.service';

@Component({
  selector: 'gaw-gawpass-expedition',
  templateUrl: './gawpass-expedition.component.html',
  styleUrls: ['./gawpass-expedition.component.scss'],
})
export class GawpassExpeditionComponent implements OnInit, OnDestroy {
  @Input() boxDetails$: Observable<BoxDetails>;
  @Input() data: IDetailsComponent;
  tileErrorPlaceholderErrorOption = { message: 'Errore imprevisto.' };
  appConfig: AppConfig;
  subscriptions = new Subscription();

  constructor(
    private router: Router,
    private confService: ConfigService,
    private boxDetailsService: BoxDetailsService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.confService.whenAppConfig().subscribe((value) => (this.appConfig = value)));
  }

  goToLottoDetail(spool: Spool) {
    this.router
      .navigate([
        `details/${this.data.licenseId}/${this.data.siaCode}/spools/${this.appConfig.lottiDocSeriesId}/GAWLOTTI/${spool.elasticId}`,
      ])
      .then();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
