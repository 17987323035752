<div class="text-end">
  <span>{{
    value
      | numberLocale
        : {
            locale: 'it',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }
  }}</span>
</div>
