import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountModule } from './account/account.module';
import { DocumentsModule } from './documents/documents.module';
import { TipoDocumentoModalComponent } from './modals/tipo-documento-modal/tipo-documento-modal.component';

@NgModule({
  declarations: [TipoDocumentoModalComponent],
  imports: [DocumentsModule, AccountModule, CommonModule],
  exports: [DocumentsModule, AccountModule],
})
export class HubfeCommonsModule {}
