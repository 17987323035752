import { Extension, File } from 'app/core/common/utilities/file/file';

export class AttachmentPreview {
  private static availablePreviews = [Extension.PDF, Extension.XML];

  public static hasPreview(filename: string): boolean {
    const ext = File.getExtension(filename);
    return this.availablePreviews.indexOf(ext) !== -1;
  }

  public static getPreviewType(filename: string): Extension | undefined {
    return this.availablePreviews.find((x) => x === File.getExtension(filename));
  }
}
