import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe che converte un datetime iso in data dd/mm/yyyy
 */
@Pipe({
  name: 'isoDateToDate',
})
export class IsoDateToDatePipe implements PipeTransform {
  transform(value: string): string {
    if (value !== null && value !== '' && value !== undefined) {
      let dayString: string;
      let monthString: string;

      const date = new Date(value);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      if (day < 10) dayString = '0' + day;
      else dayString = '' + day;

      if (month < 10) monthString = '0' + month;
      else monthString = '' + month;

      return dayString + '/' + monthString + '/' + year;
    } else return value;
  }
}
