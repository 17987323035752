import { DocumentKeysItem } from './document-keys-item';

export class PDDRequestModel {
  idLicenza: string;
  codSia: string;
  annoFiscale: string;
  idRegistro: string;
  descrizione: string;
  flagAttestato: boolean;
  flagIPDA: boolean;
  flagRDV: boolean;
  flagSuperimpronte: boolean;
  flagComunicazioniAde: boolean;
  flagRicevuteAde: boolean;
  docCount: number;
  previous2014: boolean;
  documentForPdds: DocumentKeysItem[];
}
