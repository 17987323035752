import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadsNotificationService } from './uploads-notification.service';
import { UploadsNotificationHttpService } from './uploads-notification-http.service';

@NgModule({
  imports: [CommonModule],
})
export class UploadsNotificationModule {
  public static forRoot(): ModuleWithProviders<UploadsNotificationModule> {
    return {
      ngModule: UploadsNotificationModule,
      providers: [UploadsNotificationService, UploadsNotificationHttpService],
    };
  }
}
