import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { UiConfig } from 'app/entities/ui-config/ui-config';

// CONFIGURAZIONE PIASTRELLE ATTIVO
export const uiConfigReceivable: UiConfig = {
  services: [
    {
      serviceId: '35',
      serviceName: 'GEDINVOICE',
      tiles: [
        {
          serviceId: '35',
          serviceName: 'GEDINVOICE',
          title: 'Inviate a SDI',
          subtitle: '',
          color: 'blue',
          metadata: SectionCode.INVIATOSDI35,
        },
        {
          serviceId: '35',
          serviceName: 'GEDINVOICE',
          title: 'Errore controlli SDI',
          subtitle: '',
          color: 'red',
          metadata: SectionCode.ERRORECONTROLLISDI35,
        },
        {
          serviceId: '35',
          serviceName: 'GEDINVOICE',
          title: 'Mancata consegna',
          subtitle: '',
          color: 'red',
          metadata: SectionCode.NONCONSEGNATO35,
        },
        {
          serviceId: '35',
          serviceName: 'GEDINVOICE',
          title: 'Mancata consegna copia analogica',
          subtitle: '',
          color: 'red',
          metadata: SectionCode.NONCONSEGNATO35CA,
          hidden: true,
        },
        {
          serviceId: '35',
          serviceName: 'GEDINVOICE',
          title: 'Annullate',
          subtitle: '',
          color: 'grey',
          metadata: SectionCode.ANNULLATO35,
        },
        {
          serviceId: '35',
          serviceName: 'GEDINVOICE',
          title: 'Consegnate',
          subtitle: '',
          color: 'green',
          metadata: SectionCode.CONSEGNATO35,
        },
      ],
    },
    {
      serviceId: '6',
      serviceName: 'GEDPOST',
      tiles: [
        {
          serviceId: '6',
          serviceName: 'GEDPOST',
          title: 'Inviate allo stampatore',
          subtitle: '',
          color: 'blue',
          metadata: SectionCode.INVIATOSTAMPATORE06,
        },
        {
          serviceId: '6',
          serviceName: 'GEDPOST',
          title: 'Stampate',
          subtitle: '',
          color: 'blue',
          metadata: SectionCode.STAMPATO06,
        },
        {
          serviceId: '6',
          serviceName: 'GEDPOST',
          title: 'Rese',
          subtitle: '',
          color: 'red',
          metadata: SectionCode.RESO06,
        },
        {
          serviceId: '6',
          serviceName: 'GEDPOST',
          title: 'Annullate',
          subtitle: '',
          color: 'grey',
          metadata: SectionCode.ANNULLATO06,
        },
        {
          serviceId: '6',
          serviceName: 'GEDPOST',
          title: 'Consegnate o non tracciate',
          subtitle: '',
          color: 'green',
          metadata: SectionCode.CONSEGNATO06,
        },
      ],
    },
    {
      serviceId: '21',
      serviceName: 'GEDMAIL',
      tiles: [
        {
          serviceId: '21',
          serviceName: 'GEDMAIL',
          title: 'Da spedire',
          subtitle: '',
          color: 'blue',
          metadata: SectionCode.DASPEDIRE21,
        },
        {
          serviceId: '21',
          serviceName: 'GEDMAIL',
          title: 'In errore',
          subtitle: '',
          color: 'red',
          metadata: SectionCode.INERRORE21,
        },
        {
          serviceId: '21',
          serviceName: 'GEDMAIL',
          title: 'Annullate',
          subtitle: '',
          color: 'grey',
          metadata: SectionCode.ANNULLATO21,
        },
        {
          serviceId: '21',
          serviceName: 'GEDMAIL',
          title: 'Spedite',
          subtitle: '',
          color: 'green',
          metadata: SectionCode.SPEDITO21,
        },
      ],
    },
    {
      serviceId: '22',
      serviceName: 'GEDPEC',
      tiles: [
        {
          serviceId: '22',
          serviceName: 'GEDPEC',
          title: 'Da spedire',
          subtitle: '',
          color: 'blue',
          metadata: SectionCode.SPEDITO22,
        },
        {
          serviceId: '22',
          serviceName: 'GEDPEC',
          title: 'Consegna non riuscita',
          subtitle: '',
          color: 'red',
          metadata: SectionCode.NONCONSEGNATO22,
        },
        {
          serviceId: '22',
          serviceName: 'GEDPEC',
          title: 'Annullate',
          subtitle: '',
          color: 'grey',
          metadata: SectionCode.ANNULLATO22,
        },
        {
          serviceId: '22',
          serviceName: 'GEDPEC',
          title: 'Consegnate',
          subtitle: '',
          color: 'green',
          metadata: SectionCode.CONSEGNATO22,
        },
      ],
    },
  ],
};

export const uiLargeCardsMetricsConfig: { title: string; metadata: string }[] = [
  {
    title: 'In importazione',
    metadata: SectionCode.RICEVUTO,
  },
  {
    title: 'In errore pre-elaborazione',
    metadata: SectionCode.INERROREPREELAB,
  },
  {
    title: 'Conferma e firma',
    metadata: SectionCode.INFIRMA,
  },
  {
    title: 'In spedizione',
    metadata: SectionCode.INSPEDIZIONE,
  },
  {
    title: 'Consegnato su canale prioritario',
    metadata: SectionCode.CONSEGNATO,
  },
  {
    title: 'Annullato su canale prioritario',
    metadata: SectionCode.ANNULLATO,
  },
  {
    title: 'In conservazione',
    metadata: SectionCode.INCONSERVAZIONE,
  },
];

// serve solo per dare titolo nella lista documenti
export const uiPayableCardsMetricsConfig: { title: string; metadata: string }[] = [
  {
    title: 'In elaborazione sul canale GEDFATT',
    metadata: SectionCode.CP_RICEVUTO35,
  },
  {
    title: 'In elaborazione sul canale GEDPASSPLUS',
    metadata: SectionCode.CP_RICEVUTO36,
  },
  {
    title: 'In elaborazione sul canale GEDPASS',
    metadata: SectionCode.CP_RICEVUTO18,
  },
  {
    title: 'In elaborazione sul canale GEDEDI',
    metadata: SectionCode.CP_RICEVUTO10,
  },
  {
    title: 'Copie analogiche non accoppiate ad originali',
    metadata: SectionCode.CP_NONACCOPPIATO,
  },
  {
    title: 'Ricevute',
    metadata: SectionCode.CP_RICEVUTO,
  },
  {
    title: 'Squadratura fra originale e copie',
    metadata: SectionCode.CP_SQUADRATO,
  },
  {
    title: 'Fatture da approvare',
    metadata: SectionCode.CP_ATTESAACCETTRIFIUTO,
  },
  {
    title: 'Fatture non conservate',
    metadata: SectionCode.CP_ESCLUSAPROTOCOLLAZIONE,
  },
  {
    title: 'Esportate verso gestionale e da protocollare',
    metadata: SectionCode.CP_ESPORTATO,
  },
  {
    title: 'Registrate su gestionale e protocollate',
    metadata: SectionCode.CP_REGISTRATO,
  },
  {
    title: 'In conservazione',
    metadata: SectionCode.CP_INCONSERVAZIONE,
  },
  {
    title: 'Fatture squadrate dopo esportazione',
    metadata: SectionCode.CP_SQUADRATOPOSTELAB,
  },
];

// metadati che saranno presenti nella proprietà display della response della /Search
export const selectMetadataConfig: string[] = [
  MetadataEnum.INTESTAZIONE,
  MetadataEnum.NUMERO_FATTURA,
  MetadataEnum.DATA_FATTURA,
  MetadataEnum.IMPORTO,
  MetadataEnum.VALUTA_ISO,
  MetadataEnum.DATA_INSERIMENTO,
  MetadataEnum.FAMIGLIA_ERRORE,
  MetadataEnum.DETTAGLIO_ERRORE,
  MetadataEnum.ULTIMO_STATO_PREM,
  MetadataEnum.DATA_RICEZIONE,
  MetadataEnum.DESCRIZIONE_STATO,
  MetadataEnum.NUMERO_PROTOCOLLO,
  MetadataEnum.DATA_PROTOCOLLO,
  MetadataEnum.GAW30_TAGS,
  MetadataEnum.VISIBILITY,
  MetadataEnum.DESCRIZIONE_STATO_35,
  MetadataEnum.DESCRIZIONE_STATO_06,
  MetadataEnum.DESCRIZIONE_STATO_22,
  MetadataEnum.DESCRIZIONE_STATO_21,
  MetadataEnum.FORMATO_TRASMISSIONE_SDI,
  MetadataEnum.STATO_WEB,
  MetadataEnum.ULTIMO_STATO_35,
  MetadataEnum.PROGSPOOL,
  MetadataEnum.PROGBUSTA,
  MetadataEnum.ID_COMUNICAZIONE_06,
  MetadataEnum.ID_COMUNICAZIONE_22,
  MetadataEnum.ID_COMUNICAZIONE_21,
  MetadataEnum.ID_COMUNICAZIONE_35,
  MetadataEnum.ULTIMO_STATO_PA35,
  MetadataEnum.HAS_SCONTO_DINAMICO,
  MetadataEnum.ULTIMO_STATO_22,
  MetadataEnum.ULTIMO_STATO_06,
  MetadataEnum.ULTIMO_STATO_21,
  MetadataEnum.PRESENZA_ALLEGATI_35,

  SectionCode.RICEVUTO,
  SectionCode.INERROREPREELAB,
  SectionCode.INFIRMA,
  SectionCode.INSPEDIZIONE,
  SectionCode.CONSEGNATO,
  SectionCode.ANNULLATO,
  SectionCode.INVIATOSDI35,
  SectionCode.ERRORECONTROLLISDI35,
  SectionCode.NONCONSEGNATO35,
  SectionCode.NONCONSEGNATO35CA,
  SectionCode.ANNULLATO35,
  SectionCode.CONSEGNATO35,
  SectionCode.INVIATOSTAMPATORE06,
  SectionCode.STAMPATO06,
  SectionCode.RESO06,
  SectionCode.ANNULLATO06,
  SectionCode.CONSEGNATO06,
  SectionCode.DASPEDIRE21,
  SectionCode.INERRORE21,
  SectionCode.ANNULLATO21,
  SectionCode.SPEDITO21,
  SectionCode.SPEDITO22,
  SectionCode.NONCONSEGNATO22,
  SectionCode.ANNULLATO22,
  SectionCode.CONSEGNATO22,
  // card passivo
  SectionCode.CP_NONACCOPPIATO,
  SectionCode.CP_RICEVUTO,
  SectionCode.CP_SQUADRATO,
  SectionCode.CP_ESPORTATO,
  SectionCode.CP_ATTESAACCETTRIFIUTO,
  SectionCode.CP_ESCLUSAPROTOCOLLAZIONE,
  SectionCode.CP_REGISTRATO,
  SectionCode.CP_SQUADRATOPOSTELAB,
];

// metadati per il payload di magellano (proprietà metadataList) = metadati elastic da rendere ricercabili tramite barra di ricerca
export const metadataListConfig: string[] = [
  MetadataEnum.INTESTAZIONE,
  MetadataEnum.NUMERO_FATTURA,
  MetadataEnum.GAW30_TAGS,
  MetadataEnum.NUMERO_PROTOCOLLO,
  MetadataEnum.IMPORTO,
  MetadataEnum.PIVA,
  MetadataEnum.CODICE_FISCALE,
  MetadataEnum.ID_FILE_SDI,
  MetadataEnum.DATA_INSERIMENTO,
  MetadataEnum.SERVIZI_CONFIGURATI,
];
