import { Action, createAction, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Column } from 'app/constants/column-configuration/ui-configuration-columns';
import { produce } from 'immer';

export const documentsSearchModuleFeatureKey = 'hubDocumentsSearchModule';
// actions.ts

export const sectionColumnsFetchedHUBFE = createAction(
  '[Filter Effect HUBFE] sectionColumnsFetched',
  (primaryConfig: Column[] = [], secondaryConfig: Column[]) => ({ payload: { primaryConfig, secondaryConfig } }),
);

// reducer.ts
export function extendedDocumentStateReducer(state: ExtendedDocumentState | undefined, action: Action) {
  return _extededDocumentStateReducer(state, action);
}

export interface ExtendedDocumentState {
  primaryColumnConfig: Column[];
  secondaryColumnConfig: Column[];
}

export const initialState: ExtendedDocumentState = {
  primaryColumnConfig: [],
  secondaryColumnConfig: [],
};

const _onSectionColumnsFetched = (currentState, { payload }) =>
  produce<ExtendedDocumentState, ExtendedDocumentState>(currentState, (draftState) => {
    draftState.primaryColumnConfig = payload.primaryConfig;
    payload.secondaryConfig.forEach((element, index) => {
      if (element.value === 'GEDFATT')
        draftState.secondaryColumnConfig[index] = { value: 'SDI', id: element.id, displayStyle: element.displayStyle };
      else if (element.value === 'GEDMAIL')
        draftState.secondaryColumnConfig[index] = {
          value: 'Email',
          id: element.id,
          displayStyle: element.displayStyle,
        };
      else if (element.value === 'GEDPEC')
        draftState.secondaryColumnConfig[index] = { value: 'PEC', id: element.id, displayStyle: element.displayStyle };
      else if (element.value === 'GEDPOST')
        draftState.secondaryColumnConfig[index] = {
          value: 'Posta',
          id: element.id,
          displayStyle: element.displayStyle,
        };
      else if (element.value === 'GEDPASS')
        draftState.secondaryColumnConfig[index] = {
          value: 'Digitalizzazione Documenti',
          id: element.id,
          displayStyle: element.displayStyle,
        };
      else if (element.value === 'GEDEDI')
        draftState.secondaryColumnConfig[index] = { value: 'EDI', id: element.id, displayStyle: element.displayStyle };
      else if (element.value === 'GEDPASS+')
        draftState.secondaryColumnConfig[index] = {
          value: 'Da PDF a XML (OCR)',
          id: element.id,
          displayStyle: element.displayStyle,
        };
      else if (element.value === 'GEDNOTICE')
        draftState.secondaryColumnConfig[index] = {
          value: 'TNotice',
          id: element.id,
          displayStyle: element.displayStyle,
        };
      else draftState.secondaryColumnConfig[index] = element;
    });

    return draftState;
  });

const _extededDocumentStateReducer = createReducer(
  initialState,
  on(sectionColumnsFetchedHUBFE, _onSectionColumnsFetched),
);

// index.ts
export interface ExtendedDocumentsSearchModuleState {
  extendedDocumentState: ExtendedDocumentState;
}

export const extendedDocumentsSearchModuleReducers = {
  extendedDocumentState: extendedDocumentStateReducer,
};

export const selectDocumentsSearchModuleState = createFeatureSelector<ExtendedDocumentsSearchModuleState>(
  documentsSearchModuleFeatureKey,
);

export const selectExtendedDocumentState = createSelector(
  selectDocumentsSearchModuleState,
  (state: ExtendedDocumentsSearchModuleState) => state.extendedDocumentState,
);

export const getColumnConfig = createSelector(selectExtendedDocumentState, (value: ExtendedDocumentState) => ({
  primaryConfig: value.primaryColumnConfig,
  secondaryConfig: value.secondaryColumnConfig,
}));
