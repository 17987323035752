export enum NotificationType {
  GENERAL,
  ERROR,
  SUCCESS,
  INFO,
  QUESTION,
  LOADING,
  CUSTOM,
  LARGE,
  EXTRA_LARGE,
  XXL,
}
