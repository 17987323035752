<select
  class="form-control form-control-sm"
  name="{{ name }}"
  [id]="id"
  [ngStyle]="style"
  [ngClass]="{
    valid: selectInputForm.value && selectInputForm.valid && required,
  }"
  #selectInputForm="ngModel"
  [(ngModel)]="value"
  (ngModelChange)="onChangeUpdate()"
  [ngModelOptions]="ngModelOptions"
  #selectInputPopover="bs-popover"
  [popover]="InvalidFormMessage"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()"
  value="{{ value }}"
  triggers=""
  [required]="required"
  [selectValidator]="selectValues"
  [useComplexObj]="useComplexObject"
  [valueId]="valueId"
  [composeValidators]="_validators"
  [composeAsyncValidators]="_asyncValidators"
>
  <option value=""></option>
  <ng-container *ngIf="useComplexObject; else primaryObject">
    <ng-container *ngFor="let val of selectValues">
      <option [value]="val[valueId]">
        {{ val[valueName] }}
      </option>
    </ng-container>
  </ng-container>
  <ng-template #primaryObject>
    <ng-container *ngFor="let val of selectValues">
      <option [value]="val">
        {{ val }}
      </option>
    </ng-container>
  </ng-template>
</select>
<ng-template #InvalidFormMessage>
  <ul *ngIf="selectInputForm.invalid" class="invalid-form-msg">
    <ng-container *ngFor="let error of getErrorMessage(selectInputForm)">
      <li>
        {{ error }}
      </li>
    </ng-container>
  </ul>
</ng-template>
