import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ErrorMessage } from 'app/entities/errors/errors';
import { CustomValidationError } from 'app/shared/components/dry/validator/custom-validation-error';

export class CheckboxValidator {
  /**
   * Validator that requires controls must be true.
   * @return ValidatorFn
   */
  public static requiredTrue(): ValidatorFn {
    return Validators.requiredTrue;
  }

  public static isBoolean(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: ErrorMessage } => {
      if (control.value && control.value !== '')
        return typeof control.value === 'boolean'
          ? null
          : <CustomValidationError>{
              boolean: {
                message: 'Sono ammessi solo valori true o false',
              },
            };

      return null;
    };
  }
}
