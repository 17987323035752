import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { FormControlName, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { SelectValidators } from 'app/shared/components/dry/validator/select-validators';
import { CustomFormControlComponent } from 'app/shared/components/form-validation/reactive-form/custom-form-control.component';

@Component({
  selector: 'app-select-form-control',
  templateUrl: './select-form-control.component.html',
  styleUrls: ['./select-form-control.component.scss'],
})
export class SelectFormControlComponent<T> extends CustomFormControlComponent<T> implements OnInit, OnChanges {
  @Input() values: T[];
  @Input() tooltip: boolean;

  @Input() objectId: string;
  @Input() objectValue: string;
  @Input() placeholder: string;

  private controlValidators: ValidatorFn;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public controlName: FormControlName,
    private cdr: ChangeDetectorRef,
  ) {
    super(ngControl, controlName);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.controlValidators = this.control.validator;
    this.control.setValidators(Validators.compose([this.controlValidators, this.checkValuesValidators()]));
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) return;

    if (this.control && changes['values'] && changes['values'].currentValue)
      // setto i validatori solo se cambia l'input iniziale
      this.control.setValidators(Validators.compose([this.controlValidators, this.checkValuesValidators()]));
  }

  private checkValuesValidators(): ValidatorFn {
    return this.objectId
      ? SelectValidators.checkObject(this.values, this.objectId)
      : SelectValidators.checkPrimitiveValue(this.values);
  }
}
