import { Injectable } from '@angular/core';
import { HomeFilter, HomeFilterRangeType } from '@ctel/gaw-commons';
import { Store, select } from '@ngrx/store';
import { FilterBuilder } from 'app/constants/filters/filter-builder';
import { dataProtocollazioneCtelFilterConfig } from 'app/constants/filters/filters-config';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';
import { Copier } from 'app/core/common/utilities/copier';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { changeHomeDateFilterRequested } from '../documents/filters/store/home-filter.actions';
import { getCachedHomeFilters } from '../documents/filters/store/home-filter.selectors';

/**
 * Servizio che gestisce le dropdown con le date
 */
@Injectable({
  providedIn: 'root',
})
export class DateDropdownsReceivableService {
  private isInvoiceDateSelected$ = new BehaviorSubject<boolean>(false);
  private currentFilterValue: HomeFilter[];

  constructor(private store: Store) {
    this.initializeDateFilters();
  }

  initializeDateFilters() {
    const currentFilterValue$ = this.store.pipe(
      select(getCachedHomeFilters),
      map((homeFilters) => homeFilters.receivable),
    );
    currentFilterValue$
      .pipe(
        tap((homeFilters) => {
          this.currentFilterValue = Copier.deepCopy(homeFilters);
        }),
      )
      .subscribe();
  }

  // se è stata selezionata una data fattura (serve per disabilitare la chiamata ai dati degli spool)
  whenIsInvoiceDateSelected(): Observable<boolean> {
    return this.isInvoiceDateSelected$.asObservable();
  }

  sendIsInvoiceDateSelected(value: boolean) {
    this.isInvoiceDateSelected$.next(value);
  }

  // current date filter value
  getCurrentDateFilterValue(): HomeFilter[] {
    return this.currentFilterValue;
  }

  // quando cambio il valore selezionato in una delle dropdown data della home
  createDateFilter(
    metadata: string,
    valueFrom: string,
    valueTo: string,
    doubleInsert: boolean,
    dateRangeType: HomeFilterRangeType,
    valueFromInvoice?: string,
    valueToInvoice?: string,
  ) {
    const previousDateFilters = Copier.deepCopy(this.getCurrentDateFilterValue());
    const newFilters: HomeFilter[] = previousDateFilters.filter((value) => {
      if (!doubleInsert) return value.metadata !== metadata;

      return value.metadata !== MetadataEnum.DATA_FATTURA && value.metadata !== MetadataEnum.DATA_INSERIMENTO;
    });

    // aggiungo il nuovo filtro (ma non se sto impostando data fattura su "nessun filtro" o se sto facendo un doppio inserimento)
    if (valueFrom !== '' && !doubleInsert) {
      if (metadata === MetadataEnum.DATA_FATTURA) {
        const newFilter = previousDateFilters.find((filter) => filter.metadata === MetadataEnum.DATA_INSERIMENTO);
        if (!newFilter) {
          // Default.
          const newF = Copier.deepCopy(dataProtocollazioneCtelFilterConfig);
          newF.dateRangeType = HomeFilterRangeType.ULTIMO_TRIMESTRE;
          newFilters.splice(0, 0, newF);
        }
      }
      const built = FilterBuilder.generalDateFilter(metadata, valueFrom, valueTo) as HomeFilter;
      built.dateRangeType = dateRangeType;
      newFilters.push(built);
      // se è un doppio inserimento, inserisco entrambe le tipologie di filtro data
    } else if (doubleInsert) {
      const built1 = FilterBuilder.dataProtocollazioneCtelFilter(valueFrom, valueTo) as HomeFilter;
      built1.dateRangeType = dateRangeType;
      const built2 = FilterBuilder.invoiceDateFilter(valueFromInvoice, valueToInvoice) as HomeFilter;
      built2.dateRangeType = dateRangeType;
      newFilters.push(built1, built2);
    }
    this.store.dispatch(changeHomeDateFilterRequested(newFilters, 'receivable', true));
  }
}
