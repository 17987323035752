import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EnabledServicesService } from 'app/core/business/user/enabled-services/enabled-services.service';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthorizationGuard {
  constructor(
    private enabledServicesService: EnabledServicesService,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const authorizedService = next.data.authorizedService;
    return this.enabledServicesService.isEnabledService(authorizedService).pipe(
      take(1),
      switchMap((enabledService) => {
        if (!enabledService) {
          this.router.navigate(['/service-disabled'], { queryParams: { serviceName: authorizedService } }).then();
          return of(false);
        }
        return of(true);
      }),
    );
  }
}
