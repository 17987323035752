import { Component } from '@angular/core';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';

@Component({
  // selector: 'gaw-refresh-placeholder',
  templateUrl: './refresh-placeholder.component.html',
  styleUrls: ['./refresh-placeholder.component.scss'],
})
export class RefreshPlaceholderComponent {
  public faIcons = FaIcons;
}
