<div class="d-flex block-ui-light-template">
  <div class="row align-self-center w-100 mx-auto">
    <div class="col-6 mx-auto">
      <i
        class="spinner-icon text-primary"
        [ngClass]="[faIcons.FAS_CIRCLE_NOTCH, faIcons.FA_SPIN, faIcons.FA_3X]"
        aria-hidden="true"
      ></i>
    </div>
  </div>
</div>
