import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentsViewService {
  private documentsScrollPosition: number;

  constructor() {
    this.documentsScrollPosition = 0;
  }

  getDocumentsScrollPosition(): number {
    return this.documentsScrollPosition;
  }

  sendDocumentsScrollPosition(position: number) {
    this.documentsScrollPosition = position;
  }
}
