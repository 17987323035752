import { Pipe, PipeTransform } from '@angular/core';
import { Company } from 'app/entities/companies/company';

@Pipe({ name: 'companiesOrderByAlphabetical' })
export class AlphabeticalOrderPipe implements PipeTransform {
  transform(companies: Company[]) {
    companies.sort((a: Company, b: Company) => {
      if (a.companyDescription < b.companyDescription) return -1;
      else if (a.companyDescription > b.companyDescription) return 1;
      else return 0;
    });
    return companies;
  }
}
