import { CessionarioCommittente, DatiTrasmissione } from 'app/entities/invoice-pa/invoice-pa-model';

export interface Contact {
  contactId: number;
  contactAlias: string;
  contactConfiguration: ContactConfiguration;
}

export interface ContactConfiguration {
  contactAlias: string;
  cessionarioCommittente: CessionarioCommittente;
  datiTrasmissione?: DatiTrasmissione;
}

export interface GetContactsResponse {
  contacts: Contact[];
  count: number;
}

export interface UpsertContact {
  idContact?: number;
  contactAlias: string;
  configuration: ContactConfiguration;
}

export const emptyContact: Contact = {
  contactId: null,
  contactAlias: '',
  contactConfiguration: {
    contactAlias: '',
    cessionarioCommittente: {
      DatiAnagrafici: {
        IdFiscaleIVA: {
          IdPaese: '',
          IdCodice: '',
        },
        CodiceFiscale: '',
        Anagrafica: {
          Denominazione: '',
          Nome: '',
          Cognome: '',
          Titolo: '',
          CodEORI: '',
        },
      },
      Sede: {
        Indirizzo: '',
        NumeroCivico: '',
        CAP: '',
        Comune: '',
        Provincia: '',
        Nazione: '',
      },
      StabileOrganizzazione: {
        Indirizzo: '',
        NumeroCivico: '',
        CAP: '',
        Comune: '',
        Provincia: '',
        Nazione: '',
      },
      RappresentanteFiscale: {
        IdFiscaleIVA: {
          IdPaese: '',
          IdCodice: '',
        },
        Denominazione: '',
        Nome: '',
        Cognome: '',
      },
    },
    datiTrasmissione: {
      IdTrasmittente: {
        IdPaese: '',
        IdCodice: '',
      },
      ProgressivoInvio: '',
      FormatoTrasmissione: '',
      CodiceDestinatario: '',
      ContattiTrasmittente: {
        Telefono: '',
        Email: '',
      },
      PECDestinatario: '',
    },
  },
};
