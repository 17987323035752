import { ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef } from '@angular/core';
import { Column } from '..';
import { Field } from '../../dry/field';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';

@Directive({
  selector: '[gridHeaderRenderer]',
})
export class HeaderRendererDirective<T extends Field> implements OnInit, OnChanges {
  @Input() column: Column<T>;

  private component: ComponentRef<unknown>;

  constructor(private container: ViewContainerRef) {}

  ngOnInit(): void {
    const component = this.column.headerComponentRender ? this.column.headerComponentRender : SimpleHeaderComponent;
    this.component = this.container.createComponent(component);
    this.component.instance['column'] = this.column;
  }

  ngOnChanges(): void {
    if (this.component) this.component.instance['column'] = this.column;
  }
}
