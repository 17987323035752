import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UiConfigurationHttpService } from './ui-configuration-http.service';
import { UiConfigurationService } from './ui-configuration.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class UiConfigurationModule {
  static forRoot(): ModuleWithProviders<UiConfigurationModule> {
    return {
      ngModule: UiConfigurationModule,
      providers: [UiConfigurationService, UiConfigurationHttpService],
    };
  }
}
