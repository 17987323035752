import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StatusNoteItem } from '@ctel/gaw-commons';
import { Transfer, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { AppError } from 'app/core/common/error/app-error';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HistoryActionModalComponent } from '../../modals/history-action-modal.component/history-action-modal.component';
import { CheckboxService } from '../checkbox/checkbox.service';
import { DocumentDetailsService } from '../document-details/document-details.service';
import { DocumentsSeriesService } from '../documents-series/documents-series.service';
import { DocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionsService2 } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class ExecuteActionsService {
  public readonly gedPecEndpoint = '/api/v1/Action/modifypec';
  private readonly gedMailEndpoint =
    '/v1/Document/GEDMAIL/ModificaMail?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&indirizzoMail={mail}';

  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private actionsService: ActionsService2,
    private documentDetailsService: DocumentDetailsService,
    private documentsService: DocumentsService2,
    private documentsSeriesService: DocumentsSeriesService,
    private checkboxService: CheckboxService,
    private companiesService: CompaniesService,
    private router: Router,
  ) {}

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  public executeSingleAction(action: ActionObject | ActionConfigItem, keys: any) {
    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    const basicPayload = {
      ctelElasticDocumentId: keys.ctelElasticDocumentId,
      idFileOutput10: keys.idFileOutput10,
    };

    let idLicenza: string;

    switch (actionCode) {
      // GAWEDI SINGLE ACTIONS
      case ActionCode.SHOW_STATUS_HISTORY:
        this.actionsHttpService
          .whenChangesHistory(actionUrl, keys.idFileOutput10)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result: StatusNoteItem[] | null) => {
            if (result) {
              let title = '';

              switch (actionCode) {
                case ActionCode.SHOW_STATUS_HISTORY:
                  title = 'Storico stati';
                  this.actionsService.sendHistory(result);
                  this.notificationService.showModal(NotificationType.LARGE, {
                    title,
                    customFooter: false,
                    childComponent: HistoryActionModalComponent,
                    childData: basicPayload,
                    disableClickOutside: false,
                  });
                  break;
              }
            } else this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico non trovato');
          });
        break;

      case ActionCode.DOWNLOAD_PDF:
        idLicenza = this.companiesService.getCurrentCompanyValue().licenseId;
        this.actionsHttpService
          .whenSinglePdf(actionUrl, idLicenza, keys.idFileOutput10)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
                new FileSaver(blob).saveAs(keys.numeroDocumento || r.name, FileSaverExtension.PDF);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      case ActionCode.DOWNLOAD_EDI:
        idLicenza = this.companiesService.getCurrentCompanyValue().licenseId;
        this.actionsHttpService
          .whenEdi(actionUrl, idLicenza, keys.idFileOutput10)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/octet-stream' });
                //TODO nome provvisorio
                new FileSaver(blob).saveAs(`${keys.idFileOutput10}.edi`, FileSaverExtension.EDI);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;

      default:
        console.warn('ACTION non configurata', actionCode);
        break;
    }
  }

  // ESECUZIONE AZIONI MULTIPLE
  public executeMultiAction(action: ActionObject, keys: any[], sectionCode?: string) {
    const docCount = keys.length;

    const actionCode = action.actionCode;
    // let actionCode = '';
    // let actionUrl = '';

    // if (action instanceof ActionObject) {
    // 	actionCode = action.actionCode;
    // 	actionUrl = action.url;
    // } else {
    // 	actionCode = action.actionCode;
    // 	actionUrl = action.actionUrl;
    // }

    switch (actionCode) {
      // massive/multi
      case ActionCode.DOWNLOAD_EXCEL: {
        // Modal della scelta del formato dell'excel
        const showModal = () => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: 'Esporta risultati in Excel',
            childComponent: ExcelFormatModalComponent,
            childData: {
              action,
              actionType: 'multiple',
              docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
              keys,
              service: 'GAWEDI',
              sectionCode,
            },
          });
        };

        if (docCount > 50000)
          this.notificationService.showSweetAlert(
            NotificationType.QUESTION,
            'Numero massimo di documenti superato.',
            "Procedere all'esportazione dei primi 50000 documenti?",
            showModal,
          );
        else showModal();

        break;
      }
    }
  }

  public editEmail(
    indirizzoPec: string,
    progSpool: string,
    progBusta: string,
    idComunicazione: string,
    gedpec: boolean,
  ) {
    if (gedpec)
      return this.actionsHttpService.whenModifyMailOrPecAddress(this.gedPecEndpoint, {
        progSpool,
        progBusta,
        idComunicazione,
        indirizzoPec,
      });

    return this.actionsHttpService.whenModifyMailOrPecAddress(this.gedMailEndpoint, {
      progSpool,
      progBusta,
      idComunicazione,
      indirizzoPec,
    });
  }

  private catchError(err: AppError): Observable<null> {
    if (err.type === ErrorTypes.HTTP_UNAUTHORIZED)
      //this.router.navigate(['/unauthorized']).then();
      return of(null);
    else return of(null);
  }
}
