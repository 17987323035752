import { Injectable } from '@angular/core';
import { MassiveActionsCatalog } from './massive-actions-catalog';
import { MultiActionsCatalog } from './multi-actions-catalog';
import { SingleActionsCatalog } from './single-actions-catalog';
import { ActionObject } from 'app/entities/ui-config/action/action-object';

@Injectable({
  providedIn: 'root',
})
export class ActionsByType {
  public readonly massiveActions: ActionObject[] = [
    this.massiveActionsCatalog.cancelMassive,
    this.massiveActionsCatalog.confirmMassive,
  ];

  public readonly multiActions: ActionObject[] = [
    this.multiActionsCatalog.cancelMultiple,
    this.multiActionsCatalog.confirmMultiple,
  ];

  public readonly singleActions: ActionObject[] = [
    this.singleActionsCatalog.cancel,
    this.singleActionsCatalog.confirm,
    this.singleActionsCatalog.download,
  ];

  constructor(
    private massiveActionsCatalog: MassiveActionsCatalog,
    private multiActionsCatalog: MultiActionsCatalog,
    private singleActionsCatalog: SingleActionsCatalog,
  ) {}
}
