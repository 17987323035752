import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppError } from 'app/core/common/error';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'gaw-sign-reason-modal',
  templateUrl: './sign-reason-modal.component.html',
  styleUrls: ['./sign-reason-modal.component.scss'],
})
export class SignReasonModalComponent implements OnInit, OnDestroy {
  @Input() modal: BsModalRef;
  @Input() data: {
    licenseId: string;
    siaCode: string;
    progSpools: number[];
    sign: (
      username: string,
      password: string,
      otp: number,
      licenseId: string,
      siaCode: string,
      progSpools: number[],
    ) => Observable<void>;
  };
  reason: string;
  formGroup: FormGroup;
  private destroy$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.formGroup = this._fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      otp: [''],
    });
  }

  onSign() {
    const { username, password, otp } = this.formGroup.value;
    this.data
      .sign(username, password, otp, this.data.licenseId, this.data.siaCode, this.data.progSpools)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => this.modal.hide(),
        error: (error: unknown) => {
          this.modal.hide();
          this.notificationService.showSweetAlert(
            NotificationType.ERROR,
            'Errore',
            (error as AppError).additionalInfo.find((x) => x.t === 'response')?.u,
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
