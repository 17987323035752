import { Injectable } from '@angular/core';
import { DocumentState, getActions } from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import {
  ActionsConfigRequest,
  ConfigurationsGroup,
  DocMetadata,
} from 'app/entities/ui-config/action/actions-configuration';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ActionsConfigurationService } from '../../ui-configuration/actions-configuration/actions-configuration.service';
import { FRLottiDocumentsService2 } from '../documents/documents.service';
import { ActionsByType } from './actions-by-type';

/**
 * Questa implementazione esiste unicamente per la nuova versione dei filtri che si
 * basa sugli stati applicativi (ngrx).
 */
@Injectable({
  providedIn: 'root',
})
export class FRLottiActionsService2 {
  private readonly massiveActionsList$ = new Observable<ActionObject[]>();
  private multiActionsList$ = new ReplaySubject<ActionObject[]>(1);
  private singleActionsList$ = new ReplaySubject<ActionObject[]>(1);
  private actionLoadingError$ = new BehaviorSubject<boolean>(false);

  constructor(
    private actionsConfigurationService: ActionsConfigurationService,
    private documentsService: FRLottiDocumentsService2,
    private store: Store<DocumentState>,
    private actionsByType: ActionsByType,
  ) {
    this.massiveActionsList$ = this.store.pipe(
      select(getActions),
      map((actions) => <ActionObject[]>actions),
    );
  }

  // azioni massive
  whenMassiveActionsList(): Observable<ActionObject[]> {
    return this.massiveActionsList$;
  }

  // azioni multiple
  whenMultiActionsList(): Observable<ActionObject[]> {
    return this.multiActionsList$.asObservable();
  }

  sendLoadingActionError(value: boolean) {
    this.actionLoadingError$.next(value);
  }

  whenLoadingActionError(): Observable<boolean> {
    return this.actionLoadingError$.asObservable();
  }

  sendMultiActionsList(actions: ActionObject[]) {
    this.multiActionsList$.next(actions);
  }

  // azioni singole
  whenSingleActionsList(): Observable<ActionObject[]> {
    return this.singleActionsList$.asObservable();
  }

  sendSingleActionsList(actions: ActionObject[]) {
    this.singleActionsList$.next(actions);
  }

  // doc � facoltativo solo quando richiamiamo il metodo per ottenere l'endpoint per la visualizzazione anteprima del pdf nel dettaglio
  requestSingleActionsConfig(
    licenseId: string,
    siaCode: string,
    sectionCode: string,
    keys: any,
  ): Observable<ConfigurationsGroup[]> {
    const newMetadataList: DocMetadata[] = [];

    Object.keys(keys).forEach((value) =>
      newMetadataList.push({
        keyCode: value,
        value: keys[value],
      }),
    );

    const body: ActionsConfigRequest = {
      progSpool: keys.progSpool,
      progBusta: keys.progBusta,
      docMetadata: newMetadataList,
    };

    return this.actionsConfigurationService.getSingleActionsByDocSeries(licenseId, siaCode, sectionCode, body).pipe(
      tap((config) => {
        this.sendSingleActionsList(this.mergeActionConfig(config, 'single'));
        this.sendLoadingActionError(false);
      }),
      catchError(() => {
        this.sendLoadingActionError(true);
        return EMPTY;
      }),
      take(1),
    );
  }

  requestMultiActionsConfig(licenseId: string, siaCode: string, sectionCode: string, body: ActionsConfigRequest[]) {
    this.actionsConfigurationService
      .getMultipleActionsByDocSeries(licenseId, siaCode, sectionCode, body)
      .pipe(
        tap((config) => this.sendMultiActionsList(this.mergeActionConfig(config, 'multi'))),
        take(1),
      )
      .subscribe();
  }

  mergeActionConfig(configuration: ConfigurationsGroup[], actionsType: 'single' | 'multi' | 'massive'): ActionObject[] {
    let actionsCatalog = [];
    switch (actionsType) {
      case 'single':
        actionsCatalog = _.cloneDeep(this.actionsByType.singleActions) as ActionObject[];
        break;
      case 'multi':
        actionsCatalog = _.cloneDeep(this.actionsByType.multiActions) as ActionObject[];
        break;
      case 'massive':
        actionsCatalog = _.cloneDeep(this.actionsByType.massiveActions) as ActionObject[];
        break;
    }

    const actionsList: ActionObject[] = [];
    if (configuration)
      for (let i = 0; i < configuration.length; i++) {
        const action: ActionObject = actionsCatalog.find((obj) => obj.code === configuration[i].code);
        if (action !== undefined) {
          Object.keys(configuration[i]).forEach((key) => {
            action[key] = configuration[i][key];
          });
          actionsList.push(action);
        }
      }

    return actionsList;
  }

  reset() {
    this.singleActionsList$.next(undefined);
  }
}
