import { Injectable } from '@angular/core';
import { SingleDocumentCallback } from './callbacks/single-document-callback';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SingleActionsCatalog {
  public readonly visualizeDocument = new ActionBuilder(SingleActionsCode.visualizeDocument, ActionCode.SHOW_PDF)
    .setActionURL(
      '/v1/Document/DisplayDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=false',
    )
    .build();

  public readonly visualizeStatusHistory = new ActionBuilder(
    SingleActionsCode.visualizeStatusHistory,
    ActionCode.SHOW_STATUS_HISTORY,
  )
    .setActionURL(
      '/api/v1/Action/ViewStatusHistory?progSpool={progSpool}&progBusta={progBusta}&idComunicazione={idComunicazione}',
    )
    .build();

  public readonly pdfDownload = new ActionBuilder(SingleActionsCode.pdfDownload, ActionCode.DOWNLOAD_PDF)
    .setActionURL(
      '/api/v1/Action/DownloadPDFDocument?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&download=true',
    )
    .setCallback(([url, progSpool, progBusta, docHash]) =>
      this.singleDocumentCallback.downloadPDF(url, progSpool, progBusta, docHash),
    )
    .build();

  public readonly downloadAttachments = new ActionBuilder(
    SingleActionsCode.downloadAttachments,
    ActionCode.DOWNLOAD_ATTACHMENTS,
  )
    .setActionURL('/api/v1/Action/DownloadAttachment/{idComunicazione}')
    .setCallback(([url, idComunicazione]) => this.singleDocumentCallback.downloadAttachments(url, idComunicazione))
    .build();

  public readonly visualizeMap = new ActionBuilder(SingleActionsCode.visualizeMap, ActionCode.VIEW_MAP).build();

  public readonly searchStatusRegisteredMail = new ActionBuilder(
    SingleActionsCode.searchStatusRegisteredMail,
    ActionCode.TRACKING_PROVIDER,
  )
    .setActionURL('/api/v1/Action/TrackingProvider')
    .build();
  constructor(private singleDocumentCallback: SingleDocumentCallback) {}
}

export enum SingleActionsCode {
  visualizeDocument = 'visualizeDocument',
  visualizeStatusHistory = 'visualizeStatusHistory',
  pdfDownload = 'pdfDownload',
  downloadAttachments = 'downloadAttachments',
  visualizeMap = 'visualizeMap',
  searchStatusRegisteredMail = 'searchStatusRegisteredMail',
}
