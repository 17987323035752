import { Filter, FullTextSearch, Paging } from '@ctel/gaw-commons';
import { MetadataEnum } from 'app/constants/metadata/metadata.enum';

export const sectionsFilterConfig: Filter = {
  metadata: MetadataEnum.SERIVCES,
  metadataDescription: 'Servizi',
  type: 'string',
  filterType: 'termsAggs',
  configData: {
    buckets: [],
  },
  value: {
    terms: [],
  },
};
export const tagsFilterConfig: Filter = {
  metadata: MetadataEnum.GAW30_TAGS_KW,
  metadataDescription: 'Tags',
  type: 'string',
  filterType: 'termsAggs',
  configData: {
    buckets: [],
  },
  value: {
    terms: [],
  },
};
export const serieDocFilterConfig: Filter = {
  metadata: MetadataEnum.DESCRIZIONE_SERIE_DOC_KW,
  metadataDescription: 'Tipi documento',
  type: 'string',
  filterType: 'termsAggs',
  configData: {
    buckets: [],
  },
  value: {
    terms: [],
  },
};

export const crossSearchFilterConfig: Filter[] = [serieDocFilterConfig, tagsFilterConfig, sectionsFilterConfig];

const search: FullTextSearch = {
  metadataList: [],
  value: '',
  fullText: false,
};

const paging: Paging = {
  offset: 0,
  docsPerPages: 25,
};

// const orderBy: OrderBy = {
// 	metadata: '',
// 	order: 'asc'
// };

export const crossSearchConfig: any = {
  filters: crossSearchFilterConfig,
  search,
  paging,
  orderBy: [],
};
