import { EventType } from 'app/core/common/event/event-type';

export class NotificationEventTypes {
  public static E_ALERT_SWEET = new EventType('E_ALERT_SWEET');
  public static E_ALERT_TOAST = new EventType('E_ALERT_TOAST');
  public static E_ALERT_SWEET_CLOSE = new EventType('E_ALERT_SWEET_CLOSE');
  public static E_MODAL = new EventType('E_MODAL');
  public static E_ACTIVITY_MODAL = new EventType('E_ACTIVITY_MODAL');
  public static E_SNACKBAR = new EventType('E_SNACKBAR');
  public static E_SNACKBAR_CLOSE = new EventType('E_SNACKBAR_CLOSE');
}
