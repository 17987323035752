/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { NumberValidators } from 'app/shared/components/dry/validator/number-validators';

@Directive({
  selector: '[max][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MaxValueValidatorDirective, multi: true }],
})
export class MaxValueValidatorDirective implements Validator {
  validator: ValidatorFn;

  @Input('max') set maxValue(maxValue: number | string) {
    let val = typeof maxValue === 'string' ? Number(maxValue) : maxValue;

    if (!val || isNaN(val)) val = Infinity;

    this.validator = NumberValidators.max(val);
  }

  registerOnValidatorChange(fn: () => void): void {}

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }
}
