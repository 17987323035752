import { Pipe, PipeTransform } from '@angular/core';
import { Locale } from 'app/core/common/utilities/locale';
import NumberFormatOptions = Intl.NumberFormatOptions;

export interface NumberLocaleOptions extends NumberFormatOptions {
  locale?: string;
}

@Pipe({
  name: 'numberLocale',
})
export class NumberLocalePipe implements PipeTransform {
  transform(value: number | bigint | null | undefined, option?: NumberLocaleOptions): string {
    if (value !== null && value !== undefined) {
      const locale = option && option.locale ? option.locale : Locale.currentLocale();
      return new Intl.NumberFormat(locale, option).format(value);
    }
    return '';
  }
}
