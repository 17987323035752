/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive, HostListener, Input } from '@angular/core';
import { NotificationType } from './notification-type';
import { SweetAlertInfo } from './sweet-alert/sweet-alert-info';
import { SweetAlertService } from './sweet-alert/sweet-alert.service';

@Directive({
  selector: '[dryAskConfirm]',
})
export class AskConfirmDirective {
  @Input() title = 'Sei sicuro?';
  @Input() message = "Sei sicuro di voler continuare con l'operazione?";
  constructor(private sweetAlertService: SweetAlertService) {}

  @Input() askConfirmSucess = () => {};
  @Input() askConfirmCancel = () => {};

  @HostListener('click', ['$event'])
  public confirm(): void {
    const data = new SweetAlertInfo(
      NotificationType.QUESTION,
      this.title,
      this.message,
      this.askConfirmSucess,
      this.askConfirmCancel,
    );
    this.sweetAlertService.show(data);
  }
}
