import { HomeFilter } from '@ctel/gaw-commons';
import { IFilter, IFilterStatus, IMetric } from '@ctel/search-filter-store';
import { createAction, props } from '@ngrx/store';

export const homeFiltersError = createAction('[HomeFilter Effect] homeFiltersError', props<{ error: any }>());

export const homeMetricsRequested = createAction('[Account Effect] homeMetricsRequested');

export const homeMetricsFetched = createAction(
  '[HomeFilter Effect] homeMetricsFetched',
  (metrics: IMetric[] = null, totalDocs = 0) => ({ payload: { metrics, totalDocs } }),
);

export const changeHomepageDateFilterRequested = createAction(
  '[Filter Component] changeHomepageDateFilterRequested',
  (homeFilters: HomeFilter[], section: string, doNavigate = true) => ({
    payload: { homeFilters, section, doNavigate },
  }),
);

export const switchHomeDateFilterAccount = createAction(
  '[Home Component] switchHomeDateFilterAccount',
  (filters: IFilter[], metrics: IMetric[], doNavigate = true, urlCommands: string[] = []) => ({
    payload: { filters, metrics, doNavigate, urlCommands },
  }),
);

export const resetHomepageDateFilterPayload = createAction(
  '[AccountCard Service] resetHomepageDateFilterPayload',
  (filterPayload: IFilterStatus, section: string, firstLoad: boolean) => ({
    payload: { filterPayload, section, firstLoad },
  }),
);
