import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringarrayToArrayPipe } from './stringarray-to-array.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [StringarrayToArrayPipe],
  exports: [StringarrayToArrayPipe],
  providers: [StringarrayToArrayPipe],
})
export class GawPipesModule {}
