import { FieldType } from 'app/shared/components/dry/field/field-type';
import { TextField } from 'app/shared/components/dry/field/specializations/text-field';

export class PasswordField extends TextField {
  type = FieldType.PASSWORD;

  toggle = false;
  confirm = false;

  constructor(id: string, name: string) {
    super(id, name);
  }

  canToggle(toggle: boolean) {
    this.toggle = toggle;
    return this;
  }

  toggleVisibility() {
    this.toggle = true;
    return this;
  }

  /**
   * @Deprecated
   * V 1.2 -> Not input
   */
  confirmRequired(confirm = true) {
    this.confirm = confirm;
    return this;
  }
}
