import { Injectable } from '@angular/core';
import { UploadsNotificationHttpService } from './uploads-notification-http.service';
import { BehaviorSubject, combineLatest, ReplaySubject, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UploadResults } from '../../../entities/uploads-notification/uploads-notification';

@Injectable()
export class UploadsNotificationService {
  private refresh$ = new BehaviorSubject(0);
  private uploadNotifications$ = new ReplaySubject<UploadResults[]>(1);

  constructor(private uploadsNotificationHttpService: UploadsNotificationHttpService) {
    combineLatest([this.refresh$, timer(1, 60000)])
      .pipe(switchMap(() => this.uploadsNotificationHttpService.getUploadNotifications()))
      .subscribe((result) => this.uploadNotifications$.next(result));
  }

  public get getScheduledUploadNotifications$() {
    return this.uploadNotifications$.asObservable();
  }

  public triggerUploadNotificationsGet() {
    this.refresh$.next(this.refresh$.getValue() + 1);
  }
}
