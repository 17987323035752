import { Component } from '@angular/core';
import { Upload } from 'app/entities/drag-and-drop-upload/upload';
import { FaIcons } from 'app/entities/fa-icons/fa-icons';
import * as _ from 'lodash';

@Component({
  selector: 'hub-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent {
  public faIcons = FaIcons;
  currentUpload: Upload;
  dropzoneActive = false;

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    const filesIndex = _.range(fileList.length);

    _.each(filesIndex, (idx) => {
      this.currentUpload = new Upload(fileList[idx]);
    });
  }
}
