/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative al dettaglio documento
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrackingStatusHistory } from '@ctel/gaw-commons';
import { Transfer, TransferService, transferHandler } from '@ctel/transfer-manager';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AnomalyStatusHistoryItem } from 'app/entities/anomalies/anomaly-status-history-item';
import { LoadPdfModel } from 'app/entities/anomalies/load-pdf-model';
import { AppConfig } from 'app/entities/config/app-config';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActionsHttpService {
  private jsonContentType = 'application/json';
  // host
  private documentGedHost: string;
  private documentsExportHost: string;
  private docDetailsHost: string;
  private gawpassReadHost: string;
  private gawpassWriteHost: string;

  private readonly jsonOptions: object;
  private actionLoading$ = new BehaviorSubject<boolean>(false);

  private displayDocument: string;
  private uploadDocument: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private transferService: TransferService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));

    this.jsonOptions = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', null, true);
  }

  httpHostInit(appConfig: AppConfig) {
    /* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
    this.docDetailsHost = appConfig.docDetails.http.host;
    /* ENDPOINTS DOCUMENT GED ------------------------------------------------------------------------------------------------------- */
    this.documentGedHost = appConfig.docDetails.http.host;
    /* ENDPOINTS DOCUMENT EXPORT ---------------------------------------------------------------------------------------------------- */
    this.documentsExportHost = appConfig.documentsExport.http.host;
    // this.hasAttachments = `${this.docDetailsHost}/v1/Document/GEDINVOICE/HasAttachments?progSpool={progSpool}&progBusta={progBusta}&docHash={docHash}&documentChannelType={account}`;

    /* ENDPOINTS GAWPEC --------------------------------------------------------------------------------------------------- */
    this.gawpassReadHost = appConfig.gawPassRead.http.host;
    this.gawpassWriteHost = appConfig.gawPassWrite.http.host;

    this.displayDocument = `${this.gawpassReadHost}/api/v1/Action/ScaricaPDF/{progSpool}/{progBusta}/{download}`;
    // TODO da inserire URL corretta
    this.uploadDocument = `${this.gawpassWriteHost}/api/v1/Anomalie/LoadPdf/{IdSospeso}/{NomeFlusso}/{ProgSpool}/{Progressivo}`;
  }

  whenChangesHistory(
    actionUrl: string,
    boxId: string,
    licenseId: string,
    siaCode: string,
  ): Observable<TrackingStatusHistory[]> {
    this.setLoadingAction(true);
    const url =
      this.gawpassReadHost +
      actionUrl.replace('{IdScatola}', boxId).replace('{Sia}', siaCode).replace('{IdLicenza}', licenseId);

    return this.http.get<TrackingStatusHistory[]>(url, this.jsonOptions).pipe(
      tap({
        next: () => this.setLoadingAction(false),
        error: () => this.setLoadingAction(false),
      }),
    );
  }

  whenChangesAnomalyHistory(actionUrl: string, idSospeso: string): Observable<AnomalyStatusHistoryItem[]> {
    this.setLoadingAction(true);
    const url = this.gawpassReadHost + actionUrl.replace('{idSospeso}', idSospeso);

    return this.http.get<AnomalyStatusHistoryItem[]>(url, { headers: new HttpHeaders({ log: 'true' }) }).pipe(
      tap({
        next: () => this.setLoadingAction(false),
        error: () => this.setLoadingAction(false),
      }),
    );
  }

  // MULTI: download excel doc selezionati
  whenExcel(
    actionUrl: string,
    body: unknown,
    format?: 'xlsx' | 'xls' | 'csv',
    sectionCode?: string,
  ): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    const url = this.documentsExportHost + actionUrl;
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        opts['params'] = {
          sectionCode,
          format,
        };
        return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  // MASSIVA: download excel di tutta la serie documentale/sezione
  whenExcelAll(actionUrl: string, body: unknown): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    const url = this.gawpassReadHost + actionUrl;
    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((value) => {
        const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
        return this.http.post(url, body, opts).pipe(transferHandler(this.transferService));
      }),
    );
  }

  public whenLoadingAction(): Observable<boolean> {
    return this.actionLoading$.asObservable();
  }

  public setLoadingAction(value: boolean) {
    this.actionLoading$.next(value);
  }

  whenCheckPass(actionUrl: string, body: unknown): Observable<unknown> {
    this.setLoadingAction(true);
    const headers: HttpHeaders = new HttpHeaders();
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', headers, true);
    const url = this.gawpassWriteHost + actionUrl;
    return this.http.put<unknown>(url, body, options).pipe(
      tap({
        next: () => {
          this.setLoadingAction(false);
        },
        error: () => this.setLoadingAction(false),
      }),
    );
  }

  whenDownloadPdf(
    progSpool: string,
    progBusta: string,
    download: boolean,
    idFilePathPdf: string,
  ): Observable<Transfer> {
    const url = this.displayDocument
      .replace('{progSpool}', progSpool)
      .replace('{progBusta}', progBusta)
      .replace('{download}', String(download));
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob', null, true);
    // return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
    // 	switchMap((value) => {
    // 		const opts = this.fullScreenSpinnerService.mergeSpinnerHeaders(options, value);
    // 		return this.http.get(url, {
    // 			...options,
    // 			params: { IdFilePath: idFilePathPdf }
    // 		}).pipe(transferHandler(this.transferService));
    // 	})
    // );
    return this.http
      .get(url, {
        ...options,
        params: { IdFilePath: idFilePathPdf },
      })
      .pipe(transferHandler(this.transferService));
  }

  whenUploadPdf(
    progSpool: string,
    progBusta: string,
    idSospeso: string,
    nomeLotto: string,
    idFilePath: string,
    file: File,
    filename: string,
  ): Observable<LoadPdfModel> {
    const formData: FormData = new FormData();
    formData.append('uploadedFile', file, filename);
    const url = this.uploadDocument
      .replace('{IdSospeso}', idSospeso)
      .replace('{NomeFlusso}', nomeLotto)
      .replace('{ProgSpool}', progSpool)
      .replace('{Progressivo}', progBusta);
    return this.http.post<LoadPdfModel>(url, formData, {
      params: { idFilePath: `${idFilePath}` },
      headers: new HttpHeaders({ log: 'true' }),
    });
  }

  whenChangeStatusAnomaly(actionUrl: string, body: unknown) {
    this.setLoadingAction(true);
    const headers: HttpHeaders = new HttpHeaders();
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json', headers, true);
    const url = this.gawpassWriteHost + actionUrl;
    return this.http.put<unknown>(url, body, options).pipe(
      tap({
        next: () => {
          this.setLoadingAction(false);
        },
        error: () => this.setLoadingAction(false),
      }),
    );
  }
}
