import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/core/common/config/config.service';
import { FullScreenSpinnerService } from 'app/core/common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from 'app/core/common/utilities/custom-http-options';
import { AppConfig } from 'app/entities/config/app-config';
import {
  RegimeMinimiOperationResponse,
  RegimeMinimiRecordsResponse,
  RegimeMinimiSupplier,
} from 'app/entities/configuration/regime-minimi';
import { FormFilter } from 'app/shared/components/table/form-filter';
import { Observable } from 'rxjs';

/**
 * Servizio per la gestione degli endpoint e delle chiamate http relative alla pagina regime dei minimi
 */
@Injectable({
  providedIn: 'root',
})
export class RegimeMinimiHttpService {
  private readonly jsonContentType = 'application/json';

  private documentHost: string;

  private getRegimeMinimi: string;
  private updateRegimeMinimi: string;
  private deleteRegimeMinimi: string;
  private insertRegimeMinimi: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.documentHost = appConfig.docDetails.http.host;
    this.getRegimeMinimi = `${this.documentHost}/v1/Supplier/Suppliers?idLicense={licenseId}&codSia={siaCode}&pageIndex={pageIndex}&pageSize={pageSize}&orderField={orderField}&orderType={orderType}`;
    this.updateRegimeMinimi = `${this.documentHost}/v1/Supplier/UpdateSupplier?idLicense={licenseId}&codSia={siaCode}`;
    this.deleteRegimeMinimi = `${this.documentHost}/v1/Supplier/DeleteSupplier?idLicense={licenseId}&codSia={siaCode}`;
    this.insertRegimeMinimi = `${this.documentHost}/v1/Supplier/AddSupplier?idLicense={licenseId}&codSia={siaCode}`;
  }
  whenRegimeMinimiRecordsAndCount(
    licenseId: string,
    siaCode: string,
    pageIndex: number,
    pageSize: number,
    orderField: string,
    orderType: string,
    businessName: FormFilter,
    pIva: FormFilter,
    cf: FormFilter,
  ): Observable<RegimeMinimiRecordsResponse> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    let url = this.getRegimeMinimi
      .replace('{licenseId}', licenseId)
      .replace('{siaCode}', siaCode)
      .replace('{orderType}', orderType)
      .replace('{orderField}', orderField)
      .replace('{pageIndex}', pageIndex + '')
      .replace('{pageSize}', pageSize + '');
    if (businessName !== undefined && businessName.value !== '')
      url = url + '&businessNameFilter=' + businessName.value;

    if (pIva !== undefined && pIva.value !== '') url = url + '&vatCodeFilter=' + pIva.value;

    if (cf !== undefined && cf.value !== '') url = url + '&taxCodeFilter=' + cf.value;

    return this.http.get<RegimeMinimiRecordsResponse>(url, options);
  }

  whenActionOnRegimeMinimi(
    licenseId: string,
    siaCode: string,
    body: RegimeMinimiSupplier,
  ): Observable<RegimeMinimiOperationResponse> {
    const options = CustomHttpOptions.getHttpOptions(this.jsonContentType, 'json');
    const url = this.updateRegimeMinimi.replace('{licenseId}', licenseId).replace('{siaCode}', siaCode);
    return this.http.post<RegimeMinimiOperationResponse>(url, body, options);
  }
}
