<div>
  <ul>
    <li *ngFor="let upload of uploadNotifications">
      <div class="wrapper dropdown-item">
        <div class="left">
          <label [style.color]="upload.error ? '#d9534f' : ''">
            {{ getCompanyName(upload.licenseId, upload.siaCode) }} - {{ upload.fileName }} -
            <ng-container *ngIf="upload.message">{{ upload.message }}</ng-container>
          </label>
          <div class="progress" *ngIf="!upload.complete && !upload.error">
            <div class="indeterminate"></div>
          </div>
        </div>
      </div>
    </li>
    <li *ngIf="uploadNotifications.length === 0">
      <div class="dropdown-item"><b>Non ci sono upload</b></div>
    </li>
  </ul>
</div>
