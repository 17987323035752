import { Injectable } from '@angular/core';
import { UploadPdfHttpService } from './upload-pdf-http.service';

@Injectable()
export class UploadPdfService {
  constructor(private uploadPdfService: UploadPdfHttpService) {}

  public uploadPdf(documentPa: string, id, sia, grafica) {
    return this.uploadPdfService.uploadPdf(id, sia, grafica, documentPa);
  }
}
