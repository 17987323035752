import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ElementAttributes } from 'app/core/common/placeholder/loading-placeholder/base-loading-placeholder/element-attributes';

@Component({
  selector: 'app-text-placeholder',
  templateUrl: './text-placeholder.component.html',
  styleUrls: ['./text-placeholder.component.scss'],
})
export class TextPlaceholderComponent implements AfterViewInit {
  @Input() margin = 20;
  @Input() style: {
    [key: string]: string;
  } = {};

  @ViewChild('componentContainer', { read: ViewContainerRef, static: false }) insertionPoint;

  @ViewChild('placeholderContainer', { static: false }) placeholderContainer: ElementRef;

  public elementAttributes: ElementAttributes[] = [];
  public uniqueId: number;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.uniqueId = Math.floor(Math.random() * 10000000);
    const containerWidth = (this.placeholderContainer.nativeElement as HTMLElement).offsetWidth;
    const containerHeight = (this.placeholderContainer.nativeElement as HTMLElement).offsetHeight;
    this.buildDefaultPlaceholder(containerWidth, containerHeight);
    this.changeDetectorRef.detectChanges();
  }

  private buildDefaultPlaceholder(containerWidth: number, containerHeight: number) {
    const margin = this.margin;

    const maxWidthVariation = 100;
    const blockElements = 6;

    const elementHeight = 11;
    const elementMarginBottom = 11;

    const elementNumber = Math.floor((containerHeight - margin) / (elementHeight + elementMarginBottom));

    for (let i = 0; i < elementNumber; i++) {
      let elementWidth: number;
      if (i % blockElements === 0 && i > 0)
        // Riga bianca per simulare i paragrafi
        elementWidth = 0;
      else {
        const widthVariation = Math.random();
        elementWidth = containerWidth - 2 * margin - maxWidthVariation * widthVariation;
      }

      this.elementAttributes.push(
        new ElementAttributes(
          margin,
          margin + i * (elementHeight + elementMarginBottom),
          elementWidth,
          elementHeight,
          elementMarginBottom,
          margin,
        ),
      );
    }
  }
}
