import { Directive, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=file]',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FileValueAccessorDirective, multi: true }],
})
export class FileValueAccessorDirective implements ControlValueAccessor {
  value: unknown;

  @HostListener('change', ['$event'])
  changeEventHandler(event) {
    this.onChange(event.target.files);
  }

  @HostListener('blur', [])
  blurEventHandler() {
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (files: unknown) => null;

  onTouched = () => null;

  writeValue() {
    return;
  }

  registerOnChange(fn: (value: unknown) => unknown) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => unknown) {
    this.onTouched = fn;
  }
}
