import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { HistoryObject } from 'app/core/common/entities/documents/actions/history-item';
import { AppError, ErrorTypes } from 'app/core/common/error';
import { ActionConfirmationModalComponent } from 'app/core/common/modals/action-confirmation-modal/action-confirmation-modal.component';
import { ActionOriginalPdfModalComponent } from 'app/core/common/modals/action-original-pdf/action-original-pdf-modal.component';
import { ActionProtocolModalComponent } from 'app/core/common/modals/action-protocol-modal/action-protocol-modal.component';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { HistoryActionModalComponent } from 'app/core/common/modals/history-action-modal.component/history-action-modal.component';
import { HistoryOutcomesModalComponent } from 'app/core/common/modals/history-outcomes-modal/history-outcomes-modal.component';
import { HistoryOutcomesPayableModalComponent } from 'app/core/common/modals/history-outcomes-payable-modal/history-outcomes-payable-modal.component';
import { SendAnalogicCopyData } from 'app/core/common/modals/send-analogic-copy-modal/send-analogic-copy-data';
import { SendAnalogicCopyModalComponent } from 'app/core/common/modals/send-analogic-copy-modal/send-analogic-copy-modal.component';
import { SignDocModalComponent } from 'app/core/common/modals/sign-doc-modal/sign-doc-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { Extension } from 'app/core/common/utilities/file/file';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionType } from 'app/entities/ui-config/action/action-type.enum';
import { ActionConfigItem } from 'app/entities/ui-config/actions-config';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { Observable, of } from 'rxjs';
import { catchError, delay, take, tap } from 'rxjs/operators';
import { CheckSdiAndResend } from '../../entities/actions/check-sdi-and-resend/check-sdi-and-resend';
import { DocumentDetailsService } from '../document-details/document-details.service';
import { DocumentsService } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';
import { ActionsService } from './actions.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class ExecuteActionsService {
  // private currentServiceActions$ = new ReplaySubject<ActionConfigItem[]>(1);
  // private elasticDocumentId$ = new BehaviorSubject<string>('');

  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private actionsService: ActionsService,
    private documentDetailsService: DocumentDetailsService,
    private companiesService: CompaniesService,
    private documentsService: DocumentsService,
    private router: Router,
    private transferService: TransferService,
  ) {}

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  public executeSingleAction(
    action: ActionObject | ActionConfigItem,
    keys: unknown,
    accountType: string,
    serviceId: string,
    refreshDocuments: boolean,
    sectionCode?: string,
  ) {
    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    let body: unknown;

    const basicPayload = {
      progSpool: keys['progSpool'],
      progBusta: keys['progBusta'],
      docHash: keys['hashDocKey'],
      ctelElasticDocumentId: keys['ctelElasticDocumentId'],
      ctelDocSeriesId: keys['ctelDocSeriesId'],
    };

    switch (actionCode) {
      case ActionCode.SHOW_ORIGINAL_PDF:
        basicPayload['account'] = accountType;
        this.notificationService.showModal(NotificationType.XXL, {
          title: 'Pdf originale / da foglio di stile',
          customFooter: false,
          childComponent: ActionOriginalPdfModalComponent,
          childData: basicPayload,
          disableClickOutside: true,
        });
        break;
      case ActionCode.HIDE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, [keys], true)
          .pipe(
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(NotificationType.SUCCESS, 'Nascondi documenti', result.message);
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Nascondi documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Nascondi documenti',
                'Errore imprevisto',
              );
            },
          });
        break;

      case ActionCode.RESTORE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, [keys], false)
          .pipe(
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Ripristina documenti',
                  result.message,
                );
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Ripristina documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Ripristina documenti',
                'Errore imprevisto',
              );
            },
          });
        break;

      case ActionCode.SEND_ANALOGIC_COPY: {
        const data: SendAnalogicCopyData = {
          channelType: Number(serviceId),
          documentId: {
            ctelDocSeriesId: basicPayload.ctelDocSeriesId,
            ctelElasticDocumentId: basicPayload.ctelElasticDocumentId,
            docHash: basicPayload.docHash,
            progBusta: basicPayload.progBusta,
            progSpool: basicPayload.progSpool,
          },
        };

        this.notificationService.showModal(NotificationType.CUSTOM, {
          title: 'Invia copia analogica',
          childComponent: SendAnalogicCopyModalComponent,
          customFooter: true,
          childData: data,
          disableClickOutside: false,
        });

        break;
      }
      // azione singola
      case ActionCode.DOWNLOAD_PDF:
        this.actionsHttpService
          .whenSinglePdf(actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.docHash)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
                new FileSaver(blob).saveAs(r.name, FileSaverExtension.PDF);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;
      // azione singola
      case ActionCode.DOWNLOAD_XML:
        this.actionsHttpService
          .whenXml(
            actionUrl,
            basicPayload.progSpool,
            basicPayload.progBusta,
            basicPayload.docHash,
            '1',
            accountType === SectionCode.RECEIVABLE ? 'ATTIVO' : 'PASSIVO',
          )
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/xml' });
                new FileSaver(blob).saveAs(r.name, FileSaverExtension.XML);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;
      // azione singola
      case ActionCode.DOWNLOAD_ATTACHMENTS:
      case ActionCode.DOWNLOAD_ATTACHMENTS_RECEIVABLE:
      case ActionCode.DOWNLOAD_ATTACHMENTS_PAYABLE:
        this.actionsHttpService
          .whenAttachments(actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.docHash)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((d: Transfer) => {
            if (d !== null)
              if (TransferUtils.isHttpResponse(d.originatingEvent)) {
                const type = d.originatingEvent.body.type;
                const blob = new Blob([d.originatingEvent.body], { type });
                let ext = FileSaverExtension.PDF;
                if (type === 'application/zip') ext = FileSaverExtension.ZIP;

                new FileSaver(blob).saveAs(d.name, ext);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;
      //	azione singola
      case ActionCode.SHOW_OUTCOMES_HISTORY_PAYABLE:
        this.actionsHttpService
          .whenOutcomesHistoryPayable(
            actionUrl,
            basicPayload.progSpool,
            basicPayload.progBusta,
            this.companiesService.getCurrentCompanyValue().licenseId,
            this.companiesService.getCurrentCompanyValue().siaCode,
          )
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result) {
              this.actionsService.sendHistoryOutcomesPayable(result);
              this.notificationService.showModal(NotificationType.LARGE, {
                title: 'Storico esiti',
                childComponent: HistoryOutcomesPayableModalComponent,
                customFooter: false,
                disableClickOutside: false,
              });
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico esiti non trovato');
          });
        break;

      // azione singola
      case ActionCode.SHOW_CHANGES_HISTORY:
      case ActionCode.SHOW_OUTCOMES_HISTORY:
      case ActionCode.SHOW_STATUS_HISTORY:
        this.actionsHttpService
          .whenChangesHistory(actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.docHash)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result) {
              let title = '';
              switch (actionCode) {
                case ActionCode.SHOW_CHANGES_HISTORY:
                  title = 'Storico modifiche';
                  this.actionsService.sendHistory(result as HistoryObject[]);
                  this.notificationService.showModal(NotificationType.GENERAL, {
                    title,
                    customFooter: false,
                    childComponent: HistoryActionModalComponent,
                    disableClickOutside: false,
                  });
                  break;
                case ActionCode.SHOW_OUTCOMES_HISTORY:
                  title = 'Storico esiti';
                  this.actionsService.sendHistory(result as HistoryObject[]);
                  this.notificationService.showModal(NotificationType.LARGE, {
                    title,
                    childComponent: HistoryOutcomesModalComponent,
                    customFooter: false,
                    disableClickOutside: false,
                  });
                  break;
                // case ActionCode.SHOW_OUTCOMES_HISTORY_PAYABLE:
                // 	title = 'Storico esiti';
                // 	this.actionsService.sendHistoryOutcomesPayable(result as HistoryOutcomesPayableItem[]);
                // 	this.notificationService.showModal(NotificationType.LARGE, {
                // 		title,
                // 		childComponent: HistoryOutcomesPayableModalComponent,
                // 		customFooter: false,
                // 		disableClickOutside: false
                // 	});
                // 	break;
                case ActionCode.SHOW_STATUS_HISTORY:
                  title = 'Storico stati';
                  this.actionsService.sendHistory(result as HistoryObject[]);
                  this.notificationService.showModal(NotificationType.GENERAL, {
                    title,
                    customFooter: false,
                    childComponent: HistoryActionModalComponent,
                    childData: basicPayload,
                    disableClickOutside: false,
                  });
                  break;
              }
            } else this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Storico non trovato');
          });
        break;
      // azione singola
      case ActionCode.SIGN_DOC:
        body = { documents: [basicPayload] };
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Inserimento dati firmatario',
          customFooter: true,
          childData: {
            payload: body,
            actionUrl,
            actionType: ActionType.SINGLE,
            refreshDocuments,
          },
          childComponent: SignDocModalComponent,
          disableClickOutside: true,
        });
        break;
      // azione singola
      case ActionCode.CANCEL_ELAB: {
        body = [basicPayload];

        const cancelElabAction$ = () => {
          const params = (<ActionObject>action).params as { channel: string; clientId: string };
          return this.actionsHttpService
            .whenCancelInvoice(actionUrl, body as unknown[], params.channel, params.clientId)
            .pipe(
              catchError((err: unknown) => this.catchError(err as AppError)),
              delay(1000),
              tap(() => {
                // per evitare Search se sono nella pagina di dettaglio
                if (refreshDocuments) this.documentsService.refreshDocuments();
              }),
              tap((result) => {
                if (result !== null) {
                  let isError = false;
                  for (let i = 0; i < result.length; i++)
                    if (result[i].esitoOK === false) {
                      isError = true;
                      break;
                    }

                  if (isError)
                    this.notificationService.showSweetAlert(NotificationType.INFO, 'Documento non annullabile', '');
                  else {
                    // se sono nel dettaglio, l'azione fa il refresh della lista azioni
                    if (!refreshDocuments) this.documentDetailsService.sendRefreshDocumentDetails();

                    this.notificationService.showSweetAlert(
                      NotificationType.SUCCESS,
                      'Richiesta annullamento' + ' eseguita correttamente',
                      '',
                    );
                  }
                } else this.notificationService.showSweetAlert(NotificationType.INFO, 'Documento non annullabile', '');
              }),
            );
        };

        //TODO TAM: deve diventare uno sweet alert
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Annulla',
          childComponent: ActionConfirmationModalComponent,
          customFooter: true,
          childData: {
            action: cancelElabAction$,
            title: 'Annulla',
            docCount: 1,
          },
          disableClickOutside: false,
        });
        break;
      }
      // azione singola
      case ActionCode.EDIT_INVOICE:
      case ActionCode.EDIT_INVOICE_WITHOUT_CANCEL: {
        let docHash: string;
        // nel caso in cui dochash sia vuoto
        // TODO karandip docHash da verificare - 15-01-2020
        if (basicPayload.docHash) docHash = basicPayload.docHash;
        else docHash = ' ';

        this.documentDetailsService.sendCurrentProgSpoolAndBustaService([
          basicPayload.progSpool,
          basicPayload.progBusta,
          basicPayload.docHash,
          basicPayload,
        ]);
        this.documentDetailsService.sendDocumentListUrl(this.router.url);
        const url = [
          '/gawfe/editinvoice/',
          serviceId,
          '/',
          this.companiesService.getCurrentCompanyValue().licenseId,
          '/',
          this.companiesService.getCurrentCompanyValue().siaCode,
          '/',
          basicPayload.progSpool,
          '/',
          basicPayload.progBusta,
          '/',
          docHash,
          '/',
          basicPayload.ctelElasticDocumentId,
          '/',
          basicPayload.ctelDocSeriesId,
        ].join('');
        this.router.navigate([url]).then();
        break;
      }

      // PASSIVO	--------------------------------------------------------------------------------------------------------
      // azione singola
      case ActionCode.FORWARD_ADD_SUPPLIER:
        body = {
          progSpool: basicPayload.progSpool,
          progBusta: basicPayload.progBusta,
          hashDoc: basicPayload.docHash,
        };
        this.actionsHttpService
          .whenForwardAddSupplier(actionUrl, body)
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((d) => {
            if (d !== null)
              this.notificationService.showSweetAlert(
                NotificationType.SUCCESS,
                'Avanzamento eseguito correttamente.',
                '',
              );
            else this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore nell'avanzamento", '');
          });
        break;
      // azione singola
      case ActionCode.FORWARD_NO_SUPPLIER:
        body = {
          progSpool: basicPayload.progSpool,
          progBusta: basicPayload.progBusta,
          hashDoc: basicPayload.docHash,
        };
        this.actionsHttpService
          .whenForwardNoSupplier(actionUrl, body)
          .pipe(catchError((err: unknown) => this.catchError(err as AppError)))
          .subscribe((d) => {
            if (d !== null)
              this.notificationService.showSweetAlert(
                NotificationType.SUCCESS,
                'Avanzamento eseguito correttamente.',
                '',
              );
            else
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                "Errore nell'avanzamento del documento",
                '',
              );
          });
        break;
      // azione singola
      case ActionCode.EXPORT_TO_MANAGEMENT: {
        const successfulAction = (response: { esitoOK: boolean; message: string }) => {
          if (response.esitoOK)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Richiesta di esportazione verso il gestionale eseguita correttamente',
              '',
            );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              "Errore durante l'esecuzione dell'azione",
              response.message,
            );
        };
        const executeAction$ = () =>
          this.actionsHttpService.whenExportToManagementSW(actionUrl, [basicPayload]).pipe(
            take(1),
            tap((response) => successfulAction(response)),
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
            catchError((err: unknown) => this.catchError(err as AppError)),
            tap((res) => {
              if (res === null)
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Documento non esportato');
              else {
                // se sono nel dettaglio doc
                if (!refreshDocuments) this.documentDetailsService.sendRefreshDocumentDetails();

                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Documento esportato correttamente',
                  '',
                );
              }
            }),
          );

        //TODO TAM: deve diventare uno sweet alert
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Esporta verso gestionale',
          childComponent: ActionConfirmationModalComponent,
          customFooter: true,
          childData: {
            action: executeAction$,
            title: 'Esporta verso gestionale',
            docCount: 1,
          },
          disableClickOutside: false,
        });
        break;
      }
      // azione singola
      case ActionCode.PROTOCOL:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 0;
        basicPayload['edit'] = false;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Inserisci dati protocollo',
          customFooter: true,
          childComponent: ActionProtocolModalComponent,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: true,
        });
        break;
      // azione singola
      case ActionCode.EDIT_PROTOCOL:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 0;
        basicPayload['edit'] = true;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Modifica dati protocollo ',
          childComponent: ActionProtocolModalComponent,
          customFooter: true,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: true,
        });
        break;
      case ActionCode.PROTOCOL_RC_FILE:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 1;
        basicPayload['edit'] = false;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Inserisci dati protocollo e Reverse Charge',
          childComponent: ActionProtocolModalComponent,
          customFooter: true,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: true,
        });
        break;
      case ActionCode.PROTOCOL_RC_METADATA:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 2;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Inserisci dati protocollo e Reverse Charge',
          customFooter: true,
          childComponent: ActionProtocolModalComponent,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: true,
        });
        break;
      case ActionCode.RC_FILE:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 3;
        basicPayload['edit'] = false;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Inserisci dati Reverse Charge',
          customFooter: true,
          childComponent: ActionProtocolModalComponent,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: true,
        });
        break;
      case ActionCode.RC_METADATA:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 4;
        basicPayload['edit'] = false;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Inserisci dati Reverse Charge',
          childComponent: ActionProtocolModalComponent,
          customFooter: true,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: true,
        });
        break;
      //TODO TAM: deve diventare uno sweet alert
      case ActionCode.CONFIRM_REGISTRATION:
        basicPayload['actionUrl'] = actionUrl;
        basicPayload['mode'] = 5;
        basicPayload['edit'] = false;
        this.notificationService.showModal(NotificationType.LARGE, {
          title: 'Conferma registrazione su gestionale',
          childComponent: ActionProtocolModalComponent,
          customFooter: true,
          childData: {
            refreshDocuments,
            basicPayload,
          },
          disableClickOutside: false,
        });
        break;
      case ActionCode.FORWARD_WITH_COPIES:
        this.actionsHttpService
          .whenForwardWithCopies(actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.docHash, {})
          .pipe(
            catchError((err: unknown) => this.catchError(err as AppError)),
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
          )
          .subscribe((d) => {
            if (d !== null) {
              if (!refreshDocuments) this.documentDetailsService.sendRefreshDocumentDetails();

              this.notificationService.showSweetAlert(
                NotificationType.SUCCESS,
                'Avanzamento eseguito correttamente.',
                '',
              );
            } else this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore nell'avanzamento", '');
          });
        break;

      case ActionCode.FORWARD_WITHOUT_COPIES:
        this.actionsHttpService
          .whenForwardWithoutCopies(actionUrl, basicPayload.progSpool, basicPayload.progBusta, basicPayload.docHash, {})
          .pipe(
            catchError((err: unknown) => this.catchError(err as AppError)),
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
          )
          .subscribe((d) => {
            if (d !== null) {
              if (!refreshDocuments) this.documentDetailsService.sendRefreshDocumentDetails();

              this.notificationService.showSweetAlert(
                NotificationType.SUCCESS,
                'Avanzamento eseguito correttamente.',
                '',
              );
            } else this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore nell'avanzamento", '');
          });
        break;

      case ActionCode.EXPORT_TO_FTP: {
        const exportBody = [{ progSpool: basicPayload.progSpool, progBusta: basicPayload.progBusta }];
        this.exportToFtp(actionUrl, exportBody);
        break;
      }
      case ActionCode.EXCLUDE_FROM_CONS: {
        const excludeDocuments = [
          {
            progSpool: basicPayload.progSpool,
            progBusta: basicPayload.progBusta,
            documentId: basicPayload.ctelElasticDocumentId,
            idSerieDoc: basicPayload.ctelDocSeriesId,
          },
        ];
        const excludeFromCons = () => this.excludeFromCons(actionUrl, excludeDocuments, refreshDocuments);

        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          '',
          'La fattura selezionata sarà esclusa dalla Conservazione Digitale. Vuoi procedere?',
          () => excludeFromCons(),
        );

        break;
      }
      case ActionCode.DOWNLOAD_ESITI_PDF:
        body = [keys['idComunicazione35']];
        this.downloadEsiti(actionUrl, body as string[], Extension.PDF);
        break;
      case ActionCode.DOWNLOAD_ESITI_XML:
        body = [keys['idComunicazione35']];
        this.downloadEsiti(actionUrl, body as string[], Extension.XML);
        break;
      case ActionCode.DOWNLOAD_ESITI_EXCEL: {
        const docCount = keys['length'];
        const showModal = () => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: 'Esporta risultati in Excel',
            customFooter: true,
            childComponent: ExcelFormatModalComponent,
            childData: {
              backButton: true,
              action,
              actionType: 'single',
              keys,
              service: 'HUBFE-Esiti',
              sectionCode,
            },
          });
        };
        if (docCount > 50000)
          this.notificationService.showSweetAlert(
            NotificationType.QUESTION,
            'Numero massimo di documenti superato.',
            "Procedere all'esportazione dei primi 50000 documenti?",
            showModal,
          );
        else showModal();

        break;
      }
      case ActionCode.EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Documento da Conservare',
          'La fattura selezionata sarà spostata nella sezione "Esportate verso gestionale e da protocollare" per essere protocollata e conservata. Vuoi procedere?',
          () =>
            this.actionsHttpService
              .whenExportToManagementFromUnpreserved(actionUrl, [basicPayload])
              .pipe(
                take(1),
                catchError((err: unknown) => this.catchError(err as AppError)),
                tap((res) => {
                  if (res === null)
                    this.notificationService.showSweetAlert(
                      NotificationType.ERROR,
                      'Errore',
                      'Documento non esportato',
                    );
                  else {
                    // se sono nel dettaglio doc
                    if (!refreshDocuments) this.documentDetailsService.sendRefreshDocumentDetails();

                    this.notificationService.showSweetAlert(
                      NotificationType.SUCCESS,
                      'Documento esportato correttamente',
                      '',
                    );
                  }
                }),
                delay(1000),
                tap(() => {
                  if (refreshDocuments) this.documentsService.refreshDocuments();
                }),
              )
              .subscribe(),
        );
        break;
    }
  }

  // ESECUZIONE AZIONI MULTIPLE
  public executeMultiAction(
    action: ActionObject | ActionConfigItem,
    keys: unknown[],
    classification: string,
    account: string,
  ) {
    let body: unknown;
    const docCount = keys.length;

    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    switch (actionCode) {
      // multi
      case ActionCode.DOWNLOAD_EXCEL: {
        const accountType = account === 'payable' ? 'ExportExcelCicloPassivo' : 'ExportExcelCicloAttivo';
        // Modal della scelta del formato dell'excel
        const showModal = () => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: 'Esporta risultati in Excel',
            customFooter: true,
            childComponent: ExcelFormatModalComponent,
            childData: {
              backButton: true,
              action,
              actionType: 'multiple',
              accountType,
              classification,
              keys,
              service: 'GAWFE',
            },
            disableClickOutside: false,
          });
        };

        if (docCount > 50000)
          this.notificationService.showSweetAlert(
            NotificationType.QUESTION,
            'Numero massimo di documenti superato.',
            `Procedere all'esportazione dei primi 50000 documenti?
									\nL'operazione potrebbe richiedere alcuni minuti`,
            showModal,
          );
        else showModal();

        break;
      }
      // multi
      case ActionCode.HIDE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, keys, true)
          .pipe(
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(NotificationType.SUCCESS, 'Nascondi documenti', result.message);
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Nascondi documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Nascondi documenti',
                'Errore imprevisto',
              );
            },
          });
        break;
      // multi
      case ActionCode.RESTORE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, keys, false)
          .pipe(
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Ripristina documenti',
                  result.message,
                );
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Ripristina documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Ripristina documenti',
                'Errore imprevisto',
              );
            },
          });
        break;
      // multi
      case ActionCode.CANCEL_ELAB: {
        const params = (<ActionObject>action).params as { channel: string; clientId: string };
        const cancelElabAction$ = () =>
          this.actionsHttpService.whenCancelInvoice(actionUrl, keys, params.channel, params.clientId).pipe(
            catchError((err: unknown) => this.catchError(err as AppError)),
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
            tap((data) => {
              if (data !== null) {
                let isError = false;
                for (let i = 0; i < data.length; i++)
                  if (data[i].esitoOK === false) {
                    isError = true;
                    break;
                  }

                if (isError)
                  if (data.length === 1)
                    this.notificationService.showSweetAlert(NotificationType.ERROR, 'Documento non annullabile', '');
                  else
                    this.notificationService.showSweetAlert(
                      NotificationType.ERROR,
                      'Almeno un documento non è annullabile',
                      '',
                    );
                else
                  this.notificationService.showSweetAlert(
                    NotificationType.SUCCESS,
                    'Richiesta annullamento documento/i eseguita correttamente',
                    '',
                  );
              }
            }),
          );
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          '',
          'Proseguire con l\'azione "Annulla" sul documento selezionato?',
          () => cancelElabAction$().subscribe(),
        );
        break;
      }
      // azione multipla
      case ActionCode.SIGN_DOC:
        body = {
          documents: keys,
        };
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Inserimento dati firmatario',
          customFooter: true,
          childData: {
            payload: body,
            actionUrl,
            actionType: ActionType.MULTI,
            refreshDocuments: true,
          },
          childComponent: SignDocModalComponent,
          disableClickOutside: true,
        });
        break;

      // multi
      case ActionCode.DOWNLOAD_PDF_ZIP:
        this.actionsHttpService
          .whenPdfZip(actionUrl + '?allegatoType=50', keys)
          .pipe(catchError((err: unknown) => this.catchError(err as AppError)))
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                // TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
                // si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
                // transfer.
                let fileName = classification;
                if (classification === SectionCode.RECEIVABLE)
                  fileName = `CA_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName()}.zip`;
                else if (classification === SectionCode.PAYABLE)
                  fileName = `CP_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName()}.zip`;

                this.transferService.updateTransfer(r.key, fileName, FileSaverExtension.ZIP);
                const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
                new FileSaver(blob).saveAs(fileName, FileSaverExtension.ZIP);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.INFO,
                  'Uno o più documenti non sono disponibili',
                  "L'archivio non verrà scaricato",
                );
            } else
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'Uno o più documenti non sono disponibili',
                "L'archivio non verrà scaricato",
              );
          });
        break;
      // multi
      case ActionCode.DOWNLOAD_XML_ZIP:
      case ActionCode.DOWNLOAD_XML_ZIP_GENERIC: {
        let payload: unknown = {
          documents: [],
        };
        if (actionCode === ActionCode.DOWNLOAD_XML_ZIP_GENERIC)
          keys.forEach(function (value) {
            payload['documents']?.push({
              channel: 'gedinvoice',
              docType: '52',
              progSpool: value['progSpool'],
              progBusta: value['progBusta'],
              docHash: value['hashDocKey'],
            });
          });
        else payload = keys;

        this.actionsHttpService
          .whenXmlZip(actionUrl, payload)
          .pipe(catchError((err: unknown) => this.catchError(err as AppError)))
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                // TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
                // si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
                // transfer.
                let fileName = classification;
                if (classification === SectionCode.RECEIVABLE)
                  fileName = `CA_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName()}.zip`;
                else if (classification === SectionCode.PAYABLE)
                  fileName = `CP_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName()}.zip`;

                this.transferService.updateTransfer(r.key, fileName, FileSaverExtension.ZIP);
                const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
                new FileSaver(blob).saveAs(fileName, FileSaverExtension.ZIP);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.INFO,
                  'Uno o più documenti non sono disponibili',
                  "L'archivio non verrà scaricato",
                );
            } else
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'Uno o più documenti non sono disponibili',
                "L'archivio non verrà scaricato",
              );
          });
        break;
      }
      // multi
      case ActionCode.EXPORT_TO_MANAGEMENT: {
        const successfulAction = (response: unknown) => {
          if (response['esitoOK'])
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Richiesta di esportazione verso il gestionale eseguita correttamente',
              '',
            );
          else {
            let messageString = '';
            for (let i = 0; i < (response as unknown[]).length; i++) messageString += response[i].message + '.\n';

            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore nella richiesta di esportazione',
              messageString,
            );
          }
        };

        const executeAction$ = () =>
          this.actionsHttpService.whenExportToManagementSW(actionUrl, keys).pipe(
            take(1),
            tap((response) => successfulAction(response)),
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
            catchError((err: unknown) => this.catchError(err as AppError)),
            tap((res) => {
              if (res === null)
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Documento non esportato');
            }),
          );

        //TODO TAM: deve diventare uno sweet alert
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Esporta verso gestionale',
          childComponent: ActionConfirmationModalComponent,
          customFooter: true,
          childData: {
            action: executeAction$,
            title: 'Esporta verso gestionale',
            docCount,
            maxDocCount: 10000,
          },
          disableClickOutside: false,
        });
        break;
      }

      // multi
      case ActionCode.RE_EXPORT_TO_MANAGEMENT: {
        const successfulReExportAction = (response: unknown) => {
          if (response['esitoOK'])
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Richiesta di riesportazione verso il gestionale eseguita correttamente',
              '',
            );
          else {
            let messageString = '';
            for (let i = 0; i < (response as unknown[]).length; i++) messageString += response[i].message + '.\n';

            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore nella richiesta di riesportazione',
              messageString,
            );
          }
        };

        const executeReExportAction$ = () =>
          this.actionsHttpService.whenReExportToManagementSW(actionUrl, keys).pipe(
            tap((response) => {
              successfulReExportAction(response);
            }),
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
            catchError((err: unknown) => this.catchError(err as AppError)),
          );

        //TODO TAM: deve diventare uno sweet alert
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Riesporta verso gestionale',
          childComponent: ActionConfirmationModalComponent,
          customFooter: true,
          childData: {
            action: executeReExportAction$,
            title: 'Riesporta verso gestionale',
            docCount,
            maxDocCount: 10000,
          },
          disableClickOutside: false,
        });
        break;
      }
      // multi
      case ActionCode.DOWNLOAD_ATTACHMENTS_RECEIVABLE: {
        const requestBody = {
          documents: keys.map((k) => ({ progSpool: k['progSpool'], progBusta: k['progBusta'] })),
        };
        this.actionsHttpService
          .downloadAttachmentsMulti(requestBody)
          .pipe(take(1))
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
                new FileSaver(blob).saveAs(r.name, FileSaverExtension.ZIP);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });

        break;
      }
      //	multi
      case ActionCode.CHECK_SDI_AND_RESEND: {
        const successfulResendAction = (response: unknown) => {
          if (response['esitoOK'])
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Azione eseguita correttamente',
              'Sono stati reinviati ' + response['validDocument'] + ' documenti',
            );
          else this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', response['message']);
        };

        const executeResendAction$ = (payload: CheckSdiAndResend) =>
          this.actionsHttpService.whenCheckAndResendInvoice(actionUrl, payload).pipe(
            tap((response) => {
              successfulResendAction(response);
            }),
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
            catchError((err: unknown) => {
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Errore inatteso');
              return this.catchError(err as AppError);
            }),
          );

        //TODO TAM: deve diventare uno sweet alert
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Applica controlli SDI e reinvia',
          childComponent: ActionConfirmationModalComponent,
          customFooter: true,
          childData: {
            action: executeResendAction$,
            actionParams: {
              licenseId: this.companiesService.getCurrentCompanyValue().licenseId,
              siaCode: this.companiesService.getCurrentCompanyValue().siaCode,
              keysList: keys,
            },
            title: 'Applica controlli SDI e reinvia',
            docCount,
            maxDocCount: 50,
          },
          disableClickOutside: false,
        });
        break;
      }

      case ActionCode.EXPORT_TO_FTP: {
        const exportBody = [...keys.map((k) => ({ progSpool: k['progSpool'], progBusta: k['progBusta'] }))];
        this.exportToFtp(actionUrl, exportBody);
        break;
      }

      case ActionCode.EXCLUDE_FROM_CONS: {
        const excludeDocuments = [
          ...keys.map((k) => ({
            progSpool: k['progSpool'],
            progBusta: k['progBusta'],
            documentId: k['ctelElasticDocumentId'],
            idSerieDoc: k['ctelDocSeriesId'],
          })),
        ];
        const excludeFromCons = () => this.excludeFromCons(actionUrl, excludeDocuments, true);

        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          '',
          'Le fatture selezionate saranno escluse dalla Conservazione Digitale. Vuoi procedere?',
          () => excludeFromCons(),
        );

        break;
      }

      case ActionCode.DOWNLOAD_ESITI_PDF:
        body = keys.map((key) => key['idComunicazione35']);
        this.downloadEsiti(actionUrl, body as string[], Extension.PDF);
        break;

      case ActionCode.DOWNLOAD_ESITI_XML:
        body = keys.map((key) => key['idComunicazione35']);
        this.downloadEsiti(actionUrl, body as string[], Extension.XML);
        break;
      case ActionCode.DOWNLOAD_ESITI_EXCEL: {
        const showFormatModal = () => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: 'Esporta risultati in Excel',
            customFooter: true,
            childComponent: ExcelFormatModalComponent,
            childData: {
              backButton: true,
              action,
              actionType: 'multiple',
              keys,
              service: 'HUBFE-Esiti',
              sectionCode: classification,
            },
          });
        };
        if (docCount > 50000)
          this.notificationService.showSweetAlert(
            NotificationType.QUESTION,
            'Numero massimo di documenti superato.',
            "Procedere all'esportazione dei primi 50000 documenti?",
            showFormatModal,
          );
        else showFormatModal();

        break;
      }
      case ActionCode.EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED: {
        const documentList = keys;
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Documenti da Conservare',
          'Le fatture selezionate saranno spostate nella sezione "Esportate verso gestionale e da protocollare" per essere protocollate e conservate. Vuoi procedere?',
          () =>
            this.actionsHttpService
              .whenExportToManagementFromUnpreserved(actionUrl, documentList)
              .pipe(
                take(1),
                catchError((err: unknown) => this.catchError(err as AppError)),
                tap((res) => {
                  if (res === null)
                    this.notificationService.showSweetAlert(
                      NotificationType.ERROR,
                      'Errore',
                      'Documenti non esportati',
                    );
                  else
                    this.notificationService.showSweetAlert(
                      NotificationType.SUCCESS,
                      'Documenti esportati correttamente',
                      '',
                    );
                }),
                delay(1000),
                tap(() => this.documentsService.refreshDocuments()),
              )
              .subscribe(),
        );
        break;
      }
    }
  }

  // Elimina anche il 2.
  private formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName(): string {
    let currentDate = new Date();
    const offset = currentDate.getTimezoneOffset();
    const dir = offset >= 0 ? 1 : -1; // Se siam prima o dopo lo 0, bisogna aggiungere o sottrarre.
    currentDate = new Date(currentDate.getTime() + dir * currentDate.getTimezoneOffset() * 60 * 1000);
    return currentDate
      .toISOString()
      .replace(/[\\.:-]/g, '')
      .replace('T', '_')
      .replace('Z', '');
  }

  private catchError(err: AppError): Observable<null> {
    if (err.type === ErrorTypes.HTTP_UNAUTHORIZED)
      //this.router.navigate(['/unauthorized']).then();
      return of(null);

    return of(null);
  }

  private exportToFtp(actionUrl: string, exportBody: { progBusta: unknown; progSpool: unknown }[]) {
    this.actionsHttpService
      .exportToFtp(actionUrl, exportBody)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.notificationService.showSweetAlert(
            NotificationType.SUCCESS,
            'Invia via FTP',
            "L'esportazione dei documenti selezionati è stata presa in carico. " +
              "I documenti saranno disponibili nell'area FTP al termine del processo.",
          );
          this.documentsService.refreshDocuments();
        },
        error: () => {
          this.notificationService.showSweetAlert(
            NotificationType.ERROR,
            'Invia via FTP',
            "Non è stato possibile avviare l'esportazione dei documenti selezionati. " +
              "Ti invitiamo a riprovare più tardi. Se il problema dovesse persistere, ti suggeriamo di contattare l'Assistenza Clienti",
          );
        },
      });
  }

  private excludeFromCons(
    actionUrl: string,
    excludeDocsBody: Array<{
      progSpool: number;
      progBusta: number;
      documentId: string;
      idSerieDoc: string;
    }>,
    refreshDocuments: boolean,
  ) {
    this.actionsHttpService
      .excludeFromCons(actionUrl, { excludeDocuments: excludeDocsBody })
      .pipe(take(1), delay(1000))
      .subscribe({
        next: (data) => {
          const isAllSuccess = data.every((d) => d.esitoOK);
          if (isAllSuccess)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Escludi da conservazione',
              'Azione eseguita con successo.',
            );
          else {
            const errorDocs = data.filter((d) => !d.esitoOK);
            const multiErrorDocResponse = errorDocs.length > 1;

            if (multiErrorDocResponse)
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Escludi da conservazione',
                "Non è stato possibile eseguire l'azione.",
              );
            else
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Escludi da conservazione',
                errorDocs[0]?.message,
              );
          }
        },
        error: () => {
          this.notificationService.showSweetAlert(
            NotificationType.ERROR,
            'Escludi da conservazione',
            "Non è stato possibile eseguire l'azione. " +
              "Ti invitiamo a riprovare più tardi. Se il problema dovesse persistere, ti suggeriamo di contattare l'Assistenza Clienti",
          );
        },
        complete: () => {
          if (refreshDocuments) this.documentsService.refreshDocuments();
        },
      });
  }

  private downloadEsiti(actionUrl: string, body: string[], format: Extension) {
    this.actionsHttpService
      .downloadEsiti(actionUrl, body, format)
      .pipe(catchError((err: unknown) => this.catchError(err as AppError)))
      .subscribe((result) => {
        if (result !== null) {
          const r = result as Transfer;
          if (TransferUtils.isHttpResponse(r.originatingEvent)) {
            const blob = new Blob([r.originatingEvent.body], { type: 'application/zip' });
            new FileSaver(blob).saveAs(r.name, FileSaverExtension.ZIP);
          } else
            this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
        } else this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
      });
  }
}
