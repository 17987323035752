import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { NotificationType } from '../notification-type';
import { ToastInfo } from './toast-info';

@Injectable()
export class ToastService {
  constructor(private toastr: ToastrService) {}

  public show(data: ToastInfo) {
    let toaster: ActiveToast<any>;

    switch (data.type) {
      case NotificationType.ERROR:
        toaster = this.toastr.error(data.message, data.title);
        break;

      case NotificationType.SUCCESS:
        toaster = this.toastr.success(data.message, data.title);
        break;

      default:
        toaster = this.toastr.info(data.message, data.title);
        break;
    }

    this.onTap(toaster, data);
  }

  private onTap(toaster: ActiveToast<any>, data: ToastInfo) {
    if (toaster) if (data.onTapCallback) toaster.onTap.subscribe(data.onTapCallback);
  }
}
