import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { FormControlName, NgControl } from '@angular/forms';
import { PaOfficesList } from 'app/entities/invoice-pa/invoice-pa-autocomplete/pa-offices-list';
import { CustomFormControlComponent } from 'app/shared/components/form-validation/reactive-form/custom-form-control.component';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-text-form-typeahead-control',
  templateUrl: './text-form-typeahead-control.component.html',
  styleUrls: ['../text-form-control/text-form-control.component.scss'],
})
export class TextFormTypeaheadControlComponent extends CustomFormControlComponent<string> {
  @Input() dataSource: Observable<unknown[]> | unknown[] = null;
  @Input() typeaheadOptionsLimit = 5;
  @Input() typeaheadOptionField: string;
  @Input() typeaheadMinLength = 0;
  @Input() showOffice = false;
  @Input() typeaheadAsync = false;
  @Input() typeaheadScrollable = false;
  @Output() selectedItem = new EventEmitter<PaOfficesList>();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public controlName: FormControlName,
  ) {
    super(ngControl, controlName);
  }

  myFunc(obj: TypeaheadMatch) {
    this.selectedItem.emit(obj.item);
  }
}
