import { Injectable, OnDestroy } from '@angular/core';
import {
  FilterActions,
  IAdvancedTextSearch,
  getAdvancedTextSearchWithUserValuesForSearch,
} from '@ctel/search-filter-store';
import { Store, select } from '@ngrx/store';
import { RelatedSectionData } from 'app/entities/sections/related-section-data';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocSeriesMetadataDesc, DocSeriesMetadataDescSearch } from '../entities/doc-series-search/document-details';

@Injectable({
  providedIn: 'root',
})
export class AdvancedTextSearchService implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();
  protected readonly advancedTextSearch$: Observable<IAdvancedTextSearch[]>;

  protected constructor(protected store: Store) {
    this.advancedTextSearch$ = this.store.pipe(select(getAdvancedTextSearchWithUserValuesForSearch));
  }

  private static isImport(value: string) {
    const reg = new RegExp('^(((\\d{1,3})((\\.\\d{3})*)|(\\d*))(,\\d{1,2})?)$');
    return reg.test(value.trim());
  }

  private static sanitizeImport(value: string) {
    return value.trim().split('.').join('').replace(',', '.');
  }

  public whenAdvancedTextSearch(): Observable<IAdvancedTextSearch[]> {
    return this.advancedTextSearch$.pipe(
      map((x) =>
        x.reduce(
          (acc, item) => [
            ...acc,
            {
              label: item.description,
              value: item.value,
              metadata: item.metadata,
            },
          ],
          [],
        ),
      ),
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public sortTextSearchMetadata(
    docSeriesMetadataDesc: DocSeriesMetadataDesc[],
    relatedSectionData: RelatedSectionData[],
  ) {
    const reduced = docSeriesMetadataDesc.reduce(
      (acc, item) => [
        ...acc,
        {
          metadata: item.metadato,
          value: '',
          description: item.descrizioni.length > 0 ? item.descrizioni[0] : '',
        },
      ],
      [] as IAdvancedTextSearch[],
    );

    // We sort using the original related data ordering. In case metadata are present in different
    // sections, we find the first that contains both metadata. We sort by ascending order, otherwise.
    return reduced.sort((a, b) => {
      const found = relatedSectionData.find(
        (x) => x.textSearchMetadata.indexOf(a.metadata) !== -1 && x.textSearchMetadata.indexOf(b.metadata) !== -1,
      );
      return found
        ? found.textSearchMetadata.indexOf(a.metadata) - found.textSearchMetadata.indexOf(b.metadata)
        : a > b
          ? 1
          : -1;
    });
  }

  public sendAdvancedTextSearchValues(
    values: { label: string; metadata: string; value: string }[],
    urlCommands: string[] = null,
  ) {
    // Dispatchiamo solamente i filtri con un value non vuoto. Inoltre modifichiamo il value per essere sanitizzato.
    values = values.reduce((acc, item) => {
      let value = item.value && item.value.trim();
      if (value) {
        if (AdvancedTextSearchService.isImport(value)) value = AdvancedTextSearchService.sanitizeImport(value);

        return [...acc, { ...item, value }];
      }
      return acc;
    }, []);

    this.store.dispatch(
      FilterActions.changeFilterRequested({
        kind: FilterActions.ChangeFilterRequestedKind.AdvancedTextSearchChanged,
        metadata: '',
        advancedTextSearchMetadata: values.reduce(
          (acc, item) => [...acc, { metadata: item.metadata, value: item.value }],
          [],
        ),
        urlCommands,
      }),
    );
  }

  public createMetadataDescriptionPayload(relatedSectionData: RelatedSectionData[]): DocSeriesMetadataDescSearch {
    // NOTE: should be RelatedSectionData[] but we need to decouple here as RelatedSectionData type is badly defined.
    const reduced = relatedSectionData.reduce(
      (acc, item) => {
        item.docSeriesIds.forEach((docSeriesId) => acc.docSeriesIds.add(docSeriesId));
        item.textSearchMetadata.forEach((metadata) => acc.metadata.add(metadata));
        return acc;
      },
      { docSeriesIds: new Set<string>(), metadata: new Set<string>() },
    );

    return {
      idsSerieDoc: Array.from(reduced.docSeriesIds),
      metadata: Array.from(reduced.metadata),
    } as DocSeriesMetadataDescSearch;
  }
}
