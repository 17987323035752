export enum ModalType {
  SIMPLE,
  SIMPLE_LARGE,
  ACTIVITY_LARGE,
  EXTRA_LARGE,
  EXTRA_LARGE_DISABLED_CLICK,
  SIMPLE_LARGE_DISABLED_CLICK,
  SIMPLE_DISABLED_CLICK,
  XXL,
}
