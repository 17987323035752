import { Injectable } from '@angular/core';
import { BridgeGedconsHttpService } from 'app/core/business/bridge-dnn/bridge-gedcons-http.service';
import { Observable } from 'rxjs';

@Injectable()
export class BridgeGedconsService {
  constructor(private bridgeGedconsHttpService: BridgeGedconsHttpService) {}

  public redirectToDnn(): Observable<string> {
    return this.bridgeGedconsHttpService.redirectToDnn();
  }
}
