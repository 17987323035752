import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/entities/config/app-config';
import { ConfigService } from 'app/core/common/config/config.service';

@Injectable()
export class UploadPdfHttpService {
  // endpoints
  private validation: string;
  private validationHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.validationHost = appConfig.uploadDocument.http.host;
    this.validation = `${this.validationHost}/v1/Upload/UploadDocument`;
  }

  public uploadPdf(id_: string, sia_: string, grafica_: string, documentPa: string) {
    const url_ = this.validation;

    const headers = new HttpHeaders().set('Content-Type', 'text/json');

    return this.http.post(url_, documentPa, {
      headers,
      responseType: 'text',
      params: {
        id: id_,
        sia: sia_,
        grafica: grafica_,
        metadataMatching: 'SequenceNumberMatchingKey',
      },
    });
  }
}
