<content-loader
  [speed]="2"
  [foregroundColor]="'#f3f3f3'"
  [backgroundColor]="'#ecebeb'"
  [style]="{ height: '250px', width: '400px' }"
>
  <svg:polygon points="0,10 35,10 35,50" />
  <svg:polygon points="0,50 35,50 35,10" />
  <svg:rect x="18" y="10" rx="3" ry="3" width="363" height="40" />
  <svg:rect x="0" y="60" rx="4" ry="4" width="380" height="40" />
  <svg:polygon points="380,9 380,49 395,32" />
  <svg:rect x="0" y="110" rx="3" ry="3" width="380" height="40" />
  <svg:rect x="0" y="160" rx="4" ry="4" width="380" height="40" />
</content-loader>
