import { Directive } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';

@Directive({
  selector: '[requireFile]',
  providers: [{ provide: NG_VALIDATORS, useExisting: FileValidator, multi: true }],
})
export class FileValidator implements Validator {
  static validate(c: UntypedFormControl): { [key: string]: boolean } {
    return c.value === undefined || c.value === null || c.value.length === 0 ? { required: true } : null;
  }

  validate(c: UntypedFormControl): { [key: string]: boolean } {
    return FileValidator.validate(c);
  }
}
