import { Component, Input, Optional, Self } from '@angular/core';
import { FormControlName, NG_VALIDATORS, NgControl } from '@angular/forms';
import { DateValidators } from 'app/shared/components/dry/validator/date-validators';
import { CustomFormControlComponent } from '../../custom-form-control.component';

@Component({
  selector: 'app-date-form-control',
  templateUrl: './date-form-control.component.html',
  styleUrls: ['./date-form-control.component.scss'],
  providers: [
    // Il datepicker vuole una data in formato 'YYYY-MM-DD', che poi verrà visualizzata secondo il locale
    { provide: NG_VALIDATORS, useValue: DateValidators.isDate('YYYY-MM-DD'), multi: true },
  ],
})
export class DateFormControlComponent extends CustomFormControlComponent<Date> {
  // FIXME: Per gestire da qua il format bisogna utilizzare un datepicker custom, html5 non lo permette
  @Input() format: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public controlName: FormControlName,
  ) {
    super(ngControl, controlName);
  }
}
