import { Injectable } from '@angular/core';
import { UploadLottoHttpService } from './upload-lotto-http.service';
import { Observable } from 'rxjs';
import { UploadLottoMessage } from 'app/entities/invoice-pa/upload-lotto-message/upload-lotto-message';

@Injectable()
export class UploadLottoService {
  constructor(private uploadLottoService: UploadLottoHttpService) {}

  public uploadLotto(lotto: File, id, sia, grafica): Observable<UploadLottoMessage[]> {
    return this.uploadLottoService.uploadLotto(id, sia, grafica, lotto);
  }
}
