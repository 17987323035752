import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { FrDocumentiHttpService } from './fr-documenti-http.service';
import { ConfirmMassiveRequest } from '../entities/confirm-massive-request';

@Injectable({
  providedIn: 'root',
})
export class FrDocumentiService {
  constructor(private frDocumentiHttp: FrDocumentiHttpService) {}

  cancelMassive(body: string): Observable<void> {
    return this.frDocumentiHttp.cancelMassive(body);
  }

  confirmMassive(payload: ConfirmMassiveRequest): Observable<void> {
    return this.frDocumentiHttp.confirmMassive(payload);
  }
}
