import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { EditSpedizione } from 'app/entities/graphic-spedizioni/edit-spedizione';
import { GraphicSpedizioni } from 'app/entities/graphic-spedizioni/graphic-spedizioni';
import { LottoSpedizioni } from 'app/entities/lotto-spedizioni/lotto-spedizioni';
import { ShipmentHttpService } from './shipment-http.service';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService {
  private code$ = new ReplaySubject<string>(1);
  private lottiList$ = new ReplaySubject<LottoSpedizioni[]>(1);
  private graphicsList$ = new ReplaySubject<GraphicSpedizioni[]>(1);

  constructor(private creaSpedizioneHttpService: ShipmentHttpService) {}

  whenCode(licenseId, siaCode, graphic): Observable<string> {
    return this.creaSpedizioneHttpService.whenCode(licenseId, siaCode, graphic).pipe(catchError(() => EMPTY));
  }

  whenLotti(licenseId, siaCode, grafica, offset, rows, parziale, dateFrom, dateTo, idScatola?) {
    return this.creaSpedizioneHttpService
      .whenLotti(licenseId, siaCode, grafica, offset, rows, parziale, dateFrom, dateTo, idScatola)
      .pipe(
        catchError(() => EMPTY),
        tap((lotti) => {
          this.sendLotti(lotti);
        }),
      );
  }

  sendLotti(lotti) {
    this.lottiList$.next(lotti);
  }

  whenGraphics(licenseId, siaCode): Observable<GraphicSpedizioni[]> {
    return this.creaSpedizioneHttpService.whenGraphics(licenseId, siaCode).pipe(
      catchError(() => EMPTY),
      tap((graphics) => {
        this.sendGraphics(graphics);
      }),
    );
  }

  sendGraphics(graphics) {
    this.lottiList$.next(graphics);
  }

  upsertTracking(
    licenseId: string,
    siaCode: string,
    grafica: string,
    code: string,
    checked: number,
    notes: string,
    tipiScatola: string,
    processoElaborazione: string,
    spools: any,
    boxId: string,
    totaleDocLavorati: number,
    nomiFlusso: string[],
    descTipoDocumento: string,
  ) {
    if (boxId)
      return this.creaSpedizioneHttpService
        .updateTracking(
          code,
          checked,
          notes,
          tipiScatola,
          processoElaborazione,
          spools,
          boxId,
          totaleDocLavorati,
          nomiFlusso,
        )
        .pipe(
          catchError((err: unknown) => {
            throw err;
          }),
        );

    return this.creaSpedizioneHttpService
      .insertTracking(
        licenseId,
        siaCode,
        grafica,
        code,
        checked,
        notes,
        tipiScatola,
        processoElaborazione,
        spools,
        nomiFlusso,
        descTipoDocumento,
      )
      .pipe(
        catchError((err: unknown) => {
          throw err;
        }),
      );
  }

  getTracking(licenseId: string, siaCode: string, idSpedizione: string): Observable<EditSpedizione> {
    return this.creaSpedizioneHttpService.getTracking(licenseId, siaCode, idSpedizione);
  }
}
