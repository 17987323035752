import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BlockUIModule } from '@ctel/block-ui';
import { ErrorPlaceholderModule } from '@ctel/placeholders';
import { DryLoadingPlaceholderModule } from 'app/core/common/placeholder/loading-placeholder/dry-loading-placeholder.module';
import { PdfViewerComponent } from 'app/shared/components/display-document/viewers/pdf-viewer/pdf-viewer.component';
import { DryComponentsModule } from 'app/shared/components/dry/dry-components.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DisplayDocumentComponent } from './display-document/display-document.component';
import { TabbedDisplayDocumentComponent } from './tabbed-display-document/tabbed-display-document.component';
import { XmlViewerComponent } from './viewers/xml-viewer/xml-viewer.component';
import { PipesModule } from 'app/shared/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    DryComponentsModule,
    PdfViewerModule,
    BlockUIModule,
    FormsModule,
    ErrorPlaceholderModule,
    DryLoadingPlaceholderModule,
    ErrorPlaceholderModule,
    PipesModule,
  ],
  declarations: [DisplayDocumentComponent, TabbedDisplayDocumentComponent, XmlViewerComponent, PdfViewerComponent],
  exports: [DisplayDocumentComponent, TabbedDisplayDocumentComponent, XmlViewerComponent, PdfViewerComponent],
})
export class DisplayDocumentModule {}
