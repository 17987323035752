import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterStatus } from '@ctel/gaw-commons';
import { Transfer, transferHandler, TransferService } from '@ctel/transfer-manager';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppConfig } from '../../../entities/config/app-config';
import { ConfigService } from '../../common/config/config.service';
import { FullScreenSpinnerService } from '../../common/spinner/full-screen-spinner/full-screen-spinner.service';
import { CustomHttpOptions } from '../../common/utilities/custom-http-options';

@Injectable({
  providedIn: 'root',
})
export class AsyncActionsHttpService {
  private jsonContentType = 'application/json';
  // host
  private documentsExportHost: string;
  private e_chunkMassiveDownload: string;
  private e_asyncExcelMassiveDownload: string;
  private e_downloadLog: string;
  private employeeHost: string;

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
    private transferService: TransferService,
    private fullScreenSpinnerService: FullScreenSpinnerService,
  ) {
    this.configService.whenAppConfig().subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  httpHostInit(appConfig: AppConfig) {
    this.documentsExportHost = appConfig.documentsExport.http.host;
    this.employeeHost = appConfig.employeesRead.http.host;
    this.e_chunkMassiveDownload = `${this.documentsExportHost}/v1/documents/export/massive/download/{uiCode}/{requestId}/{requestDetailId}`;
    this.e_asyncExcelMassiveDownload = `${this.documentsExportHost}/{version}/documents/export/massive?clientId={clientId}`;
    this.e_downloadLog = `${this.employeeHost}/v1/dipendenti/scarica/storico/{logId}`;
  }

  chunkMassiveDownload(uiCode: string, requestId: string, chunkNumber: string): Observable<Transfer> {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob');
    const url = this.e_chunkMassiveDownload
      .replace('{uiCode}', uiCode)
      .replace('{requestId}', requestId)
      .replace('{requestDetailId}', chunkNumber);
    return this.http.get(url, options).pipe(transferHandler(this.transferService));
  }

  asyncExcelMassiveDownload(
    elasticRequest: FilterStatus,
    fileType: string,
    sectionCode: string,
    clientId: string,
  ): Observable<unknown> {
    const url = this.e_asyncExcelMassiveDownload
      .replace('{version}', clientId === 'HUBFE' ? 'v1' : 'v2')
      .replace('{clientId}', clientId);

    return this.fullScreenSpinnerService.inhibitSpinnerHeaders().pipe(
      switchMap((fullscreenHeaders) => {
        const configurationGroup = '';
        const body = {
          elasticRequest,
          fileType,
          configurationGroup,
          sectionCode,
        };
        const headers = fullscreenHeaders.set('log', 'true');
        return this.http.post(url, body, { headers });
      }),
    );
  }

  downloadLog(logId: string) {
    const options = CustomHttpOptions.getHttpOptionsObserveProgressEvent(this.jsonContentType, 'blob');
    const url = this.e_downloadLog.replace('{logId}', logId);
    return this.http.get(url, options).pipe(transferHandler(this.transferService));
  }
}
