<input
  class="form-control form-control-sm"
  [ngClass]="{
    valid: numberInputForm.value && numberInputForm.valid && required,
  }"
  type="number"
  name="{{ name }}"
  [id]="id"
  [placeholder]="placeholder"
  #numberInputForm="ngModel"
  [(ngModel)]="value"
  (ngModelChange)="onChangeUpdate()"
  [ngModelOptions]="ngModelOptions"
  #numberInputPopover="bs-popover"
  [popover]="InvalidFormMessage"
  (focusin)="onFocusIn()"
  (focusout)="onFocusOut()"
  triggers=""
  [required]="required"
  isNumberValidator
  max="{{ max }}"
  [composeValidators]="_validators"
  [composeAsyncValidators]="_asyncValidators"
/>
<ng-template #InvalidFormMessage>
  <ul *ngIf="numberInputForm.invalid" class="invalid-form-msg">
    <ng-container *ngFor="let error of getErrorMessage(numberInputForm)">
      <li>
        {{ error }}
      </li>
    </ng-container>
  </ul>
</ng-template>
