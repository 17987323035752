<ctel-add-notes
  class="document-detail-body"
  [progSpool]="data.progSpool"
  [progBusta]="data.progBusta"
  [idSerieDoc]="data.docSeriesId"
  [note]="data.note"
  [userId]="data.userId"
  (noteSaved)="onNoteSaved()"
  (viewInitalized)="onViewInit($event)"
>
</ctel-add-notes>
