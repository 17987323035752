import { ComponentRef, Directive, Input, OnChanges, OnInit, Type, ViewContainerRef } from '@angular/core';
import { Row } from '..';
import { CheckboxCellAddition } from './checkbox-cell-addition';

@Directive({
  selector: '[gridCheckboxSelectAdditionRenderer]',
})
export class CheckboxSelectAdditionRendererDirective implements OnInit, OnChanges {
  @Input() row: Row;
  @Input() c: Type<CheckboxCellAddition>;

  private component: ComponentRef<unknown>;

  constructor(private container: ViewContainerRef) {}

  ngOnInit(): void {
    if (!this.c) return;

    this.component = this.container.createComponent(this.c);
    this.component.instance['row'] = this.row;
  }

  ngOnChanges(): void {
    if (this.component) this.component.instance['value'] = this.row;
  }
}
