/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { TextValidators } from 'app/shared/components/dry/validator/text-validators';

@Directive({
  selector: '[onlyNumberAndLetter][ngModel], [onlyNumberAndLetter][formControlName]',
  providers: [{ provide: NG_VALIDATORS, useExisting: OnlyNumberAndLetterValidatorDirective, multi: true }],
})
export class OnlyNumberAndLetterValidatorDirective implements Validator {
  private validator: ValidatorFn;

  constructor() {
    this.validator = TextValidators.onlyLetterAndNumber();
  }

  registerOnValidatorChange(fn: () => void): void {}

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator(c);
  }
}
