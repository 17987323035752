import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { Company } from 'app/entities/companies/company';
import {
  RegimeMinimiOperationResponse,
  RegimeMinimiRecord,
  RegimeMinimiRecordsResponse,
  RegimeMinimiSupplier,
} from 'app/entities/configuration/regime-minimi';
import { FormFilter } from 'app/shared/components/table/form-filter';
import { Order, Ordering } from 'app/shared/components/table/interface/ordering';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, combineLatest } from 'rxjs';
import { catchError, map, share, switchMap, take, tap } from 'rxjs/operators';
import { RegimeMinimiHttpService } from './regime-minimi-http.service';

/**
 * Servizio che gestisce la pagina regime dei minimi
 */
@Injectable({
  providedIn: 'root',
})
export class RegimeMinimiService {
  public readonly regimeMinimiCount$: Observable<number>;
  public readonly regimeMinimiRecords$: Observable<RegimeMinimiRecord[]>;
  public readonly regimeMinimiResponse$: Observable<RegimeMinimiRecordsResponse>;

  public readonly PAGE_DIMENSION = 10;
  public readonly DEFAULT_PAGE_INDEX = 1;
  public readonly DEFAULT_ORDER: Ordering = { field: 'dataInserimento', order: Order.DESCENDING };
  public readonly DEFAULT_BUSINESS_NAME = '';
  public readonly DEFAULT_PIVA = '';
  public readonly DEFAULT_CF = '';

  // Eventi di ricerca
  private currentPage$: BehaviorSubject<number> = new BehaviorSubject(this.DEFAULT_PAGE_INDEX);
  private pageDimension$: BehaviorSubject<number> = new BehaviorSubject(this.PAGE_DIMENSION);
  private order$: BehaviorSubject<Ordering> = new BehaviorSubject(this.DEFAULT_ORDER);
  // private businessName$: BehaviorSubject<string> = new BehaviorSubject(this.DEFAULT_BUSINESS_NAME);
  // private piva$: BehaviorSubject<string> = new BehaviorSubject(this.DEFAULT_PIVA);
  // private cf$: BehaviorSubject<string> = new BehaviorSubject(this.DEFAULT_CF);
  private filters$: BehaviorSubject<FormFilter[]> = new BehaviorSubject([]);

  // Eventi di refresh
  private refresh$: BehaviorSubject<any> = new BehaviorSubject(0);

  constructor(
    private regimeMinimiHttpService: RegimeMinimiHttpService,
    private companiesService: CompaniesService,
    private router: Router,
  ) {
    /**
     * Observable di refresh per il regime dei minimi.
     * refresh$ -> Forza manualmente il refresh di tutto l'Observable
     * combineLatest() -> Valori utilizzati per il refresh
     */
    const refreshEvents$ = this.refresh$.pipe(
      switchMap(() =>
        combineLatest([
          this.companiesService.whenCurrentCompany(),
          this.currentPage$,
          this.pageDimension$,
          this.order$,
          this.filters$,
        ]),
      ),
    );

    this.regimeMinimiResponse$ = refreshEvents$.pipe(
      switchMap(
        ([company, currentPage, pageDimension, order, filters]: [Company, number, number, Ordering, FormFilter[]]) =>
          this.regimeMinimiHttpService
            .whenRegimeMinimiRecordsAndCount(
              company.licenseId,
              company.siaCode,
              currentPage,
              pageDimension,
              order.field,
              order.order,
              filters.find((filter) => filter.field.id === 'ragioneSociale'),
              filters.find((filter) => filter.field.id === 'partitaIva'),
              filters.find((filter) => filter.field.id === 'codiceFiscale'),
            )
            .pipe(
              catchError((err: unknown) => {
                if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
                  //this.router.navigate(['/unauthorized']).then();
                }
                return EMPTY;
              }),
            ),
      ),
      // Multicasting dell'Observable per evitare doppia chiamata HTTP per Count e Records
      share({ connector: () => new ReplaySubject<RegimeMinimiRecordsResponse>(1) }),
    );

    this.regimeMinimiCount$ = this.regimeMinimiResponse$.pipe(map((res) => res.counter));

    this.regimeMinimiRecords$ = this.regimeMinimiResponse$.pipe(map((res) => res.suppliers));
  }

  whenDeleteRegimeMinimi(body: RegimeMinimiSupplier): Observable<any> {
    return this.companiesService.whenCurrentCompany().pipe(
      take(1),
      switchMap((company) =>
        this.regimeMinimiHttpService.whenActionOnRegimeMinimi(company.licenseId, company.siaCode, body),
      ),
      switchMap(() => this.regimeMinimiCount$),
      take(1),
      tap((totalCount) => {
        // se sto cancellando un elemento dall'ultima pagina e quell'elemento è l'unico, setto la pagina
        // precedente
        if (totalCount % 10 === 1 && Math.floor(totalCount / 10) + 1 === this.getCurrentPage())
          this.setCurrentPage(this.getCurrentPage() - 1);
        // forzo il refresh della pagina dopo la cancellazione
        else this.refresh();
      }),
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
    );
  }

  whenUpdateOrInsertRegimeMinimi(body: RegimeMinimiSupplier): Observable<RegimeMinimiOperationResponse> {
    return this.companiesService.whenCurrentCompany().pipe(
      take(1),
      switchMap((company) =>
        this.regimeMinimiHttpService.whenActionOnRegimeMinimi(company.licenseId, company.siaCode, body),
      ),
      catchError((err: unknown) => {
        if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
          //this.router.navigate(['/unauthorized']).then();
        }
        return EMPTY;
      }),
    );
  }

  whenCurrentPage(): Observable<number> {
    return this.currentPage$.asObservable();
  }

  getCurrentPage(): number {
    return this.currentPage$.value;
  }

  sendCurrentPage(value: number): void {
    this.currentPage$.next(value);
  }

  /**
   * Setta il numero di pagina della paginazione
   * @param page
   */
  setCurrentPage(page: number) {
    this.currentPage$.next(page);
  }

  /**
   * Setta il numero di elementi visualizzati nella paginazione
   * @param dimension
   */
  setPageDimension(dimension: number) {
    this.pageDimension$.next(dimension);
  }

  /**
   * Setta i parametri di ricerca
   * @param filters
   */
  setFilters(filters: FormFilter[]) {
    this.filters$.next(filters);
  }

  /**
   * Setta per che campo e che ordine ordinare
   * @param order
   */
  setOrder(order: Ordering) {
    this.order$.next(order);
  }

  /**
   * Aggiorna i valori degli Observable
   */
  refresh() {
    this.refresh$.next(this.refresh$.getValue() + 1);
  }

  getItemsPerPage(): number {
    return this.pageDimension$.getValue();
  }

  getActualPage(): number {
    return this.currentPage$.getValue();
  }
}
