<div class="preview">
  <tabset [justified]="true">
    <tab class="tabs" [active]="!active">
      <ng-template tabHeading>
        <div class="tab-header">
          <b>Documento</b>
        </div>
      </ng-template>
      <div class="tab-box" [ngClass]="{ 'p-3': !documentAttachmentPec }">
        <dry-pdf-viewer [documentAttachment]="documentAttachmentPec"></dry-pdf-viewer>
      </div>
    </tab>
    <tab class="tabs" [active]="active">
      <ng-template tabHeading>
        <div class="tab-header" (click)="openPecMessagePdf()">
          <b>Anomalia</b>
        </div>
      </ng-template>
      <gaw-view-edit-anomaly [data]="data" [edit]="active"></gaw-view-edit-anomaly>
    </tab>
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header document-detail-body">
          <b>Doppi per chiave</b>
        </div>
      </ng-template>
      <gaw-anomaly-doubles-by-keys class="document-detail-body" [data]="data"></gaw-anomaly-doubles-by-keys>
    </tab>
    <tab class="tabs">
      <ng-template tabHeading>
        <div class="tab-header document-detail-body">
          <b>Storico Stati</b>
        </div>
      </ng-template>
      <gaw-anomaly-status-history class="document-detail-body" [data]="data"></gaw-anomaly-status-history>
    </tab>
  </tabset>
</div>
