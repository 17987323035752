<div class="main-div">
  <div class="inner-div">
    <form novalidate (ngSubmit)="onFormSubmit(protocolForm)" #protocolForm="ngForm">
      <fieldset>
        <div>
          <label class="form-label" for="mail">Email<span *ngIf="isErrorMail" class="error">*</span></label>
          <input type="email" id="mail" name="mail" class="form-control" ngModel />
        </div>

        <div>
          <button type="submit" class="btn" id="insert-mail-confirm-button"><b>Conferma</b></button>
        </div>
      </fieldset>
      <div *ngIf="isErrorMail" class="error-msg">
        <b>*Email non valida</b>
      </div>
    </form>
  </div>
</div>
