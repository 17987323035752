import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PDDRequestModel } from '../../entities/PDD-request-model';

@Component({
  selector: 'gaw-pdd-request-modal',
  templateUrl: './pdd-request-modal.component.html',
  styleUrls: ['./pdd-request-modal.component.scss'],
})
export class PDDRequestModalComponent {
  @Input() data: {
    execute: (pddModel: PDDRequestModel) => NonNullable<unknown>;
    validationMessage: string;
    pddModel: PDDRequestModel;
  };
  @Input() modal: BsModalRef;

  onSubmit() {
    this.data.execute(this.data.pddModel);
    this.modal.hide();
  }
}
