import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Note } from '../../entities/note';
import { Sorting } from '../../entities/sorting';
import { DocumentNotesService } from '../../services/document-notes.service';

@Component({
  selector: 'ctel-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DocumentNotesService],
})
export class NotesListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() style: { [klass: string]: unknown };
  @Input() klass: string | string[] | Set<string> | { [klass: string]: unknown };
  @Input() sortIcon: {
    asc: string;
    desc: string;
    default: string;
  };
  @Input() progSpool: number;
  @Input() progBusta: number;
  @Input() idSerieDoc: number;
  @Input() user: number;

  @Output() noteSelected = new EventEmitter<Note>();

  sorting$: Observable<Sorting>;
  notes: Note[];
  hasNotes: boolean;
  private destroy$ = new Subject<void>();
  constructor(private documentNotesService: DocumentNotesService) {}

  ngOnInit(): void {
    this.documentNotesService
      .getNotes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => {
        this.hasNotes = list.length > 0;
        this.notes = list;
      });
    this.sorting$ = this.documentNotesService.getSorting();
  }

  onNoteClick($event: Note) {
    this.noteSelected.emit($event);
  }

  onNoteDelete(note: Note) {
    this.documentNotesService.deleteNote(note, this.progSpool, this.progBusta, this.idSerieDoc);
  }

  changeSorting(field: 'User' | 'Date') {
    this.sorting$.pipe(take(1)).subscribe((sorting) => {
      const newSort: Sorting = { field, type: sorting.type };
      if (sorting.field === field)
        // Se il campo è uguale alterno l'ordinamento
        newSort.type = sorting.type === 'DESC' ? 'ASC' : 'DESC';
      // Se il campo è diverso reimposto l'ordinamento al valore di default
      else newSort.type = 'DESC';

      this.documentNotesService.setSorting(newSort);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes)
      if (changes.progSpool || changes.progBusta || changes.idSerieDoc)
        this.documentNotesService.setDocumentId(this.progSpool, this.progBusta, this.idSerieDoc);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
