import { Component, Input, OnInit } from '@angular/core';
import { NewsAndNotificationsService } from 'app/core/business/news-and-notifications/news-and-notifications.service';
import { News } from 'app/entities/news-and-notifications/news-and-notifications';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-alert-news-modal',
  templateUrl: './alert-news-modal.component.html',
  styleUrls: ['./alert-news-modal.component.scss'],
})
export class AlertNewsModalComponent implements OnInit {
  @Input() data: {
    news: News;
  };
  @Input() modal: BsModalRef;
  onHide: () => Observable<any>;

  constructor(private newsAndNotificationsService: NewsAndNotificationsService) {}

  ngOnInit() {
    this.onHide()
      .pipe(
        switchMap(() => this.newsAndNotificationsService.setNewsRead(this.data.news.id)),
        take(1),
      )
      .subscribe();
  }
}
