import { Injectable } from '@angular/core';
import { Extension } from 'app/core/common/utilities/file/file';
import { ActionBuilder } from 'app/entities/ui-config/action-builder/action-builder';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { AllDocumentsCallback } from './callbacks/all-documents-callback';

@Injectable({
  providedIn: 'root',
})
export class MassiveActionsCatalog {
  public readonly exportExcelMassive = new ActionBuilder(
    MassiveActionCode.exportExcelMassive,
    ActionCode.DOWNLOAD_EXCEL,
  )
    .setActionURL('/v1/documents/export/report/excel?clientId=HUBFE&configurationGroup={account}')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.exportXLSX(action))
    .build();

  public readonly cancelElabPremuMassive = new ActionBuilder(
    MassiveActionCode.cancelElabPremuMassive,
    ActionCode.CANCEL_ELAB,
  )
    .setActionURL('/v1/Document/AnnullaAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.cancelElab(action, 'Premu'))
    .build();

  public readonly cancelElabMassive = new ActionBuilder(MassiveActionCode.cancelElabMassive, ActionCode.CANCEL_ELAB)
    .setActionURL('/v1/Document/AnnullaAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.cancelElab(action, 'GedInvoice'))
    .build();

  public readonly cancelElabGedPecMassive = new ActionBuilder(
    MassiveActionCode.cancelElabGedPecMassive,
    ActionCode.CANCEL_ELAB,
  )
    .setActionURL('/v1/Document/AnnullaAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.cancelElab(action, 'GedPec'))
    .build();

  public readonly cancelElabGedPostMassive = new ActionBuilder(
    MassiveActionCode.cancelElabGedPostMassive,
    ActionCode.CANCEL_ELAB,
  )
    .setActionURL('/v1/Document/AnnullaAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.cancelElab(action, 'GedPost'))
    .build();

  public readonly cancelElabGedMailMassive = new ActionBuilder(
    MassiveActionCode.cancelElabGedMailMassive,
    ActionCode.CANCEL_ELAB,
  )
    .setActionURL('/v1/Document/AnnullaAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.cancelElab(action, 'GedMail'))
    .build();

  public readonly downloadPdfZipMassive = new ActionBuilder(
    MassiveActionCode.downloadPdfZipMassive,
    ActionCode.DOWNLOAD_PDF_ZIP,
  )
    .setCallback(() => this.allDocumentsCallback.asyncDownloadMassivePDF())
    .build();

  public readonly downloadXmlZipGedinvoiceMassive = new ActionBuilder(
    MassiveActionCode.downloadXmlZipGedinvoiceMassive,
    ActionCode.DOWNLOAD_XML_ZIP,
  )
    .setCallback(() => this.allDocumentsCallback.asyncDownloadMassiveXML())
    .build();

  public readonly downloadXmlZipGedpassjoinMassive = new ActionBuilder(
    MassiveActionCode.downloadXmlZipGedpassjoinMassive,
    ActionCode.DOWNLOAD_XML_ZIP,
  )
    .setCallback(() => this.allDocumentsCallback.asyncDownloadMassiveXML())
    .build();

  public readonly exportToManagementMassive = new ActionBuilder(
    MassiveActionCode.exportToManagementMassive,
    ActionCode.EXPORT_TO_MANAGEMENT,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/EsportaVersoGestionaleAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.exportToManagementSW(action))
    .build();

  public readonly massiveHideDocumentsAction = new ActionBuilder(
    MassiveActionCode.massiveHideDocumentsAction,
    ActionCode.HIDE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestoreAll?hideAndRestoreAction={hide}&clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.hideAndRestoreMassiveDocuments(action, true))
    .build();

  public readonly massiveRestoreDocumentsAction = new ActionBuilder(
    MassiveActionCode.massiveRestoreDocumentsAction,
    ActionCode.RESTORE_DOCUMENTS,
  )
    .setActionURL('/v1/Document/HideAndRestoreAll?hideAndRestoreAction={hide}&clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.hideAndRestoreMassiveDocuments(action, false))
    .build();

  public readonly signDocMassive = new ActionBuilder(MassiveActionCode.signDocMassive, ActionCode.SIGN_DOC)
    .setActionURL('/v1/Document/SignAll?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.signDocs(action))
    .build();

  public readonly downloadEsitiPdfMassive = new ActionBuilder(
    MassiveActionCode.downloadEsitiPdfMassive,
    ActionCode.DOWNLOAD_ESITI_PDF,
  )
    .setActionURL('/v1/Document/GEDINVOICE/MassiveDownloadEsitiSdi')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.asyncDownloadMassiveEsiti(action, Extension.PDF))
    .build();
  public readonly downloadEsitiXmlMassive = new ActionBuilder(
    MassiveActionCode.downloadEsitiXmlMassive,
    ActionCode.DOWNLOAD_ESITI_XML,
  )
    .setActionURL('/v1/Document/GEDINVOICE/MassiveDownloadEsitiSdi')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.asyncDownloadMassiveEsiti(action, Extension.XML))
    .build();
  public readonly downloadEsitiExcelMassive = new ActionBuilder(
    MassiveActionCode.downloadEsitiExcelMassive,
    ActionCode.DOWNLOAD_ESITI_EXCEL,
  )
    .setActionURL('/v1/outcomes/export/massive')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.exportEsitiExcel(action))
    .build();

  public readonly exportToManagementFromUnpreserved = new ActionBuilder(
    MassiveActionCode.exportToManagementFromUnpreserved,
    ActionCode.EXPORT_TO_MANAGEMENT_FROM_UNPRESERVED,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/RevertExcludeProtocolMassive?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.exportToManagementFromUnpreservedMassive(action))
    .build();

  public readonly excludeFromConsMassive = new ActionBuilder(
    MassiveActionCode.excludeFromConsMassive,
    ActionCode.EXCLUDE_FROM_CONS,
  )
    .setActionURL('/v1/Document/GEDPASSJOIN/ExcludeProtocolMassive?clientId=HUBFE')
    .setCallback((action: ActionObject) => this.allDocumentsCallback.excludeFromConsMassive(action))
    .build();

  constructor(private allDocumentsCallback: AllDocumentsCallback) {}
}

export enum MassiveActionCode {
  exportExcelMassive = 'exportExcelMassive',
  cancelElabPremuMassive = 'cancelElabPremuMassive',
  cancelElabMassive = 'cancelElabMassive',
  cancelElabGedPecMassive = 'cancelElabGedPecMassive',
  cancelElabGedPostMassive = 'cancelElabGedPostMassive',
  cancelElabGedMailMassive = 'cancelElabGedMailMassive',
  downloadPdfZipMassive = 'downloadPdfZipMassive',
  downloadXmlZipGedinvoiceMassive = 'downloadXmlZipGedinvoiceMassive',
  downloadXmlZipGedpassjoinMassive = 'downloadXmlZipGedpassjoinMassive',
  exportToManagementMassive = 'exportToManagementMassive',
  massiveHideDocumentsAction = 'massiveHideDocumentsAction',
  massiveRestoreDocumentsAction = 'massiveRestoreDocumentsAction',
  signDocMassive = 'signDocMassive',
  downloadEsitiPdfMassive = 'downloadEsitiPdfMassive',
  downloadEsitiXmlMassive = 'downloadEsitiXmlMassive',
  downloadEsitiExcelMassive = 'downloadEsitiExcelMassive',
  exportToManagementFromUnpreserved = 'exportToManagementFromUnpreserved',
  excludeFromConsMassive = 'excludeFromConsMassive',
}
