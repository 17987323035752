import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@ctel/auth';
import { DocumentsWorkflowModalComponent, WorkflowService } from '@ctel/gaw-workflow';
import { Transfer, TransferService, TransferUtils } from '@ctel/transfer-manager';
import { CompaniesService } from 'app/core/business/companies/companies.service';
import { DocSeriesService } from 'app/core/business/doc-series/doc-series.service';
import { ChannelsService } from 'app/core/business/invoice-pa/channels/channels.service';
import { AppError } from 'app/core/common/error/app-error';
import { HttpHeadersFilename } from 'app/core/common/utilities/http-headers-filename';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { EditKeysModalComponent } from 'app/core/common/modals/edit-keys-modal/edit-keys-modal.component';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { TopProgressBarService } from 'app/core/common/spinner/top-progress-bar/top-progress-bar.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { Channels } from 'app/entities/channels/channels';
import { ActionCode } from 'app/entities/ui-config/action/action-code.enum';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionConfigItem } from 'app/entities/ui-config/action/actions-config';
import { saveAs } from 'file-saver';
import { EMPTY, Observable, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { DocumentNotesModalComponent } from '../../modals/document-notes-modal/document-notes-modal.component';
import { CheckboxService } from '../checkbox/checkbox.service';
import { DocumentDetailsService } from '../document-details/document-details.service';
import { DocumentsSeriesService } from '../documents-series/documents-series.service';
import { DocumentsService2 } from '../documents/documents.service';
import { ActionsHttpService } from './actions-http.service';

/**
 * Servizio che gestisce l'esecuzione delle azioni sui documenti
 */
@Injectable({
  providedIn: 'root',
})
export class ExecuteActionsService {
  constructor(
    private actionsHttpService: ActionsHttpService,
    private notificationService: NotificationService,
    private documentDetailsService: DocumentDetailsService,
    private companiesService: CompaniesService,
    private channelsService: ChannelsService,
    private documentsService: DocumentsService2,
    private documentsSeriesService: DocumentsSeriesService,
    private workflowService: WorkflowService,
    private checkboxService: CheckboxService,
    private router: Router,
    private transferService: TransferService,
    private docSeriesService: DocSeriesService,
    private progressBarService: TopProgressBarService,
    private userService: UserService,
  ) {}

  public whenLoadingAction(): Observable<boolean> {
    return this.actionsHttpService.whenLoadingAction();
  }

  // ESECUZIONE AZIONE SINGOLA
  public executeSingleAction(
    action: ActionObject | ActionConfigItem,
    keys: object,
    refreshDocuments: boolean,
    onCompleted?: { (): unknown },
  ) {
    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    switch (actionCode) {
      case ActionCode.DOWNLOAD_PDF:
        this.actionsHttpService
          .whenSinglePdf(actionUrl, keys['progSpool'], keys['progBusta'], keys['hashDocKey'])
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/pdf' });
                new FileSaver(blob).saveAs(r.name, FileSaverExtension.PDF);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;
      case ActionCode.DOWNLOAD_XML:
        this.actionsHttpService
          .whenSingleXml(actionUrl, keys['progSpool'], keys['progBusta'])
          .pipe(
            take(1),
            catchError((err: unknown) => this.catchError(err as AppError)),
          )
          .subscribe((result) => {
            if (result !== null) {
              const r = result as Transfer;
              if (TransferUtils.isHttpResponse(r.originatingEvent)) {
                const blob = new Blob([r.originatingEvent.body], { type: 'application/xml' });
                new FileSaver(blob).saveAs(r.name, FileSaverExtension.XML);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Errore',
                  'Il file non verrà scaricato',
                );
            } else
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore', 'Il file non verrà scaricato');
          });
        break;
      case ActionCode.START_WORKFLOW:
        this.workflowService.sendCreationSuccess('start');
        this.documentDetailsService.sendDocumentId(keys['ctelElasticDocumentId']);
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Scegli una Attività',
          childComponent: DocumentsWorkflowModalComponent,
        });
        break;
      case ActionCode.DOWNLOAD_ATTACHMENTS_CA:
      case ActionCode.DOWNLOAD_ATTACHMENTS_CP:
        this.actionsHttpService
          .whenAttachments(actionUrl, keys['progSpool'], keys['progBusta'], keys['hashDocKey'])
          .pipe(
            take(1),
            catchError(() => {
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'Allegati non disponbili per questo documento',
                '',
              );
              return EMPTY;
            }),
          )
          .subscribe((result) => {
            if (TransferUtils.isHttpResponse(result.originatingEvent)) {
              const fileName =
                this.documentsSeriesService.getDocSeriesLabel() + '_' + keys['ctelElasticDocumentId'] + '.zip';
              this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.ZIP);
              const blob = new Blob([result.originatingEvent.body], { type: 'application/zip' });
              saveAs(blob, fileName);
            }
          });
        break;
      case ActionCode.HIDE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, [keys], true)
          .pipe(
            take(1),
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(NotificationType.SUCCESS, 'Nascondi documenti', result.message);
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Nascondi documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Nascondi documenti',
                'Errore imprevisto',
              );
            },
          });
        break;

      case ActionCode.RESTORE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, [keys], false)
          .pipe(
            take(1),
            delay(1000),
            tap(() => {
              if (refreshDocuments) this.documentsService.refreshDocuments();
            }),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Ripristina documenti',
                  result.message,
                );
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Ripristina documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Ripristina documenti',
                'Errore imprevisto',
              );
            },
          });
        break;
      //azione singola
      case ActionCode.ASSOCIA_AUTOFATTURA: {
        const licenseId = this.companiesService.getCurrentCompanyValue().licenseId;
        const siaCode = this.companiesService.getCurrentCompanyValue().siaCode;
        const basicPayload = {
          progSpool: keys['progSpool'],
          progBusta: keys['progBusta'],
          docHash: keys['hashDocKey'] ?? ' ',
          ctelElasticDocumentId: keys['ctelElasticDocumentId'],
          ctelDocSeriesId: keys['ctelDocSeriesId'],
        };

        // prende le serie documentali dell'utente
        this.docSeriesService
          .getDocClass(licenseId, siaCode, 'fattureAttive')
          .pipe(
            switchMap((grafiche) => {
              if ((grafiche ?? []).length === 0) return of(null);

              // calls rappresenta la lista delle chiamate da effettuare per ogni grafica (serie doc) ottenuta
              const calls: Observable<Channels>[] = [];
              const graficheClean: string[] = [];

              // filtra le serie Doc, eliminando le "vuote"
              grafiche.forEach((g) => {
                const codGrafica = g.graphicsCode.trim();
                if (codGrafica !== '') {
                  // per fare in modo che le chiamate siano riconducibili (tramite index)
                  // alla propria grafica, le memorizza in una lista con indici combacianti
                  graficheClean.push(codGrafica);
                  calls.push(this.channelsService.getChannels(licenseId, siaCode, codGrafica).pipe(take(1)));
                }
              });
              return combineLatest([of(graficheClean), forkJoin(calls)]);
            }),
            take(1),
          )
          .subscribe(([graficheClean, channels]: [string[], Channels[]]) => {
            let docSeries = '';

            // ogni ch contiene la lista dei canali abilitati a una grafica (serie Doc)
            channels.forEach((ch) => {
              if ((ch.listChannelGed ?? []).length > 0) {
                const found = ch.listChannelGed.find((c) => c.channelName.value === '35');

                if (found) docSeries = graficheClean[channels.indexOf(ch)];
              }
            });

            if (docSeries !== '') {
              const url = [
                '/cloneinvoice/',
                licenseId,
                '/',
                siaCode,
                '/',
                docSeries,
                '/',
                basicPayload.progSpool,
                '/',
                basicPayload.progBusta,
                '/',
                basicPayload.docHash,
              ].join('');

              this.router.navigate([url]).then();
            } else
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Attenzione',
                "Non ci sono serie documentali dedicate all'invio a SDI.",
              );
          });

        break;
      }
      case ActionCode.EDIT_KEYS_DISABLED:
        break;
      case ActionCode.EDIT_KEYS: {
        const documentDetails$ = this.documentDetailsService
          .whenDocumentDetails(
            this.companiesService.getCurrentCompanyValue().licenseId,
            this.companiesService.getCurrentCompanyValue().siaCode,
            keys['ctelDocSeriesId'],
            keys['ctelElasticDocumentId'],
            false,
          )
          .pipe(map((details) => details.metadataList));

        const graphic$ = this.documentDetailsService.whenGraphic(keys['ctelDocSeriesId']);

        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Modifica metadati',
          childComponent: EditKeysModalComponent,
          childData: {
            action,
            keys,
            documentDetails$,
            graphic$,
            refreshDocuments,
          },
          customFooter: true,
        });
        break;
      }
      case ActionCode.ADD_NOTES:
        this.userService
          .getUser()
          .pipe(
            take(1),
            map((user) => user.id),
          )
          .subscribe({
            next: (userId) =>
              this.notificationService.showModal(NotificationType.LARGE, {
                childComponent: DocumentNotesModalComponent,
                title: 'Aggiungi/modifica nota',
                childData: {
                  progSpool: keys['progSpool'],
                  progBusta: keys['progBusta'],
                  docSeriesId: keys['ctelDocSeriesId'],
                  userId,
                },
                customFooter: true,
              }),
          });
        break;
      case ActionCode.DOWNLOAD_HISTORY_STATES:
        this.companiesService
          .whenCurrentCompany()
          .pipe(
            take(1),
            switchMap((company) =>
              this.actionsHttpService.downloadWFHistory(actionUrl, {
                progSpool: keys['progSpool'],
                progBusta: keys['progBusta'],
                license: company.licenseId,
                codSia: company.siaCode,
                idSerieDoc: +keys['ctelDocSeriesId'],
                companyDescription: company.companyDescription,
              }),
            ),
          )
          .subscribe((result) => {
            if (TransferUtils.isHttpResponse(result.originatingEvent)) {
              const fileName = result.name + '.pdf';
              this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.PDF);
              const blob = new Blob([result.originatingEvent.body], { type: 'application/pdf' });
              saveAs(blob, fileName);
            }
          });
        break;
      case ActionCode.MOVE_TO_TRASH:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Sposta nel Cestino',
          'Vuoi spostare nel cestino il documento?',
          () => {
            this.progressBarService.pushRequest();
            this.companiesService
              .whenCurrentCompany()
              .pipe(
                switchMap((company) =>
                  this.actionsHttpService.whenMoveToTrash(actionUrl, {
                    licenseId: company.licenseId,
                    siaCode: company.siaCode,
                    keys: [
                      {
                        progSpool: keys['progSpool'],
                        progBusta: keys['progBusta'],
                        docSeriesId: keys['ctelDocSeriesId'],
                        elasticDocumentId: keys['ctelElasticDocumentId'],
                      },
                    ],
                  }),
                ),
                take(1),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Documento non spostato',
                    'Si sono verificati dei problemi e non è stato possibile spostare il documento nel Cestino',
                  );
                  return EMPTY;
                }),
                delay(1000),
                tap(() => {
                  this.progressBarService.popRequest();
                  this.documentsService.refreshDocuments();
                  this.notificationService.showSweetAlert(
                    NotificationType.SUCCESS,
                    'Documento Spostato',
                    'Il documento è stato correttamente spostato nel Cestino',
                    () => {
                      if (onCompleted) onCompleted();
                    },
                  );
                }),
              )
              .subscribe();
          },
        );
        break;
      default:
        break;
    }
  }

  // ESECUZIONE AZIONI MULTIPLE
  public executeMultiAction(
    action: ActionObject | ActionConfigItem,
    keys: unknown[],
    sectionCode?: string,
    onCompleted?: { (): unknown },
  ) {
    const docCount = keys.length;

    let actionCode = '';
    let actionUrl = '';

    if (action instanceof ActionObject) {
      actionCode = action.actionCode;
      actionUrl = action.url;
    } else {
      actionCode = action.actionCode;
      actionUrl = action.actionUrl;
    }

    const checkedKeys = this.checkboxService.getCheckedKeys().map((v) => JSON.parse(v));

    switch (actionCode) {
      // multi
      case ActionCode.DOWNLOAD_EXCEL: {
        // Modal della scelta del formato dell'excel
        const showModal = () => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: 'Esporta risultati in Excel',
            customFooter: true,
            childComponent: ExcelFormatModalComponent,
            childData: {
              backButton: true,
              action,
              actionType: 'multiple',
              docSeriesDescription: this.documentsSeriesService.getDocSeriesLabel(),
              keys,
              service: 'GAW30',
              sectionCode,
            },
          });
        };

        if (docCount > 50000)
          this.notificationService.showSweetAlert(
            NotificationType.QUESTION,
            'Numero massimo di documenti superato.',
            "Procedere all'esportazione dei primi 50000 documenti?",
            showModal,
          );
        else showModal();

        break;
      }
      case ActionCode.DOWNLOAD_ZIP:
        this.actionsHttpService
          .whenPdfZip(actionUrl, checkedKeys)
          .pipe(
            take(1),
            catchError(() => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Errore durante il download del file .zip',
                '',
              );
              return EMPTY;
            }),
          )
          .subscribe((result) => {
            if (TransferUtils.isHttpResponse(result.originatingEvent)) {
              const fileName = this.documentsSeriesService.getDocSeriesLabel() + '.zip';
              this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.ZIP);
              const blob = new Blob([result.originatingEvent.body], { type: 'application/zip' });
              saveAs(blob, fileName);
            }
          });
        break;

      case ActionCode.DOWNLOAD_XML_ZIP:
        this.actionsHttpService
          .whenMultiXml(actionUrl, checkedKeys)
          .pipe(
            take(1),
            catchError(() => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Errore durante il download dei file XML',
                '',
              );
              return EMPTY;
            }),
          )
          .subscribe((result) => {
            // eslint-disable-next-line curly
            if (result !== null) {
              if (TransferUtils.isHttpResponse(result.originatingEvent)) {
                const filename = HttpHeadersFilename.getFilenameFromHttpHeaders(result.originatingEvent);
                this.transferService.updateTransfer(result.key, filename, FileSaverExtension.ZIP);
                const blob = new Blob([result.originatingEvent.body], { type: 'application/zip' });
                new FileSaver(blob).saveAs(filename, FileSaverExtension.ZIP);
              } else
                this.notificationService.showSweetAlert(
                  NotificationType.INFO,
                  'Uno o più documenti non sono disponibili',
                  "L'archivio non verrà scaricato",
                );
            } else
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'Uno o più documenti non sono disponibili',
                "L'archivio non verrà scaricato",
              );
          });
        break;
      case ActionCode.DOWNLOAD_MERGED_PDF:
        this.actionsHttpService
          .whenMergedPdf(actionUrl, checkedKeys)
          .pipe(
            take(1),
            catchError(() => {
              this.notificationService.showSweetAlert(NotificationType.ERROR, 'Errore durante il download del PDF', '');
              return EMPTY;
            }),
          )
          .subscribe((result) => {
            if (TransferUtils.isHttpResponse(result.originatingEvent)) {
              const fileName = this.documentsSeriesService.getDocSeriesLabel() + '_merged.pdf';
              this.transferService.updateTransfer(result.key, fileName, FileSaverExtension.PDF);
              const blob = new Blob([result.originatingEvent.body], { type: 'application/pdf' });
              saveAs(blob, fileName);
            }
          });
        break;
      case ActionCode.START_WORKFLOW:
        this.workflowService.sendCreationSuccess('start');
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Scegli una Attività',
          childComponent: DocumentsWorkflowModalComponent,
          childData: checkedKeys,
        });
        break;
      // multi
      case ActionCode.HIDE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, keys, true)
          .pipe(
            take(1),
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(NotificationType.SUCCESS, 'Nascondi documenti', result.message);
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Nascondi documenti', result.message);
            },
            error: () => {
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Nascondi documenti',
                'Errore imprevisto',
              );
            },
          });
        break;
      // multi
      case ActionCode.RESTORE_DOCUMENTS:
        this.actionsHttpService
          .whenHideAndShowDocuments(actionUrl, keys, false)
          .pipe(
            take(1),
            delay(1000),
            tap(() => this.documentsService.refreshDocuments()),
          )
          .subscribe({
            next: (result) => {
              if (result.esitoOK)
                this.notificationService.showSweetAlert(
                  NotificationType.SUCCESS,
                  'Ripristina documenti',
                  result.message,
                );
              else
                this.notificationService.showSweetAlert(NotificationType.ERROR, 'Ripristina documenti', result.message);
            },
            error: () =>
              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                'Ripristina documenti',
                'Errore imprevisto',
              ),
          });
        break;
      case ActionCode.MOVE_TO_TRASH:
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Sposta nel Cestino',
          'Vuoi spostare nel cestino i documenti selezionati?',
          () => {
            this.progressBarService.pushRequest();
            this.companiesService
              .whenCurrentCompany()
              .pipe(
                switchMap((company) =>
                  this.actionsHttpService.whenMoveToTrash(actionUrl, {
                    licenseId: company.licenseId,
                    siaCode: company.siaCode,
                    keys: keys.map((doc) => ({
                      progSpool: doc['progSpool'],
                      progBusta: doc['progBusta'],
                      docSeriesId: doc['ctelDocSeriesId'],
                      elasticDocumentId: doc['ctelElasticDocumentId'],
                    })),
                  }),
                ),
                take(1),
                catchError(() => {
                  this.progressBarService.popRequest();
                  this.notificationService.showSweetAlert(
                    NotificationType.ERROR,
                    'Documenti non spostati',
                    'Si sono verificati dei problemi e non è stato possibile spostare i documenti nel Cestino',
                  );
                  return EMPTY;
                }),
                delay(1000),
                tap(() => {
                  this.progressBarService.popRequest();
                  this.documentsService.refreshDocuments();
                  this.notificationService.showSweetAlert(
                    NotificationType.SUCCESS,
                    'Documenti Spostati',
                    'I documenti sono stati correttamente spostati nel Cestino',
                    () => {
                      if (onCompleted) onCompleted();
                    },
                  );
                }),
              )
              .subscribe();
          },
        );
        break;
    }
  }

  private catchError(err: AppError): Observable<null> {
    if (err.type === ErrorTypes.HTTP_UNAUTHORIZED) return of(null);
    else return of(null);
  }
}
