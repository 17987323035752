<pre *ngIf="asyncCode$ | async as asyncCode; else staticCode">
	<code [ngClass]="language" [innerHTML]="asyncCode | json"></code>
</pre>

<ng-template #staticCode>
  <pre *ngIf="code; else emptyCode">
		<code [ngClass]="language" [innerHTML]="code | json"></code>
	</pre>
  <ng-template #emptyCode>
    <pre>
			<code [ngClass]="language">Codice non disponibile</code>
		</pre>
  </ng-template>
</ng-template>
