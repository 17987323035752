import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TipoDocumentoModalComponent } from '@ctel/hubfe-commons';
import {
  AbstractFilterEffects,
  DocumentActions,
  FilterAction,
  FilterActions,
  FilterState,
  IAdvancedTextSearchUrl,
  IFilterStatus,
  IFilterUrl,
  IFullTextSearchUrl,
  IOrderByUrl,
  IOuterFilterUrl,
  IPagingUrl,
  IRouterStateUrl,
  getRouterInfo,
} from '@ctel/search-filter-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { crossSearchConfig } from 'app/constants/filters/filter-cross-config';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { DocumentsService2 } from 'app/modules/homepage/core/documents-search/documents/documents.service';
import { FilterService2 } from 'app/modules/homepage/core/documents-search/filters/filter.service';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import ChangeFilterRequestedKind = FilterActions.ChangeFilterRequestedKind;

@Injectable({
  providedIn: 'root',
})
export class FilterEffects extends AbstractFilterEffects {
  /**
   * Effetto che gestisce il side effect di aver fetchato i filtri preferiti,
   * e per i quali è necessario recuperare nuovi documenti.
   */
  onFavoriteFiltersFetched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favoriteFiltersFetched),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gaw/') !== -1),
      switchMap(() => [DocumentActions.fetchDocuments(true), DocumentActions.fetchDocumentsActions()]),
    ),
  );

  /**
   * Effetto che gestisce il side effect della richiesta dei filtri preferiti,
   * che è necessario recuperare.
   */
  onFavouritesFiltersRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.favouritesFiltersRequested),
      withLatestFrom(this.store.pipe(select(getRouterInfo))),
      filter(([, routerInfo]) => routerInfo.state.url.indexOf('gaw/documents/') !== -1),
      map(([, routerInfo]) => {
        const newFilterStatus: IFilterStatus = {
          docSeriesId: routerInfo.state.params['doctype'],
          siaCode: routerInfo.state.params['siacode'],
          licenseId: routerInfo.state.params['license'],
          filters: crossSearchConfig.filters,
          search: crossSearchConfig.search,
          paging: crossSearchConfig.paging,
          orderBy: crossSearchConfig.orderBy,
        };
        return FilterActions.favoriteFiltersFetched({ filterStatus: newFilterStatus });
      }),
      catchError((error: unknown) => this.handleError(error)),
    ),
  );

  onMostraTutti$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FilterActions.editFilterRequested),
        withLatestFrom(this.store.pipe(select(getRouterInfo))),
        filter(
          ([, routerInfo]) =>
            routerInfo.state.url.indexOf('gawfe/') !== -1 || routerInfo.state.url.indexOf('gawedi/') !== -1,
        ),
        tap(([action]) => {
          this.notificationService.showModal(NotificationType.GENERAL, {
            title: action.filter.metadataDescription,
            customFooter: true,
            childComponent: TipoDocumentoModalComponent,
            childData: action,
            disableClickOutside: false,
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    protected actions$: Actions,
    protected store: Store<FilterState>,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private documentsService: DocumentsService2,
    protected filterService: FilterService2,
    protected ngZone: NgZone,
  ) {
    super(actions$, store, filterService, router, activatedRoute, ngZone);
  }

  protected getDocumentDetailUrl(): string {
    return '/details/';
  }

  protected getDocumentsListUrl(): string {
    return '/gaw/documents/';
  }

  protected getUrlCommands(routerInfo: { state: IRouterStateUrl; navigationId: number }): string[] {
    const urlCommands: string[] = [];
    urlCommands.push(this.getDocumentsListUrl());
    urlCommands.push(routerInfo.state.params['license']);
    urlCommands.push(routerInfo.state.params['siacode']);
    urlCommands.push(routerInfo.state.params['section']);
    return urlCommands;
  }

  protected doOnChangeFilterRequested(
    action: FilterAction,
    routerInfo: { state: IRouterStateUrl; navigationId: number },
    filtersUrl: IFilterUrl[],
    outerFiltersUrl: IOuterFilterUrl[],
    pagingFiltersUrl: IPagingUrl,
    orderByFiltersUrl: IOrderByUrl[],
    fullTextSearchUrl: IFullTextSearchUrl,
    advancedTextSearchUrl: IAdvancedTextSearchUrl[],
  ): void {
    if (routerInfo.state.url.indexOf('gaw/') === -1) return;

    if (action.kind === ChangeFilterRequestedKind.PagingChanged) this.documentsService.setLoadingDocsOnPaging(true);
    else {
      this.documentsService.setLoadingDocsAfterFilterApplication(true);
      this.documentsService.setLoadingDocsOnPaging(false);
    }
    super.doOnChangeFilterRequested(
      action,
      routerInfo,
      filtersUrl,
      outerFiltersUrl,
      pagingFiltersUrl,
      orderByFiltersUrl,
      fullTextSearchUrl,
      advancedTextSearchUrl,
    );
  }

  protected getParamsDistinctCriteria(prev: Params, succ: Params): boolean {
    if (this.router.url.indexOf(this.getDocumentsListUrl()) === -1) return false;

    return super.getParamsDistinctCriteria(prev, succ);
  }
}
