import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { saveAs } from 'file-saver';

export class FileSaver {
  private readonly b: Blob;

  constructor(blob: Blob | { blobParts: any[]; options?: BlobPropertyBag }) {
    if (blob instanceof Blob) this.b = blob;
    else this.b = new Blob(blob.blobParts, blob.options);
  }

  static save(file: File) {
    saveAs(file, file.name);
  }

  saveAs(fileName: string, extension?: FileSaverExtension) {
    // se il filename ha già l'estensione, non la aggiungo
    if (fileName.toLocaleLowerCase().endsWith('_')) fileName = fileName.substring(0, fileName.length - 1);

    if (fileName.toLocaleLowerCase().endsWith(extension)) extension = FileSaverExtension.NONE;

    saveAs(this.b, fileName + (extension || ''));
  }
}
