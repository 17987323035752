import { Injectable, OnDestroy } from '@angular/core';
import { FilterPayload, ServiceType } from '@ctel/gaw-commons';
import { FilterState } from '@ctel/search-filter-store';
import { TransferService, TransferUtils } from '@ctel/transfer-manager';
import { Transfer } from '@ctel/transfer-manager/lib/entities/transfer';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AccountType } from 'app/core/common/account/accountType';
import { AppErrorBuilder } from 'app/core/common/error/app-error-builder';
import { ErrorTypes } from 'app/core/common/error/error-types';
import { ActionConfirmationModalComponent } from 'app/core/common/modals/action-confirmation-modal/action-confirmation-modal.component';
import { ExcelFormatModalComponent } from 'app/core/common/modals/excel-format-modal/excel-format-modal.component';
import { SignDocModalComponent } from 'app/core/common/modals/sign-doc-modal/sign-doc-modal.component';
import { NotificationType } from 'app/core/common/notification';
import { NotificationService } from 'app/core/common/notification/notification.service';
import { FileSaver } from 'app/core/common/utilities/file-saver/file-saver';
import { FileSaverExtension } from 'app/core/common/utilities/file-saver/file-saver-extension.enum';
import { Extension } from 'app/core/common/utilities/file/file';
import { ActionObject } from 'app/entities/ui-config/action/action-object';
import { ActionType } from 'app/entities/ui-config/action/action-type.enum';
import {
  Channel,
  DocType,
  MassiveActionRequest,
  MassiveActionType,
} from 'app/entities/ui-config/action/massive-action-request';
import { SectionCode } from 'app/entities/ui-config/classification-code.enum';
import { EMPTY, Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, count, delay, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from '../../../account/account.service';
import { DocumentDetailsService } from '../../document-details/document-details.service';
import { DocumentsService } from '../../documents/documents.service';
import { FilterService } from '../../filters/filter.service';
import { ActionsHttpService } from '../actions-http.service';

/**
 * Callback delle azioni massive
 */
@Injectable({
  providedIn: 'root',
})
export class AllDocumentsCallback implements OnDestroy {
  private readonly _currentClassification$: Observable<string>;
  private readonly _filterPayload$: Observable<FilterPayload>;
  private readonly _totalDocuments$: Observable<number>;
  private readonly _actualServiceId$: Observable<string>;
  private concurrentDownloads = 0;
  private destroy$ = new Subject<void>();

  constructor(
    protected actions$: Actions,
    protected store: Store<FilterState>,
    private documentsService: DocumentsService,
    private documentDetailsService: DocumentDetailsService,
    private filterService: FilterService,
    private notificationService: NotificationService,
    private actionsHttpService: ActionsHttpService,
    private accountService: AccountService,
    private transferService: TransferService,
  ) {
    this._currentClassification$ = documentsService.whenCurrentClassification();
    this._totalDocuments$ = documentsService.whenTotalDocuments();
    this._filterPayload$ = filterService.whenFilterPayload();
    this._actualServiceId$ = documentsService.whenActualServiceId();
    this.transferService
      .whenTransfers()
      .pipe(
        switchMap((value) =>
          of(Object.values<Transfer>(value)).pipe(
            mergeMap((value1) => value1),
            count((v) => !TransferUtils.isComplete(v)),
            tap((v) => (this.concurrentDownloads = v)),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Funzione di esporta excel massivo
   * @param action
   */
  public exportXLSX(action: ActionObject): Observable<any> {
    if (this.concurrentDownloads >= 2) {
      this.notificationService.showSweetAlert(
        NotificationType.INFO,
        'Attenzione',
        'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva',
      );
      return EMPTY;
    }

    const account =
      this.accountService.getCurrentAccount() === AccountType.PAYABLE
        ? 'ExportExcelCicloPassivo'
        : 'ExportExcelCicloAttivo';

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap(() =>
        combineLatest([this._filterPayload$, this._currentClassification$]).pipe(
          take(1),
          switchMap(([payload, classification]) => {
            // Modal della scelta del formato dell'excel
            const showModal = () => {
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Esporta risultati in Excel',
                customFooter: true,
                childComponent: ExcelFormatModalComponent,
                childData: {
                  backButton: true,
                  action,
                  actionType: 'massive',
                  accountType: account,
                  classification,
                  massivePayload: payload,
                  service: 'GAWFE',
                },
                disableClickOutside: false,
              });
            };

            showModal();
            return EMPTY;
          }),
        ),
      ),
    );
  }

  public exportEsitiExcel(action: ActionObject): Observable<any> {
    if (this.concurrentDownloads >= 2) {
      this.notificationService.showSweetAlert(
        NotificationType.INFO,
        'Attenzione',
        'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva',
      );
      return EMPTY;
    }

    const account =
      this.accountService.getCurrentAccount() === AccountType.PAYABLE
        ? 'ExportExcelCicloPassivo'
        : 'ExportExcelCicloAttivo';

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap(() =>
        combineLatest([this._filterPayload$, this._currentClassification$]).pipe(
          take(1),
          switchMap(([payload, classification]) => {
            // Modal della scelta del formato dell'excel
            const showModal = () => {
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Esporta risultati in Excel',
                customFooter: true,
                childComponent: ExcelFormatModalComponent,
                childData: {
                  backButton: true,
                  action,
                  actionType: 'massive',
                  accountType: account,
                  classification,
                  massivePayload: payload,
                  service: 'HUBFE-Esiti',
                },
                disableClickOutside: false,
              });
            };

            showModal();
            return EMPTY;
          }),
        ),
      ),
    );
  }

  /**
   * Funzione di firma massiva
   * @param action
   */
  public signDocs(action: ActionObject): Observable<any> {
    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    return this._filterPayload$.pipe(
      tap((filterPayload) => {
        this.notificationService.showModal(NotificationType.GENERAL, {
          title: 'Firma',
          customFooter: true,
          childComponent: SignDocModalComponent,
          childData: {
            payload: {
              elasticPayload: filterPayload,
            },
            actionUrl: action.url,
            actionType: ActionType.MASSIVE,
            refreshDocuments: true,
          },
          disableClickOutside: true,
        });
      }),
    );
  }

  public asyncDownloadMassivePDF(): Observable<any> {
    if (this.blockMoreThan2Request()) return EMPTY;

    const onConfirm = () =>
      combineLatest([this._filterPayload$, this._actualServiceId$, this.accountService.whenCurrentAccount()]).pipe(
        take(1),
        switchMap(([payload, serviceId, currentAccount]) => {
          const account = currentAccount === AccountType.PAYABLE ? DocType.PASSIVO : DocType.ATTIVO;
          const service = this.getService(serviceId, account);

          return this.actionsHttpService.asyncPDFMassiveDownload(payload, account, service).pipe(
            catchError((err: unknown) => {
              if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Uno o più documenti non trovati. Lo zip non verrà scaricato',
                  '',
                );
                return EMPTY;
              }

              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                "Errore durante l'esecuzione dell'azione",
                '',
              );
              return EMPTY;
            }),
            tap(() => {
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'La richiesta di esportazione è stata presa in carico. Riceverai una notifica quando verrà completata.',
                '',
              );
            }),
          );
        }),
      );

    this.notificationService.showSweetAlert(
      NotificationType.QUESTION,
      'Attenzione:',
      `La richiesta potrebbe superare il limite consentito di 100.000 documenti o 2GB di dati.<br>
				Lo zip scaricato potrebbe non contenere tutti i documenti.
				In ogni caso l'operazione avverrà in differita e si riceverà una notifica e una email al termine del processo.`,
      () => onConfirm().pipe(take(1)).subscribe(),
    );

    return EMPTY;
  }

  asyncDownloadMassiveXML() {
    if (this.blockMoreThan2Request()) return EMPTY;

    const onConfirm = () =>
      combineLatest([this._filterPayload$, this._actualServiceId$, this.accountService.whenCurrentAccount()]).pipe(
        take(1),
        switchMap(([payload, serviceId, currentAccount]) => {
          const account = currentAccount === AccountType.PAYABLE ? DocType.PASSIVO : DocType.ATTIVO;
          const service = this.getService(serviceId, account);
          return this.actionsHttpService.asyncXMLMassiveDownload(payload, account, service).pipe(
            catchError((err: unknown) => {
              if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
                this.notificationService.showSweetAlert(
                  NotificationType.ERROR,
                  'Uno o più documenti non trovati. Lo zip non verrà scaricato',
                  '',
                );
                return EMPTY;
              }

              this.notificationService.showSweetAlert(
                NotificationType.ERROR,
                "Errore durante l'esecuzione dell'azione",
                '',
              );
              return EMPTY;
            }),
            tap(() => {
              this.notificationService.showSweetAlert(
                NotificationType.INFO,
                'La richiesta di esportazione è stata presa in carico. Riceverai una notifica quando verrà completata.',
                '',
              );
            }),
          );
        }),
      );

    this.notificationService.showSweetAlert(
      NotificationType.QUESTION,
      'Attenzione:',
      `La richiesta potrebbe superare il limite consentito di 100.000 documenti o 2GB di dati.<br>
				Lo zip scaricato potrebbe non contenere tutti i documenti.
				In ogni caso l'operazione avverrà in differita e si riceverà una notifica e una email al termine del processo.`,
      () => onConfirm().pipe(take(1)).subscribe(),
    );

    return EMPTY;
  }

  /**
   * Funzione di scarica zip PDF massiva
   * @param action
   */
  public downloadPdfZip(action: ActionObject): Observable<any> {
    if (this.concurrentDownloads >= 2) {
      this.notificationService.showSweetAlert(
        NotificationType.INFO,
        'Attenzione',
        'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva',
      );
      return EMPTY;
    }

    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    const saveZip = (response: Transfer, classification: string) => {
      // TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
      // si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
      // transfer.
      let fileName = classification;
      if (classification === SectionCode.RECEIVABLE)
        fileName = `CA_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;
      else if (classification === SectionCode.PAYABLE)
        fileName = `CP_PDF_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;

      this.transferService.updateTransfer(response.key, fileName, FileSaverExtension.ZIP);
      if (TransferUtils.isHttpResponse(response.originatingEvent)) {
        const blob = new Blob([response.originatingEvent.body], { type: 'application/zip' });
        new FileSaver(blob).saveAs(fileName, FileSaverExtension.ZIP);
      } else
        throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
          .description('Oggetto evento originario non valido')
          .info('transfer', response)
          .build();
    };

    const downloadZip$ = (payload, serviceId) => {
      const body = this.buildPayloadForMassiveRequest(serviceId, payload, MassiveActionType.DOWNLOAD, 'Pdf');

      return combineLatest([this.actionsHttpService.whenPdfZip(action.url, body), this._currentClassification$]).pipe(
        tap(([response, classification]) => saveZip(response, classification)),
        catchError((err: unknown) => {
          if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
            //this.router.navigate(['/unauthorized']).then();
          }
          if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Uno o più documenti non trovati. Lo zip non verrà scaricato',
              '',
            );

            return EMPTY;
          }
          if (err?.['type'] === ErrorTypes.HTTP_ERROR) {
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore nel download di uno o più documenti',
              '',
            );

            return EMPTY;
          }
          return throwError(() => err);
        }),
      );
    };

    return combineLatest([this._filterPayload$, this._actualServiceId$]).pipe(
      take(1),
      tap(([payload, serviceId]) => {
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Attenzione:',
          `
							Il download potrebbe superare il limite consentito di 500MB.
							<br>
							Lo zip scaricato potrebbe non contenere tutti i documenti
						`,
          () => downloadZip$(payload, serviceId).pipe(take(1)).subscribe(),
        );
      }),
    );
  }

  /**
   * Funzione di scarica zip XML massiva
   * @param action
   */
  public downloadXmlZip(action: ActionObject): Observable<any> {
    if (this.concurrentDownloads >= 2) {
      this.notificationService.showSweetAlert(
        NotificationType.INFO,
        'Attenzione',
        'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva',
      );
      return EMPTY;
    }

    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    const saveZip = (response: Transfer, classification: string) => {
      // TODO: quando per le massive/multiple ci saranno dei filename sensati negli header,
      // si avrà tale filename già disponibile dentro r.name e non sarà più necessario fare un update
      // transfer.
      let fileName = classification;
      if (classification === SectionCode.RECEIVABLE)
        fileName = `CA_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;
      else if (classification === SectionCode.PAYABLE)
        fileName = `CP_XML_Aggregato_${this.formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2()}.zip`;

      this.transferService.updateTransfer(response.key, fileName, FileSaverExtension.ZIP);
      if (TransferUtils.isHttpResponse(response.originatingEvent)) {
        const blob = new Blob([response.originatingEvent.body], { type: 'application/zip' });
        new FileSaver(blob).saveAs(fileName, FileSaverExtension.ZIP);
      } else
        throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
          .description('Evento originario non valido')
          .info('transfer', response)
          .build();
    };

    const downloadZip$ = (payload, serviceId) => {
      const body = this.buildPayloadForMassiveRequest(serviceId, payload, MassiveActionType.DOWNLOAD, 'Xml');

      return combineLatest([this.actionsHttpService.whenPdfZip(action.url, body), this._currentClassification$]).pipe(
        tap(([response, classification]) => saveZip(response, classification)),
        catchError((err: unknown) => {
          if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
            //this.router.navigate(['/unauthorized']).then();
          }
          if (err?.['type'] === ErrorTypes.HTTP_NOT_FOUND) {
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Uno o più documenti non trovati. Lo zip non verrà scaricato',
              '',
            );

            return EMPTY;
          }
          if (err?.['type'] === ErrorTypes.HTTP_ERROR) {
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              'Errore nel download di uno o più documenti',
              '',
            );

            return EMPTY;
          }
          return throwError(() => err);
        }),
      );
    };

    return combineLatest([this._filterPayload$, this._actualServiceId$]).pipe(
      take(1),
      tap(([payload, serviceId]) => {
        this.notificationService.showSweetAlert(
          NotificationType.QUESTION,
          'Attenzione:',
          `
							Il download potrebbe superare il limite consentito di 500MB.
							<br>
							Lo zip scaricato potrebbe non contenere tutti i documenti
						`,
          () => downloadZip$(payload, serviceId).pipe(take(1)).subscribe(),
        );
      }),
    );
  }

  /**
   * Funzione di esporta al gestionale massiva
   * @param action
   */
  public exportToManagementSW(action: ActionObject): Observable<any> {
    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    const errorAction$ = (err) => {
      if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
        //this.router.navigate(['/unauthorized']).then();
      }
      this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore durante l'esecuzione dell'azione", '');
      return EMPTY;
    };

    const executeAction$ = (payload) => {
      const body = this.buildPayloadForExportAndHideShowDocuments(payload);
      return this.actionsHttpService.whenExportManagementMassive(action.url, body).pipe(
        tap((response) => {
          if (response.esitoOK)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Richiesta di esportazione verso il gestionale eseguita correttamente',
              '',
            );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              "Errore durante l'esecuzione dell'azione",
              response.message,
            );
        }),
        delay(1000),
        tap(() => this.documentsService.refreshDocuments()),
        catchError((err: unknown) => errorAction$(err)),
      );
    };

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap((docCount) =>
        this._filterPayload$.pipe(
          take(1),
          switchMap((filterPayload) => {
            //TODO TAM: deve diventare uno sweet alert
            this.notificationService.showModal(NotificationType.GENERAL, {
              title: 'Esporta verso gestionale',
              childComponent: ActionConfirmationModalComponent,
              customFooter: true,
              childData: {
                action: executeAction$(filterPayload),
                title: 'Esporta verso gestionale',
                docCount,
                maxDocCount: 10000,
              },
              disableClickOutside: false,
            });
            return EMPTY;
          }),
        ),
      ),
    );
  }

  /**
   * Funzione di annulla massiva
   * @param action
   * @param service
   */
  public cancelElab(action: ActionObject, service: string): Observable<any> {
    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();
    const errorAction$ = (err) => {
      if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
        //this.router.navigate(['/unauthorized']).then();
      }

      this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore durante l'esecuzione dell'azione", '');
      return EMPTY;
    };

    const executeAction$ = (payload) => {
      const body = this.buildPayloadForMassiveCancel(service, payload);
      return this.actionsHttpService.whenCancelAll(action.url, body).pipe(
        tap((response) => {
          if (response.esitoOK === true)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Richiesta annullamento eseguita correttamente',
              '',
            );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              "Errore durante l'esecuzione dell'azione",
              response.message,
            );
        }),
        delay(1000),
        tap(() => this.documentsService.refreshDocuments()),
        catchError((err: unknown) => errorAction$(err)),
      );
    };

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap((docCount) =>
        this._filterPayload$.pipe(
          take(1),
          switchMap((filterPayload) => {
            //TODO TAM: deve diventare uno sweet alert
            this.notificationService.showModal(NotificationType.GENERAL, {
              title: 'Annulla',
              childComponent: ActionConfirmationModalComponent,
              customFooter: true,
              childData: {
                action: () => executeAction$(filterPayload),
                title: 'Annulla',
                docCount,
                maxDocCount: 10000,
              },
              disableClickOutside: false,
            });
            return EMPTY;
          }),
        ),
      ),
    );
  }

  /**
   * Funzione di nascondi documenti
   * @param action
   * @param hide
   */
  public hideAndRestoreMassiveDocuments(action: ActionObject, hide: boolean): Observable<any> {
    if (!this.checkActionInput(action))
      throw new AppErrorBuilder(ErrorTypes.INVALID_OBJECT)
        .description('Azione non valida')
        .info('action', action)
        .build();

    const errorAction$ = (err) => {
      if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED) {
        //this.router.navigate(['/unauthorized']).then();
      }
      this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore durante l'esecuzione dell'azione", '');
      return EMPTY;
    };

    const executeAction$ = (payload) => {
      const body = this.buildPayloadForExportAndHideShowDocuments(payload, true);
      return this.actionsHttpService.whenHideAndShowDocumentsMassive(action.url, body, hide).pipe(
        take(1),
        tap((response) => {
          // se l'azione è nascondi documenti
          if (response.esitoOK && hide)
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Documenti nascosti con successo',
              response.message,
            );
          else if (response.esitoOK && !hide)
            // se l'azione è ripristina documenti
            this.notificationService.showSweetAlert(
              NotificationType.SUCCESS,
              'Documenti ripristinati con successo',
              response.message,
            );
          else
            this.notificationService.showSweetAlert(
              NotificationType.ERROR,
              "Errore durante l'esecuzione dell'azione",
              response.message,
            );
        }),
        // TAM: DECOMMENTARE PER AZIONI MASSIVE
        delay(1000),
        tap(() => this.documentsService.refreshDocuments()),
        catchError((err: unknown) => errorAction$(err)),
      );
    };

    return this._totalDocuments$.pipe(
      take(1),
      mergeMap((docCount) =>
        this._filterPayload$.pipe(
          take(1),
          switchMap((filterPayload) => {
            // se l'azione è nascondi documenti
            if (hide)
              //TODO TAM: deve diventare uno sweet alert
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Nascondi documenti',
                childComponent: ActionConfirmationModalComponent,
                customFooter: true,
                childData: {
                  action: executeAction$(filterPayload),
                  title: 'Nascondi documenti',
                  docCount,
                  maxDocCount: 10000,
                },
                disableClickOutside: false,
              });
            // se l'azione è ripristina documenti
            //TODO TAM: deve diventare uno sweet alert
            else
              this.notificationService.showModal(NotificationType.GENERAL, {
                title: 'Ripristina documenti',
                childComponent: ActionConfirmationModalComponent,
                customFooter: true,
                childData: {
                  action: executeAction$(filterPayload),
                  title: 'Ripristina documenti',
                  docCount,
                  maxDocCount: 10000,
                },
                disableClickOutside: false,
              });

            return EMPTY;
          }),
        ),
      ),
    );
  }

  asyncDownloadMassiveEsiti(action: ActionObject, extension) {
    let ESITI_MASSIVE_LIMIT;
    switch (extension) {
      case Extension.XML:
        ESITI_MASSIVE_LIMIT = 100000;
        break;
      case Extension.PDF:
        ESITI_MASSIVE_LIMIT = 10000;
        break;
    }
    return combineLatest([
      this._filterPayload$,
      this._actualServiceId$,
      this._totalDocuments$,
      this.accountService.whenCurrentAccount(),
    ]).pipe(
      take(1),
      switchMap(([payload, serviceId, totalDocs, accountType]) => {
        if (totalDocs > ESITI_MASSIVE_LIMIT) {
          this.notificationService.showSweetAlert(
            NotificationType.ERROR,
            'Attenzione',
            `Sono stati selezionati più di ${ESITI_MASSIVE_LIMIT} documenti. Non è possibile proseguire con la richiesta. Per poter procedere bisogna selezionare un numero inferiore di documenti`,
          );
          return EMPTY;
        } else {
          const account = accountType === AccountType.PAYABLE ? DocType.PASSIVO : DocType.ATTIVO;
          const body = this.buildPayloadForMassiveRequest(
            ServiceType.HUBFE,
            payload,
            MassiveActionType.DOWNLOAD,
            extension,
          );
          body.channel = this.getService(serviceId, account);
          return this.actionsHttpService.asyncDownloadMassiveEsiti(action, body);
        }
      }),
      tap(() => {
        this.notificationService.showSweetAlert(
          NotificationType.INFO,
          'La richiesta di esportazione è stata presa in carico. Riceverai una notifica quando verrà completata.',
          '',
        );
      }),
      catchError(() => {
        this.notificationService.showSweetAlert(NotificationType.ERROR, "Errore durante l'esecuzione dell'azione", '');
        return EMPTY;
      }),
    );
  }

  exportToManagementFromUnpreservedMassive(action: ActionObject) {
    const actionUrl = action.url;

    this.notificationService.showSweetAlert(
      NotificationType.QUESTION,
      'Documenti da Conservare',
      'Le fatture selezionate saranno spostate nella sezione "Esportate verso gestionale e da protocollare" per essere protocollate e conservate. Vuoi procedere?',
      () => {
        this._filterPayload$
          .pipe(
            take(1),
            switchMap((filter) =>
              this.actionsHttpService.whenExportToManagementFromUnpreservedMassive(actionUrl, filter).pipe(
                take(1),
                catchError((err: unknown) => {
                  if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
                    //this.router.navigate(['/unauthorized']).then();
                    return of(null);

                  return of(null);
                }),
                tap((res) => {
                  if (res === null)
                    this.notificationService.showSweetAlert(
                      NotificationType.ERROR,
                      'Errore',
                      'Documenti non esportati',
                    );
                  else
                    this.notificationService.showSweetAlert(
                      NotificationType.SUCCESS,
                      'Documenti esportati correttamente',
                      '',
                    );
                }),
                delay(1000),
                tap(() => {
                  this.documentsService.refreshDocuments();
                }),
              ),
            ),
          )
          .subscribe();
      },
    );
    return EMPTY;
  }

  excludeFromConsMassive(action: ActionObject) {
    const actionUrl = action.url;

    this.notificationService.showSweetAlert(
      NotificationType.QUESTION,
      'Escludi dalla Conservazione',
      'Le fatture selezionate saranno escluse dalla Conservazione Digitale. Vuoi procedere?',
      () => {
        this._filterPayload$
          .pipe(
            take(1),
            switchMap((filter) =>
              this.actionsHttpService.excludeFromCons(actionUrl, filter).pipe(
                take(1),
                catchError((err: unknown) => {
                  if (err?.['type'] === ErrorTypes.HTTP_UNAUTHORIZED)
                    //this.router.navigate(['/unauthorized']).then();
                    return of(null);

                  return of(null);
                }),
                tap((res) => {
                  if (res === null)
                    this.notificationService.showSweetAlert(
                      NotificationType.ERROR,
                      'Errore',
                      "Non è stato possibile eseguire l'azione.\nTi invitiamo a riprovare più tardi. Se il problema dovesse persistere, ti suggeriamo di contattare l'Assistenza Clienti",
                    );
                  else
                    this.notificationService.showSweetAlert(
                      NotificationType.SUCCESS,
                      'Documenti esclusi dalla Conservazione con successo',
                      '',
                    );
                }),
                delay(1000),
                tap(() => this.documentsService.refreshDocuments()),
              ),
            ),
          )
          .subscribe();
      },
    );
    return EMPTY;
  }

  // Controllo sull'azione in input, ritorna true se l'url è valido
  // noinspection JSMethodCanBeStatic
  private checkActionInput(action): boolean {
    return !!(action && action.url);
  }

  // Crea il payload da passare alla richiste di azioni massive
  private buildPayloadForMassiveRequest(
    currentService: string,
    payload: FilterPayload,
    actionType: MassiveActionType,
    fileType: string,
  ): MassiveActionRequest {
    const account = this.accountService.getCurrentAccount() === AccountType.PAYABLE ? DocType.PASSIVO : DocType.ATTIVO;
    const service = this.getService(currentService, account);
    return <MassiveActionRequest>{
      elasticRequest: payload,
      action: actionType,
      docType: account,
      fileType,
      channel: service,
    };
  }

  // Crea il payload da passare alla richiste di annulla massivo
  private buildPayloadForMassiveCancel(service: string, payload: FilterPayload): MassiveActionRequest {
    return <MassiveActionRequest>{
      elasticRequest: payload,
      channel: service,
    };
  }

  // Crea il payload da passare alla richiesta di esporta verso gestionale massivo o per nascondi/ripristina documenti massivo
  private buildPayloadForExportAndHideShowDocuments(
    payload: FilterPayload,
    hideAndShowDocuments = false,
  ): MassiveActionRequest {
    const account = this.accountService.getCurrentAccount() === AccountType.PAYABLE ? DocType.PASSIVO : DocType.ATTIVO;
    // se sto costruendo il payload per nascondi/ripristina documenti
    if (hideAndShowDocuments)
      return <MassiveActionRequest>{
        elasticRequest: payload,
      };
    else
      return <MassiveActionRequest>{
        elasticRequest: payload,
        channel: 'GEDPASSJOIN',
        docType: account,
      };
  }

  // noinspection JSMethodCanBeStatic
  private getService(serviceId: string, account: DocType): string {
    if (account === DocType.PASSIVO) return Channel.GEDPASSJOIN;

    if (serviceId === '0') return Channel.PREMULTI;

    if (serviceId === '06') return Channel.GEDPOST;

    if (serviceId === '21') return Channel.GEDMAIL;

    if (serviceId === '22') return Channel.GEDPEC;

    if (serviceId === '35') return Channel.GEDINVOICE;
  }

  // Elimina anche l'1.
  private formatDownloadDateAsTheyWantKeepingInMindTheBackendShouldGiveMeTheRightFileName2(): string {
    let currentDate = new Date();
    const offset = currentDate.getTimezoneOffset();
    const dir = offset >= 0 ? 1 : -1; // Se siam prima o dopo lo 0, bisogna aggiungere o sottrarre.
    currentDate = new Date(currentDate.getTime() + dir * currentDate.getTimezoneOffset() * 60 * 1000);
    return currentDate
      .toISOString()
      .replace(/[\\.:-]/g, '')
      .replace('T', '_')
      .replace('Z', '');
  }

  private blockMoreThan2Request(): boolean {
    if (this.concurrentDownloads >= 2) {
      this.notificationService.showSweetAlert(
        NotificationType.INFO,
        'Attenzione',
        'Un massimo di due trasferimenti contemporanei sono supportati, nel caso di esportazione massiva',
      );
      return true;
    }
    return false;
  }
}
