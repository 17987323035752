import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { LottoDetail, Paging } from '@ctel/gaw-commons';
import { ConfigService } from 'app/core/common/config/config.service';
import { AppConfig } from 'app/entities/config/app-config';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import mockDetailJson from './mock-lotto-detail.json';

@Injectable({
  providedIn: 'root',
})
export class LottoDetailHttpService implements OnDestroy {
  // hosts
  private gawlottiReadHost: string;

  private mockDetail = JSON.stringify(mockDetailJson);
  private totalLottoDetail: LottoDetail[];
  private lottoDetail: LottoDetail[] = [];
  private spoolDetails: string;
  private jsonContentType: string;

  private destroy$ = new ReplaySubject<void>(1);

  constructor(
    private http: HttpClient,
    public configService: ConfigService,
  ) {
    this.configService
      .whenAppConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((appConfig: AppConfig) => this.httpHostInit(appConfig));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  httpHostInit(appConfig: AppConfig) {
    this.gawlottiReadHost = appConfig.gawLottiRead.http.host;
    this.spoolDetails = `${this.gawlottiReadHost}/api/v1/Spool/Channel/{tipoCanale}/Communications/{offset}/{rows}`;
  }

  whenLottoDetail(progSpool: string, tipoCanale: string, paging: Paging): Observable<LottoDetail> {
    let url = '';

    url = this.spoolDetails

      .replace('{tipoCanale}', tipoCanale)
      .replace('{offset}', paging.offset.toString(10))
      .replace('{rows}', paging.docsPerPages.toString(10));

    return this.http.post<LottoDetail>(url, { progSpool });
  }
}
